import tr from "./tr";
import en from "./en";
import fr from "./fr";
import ar from "./ar";
import ru from "./ru";

// locales.js
const translations = {
    tr: tr,
    en: en,
    fr: fr,
    ar: ar,
    ru: ru,
};

export default translations;
