import React, { useContext, useEffect, useRef, useState } from "react";

import Select from "react-select";
import { AppSettings } from "../../config/app-settings";

import ImportFile from "../../components/import-file";
import apiClient from "../../ApiClient";
import DynamicCard from "../../components/DynamicCard";
import toastMessage from "../../components/ToastMessage";
import { Card, CardBody, CardExpandToggler } from "../../components/card/card";
import TwitterItem from "./TwitterItem";

export default function SosyalMedya() {
  const context = useContext(AppSettings);
  const idContainer = useRef(null);

  const [fileColumns, setFileColumns] = useState(null);

  const [dataIds, setDataIDs] = useState({ data: [] });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [activeId, setActiveId] = useState(null);
  const [dataCharts, setDataCharts] = useState([]);
  const [dataTable, setDataTable] = useState(null);

  const [fileName, setFileName] = useState(null);
  const [customerValue, setCustomerValue] = useState(null);
  const [customerFileExists, setCustomerFileExists] = useState(false);

  const [pageName, setPageName] = useState("");
  const [activeSurvey, setActiveSurvey] = useState(0);
  const [createPageLoader, setCreatePageLoader] = useState(false);

  const [uploadPage, setUploadPage] = useState(false);

  const [cihazData1, setCihazData1] = useState(false);
  const [cihazData2, setCihazData2] = useState(false);
  const [instagramData1, setInstagramData1] = useState(false);
  const [instagramData2, setInstagramData2] = useState(false);
  const [facebookData1, setFacebookData1] = useState(false);
  const [facebookData2, setFacebookData2] = useState(false);

  const [dataShow, setDataShow] = useState([]);

  const [adminDataShow, setAdminDataShow] = useState(false);

  const [pageSurveyCustomerValue, setPageSurveyCustomerValue] = useState({
    value: null,
    label: "YOK",
  });

  const myCustomer =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).customer;

  const [pages, setPages] = useState(null);

  const [twitterShow, setTwitterShow] = useState(false);

  // Get Pages
  async function handlePageSelect(e) {
    setPages(null);
    setUploadPage(null);

    const data = await context.getOncePages(e.value);
    if (data) {
      setPages(
        data
          .filter((page) => {
            if (e && e.value) {
              return (
                page.name.startsWith("Sosyal Medya") &&
                page.page_type === "custom"
              );
            } else {
              return (
                page.name.startsWith("Sosyal Medya") &&
                page.page_type === "default"
              );
            }
          })
          .sort((a, b) => a.order - b.order)
          .map((item) => {
            return {
              value: item.id,
              label: item.name.split("Sosyal Medya")[1],
            };
          })
      );
    } else {
      setPages(null);
    }

    setCustomerValue(e);
  }

  useEffect(() => {
    if (context && context?.me?.role?.id === 1) {
      handlePageSelect({
        value: null,
        label: context?.t?.none,
      });
    } else {
      handlePageSelect({
        value: myCustomer.id,
        label: myCustomer.customer.name,
      });
    }
  }, [context]);

  // Get File Types
  async function handleFileTypes(adminShow) {
    setFileName(null);

    setFileColumns(null);
    setDataIDs(null);
    setDataCharts(null);

    if (adminShow) {
      setFileName("guncel-sosyal-medya-data-customer-null");
      getFileColumns("guncel-sosyal-medya-data-customer-null");
    } else {
      const data = await context.getFileTypes(myCustomer.id, true);
      if (data.length !== 0) {
        const fileNameCustomer = `guncel-sosyal-medya-data-customer-${myCustomer.id}`;
        const exists = data.some((item) => item.type === fileNameCustomer);

        if (exists) {
          setFileName(fileNameCustomer);
          getFileColumns(fileNameCustomer);
        } else {
          setFileName(null);

          setFileColumns([]);
          setDataIDs({ data: [] });
          setDataCharts([]);
        }
      } else {
        setFileName(null);

        setFileColumns([]);
        setDataIDs({ data: [] });
        setDataCharts([]);
      }
    }
  }

  useEffect(() => {
    handleFileTypes(adminDataShow);
  }, [adminDataShow]);

  // Get File Columns
  async function getFileColumns(name) {
    setFileColumns(null);

    if (name) {
      try {
        const response = await apiClient.get(`/columns/${name}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response) {
          setFileColumns(response.data.columns);

          fetchAds(name);
        }
      } catch (error) {
        setFileColumns([]);
        console.error("Get File Types Error", error);
      }
    } else {
      setFileColumns(null);
    }
  }

  // Get Data
  async function fetchAds(name) {
    setDataCharts(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: name,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Kampanya Adı",
                value: "select",
              },
              {
                key: "Rapor Başlangıcı",
                value: "select",
              },
              {
                key: "Bitiş",
                value: "select",
              },
            ],
            condition: [],
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const sortedData = response.sort((a, b) => {
        return (
          new Date(a["rapor başlangıcı"]) - new Date(b["rapor başlangıcı"])
        );
      });

      setDataCharts(sortedData);
    } catch (error) {
      setDataCharts([]);
      setDataTable([]);
      console.error(error);
    }
  }

  async function fetchAllData(columns, name, activeTitle, platform) {
    if (platform === "facebook") {
      setFacebookData1(null);
      setFacebookData2(null);
    }

    if (platform === "instagram") {
      setInstagramData1(null);
      setInstagramData2(null);
    }

    if (!platform) {
      setCihazData1(null);
      setCihazData2(null);
    }

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: name,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              !platform
                ? {
                    key: "Gösterim Cihazı",
                    value: "select",
                  }
                : null,
              platform
                ? {
                    key: "Platform",
                    value: "select",
                  }
                : null,
              platform
                ? {
                    key: "Reklam Alanı",
                    value: "select",
                  }
                : null,
              {
                key: "Gösterim",
                value: "sum",
              },
              {
                key: "Erişim",
                value: "sum",
              },
            ].filter((item) => item !== null),
            condition: [
              {
                key: "Kampanya Adı",
                value: activeTitle,
                operator: "=",
                type: "and",
              },
              platform
                ? {
                    key: "Platform",
                    value: platform,
                    operator: "=",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setDataShow(true);

      if (platform === "Facebook") {
        if (response.length !== 0) {
          setFacebookData1([
            response.map((item) => item["reklam alanı"]),
            response.map((item) => parseInt(item["gösterim_sum"], 10)),
          ]);

          //--

          setFacebookData2([
            response.map((item) => item["reklam alanı"]),
            response.map((item) => parseInt(item["erişim_sum"], 10)),
          ]);
        } else {
          setFacebookData1([[], []]);
          setFacebookData2([[], []]);
        }
      }

      if (platform === "Instagram") {
        if (response.length !== 0) {
          setInstagramData1([
            response.map((item) => item["reklam alanı"]),
            response.map((item) => parseInt(item["gösterim_sum"], 10)),
          ]);

          //--

          setInstagramData2([
            response.map((item) => item["reklam alanı"]),
            response.map((item) => parseInt(item["erişim_sum"], 10)),
          ]);
        } else {
          setInstagramData1([[], []]);
          setInstagramData2([[], []]);
        }
      }

      if (!platform) {
        if (response.length !== 0) {
          setCihazData1([
            response.map((item) => item["gösterim cihazı"]),
            response.map((item) => parseInt(item["gösterim_sum"], 10)),
          ]);

          //--

          setCihazData2([
            response.map((item) => item["gösterim cihazı"]),
            response.map((item) => parseInt(item["erişim_sum"], 10)),
          ]);
        } else {
          setCihazData1([[], []]);
          setCihazData2([[], []]);
        }
      }
    } catch (error) {
      console.error(error);
      setDataShow(false);

      if (platform === "facebook") {
        setFacebookData1(null);
        setFacebookData2(null);
      }

      if (platform === "instagram") {
        setInstagramData1(null);
        setInstagramData2(null);
      }

      if (!platform) {
        setCihazData1(null);
        setCihazData2(null);
      }
    }
  }

  function handleActiveAnaliz(title) {
    setDataShow(false);
    setActiveSurvey(title);

    fetchAllData(fileColumns, fileName, title, "Facebook");
    fetchAllData(fileColumns, fileName, title, "Instagram");
    fetchAllData(fileColumns, fileName, title, null);

    setTwitterShow(false);
  }

  return (
    <div className="row gap-4 mb-5 pb-5">
      <div className="zoom-item">
        <div className="col-12">
          <div className="border-bottom pb-4 mb-4 d-flex align-items-end gap-3">
            <div className="border-end pe-3">
              <a
                href={`${process.env.PUBLIC_URL}/download/ornek-sosyal-medya.csv`}
                className="d-block mb-2"
              >
                {context?.t?.example_file_download}
              </a>
              <button
                type="button"
                className="btn btn-outline-theme btn-lg"
                data-bs-toggle="modal"
                data-bs-target="#createGraphicSurveyModal"
              >
                {context?.t?.analiz_yukle}
              </button>
            </div>

            <button
              type="button"
              className={`btn btn-lg ${
                !twitterShow
                  ? "btn-theme text-black fw-bold"
                  : "btn-outline-theme"
              }`}
              onClick={() => {
                setTwitterShow(false);
              }}
            >
              Meta
            </button>

            <button
              type="button"
              className={`btn btn-lg ${
                twitterShow
                  ? "btn-theme text-black fw-bold"
                  : "btn-outline-theme"
              }`}
              onClick={() => {
                setTwitterShow(true);
              }}
            >
              Twitter
            </button>
            {/* <button
              type="button"
              className="btn btn-outline-theme btn-lg"
              onClick={() => setAdminDataShow(!adminDataShow)}
            >
              {adminDataShow ? "Benim Analizim" : "Genel Analiz Görüntüle"}
            </button> */}
          </div>
        </div>

        {/* <div className="col-12">
          <div className="col w-auto mb-3" style={{ flex: "none" }}>
            <h5>Genel Analizler</h5>
          </div>
          <div className="row g-3">
            {context.pages ? (
              context.pages.filter(
                (page) =>
                  page.page_type === "default" &&
                  page.id !== 66 &&
                  page.name.startsWith("Sosyal Medya")
              ).length !== 0 ? (
                context.pages
                  .filter(
                    (page) =>
                      page.page_type === "default" &&
                      page.id !== 66 &&
                      page.name.startsWith("Sosyal Medya")
                  )
                  .map((item, index) => (
                    <div
                      key={index}
                      className="col w-auto"
                      style={{ flex: "none" }}
                    >
                      <button
                        type="button"
                        className={`btn btn-lg btn${
                          activeSurvey === item.id ? "" : "-outline"
                        }-theme`}
                        onClick={() => handleActiveSurvey(item.id)}
                      >
                        {item.name.split("Sosyal Medya")[1]}
                      </button>
                    </div>
                  ))
              ) : (
                <span>Genel Bir {context?.t?.analiz_bulunamadi}</span>
              )
            ) : (
              <div className="col-12">
                <div className="spinner-border spinner-border-sm text-theme"></div>
              </div>
            )}
          </div>
          <div className="col-12 mt-4">
            <hr />
          </div>
        </div> */}

        {twitterShow ? (
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-4">
                <Card>
                  <CardBody>
                    <h4 className="mb-4">{context?.t?.turkiye_gundemi}</h4>

                    <div
                      className="overflow-auto"
                      style={{ height: "calc(100vh - 300px)" }}
                    >
                      <TwitterItem />
                      <TwitterItem type="img" />
                      <TwitterItem type="video" />
                    </div>

                    <span className="text-black position-absolute bottom-0 start-0 m-2 bg-theme rounded">
                      <i className="fas fa-md fa-fw fa-arrow-down"></i>
                    </span>
                  </CardBody>
                </Card>
              </div>

              <div className="col-12 col-md-4">
                <Card>
                  <CardBody>
                    <div className="row align-items-center g-2 mb-3">
                      <div className="col w-auto flex-none me-3">
                        <h4 className="mb-0">{context?.t?.profile}</h4>
                      </div>

                      <div className="col">
                        <input
                          type="text"
                          placeholder={context?.t?.kullanici_secin}
                          className="form-control"
                        />
                      </div>

                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={context?.t?.etiket_yazin}
                        />
                      </div>

                      <div className="col w-auto flex-none">
                        <button type="button" className="btn btn-outline-theme">
                          {context?.t?.getir}
                        </button>
                      </div>
                    </div>

                    <div
                      className="overflow-auto"
                      style={{ height: "calc(100vh - 300px)" }}
                    >
                      <TwitterItem />
                      <TwitterItem type="img" />
                      <TwitterItem type="video" />
                    </div>

                    <span className="text-black position-absolute bottom-0 start-0 m-2 bg-theme rounded">
                      <i className="fas fa-md fa-fw fa-arrow-down"></i>
                    </span>
                  </CardBody>
                </Card>
              </div>

              <div className="col-12 col-md-4">
                <Card>
                  <CardBody>
                    <div className="row g-2 mb-3 align-items-center justify-content-between">
                      <div className="col w-auto flex-none">
                        <h4 className="mb-0">{context?.t?.etiketlenen}</h4>
                      </div>

                      <div className="col w-auto flex-none">
                        <div className="row g-2">
                          <div className="col w-auto flex-none">
                            <Select
                              options={[
                                {
                                  value: null,
                                  label: "Kişi 1",
                                },
                                {
                                  value: null,
                                  label: "Kişi 2",
                                },
                              ]}
                              placeholder={context?.t?.kullanici_secin}
                              classNamePrefix="react-select"
                              noOptionsMessage={() => context?.t?.no_option}
                            />
                          </div>

                          <div className="col w-auto flex-none">
                            <button
                              type="button"
                              className="btn btn-outline-theme"
                            >
                              {context?.t?.getir}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="overflow-auto"
                      style={{ height: "calc(100vh - 300px)" }}
                    >
                      <TwitterItem />
                      <TwitterItem type="img" />
                      <TwitterItem type="video" />
                    </div>

                    <span className="text-black position-absolute bottom-0 start-0 m-2 bg-theme rounded">
                      <i className="fas fa-md fa-fw fa-arrow-down"></i>
                    </span>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="col-12">
              <div className="col w-auto mb-3" style={{ flex: "none" }}>
                <h5>{context?.t?.reklam_basliklari}</h5>
              </div>
              <div className="row g-3">
                {dataCharts ? (
                  dataCharts.length !== 0 ? (
                    <div style={{ maxWidth: 300 }}>
                      <Select
                        options={dataCharts.map((item, index) => {
                          return {
                            value: item["kampanya adı"],
                            label: item["kampanya adı"],
                          };
                        })}
                        classNamePrefix="react-select"
                        placeholder={context?.t?.reklam_seciniz}
                        noOptionsMessage={() => context?.t?.no_option}
                        value={
                          activeSurvey
                            ? { label: activeSurvey, value: activeSurvey }
                            : null
                        }
                        onChange={(e) => handleActiveAnaliz(e.value)}
                      />
                    </div>
                  ) : (
                    <span>{context?.t?.analiz_yukleyin}</span>
                  )
                ) : (
                  <div className="col-12">
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  </div>
                )}

                {/* {dataCharts ? (
              dataCharts.length !== 0 ? (
                dataCharts.map((item, index) => (
                  <div
                    key={index}
                    className="col w-auto"
                    style={{ flex: "none" }}
                  >
                    <button
                      type="button"
                      className={`btn btn${
                        activeSurvey === item["kampanya adı"] ? "" : "-outline"
                      }-theme`}
                      onClick={() => handleActiveAnaliz(item["kampanya adı"])}
                    >
                      {item["kampanya adı"]}
                    </button>
                  </div>
                ))
              ) : (
                <span>Analiz Yükleyin</span>
              )
            ) : (
              <div className="col-12">
                <div className="spinner-border spinner-border-sm text-theme"></div>
              </div>
            )} */}
              </div>
              <div className="col-12 mt-4">
                <hr />
              </div>
            </div>

            <div className="col-12">
              {dataShow ? (
                <div className="row g-4">
                  {(facebookData1 || facebookData2) && (
                    <div className="col-12 col-md-6">
                      <div className="border p-3">
                        <div className="col-12">
                          <h5 className="text-center border-bottom pb-3 mb-3">
                            Facebook
                          </h5>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6">
                            {facebookData1 ? (
                              facebookData1[0].length !== 0 ? (
                                <DynamicCard
                                  events={false}
                                  fullScreen={true}
                                  cityFilter={"asd"}
                                  data={facebookData1[0]}
                                  item={{
                                    title: "Gösterim",
                                    chart: 2,
                                    options: {
                                      options: {
                                        grid: {
                                          bottom: "20%",
                                        },
                                        xAxis: [
                                          {
                                            data:
                                              facebookData1 && facebookData1[0],
                                            type: "category",
                                            axisLabel: {
                                              rotate: 45,
                                              textStyle: {
                                                color: "#fff",
                                                fontSize: 10,
                                              },
                                            },
                                          },
                                        ],
                                        yAxis: [
                                          {
                                            type: "value",
                                            axisLabel: {
                                              textStyle: {
                                                color: "#fff",
                                              },
                                            },
                                          },
                                        ],
                                        series: [
                                          {
                                            data:
                                              facebookData1 && facebookData1[1],
                                            name: null,
                                            type: "bar",
                                            itemStyle: {
                                              color: context?.chartColor,
                                            },
                                            markPoint: {
                                              data: [
                                                {
                                                  name: "Max",
                                                  type: "max",
                                                },
                                                {
                                                  name: "Min",
                                                  type: "min",
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                        toolbox: {
                                          show: true,
                                          feature: {
                                            restore: {
                                              show: false,
                                            },
                                            magicType: {
                                              show: true,
                                              type: ["line", "bar"],
                                            },
                                            saveAsImage: {
                                              show: false,
                                            },
                                          },
                                          iconStyle: {
                                            borderColor: "#fff",
                                          },
                                        },
                                        tooltip: {
                                          trigger: "axis",
                                        },
                                        darkMode: true,
                                        visualMap: context?.chartColor
                                          ? {
                                              max: 50,
                                              min: 0,
                                              show: false,
                                              inRange: {
                                                colorLightness: [0.2, 1],
                                              },
                                            }
                                          : false,
                                        calculable: true,
                                      },
                                    },
                                  }}
                                />
                              ) : (
                                <div className="text-center">
                                  {context?.t?.analiz_bulunamadi}
                                </div>
                              )
                            ) : (
                              <div className="text-center">
                                <div className="spinner-border text-theme"></div>
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-md-6">
                            {facebookData2 ? (
                              facebookData2[0].length !== 0 ? (
                                <DynamicCard
                                  events={false}
                                  fullScreen={true}
                                  cityFilter={"asd"}
                                  data={facebookData2[0]}
                                  item={{
                                    title: "Erişim",
                                    chart: 2,
                                    options: {
                                      options: {
                                        grid: {
                                          bottom: "20%",
                                        },
                                        xAxis: [
                                          {
                                            data:
                                              facebookData2 && facebookData2[0],
                                            type: "category",
                                            axisLabel: {
                                              rotate: 45,
                                              textStyle: {
                                                color: "#fff",
                                                fontSize: 10,
                                              },
                                            },
                                          },
                                        ],
                                        yAxis: [
                                          {
                                            type: "value",
                                            axisLabel: {
                                              textStyle: {
                                                color: "#fff",
                                              },
                                            },
                                          },
                                        ],
                                        series: [
                                          {
                                            data:
                                              facebookData2 && facebookData2[1],
                                            name: null,
                                            type: "bar",
                                            itemStyle: {
                                              color: context?.chartColor,
                                            },
                                            markPoint: {
                                              data: [
                                                {
                                                  name: "Max",
                                                  type: "max",
                                                },
                                                {
                                                  name: "Min",
                                                  type: "min",
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                        toolbox: {
                                          show: true,
                                          feature: {
                                            restore: {
                                              show: false,
                                            },
                                            magicType: {
                                              show: true,
                                              type: ["line", "bar"],
                                            },
                                            saveAsImage: {
                                              show: false,
                                            },
                                          },
                                          iconStyle: {
                                            borderColor: "#fff",
                                          },
                                        },
                                        tooltip: {
                                          trigger: "axis",
                                        },
                                        darkMode: true,
                                        visualMap: context?.chartColor
                                          ? {
                                              max: 50,
                                              min: 0,
                                              show: false,
                                              inRange: {
                                                colorLightness: [0.2, 1],
                                              },
                                            }
                                          : false,
                                        calculable: true,
                                      },
                                    },
                                  }}
                                />
                              ) : (
                                <div className="text-center">
                                  {context?.t?.analiz_bulunamadi}
                                </div>
                              )
                            ) : (
                              <div className="text-center">
                                <div className="spinner-border text-theme"></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {(instagramData1 || instagramData2) && (
                    <div className="col-12 col-md-6">
                      <div className="border p-3">
                        <div className="col-12">
                          <h5 className="text-center border-bottom pb-3 mb-3">
                            Instagram
                          </h5>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6">
                            {instagramData1 ? (
                              instagramData1[0].length !== 0 ? (
                                <DynamicCard
                                  events={false}
                                  fullScreen={true}
                                  cityFilter={"asd"}
                                  data={instagramData1[0]}
                                  item={{
                                    title: "Gösterim",
                                    chart: 2,
                                    options: {
                                      options: {
                                        grid: {
                                          bottom: "20%",
                                        },
                                        xAxis: [
                                          {
                                            data:
                                              instagramData1 &&
                                              instagramData1[0],
                                            type: "category",
                                            axisLabel: {
                                              rotate: 45,
                                              textStyle: {
                                                color: "#fff",
                                                fontSize: 10,
                                              },
                                            },
                                          },
                                        ],
                                        yAxis: [
                                          {
                                            type: "value",
                                            axisLabel: {
                                              textStyle: {
                                                color: "#fff",
                                              },
                                            },
                                          },
                                        ],
                                        series: [
                                          {
                                            data:
                                              instagramData1 &&
                                              instagramData1[1],
                                            name: null,
                                            type: "bar",
                                            itemStyle: {
                                              color: context?.chartColor,
                                            },
                                            markPoint: {
                                              data: [
                                                {
                                                  name: "Max",
                                                  type: "max",
                                                },
                                                {
                                                  name: "Min",
                                                  type: "min",
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                        toolbox: {
                                          show: true,
                                          feature: {
                                            restore: {
                                              show: false,
                                            },
                                            magicType: {
                                              show: true,
                                              type: ["line", "bar"],
                                            },
                                            saveAsImage: {
                                              show: false,
                                            },
                                          },
                                          iconStyle: {
                                            borderColor: "#fff",
                                          },
                                        },
                                        tooltip: {
                                          trigger: "axis",
                                        },
                                        darkMode: true,
                                        visualMap: context?.chartColor
                                          ? {
                                              min:
                                                instagramData1 &&
                                                instagramData1[1].length !== 0
                                                  ? Math.min(
                                                      ...instagramData1[1]
                                                    )
                                                  : 0,
                                              max:
                                                instagramData1 &&
                                                instagramData1[1].length !== 0
                                                  ? Math.max(
                                                      ...instagramData1[1]
                                                    )
                                                  : 100,
                                              show: false,
                                              inRange: {
                                                colorLightness: [0.2, 1],
                                              },
                                            }
                                          : false,
                                        calculable: true,
                                      },
                                    },
                                  }}
                                />
                              ) : (
                                <div className="text-center">
                                  {context?.t?.analiz_bulunamadi}
                                </div>
                              )
                            ) : (
                              <div className="text-center">
                                <div className="spinner-border text-theme"></div>
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-md-6">
                            {instagramData2 ? (
                              instagramData2[0].length !== 0 ? (
                                <DynamicCard
                                  events={false}
                                  fullScreen={true}
                                  cityFilter={"asd"}
                                  data={instagramData2[0]}
                                  item={{
                                    title: "Erişim",
                                    chart: 2,
                                    options: {
                                      options: {
                                        grid: {
                                          bottom: "20%",
                                        },
                                        xAxis: [
                                          {
                                            data:
                                              instagramData2 &&
                                              instagramData2[0],
                                            type: "category",
                                            axisLabel: {
                                              rotate: 45,
                                              textStyle: {
                                                color: "#fff",
                                                fontSize: 10,
                                              },
                                            },
                                          },
                                        ],
                                        yAxis: [
                                          {
                                            type: "value",
                                            axisLabel: {
                                              textStyle: {
                                                color: "#fff",
                                              },
                                            },
                                          },
                                        ],
                                        series: [
                                          {
                                            data:
                                              instagramData2 &&
                                              instagramData2[1],
                                            name: null,
                                            type: "bar",
                                            itemStyle: {
                                              color: context?.chartColor,
                                            },
                                            markPoint: {
                                              data: [
                                                {
                                                  name: "Max",
                                                  type: "max",
                                                },
                                                {
                                                  name: "Min",
                                                  type: "min",
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                        toolbox: {
                                          show: true,
                                          feature: {
                                            restore: {
                                              show: false,
                                            },
                                            magicType: {
                                              show: true,
                                              type: ["line", "bar"],
                                            },
                                            saveAsImage: {
                                              show: false,
                                            },
                                          },
                                          iconStyle: {
                                            borderColor: "#fff",
                                          },
                                        },
                                        tooltip: {
                                          trigger: "axis",
                                        },
                                        darkMode: true,
                                        visualMap: context?.chartColor
                                          ? {
                                              min:
                                                instagramData2 &&
                                                instagramData2[1].length !== 0
                                                  ? Math.min(
                                                      ...instagramData2[1]
                                                    )
                                                  : 0,
                                              max:
                                                instagramData2 &&
                                                instagramData2[1].length !== 0
                                                  ? Math.max(
                                                      ...instagramData2[1]
                                                    )
                                                  : 100,
                                              show: false,
                                              inRange: {
                                                colorLightness: [0.2, 1],
                                              },
                                            }
                                          : false,
                                        calculable: true,
                                      },
                                    },
                                  }}
                                />
                              ) : (
                                <div className="text-center">
                                  {context?.t?.analiz_bulunamadi}
                                </div>
                              )
                            ) : (
                              <div className="text-center">
                                <div className="spinner-border text-theme"></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {(cihazData1 || cihazData2) && (
                    <div className="col-12">
                      <div className="border p-3">
                        <div className="col-12">
                          <h5 className="text-center border-bottom pb-3 mb-3">
                            Cihaz
                          </h5>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6">
                            {cihazData1 ? (
                              cihazData1[0].length !== 0 ? (
                                <DynamicCard
                                  events={false}
                                  fullScreen={true}
                                  cityFilter={"asd"}
                                  data={cihazData1[0]}
                                  item={{
                                    title: "Gösterim",
                                    chart: 2,
                                    options: {
                                      options: {
                                        grid: {
                                          bottom: "20%",
                                        },
                                        xAxis: [
                                          {
                                            data: cihazData1 && cihazData1[0],
                                            type: "category",
                                            axisLabel: {
                                              rotate: 45,
                                              textStyle: {
                                                color: "#fff",
                                                fontSize: 10,
                                              },
                                            },
                                          },
                                        ],
                                        yAxis: [
                                          {
                                            type: "value",
                                            axisLabel: {
                                              textStyle: {
                                                color: "#fff",
                                              },
                                            },
                                          },
                                        ],
                                        series: [
                                          {
                                            data: cihazData1 && cihazData1[1],
                                            name: null,
                                            type: "bar",
                                            itemStyle: {
                                              color: context?.chartColor,
                                            },
                                            markPoint: {
                                              data: [
                                                {
                                                  name: "Max",
                                                  type: "max",
                                                },
                                                {
                                                  name: "Min",
                                                  type: "min",
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                        toolbox: {
                                          show: true,
                                          feature: {
                                            restore: {
                                              show: false,
                                            },
                                            magicType: {
                                              show: true,
                                              type: ["line", "bar"],
                                            },
                                            saveAsImage: {
                                              show: false,
                                            },
                                          },
                                          iconStyle: {
                                            borderColor: "#fff",
                                          },
                                        },
                                        tooltip: {
                                          trigger: "axis",
                                        },
                                        darkMode: true,
                                        visualMap: context?.chartColor
                                          ? {
                                              min:
                                                cihazData1 &&
                                                cihazData1[1].length !== 0
                                                  ? Math.min(...cihazData1[1])
                                                  : 0,
                                              max:
                                                cihazData1 &&
                                                cihazData1[1].length !== 0
                                                  ? Math.max(...cihazData1[1])
                                                  : 100,
                                              show: false,
                                              inRange: {
                                                colorLightness: [0.2, 1],
                                              },
                                            }
                                          : false,
                                        calculable: true,
                                      },
                                    },
                                  }}
                                />
                              ) : (
                                <div className="text-center">
                                  {context?.t?.analiz_bulunamadi}
                                </div>
                              )
                            ) : (
                              <div className="text-center">
                                <div className="spinner-border text-theme"></div>
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-md-6">
                            {cihazData2 ? (
                              cihazData2[0].length !== 0 ? (
                                <DynamicCard
                                  events={false}
                                  fullScreen={true}
                                  cityFilter={"asd"}
                                  data={cihazData2[0]}
                                  item={{
                                    title: "Erişim",
                                    chart: 2,
                                    options: {
                                      options: {
                                        grid: {
                                          bottom: "20%",
                                        },
                                        xAxis: [
                                          {
                                            data: cihazData2 && cihazData2[0],
                                            type: "category",
                                            axisLabel: {
                                              rotate: 45,
                                              textStyle: {
                                                color: "#fff",
                                                fontSize: 10,
                                              },
                                            },
                                          },
                                        ],
                                        yAxis: [
                                          {
                                            type: "value",
                                            axisLabel: {
                                              textStyle: {
                                                color: "#fff",
                                              },
                                            },
                                          },
                                        ],
                                        series: [
                                          {
                                            data: cihazData2 && cihazData2[1],
                                            name: null,
                                            type: "bar",
                                            itemStyle: {
                                              color: context?.chartColor,
                                            },
                                            markPoint: {
                                              data: [
                                                {
                                                  name: "Max",
                                                  type: "max",
                                                },
                                                {
                                                  name: "Min",
                                                  type: "min",
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                        toolbox: {
                                          show: true,
                                          feature: {
                                            restore: {
                                              show: false,
                                            },
                                            magicType: {
                                              show: true,
                                              type: ["line", "bar"],
                                            },
                                            saveAsImage: {
                                              show: false,
                                            },
                                          },
                                          iconStyle: {
                                            borderColor: "#fff",
                                          },
                                        },
                                        tooltip: {
                                          trigger: "axis",
                                        },
                                        darkMode: true,
                                        visualMap: context?.chartColor
                                          ? {
                                              min:
                                                cihazData2 &&
                                                cihazData2[1].length !== 0
                                                  ? Math.min(...cihazData2[1])
                                                  : 0,
                                              max:
                                                cihazData2 &&
                                                cihazData2[1].length !== 0
                                                  ? Math.max(...cihazData2[1])
                                                  : 100,
                                              show: false,
                                              inRange: {
                                                colorLightness: [0.2, 1],
                                              },
                                            }
                                          : false,
                                        calculable: true,
                                      },
                                    },
                                  }}
                                />
                              ) : (
                                <div className="text-center">
                                  {context?.t?.analiz_bulunamadi}
                                </div>
                              )
                            ) : (
                              <div className="text-center">
                                <div className="spinner-border text-theme"></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="spinner-border text-theme"></div>
              )}
            </div>
          </>
        )}

        {/* {activeSurvey ? (
          <>
            <div className="col-12">
              <div className="row g-4">
                {dataCharts ? (
                  dataCharts.length !== 0 ? (
                    dataCharts.map((item, index) =>
                      item.chart === 0 ? (
                        <div
                          className={`graphic-item position-relative ${
                            item?.options?.width?.value.split("col-")[1]
                              ? "col-12 col-xl-" +
                                item?.options?.width?.value.split("col-")[1]
                              : item?.options?.width?.value
                          }`}
                        >
                          <Card>
                            <CardBody className="d-flex flex-column h-100">
                              {item.title && (
                                <div
                                  className={`d-flex align-items-center justify-content-between border-bottom pb-2 mb-3`}
                                >
                                  <span
                                    className={`fw-bold h6 mb-0 flex-1`}
                                    style={{
                                      maxWidth: 500,
                                    }}
                                  >
                                    {item?.title?.toUpperCase()}
                                  </span>

                                  <div className="d-flex align-items-center ms-2">
                                    <CardExpandToggler />
                                  </div>
                                </div>
                              )}

                              <div className="position-relative overflow-hidden flex-1 d-flex flex-column">
                                <div
                                  className="table-responsive"
                                  style={{ height: 510 }}
                                >
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>Cevap</th>
                                        <th className="text-end">Sayısı</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {item.options.options.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td>{item.name}</td>
                                            <td className="text-end">
                                              {item.value}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              {item.order && (
                                <div className="text-end">#{item.order}</div>
                              )}
                            </CardBody>
                          </Card>
                        </div>
                      ) : (
                        <DynamicCard
                          events={false}
                          key={index}
                          fullScreen={true}
                          item={item}
                          cityFilter={"asd"}
                        />
                      )
                    )
                  ) : (
                    <span>{context?.t?.analiz_bulunamadi}</span>
                  )
                ) : (
                  <div className="spinner-border text-theme"></div>
                )}
              </div>
            </div>
          </>
        ) : (
          <h5 className="mt-4 fw-normal">Analiz Yükleyin</h5>
        )} */}
      </div>

      <div className="modal modal-cover fade" id="createGraphicSurveyModal">
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">{context?.t?.analiz_yukle}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="createGraphicSurveyModalCloseButton"
              ></button>
            </div>

            <div className="modal-body">
              {context?.me?.role?.id === 1 && (
                <div className="mb-3">
                  <span className="mb-1 d-block">{context?.t?.customer}</span>
                  {context.allCustomerSelect ? (
                    <Select
                      options={[
                        // {
                        //   value: null,
                        //   label: "YOK",
                        //   user_id: null,
                        // },
                        ...context.allCustomerSelect,
                      ]}
                      placeholder=""
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      value={customerValue}
                      onChange={(e) => handlePageSelect(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
              )}

              <ImportFile
                closeId="createGraphicSurveyModalCloseButton"
                pageImport={true}
                columnTypesString={true}
                dataname={`guncel-sosyal-medya-data-customer-${
                  context?.me?.role?.id === 1
                    ? customerValue?.value
                    : myCustomer?.id
                }-user-${customerValue?.user_id || null}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
