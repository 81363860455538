import React from "react";

import KentmetreMap from "../anasayfa/KentmetreMap";
import { Link } from "react-router-dom";

export default function KentmetreHaritasi() {
  return (
    <>
      <nav>
        <div className="nav nav-tabs text-center pb-4 mb-4 gap-3">
          <Link
            to="/strateji-haritasi"
            className="btn btn-lg btn-outline-theme flex-1"
          >
            Strateji Haritası
          </Link>
          <Link
            to="/kentmetre-haritasi"
            className="btn btn-lg btn-theme flex-1"
          >
            Kentmetre Haritası
          </Link>
        </div>
      </nav>

      <div style={{ height: "100vh" }} className="mb-5">
        <KentmetreMap tab={false} />
      </div>
    </>
  );
}
