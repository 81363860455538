export const stats = [
  {
    counts: [
      {
        text: "Sokak Başına Ortalama Bina Sayısı",
        count: 28,
      },
      {
        text: "Bina Başına Ortalama Hane Sayısı",
        count: 7,
      },
    ],
    countsBorder: true,
    info: [
      {
        text: "XX Mahalle Sayısı",
      },
      {
        text: "XXX Sokak Sayısı",
      },
      {
        text: "XXXXX Bina Sayısı",
      },
      {
        text: "XXXXXXXX Hane Sayısı",
      },
    ],
    infoGrid: true,
  },
  {
    title: "Ortalama Yaş",
    chartData: [
      {
        name: "Visitors",
        data: [69, 34, 70, 73, 41, 32, 73, 72, 89, 57, 42, 86, 78],
      },
    ],
    chartType: "bar",
    chartHeight: 30,
  },
  {
    counts: [
      {
        text: "1. Tur 2. Başkanlık Seçiminde İlk Defa Oy Kullanan Seçmen Sayısı",
        count: "XXX",
      },
      {
        text: "31 Mart Yeel  Seçiminde İlk Defa Oy Kullanan Seçmen Sayısı",
        count: "XXX",
      },
    ],
  },
  {
    info: [
      {
        text: "Seçmen Sayısı",
      },
      {
        text: "Kadın Seçmen Sayısı",
      },
      {
        text: "Erkek Seçmen Sayısı",
      },
    ],
    chartData: [77, 80, 70],
    chartType: "pie",
    chartHeight: 45,
  },
  {
    title: "İLK 4 HEMŞEHRİLİK ORANI",
    info: [
      {
        text: "XXX Y İlinin Z İLÇESİ",
      },
      {
        text: "XXX B İlinin C İLÇESİ",
      },
      {
        text: "XXX D İlinin E İLÇESİ",
      },
      {
        text: "XXX F İlinin G İLÇESİ",
      },
    ],
    chartData: [
      {
        name: "Visitors",
        data: [69, 34, 70, 73, 41, 32, 73, 72, 89, 57, 42, 86, 78],
      },
    ],
    chartType: "bar",
    chartHeight: 30,
  },
  {
    title: "Sosyoekonomik Statü Dağılımı",
    info: [
      {
        text: "XXX AA",
      },
      {
        text: "XXX C",
      },
      {
        text: "XXX A",
      },
      {
        text: "XXX D",
      },
      {
        text: "XXX B",
      },
      {
        text: "XXX E",
      },
    ],
    infoGrid: true,
    chartData: [83, 30, 74, 58, 70, 78],
    chartType: "donut",
    chartHeight: 45,
  },
];
