import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import toastMessage from "../ToastMessage";
import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";
import moment from "moment";
// import TusUploader from "./tus-upload";

export default function ImportFile({
  closeId,
  pageNameRequired = false,
  pageName = null,
  dataname = null,
  customerProps = null,
  pageImport = false,
  columnTypesString = false,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [separator, setSeparator] = useState(null);
  const [columns, setColumns] = useState([]);
  const [fileImportError, setFileImportError] = useState(null);
  const [file, setFile] = useState(null);
  const [dataName, setDataName] = useState("");
  const [columnTypes, setColumnTypes] = useState({});

  const [createFileShow, setCreateFileShow] = useState(false);
  const [rows, setRows] = useState([
    {
      id: 1,
      inputType: "string",
      exampleData: "Title",
      renderedData: "Example",
    },
  ]);

  const [customerValue, setCustomerValue] = useState({
    value: null,
    label: context?.t?.all,
  });

  useEffect(() => {
    if (customerProps) {
      setCustomerValue({
        value: customerProps?.value,
        label: customerProps?.label,
        user_id: customerProps?.user_id,
      });
    } else {
      setCustomerValue({
        value: null,
        label: context?.t?.all,
        user_id: null,
      });
    }
  }, [customerProps, context]);

  function errorMessages(error) {
    if (error.response && error.response.status) {
      if (error.response.status === 422) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }

      if (error.response.status === 401) {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }

      if (error.response.status === 403) {
        alert(context?.t?.try_again_error);
        setTimeout(() => {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }, 1000);
      }

      if (error.response.status === 500) {
        alert(context?.t?.server_error);
      }
    } else {
      toastMessage(context?.t?.file_size_error, "error");
    }
  }

  const separators = [
    ",",
    ";",
    "|",
    ":",
    "!",
    "#",
    "$",
    "%",
    "&",
    "*",
    "+",
    ".",
    "/",
    "<",
    "=",
    ">",
    "?",
    "@",
    "^",
    "-",
    "_",
    "`",
    "~",
  ];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCreateFileShow(false);
      setColumns([]);

      if (file.size >= 200 * 1024 * 1024) {
        toastMessage(context?.t?.max_200_mb, "error");
      } else {
        setFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target.result;
          const detectedSeparator = detectSeparator(text);
          if (detectedSeparator) {
            setSeparator(detectedSeparator);
            setFileImportError(null);
            const columns = extractColumns(text, detectedSeparator);
            setColumns(columns);
            setColumnTypes(
              columns.reduce((acc, col) => {
                acc[col] = "";
                return acc;
              }, {})
            );
          } else {
            setSeparator(null);
            setFileImportError(context?.t?.separators_error);
            setColumns([]);
          }
        };
        reader.readAsText(file);
      }
    }
  };

  const detectSeparator = (text) => {
    let maxSeparator = null;
    let maxCount = 0;

    separators.forEach((sep) => {
      const count = text.split(sep).length - 1;
      if (count > maxCount) {
        maxCount = count;
        maxSeparator = sep;
      }
    });

    return maxCount > 0 ? maxSeparator : null;
  };

  const extractColumns = (text, separator) => {
    const rows = text.split("\n");
    if (rows.length > 0) {
      return rows[0].split(separator).map((col) => col.trim());
    }
    return [];
  };

  const handleColumnTypeChange = (column, selectedOption) => {
    setColumnTypes({
      ...columnTypes,
      [column]: selectedOption ? selectedOption.value : "",
    });
  };

  const handleSubmit = async () => {
    let staticColumnTypes = null;

    if (pageNameRequired && !pageName) {
      toastMessage(context?.t?.analysisRequired, "error");
      return;
    }

    if (!dataName) {
      toastMessage(context?.t?.data_name_important, "error");
      return;
    }

    const formData = new FormData();

    if (createFileShow) {
      let valid = true;

      const formattedRows = rows.map((row) => {
        if (!row.exampleData || !row.renderedData || !row.inputType) {
          toastMessage(
            `${row.id - 1 === 0 ? 1 : row.id - 1} - ${
              context?.t?.bos_birakmayin
            }`,
            "error"
          );
          valid = false;
        }

        if (row.inputType === "date" && row.renderedData) {
          return {
            ...row,
            renderedData: moment(row.renderedData).format("YYYY-MM-DD"),
          };
        }
        return row;
      });

      if (valid) {
        const headers = formattedRows.map((row) => row.exampleData).join(";");
        const rowData = formattedRows.map((row) => row.renderedData).join(";");

        const csvContent = `${headers}\n${rowData}`;

        const blob = new Blob([csvContent], { type: "text/csv" });
        const file = new File([blob], dataName + ".csv", { type: "text/csv" });

        formData.append("file", file);
        formData.append(
          "data_type",
          context?.me?.role?.id === 1
            ? customerValue?.value
              ? "custom"
              : "global"
            : "custom"
        );
        formData.append(
          "user_id",
          customerValue?.value ? customerValue?.user_id : context?.me?.id
        );
        formData.append("type", "manuel-" + dataName);
        formData.append("separator", ";");
        formattedRows.forEach((item, index) => {
          formData.append("columns[" + index + "][key]", item.exampleData);
          formData.append("columns[" + index + "][value]", item.inputType);
        });
      }
    } else {
      const maxFileSize = 200 * 1024 * 1024; // 5MB in bytes

      if (!file) {
        toastMessage(context?.t?.file_important, "error");
        return;
      }
      if (file.size > maxFileSize) {
        toastMessage(context?.t?.max_200_mb, "error");
        return;
      }
      if (!separator) {
        toastMessage(context?.t?.separators_warning, "error");
        return;
      }

      if (columnTypesString) {
        staticColumnTypes = Object.fromEntries(
          Object.entries(columnTypes).map(([key, value]) => [key, "string"])
        );
      } else {
        staticColumnTypes = null;
      }

      if (!columnTypesString && columns.some((col) => !columnTypes[col])) {
        toastMessage(context?.t?.column_warning, "error");
        return;
      }

      formData.append("file", file);
      formData.append(
        "data_type",
        context?.me?.role?.id === 1
          ? dataname &&
            dataname.includes("customer-") &&
            dataname.split("customer-")[1].split("-")[0] !== "null"
            ? "custom"
            : customerValue?.value
            ? "custom"
            : "global"
          : "custom"
      );

      if (
        dataname &&
        dataname.includes("user-") &&
        dataname.split("user-")[1] !== "null"
      ) {
        formData.append("user_id", dataname.split("user-")[1]);
      } else {
        const localUser = localStorage.getItem("user");
        if (localUser) {
          const parsedUser = JSON.parse(localUser);
          formData.append(
            "user_id",
            customerValue?.value
              ? customerValue?.user_id
              : parsedUser.customer &&
                parsedUser.customer.user &&
                parsedUser.customer.user.length !== 0
              ? parsedUser.customer.user[0].id
              : null
          );
        } else {
          formData.append("user_id", null);
        }
      }

      formData.append(
        "type",
        dataname ? dataname.split("-user-")[0] || dataname : dataName
      );
      formData.append("separator", separator);
      columns.forEach((col, index) => {
        formData.append("columns[" + index + "][key]", col);
        formData.append(
          "columns[" + index + "][value]",
          staticColumnTypes ? staticColumnTypes[col] : columnTypes[col]
        );
      });
    }

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    // CSV POST
    setLoader(true);

    try {
      const response = await apiClient.post("/csv/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setLoader(false);

      if (response.status) {
        toastMessage(context?.t?.file_import_success, "success");
        toastMessage(context?.t?.file_import_info, "success");

        setSeparator(null);
        setColumns([]);
        setFileImportError(null);
        setFile(null);
        setDataName("");
        setColumnTypes({});

        if (closeId) {
          setTimeout(() => {
            document.getElementById(closeId).click();
          }, 300);
        }

        // Reset 2
        setCreateFileShow(false);
        setRows([
          {
            id: 1,
            inputType: "string",
            exampleData: "Title",
            renderedData: "Example",
          },
        ]);
      } else {
        toastMessage(context?.t?.file_import_error, "error");
      }
    } catch (error) {
      setLoader(false);
      console.error("Import CSV File Error", error);
      errorMessages(error);
    }
  };

  //--

  useEffect(() => {
    if (dataname) {
      setDataName(dataname);
    } else {
      setDataName("");
    }
  }, [dataname]);

  // Create File
  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        inputType: "string",
        exampleData: "Title",
        renderedData: "Example",
      },
    ]);
  };

  const handleRemoveRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleExampleInputChange = (id, value) => {
    setRows(
      rows.map((row) => (row.id === id ? { ...row, exampleData: value } : row))
    );
  };

  const handleRenderedInputChange = (id, value) => {
    setRows(
      rows.map((row) => (row.id === id ? { ...row, renderedData: value } : row))
    );
  };

  const handleInputTypeChange = (id, value) => {
    setRows(
      rows.map((row) =>
        row.id === id
          ? {
              ...row,
              inputType: value,
              renderedData:
                value === "boolean"
                  ? "true"
                  : value === "integer" || value === "id"
                  ? 1
                  : value === "string" || value === "json"
                  ? "Example"
                  : value === "email"
                  ? "info@example.com"
                  : value === "phone"
                  ? "5555555555"
                  : value === "date"
                  ? new Date()
                  : row.renderedData,
            }
          : row
      )
    );
  };

  const renderInput = (row) => {
    switch (row.inputType) {
      case "id":
        return (
          <input
            type="number"
            value={row.renderedData || "1"}
            onChange={(e) =>
              handleRenderedInputChange(row.id, e.target.value || "1")
            }
            className="form-control"
          />
        );
      case "integer":
        return (
          <input
            type="number"
            value={row.renderedData || "1"}
            onChange={(e) =>
              handleRenderedInputChange(row.id, e.target.value || "1")
            }
            className="form-control"
          />
        );
      case "string":
        return (
          <input
            type="text"
            value={row.renderedData || "example"}
            onChange={(e) =>
              handleRenderedInputChange(row.id, e.target.value || "example")
            }
            className="form-control"
          />
        );
      case "json":
        return (
          <input
            type="text"
            value={row.renderedData || "example"}
            onChange={(e) =>
              handleRenderedInputChange(row.id, e.target.value || "example")
            }
            className="form-control"
          />
        );
      case "email":
        return (
          <input
            type="email"
            value={row.renderedData || "info@example.com"}
            onChange={(e) =>
              handleRenderedInputChange(
                row.id,
                e.target.value || "info@example.com"
              )
            }
            className="form-control"
          />
        );
      case "phone":
        return (
          <input
            type="tel"
            value={row.renderedData || "5555555555"}
            onChange={(e) =>
              handleRenderedInputChange(row.id, e.target.value || "5555555555")
            }
            className="form-control"
          />
        );
      case "boolean":
        return (
          <div className="d-flex gap-2">
            <input
              className="form-check-input"
              type="radio"
              name={`boolean-${row.id}`}
              value="true"
              checked={row.renderedData === "true"}
              onChange={() => handleRenderedInputChange(row.id, "true")}
            />
            <label className="form-check-label" htmlFor={`boolean-${row.id}`}>
              True
            </label>

            <input
              className="form-check-input"
              type="radio"
              name={`boolean-${row.id}`}
              value="false"
              checked={row.renderedData === "false"}
              onChange={() => handleRenderedInputChange(row.id, "false")}
            />
            <label className="form-check-label" htmlFor={`boolean-${row.id}`}>
              False
            </label>
          </div>
        );
      case "date":
        return (
          <DatePicker
            locale="tr"
            className="form-control"
            selected={row.renderedData || new Date()}
            onChange={(date) => handleRenderedInputChange(row.id, date)}
            required
          />
        );
      default:
        return (
          <input
            type="text"
            value={row.renderedData}
            onChange={(e) => handleRenderedInputChange(row.id, e.target.value)}
            className="form-control"
          />
        );
    }
  };

  return (
    <div>
      {/* <TusUploader /> */}

      {!columnTypesString && context && context?.me?.role?.id === 1 && (
        <div className="mb-3">
          <span className="mb-1 d-block">{context?.t?.customer}</span>
          {context.allCustomerSelect ? (
            <Select
              options={[
                {
                  value: null,
                  label: context?.t?.all,
                  user_id: null,
                },
                ...context.allCustomerSelect,
              ]}
              placeholder=""
              classNamePrefix="react-select"
              noOptionsMessage={() => context?.t?.no_option}
              value={customerValue}
              onChange={(e) => setCustomerValue(e)}
            />
          ) : (
            <div className="spinner-border spinner-border-sm text-theme"></div>
          )}
        </div>
      )}

      {!pageImport && (
        <div className="mb-3">
          <span className="mb-1 d-block">{context?.t?.data_name}</span>
          <input
            type="text"
            className="form-control"
            value={dataName}
            onChange={(e) =>
              setDataName(
                e.target.value.replace(/[^a-zA-Z0-9]/g, "-").replace(/-+/g, "-")
              )
            }
          />
        </div>
      )}

      <div className="mb-3">
        {/* <span className="mb-1 d-block">{context?.t?.file}</span> */}
        <div className="d-flex gap-2">
          <div className="flex-1">
            <input
              id={`import_file_csv${dataName ? "_" + dataName : ""}`}
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              className="d-none"
            />
            <label
              htmlFor={`import_file_csv${dataName ? "_" + dataName : ""}`}
              onClick={() => setCreateFileShow(false)}
              className="btn btn-outline-theme w-100 h-100 d-flex align-items-center justify-content-center pointer"
            >
              {context?.t?.choose_csv_file}
            </label>
          </div>

          <button
            className="btn btn-outline-theme flex-1"
            onClick={() => setCreateFileShow(!createFileShow)}
          >
            {context?.t?.dosya_olustur}/
            {createFileShow ? context?.t?.hide : context?.t?.show}
          </button>
        </div>
        {!columnTypesString && (
          <div className="d-flex gap-1 flex-wrap mt-2">
            <span className="me-2">{context?.t?.separators}:</span>
            {separators.map((item, index) => (
              <span
                className="px-1"
                style={{
                  background: "rgb(255 255 255 / 10%)",
                }}
                key={index}
              >
                {item}
              </span>
            ))}
          </div>
        )}
      </div>

      {fileImportError && <p style={{ color: "red" }}>{fileImportError}</p>}

      {!createFileShow && columns.length !== 0 && (
        <div className="mb-3 border-top pt-3 mt-4">
          <span className="mb-1 d-block">
            <span className="d-block">
              {context?.t?.file_name}: {file.name}
            </span>
          </span>

          {!columnTypesString && (
            <div className="table-responsive pb-5" style={{ minHeight: 300 }}>
              <table className="align-middle table border">
                <tbody>
                  {columns.map((column, index) => (
                    <tr key={index}>
                      <td>{column}</td>
                      <td className="border-start" width={200}>
                        <Select
                          options={[
                            { value: "id", label: context?.t?.id },
                            { value: "integer", label: context?.t?.integer },
                            // { value: "integer", label: context?.t?.ondalik_sayi },
                            { value: "string", label: context?.t?.string },
                            { value: "date", label: context?.t?.date },
                            { value: "json", label: context?.t?.json },
                            { value: "email", label: context?.t?.email },
                            { value: "phone", label: context?.t?.phone },
                            {
                              value: "boolean",
                              label: context?.t?.boolean,
                            },
                          ]}
                          placeholder={context?.t?.data_type}
                          classNamePrefix="react-select"
                          noOptionsMessage={() => context?.t?.no_option}
                          onChange={(selectedOption) =>
                            handleColumnTypeChange(column, selectedOption)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {createFileShow && (
        <div className="mb-3 border-top pt-3 mt-4">
          <table className="align-middle table border">
            <tbody>
              <tr>
                <td>#</td>
                <td>{context?.t?.title}</td>
                <td>{context?.t?.data_type}</td>
                <td>{context?.t?.example_data}</td>
                <td></td>
              </tr>
              {rows.map((row, index) => (
                <tr key={row.id}>
                  <td width={30}>{index + 1}</td>
                  <td className="border-start">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={context?.t?.title}
                      value={row.exampleData}
                      onChange={(e) =>
                        handleExampleInputChange(row.id, e.target.value)
                      }
                    />
                  </td>
                  <td className="border-start">
                    <Select
                      options={[
                        { value: "id", label: context?.t?.id },
                        { value: "integer", label: context?.t?.integer },
                        { value: "string", label: context?.t?.string },
                        { value: "date", label: context?.t?.date },
                        { value: "json", label: context?.t?.json },
                        { value: "email", label: context?.t?.email },
                        { value: "phone", label: context?.t?.phone },
                        { value: "boolean", label: context?.t?.boolean },
                      ]}
                      placeholder={context?.t?.data_type}
                      classNamePrefix="react-select"
                      value={
                        row.inputType
                          ? {
                              value: row.inputType,
                              label: [
                                { value: "id", label: context?.t?.id },
                                {
                                  value: "integer",
                                  label: context?.t?.integer,
                                },
                                { value: "string", label: context?.t?.string },
                                { value: "date", label: context?.t?.date },
                                { value: "json", label: context?.t?.json },
                                { value: "email", label: context?.t?.email },
                                { value: "phone", label: context?.t?.phone },
                                {
                                  value: "boolean",
                                  label: context?.t?.boolean,
                                },
                              ].find((item) => item.value === row.inputType)
                                .label,
                            }
                          : null
                      }
                      onChange={(selected) =>
                        handleInputTypeChange(row.id, selected.value)
                      }
                      noOptionsMessage={() => context?.t?.no_option}
                    />
                  </td>
                  <td className="border-start">{renderInput(row)}</td>
                  <td width={90} className="text-end border-start">
                    <button
                      type="button"
                      className="btn btn-danger w-100"
                      onClick={() => handleRemoveRow(row.id)}
                      disabled={index > 0 ? false : true}
                    >
                      {context?.t?.remove}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            type="button"
            className="btn btn-outline-theme mt-1"
            onClick={handleAddRow}
          >
            {context?.t?.addColumn} +
          </button>
        </div>
      )}

      {columnTypes && Object.values(columnTypes).includes("date") && (
        <p
          className="mb-0"
          dangerouslySetInnerHTML={{
            __html: context?.t?.warning_for_date,
          }}
        />
      )}

      <p className="mt-2 fw-bold text-white">
        {context?.t?.yukle_dosya_sutun_uyari}
      </p>

      <button
        type="button"
        className="btn btn-theme text-black fw-bold w-100 mb-3"
        onClick={handleSubmit}
        disabled={loader}
      >
        {context?.t?.save}
      </button>

      {loader && (
        <div
          className="position-fixed top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center"
          style={{ zIndex: 99, background: "rgb(13 20 18 / 85%)" }}
        >
          <div className="spinner-border text-theme"></div>
        </div>
      )}
    </div>
  );
}
