import React, { useState } from "react";

const PasswordInput = ({
  value,
  name,
  onChange,
  extraClass,
  required = true,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`position-relative`}>
      <input
        type={showPassword ? "text" : "password"}
        className={`form-control ${extraClass}`}
        style={{ paddingRight: 40 }}
        value={value}
        name={name}
        onChange={onChange}
        required={required}
      />
      <span
        className="position-absolute end-0 px-2 py-2"
        onClick={handleTogglePassword}
        style={{ top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
      >
        {showPassword ? (
          <i className="fas fa-lg fa-fw fa-eye-slash"></i>
        ) : (
          <i className="fas fa-lg fa-fw fa-eye"></i>
        )}
      </span>
    </div>
  );
};

export default PasswordInput;
