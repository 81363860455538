import React, { useContext, useEffect, useRef, useState } from "react";
import { AppSettings } from "../config/app-settings";

export default function RoleInfo() {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);
  const context = useContext(AppSettings);

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <span className="mb-1 d-block">{context?.t?.user_role}</span>
        <div
          className="info-icon"
          onClick={() => setShowPopover(!showPopover)}
          style={{ position: "relative", cursor: "pointer" }}
        >
          <i className="fas fa-info-circle"></i>
          {showPopover && (
            <div className="popover-content" ref={popoverRef}>
              <ol>
                {context?.me?.role?.id === 1 ||
                  (context?.me?.role?.id === 2 && (
                    <li>
                      <span>{context?.t?.institution_official}:</span>
                      <span>{context?.t?.all}</span>
                    </li>
                  ))}
                <li>
                  <span>{context?.t?.public_relations}:</span>
                  <span>
                    {context?.t?.hane_ve_birey_analizi} <br />
                    {context?.t?.halkla_iletisim_yonetimi} <br />
                    {context?.t?.hazir_raporlar}
                  </span>
                </li>
                <li>
                  <span>{context?.t?.strateji_haritasi}:</span>
                  <span>
                    {context?.t?.strateji_haritasi} <br />
                    {context?.t?.kentmetre_haritasi}
                  </span>
                </li>
                <li>
                  <span>{context?.t?.halkla_iliskiler_gozlemci}:</span>
                  <span>{context?.t?.info_hane_ve_birey_analizi}</span>
                </li>
                <li>
                  <span>{context?.t?.gozlemci}:</span>
                  <span>{context?.t?.arastirma_raporu}</span>
                </li>
                <li>
                  <span>{context?.t?.veri_girisi}:</span>
                  <span>{context?.t?.veri_girisi}</span>
                </li>
              </ol>
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .popover-content {
          position: absolute;
          top: 20px;
          right: 0;
          z-index: 10;
          width: auto;
          padding: 10px;
          border: 1px solid #4f545b;
          background-color: #131b24;
          box-shadow: 0 2px 10px rgb(255 255 255 / 10%);
        }
        .popover-content ol {
          white-space: nowrap;
          margin-bottom: 0;
          padding-left: 0px;
        }
        .popover-content li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
        }
        .popover-content li + li {
          padding-top: 5px;
          margin-top: 5px;
          border-top: 1px solid #4f545b;
        }
        .popover-content li span:nth-child(2) {
          text-align: right;
        }
      `}</style>
    </>
  );
}
