import React, { useContext, useEffect, useState } from "react";
import toastMessage from "../../components/ToastMessage";
import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

export default function DevamEdenAnketler() {
  const context = useContext(AppSettings);

  // Atanmış Anketleri Getir
  const [assigmentData, setAssigmentData] = useState(null);

  async function handleGetReports() {
    setAssigmentData(null);

    try {
      const response = await apiClient.get(`/street-report`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.length !== 0) {
        setAssigmentData(response.data.sort((a, b) => a.id - b.id));
      } else {
        setAssigmentData([]);
      }
    } catch (error) {
      setAssigmentData(null);

      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error?.response?.data?.message, "error");
      }
    }
  }

  useEffect(() => {
    handleGetReports();
  }, []);

  // Status
  async function handleStatus(id) {
    try {
      const response = await apiClient.put(
        "/street-report/" + id,
        {
          status: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        toastMessage(context?.t?.anket_tamamlandi, "success");
        handleGetReports();
      }
    } catch (error) {
      console.error("Error", error);
    }
  }

  // Download CSV
  async function handleDownload(name, id) {
    try {
      const response = await apiClient.get(
        `/street-report-reply/${id}/export`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        const link = document.createElement("a");
        link.href = response.data.url;
        link.setAttribute("download", name + "_report.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();

        toastMessage(context?.t?.rapor_basariyla_olusturuldu, "success");
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error?.response?.data?.message, "error");
      }
    }
  }

  return (
    <div className="mb-5 pb-5">
      <h4 className="mb-4">{context?.t?.devam_eden_anketler}</h4>

      {assigmentData ? (
        assigmentData.length !== 0 ? (
          <div className="dataTables_wrapper dt-bootstrap5">
            <div className="dataTables_scroll">
              <div className="dataTables_scrollBody table-responsive">
                <table className="table text-nowrap w-100 dataTable">
                  <thead>
                    <tr>
                      <th className="px-2" style={{ width: 50 }}>
                        #
                      </th>
                      <th className="px-2" style={{ width: 100 }}>
                        {context?.t?.anket_indir}
                      </th>
                      <th className="px-2" style={{ width: 100 }}>
                        {context?.t?.status}
                      </th>
                      <th className="px-2">{context?.t?.anket_adi}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {assigmentData
                      .sort((a, b) => a.status - b.status)
                      .filter((item) => item.customer_id !== null)
                      .map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            background: item?.status
                              ? "rgb(60 210 165 / 16%)"
                              : "",
                          }}
                        >
                          <td>{index + 1}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-outline-theme w-100"
                              onClick={() =>
                                handleDownload(item?.name, item?.id)
                              }
                            >
                              {context?.t?.indir}
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              className={`btn w-100 btn-outline-theme`}
                              onClick={() =>
                                !item?.status && handleStatus(item.id)
                              }
                            >
                              {item?.status
                                ? context?.t?.tamamlandi
                                : context?.t?.tamamla}
                            </button>
                          </td>
                          <td>{item.name}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <span>{context?.t?.devam_eden_bir_anket_yoktur}</span>
        )
      ) : (
        <div className="spinner-border text-theme"></div>
      )}
    </div>
  );
}
