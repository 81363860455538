import React, { useContext, useEffect, useState } from "react";
import apiClient from "../ApiClient";
import { AppSettings } from "../config/app-settings";

const removeHtmlTag = (str) => {
  if (str.startsWith("```html")) {
    return str.slice(7); // "```html" kısmını kaldırır
  }
  return str;
};

export default function AIComponent({ data }) {
  const context = useContext(AppSettings);

  const [aiAnswer, setAiAnswer] = useState("");
  const [aiPrompt, setAiPrompt] = useState("");

  const [prompShow, setPrompShow] = useState(false);

  async function handleAI(type) {
    setAiAnswer("loading");

    try {
      const response = await apiClient.customPost(
        `/ai`,
        {
          prompt: aiPrompt,
          id: `dynamic-${data.id}`,
          retry: type,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setAiAnswer(response);
    } catch (error) {
      setAiAnswer("");
      console.error(error);
    }
  }

  useEffect(() => {
    setAiAnswer("");

    if (context?.me?.role?.id !== 1) {
      setAiAnswer("loading");
    }

    if (data) {
      setAiPrompt(`Grafik/Tablo Adı = ${data?.title}
${
  typeof data?.id === "string" &&
  data?.id.split("-")[2] &&
  typeof data?.id === "string" &&
  data?.id.split("-")[2] !== "all"
    ? `Veri İli = ${typeof data?.id === "string" && data?.id.split("-")[2]}`
    : ""
}
${
  typeof data?.id === "string" && data?.id.split("-")[3]
    ? `Veri İlçesi = ${typeof data?.id === "string" && data?.id.split("-")[3]}`
    : ""
}
${
  typeof data?.id === "string" && data?.id.split("-")[4]
    ? `Veri Mahallesi = ${
        typeof data?.id === "string" && data?.id.split("-")[4]
      }`
    : ""
}
  Grafik/Tablo Adından ve yukarıda belirtilen, verinin ait olduğu varsa il veya il – ilçe veya il-ilçe-mahalle adından yola çıkarak, aşağıda verilen parametreleri ve değerleri göz önüne alarak akademik makalelerden ve güncel haberlerden alıntılar yaparak analiz etmeni ve bir “Analiz Metni” oluşturmanı istiyorum. Ek olarak Grafik/Tablo adında ve verilerde geçen konu, aşağıda belirttiğim şartlardan biri yada birkaçı ile ilgili ise bu konularda da analizler yaparak oluşturacağın Analiz Metni’ne eklemeni istiyorum.

  Grafik/Tablo Adı hangi dilde ise Analiz Metni’ni o dilde yazmanı istiyorum. Eğer Grafik/Tablo adı herhangi bir dilde kelimeler içermiyorsa Analiz Metni’ni Türkçe dilinde yazmanı istiyorum.
  Eğer; veriler dünya genelinde ülkeler, şehirler, eyaletler, ilçeler, mahalleler veya sokaklar ile alakalıysa, analize verilerde geçen yerler ile ilgili en son haberler, istatistikler ve gelişmelere de vurgular yapmanı istiyorum.
  Eğer; veriler özel ve kamu sektörlerinin hizmetleri yada genel sektörel veriler ile alakalıysa, analize verilerde geçen yerler ile ilgili en son haberler, istatistikler ve gelişmelere de vurgular yapmanı istiyorum.
  Eğer; veriler satışı yapılan ürün yada ürünler ile alakalıysa, analize verilerde geçen yerler ile ilgili en son haberler, istatistikler ve gelişmelere de vurgular yapmanı istiyorum.
  Eğer; veriler demografik, sosyolojik, eğitim, turizm, spor, göç, hemşerilik durumu konular ile alakalıysa, analize verilerde geçen yerler ile ilgili en son haberler, istatistikler ve gelişmelere de vurgular yapmanı istiyorum.
  Eğer; veriler siyasi seçimler, seçmenler ile alakalıysa, analize verilerde geçen yerler ile ilgili en son haberler, istatistikler ve gelişmelere de vurgular yapmanı istiyorum.
  Eğer; veriler yukarıda bahsettiğim konuların dışında farklı konular ile alakalıysa, analize verilerde geçen yerler ile ilgili en son haberler, istatistikler ve gelişmelere de vurgular yapmanı istiyorum.
  Oluşturacağın analiz 500 kelimeden az olmasın.
  Veri bir il veya bir ilin ilçesi veya da bir ilin bir ilçesinin bir mahallesi ile alakalıysa o ilin güncel sosyokültürel durumu hakkında da bilgiler vermeni istiyorum.
  Grafik/Tablo adı içerisinde nitel yada nicel bir analize ait betimleme bulunuyorsa, adı geçen analiz ile ilgili de çıkarımlar yapmanı istiyorum. Metni html etiketleri ile süsleyebilirsin, eğer tablo ekleyeceksen <table> kısmına "table" class ekle.
  Veriler ve Parametreler şunlardır:
  ${JSON.stringify(data?.data)}`);
    }
  }, [data, context?.me?.role?.id]);

  useEffect(() => {
    if (aiPrompt) {
      if (context?.me?.role?.id !== 1) {
        handleAI(false);
      }
    }
  }, [aiPrompt, context?.me?.role?.id]);

  return (
    <div>
      {context?.me?.role?.id === 1 && (
        <div className="mb-3">
          <div
            className="d-flex align-items-center justify-content-between pointer"
            onClick={() => setPrompShow(!prompShow)}
          >
            <span className="d-block w-100 mb-1">Prompt</span>
            {prompShow ? (
              <i className="fas fa-angle-down"></i>
            ) : (
              <i className="fas fa-angle-up"></i>
            )}
          </div>

          {prompShow && (
            <textarea
              style={{ height: 500 }}
              className="form-control"
              value={aiPrompt}
              onChange={(e) => setAiPrompt(e.target.value)}
            />
          )}
        </div>
      )}

      {aiAnswer === "loading" ? (
        <div className="spinner-border text-theme"></div>
      ) : (
        aiAnswer && (
          <p
            className="mb-3"
            dangerouslySetInnerHTML={{
              __html: removeHtmlTag(
                aiAnswer?.choices && aiAnswer?.choices[0]
                  ? aiAnswer?.choices[0]?.message?.content
                  : ""
              ),
            }}
          />
        )
      )}

      {context?.me?.role?.id === 1 && (
        <div className="d-flex gap-2 mt-2">
          <button
            className="btn btn-outline-theme w-100"
            onClick={() => handleAI(true)}
          >
            Yeni bir Sonuç Getir
          </button>
          <button
            className="btn btn-outline-theme w-100"
            onClick={() => handleAI(false)}
          >
            Şimdiki Sonucu Getir
          </button>
        </div>
      )}
    </div>
  );
}
