import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { slideToggle } from "./../../composables/slideToggle.js";
import { AppSettings } from "../../config/app-settings.js";

function hexToRgba(hex, opacity) {
  // Hex kodundan başındaki # işaretini kaldır
  hex = hex.replace("#", "");

  // R, G, B değerlerini al
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // Opacity (saydamlık) değerini 0 ile 1 arasında normalize et
  var alpha = opacity || 1;

  // rgba formatında geri döndür
  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}

function Header() {
  const context = useContext(AppSettings);

  // const notificationData = [
  //   {
  //     icon: "bi bi-bag text-theme",
  //     title: "NEW ORDER RECEIVED ($1,299)",
  //     time: "JUST NOW",
  //   },
  //   {
  //     icon: "bi bi-person-circle text-theme",
  //     title: "3 NEW ACCOUNT CREATED",
  //     time: "2 MINUTES AGO",
  //   },
  //   {
  //     icon: "bi bi-gear text-theme",
  //     title: "SETUP COMPLETED",
  //     time: "3 MINUTES AGO",
  //   },
  //   {
  //     icon: "bi bi-grid text-theme",
  //     title: "WIDGET INSTALLATION DONE",
  //     time: "5 MINUTES AGO",
  //   },
  //   {
  //     icon: "bi bi-credit-card text-theme",
  //     title: "PAYMENT METHOD ENABLED",
  //     time: "10 MINUTES AGO",
  //   },
  // ];

  // const toggleAppSidebarDesktop = () => {
  //   var elm = document.querySelector(".app");
  //   if (elm) {
  //     if (
  //       !(
  //         elm.classList.contains("app-with-top-nav") &&
  //         elm.classList.contains("app-without-sidebar")
  //       )
  //     ) {
  //       elm.classList.toggle("app-sidebar-collapsed");
  //     }
  //   }
  // };

  const toggleAppSidebarMobile = () => {
    var elm = document.querySelector(".app");
    if (elm) {
      if (
        !(
          elm.classList.contains("app-with-top-nav") &&
          elm.classList.contains("app-without-sidebar")
        )
      ) {
        elm.classList.toggle("app-sidebar-mobile-toggled");
      } else {
        slideToggle(document.querySelector(".app-top-nav"));
      }
    }
  };

  // const toggleAppHeaderSearch = () => {
  //   var elm = document.querySelector(".app");
  //   elm.classList.toggle("app-header-menu-search-toggled");
  // };

  const [customerLogo, setCustomerLogo] = useState(null);

  async function handleGetCustomer(id) {
    const customerData = await context.getCustomer(id);
    const customerTheme = customerData?.settings?.theme.split("|");

    setCustomerLogo(customerData?.settings?.logo);

    if (customerTheme && customerTheme[0] !== "null") {
      document.getElementById("header").style.background = hexToRgba(
        customerTheme[0],
        0.8
      );
      document.getElementById("sidebar").style.background = hexToRgba(
        customerTheme[0],
        0.4
      );
      document.querySelector("body").style.background = hexToRgba(
        customerTheme[0],
        0.4
      );
    } else {
      if (context.me?.customer?.settings?.theme.split("|")[0] === "null") {
        document.getElementById("header").removeAttribute("style");
        document.getElementById("sidebar").removeAttribute("style");
        document.querySelector("body").removeAttribute("style");
      } else {
        document.getElementById("header").style.background = hexToRgba(
          context.me?.customer?.settings?.theme.split("|")[0],
          0.8
        );
        document.getElementById("sidebar").style.background = hexToRgba(
          context.me?.customer?.settings?.theme.split("|")[0],
          0.4
        );
        document.querySelector("body").style.background = hexToRgba(
          context.me?.customer?.settings?.theme.split("|")[0],
          0.4
        );
      }
    }

    if (customerTheme[1] !== "null") {
      document.querySelector(
        ".app"
      ).style.background = `linear-gradient(180deg, ${customerTheme[1]} -190%, rgb(0, 7, 23) 90%)`;
    } else {
      if (context.me?.customer?.settings?.theme.split("|")[1] === "null") {
        document.querySelector(".app").removeAttribute("style");
      } else {
        document.querySelector(
          ".app"
        ).style.background = `linear-gradient(180deg, ${
          context.me?.customer?.settings?.theme.split("|")[1]
        } -190%, rgb(0, 7, 23) 90%)`;
      }
    }
  }

  useEffect(() => {
    if (context?.activeCustomer) {
      handleGetCustomer(context?.activeCustomer);
    }
  }, [context]);

  return (
    <div id="header" className="app-header">
      <div className="brand">
        <div className="mobile-toggler">
          <button
            type="button"
            className="menu-toggler"
            onClick={toggleAppSidebarMobile}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </button>
        </div>

        <div className="brand-logo justify-content-between w-100">
          {customerLogo ? (
            <img src={customerLogo} alt="Logo" height={45} />
          ) : context && context?.me?.customer?.settings?.logo ? (
            <img
              src={context?.me?.customer?.settings?.logo}
              alt={context?.me?.customer?.customer?.name}
              height={45}
            />
          ) : window.location.host.includes("datakokpit") ? (
            <div className="brand-text">
              DATA
              <div className="card">
                <span className="text-theme">KOKPIT</span>

                <div className="card-arrow">
                  <div className="card-arrow-top-left"></div>
                  <div className="card-arrow-top-right"></div>
                  <div className="card-arrow-bottom-left"></div>
                  <div className="card-arrow-bottom-right"></div>
                </div>
              </div>
            </div>
          ) : (
            <div className="brand-text">
              STRATEJİ
              <div className="card">
                <span style={{ color: "#3cd2a5" }}>MERKEZİ</span>

                <div className="card-arrow">
                  <div className="card-arrow-top-left"></div>
                  <div className="card-arrow-top-right"></div>
                  <div className="card-arrow-bottom-left"></div>
                  <div className="card-arrow-bottom-right"></div>
                </div>
              </div>
            </div>
          )}

          {/* <svg
            className="live-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 100"
          >
            <polyline
              fill="none"
              strokeWidth="3"
              stroke={
                localStorage.getItem("theme")
                  ? localStorage.getItem("theme").split("|")[0]
                  : "#3cd2a5"
              }
              points="2.4,58.7 70.8,58.7 76.1,46.2 81.1,58.7 89.9,58.7 93.8,66.5 102.8,22.7 110.6,78.7 115.3,58.7 126.4,58.7 134.4,54.7 142.4,58.7 197.8,58.7 "
            />
          </svg> */}
        </div>
      </div>

      {/* <div className="desktop-toggler">
          <button
            type="button"
            className="menu-toggler"
            onClick={toggleAppSidebarDesktop}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </button>
        </div> */}

      {/* <div className="menu"> */}
      {/* <div className="menu-item dropdown">
					<a href="#/" onClick={toggleAppHeaderSearch} className="menu-link">
						<div className="menu-icon"><i className="bi bi-search nav-icon"></i></div>
					</a>
				</div>
				<div className="menu-item dropdown dropdown-mobile-full">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						<div className="menu-icon"><i className="bi bi-grid-3x3-gap nav-icon"></i></div>
					</a>
					<div className="dropdown-menu fade dropdown-menu-end w-300px text-center p-0 mt-1">
						<div className="row row-grid gx-0">
							<div className="col-4">
								<Link to="/email/inbox" className="dropdown-item text-decoration-none p-3 bg-none">
									<div className="position-relative">
										<i className="bi bi-circle-fill position-absolute text-theme top-0 mt-n2 me-n2 fs-6px d-block text-center w-100"></i>
										<i className="bi bi-envelope h2 opacity-5 d-block my-1"></i>
									</div>
									<div className="fw-500 fs-10px text-inverse">INBOX</div>
								</Link>
							</div>
							<div className="col-4">
								<Link to="/pos/customer-order" className="dropdown-item text-decoration-none p-3 bg-none">
									<div><i className="bi bi-hdd-network h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-inverse">POS SYSTEM</div>
								</Link>
							</div>
							<div className="col-4">
								<Link to="/calendar" className="dropdown-item text-decoration-none p-3 bg-none">
									<div><i className="bi bi-calendar4 h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-inverse">CALENDAR</div>
								</Link>
							</div>
						</div>
						<div className="row row-grid gx-0">
							<div className="col-4">
								<Link to="/helper" className="dropdown-item text-decoration-none p-3 bg-none">
									<div><i className="bi bi-terminal h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-inverse">HELPER</div>
								</Link>
							</div>
							<div className="col-4">
								<Link to="/settings" className="dropdown-item text-decoration-none p-3 bg-none">
									<div className="position-relative">
										<i className="bi bi-circle-fill position-absolute text-theme top-0 mt-n2 me-n2 fs-6px d-block text-center w-100"></i>
										<i className="bi bi-sliders h2 opacity-5 d-block my-1"></i>
									</div>
									<div className="fw-500 fs-10px text-inverse">SETTINGS</div>
								</Link>
							</div>
							<div className="col-4">
								<Link to="/widgets" className="dropdown-item text-decoration-none p-3 bg-none">
									<div><i className="bi bi-collection-play h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-inverse">WIDGETS</div>
								</Link>
							</div>
						</div>
					</div>
				</div> */}
      {/* <div className="menu-item dropdown dropdown-mobile-full">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						<div className="menu-icon"><i className="bi bi-bell nav-icon"></i></div>
						<div className="menu-badge bg-theme"></div>
					</a>
					<div className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1">
						<h6 className="dropdown-header fs-10px mb-1">NOTIFICATIONS</h6>
						<div className="dropdown-divider mt-1"></div>
						{notificationData.length > 0 ? (notificationData.map((notification, index) => ((
							<a href="#/" key={index} className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold">
								<div className="fs-20px">
									<i className={notification.icon}></i>
								</div>
								<div className="flex-1 flex-wrap ps-3">
									<div className="mb-1 text-inverse">{notification.title}</div>
									<div className="small text-inverse text-opacity-50">{notification.time}</div>
								</div>
								<div className="ps-2 fs-16px">
									<i className="bi bi-chevron-right"></i>
								</div>
							</a>
						)))) : (
							<div className="dropdown-notification-item p-3 text-center">
								No record found
							</div>
						)}
						<hr className="mb-0 mt-2" />
						<div className="py-10px mb-n2 text-center">
							<a href="#/" className="text-decoration-none fw-bold">SEE ALL</a>
						</div>
					</div>
				</div> */}
      {/* <div className="menu-item dropdown dropdown-mobile-full">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            className="menu-link"
          >
            <div className="menu-img online">
              <img
                src="/assets/img/user/profile.jpg"
                alt="Profile"
                height="60"
              />
            </div>
            <div className="menu-text d-sm-block d-none w-170px">
              username@account.com
            </div>
          </a>
          <div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
            <Link
              to="/profile"
              className="dropdown-item d-flex align-items-center"
            >
              PROFILE{" "}
              <i className="bi bi-person-circle ms-auto text-theme fs-16px my-n1"></i>
            </Link>
            <Link
              to="/email/inbox"
              className="dropdown-item d-flex align-items-center"
            >
              INBOX{" "}
              <i className="bi bi-envelope ms-auto text-theme fs-16px my-n1"></i>
            </Link>
            <Link
              to="/calendar"
              className="dropdown-item d-flex align-items-center"
            >
              CALENDAR{" "}
              <i className="bi bi-calendar ms-auto text-theme fs-16px my-n1"></i>
            </Link>
            <Link
              to="/settings"
              className="dropdown-item d-flex align-items-center"
            >
              SETTINGS{" "}
              <i className="bi bi-gear ms-auto text-theme fs-16px my-n1"></i>
            </Link>
            <div className="dropdown-divider"></div>
            <Link
              to="/_pages/login"
              className="dropdown-item d-flex align-items-center"
            >
              LOGOUT{" "}
              <i className="bi bi-toggle-off ms-auto text-theme fs-16px my-n1"></i>
            </Link>
          </div>
        </div> */}
      {/* </div> */}

      {/* <form className="menu-search" method="POST" name="header_search_form">
				<div className="menu-search-container">
					<div className="menu-search-icon"><i className="bi bi-search"></i></div>
					<div className="menu-search-input">
						<input type="text" className="form-control form-control-lg" placeholder="Search menu..." />
					</div>
					<div className="menu-search-icon">
						<a href="#/" onClick={toggleAppHeaderSearch}><i className="bi bi-x-lg"></i></a>
					</div>
				</div>
			</form> */}
    </div>
  );
}

export default Header;
