import React, { useState } from "react";

import PageFilter from "../../components/PageFilter";

export default function IsZekasi() {
  const [cityFilter, setCityFilter] = useState({
    value: 40,
    label: "ISTANBUL",
  });
  const [districtFilter, setDistrictFilter] = useState(null);
  const [neighbourhoodFilter, setNeighbourhoodFilter] = useState("");

  function createGraphic() {
    localStorage.setItem("create-graphic-page", "is-zekasi");
  }

  return (
    <div className="row gap-4 mb-4">
      <div className="row">
        <div className="col">
          <PageFilter
            setCityFilter={setCityFilter}
            districtFilter={districtFilter}
            setDistrictFilter={setDistrictFilter}
            neighbourhoodFilter={neighbourhoodFilter}
            setNeighbourhoodFilter={setNeighbourhoodFilter}
            street={false}
          />
        </div>

        <div className="col w-auto border-start" style={{ flex: "none" }}>
          <button
            type="button"
            className="btn btn-outline-theme w-100"
            data-bs-toggle="modal"
            data-bs-target="#createGraphicModal"
            onClick={() => createGraphic()}
          >
            Grafik Oluştur
          </button>
        </div>
      </div>
    </div>
  );
}
