import React, { useCallback, useContext, useEffect, useState } from "react";

import Select from "react-select";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";

import { Card, CardBody } from "./card/card";
import { AppSettings } from "../config/app-settings";
import apiClient from "../ApiClient";
import moment, { max } from "moment";
import DynamicCard from "./DynamicCard";
import toastMessage from "./ToastMessage";

const center = { lat: 39.1667, lng: 35.6667 };
const defaultZoom = 5.8;
let options;
setTimeout(() => {
  options = {
    center,
    zoom: defaultZoom,
    styles:
      document.querySelector("html").getAttribute("data-bs-theme") === "light"
        ? []
        : [
            {
              featureType: "all",
              elementType: "all",
              stylers: [
                { invert_lightness: true },
                { saturation: -50 },
                { lightness: 40 },
                { gamma: 0.5 },
                {
                  hue: window.location.host.includes("datakokpit")
                    ? "#17248a"
                    : "#3cd2a5",
                },
              ],
            },
          ],
  };
}, 3000);

const getMarkerSize = (mag, depth) => {
  // İki örneği baz alarak bir çarpan belirleyelim
  // Oranı belirlemek için
  const scalingFactor = 2; // Deneme çarpanı, uygun olanı belirlemek için ayarlayın
  const size = scalingFactor * mag;

  // Minimum ve maksimum boyutlar
  return Math.max(3, Math.min(size, 100)); // Boyutları 3 ile 15 arasında sınırlayın
};

const getMarkerStyle = (mag, depth) => {
  let color = "red";
  const size = getMarkerSize(mag, depth);

  if (depth >= 0 && depth <= 5) {
    color = "#FF0000"; // Kırmızı
  } else if (depth > 5 && depth <= 10) {
    color = "#FF6666"; // Açık kırmızı
  } else if (depth > 10 && depth <= 20) {
    color = "#FFA500"; // Turuncu
  } else if (depth > 20 && depth <= 40) {
    color = "#FFFF00"; // Sarı
  } else if (depth > 40 && depth <= 80) {
    color = "#008000"; // Yeşil
  } else if (depth > 80 && depth <= 150) {
    color = "#0000FF"; // Mavi
  }

  return {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: color,
    fillOpacity: 1,
    scale: size,
    strokeColor: color,
    strokeWeight: 1,
  };
};

export default function AfetYonetimi() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  function handleBack() {
    if (context?.me?.role?.id === 4) {
      navigate("/hane-ve-birey-analizi");
    } else if (context?.me?.role?.id === 1) {
      navigate("/durum-analizi", {
        state: { menu: { id: 51 } },
      });
    }
  }

  useEffect(() => {
    // context.setThemePanel(false);
    // context.setAppHeaderNone(true);
    // context.setAppScrollButton(false);
    // context.setAppWeather(false);
    // context.setAppSidebarNone(true);
    // context.setAppFooterFull(true);
    context.setAppContentClass("disaster-page");

    return function cleanUp() {
      // context.setThemePanel(true);
      // context.setAppHeaderNone(false);
      // context.setAppScrollButton(true);
      // context.setAppWeather(true);
      // context.setAppFooterFull(false);
      // context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };
    // eslint-disable-next-line
  }, [context]);

  // Filter
  const [city, setCity] = useState(null);
  const [cityValue, setCityValue] = useState({
    value: null,
    label: context?.t?.all,
  });

  const [district, setDistrict] = useState(null);
  const [districtLoader, setDistrictLoader] = useState(true);
  const [districtValue, setDistrictValue] = useState(null);
  const [allDistrict, setAllDistrict] = useState([]);

  const [neighborhoodValue, setNeighborhoodValue] = useState(null);
  const [neighborhoodLoader, setNeighborhoodLoader] = useState(true);
  const [allNeighborhood, setAllNeighborhood] = useState([]);

  const [streetValue, setStreetValue] = useState(null);
  const [streetLoader, setStreetLoader] = useState(true);
  const [allStreet, setAllStreet] = useState([]);

  const [allBuilding, setAllBuilding] = useState([]);
  const [buildingValue, setBuildingValue] = useState(null);
  const [buildingLoader, setBuildingLoader] = useState(true);

  const [allApartment, setAllApartment] = useState([]);
  const [apartmentValue, setApartmentValue] = useState(null);
  const [apartmentLoader, setApartmentLoader] = useState(true);

  // Filter Reset
  function resetNeighborhood() {
    setNeighborhoodValue(null);
    setNeighborhoodLoader(false);
    setTimeout(() => {
      setNeighborhoodLoader(true);
    }, 500);
  }

  function resetStreet() {
    setStreetValue(null);
    setStreetLoader(false);

    setTimeout(() => {
      setStreetLoader(true);
    }, 500);
  }

  // Filter Select
  function handleCity(e) {
    setCityValue(e);

    setDistrictLoader(false);
    setTimeout(() => {
      setDistrictLoader(true);
    }, 500);
    setDistrictValue(null);
    fetchDistrict(e.value);

    resetNeighborhood();
    fetchNeighborhood(null);

    resetStreet();
    fetchStreet(null);
    fetchBuilding(null);
    fetchApartment(null);
  }

  function handleDistrict(e) {
    setDistrictValue(e);

    resetNeighborhood();
    fetchNeighborhood(e.value && e.value !== "" ? e.value : null);

    resetStreet();
    fetchStreet(null);
    fetchBuilding(null);
    fetchApartment(null);
  }

  function handleNeighborhood(e) {
    setNeighborhoodValue(e);

    resetStreet();
    fetchStreet(e.value && e.value !== "" ? e.label : null);
    fetchBuilding(null);
    fetchApartment(null);
  }

  function handleStreet(e) {
    setStreetValue(e);

    fetchBuilding(e.value && e.value !== "" ? e.label : null);
    fetchApartment(null);
  }

  function handleBuilding(e) {
    setBuildingValue(e);

    fetchApartment(e.value && e.value !== "" ? e.label : null);
  }

  function handleApartment(e) {
    setApartmentValue(e);
  }

  // Fetchs
  async function fetchDistrict(value, name) {
    if (value) {
      const data = await context.getAllDistrict(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: context?.t?.choose_district,
        });

        setAllDistrict(newData);
      }
    }
  }

  async function fetchNeighborhood(value) {
    if (value) {
      const data = await context.getAllNeighborhood(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: context?.t?.choose_neighborhood,
        });

        setAllNeighborhood(newData);
      }
    } else {
      setAllNeighborhood([]);
    }
  }

  async function fetchStreet(value) {
    // if (value) {
    //   try {
    //     const response = await apiClient.customPost(
    //       `/query/preview`,
    //       {
    //         chart: 1,
    //         page_id: 23,
    //         title: "Bina Hane Sayısı",
    //         type: `bireysel-data-customer-${context?.me?.customer?.id}`,
    //         description: "",
    //         customer: null,
    //         status: true,
    //         order: 1,
    //         connection_type: "local",
    // sql: {
    //           column: [{ key: "Adres Sokak", value: "select" }],
    //           condition: [
    //             {
    //               key: "Adres Il",
    //               value: context
    //                 .removeTurkishCharacters(cityValue?.label)
    //                 .toUpperCase(),
    //               operator: "=",
    //               type: "and",
    //             },
    //             {
    //               key: "Adres Ilce",
    //               value: context
    //                 .removeTurkishCharacters(districtValue?.label)
    //                 .toUpperCase(),
    //               operator: "=",
    //               type: "and",
    //             },
    //             {
    //               key: "Adres Mahalle",
    //               value: context.removeTurkishCharacters(value).toUpperCase(),
    //               operator: "=",
    //               type: "and",
    //             },
    //           ],
    //           group: [],
    //         },
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem("token")}`,
    //         },
    //       }
    //     );
    //     if (response.length !== 0) {
    //       const data = response
    //         .map((item) => {
    //           return {
    //             value: context
    //               .removeTurkishCharacters(item["adres sokak"])
    //               .toUpperCase(),
    //             label: context
    //               .removeTurkishCharacters(item["adres sokak"])
    //               .toUpperCase(),
    //           };
    //         })
    //         .sort((a, b) => a.label.localeCompare(b.label));
    //       const newData = data.slice();
    //       newData.unshift({
    //         value: "",
    //         label: context?.t?.choose_street,
    //       });
    //       setAllStreet(newData);
    //     } else {
    //       setAllStreet([]);
    //     }
    //   } catch (error) {
    //     setAllStreet(null);
    //   }
    // } else {
    //   setAllStreet([]);
    // }
  }

  async function fetchBuilding(value) {
    // setAllBuilding([]);
    // setBuildingValue(null);
    // setBuildingLoader(false);
    // setTimeout(async () => {
    //   setBuildingLoader(true);
    //   if (value) {
    //     try {
    //       const response = await apiClient.customPost(
    //         `/query/preview`,
    //         {
    //           chart: 1,
    //           page_id: 23,
    //           title: "Bina Hane Sayısı",
    //           type: `bireysel-data-customer-${context?.me?.customer?.id}`,
    //           description: "",
    //           customer: null,
    //           status: true,
    //           order: 1,
    //           connection_type: "local",
    // sql: {
    //             column: [{ key: "Bina NO", value: "select" }],
    //             condition: [
    //               {
    //                 key: "Adres Il",
    //                 value: context
    //                   .removeTurkishCharacters(cityValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Ilce",
    //                 value: context
    //                   .removeTurkishCharacters(districtValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Mahalle",
    //                 value: context
    //                   .removeTurkishCharacters(neighborhoodValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Sokak",
    //                 value: context.removeTurkishCharacters(value).toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //             ],
    //             group: [],
    //           },
    //         },
    //         {
    //           headers: {
    //             Authorization: `Bearer ${localStorage.getItem("token")}`,
    //           },
    //         }
    //       );
    //       if (response.length !== 0) {
    //         const data = response
    //           .map((item) => {
    //             return {
    //               value: context
    //                 .removeTurkishCharacters(item["bina no"])
    //                 .toUpperCase(),
    //               label: context
    //                 .removeTurkishCharacters(item["bina no"])
    //                 .toUpperCase(),
    //             };
    //           })
    //           .sort((a, b) => a.label.localeCompare(b.label));
    //         const newData = data.slice();
    //         newData.unshift({
    //           value: "",
    //           label: context?.t?.choose_building,
    //         });
    //         setAllBuilding(newData);
    //       } else {
    //         setAllBuilding([]);
    //       }
    //     } catch (error) {
    //       setAllBuilding(null);
    //     }
    //   } else {
    //     setAllBuilding([]);
    //   }
    // }, 500);
  }

  async function fetchApartment(value) {
    // setAllApartment([]);
    // setApartmentValue(null);
    // setApartmentLoader(false);
    // setTimeout(async () => {
    //   setApartmentLoader(true);
    //   if (value) {
    //     try {
    //       const response = await apiClient.customPost(
    //         `/query/preview`,
    //         {
    //           chart: 1,
    //           page_id: 23,
    //           title: "Bina Hane Sayısı",
    //           type: `bireysel-data-customer-${context?.me?.customer?.id}`,
    //           description: "",
    //           customer: null,
    //           status: true,
    //           order: 1,
    //           connection_type: "local",
    // sql: {
    //             column: [{ key: "Daire", value: "select" }],
    //             condition: [
    //               {
    //                 key: "Adres Il",
    //                 value: context
    //                   .removeTurkishCharacters(cityValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Ilce",
    //                 value: context
    //                   .removeTurkishCharacters(districtValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Mahalle",
    //                 value: context
    //                   .removeTurkishCharacters(neighborhoodValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Sokak",
    //                 value: context
    //                   .removeTurkishCharacters(streetValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Bina NO",
    //                 value: context.removeTurkishCharacters(value).toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //             ],
    //             group: [],
    //           },
    //         },
    //         {
    //           headers: {
    //             Authorization: `Bearer ${localStorage.getItem("token")}`,
    //           },
    //         }
    //       );
    //       if (response.length !== 0) {
    //         const data = response
    //           .map((item) => {
    //             return {
    //               value: context
    //                 .removeTurkishCharacters(item["daire"])
    //                 .toUpperCase(),
    //               label: context
    //                 .removeTurkishCharacters(item["daire"])
    //                 .toUpperCase(),
    //             };
    //           })
    //           .sort((a, b) => a.label.localeCompare(b.label));
    //         const newData = data.slice();
    //         newData.unshift({
    //           value: "",
    //           label: context?.t?.choose_apartment,
    //         });
    //         setAllApartment(newData);
    //       } else {
    //         setAllApartment([]);
    //       }
    //     } catch (error) {
    //       setAllApartment(null);
    //     }
    //   } else {
    //     setAllApartment([]);
    //   }
    // }, 500);
  }

  // GET MAP
  const [showTable, setShowTable] = useState(true);

  const [earthquakeData, setEarthquakeData] = useState(null);
  const [selectedEarthquake, setSelectedEarthquake] = useState(null);
  const [filter, setFilter] = useState({
    mag_0_2_9: true,
    mag_3_3_9: true,
    mag_4_4_9: true,
    mag_5_5_9: true,
    mag_6_6_9: true,
    mag_7_7_9: true,
  });

  useEffect(() => {
    fetch("https://api.orhanaydogdu.com.tr/deprem/kandilli/live")
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.result.reduce((acc, current) => {
          // const existing = acc.find((item) => item.title === current.title);
          // if (!existing) {
          acc.push(current);
          // } else {
          //   const existingDate = new Date(existing.date);
          //   const currentDate = new Date(current.date);
          //   if (currentDate > existingDate) {
          //     acc = acc.filter((item) => item.title !== current.title);
          //     acc.push(current);
          //   }
          // }
          return acc;
        }, []);

        setEarthquakeData({ ...data, result: filteredData });
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleFilterChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.checked,
    });
  };

  const filterEarthquakes = () => {
    if (!earthquakeData) return [];

    const firstEarthquake = earthquakeData.result[0];

    const filteredEarthquakes = earthquakeData.result
      .slice(1)
      .filter((earthquake) => {
        const mag = earthquake.mag;

        if (filter.mag_0_2_9 && mag >= 0 && mag <= 2.9) return true;
        if (filter.mag_3_3_9 && mag >= 3 && mag <= 3.9) return true;
        if (filter.mag_4_4_9 && mag >= 4 && mag <= 4.9) return true;
        if (filter.mag_5_5_9 && mag >= 5 && mag <= 5.9) return true;
        if (filter.mag_6_6_9 && mag >= 6 && mag <= 6.9) return true;
        if (filter.mag_7_7_9 && mag >= 7 && mag <= 7.9) return true;

        return false;
      });

    return [firstEarthquake, ...filteredEarthquakes];
  };

  // Tesisler Data
  const [tesislerSelectData, setTesislerSelectData] = useState([]);
  const [tesislerSelectValue, setTesislerSelectValue] = useState({
    value: null,
    label: context?.t?.all,
  });

  const [schoolsData, setSchoolsData] = useState(null);
  const [currentPageSchool, setCurrentPageSchool] = useState(1);
  const [schoolMarker, setSchoolMarker] = useState(null);
  const [schoolMarkerSelected, setSchoolMarkerSelected] = useState(null);
  const [allSchoolMarkers, setAllSchoolMarkers] = useState(null);

  const [tesislerData, setTesislerData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tesisMarker, setTesisMarker] = useState(null);
  const [tesisMarkerSelected, setTesisMarkerSelected] = useState(null);
  const [allTesisMarkers, setAllTesisMarkers] = useState(null);

  const [filterCity, setFilterCity] = useState(null);
  const [filterDistrict, setFilterDistrict] = useState(null);
  const [filterNeighborhood, setFilterNeighborhood] = useState(null);

  const [locationsLoader, setLocationsLoader] = useState(false);

  async function handleLocationAllSchool() {
    setAllSchoolMarkers(null);
    setSchoolMarker(null);
    setSchoolMarkerSelected(null);
    setLocationsLoader(true);

    const payload = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `okullar`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      connection_type: "local",
      sql: {
        column: [
          {
            key: "DevletOzel",
            value: "select",
          },
          {
            key: "OkulTuru",
            value: "select",
          },
          {
            key: "Kademe",
            value: "select",
          },
          {
            key: "Okul",
            value: "select",
          },
          {
            key: "Ogretmen",
            value: "select",
          },
          {
            key: "Ogrenci",
            value: "select",
          },
          {
            key: "Derslik",
            value: "select",
          },
          {
            key: "KutuphaneKitapSayisi",
            value: "select",
          },
          {
            key: "KonferansSalonu",
            value: "select",
          },
          {
            key: "OgretmenBasinaOgrenciSayisi",
            value: "select",
          },
          {
            key: "OgrenciBasinaDerslikSayisi",
            value: "select",
          },
          {
            key: "Adres",
            value: "select",
          },
          {
            key: "Latitude",
            value: "select",
          },
          {
            key: "Longitude",
            value: "select",
          },
        ],
        condition: [
          filterCity
            ? {
                key: "Sehir",
                value: context
                  .removeTurkishCharacters(filterCity.label)
                  .toUpperCase(),
                operator: "like",
                type: "and",
              }
            : null,
          filterDistrict
            ? {
                key: "Ilce",
                value: context
                  .removeTurkishCharacters(filterDistrict.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setLocationsLoader(false);
      setAllSchoolMarkers(response);
    } catch (error) {
      setLocationsLoader(false);
      setAllSchoolMarkers([]);
      console.error(error);
    }
  }

  async function handleLocationAllTesis() {
    setAllTesisMarkers(null);
    setTesisMarker(null);
    setTesisMarkerSelected(null);

    setLocationsLoader(true);

    const payload = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `saglik-kurumlari`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      connection_type: "local",
      sql: {
        column: [
          {
            key: "Şehir",
            value: "select",
          },
          {
            key: "İlçe",
            value: "select",
          },
          {
            key: "Kurum Adı",
            value: "select",
          },
          {
            key: "Kategori",
            value: "select",
          },
          {
            key: "Adres",
            value: "select",
          },
          {
            key: "Enlem",
            value: "select",
          },
          {
            key: "Boylam",
            value: "select",
          },
          {
            key: "Telefon",
            value: "select",
          },
          {
            key: "Web Sitesi",
            value: "select",
          },
          {
            key: "Bölümler",
            value: "select",
          },
        ],
        condition: [
          filterCity
            ? {
                key: "Şehir",
                value: context
                  .removeTurkishCharacters(filterCity.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          filterDistrict
            ? {
                key: "İlçe",
                value: context
                  .removeTurkishCharacters(filterDistrict.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          tesislerSelectValue && tesislerSelectValue.value
            ? {
                key: "Kategori",
                value: tesislerSelectValue.value,
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setLocationsLoader(false);
      setAllTesisMarkers(response);
    } catch (error) {
      setLocationsLoader(false);
      setAllTesisMarkers([]);
      console.error(error);
    }
  }

  async function fetchTesislerSelect(city, district, neighborhood) {
    setTesislerSelectData([]);

    const payload = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `saglik-kurumlari`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      connection_type: "local",
      sql: {
        column: [
          {
            key: "Kategori",
            value: "select",
          },
        ],
        condition: [
          city
            ? {
                key: "Şehir",
                value: context
                  .removeTurkishCharacters(city.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          district
            ? {
                key: "İlçe",
                value: context
                  .removeTurkishCharacters(district.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setTesislerSelectData(
        response.length !== 0
          ? [
              {
                value: null,
                label: context?.t?.all,
              },
              ...response.map((item) => {
                return {
                  value: item["kategori"],
                  label: item["kategori"],
                };
              }),
            ]
          : []
      );
    } catch (error) {
      setTesislerSelectData([]);
      console.error(error);
    }
  }

  // Afet Dosyası
  const [afetGraphic1, setAfetGraphic1] = useState(null);
  const [afetGraphic2, setAfetGraphic2] = useState(null);
  const [afetGraphic3, setAfetGraphic3] = useState(null);
  const [afetGraphic4, setAfetGraphic4] = useState(null);
  const [afetGraphic5, setAfetGraphic5] = useState(null);
  const [afetGraphic6, setAfetGraphic6] = useState(null);
  const [afetGraphic7, setAfetGraphic7] = useState(null);
  const [afetGraphic8, setAfetGraphic8] = useState(null);
  const [afetGraphic9, setAfetGraphic9] = useState(null);

  const fetchAfetFile = useCallback(
    async (city, district, neighborhood) => {
      setAfetGraphic1(null);
      setAfetGraphic2(null);
      setAfetGraphic3(null);
      setAfetGraphic4(null);
      setAfetGraphic5(null);
      setAfetGraphic6(null);
      setAfetGraphic7(null);
      setAfetGraphic8(null);
      setAfetGraphic9(null);

      async function fetchAfetItem(category_1, category_2, graphicNumber) {
        try {
          const response = await apiClient.customPost(
            `/query/preview`,
            {
              chart: 1,
              page_id: 23,
              title: "-",
              type: `Yapı Kullanım İzin Belgesi İstatistikleri`,
              description: "",
              customer: null,
              status: true,
              order: 1,
              connection_type: "local",
              sql: {
                column: category_1,
                condition: [
                  ...category_2,
                  city && city?.value
                    ? {
                        key: "Sehir",
                        value: city?.label,
                        operator: "=",
                        type: "and",
                      }
                    : null,
                  district && district?.value
                    ? {
                        key: "Ilce",
                        value: district?.label,
                        operator: "=",
                        type: "and",
                      }
                    : null,
                ].filter((item) => item !== null),
                group: [],
              },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.length !== 0) {
            // const sortedResponse = response.sort(
            //   (a, b) => a.deger_sum - b.deger_sum
            // );

            // const chartOptionX = sortedResponse.map((item) => {
            //   return item.parametre;
            // });
            // const chartOptionY = sortedResponse.map((item) => {
            //   return item.deger_sum;
            // });

            if (graphicNumber === 1) {
              const years = [...new Set(response.map((item) => item.yıl))].sort(
                (a, b) => a - b
              );

              const parameters = [
                ...new Set(response.map((item) => item["kat-sayisi"])),
              ].sort((a, b) => {
                if (!isNaN(a) && !isNaN(b)) return a - b;
                return a.localeCompare(b, undefined, { numeric: true });
              });

              const formattedArray = years.map((year) => {
                const dataForYear = parameters.map((param) => {
                  const item = response.find(
                    (d) => d.yıl === year && d["kat-sayisi"] === param
                  );
                  return item ? parseInt(item["kat_sayisi_count"]) : 0;
                });

                return {
                  title: {
                    text: `${year}`,
                    textStyle: {
                      color: context?.theme === "light" ? "#000" : "#fff",
                    },
                  },
                  series: [
                    {
                      data: dataForYear,
                      itemStyle: {
                        color:
                          context?.chartColor === "random"
                            ? null
                            : context?.chartColor,
                      },
                    },
                  ],
                };
              });

              setAfetGraphic1([
                {
                  years,
                  parameters,
                  formattedArray,
                },
              ]);
            }
            if (graphicNumber === 2) {
              setAfetGraphic2(
                response.map((item) => {
                  return {
                    value: item.yapi_sahibi_count,
                    name: item["yapi-sahibi"],
                  };
                })
              );
            }
            if (graphicNumber === 3) {
              const years = [...new Set(response.map((item) => item.yıl))].sort(
                (a, b) => a - b
              );

              const parameters = [
                ...new Set(
                  response.map((item) => item["tasiyici-sistem-aciklama-1"])
                ),
              ].sort((a, b) => {
                if (!isNaN(a) && !isNaN(b)) return a - b;
                return a.localeCompare(b, undefined, { numeric: true });
              });

              const formattedArray = years.map((year) => {
                const dataForYear = parameters.map((param) => {
                  const item = response.find(
                    (d) =>
                      d.yıl === year &&
                      d["tasiyici-sistem-aciklama-1"] === param
                  );
                  return item
                    ? parseInt(item["tasiyici_sistem_aciklama_1_count"])
                    : 0;
                });

                return {
                  title: {
                    text: `${year}`,
                    textStyle: {
                      color: context?.theme === "light" ? "#000" : "#fff",
                    },
                  },
                  series: [
                    {
                      data: dataForYear,
                      itemStyle: {
                        color:
                          context?.chartColor === "random"
                            ? null
                            : context?.chartColor,
                      },
                    },
                  ],
                };
              });

              setAfetGraphic3([
                {
                  years,
                  parameters,
                  formattedArray,
                },
              ]);
            }
            if (graphicNumber === 4) {
              const years = [...new Set(response.map((item) => item.yıl))].sort(
                (a, b) => a - b
              );

              const parameters = [
                ...new Set(
                  response.map((item) => item["tasiyici-sistem-aciklama-2"])
                ),
              ].sort((a, b) => {
                if (!isNaN(a) && !isNaN(b)) return a - b;
                return a.localeCompare(b, undefined, { numeric: true });
              });

              const formattedArray = years.map((year) => {
                const dataForYear = parameters.map((param) => {
                  const item = response.find(
                    (d) =>
                      d.yıl === year &&
                      d["tasiyici-sistem-aciklama-2"] === param
                  );
                  return item
                    ? parseInt(item["tasiyici_sistem_aciklama_2_count"])
                    : 0;
                });

                return {
                  title: {
                    text: `${year}`,
                    textStyle: {
                      color: context?.theme === "light" ? "#000" : "#fff",
                    },
                  },
                  series: [
                    {
                      data: dataForYear,
                      itemStyle: {
                        color:
                          context?.chartColor === "random"
                            ? null
                            : context?.chartColor,
                      },
                    },
                  ],
                };
              });

              setAfetGraphic4([
                {
                  years,
                  parameters,
                  formattedArray,
                },
              ]);
            }
            if (graphicNumber === 5) {
              const years = [...new Set(response.map((item) => item.yıl))].sort(
                (a, b) => a - b
              );

              const parameters = [
                ...new Set(
                  response.map((item) => item["tasiyici-sistem-aciklama-3"])
                ),
              ].sort((a, b) => {
                if (!isNaN(a) && !isNaN(b)) return a - b;
                return a.localeCompare(b, undefined, { numeric: true });
              });

              const formattedArray = years.map((year) => {
                const dataForYear = parameters.map((param) => {
                  const item = response.find(
                    (d) =>
                      d.yıl === year &&
                      d["tasiyici-sistem-aciklama-3"] === param
                  );
                  return item
                    ? parseInt(item["tasiyici_sistem_aciklama_3_count"])
                    : 0;
                });

                return {
                  title: {
                    text: `${year}`,
                    textStyle: {
                      color: context?.theme === "light" ? "#000" : "#fff",
                    },
                  },
                  series: [
                    {
                      data: dataForYear,
                      itemStyle: {
                        color:
                          context?.chartColor === "random"
                            ? null
                            : context?.chartColor,
                      },
                    },
                  ],
                };
              });

              setAfetGraphic5([
                {
                  years,
                  parameters,
                  formattedArray,
                },
              ]);
            }
            if (graphicNumber === 6) {
              setAfetGraphic6(
                response.map((item) => {
                  return {
                    value: item.isitma_sistemi_count,
                    name:
                      item["isitma-sistemi"] ||
                      context?.t?.unknown.toUpperCase(),
                  };
                })
              );
            }
            // if (graphicNumber === 7) {
            //   const years = [...new Set(response.map((item) => item.yıl))].sort(
            //     (a, b) => a - b
            //   );

            //   const parameters = [
            //     ...new Set(response.map((item) => item["bina-sayisi"])),
            //   ].sort((a, b) => {
            //     if (!isNaN(a) && !isNaN(b)) return a - b;
            //     return a.localeCompare(b, undefined, { numeric: true });
            //   });

            //   const formattedArray = years.map((year) => {
            //     const dataForYear = parameters.map((param) => {
            //       const item = response.find(
            //         (d) => d.yıl === year && d["bina-sayisi"] === param
            //       );
            //       return item ? parseInt(item["bina_sayisi_count"]) : 0;
            //     });

            //     return {
            //       title: {
            //         text: `${year}`,
            //         textStyle: {
            //           color: context?.theme === "light" ? "#000" : "#fff",
            //         },
            //       },
            //       series: [
            //         {
            //           data: dataForYear,
            //           itemStyle: {
            //             color:
            //               context?.chartColor === "random"
            //                 ? null
            //                 : context?.chartColor,
            //           },
            //         },
            //       ],
            //     };
            //   });

            //   setAfetGraphic7([
            //     {
            //       years,
            //       parameters,
            //       formattedArray,
            //     },
            //   ]);
            // }
            // if (graphicNumber === 8) {
            //   const years = [...new Set(response.map((item) => item.yıl))].sort(
            //     (a, b) => a - b
            //   );

            //   const parameters = [
            //     ...new Set(response.map((item) => item["daire-sayisi"])),
            //   ].sort((a, b) => {
            //     if (!isNaN(a) && !isNaN(b)) return a - b;
            //     return a.localeCompare(b, undefined, { numeric: true });
            //   });

            //   const formattedArray = years.map((year) => {
            //     const dataForYear = parameters.map((param) => {
            //       const item = response.find(
            //         (d) => d.yıl === year && d["daire-sayisi"] === param
            //       );
            //       return item ? parseInt(item["daire_sayisi_count"]) : 0;
            //     });

            //     return {
            //       title: {
            //         text: `${year}`,
            //         textStyle: {
            //           color: context?.theme === "light" ? "#000" : "#fff",
            //         },
            //       },
            //       series: [
            //         {
            //           data: dataForYear,
            //           itemStyle: {
            //             color:
            //               context?.chartColor === "random"
            //                 ? null
            //                 : context?.chartColor,
            //           },
            //         },
            //       ],
            //     };
            //   });

            //   setAfetGraphic8([
            //     {
            //       years,
            //       parameters,
            //       formattedArray,
            //     },
            //   ]);
            // }
            // if (graphicNumber === 9) {
            //   const years = [...new Set(response.map((item) => item.yıl))].sort(
            //     (a, b) => a - b
            //   );

            //   const parameters = [
            //     ...new Set(response.map((item) => item["yuzolcumu-m2"])),
            //   ].sort((a, b) => {
            //     if (!isNaN(a) && !isNaN(b)) return a - b;
            //     return a.localeCompare(b, undefined, { numeric: true });
            //   });

            //   const formattedArray = years.map((year) => {
            //     const dataForYear = parameters.map((param) => {
            //       const item = response.find(
            //         (d) => d.yıl === year && d["yuzolcumu-m2"] === param
            //       );
            //       return item ? parseInt(item["yuzolcumu_m2_count"]) : 0;
            //     });

            //     return {
            //       title: {
            //         text: `${year}`,
            //         textStyle: {
            //           color: context?.theme === "light" ? "#000" : "#fff",
            //         },
            //       },
            //       series: [
            //         {
            //           data: dataForYear,
            //           itemStyle: {
            //             color:
            //               context?.chartColor === "random"
            //                 ? null
            //                 : context?.chartColor,
            //           },
            //         },
            //       ],
            //     };
            //   });

            //   setAfetGraphic9([
            //     {
            //       years,
            //       parameters,
            //       formattedArray,
            //     },
            //   ]);
            // }
          } else {
            if (graphicNumber === 1) {
              setAfetGraphic1([]);
            }
            if (graphicNumber === 2) {
              setAfetGraphic2([]);
            }
            if (graphicNumber === 3) {
              setAfetGraphic3([]);
            }
            if (graphicNumber === 4) {
              setAfetGraphic4([]);
            }
            if (graphicNumber === 5) {
              setAfetGraphic5([]);
            }
            if (graphicNumber === 6) {
              setAfetGraphic6([]);
            }
            if (graphicNumber === 7) {
              setAfetGraphic7([]);
            }
            if (graphicNumber === 8) {
              setAfetGraphic8([]);
            }
            if (graphicNumber === 9) {
              setAfetGraphic9([]);
            }
          }
        } catch (error) {
          console.error(error);
          if (graphicNumber === 1) {
            setAfetGraphic1([]);
          }
          if (graphicNumber === 2) {
            setAfetGraphic2([]);
          }
          if (graphicNumber === 3) {
            setAfetGraphic3([]);
          }
          if (graphicNumber === 4) {
            setAfetGraphic4([]);
          }
          if (graphicNumber === 5) {
            setAfetGraphic5([]);
          }
          if (graphicNumber === 6) {
            setAfetGraphic6([]);
          }
          if (graphicNumber === 7) {
            setAfetGraphic7([]);
          }
          if (graphicNumber === 8) {
            setAfetGraphic8([]);
          }
          if (graphicNumber === 9) {
            setAfetGraphic9([]);
          }
        }
      }

      fetchAfetItem(
        [
          {
            key: "Kat_Sayisi",
            value: "count",
          },
          {
            key: "Kat-Sayisi",
            value: "select",
          },
          {
            key: "Yıl",
            value: "select",
          },
        ],
        [],
        1
      );
      fetchAfetItem(
        [
          {
            key: "Yapi-Sahibi",
            value: "select",
          },
          {
            key: "Yapi_Sahibi",
            value: "count",
          },
        ],
        [
          {
            key: "Yıl",
            value: "2023",
            operator: "=",
            type: "and",
          },
        ],
        2
      );
      fetchAfetItem(
        [
          {
            key: "Tasiyici_Sistem_Aciklama_1",
            value: "count",
          },
          {
            key: "Tasiyici-Sistem-Aciklama-1",
            value: "select",
          },
          {
            key: "Yıl",
            value: "select",
          },
        ],
        [],
        3
      );
      fetchAfetItem(
        [
          {
            key: "Tasiyici_Sistem_Aciklama_2",
            value: "count",
          },
          {
            key: "Tasiyici-Sistem-Aciklama-2",
            value: "select",
          },
          {
            key: "Yıl",
            value: "select",
          },
        ],
        [],
        4
      );
      fetchAfetItem(
        [
          {
            key: "Tasiyici_Sistem_Aciklama_3",
            value: "count",
          },
          {
            key: "Tasiyici-Sistem-Aciklama-3",
            value: "select",
          },
          {
            key: "Yıl",
            value: "select",
          },
        ],
        [],
        5
      );
      fetchAfetItem(
        [
          {
            key: "Isitma-Sistemi",
            value: "select",
          },
          {
            key: "Isitma_Sistemi",
            value: "count",
          },
        ],
        [],
        6
      );
      // fetchAfetItem(
      //   [
      //     {
      //       key: "Bina-Sayisi",
      //       value: "select",
      //     },
      //     {
      //       key: "Bina_Sayisi",
      //       value: "count",
      //     },
      //     {
      //       key: "Yıl",
      //       value: "select",
      //     },
      //   ],
      //   [],
      //   7
      // );
      // fetchAfetItem(
      //   [
      //     {
      //       key: "Daire-Sayisi",
      //       value: "select",
      //     },
      //     {
      //       key: "Daire_Sayisi",
      //       value: "count",
      //     },
      //     {
      //       key: "Yıl",
      //       value: "select",
      //     },
      //   ],
      //   [],
      //   8
      // );
      // fetchAfetItem(
      //   [
      //     {
      //       key: "Yuzolcumu-m2",
      //       value: "select",
      //     },
      //     {
      //       key: "Yuzolcumu_m2",
      //       value: "count",
      //     },
      //     {
      //       key: "Yıl",
      //       value: "select",
      //     },
      //   ],
      //   [],
      //   9
      // );
    },
    [context?.chartColor]
  );

  async function fetchSchools(page = 1, city = null, district = null) {
    setSchoolsData(null);

    setSchoolMarker(null);
    setSchoolMarkerSelected(null);
    setAllSchoolMarkers(null);

    const payload = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `okullar`,
      description: "",
      customer: null,
      status: true,
      paginate: true,
      order: 1,
      connection_type: "local",
      sql: {
        column: [
          {
            key: "DevletOzel",
            value: "select",
          },
          {
            key: "OkulTuru",
            value: "select",
          },
          {
            key: "Kademe",
            value: "select",
          },
          {
            key: "Okul",
            value: "select",
          },
          {
            key: "Ogretmen",
            value: "select",
          },
          {
            key: "Ogrenci",
            value: "select",
          },
          {
            key: "Derslik",
            value: "select",
          },
          {
            key: "KutuphaneKitapSayisi",
            value: "select",
          },
          {
            key: "KonferansSalonu",
            value: "select",
          },
          {
            key: "OgretmenBasinaOgrenciSayisi",
            value: "select",
          },
          {
            key: "OgrenciBasinaDerslikSayisi",
            value: "select",
          },
          {
            key: "Adres",
            value: "select",
          },
          {
            key: "Latitude",
            value: "select",
          },
          {
            key: "Longitude",
            value: "select",
          },
        ],
        condition: [
          city
            ? {
                key: "Sehir",
                value: context
                  .removeTurkishCharacters(city.label)
                  .toUpperCase(),
                operator: "like",
                type: "and",
              }
            : null,
          district
            ? {
                key: "Ilce",
                value: context
                  .removeTurkishCharacters(district.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${page}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setSchoolsData(response);
    } catch (error) {
      setSchoolsData([]);
      console.error(error);
    }
  }

  async function fetchTesisler(
    page = 1,
    city = null,
    district = null,
    neighborhood = null,
    filter = null
  ) {
    setTesislerData(null);

    setTesisMarker(null);
    setTesisMarkerSelected(null);
    setAllTesisMarkers(null);

    const payload = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `saglik-kurumlari`,
      description: "",
      customer: null,
      status: true,
      paginate: true,
      order: 1,
      connection_type: "local",
      sql: {
        column: [
          {
            key: "Şehir",
            value: "select",
          },
          {
            key: "İlçe",
            value: "select",
          },
          {
            key: "Kurum Adı",
            value: "select",
          },
          {
            key: "Kategori",
            value: "select",
          },
          {
            key: "Adres",
            value: "select",
          },
          {
            key: "Enlem",
            value: "select",
          },
          {
            key: "Boylam",
            value: "select",
          },
          {
            key: "Telefon",
            value: "select",
          },
          {
            key: "Web Sitesi",
            value: "select",
          },
          {
            key: "Bölümler",
            value: "select",
          },
        ],
        condition: [
          city
            ? {
                key: "Şehir",
                value: context
                  .removeTurkishCharacters(city.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          district
            ? {
                key: "İlçe",
                value: context
                  .removeTurkishCharacters(district.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          filter && filter.value
            ? {
                key: "Kategori",
                value: filter.value,
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${page}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setTesislerData(response);
    } catch (error) {
      setTesislerData([]);
      console.error(error);
    }
  }

  async function handleData(city, district, neighborhood) {
    if (city && district && neighborhood) {
      setCityValue({
        value: null,
        label: context?.t?.all,
      });
      setDistrictValue(null);
      setNeighborhoodValue(null);
    }

    setFilterCity(city && city.value !== "" ? city : null);
    setFilterDistrict(district && district.value !== "" ? district : null);
    setFilterNeighborhood(
      neighborhood && neighborhood.value !== "" ? neighborhood : null
    );

    setCurrentPage(1);
    fetchTesislerSelect(null, null, null);
    setTesislerSelectValue({
      value: null,
      label: context?.t?.all,
    });

    fetchSchools(
      1,
      city && city.value !== "" ? city : null,
      district && district.value !== "" ? district : null,
      null
    );

    fetchTesisler(
      1,
      city && city.value !== "" ? city : null,
      district && district.value !== "" ? district : null,
      neighborhood && neighborhood.value !== "" ? neighborhood : null,
      null
    );

    fetchAfetFile(
      city && city.value !== "" ? city : null,
      district && district.value !== "" ? district : null,
      neighborhood && neighborhood.value !== "" ? neighborhood : null
    );
  }

  const handlePageChangeSchool = (page) => {
    setCurrentPageSchool(page);
    fetchSchools(page, filterCity, filterDistrict);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchTesisler(
      page,
      filterCity,
      filterDistrict,
      filterNeighborhood,
      tesislerSelectValue
    );
  };

  const handleTesislerSelect = (e) => {
    setCurrentPage(1);

    setTesislerSelectValue(e);
    fetchTesisler(1, filterCity, filterDistrict, filterNeighborhood, e);
    fetchTesislerSelect(filterCity, filterDistrict, filterNeighborhood);
  };

  useEffect(() => {
    fetchSchools();
    fetchTesisler();
    fetchTesislerSelect(null, null, null);
    fetchAfetFile(null, null, null);
  }, [context?.chartColor]);

  // Demografik Analiz
  const currentYear = new Date().getFullYear();

  const [ages, setAges] = useState(null);
  const [dayCount, setDayCount] = useState(1);
  const [ihtiyacTablosu, setIhtiyacTablosu] = useState(null);

  const fetchAges = useCallback(
    async (city, district, neighborhood, street, building, daire) => {
      setAges(null);

      try {
        // API isteği
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: "-",
            type: `demograf`,
            description: "",
            customer: null,
            status: true,
            order: 1,
            connection_type: "local",
            sql: {
              column: [
                {
                  key: "Tarih",
                  value: "toYear",
                },
                {
                  key: "Parametre",
                  value: "select",
                },
                {
                  key: "Deger",
                  value: "sum",
                },
              ],
              condition: [
                city &&
                context.removeTurkishCharacters(city.label).toUpperCase() ===
                  "TUMU"
                  ? {
                      key: "Sehir",
                      value: context
                        .removeTurkishCharacters(city.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                district
                  ? {
                      key: "Ilce",
                      value: context
                        .removeTurkishCharacters(district.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                neighborhood
                  ? {
                      key: "Mahalle",
                      value: context
                        .removeTurkishCharacters(neighborhood.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                // street
                //   ? {
                //       key: "Adres Sokak",
                //       value: context
                //         .removeTurkishCharacters(street.label)
                //         .toUpperCase(),
                //       operator: "=",
                //       type: "and",
                //     }
                //   : null,
                {
                  key: "Alt-Kategori-1",
                  value: "YAS DAGILIMI",
                  operator: "=",
                  type: "and",
                },
              ].filter((item) => item !== null),
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.length !== 0) {
          const maxYear = Math.max(
            ...response.map((item) => item.tarih_toyear)
          );

          setAges({
            data: true,
            total: response
              .filter((item) => item.tarih_toyear === maxYear)
              .reduce((sum, item) => sum + item.deger_sum, 0),
            "0_4": response.find(
              (item) =>
                item.tarih_toyear === maxYear && item.parametre === "0-4"
            ).deger_sum,
            "5_9": response.find(
              (item) =>
                item.tarih_toyear === maxYear && item.parametre === "5-9"
            ).deger_sum,
            "10_65_female": parseInt(
              response
                .filter(
                  (item) =>
                    item.tarih_toyear === maxYear &&
                    item.parametre !== "65+" &&
                    item.parametre !== "0-4" &&
                    item.parametre !== "5-9"
                )
                .reduce((sum, item) => sum + item.deger_sum, 0) * 1.49
            ),
            "10_65_male": parseInt(
              response
                .filter(
                  (item) =>
                    item.tarih_toyear === maxYear &&
                    item.parametre !== "65+" &&
                    item.parametre !== "0-4" &&
                    item.parametre !== "5-9"
                )
                .reduce((sum, item) => sum + item.deger_sum, 0) * 1.51
            ),
            "65_female": parseInt(
              response.find(
                (item) =>
                  item.tarih_toyear === maxYear && item.parametre === "0-4"
              ).deger_sum * 1.49
            ),
            "65_male": parseInt(
              response.find(
                (item) =>
                  item.tarih_toyear === maxYear && item.parametre === "0-4"
              ).deger_sum * 1.51
            ),
            hane: response
              .filter((item) => item.tarih_toyear === maxYear)
              .reduce((sum, item) => sum + item.deger_sum, 0),
          });
        } else {
          setAges({
            data: false,
          });
        }
      } catch (error) {
        console.error(error);
        setAges(null);
      }
    },
    []
  );

  useEffect(() => {
    fetchAges();
  }, [fetchAges]);

  const fetchIhtiyactablosu = useCallback(async () => {
    setIhtiyacTablosu(null);

    try {
      // API isteği
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: `ihtiyac-tablosu`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          connection_type: "local",
          sql: {
            column: [
              {
                key: "0_4",
                value: "select",
              },
              {
                key: "5_9",
                value: "select",
              },
              {
                key: "10_65_kadin",
                value: "select",
              },
              {
                key: "10_65_erkek",
                value: "select",
              },
              {
                key: "65_kadin",
                value: "select",
              },
              {
                key: "65_erkek",
                value: "select",
              },
              {
                key: "hane",
                value: "select",
              },
              {
                key: "tur",
                value: "select",
              },
              {
                key: "icerik",
                value: "select",
              },
              {
                key: "birim",
                value: "select",
              },
            ],
            condition: [],
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setIhtiyacTablosu(response.sort((a, b) => a.tur.localeCompare(b.tur)));
    } catch (error) {
      console.error(error);
      setIhtiyacTablosu(null);
    }
  }, []);

  useEffect(() => {
    fetchIhtiyactablosu();
  }, [fetchIhtiyactablosu]);

  return (
    (context?.me?.role?.id === 1 ||
      context?.me?.role?.id === 2 ||
      context?.me?.role?.id === 4) && (
      <div className="disaster-container mb-5 pb-5 mx-3 text-center">
        {locationsLoader && (
          <div
            className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
            style={{ background: "rgba(29, 40, 53, 0.9)", zIndex: 99 }}
          >
            <div className="spinner-border text-theme"></div>
          </div>
        )}

        <div style={{ maxWidth: 1500 }} className="mx-auto">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h3>{context?.t?.disaster_management}</h3>

            {/* <button
              onClick={() => handleBack()}
              className="btn btn-danger mb-3"
            >
              {context?.t?.go_back}
            </button> */}
          </div>

          <Card>
            <CardBody>
              <div className="row align-items-end text-start g-2">
                <div className="col">
                  <span className="d-block">{context?.t?.city}</span>
                  {context.allCity ? (
                    <Select
                      options={[
                        { value: null, label: context?.t?.all },
                        ...context.allCity,
                      ]}
                      value={
                        cityValue
                          ? cityValue
                          : city &&
                            context.allCity.find(function (item) {
                              return item.value === city;
                            })
                      }
                      classNamePrefix="react-select"
                      placeholder={context?.t?.choose_city}
                      noOptionsMessage={() => context?.t?.no_option}
                      onChange={(e) => handleCity(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">{context?.t?.district}</span>
                  {districtLoader ? (
                    <Select
                      options={allDistrict}
                      value={districtValue}
                      classNamePrefix="react-select"
                      placeholder={context?.t?.choose_district}
                      noOptionsMessage={() => context?.t?.no_option}
                      onChange={(e) => handleDistrict(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">{context?.t?.neighborhood}</span>
                  {neighborhoodLoader ? (
                    <Select
                      options={allNeighborhood}
                      value={neighborhoodValue ? neighborhoodValue : ""}
                      classNamePrefix="react-select"
                      placeholder={context?.t?.choose_neighborhood}
                      noOptionsMessage={() => context?.t?.no_option}
                      onChange={(e) => handleNeighborhood(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                {/* <div className="col">
                  <span className="d-block">{context?.t?.street}</span>
                  {streetLoader ? (
                    <Select
                      options={allStreet}
                      value={streetValue ? streetValue : ""}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      placeholder={context?.t?.choose_street}
                      onChange={handleStreet}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">{context?.t?.building}</span>
                  {buildingLoader ? (
                    <Select
                      options={allBuilding}
                      value={buildingValue ? buildingValue : ""}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      placeholder={context?.t?.choose_building}
                      onChange={handleBuilding}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">{context?.t?.apartment}</span>
                  {apartmentLoader ? (
                    <Select
                      options={allApartment}
                      value={apartmentValue ? apartmentValue : ""}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      placeholder={context?.t?.choose_apartment}
                      onChange={handleApartment}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div> */}
                <div className="col flex-none w-auto">
                  <button
                    type="button"
                    className="btn btn-outline-theme px-3 me-2"
                    onClick={() => {
                      handleData(null, null, null);
                      fetchAges(null, null, null, null, null, null);
                    }}
                  >
                    {context?.t?.all}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-theme px-4"
                    onClick={() => {
                      handleData(cityValue, districtValue, neighborhoodValue);
                      fetchAges(
                        cityValue && cityValue.value !== "" ? cityValue : null,
                        districtValue && districtValue.value !== ""
                          ? districtValue
                          : null,
                        neighborhoodValue && neighborhoodValue.value !== ""
                          ? neighborhoodValue
                          : null,
                        streetValue && streetValue.value !== ""
                          ? streetValue
                          : null,
                        buildingValue && buildingValue.value !== ""
                          ? buildingValue
                          : null,
                        apartmentValue && apartmentValue.value !== ""
                          ? apartmentValue
                          : null
                      );
                    }}
                  >
                    {context?.t?.find}
                  </button>
                </div>
              </div>

              <hr />

              {earthquakeData ? (
                <>
                  <div className="mt-3 position-relative">
                    <div className="d-flex gap-2 justify-content-between mb-3">
                      <div className="d-flex flex-wrap align-items-center gap-2">
                        {earthquakeData.metadata.date_starts && (
                          <span className="d-block">
                            {context?.t?.started_date} <br />
                            <strong>
                              {moment(
                                earthquakeData.metadata.date_starts,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("DD MMMM YYYY HH:mm:ss")}
                            </strong>
                          </span>
                        )}

                        {earthquakeData.metadata.date_ends && (
                          <span className="d-block border-start ps-3 ms-3">
                            {context?.t?.end_date} <br />
                            <strong>
                              {moment(
                                earthquakeData.metadata.date_ends,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("DD MMMM YYYY HH:mm:ss")}
                            </strong>
                          </span>
                        )}

                        {earthquakeData.metadata.total && (
                          <span className="d-block border-start ps-3 ms-3">
                            {context?.t?.total} <br />{" "}
                            <strong>{earthquakeData.metadata.total}</strong>
                          </span>
                        )}
                      </div>

                      <div className="d-flex flex-column gap-1">
                        <div className="d-flex align-items-center justify-content-end gap-1">
                          <span className="d-block">
                            {context?.t?.tum_okullarin_konumlari}
                          </span>
                          <button
                            type="button"
                            className="btn btn-outline-theme"
                            onClick={() =>
                              !filterCity
                                ? toastMessage(
                                    context?.t?.il_secmelisiniz,
                                    "error"
                                  )
                                : allSchoolMarkers
                                ? setAllSchoolMarkers(null)
                                : handleLocationAllSchool()
                            }
                          >
                            {allSchoolMarkers
                              ? context?.t?.hide
                              : context?.t?.show}
                          </button>
                        </div>

                        <div className="d-flex align-items-center justify-content-end gap-1">
                          <span className="d-block">
                            {context?.t?.tum_saglik_tesisleri_konumlari}
                          </span>
                          <button
                            type="button"
                            className="btn btn-outline-theme"
                            onClick={() =>
                              !filterCity
                                ? toastMessage(
                                    context?.t?.il_secmelisiniz,
                                    "error"
                                  )
                                : allTesisMarkers
                                ? setAllTesisMarkers(null)
                                : handleLocationAllTesis()
                            }
                          >
                            {allTesisMarkers
                              ? context?.t?.hide
                              : context?.t?.show}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="d-flex flex-column gap-1 position-absolute left-0 bottom-0 mx-3 my-2"
                      style={{ zIndex: 1 }}
                    >
                      <div className="d-flex flex-column gap-1 bg-white text-black rounded px-3 py-2 text-start">
                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_0_2_9"
                              id="mag_0_2_9"
                              checked={filter.mag_0_2_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_0_2_9"
                              className="form-check-label"
                              style={{ color: "black" }}
                            >
                              M≥0 M≤2.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FF0000" }}
                            className="rounded ms-2 px-2 flex-1 text-center text-white"
                          >
                            d≥0 d≤5
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_3_3_9"
                              id="mag_3_3_9"
                              checked={filter.mag_3_3_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_3_3_9"
                              className="form-check-label"
                              style={{ color: "black" }}
                            >
                              M≥3 M≤3.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FF6666" }}
                            className="rounded ms-2 px-2 flex-1 text-center"
                          >
                            d{">"}5 d≤10{" "}
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_4_4_9"
                              id="mag_4_4_9"
                              checked={filter.mag_4_4_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_4_4_9"
                              className="form-check-label"
                              style={{ color: "black" }}
                            >
                              M≥4 M≤4.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FFA500" }}
                            className="rounded ms-2 px-2 flex-1 text-center"
                          >
                            d{">"}10 d≤20
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_5_5_9"
                              id="mag_5_5_9"
                              checked={filter.mag_5_5_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_5_5_9"
                              className="form-check-label"
                              style={{ color: "black" }}
                            >
                              M≥5 M≤5.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FFFF00" }}
                            className="rounded ms-2 px-2 flex-1 text-center"
                          >
                            d{">"}20 d≤40
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_6_6_9"
                              id="mag_6_6_9"
                              checked={filter.mag_6_6_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_6_6_9"
                              className="form-check-label"
                              style={{ color: "black" }}
                            >
                              M≥6 M≤6.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#008000" }}
                            className="rounded ms-2 px-2 flex-1 text-center text-white"
                          >
                            d{">"}40 d≤80
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_7_7_9"
                              id="mag_7_7_9"
                              checked={filter.mag_7_7_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_7_7_9"
                              className="form-check-label"
                              style={{ color: "black" }}
                            >
                              M≥7 M≤7.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#0000FF" }}
                            className="rounded ms-2 px-2 flex-1 text-center text-white"
                          >
                            d{">"}80 d≤150
                          </div>
                        </div>
                      </div>
                      {/* <button
                        type="button"
                        className="btn btn-dark"
                        onClick={() => setShowTable(!showTable)}
                      >
                        Tablo {showTable ? "Gizle" : "Göster"}
                      </button> */}
                    </div>

                    <GoogleMap
                      mapTypeId="terrain"
                      mapContainerStyle={{
                        height: 500,
                        width: "100%",
                      }}
                      options={options}
                    >
                      {/* Schools */}
                      {allSchoolMarkers && allSchoolMarkers.length > 0 && (
                        <MarkerClusterer
                          options={{
                            maxZoom: 20, // Set this to control when clusters should stop grouping markers
                            styles: [
                              {
                                textColor: "white", // Set the cluster text color to white
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
                                height: 50,
                                width: 50,
                              },
                              {
                                textColor: "white",
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png",
                                height: 56,
                                width: 56,
                              },
                              {
                                textColor: "white",
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
                                height: 66,
                                width: 66,
                              },
                            ],
                          }}
                        >
                          {(clusterer) =>
                            allSchoolMarkers.map((item, index) => (
                              <Marker
                                key={index}
                                clusterer={clusterer}
                                position={{
                                  lat: parseFloat(
                                    item.latitude.replace(",", ".")
                                  ),
                                  lng: parseFloat(
                                    item.longitude.replace(",", ".")
                                  ),
                                }}
                                onClick={() => setSchoolMarkerSelected(item)}
                              />
                            ))
                          }
                        </MarkerClusterer>
                      )}

                      {schoolMarker && (
                        <Marker
                          position={{
                            lat: parseFloat(
                              schoolMarker.latitude.replace(",", ".")
                            ),
                            lng: parseFloat(
                              schoolMarker.longitude.replace(",", ".")
                            ),
                          }}
                          onClick={() => setSchoolMarkerSelected(schoolMarker)}
                        />
                      )}

                      {schoolMarkerSelected && (
                        <InfoWindow
                          position={{
                            lat: parseFloat(
                              schoolMarkerSelected.latitude.replace(",", ".")
                            ),
                            lng: parseFloat(
                              schoolMarkerSelected.longitude.replace(",", ".")
                            ),
                          }}
                          onCloseClick={() => setSchoolMarkerSelected(null)}
                        >
                          <div className="text-start">
                            <strong>{context?.t?.okul_adi}</strong>{" "}
                            {schoolMarkerSelected["okul"]}
                            <br />
                            <strong>{context?.t?.devlet_ozel}</strong>{" "}
                            {schoolMarkerSelected["devletozel"]}
                            <br />
                            <strong>{context?.t?.address}</strong>{" "}
                            {schoolMarkerSelected["adres"]}
                            <br />
                            <strong>{context?.t?.ogrenci_sayisi}</strong>{" "}
                            {schoolMarkerSelected["ogrenci"]}
                            <br />
                            <strong>{context?.t?.ogretmen_sayisi}</strong>{" "}
                            {schoolMarkerSelected["ogretmen"]}
                            <br />
                            <strong>
                              {context?.t?.ogretmen_basina_ogrenci_sayisi}
                            </strong>{" "}
                            {
                              schoolMarkerSelected[
                                "ogretmenbasinaogrencisayisi"
                              ]
                            }
                            <br />
                            <strong>{context?.t?.derslik}</strong>{" "}
                            {schoolMarkerSelected["derslik"]}
                            <br />
                            <strong>
                              {context?.t?.ogrenci_basina_derslik_sayisi}
                            </strong>{" "}
                            {schoolMarkerSelected["ogrencibasinadersliksayisi"]}
                            <br />
                            <strong>
                              {context?.t?.kutuphane_kitap_sayisi}
                            </strong>{" "}
                            {schoolMarkerSelected["kutuphanekitapsayisi"]}
                            <br />
                            <strong>
                              {context?.t?.konferans_salon_sayisi}
                            </strong>{" "}
                            {schoolMarkerSelected["konferanssalonu"]}
                          </div>
                        </InfoWindow>
                      )}

                      {/* Tesisler */}
                      {allTesisMarkers && allTesisMarkers.length > 0 && (
                        <MarkerClusterer
                          options={{
                            maxZoom: 20, // Set this to control when clusters should stop grouping markers
                            styles: [
                              {
                                textColor: "white", // Set the cluster text color to white
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
                                height: 50,
                                width: 50,
                              },
                              {
                                textColor: "white",
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png",
                                height: 56,
                                width: 56,
                              },
                              {
                                textColor: "white",
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
                                height: 66,
                                width: 66,
                              },
                            ],
                          }}
                        >
                          {(clusterer) =>
                            allTesisMarkers.map((item, index) => (
                              <Marker
                                key={index}
                                clusterer={clusterer}
                                position={{
                                  lat: parseFloat(item.enlem.replace(",", ".")),
                                  lng: parseFloat(
                                    item.boylam.replace(",", ".")
                                  ),
                                }}
                                onClick={() => setTesisMarkerSelected(item)}
                              />
                            ))
                          }
                        </MarkerClusterer>
                      )}

                      {tesisMarker && (
                        <Marker
                          position={{
                            lat: parseFloat(
                              tesisMarker.enlem.replace(",", ".")
                            ),
                            lng: parseFloat(
                              tesisMarker.boylam.replace(",", ".")
                            ),
                          }}
                          onClick={() => setTesisMarkerSelected(tesisMarker)}
                        />
                      )}

                      {tesisMarkerSelected && (
                        <InfoWindow
                          position={{
                            lat: parseFloat(
                              tesisMarkerSelected.enlem.replace(",", ".")
                            ),
                            lng: parseFloat(
                              tesisMarkerSelected.boylam.replace(",", ".")
                            ),
                          }}
                          onCloseClick={() => setTesisMarkerSelected(null)}
                        >
                          <div className="text-start">
                            <strong>{context?.t?.kurum_adi}:</strong>{" "}
                            {tesisMarkerSelected["kurum adı"]}
                            <br />
                            <strong>{context?.t?.address}:</strong>{" "}
                            {tesisMarkerSelected["adres"]}
                            <br />
                            <strong>{context?.t?.phone}:</strong>{" "}
                            {tesisMarkerSelected["telefon"]}
                            <br />
                            <strong>{context?.t?.web_site}:</strong>{" "}
                            {tesisMarkerSelected["web sitesi"] ? (
                              <a
                                href={tesisMarkerSelected["web sitesi"]}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-black"
                              >
                                {context?.t?.view}
                              </a>
                            ) : (
                              ""
                            )}
                            <br />
                            <strong>{context?.t?.city}:</strong>{" "}
                            {tesisMarkerSelected["şehir"]}
                            <br />
                            <strong>{context?.t?.district}:</strong>{" "}
                            {tesisMarkerSelected["i̇lçe"]}
                            <br />
                            <strong>{context?.t?.category}:</strong>{" "}
                            {tesisMarkerSelected["kategori"]}
                            <br />
                            <strong>{context?.t?.bolumler}:</strong>{" "}
                            <div>
                              {tesisMarkerSelected["bölümler"] ? (
                                <div className="d-flex flex-wrap gap-1">
                                  {tesisMarkerSelected["bölümler"]
                                    .split(",")
                                    .map((item, itemIndex) => (
                                      <span
                                        key={itemIndex}
                                        className="btn btn-sm btn-dark"
                                      >
                                        {item}
                                      </span>
                                    ))}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </InfoWindow>
                      )}

                      {earthquakeData.result.map(
                        (earthquake, index) =>
                          index === 0 && (
                            <Marker
                              key={earthquake._id + "_live"}
                              position={{
                                lat: earthquake.geojson.coordinates[1],
                                lng: earthquake.geojson.coordinates[0],
                              }}
                              icon={{
                                url: "http://www.koeri.boun.edu.tr/sismo/zeqmap/images/neweq0.gif",
                                scaledSize: new window.google.maps.Size(50, 50),
                                anchor: new window.google.maps.Point(25, 25),
                              }}
                            />
                          )
                      )}

                      {filterEarthquakes().map((earthquake, index) => (
                        <Marker
                          key={earthquake._id}
                          position={{
                            lat: earthquake.geojson.coordinates[1],
                            lng: earthquake.geojson.coordinates[0],
                          }}
                          icon={getMarkerStyle(
                            earthquake.mag,
                            earthquake.depth
                          )}
                          onClick={() => setSelectedEarthquake(earthquake)}
                        />
                      ))}

                      {selectedEarthquake && (
                        <InfoWindow
                          position={{
                            lat: selectedEarthquake.geojson.coordinates[1],
                            lng: selectedEarthquake.geojson.coordinates[0],
                          }}
                          onCloseClick={() => setSelectedEarthquake(null)}
                        >
                          <div
                            className="text-start overflow-auto"
                            style={{ height: 300 }}
                          >
                            <strong>{context?.t?.title}:</strong>{" "}
                            {selectedEarthquake.title} <br />
                            <strong>{context?.t?.date}:</strong>{" "}
                            {selectedEarthquake.date} <br />
                            <strong>{context?.t?.size}:</strong>{" "}
                            {selectedEarthquake.mag} <br />
                            <strong>{context?.t?.depth}:</strong>{" "}
                            {selectedEarthquake.depth} km <br />
                            <strong>{context?.t?.closestCities}:</strong>
                            {selectedEarthquake.location_properties
                              .closestCities.length !== 0 &&
                              selectedEarthquake.location_properties.closestCities.map(
                                (item, index) => (
                                  <div
                                    key={index}
                                    className="border-top border-black mt-1 pt-1"
                                  >
                                    <strong>{context?.t?.city}:</strong>{" "}
                                    {item.name}
                                    <br />
                                    <strong>
                                      {context?.t?.population}:
                                    </strong>{" "}
                                    {item.population}
                                    <br />
                                    <strong>
                                      {context?.t?.distance}:
                                    </strong>{" "}
                                    {item.distance.toFixed(2)} m
                                  </div>
                                )
                              )}
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  </div>

                  {showTable && (
                    <div className="dataTables_wrapper dt-bootstrap5">
                      <div className="dataTables_scroll">
                        <div
                          className="dataTables_scrollBody table-responsive"
                          style={{ height: 300 }}
                        >
                          <table className="table text-nowrap w-100 dataTable mb-0 text-start">
                            <thead>
                              <tr>
                                <th className="px-2">
                                  {context?.t?.time_of_creation}
                                </th>
                                <th className="px-2">{context?.t?.lat}</th>
                                <th className="px-2">{context?.t?.lng}</th>
                                <th className="px-2">{context?.t?.depth}</th>
                                <th className="px-2">{context?.t?.size}</th>
                                <th className="px-2">{context?.t?.place}</th>
                              </tr>
                            </thead>

                            <tbody>
                              {filterEarthquakes().map((earthquake, index) => (
                                <tr key={index}>
                                  <td>{earthquake.date_time}</td>
                                  <td>{earthquake.geojson.coordinates[0]}</td>
                                  <td>{earthquake.geojson.coordinates[1]}</td>
                                  <td>{earthquake.depth}</td>
                                  <td>{earthquake.mag}</td>
                                  <td>{earthquake.title}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="spinner-border text-theme"></div>
              )}

              <hr />

              <div className="mt-3 text-start">
                <div className="mb-3 row align-items-center justify-content-between">
                  <div className="col-6">
                    <div className="d-flex align-items-center gap-2">
                      <h4 className="mb-0">{context?.t?.requirements_table}</h4>
                      {ages && (
                        <h5 className="mb-0">
                          <span> / {context?.t?.total_population}: </span>
                          {parseInt(Math.ceil(ages["total"])).toLocaleString(
                            "tr-TR"
                          )}
                        </h5>
                      )}
                    </div>
                  </div>

                  {ages && (
                    <div className="col w-auto flex-none">
                      <div className="d-flex align-items-end gap-2">
                        <div
                          className="w-100"
                          style={{ minWidth: 150, maxWidth: 150 }}
                        >
                          <span className="d-block">
                            {context?.t?.how_many_days_required}
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            value={dayCount}
                            onChange={(e) => setDayCount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {ages && ihtiyacTablosu ? (
                  ages.data ? (
                    <div className="dataTables_wrapper dt-bootstrap5">
                      <div className="dataTables_scroll">
                        <div
                          className="dataTables_scrollBody table-responsive"
                          style={{ height: 400 }}
                        >
                          <table className="table text-nowrap w-100 dataTable mb-0 text-start">
                            <thead>
                              <tr>
                                <th className="px-2">{context?.t?.category}</th>
                                <th className="px-2">
                                  {context?.t?.total_requirement}
                                </th>
                                <th className="px-2">{context?.t?.unit}</th>

                                <th className="px-2">{context?.t?.contents}</th>
                              </tr>
                            </thead>

                            <tbody>
                              {ihtiyacTablosu.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.tur}</td>
                                  <td>
                                    {(
                                      parseInt(
                                        Math.ceil(
                                          ages["0_4"] *
                                            parseFloat(
                                              item["0_4"].replace(",", ".")
                                            ) +
                                            ages["5_9"] *
                                              parseFloat(
                                                item["5_9"].replace(",", ".")
                                              ) +
                                            ages["10_65_female"] *
                                              parseFloat(
                                                item["10_65_kadin"].replace(
                                                  ",",
                                                  "."
                                                )
                                              ) +
                                            ages["10_65_male"] *
                                              parseFloat(
                                                item["10_65_erkek"].replace(
                                                  ",",
                                                  "."
                                                )
                                              ) +
                                            ages["65_female"] *
                                              parseFloat(
                                                item["65_kadin"].replace(
                                                  ",",
                                                  "."
                                                )
                                              ) +
                                            ages["65_male"] *
                                              parseFloat(
                                                item["65_erkek"].replace(
                                                  ",",
                                                  "."
                                                )
                                              ) +
                                            ages["hane"] *
                                              parseFloat(
                                                item["hane"].replace(",", ".")
                                              )
                                        )
                                      ) * dayCount
                                    ).toLocaleString("tr-TR")}
                                  </td>
                                  <td>{item.birim}</td>
                                  <td>{item.icerik}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span>{context?.t?.analysis_data_not_added}</span>
                  )
                ) : (
                  <div className="spinner-border text-theme"></div>
                )}
              </div>

              <hr />

              <h5 className="mb-3">{context?.t?.afet_title}</h5>

              <div className="row g-4 h-100 overflow-hidden">
                <div
                  className="col-12 col-md-6 col-lg-4"
                  style={{ height: 600 }}
                >
                  <DynamicCard
                    height="h-full"
                    fullScreen={true}
                    events={false}
                    data={afetGraphic1}
                    item={{
                      title: context?.t?.afet_chart_1_title,
                      options: {
                        options: {
                          baseOption: {
                            timeline: {
                              left: 0,
                              right: 0,
                              axisType: "category",
                              autoPlay: true,
                              playInterval: 3000,
                              lineStyle: {
                                color: "white",
                              },
                              data:
                                afetGraphic1 &&
                                afetGraphic1[0] &&
                                afetGraphic1[0].years &&
                                afetGraphic1[0].years,
                              label: {
                                formatter: function (s) {
                                  return new Date(s).getFullYear();
                                },
                              },
                            },
                            tooltip: {},
                            calculable: true,
                            grid: {
                              right: 10,
                              left: 70,
                              top: 70,
                              bottom: 110,
                              tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                  type: "shadow",
                                  label: {
                                    show: true,
                                    formatter: function (params) {
                                      return params.value.replace("\n", "");
                                    },
                                  },
                                },
                              },
                            },
                            xAxis: [
                              {
                                type: "category",
                                axisLabel: {
                                  interval: 0,
                                  rotate: 45,
                                  textStyle: {
                                    color:
                                      context?.theme === "light"
                                        ? "#000"
                                        : "#fff",
                                  },
                                  formatter: function (value, index) {
                                    return value.length > 7
                                      ? value.substring(0, 7) + "..."
                                      : value;
                                  },
                                },
                                data:
                                  afetGraphic1 &&
                                  afetGraphic1[0] &&
                                  afetGraphic1[0].parameters &&
                                  afetGraphic1[0].parameters,
                                splitLine: { show: false },
                              },
                            ],
                            yAxis: [
                              {
                                type: "value",
                                name: "",
                                axisLabel: {
                                  textStyle: {
                                    color:
                                      context?.theme === "light"
                                        ? "#000"
                                        : "#fff",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                name: "",
                                type: "bar",
                              },
                            ],
                            visualMap:
                              context?.chartColor === "random"
                                ? false
                                : {
                                    show: false,
                                    min:
                                      afetGraphic1 &&
                                      afetGraphic1[0] &&
                                      afetGraphic1[0].formattedArray &&
                                      afetGraphic1[0].formattedArray
                                        ? Math.min(
                                            ...(afetGraphic1 &&
                                              afetGraphic1[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    max:
                                      afetGraphic1 &&
                                      afetGraphic1[0] &&
                                      afetGraphic1[0].formattedArray &&
                                      afetGraphic1[0].formattedArray
                                        ? Math.max(
                                            ...(afetGraphic1 &&
                                              afetGraphic1[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  },
                          },
                          options:
                            afetGraphic1 &&
                            afetGraphic1[0] &&
                            afetGraphic1[0].formattedArray &&
                            afetGraphic1[0].formattedArray,
                        },
                      },
                    }}
                  />
                </div>

                <div
                  className="col-12 col-md-6 col-lg-4"
                  style={{ height: 600 }}
                >
                  <DynamicCard
                    height="h-full"
                    fullScreen={true}
                    events={false}
                    data={afetGraphic2}
                    item={{
                      title: context?.t?.afet_chart_2_title,
                      options: {
                        options: {
                          series: [
                            {
                              data: afetGraphic2,
                              name: null,
                              type: "pie",
                              label: {
                                textStyle: {
                                  fontSize: 15,
                                },
                              },
                              radius: "50%",
                              itemStyle: {
                                color: context?.chartColor,
                              },
                            },
                          ],
                          tooltip: {
                            trigger: "item",
                          },
                          darkMode: context?.theme === "light" ? false : true,
                          legend: {
                            show: true,
                            orient: "horizontal",
                            left: "center",
                            itemGap: 20,
                            textStyle: {
                              color:
                                context?.theme === "light" ? "#000" : "#fff",
                            },
                          },
                          visualMap: context?.chartColor
                            ? {
                                min:
                                  afetGraphic2 && afetGraphic2.length !== 0
                                    ? Math.min(
                                        ...afetGraphic2.map((obj) => obj.value)
                                      )
                                    : 0,
                                max:
                                  afetGraphic2 && afetGraphic2.length !== 0
                                    ? Math.max(
                                        ...afetGraphic2.map((obj) => obj.value)
                                      )
                                    : 0,
                                show: false,
                                inRange: {
                                  colorLightness: [0.2, 1],
                                },
                              }
                            : false,
                        },
                      },
                    }}
                  />
                </div>

                <div
                  className="col-12 col-md-6 col-lg-4"
                  style={{ height: 600 }}
                >
                  <DynamicCard
                    height="h-full"
                    fullScreen={true}
                    events={false}
                    data={afetGraphic3}
                    item={{
                      title: context?.t?.afet_chart_3_title,
                      options: {
                        options: {
                          baseOption: {
                            timeline: {
                              left: 0,
                              right: 0,
                              axisType: "category",
                              autoPlay: true,
                              playInterval: 3000,
                              lineStyle: {
                                color: "white",
                              },
                              data:
                                afetGraphic3 &&
                                afetGraphic3[0] &&
                                afetGraphic3[0].years &&
                                afetGraphic3[0].years,
                              label: {
                                formatter: function (s) {
                                  return new Date(s).getFullYear();
                                },
                              },
                            },
                            tooltip: {},
                            calculable: true,
                            grid: {
                              right: 10,
                              left: 70,
                              top: 70,
                              bottom: 110,
                              tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                  type: "shadow",
                                  label: {
                                    show: true,
                                    formatter: function (params) {
                                      return params.value.replace("\n", "");
                                    },
                                  },
                                },
                              },
                            },
                            xAxis: [
                              {
                                type: "category",
                                axisLabel: {
                                  interval: 0,
                                  rotate: 45,
                                  textStyle: {
                                    color:
                                      context?.theme === "light"
                                        ? "#000"
                                        : "#fff",
                                  },
                                  formatter: function (value, index) {
                                    return value.length > 7
                                      ? value.substring(0, 7) + "..."
                                      : value;
                                  },
                                },
                                data:
                                  afetGraphic3 &&
                                  afetGraphic3[0] &&
                                  afetGraphic3[0].parameters &&
                                  afetGraphic3[0].parameters,
                                splitLine: { show: false },
                              },
                            ],
                            yAxis: [
                              {
                                type: "value",
                                name: "",
                                axisLabel: {
                                  textStyle: {
                                    color:
                                      context?.theme === "light"
                                        ? "#000"
                                        : "#fff",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                name: "",
                                type: "bar",
                              },
                            ],
                            visualMap:
                              context?.chartColor === "random"
                                ? false
                                : {
                                    show: false,
                                    min:
                                      afetGraphic3 &&
                                      afetGraphic3[0] &&
                                      afetGraphic3[0].formattedArray &&
                                      afetGraphic3[0].formattedArray
                                        ? Math.min(
                                            ...(afetGraphic3 &&
                                              afetGraphic3[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    max:
                                      afetGraphic3 &&
                                      afetGraphic3[0] &&
                                      afetGraphic3[0].formattedArray &&
                                      afetGraphic3[0].formattedArray
                                        ? Math.max(
                                            ...(afetGraphic3 &&
                                              afetGraphic3[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  },
                          },
                          options:
                            afetGraphic3 &&
                            afetGraphic3[0] &&
                            afetGraphic3[0].formattedArray &&
                            afetGraphic3[0].formattedArray,
                        },
                      },
                    }}
                  />
                </div>

                <div
                  className="col-12 col-md-6 col-lg-4"
                  style={{ height: 600 }}
                >
                  <DynamicCard
                    height="h-full"
                    fullScreen={true}
                    events={false}
                    data={afetGraphic4}
                    item={{
                      title: context?.t?.afet_chart_4_title,
                      options: {
                        options: {
                          baseOption: {
                            timeline: {
                              left: 0,
                              right: 0,
                              axisType: "category",
                              autoPlay: true,
                              playInterval: 3000,
                              lineStyle: {
                                color: "white",
                              },
                              data:
                                afetGraphic4 &&
                                afetGraphic4[0] &&
                                afetGraphic4[0].years &&
                                afetGraphic4[0].years,
                              label: {
                                formatter: function (s) {
                                  return new Date(s).getFullYear();
                                },
                              },
                            },
                            tooltip: {},
                            calculable: true,
                            grid: {
                              right: 10,
                              left: 70,
                              top: 70,
                              bottom: 110,
                              tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                  type: "shadow",
                                  label: {
                                    show: true,
                                    formatter: function (params) {
                                      return params.value.replace("\n", "");
                                    },
                                  },
                                },
                              },
                            },
                            xAxis: [
                              {
                                type: "category",
                                axisLabel: {
                                  interval: 0,
                                  rotate: 45,
                                  textStyle: {
                                    color:
                                      context?.theme === "light"
                                        ? "#000"
                                        : "#fff",
                                  },
                                  formatter: function (value, index) {
                                    return value.length > 7
                                      ? value.substring(0, 7) + "..."
                                      : value;
                                  },
                                },
                                data:
                                  afetGraphic4 &&
                                  afetGraphic4[0] &&
                                  afetGraphic4[0].parameters &&
                                  afetGraphic4[0].parameters,
                                splitLine: { show: false },
                              },
                            ],
                            yAxis: [
                              {
                                type: "value",
                                name: "",
                                axisLabel: {
                                  textStyle: {
                                    color:
                                      context?.theme === "light"
                                        ? "#000"
                                        : "#fff",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                name: "",
                                type: "bar",
                              },
                            ],
                            visualMap:
                              context?.chartColor === "random"
                                ? false
                                : {
                                    show: false,
                                    min:
                                      afetGraphic4 &&
                                      afetGraphic4[0] &&
                                      afetGraphic4[0].formattedArray &&
                                      afetGraphic4[0].formattedArray
                                        ? Math.min(
                                            ...(afetGraphic4 &&
                                              afetGraphic4[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    max:
                                      afetGraphic4 &&
                                      afetGraphic4[0] &&
                                      afetGraphic4[0].formattedArray &&
                                      afetGraphic4[0].formattedArray
                                        ? Math.max(
                                            ...(afetGraphic4 &&
                                              afetGraphic4[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  },
                          },
                          options:
                            afetGraphic4 &&
                            afetGraphic4[0] &&
                            afetGraphic4[0].formattedArray &&
                            afetGraphic4[0].formattedArray,
                        },
                      },
                    }}
                  />
                </div>

                <div
                  className="col-12 col-md-6 col-lg-4"
                  style={{ height: 600 }}
                >
                  <DynamicCard
                    height="h-full"
                    fullScreen={true}
                    events={false}
                    data={afetGraphic5}
                    item={{
                      title: context?.t?.afet_chart_5_title,
                      options: {
                        options: {
                          baseOption: {
                            timeline: {
                              left: 0,
                              right: 0,
                              axisType: "category",
                              autoPlay: true,
                              playInterval: 3000,
                              lineStyle: {
                                color: "white",
                              },
                              data:
                                afetGraphic5 &&
                                afetGraphic5[0] &&
                                afetGraphic5[0].years &&
                                afetGraphic5[0].years,
                              label: {
                                formatter: function (s) {
                                  return new Date(s).getFullYear();
                                },
                              },
                            },
                            tooltip: {},
                            calculable: true,
                            grid: {
                              right: 10,
                              left: 70,
                              top: 70,
                              bottom: 110,
                              tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                  type: "shadow",
                                  label: {
                                    show: true,
                                    formatter: function (params) {
                                      return params.value.replace("\n", "");
                                    },
                                  },
                                },
                              },
                            },
                            xAxis: [
                              {
                                type: "category",
                                axisLabel: {
                                  interval: 0,
                                  rotate: 45,
                                  textStyle: {
                                    color:
                                      context?.theme === "light"
                                        ? "#000"
                                        : "#fff",
                                  },
                                  formatter: function (value, index) {
                                    return value.length > 7
                                      ? value.substring(0, 7) + "..."
                                      : value;
                                  },
                                },
                                data:
                                  afetGraphic5 &&
                                  afetGraphic5[0] &&
                                  afetGraphic5[0].parameters &&
                                  afetGraphic5[0].parameters,
                                splitLine: { show: false },
                              },
                            ],
                            yAxis: [
                              {
                                type: "value",
                                name: "",
                                axisLabel: {
                                  textStyle: {
                                    color:
                                      context?.theme === "light"
                                        ? "#000"
                                        : "#fff",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                name: "",
                                type: "bar",
                              },
                            ],
                            visualMap:
                              context?.chartColor === "random"
                                ? false
                                : {
                                    show: false,
                                    min:
                                      afetGraphic5 &&
                                      afetGraphic5[0] &&
                                      afetGraphic5[0].formattedArray &&
                                      afetGraphic5[0].formattedArray
                                        ? Math.min(
                                            ...(afetGraphic5 &&
                                              afetGraphic5[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    max:
                                      afetGraphic5 &&
                                      afetGraphic5[0] &&
                                      afetGraphic5[0].formattedArray &&
                                      afetGraphic5[0].formattedArray
                                        ? Math.max(
                                            ...(afetGraphic5 &&
                                              afetGraphic5[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  },
                          },
                          options:
                            afetGraphic5 &&
                            afetGraphic5[0] &&
                            afetGraphic5[0].formattedArray &&
                            afetGraphic5[0].formattedArray,
                        },
                      },
                    }}
                  />
                </div>

                <div
                  className="col-12 col-md-6 col-lg-4"
                  style={{ height: 600 }}
                >
                  <DynamicCard
                    height="h-full"
                    fullScreen={true}
                    events={false}
                    data={afetGraphic6}
                    item={{
                      title: context?.t?.afet_chart_6_title,
                      options: {
                        options: {
                          series: [
                            {
                              data: afetGraphic6,
                              name: null,
                              type: "pie",
                              label: {
                                textStyle: {
                                  fontSize: 15,
                                },
                              },
                              radius: "50%",
                              itemStyle: {
                                color: context?.chartColor,
                              },
                            },
                          ],
                          tooltip: {
                            trigger: "item",
                          },
                          darkMode: context?.theme === "light" ? false : true,
                          legend: {
                            show: true,
                            orient: "horizontal",
                            left: "center",
                            itemGap: 20,
                            textStyle: {
                              color:
                                context?.theme === "light" ? "#000" : "#fff",
                            },
                          },
                          visualMap: context?.chartColor
                            ? {
                                min:
                                  afetGraphic6 && afetGraphic6.length !== 0
                                    ? Math.min(
                                        ...afetGraphic6.map((obj) => obj.value)
                                      )
                                    : 0,
                                max:
                                  afetGraphic6 && afetGraphic6.length !== 0
                                    ? Math.max(
                                        ...afetGraphic6.map((obj) => obj.value)
                                      )
                                    : 0,
                                show: false,
                                inRange: {
                                  colorLightness: [0.2, 1],
                                },
                              }
                            : false,
                        },
                      },
                    }}
                  />
                </div>

                {/* <div className="col-12 col-md-6 col-lg-4" style={{ height: 600 }}>
                  <DynamicCard
                    height="h-full"
                    fullScreen={true}
                    events={false}
                    data={afetGraphic7}
                    item={{
                      title: context?.t?.afet_chart_7_title,
                      options: {
                        options: {
                          baseOption: {
                            timeline: {
                              left: 0,
                              right: 0,
                              axisType: "category",
                              autoPlay: true,
                              playInterval: 3000,
                              lineStyle: {
                                color: "white",
                              },
                              data: afetGraphic7 && afetGraphic7[0].years,
                              label: {
                                formatter: function (s) {
                                  return new Date(s).getFullYear();
                                },
                              },
                            },
                            tooltip: {},
                            calculable: true,
                            grid: {
                              right: 10,
                              left: 70,
                              top: 70,
                              bottom: 110,
                              tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                  type: "shadow",
                                  label: {
                                    show: true,
                                    formatter: function (params) {
                                      return params.value.replace("\n", "");
                                    },
                                  },
                                },
                              },
                            },
                            xAxis: [
                              {
                                type: "category",
                                axisLabel: {
                                  interval: 0,
                                  rotate: 45,
                                  textStyle: {
                                    color: context?.theme === "light" ? "#000" : "#fff",
                                  },
                                  formatter: function (value, index) {
                                    return value.length > 7
                                      ? value.substring(0, 7) + "..."
                                      : value;
                                  },
                                },
                                data:
                                  afetGraphic7 && afetGraphic7[0].parameters,
                                splitLine: { show: false },
                              },
                            ],
                            yAxis: [
                              {
                                type: "value",
                                name: "",
                                axisLabel: {
                                  textStyle: {
                                    color: context?.theme === "light" ? "#000" : "#fff",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                name: "",
                                type: "bar",
                              },
                            ],
                            visualMap:
                              context?.chartColor === "random"
                                ? false
                                : {
                                    show: false,
                                    min:
                                      afetGraphic7 &&
                                      afetGraphic7[0].formattedArray
                                        ? Math.min(
                                            ...(afetGraphic7 &&
                                              afetGraphic7[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    max:
                                      afetGraphic7 &&
                                      afetGraphic7[0].formattedArray
                                        ? Math.max(
                                            ...(afetGraphic7 &&
                                              afetGraphic7[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  },
                          },
                          options:
                            afetGraphic7 && afetGraphic7[0].formattedArray,
                        },
                      },
                    }}
                  />
                </div> */}

                {/* <div className="col-12 col-md-6 col-lg-4" style={{ height: 600 }}>
                  <DynamicCard
                    height="h-full"
                    fullScreen={true}
                    events={false}
                    data={afetGraphic8}
                    item={{
                      title: context?.t?.afet_chart_8_title,
                      options: {
                        options: {
                          baseOption: {
                            timeline: {
                              left: 0,
                              right: 0,
                              axisType: "category",
                              autoPlay: true,
                              playInterval: 3000,
                              lineStyle: {
                                color: "white",
                              },
                              data: afetGraphic8 && afetGraphic8[0].years,
                              label: {
                                formatter: function (s) {
                                  return new Date(s).getFullYear();
                                },
                              },
                            },
                            tooltip: {},
                            calculable: true,
                            grid: {
                              right: 10,
                              left: 70,
                              top: 70,
                              bottom: 110,
                              tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                  type: "shadow",
                                  label: {
                                    show: true,
                                    formatter: function (params) {
                                      return params.value.replace("\n", "");
                                    },
                                  },
                                },
                              },
                            },
                            xAxis: [
                              {
                                type: "category",
                                axisLabel: {
                                  interval: 0,
                                  rotate: 45,
                                  textStyle: {
                                    color: context?.theme === "light" ? "#000" : "#fff",
                                  },
                                  formatter: function (value, index) {
                                    return value.length > 7
                                      ? value.substring(0, 7) + "..."
                                      : value;
                                  },
                                },
                                data:
                                  afetGraphic8 && afetGraphic8[0].parameters,
                                splitLine: { show: false },
                              },
                            ],
                            yAxis: [
                              {
                                type: "value",
                                name: "",
                                axisLabel: {
                                  textStyle: {
                                    color: context?.theme === "light" ? "#000" : "#fff",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                name: "",
                                type: "bar",
                              },
                            ],
                            visualMap:
                              context?.chartColor === "random"
                                ? false
                                : {
                                    show: false,
                                    min:
                                      afetGraphic8 &&
                                      afetGraphic8[0].formattedArray
                                        ? Math.min(
                                            ...(afetGraphic8 &&
                                              afetGraphic8[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    max:
                                      afetGraphic8 &&
                                      afetGraphic8[0].formattedArray
                                        ? Math.max(
                                            ...(afetGraphic8 &&
                                              afetGraphic8[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  },
                          },
                          options:
                            afetGraphic8 && afetGraphic8[0].formattedArray,
                        },
                      },
                    }}
                  />
                </div> */}

                {/* <div className="col-12 col-md-6 col-lg-4" style={{ height: 600 }}>
                  <DynamicCard
                    height="h-full"
                    fullScreen={true}
                    events={false}
                    data={afetGraphic9}
                    item={{
                      title: context?.t?.afet_chart_9_title,
                      options: {
                        options: {
                          baseOption: {
                            timeline: {
                              left: 0,
                              right: 0,
                              axisType: "category",
                              autoPlay: true,
                              playInterval: 3000,
                              lineStyle: {
                                color: "white",
                              },
                              data: afetGraphic9 && afetGraphic9[0].years,
                              label: {
                                formatter: function (s) {
                                  return new Date(s).getFullYear();
                                },
                              },
                            },
                            tooltip: {},
                            calculable: true,
                            grid: {
                              right: 10,
                              left: 70,
                              top: 70,
                              bottom: 110,
                              tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                  type: "shadow",
                                  label: {
                                    show: true,
                                    formatter: function (params) {
                                      return params.value.replace("\n", "");
                                    },
                                  },
                                },
                              },
                            },
                            xAxis: [
                              {
                                type: "category",
                                axisLabel: {
                                  interval: 0,
                                  rotate: 45,
                                  textStyle: {
                                    color: context?.theme === "light" ? "#000" : "#fff",
                                  },
                                  formatter: function (value, index) {
                                    return value.length > 7
                                      ? value.substring(0, 7) + "..."
                                      : value;
                                  },
                                },
                                data:
                                  afetGraphic9 && afetGraphic9[0].parameters,
                                splitLine: { show: false },
                              },
                            ],
                            yAxis: [
                              {
                                type: "value",
                                name: "",
                                axisLabel: {
                                  textStyle: {
                                    color: context?.theme === "light" ? "#000" : "#fff",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                name: "",
                                type: "bar",
                              },
                            ],
                            visualMap:
                              context?.chartColor === "random"
                                ? false
                                : {
                                    show: false,
                                    min:
                                      afetGraphic9 &&
                                      afetGraphic9[0].formattedArray
                                        ? Math.min(
                                            ...(afetGraphic9 &&
                                              afetGraphic9[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    max:
                                      afetGraphic9 &&
                                      afetGraphic9[0].formattedArray
                                        ? Math.max(
                                            ...(afetGraphic9 &&
                                              afetGraphic9[0].formattedArray.flatMap(
                                                (item) =>
                                                  item.series.flatMap(
                                                    (serie) => serie.data
                                                  )
                                              ))
                                          )
                                        : 0,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  },
                          },
                          options:
                            afetGraphic9 && afetGraphic9[0].formattedArray,
                        },
                      },
                    }}
                  />
                </div> */}
              </div>

              <hr />

              <div className="mt-3 text-start">
                <div className="mb-3 row align-items-center justify-content-between">
                  <div className="col-6">
                    <h4>{context?.t?.okullar}</h4>
                  </div>

                  <div className="col w-auto flex-none">
                    <div className="d-flex gap-2">
                      <div className="text-end">
                        <span className="d-block">
                          {context?.t?.all_locations}
                        </span>
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          onClick={() =>
                            !cityValue
                              ? toastMessage(
                                  context?.t?.il_secmelisiniz,
                                  "error"
                                )
                              : allSchoolMarkers
                              ? setAllSchoolMarkers(null)
                              : handleLocationAllSchool()
                          }
                        >
                          {allSchoolMarkers
                            ? context?.t?.hide
                            : context?.t?.show}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dataTables_wrapper dt-bootstrap5">
                  {schoolsData ? (
                    schoolsData?.data?.length !== 0 ? (
                      <>
                        <div className="dataTables_scroll">
                          <div
                            className="dataTables_scrollBody table-responsive"
                            style={{ height: 400 }}
                          >
                            <table className="table text-nowrap w-100 dataTable mb-0 text-start">
                              <thead>
                                <tr>
                                  <th className="px-2">
                                    {context?.t?.okul_adi}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.devlet_ozel}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.address}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.ogrenci_sayisi}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.ogretmen_sayisi}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.ogretmen_basina_ogrenci_sayisi}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.derslik}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.ogrenci_basina_derslik_sayisi}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.kutuphane_kitap_sayisi}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.konferans_salon_sayisi}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.location}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {schoolsData.data.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item["okul"]}</td>
                                    <td>{item["devletozel"]}</td>
                                    <td>{item["adres"]}</td>
                                    <td>{item["ogrenci"]}</td>
                                    <td>{item["ogretmen"]}</td>
                                    <td>
                                      {item["ogretmenbasinaogrencisayisi"]}
                                    </td>
                                    <td>{item["derslik"]}</td>
                                    <td>
                                      {item["ogrencibasinadersliksayisi"]}
                                    </td>
                                    <td>{item["kutuphanekitapsayisi"]}</td>
                                    <td>{item["konferanssalonu"]}</td>
                                    <td>
                                      <span
                                        className="pointer text-theme text-underline"
                                        onClick={() => {
                                          setAllSchoolMarkers(null);
                                          setSchoolMarkerSelected(null);
                                          setSchoolMarker(
                                            schoolMarker
                                              ? schoolMarker.latitude ===
                                                  item.latitude &&
                                                schoolMarker.longitude ===
                                                  item.longitude
                                                ? null
                                                : item
                                              : item
                                          );
                                        }}
                                      >
                                        {schoolMarker
                                          ? schoolMarker.latitude ===
                                              item.latitude &&
                                            schoolMarker.longitude ===
                                              item.longitude
                                            ? context?.t?.hide
                                            : context?.t?.show
                                          : context?.t?.show}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="dataTables_paginate paging_simple_numbers d-flex flex-wrap justify-content-center text-center mt-3">
                          <ul className="pagination">
                            <li className={`paginate_button page-item`}>
                              <button
                                type="button"
                                onClick={() =>
                                  handlePageChangeSchool(currentPageSchool - 1)
                                }
                                className={`page-link pointer ${
                                  !schoolsData.prev_page_url ? "disabled" : ""
                                }`}
                              >
                                {context?.t?.prev}
                              </button>
                            </li>

                            {schoolsData.links.map(
                              (number, index) =>
                                index > 0 &&
                                index < schoolsData.links.length - 1 && (
                                  <li
                                    key={index}
                                    className={`paginate_button page-item ${
                                      currentPageSchool ===
                                      parseInt(number.label)
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      onClick={() =>
                                        handlePageChangeSchool(
                                          parseInt(number.label)
                                        )
                                      }
                                      className="page-link pointer"
                                    >
                                      {number.label}
                                    </div>
                                  </li>
                                )
                            )}

                            <li className={`paginate_button page-item`}>
                              <button
                                type="button"
                                onClick={() =>
                                  handlePageChangeSchool(currentPageSchool + 1)
                                }
                                className={`page-link pointer ${
                                  !schoolsData.next_page_url ? "disabled" : ""
                                }`}
                              >
                                {context?.t?.next}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <div className="text-center">
                        {context?.t?.analysis_data_not_added}
                      </div>
                    )
                  ) : (
                    <div className="spinner-border text-theme"></div>
                  )}
                </div>
              </div>

              <hr />

              <div className="mt-3 text-start">
                <div className="mb-3 row align-items-center justify-content-between">
                  <div className="col-6">
                    <h4>{context?.t?.healthcare_facilities}</h4>
                  </div>

                  <div className="col w-auto flex-none">
                    <div className="d-flex gap-2">
                      <div
                        className="w-100"
                        style={{ minWidth: 250, maxWidth: 250 }}
                      >
                        <span className="d-block">{context?.t?.category}</span>
                        <Select
                          options={tesislerSelectData}
                          value={tesislerSelectValue}
                          classNamePrefix="react-select"
                          noOptionsMessage={() => context?.t?.no_option}
                          placeholder=""
                          onChange={(e) => handleTesislerSelect(e)}
                        />
                      </div>
                      <div className="text-end">
                        <span className="d-block">
                          {context?.t?.all_locations}
                        </span>
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          onClick={() =>
                            !filterCity
                              ? toastMessage(
                                  context?.t?.il_secmelisiniz,
                                  "error"
                                )
                              : allTesisMarkers
                              ? setAllTesisMarkers(null)
                              : handleLocationAllTesis()
                          }
                        >
                          {allTesisMarkers
                            ? context?.t?.hide
                            : context?.t?.show}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dataTables_wrapper dt-bootstrap5">
                  {tesislerData ? (
                    tesislerData?.data?.length !== 0 ? (
                      <>
                        <div className="dataTables_scroll">
                          <div
                            className="dataTables_scrollBody table-responsive"
                            style={{ height: 400 }}
                          >
                            <table className="table text-nowrap w-100 dataTable mb-0 text-start">
                              <thead>
                                <tr>
                                  <th className="px-2">
                                    {context?.t?.kurum_adi}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.address}
                                  </th>
                                  <th className="px-2">{context?.t?.phone}</th>
                                  <th className="px-2">
                                    {context?.t?.web_site}
                                  </th>
                                  <th className="px-2">{context?.t?.city}</th>
                                  <th className="px-2">
                                    {context?.t?.district}
                                  </th>
                                  <th className="px-2">
                                    {context?.t?.category}
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{
                                      minWidth: 500,
                                      width: 500,
                                    }}
                                  >
                                    {context?.t?.bolumler}
                                  </th>

                                  <th className="px-2">
                                    {context?.t?.location}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {tesislerData.data.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item["kurum adı"]}</td>
                                    <td>{item["adres"]}</td>
                                    <td>{item["telefon"]}</td>
                                    <td>
                                      {item["web sitesi"] ? (
                                        <a
                                          href={item["web sitesi"]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {context?.t?.view}
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>{item["şehir"]}</td>
                                    <td>{item["i̇lçe"]}</td>
                                    <td>{item["kategori"]}</td>
                                    <td
                                      style={{
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      {item["bölümler"] ? (
                                        <div className="d-flex flex-wrap gap-1">
                                          {item["bölümler"]
                                            .split(",")
                                            .map((item) => (
                                              <span className="btn btn-sm btn-dark">
                                                {item}
                                              </span>
                                            ))}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </td>

                                    <td>
                                      <span
                                        className="pointer text-theme text-underline"
                                        onClick={() => {
                                          setAllTesisMarkers(null);
                                          setTesisMarkerSelected(null);
                                          setTesisMarker(
                                            tesisMarker
                                              ? tesisMarker.enlem ===
                                                  item.enlem &&
                                                tesisMarker.boylam ===
                                                  item.boylam
                                                ? null
                                                : item
                                              : item
                                          );
                                        }}
                                      >
                                        {tesisMarker
                                          ? tesisMarker.enlem === item.enlem &&
                                            tesisMarker.boylam === item.boylam
                                            ? context?.t?.hide
                                            : context?.t?.show
                                          : context?.t?.show}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="dataTables_paginate paging_simple_numbers d-flex flex-wrap justify-content-center text-center mt-3">
                          <ul className="pagination">
                            <li className={`paginate_button page-item`}>
                              <button
                                type="button"
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                className={`page-link pointer ${
                                  !tesislerData.prev_page_url ? "disabled" : ""
                                }`}
                              >
                                {context?.t?.prev}
                              </button>
                            </li>

                            {tesislerData.links.map(
                              (number, index) =>
                                index > 0 &&
                                index < tesislerData.links.length - 1 && (
                                  <li
                                    key={index}
                                    className={`paginate_button page-item ${
                                      currentPage === parseInt(number.label)
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      onClick={() =>
                                        handlePageChange(parseInt(number.label))
                                      }
                                      className="page-link pointer"
                                    >
                                      {number.label}
                                    </div>
                                  </li>
                                )
                            )}

                            <li className={`paginate_button page-item`}>
                              <button
                                type="button"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                                className={`page-link pointer ${
                                  !tesislerData.next_page_url ? "disabled" : ""
                                }`}
                              >
                                {context?.t?.next}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <div className="text-center">
                        {context?.t?.analysis_data_not_added}
                      </div>
                    )
                  ) : (
                    <div className="spinner-border text-theme"></div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  );
}
