import React, { useContext } from "react";

import { AppSettings } from "../../config/app-settings";

import PageIframe from "./pageIframe";

export default function KentmetreRaporu1() {
  const context = useContext(AppSettings);

  return (
    <>
      {context?.me?.customer?.reports.length !== 0 &&
      context?.me?.customer?.reports[0] ? (
        <PageIframe
          reportID={context?.me?.customer?.reports[0]?.value}
          apiKey={context?.me?.customer?.api_key}
        />
      ) : (
        <span>1. Rapor eklenmemiştir.</span>
      )}
    </>
  );
}
