import React, { useEffect, useRef } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const EChartExample11 = ({ setData }) => {
  const chartDivRef = useRef(null);

  useEffect(() => {
    // Create root element
    const root = am5.Root.new(chartDivRef.current);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    let maxLevels = 3;
    let maxNodes = 3;
    let maxValue = 100;

    // Your data here...
    let data = {
      name: "Tema",
      children: [
        {
          name: "Aile ve Çocuk",
          children: [
            {
              name: "Algılanan Davranış",
              children: [
                {
                  name: "Beklentiler",
                  value: 44,
                },
                {
                  name: "Algılar",
                  value: 76,
                },
              ],
            },
            {
              name: "Ekonomi",
              children: [
                {
                  name: "Geçim",
                  value: 23,
                },
              ],
            },
            {
              name: "Kişisel Norm",
              children: [
                {
                  name: "Güvenlik",
                  value: 23,
                },
                {
                  name: "Sağlık",
                  value: 45,
                },
              ],
            },
          ],
        },
        {
          name: "Altyapı",
          children: [
            {
              name: "Algılanan Davranış",
              children: [
                {
                  name: "Beklentiler",
                  value: 44,
                },
                {
                  name: "Algılar",
                  value: 76,
                },
              ],
            },
            {
              name: "Ekonomi",
              children: [
                {
                  name: "Geçim",
                  value: 23,
                },
              ],
            },
            {
              name: "Kişisel Norm",
              children: [
                {
                  name: "Güvenlik",
                  value: 23,
                },
                {
                  name: "Sağlık",
                  value: 45,
                },
              ],
            },
          ],
        },
        {
          name: "Hayat",
          children: [
            {
              name: "Algılanan Davranış",
              children: [
                {
                  name: "Beklentiler",
                  value: 44,
                },
                {
                  name: "Algılar",
                  value: 76,
                },
              ],
            },
            {
              name: "Ekonomi",
              children: [
                {
                  name: "Geçim",
                  value: 23,
                },
              ],
            },
            {
              name: "Kişisel Norm",
              children: [
                {
                  name: "Güvenlik",
                  value: 23,
                },
                {
                  name: "Sağlık",
                  value: 45,
                },
              ],
            },
          ],
        },
        {
          name: "Eğitim",
          children: [
            {
              name: "Algılanan Davranış",
              children: [
                {
                  name: "Beklentiler",
                  value: 44,
                },
                {
                  name: "Algılar",
                  value: 76,
                },
              ],
            },
            {
              name: "Ekonomi",
              children: [
                {
                  name: "Geçim",
                  value: 23,
                },
              ],
            },
            {
              name: "Kişisel Norm",
              children: [
                {
                  name: "Güvenlik",
                  value: 23,
                },
                {
                  name: "Sağlık",
                  value: 45,
                },
              ],
            },
          ],
        },
        {
          name: "Etkinlik",
          children: [
            {
              name: "Algılanan Davranış",
              children: [
                {
                  name: "Beklentiler",
                  value: 44,
                },
                {
                  name: "Algılar",
                  value: 76,
                },
              ],
            },
            {
              name: "Ekonomi",
              children: [
                {
                  name: "Geçim",
                  value: 23,
                },
              ],
            },
            {
              name: "Kişisel Norm",
              children: [
                {
                  name: "Güvenlik",
                  value: 23,
                },
                {
                  name: "Sağlık",
                  value: 45,
                },
              ],
            },
          ],
        },
        {
          name: "Sağlık",
          children: [
            {
              name: "Algılanan Davranış",
              children: [
                {
                  name: "Beklentiler",
                  value: 44,
                },
                {
                  name: "Algılar",
                  value: 76,
                },
              ],
            },
            {
              name: "Ekonomi",
              children: [
                {
                  name: "Geçim",
                  value: 23,
                },
              ],
            },
            {
              name: "Kişisel Norm",
              children: [
                {
                  name: "Güvenlik",
                  value: 23,
                },
                {
                  name: "Sağlık",
                  value: 45,
                },
              ],
            },
          ],
        },
        {
          name: "Kültür",
          children: [
            {
              name: "Algılanan Davranış",
              children: [
                {
                  name: "Beklentiler",
                  value: 44,
                },
                {
                  name: "Algılar",
                  value: 76,
                },
              ],
            },
            {
              name: "Ekonomi",
              children: [
                {
                  name: "Geçim",
                  value: 23,
                },
              ],
            },
            {
              name: "Kişisel Norm",
              children: [
                {
                  name: "Güvenlik",
                  value: 23,
                },
                {
                  name: "Sağlık",
                  value: 45,
                },
              ],
            },
          ],
        },
        {
          name: "Resmi",
          children: [
            {
              name: "Algılanan Davranış",
              children: [
                {
                  name: "Beklentiler",
                  value: 44,
                },
                {
                  name: "Algılar",
                  value: 76,
                },
              ],
            },
            {
              name: "Ekonomi",
              children: [
                {
                  name: "Geçim",
                  value: 23,
                },
              ],
            },
            {
              name: "Kişisel Norm",
              children: [
                {
                  name: "Güvenlik",
                  value: 23,
                },
                {
                  name: "Sağlık",
                  value: 45,
                },
              ],
            },
          ],
        },
        {
          name: "Spor",
          children: [
            {
              name: "Algılanan Davranış",
              children: [
                {
                  name: "Beklentiler",
                  value: 44,
                },
                {
                  name: "Algılar",
                  value: 76,
                },
              ],
            },
            {
              name: "Ekonomi",
              children: [
                {
                  name: "Geçim",
                  value: 23,
                },
              ],
            },
            {
              name: "Kişisel Norm",
              children: [
                {
                  name: "Güvenlik",
                  value: 23,
                },
                {
                  name: "Sağlık",
                  value: 45,
                },
              ],
            },
          ],
        },
        {
          name: "Üstyapı",
          children: [
            {
              name: "Algılanan Davranış",
              children: [
                {
                  name: "Beklentiler",
                  value: 44,
                },
                {
                  name: "Algılar",
                  value: 76,
                },
              ],
            },
            {
              name: "Ekonomi",
              children: [
                {
                  name: "Geçim",
                  value: 23,
                },
              ],
            },
            {
              name: "Kişisel Norm",
              children: [
                {
                  name: "Güvenlik",
                  value: 23,
                },
                {
                  name: "Sağlık",
                  value: 45,
                },
              ],
            },
          ],
        },
      ],
    };

    // Create wrapper container
    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    // Create series
    let series = container.children.push(
      am5hierarchy.Tree.new(root, {
        singleBranchOnly: false,
        downDepth: 1,
        initialDepth: 10,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
      })
    );

    series
      .get("colors")
      .set("colors", [
        am5.color(0x2b8e6d),
        am5.color(0x38b088),
        am5.color(0x1c664e),
        am5.color(0x38b088),
        am5.color(0x2b8e6d),
        am5.color(0x1c664e),
      ]);

    series.nodes.template.events.on("click", function (e) {
      if (e.target.dataItem.dataContext.name) {
        setData(true);
      } else {
        setData(false);
      }
    });

    // generateLevel(data, "", 0);

    series.data.setAll([data]);
    series.set("selectedDataItem", series.dataItems[0]);

    function generateLevel(data, name, level) {
      for (var i = 0; i < Math.ceil(maxNodes * Math.random()) + 1; i++) {
        let nodeName = name + "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[i];
        let child;
        if (level < maxLevels) {
          child = {
            name: nodeName + level,
          };

          if (level > 0 && Math.random() < 0.5) {
            child.value = Math.round(Math.random() * maxValue);
          } else {
            child.children = [];
            generateLevel(child, nodeName + i, level + 1);
          }
        } else {
          child = {
            name: name + i,
            value: Math.round(Math.random() * maxValue),
          };
        }
        data.children.push(child);
      }

      level++;
      return data;
    }

    // Make stuff animate on load
    series.appear(1000, 100);

    return () => {
      // Clean up the chart when the component is unmounted
      root.dispose();
    };
    // eslint-disable-next-line
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  return <div ref={chartDivRef} style={{ width: "100%", height: "800px" }} />;
};

export default EChartExample11;
