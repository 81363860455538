import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CountUp from "react-countup";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";
import toastMessage from "../ToastMessage";

import { Card, CardBody } from "../card/card";

export default function CardSosyoekonomik({
  title,
  path = "home",
  city,
  district,
  neighbourhood,
  street,
  graphic,
  type = 1,
}) {
  const context = useContext(AppSettings);

  const [data, setData] = useState();

  async function getData(dataName) {
    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "Sosyo Ekonomik",
          type: dataName,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Parametre",
                value: "select",
              },
              {
                key: "Deger",
                value: "avg",
              },
            ],
            condition: [
              city
                ? {
                    key: "Sehir",
                    value: context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Ilce",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response;
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    async function fetchData() {
      setData(null);

      const results = await Promise.all([getData("ses-raporu")]);

      if (results && results[0] && results[0].length !== 0) {
        setData(
          results[0].sort((a, b) => {
            const paramA = a.parametre;
            const paramB = b.parametre;

            if (paramA < paramB) return 1;
            if (paramA > paramB) return -1;
            return 0;
          })
        );
      } else {
        setData([]);
      }
    }

    // if (city) {
    fetchData();
    // }
  }, [city, district, neighbourhood, street]);

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl">
      <Card className="h-100">
        <CardBody>
          {data !== "filter" ? (
            data ? (
              data.length !== 0 ? (
                <>
                  <div className="d-flex fw-bold h6 mb-4 text-center">
                    <span className="flex-grow-1">{title.toUpperCase()}</span>
                  </div>

                  <div className="row align-items-center text-center">
                    <div className={"col-12"}>
                      <div className="lg text-inverse text-opacity-100 row g-3">
                        {type === 1 ? (
                          <>
                            {data.map((item, index) => (
                              <div key={index} className="col-6">
                                {item.parametre === "AA (%)"
                                  ? "A"
                                  : item.parametre === "A (%)"
                                  ? "B"
                                  : item.parametre === "B (%)"
                                  ? "C1"
                                  : item.parametre === "C (%)"
                                  ? "C2"
                                  : item.parametre.split(' (%)')[0]}
                                <br />
                                <strong className="h5">
                                  <CountUp
                                    decimal=","
                                    decimals={2}
                                    end={item.deger_avg}
                                  />
                                </strong>{" "}
                              </div>
                            ))}

                            {/* <div className="col-6">
                              AA
                              <br />
                              <strong className="h5">
                                <CountUp
                                  decimals={4}
                                  end={data[0][1464] || 0}
                                />
                              </strong>{" "}
                            </div>
                            <div className="col-6">
                              A
                              <br />
                              <strong className="h5">
                                <CountUp
                                  decimals={4}
                                  end={data[0][1466] || 0}
                                />
                              </strong>{" "}
                            </div>
                            <div className="col-6">
                              B
                              <br />
                              <strong className="h5">
                                <CountUp
                                  decimals={4}
                                  end={data[0][1467] || 0}
                                />
                              </strong>{" "}
                            </div>
                            <div className="col-6">
                              C
                              <br />
                              <strong className="h5">
                                <CountUp
                                  decimals={4}
                                  end={data[0][1468] || 0}
                                />
                              </strong>{" "}
                            </div>
                            <div className="col-6">
                              D
                              <br />
                              <strong className="h5">
                                <CountUp
                                  decimals={4}
                                  end={data[0][1469] || 0}
                                />
                              </strong>{" "}
                            </div>
                            <div className="col-6">
                              E
                              <br />
                              <strong className="h5">
                                <CountUp
                                  decimals={4}
                                  end={data[0][1470] || 0}
                                />
                              </strong>{" "}
                            </div> */}
                          </>
                        ) : (
                          type === 2 && (
                            <>
                              <div className="col-12">
                                <strong className="h5">65</strong>
                                <br />
                                TAHMİNİ BİRİNCİ DERECE ÜYE YAKINI SAYISI
                              </div>
                              <div className="col-12 mt-2">
                                <strong className="h5">25</strong>
                                <br />
                                TAHMİNİ İKİNCİ DERECE ÜYE YAKINI SAYISI
                              </div>
                            </>
                          )

                          // data[0].map((item, index) => (
                          // <div className="col-12" key={index}>
                          //   <strong className="h5">{item["difference"]}</strong>{" "}
                          //   FARK SAYISI
                          // </div>
                          // ))

                          // <div className="col-12">
                          //   <strong className="h5">
                          //     {data[0][1] ? Intl.NumberFormat("tr-TR", { style: "decimal", }).format(data[0][1][1443]) : "-"}
                          //   </strong>{" "}
                          // TAHMINI 1.
                          //     DERECE UYE VE UYE YAKINI SAYISI

                          //   TAHMINI 2. DERECE UYE YAKINI SAYISI
                          // </div>
                          // <div className="col-12">
                          //   <strong className="h5">
                          //     {data[0][2] ? Intl.NumberFormat("tr-TR", { style: "decimal", }).format(data[0][2]["difference"]) : "-"}
                          //   </strong>{" "}
                          //   FARK SAYISI
                          // </div>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-5">
                    <div className="mt-n2">
                      <Chart
                        type="donut"
                        height={45}
                        options={{
                          colors: [
                            "rgba(" + themeColorRgb + ", .10)",
                            "rgba(" + themeColorRgb + ", .30)",
                            "rgba(" + themeColorRgb + ", .50)",
                            "rgba(" + themeColorRgb + ", .70)",
                            "rgba(" + themeColorRgb + ", .90)",
                            "rgba(" + themeColorRgb + ", 1)",
                          ],
                          chart: { sparkline: { enabled: true } },
                          stroke: { show: false },
                          tooltip: {
                            x: { show: false },
                            y: {
                              title: {
                                formatter: function (seriesName) {
                                  return "";
                                },
                              },
                            },
                          },
                        }}
                        series={
                          type === 1
                            ? [
                                data[0][1464] ? data[0][1464] : 0,
                                data[0][1466] ? data[0][1466] : 0,
                                data[0][1467] ? data[0][1467] : 0,
                                data[0][1468] ? data[0][1468] : 0,
                                data[0][1469] ? data[0][1469] : 0,
                                data[0][1470] ? data[0][1470] : 0,
                              ]
                            : type === 2 && [
                                data[0][0][1442] ? data[0][0][1442] : 0,
                                data[0][1][1443] ? data[0][1][1443] : 0,
                                data[0][2]["difference"]
                                  ? data[0][2]["difference"]
                                  : 0,
                              ]
                        }
                      />
                    </div>
                  </div> */}
                  </div>
                </>
              ) : (
                <span className="d-block text-center">
                  {context?.t?.no_data_available}
                </span>
              )
            ) : (
              <div className="spinner-border text-theme"></div>
            )
          ) : (
            <span className="d-block text-center">
              {context?.t?.il_secmelisiniz}
            </span>
          )}
        </CardBody>
      </Card>

      <ToastContainer pauseOnFocusLoss={false} />
    </div>
  );
}
