import React, { useState } from "react";
import { Card, CardBody } from "../../components/card/card";

export default function SurveyDetailItem({ index, item, context }) {
  const [answersShow, setAnswersShow] = useState(false);

  return (
    <div className="border py-2 px-3 mb-2">
      <div className="d-flex align-items-center justify-content-between">
        <span>
          {index + 1}. {item?.report?.name}
          {item.status ? (
            <span className="text-theme ms-1">{context?.t?.yapildi}</span>
          ) : (
            <span className="text-danger ms-1">{context?.t?.yapilmadi}</span>
          )}
        </span>

        {item?.reply !== 0 &&
          item?.reply[0] &&
          item?.reply[0].reply &&
          item?.reply[0].reply.length !== 0 && (
            <button
              type="button"
              className="btn btn-outline-theme btn-sm"
              onClick={() => setAnswersShow(!answersShow)}
            >
              {context?.t?.cevaplar}/
              {answersShow ? context?.t?.hide : context?.t?.show}
            </button>
          )}
      </div>

      {answersShow &&
        (item?.reply !== 0 &&
        item?.reply[0] &&
        item?.reply[0].reply &&
        item?.reply[0].reply.length !== 0 ? (
          <div className="row g-2 mt-1">
            {item?.reply[0].reply[0].map((itemAnswer, indexAnswer) => (
              <div key={indexAnswer} className="col-12 col-md-6 col-lg-4">
                <Card className="h-100">
                  <CardBody className="d-flex flex-column overflow-hidden">
                    <div className="row text-white border-bottom pb-2 mb-3">
                      <span className="col">
                        <span className="fw-bold h5">{indexAnswer + 1}.</span>{" "}
                        Soru
                      </span>
                      <span className="col text-end">
                        {itemAnswer?.category?.name}
                      </span>
                    </div>
                    <div
                      className="flex-1"
                      style={{
                        maxHeight: 350,
                        overflowY: "auto",
                        overflowX: "hidden",
                        paddingRight: 10,
                        marginRight: -10,
                      }}
                    >
                      <div>
                        <span className="d-block h5 mb-3">
                          {itemAnswer?.question?.question}
                        </span>
                      </div>
                      <div>
                        <div
                          className="d-flex flex-wrap gap-1"
                          style={{ borderRadius: 8 }}
                        >
                          <span
                            className={`pe-none py-1 px-2 rounded fw-bold text-white bg-dark`}
                          >
                            {itemAnswer.answer.text ||
                              itemAnswer.answer.answer.answer}
                          </span>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
          </div>
        ) : (
          <span>{context?.t?.anket_cevaplanmamis}</span>
        ))}
    </div>
  );
}
