import React, { useState, useEffect } from "react";
import axios from "axios";
import { citiesData } from "./citiesData";
import Select from "react-select";

const API_KEY = "9b4bbf30228eb8528d36e79d05da1fac";

const WEATHER_API_URL = "https://api.openweathermap.org/data/2.5/weather";

function Weather() {
  const [city, setCity] = useState("İstanbul");
  const [weather, setWeather] = useState(null);

  const fetchWeather = async () => {
    try {
      const response = await axios.get(WEATHER_API_URL, {
        params: {
          q: city,
          appid: API_KEY,
          units: "metric",
          lang: "tr",
        },
      });
      setWeather(response.data);
    } catch (error) {
      console.error("Hava durumu alınamadı", error);
    }
  };

  useEffect(() => {
    fetchWeather();
    // eslint-disable-next-line
  }, [city]);

  return (
    weather && (
      <div className="weather-item d-flex align-items-center">
        <img
          src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
          alt={weather.name}
          width={32}
        />

        <div>
          <Select
            defaultValue={citiesData.filter((option) => option.value === city)}
            options={citiesData}
            classNamePrefix="react-select"
            className="text-uppercase mb-0"
            placeholder="Şehir Seçiniz..."
            onChange={(inputValue) => setCity(inputValue.value)}
            menuPlacement="top"
          />
          <div style={{ lineHeight: 1 }}>
            <span className="h6 text-theme" style={{ fontSize: 12 }}>
              {Math.ceil(weather.main.temp)}°C
            </span>
            <span className="ms-1 h6 opacity-90" style={{ fontSize: 10 }}>
              {weather.weather[0].description.toUpperCase()}
            </span>
          </div>
        </div>
      </div>
    )
  );
}

export default Weather;
