import React, { useContext, useEffect, useRef, useState } from "react";

import ReactECharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AppSettings } from "../../config/app-settings";
import PageChartCard from "../page-chart-card/pageChartCard";
import apiClient from "../../ApiClient";
import toastMessage from "../ToastMessage";
import { Card, CardBody } from "../card/card";

const ChartLineMultiple = ({
  setOlumDogumData,
  show = true,
  setNeighborhoodDeadthBirthData = false,
  id = null,
  title,
  path = "home",
  city,
  district,
  neighbourhood,
  graphic,
  card,
}) => {
  const chartRef = useRef(null);

  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [deathDateData, setDeathDateData] = useState([]);
  const [deathValueData, setDeathValueData] = useState([]);
  const [birthDateData, setBirthDateData] = useState([]);
  const [birthValueData, setBirthValueData] = useState([]);

  const option = {
    // Make gradient line here
    darkMode: window.location.host.includes("datakokpit") ? false : true,
    visualMap:
      graphic === 12
        ? [
            {
              show: false,
              type: "continuous",
              dimension: 0,
              min: 0,
              max: birthDateData.length + 10,
              inRange: {
                colorLightness: [1, 0],
              },
            },
            {
              show: false,
              type: "continuous",
              seriesIndex: 1,
              dimension: 0,
              min: 0,
              max: deathDateData.length + 10,
            },
          ]
        : [
            {
              show: false,
              type: "continuous",
              dimension: 0,
              min: 0,
              max: birthDateData.length + 10,
              inRange: {
                colorLightness: [1, 0],
              },
            },
          ],
    title:
      graphic === 12
        ? [
            {
              left: "center",
              text: context?.t?.dogum_oranlari,
              textStyle: {
                color: context?.theme === "light" ? "#000" : "#fff",
              },
            },
            {
              top: "50%",
              left: "center",
              text: context?.t?.olum_oranlari,
              textStyle: {
                color: context?.theme === "light" ? "#000" : "#fff",
              },
            },
          ]
        : [
            {
              show: false,
              left: "center",
              text: context?.t?.dogum_oranlari,
              textStyle: {
                color: context?.theme === "light" ? "#000" : "#fff",
              },
            },
          ],
    tooltip: {
      trigger: "axis",
    },
    xAxis:
      graphic === 12
        ? [
            {
              data: birthDateData,
              axisLabel: {
                textStyle: {
                  color: context?.theme === "light" ? "#000" : "#fff",
                },
              },
            },
            {
              data: deathDateData,
              gridIndex: 1,
              axisLabel: {
                textStyle: {
                  color: context?.theme === "light" ? "#000" : "#fff",
                },
              },
            },
          ]
        : [
            {
              data: [
                "AA-2014",
                "AA-2015",
                "AA-2016",
                "AA-2017",
                "AA-2018",
                "AA-2019",
                "AA-2020",
                "AA-2021",
                "AA-2022",
              ],
              axisLabel: {
                textStyle: {
                  color: context?.theme === "light" ? "#000" : "#fff",
                },
              },
            },
          ],
    yAxis:
      graphic === 12
        ? [
            {
              axisLabel: {
                textStyle: {
                  color: context?.theme === "light" ? "#000" : "#fff",
                },
              },
            },
            {
              gridIndex: 1,
              axisLabel: {
                textStyle: {
                  color: context?.theme === "light" ? "#000" : "#fff",
                },
              },
            },
          ]
        : [
            {
              axisLabel: {
                textStyle: {
                  color: context?.theme === "light" ? "#000" : "#fff",
                },
              },
            },
          ],
    grid:
      graphic === 12
        ? [
            {
              bottom: "60%",
            },
            {
              top: "60%",
            },
          ]
        : [
            {
              bottom: "10%",
            },
          ],
    series:
      graphic === 12
        ? [
            {
              type: "line",
              showSymbol: false,
              data: birthValueData,
              itemStyle: {
                color: window.location.host.includes("datakokpit")
                  ? "#17248a"
                  : "#3cd2a5",
              },
            },
            {
              type: "line",
              showSymbol: false,
              data: deathValueData,
              xAxisIndex: 1,
              yAxisIndex: 1,
              itemStyle: {
                color: window.location.host.includes("datakokpit")
                  ? "#17248a"
                  : "#3cd2a5",
              },
            },
          ]
        : [
            {
              type: "line",
              showSymbol: false,
              data: [5101, 4911, 4751, 4498, 4254, 3741, 3421, 3226, 3215],
              itemStyle: {
                color: window.location.host.includes("datakokpit")
                  ? "#17248a"
                  : "#3cd2a5",
              },
            },
          ],
  };

  async function getData(dataName) {
    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "Olum Sayısı",
          type: "demograf",
          description: "",
          customer: null,
          status: true,
          order: 1,
          connection_type: "local",
          sql: {
            column: [
              {
                key: "Tarih",
                value: "select",
              },
              {
                key: "Deger",
                value: "sum",
              },
            ],
            condition: [
              {
                key: "Alt-Kategori-1",
                value: dataName,
                operator: "=",
                type: "and",
              },
              city
                ? {
                    key: "Sehir",
                    value: context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Ilce",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.sort(
        (a, b) => a.tarih.split("-")[0] - b.tarih.split("-")[0]
      );
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    async function fetchData() {
      setData(null);

      const results = await Promise.all([getData("OLUM"), getData("DOGUM")]);

      let keysArrayDeath = [];
      let valuesArrayDeath = [];

      let keysArrayBirth = [];
      let valuesArrayBirth = [];

      if (
        results &&
        results[0] &&
        results[1] &&
        results[0].length !== 0 &&
        results[1].length !== 0
      ) {
        results[0].forEach(function (obj, index) {
          keysArrayDeath = keysArrayDeath.concat(obj.tarih.split("-")[0]);
          valuesArrayDeath = valuesArrayDeath.concat(obj.deger_sum);
        });

        //--

        results[1].forEach(function (obj, index) {
          keysArrayBirth = keysArrayBirth.concat(obj.tarih.split("-")[0]);
          valuesArrayBirth = valuesArrayBirth.concat(obj.deger_sum);
        });

        setDeathDateData(keysArrayDeath);
        setDeathValueData(valuesArrayDeath);

        setBirthDateData(keysArrayBirth);
        setBirthValueData(valuesArrayBirth);

        if (setNeighborhoodDeadthBirthData) {
          const reportTableWidthDeadth = [];
          const reportTableWidthBirth = [];

          keysArrayDeath.forEach((item) => {
            reportTableWidthDeadth.push("*");
          });

          keysArrayBirth.forEach((item) => {
            reportTableWidthBirth.push("*");
          });

          setNeighborhoodDeadthBirthData([
            reportTableWidthDeadth,
            keysArrayDeath,
            valuesArrayDeath,
            reportTableWidthBirth,
            keysArrayBirth,
            valuesArrayBirth,
          ]);
        }

        if (setOlumDogumData) {
          setOlumDogumData(results);
        }

        setData(results);
      } else {
        setData([]);

        if (setNeighborhoodDeadthBirthData) {
          setNeighborhoodDeadthBirthData([[], [], [], [], [], []]);
        }
      }
    }

    if (context?.me) {
      fetchData();
    }
  }, [city, district, context?.me]);

  return (
    show && (
      <>
        {show && data ? (
          data.length !== 0 ? (
            card ? (
              <PageChartCard
                id={id}
                title={title}
                tableData={data}
                path={path}
                city={city}
                district={district}
                neighbourhood={neighbourhood}
                graphic={graphic}
                chart={
                  <ReactECharts
                    option={option}
                    ref={chartRef}
                    className="w-100 h-100 pb-0"
                  />
                }
              />
            ) : (
              <ReactECharts
                option={option}
                ref={chartRef}
                className="w-100 h-100 pb-0"
              />
            )
          ) : (
            <div className="d-flex align-items-center justify-content-center h-100">
              <span className="d-block text-center">
                {context?.t?.no_data_available}
              </span>
            </div>
          )
        ) : (
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="spinner-border text-theme"></div>
          </div>
        )}
      </>
    )
  );
};

export default ChartLineMultiple;
