import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const options = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}

const toastMessage = (text, type = null) => {
    type ? toast[type](text, options) : toast(text, options);
};

export default toastMessage