import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function DynamicNote({ value, setValue }) {
  return (
    <div className="flex-1 overflow-auto d-flex flex-column h-100">
      {setValue ? (
        <CKEditor
          className="h-100"
          value={value || ""}
          data={value || ""}
          editor={ClassicEditor}
          onChange={(event, editor) => {
            const data = editor.getData();
            setValue(data);
          }}
        />
      ) : (
        <div
          className="overflow-auto text-white"
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      )}
    </div>
  );
}
