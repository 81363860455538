import React, { useContext, useRef, useEffect, useState } from "react";

import ReactECharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import egitimJson from "../../json/egitim.json";
import siyasetmetreAnalizJson from "../../json/siyasetmetre_analiz.json";
import siyasetmetreSecimJson from "../../json/siyasetmetre_secim.json";
import bireyselJson from "../../json/bireysel.json";
import { AppSettings } from "../../config/app-settings";
import PageChartCard from "../page-chart-card/pageChartCard";
import apiClient from "../../ApiClient";
import toastMessage from "../ToastMessage";
import { Card, CardBody } from "../card/card";

const ChartPie = ({
  show = true,
  setNeighborhoodCitizenshipData = false,
  setNeighborhoodVoterPoliticalData = false,
  setNeighborhoodVoterTypeData = false,
  setNeighborhoodPoliticalProfileData = false,
  setStreetPoliticalProfileData = false,
  getImage = false,
  id = null,
  title,
  path = "home",
  city,
  district,
  neighbourhood,
  street,
  graphic = null,
  card,
  height = true,
  parameterJson = false,
  buttons = false,
  optionData = null,
  chartRound,
  setChartRound,
  category,
  chartType = "pie",
  legend = true,
  parsentFalse = true,
  fixedTo = true,
  table = true,
  fullScreen = true,
  note = true,
}) => {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const chartRef = useRef(null);

  const [data, setData] = useState(null);
  const [seriesData, setSeriesData] = useState([]);
  const [cardFullScreen, setCardFullScreen] = useState(false);
  const [chartColor, setChartColor] = useState(null);

  useEffect(() => {
    setChartColor(context?.chartColor);
  }, [context]);

  let option = {
    darkMode: window.location.host.includes("datakokpit") ? false : true,
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        if (parameterJson === "analiz") {
          return `${parsentFalse ? "%" : ""}${
            fixedTo
              ? parseFloat(params.data.value).toFixed(2)
              : Intl.NumberFormat("tr-TR", {
                  style: "decimal",
                }).format(parseFloat(params.data.value))
          } - ${params.name}`;
        } else {
          return `${parsentFalse ? "%" : ""}${
            fixedTo
              ? parseFloat(params.data.value).toFixed(2)
              : Intl.NumberFormat("tr-TR", {
                  style: "decimal",
                }).format(parseFloat(params.data.value))
          }`;
        }
      },
    },
    legend: {
      show: legend
        ? path === "district-analysis"
          ? false
          : graphic === 10
          ? true
          : true
        : false,
      orient: "horizontal",
      left: "center",
      itemGap: 20,
      textStyle: {
        color: context?.theme === "light" ? "#000" : "#fff",
      },
    },
    visualMap: !chartColor
      ? false
      : {
          show: false,
          min:
            path === "district-analysis" && graphic === 4
              ? 50
              : Math.min(...seriesData.map((obj) => obj.value)),
          max: Math.max(...seriesData.map((obj) => obj.value)),
          inRange: {
            colorLightness: [0.2, 1],
          },
        },
    series: [
      {
        name: "",
        type: "pie",
        radius: "50%",
        data: seriesData,
        label: {
          textStyle: {
            fontSize: cardFullScreen ? 18 : 15,
          },
        },
        itemStyle: {
          color: chartColor,
        },
        animation: getImage ? false : true,
      },
    ],
  };

  async function getData() {
    setData(null);

    try {
      const response = await apiClient.get(
        `/${path}?graphic=${graphic}${city ? `&city=${city}` : ""}${
          district ? `&district=${district}` : ""
        }${neighbourhood ? `&neighbourhood=${neighbourhood}` : ""}${
          street ? `&street=${street}` : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      let keysArray = [];
      let valuesArray = [];
      let dataArray = [];

      if (response.status === 200) {
        if (path === "home" && graphic === 13) {
          let responseData;

          if (response.data[0].length) {
            responseData = response.data[0];
          } else {
            responseData = [response.data[0]];
          }

          responseData.forEach(function (obj, index) {
            var keys = Object.keys(obj);
            var values = Object.values(obj);

            keysArray = keysArray.concat(keys);
            valuesArray = valuesArray.concat(values);
          });

          for (var i = 0; i < keysArray.length; i++) {
            dataArray.push({
              name: siyasetmetreAnalizJson.parametre[parseInt(keysArray[i])],
              value: parseFloat(valuesArray[i]).toFixed(2),
            });
          }
        } else if (path === "home" && graphic === 10) {
          response.data[0].forEach((element) => {
            dataArray.push({
              name:
                siyasetmetreAnalizJson.alt_kategori_2[
                  parseInt(element["city"])
                ] +
                "-" +
                siyasetmetreAnalizJson.parametre[parseInt(element["district"])],
              value: element["citizenshipStatus"],
            });
          });

          if (setNeighborhoodCitizenshipData) {
            const reportTableWidth = ["*", "*", "*"];
            const reportTableValue = [["ŞEHİR", "İLÇE", "SAYI"]];

            response.data[0].forEach((element) => {
              reportTableValue.push([
                siyasetmetreAnalizJson.alt_kategori_2[
                  parseInt(element["city"])
                ],
                siyasetmetreAnalizJson.parametre[parseInt(element["district"])],
                element["citizenshipStatus"],
              ]);
            });

            setNeighborhoodCitizenshipData([
              reportTableWidth,
              reportTableValue,
            ]);
          }
        } else if (path === "district-analysis") {
          response.data[0].forEach((element) => {
            dataArray.push({
              name: egitimJson[category][parseInt(element["name"])],
              value: parseFloat(element["percentage"]).toFixed(2),
            });
          });
        } else if (path === "choice-analysis" && graphic === 12) {
          response.data[0].forEach((element) => {
            dataArray.push({
              name:
                bireyselJson.Siyasal_Kimlik_1[
                  parseInt(element["siyasal-kimlik"].split(" - ")[0])
                ] +
                " - " +
                bireyselJson.Siyasal_Kimlik_2[
                  parseInt(element["siyasal-kimlik"].split(" - ")[1])
                ],
              value: parseFloat(element["yuzde"]).toFixed(2),
            });
          });

          if (setNeighborhoodPoliticalProfileData) {
            const reportTableValue = [["Siyasal Kimlik", "Oran"]];

            response.data[0].forEach((element) => {
              reportTableValue.push([
                bireyselJson.Siyasal_Kimlik_1[
                  parseInt(element["siyasal-kimlik"].split(" - ")[0])
                ] +
                  " - " +
                  bireyselJson.Siyasal_Kimlik_2[
                    parseInt(element["siyasal-kimlik"].split(" - ")[1])
                  ],
                parseFloat(element["yuzde"]).toFixed(2),
              ]);
            });

            setNeighborhoodPoliticalProfileData([["*", "*"], reportTableValue]);
          }

          if (setStreetPoliticalProfileData) {
            const reportTableValue = [["Siyasal Kimlik", "Oran"]];

            response.data[0].forEach((element) => {
              reportTableValue.push([
                bireyselJson.Siyasal_Kimlik_1[
                  parseInt(element["siyasal-kimlik"].split(" - ")[0])
                ] +
                  " - " +
                  bireyselJson.Siyasal_Kimlik_2[
                    parseInt(element["siyasal-kimlik"].split(" - ")[1])
                  ],
                parseFloat(element["yuzde"]).toFixed(2),
              ]);
            });

            setStreetPoliticalProfileData([["*", "*"], reportTableValue]);
          }
        } else {
          let responseData;

          if (response.data[0].length) {
            responseData = response.data[0];
          } else {
            responseData = [response.data[0]];
          }

          responseData.forEach(function (obj, index) {
            var keys = Object.keys(obj);
            var values = Object.values(obj);

            keysArray = keysArray.concat(keys);
            valuesArray = valuesArray.concat(values);
          });

          if (path === "choice-analysis" && graphic === 7) {
            for (var i = 0; i < keysArray.length; i++) {
              dataArray.push({
                name:
                  siyasetmetreAnalizJson.parametre[parseInt(keysArray[i])] ||
                  "Bilinmiyor",
                value: parseFloat(valuesArray[i]).toFixed(2),
              });
            }
          } else if (parameterJson === "analiz") {
            for (var i = 0; i < keysArray.length; i++) {
              dataArray.push({
                name:
                  siyasetmetreAnalizJson.parametre[parseInt(keysArray[i])] ||
                  (path === "home" && graphic === 14
                    ? "TAHMİNİ HERHANGİ BİR ÜYE YAKINI OLMAYAN"
                    : "Bilinmiyor"),
                value: parseFloat(valuesArray[i]).toFixed(2),
              });
            }

            if (setNeighborhoodVoterPoliticalData) {
              const reportTableWidth = ["*", "*", "*"];
              const reportTableKey = [
                "TAHMINI 1. DERECE UYE VE UYE YAKINI SAYISI",
                "TAHMINI 2. DERECE UYE YAKINI SAYISI",
                "TAHMINI UYE YAKINI OLMAYAN SAYISI",
              ];
              const reportTableValue = [];

              for (var i = 0; i < keysArray.length; i++) {
                reportTableValue.push(
                  Intl.NumberFormat("tr-TR", {
                    style: "decimal",
                  }).format(parseFloat(valuesArray[i]).toFixed(2))
                );
              }

              setNeighborhoodVoterPoliticalData([
                reportTableWidth,
                reportTableKey,
                reportTableValue,
              ]);
            }
          } else if (parameterJson === "secmen-profili") {
            for (var i = 0; i < keysArray.length; i++) {
              dataArray.push({
                name:
                  bireyselJson.Secmen_Profili[parseInt(keysArray[i])] ||
                  "Bilinmiyor",
                value: parseFloat(valuesArray[i]).toFixed(2),
              });
            }

            if (setNeighborhoodVoterTypeData) {
              const reportTableWidth = [];
              const reportTableKey = [];
              const reportTableValue = [];

              for (var i = 0; i < keysArray.length; i++) {
                reportTableWidth.push("*");
                reportTableKey.push(
                  bireyselJson.Secmen_Profili[parseInt(keysArray[i])] ||
                    "Bilinmiyor"
                );
                reportTableValue.push(parseFloat(valuesArray[i]));
              }

              setNeighborhoodVoterTypeData([
                reportTableWidth,
                reportTableKey,
                reportTableValue,
              ]);
            }
          } else if (parameterJson === "bireysel") {
            for (var i = 0; i < keysArray.length; i++) {
              dataArray.push({
                name:
                  bireyselJson.parametre[
                    parseInt(keysArray[i].split(" - ")[0])
                  ] +
                  " - " +
                  bireyselJson.parametre[
                    parseInt(keysArray[i].split(" - ")[1])
                  ],
                value: parseFloat(valuesArray[i]).toFixed(2),
              });
            }
          } else if (parameterJson) {
            if (path === "home" && graphic === 23) {
              if (!district && !neighbourhood && !street) {
                dataArray.push({
                  name: "CHP",
                  value: 48.8,
                });
                dataArray.push({
                  name: "AK PARTI",
                  value: 48.55,
                });
                dataArray.push({
                  name: "SAADET",
                  value: 1.21,
                });
                dataArray.push({
                  name: "DSP",
                  value: 0.36,
                });
                dataArray.push({
                  name: "BTP",
                  value: 0.32,
                });
              } else {
                for (var i = 0; i < keysArray.length; i++) {
                  dataArray.push({
                    name:
                      siyasetmetreSecimJson.parametre[parseInt(keysArray[i])] ||
                      "Bilinmiyor",
                    value: parseFloat(valuesArray[i]).toFixed(2),
                  });
                }
              }
            } else {
              for (var i = 0; i < keysArray.length; i++) {
                dataArray.push({
                  name:
                    siyasetmetreSecimJson.parametre[parseInt(keysArray[i])] ||
                    "Bilinmiyor",
                  value: parseFloat(valuesArray[i]).toFixed(2),
                });
              }
            }
          } else {
            for (var i = 0; i < keysArray.length; i++) {
              dataArray.push({
                name: keysArray[i] || "Bilinmiyor",
                value: parseFloat(valuesArray[i]).toFixed(2),
              });
            }
          }
        }

        setData(response.data);
        setSeriesData(dataArray);
      }
    } catch (error) {
      console.error(title + " Error", error);
      setData(null);

      if (error.response.status === 500) {
        toastMessage("500 | Sunucu Hatası!", "error");
      } else if (error.response.status === 404) {
        navigate("/404");
      } else if (error.response.status === 403) {
        toastMessage("Çok fazla istek atıldı.", "error");
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      } else {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }
    }
  }

  useEffect(() => {
    if (path !== "chest-analysis" && graphic) {
      getData();
    } else {
      if (optionData) {
        const dataArray = [];

        if (optionData.length !== 0) {
          if (title === "SİYASAL KİMLİK") {
            optionData[0].forEach((item) => {
              dataArray.push({
                name:
                  bireyselJson.Siyasal_Kimlik_1[item.Parametre] || "Bilinmiyor",
                value: item.Yuzde,
              });
            });
          } else if (title === "SEÇMEN PROFİLİ") {
            optionData[0].forEach((item) => {
              dataArray.push({
                name:
                  bireyselJson.Secmen_Profili[item.Parametre] || "Bilinmiyor",
                value: item.Yuzde,
              });
            });
          } else if (
            title === "Seçmen Öncelikli Hizmet Teması Grafiği" ||
            title === "Seçmen Öncelikli Şikayet Teması Grafiği"
          ) {
            optionData.forEach((item) => {
              dataArray.push({
                name: item.name,
                value: item.value,
              });
            });
          } else {
            optionData[0].forEach((item) => {
              dataArray.push({
                name:
                  parameterJson === "chest-analysis"
                    ? siyasetmetreSecimJson.parametre[item.Parametre] ||
                      "Bilinmiyor"
                    : item.Parametre,
                value: item.Yuzde,
              });
            });
          }

          setData(dataArray);
          setSeriesData(dataArray);
        } else {
          setData([[]]);
          setSeriesData([]);
        }
      }
    }
    // eslint-disable-next-line
  }, [
    chartColor,
    path,
    optionData,
    chartRound,
    city,
    district,
    neighbourhood,
    street,
  ]);

  // For Voice
  const utterance = new SpeechSynthesisUtterance();

  const [readNote, setReadNote] = useState(true);

  function readPublicNote(text) {
    setReadNote(!readNote);

    // if (readNote) {
    //   utterance.text = text;
    //   utterance.lang = "tr-TR";
    //   utterance.rate = 0.8;
    //   utterance.pitch = 0.8;
    //   window.speechSynthesis.speak(utterance);
    // } else {
    //   window.speechSynthesis.cancel();
    // }
  }

  // Create Image
  const createImage = () => {
    if (getImage && chartRef && chartRef.current) {
      const chart = chartRef.current.getEchartsInstance();
      const base64Image = chart.getDataURL({
        pixelRatio: 2,
        backgroundColor: "#212d38",
      });
      getImage(base64Image);
      return base64Image;
    }
  };

  useEffect(() => {
    createImage();
  }, [data, card, chartRef, getImage]);

  return (
    <>
      {show &&
        (data ? (
          data[0].length !== 0 ? (
            card ? (
              <PageChartCard
                id={id}
                path={path}
                city={city}
                district={district}
                neighbourhood={neighbourhood}
                street={street}
                graphic={graphic}
                chartType={chartType}
                category={category}
                tableData={data[0]}
                parameterJson={parameterJson}
                title={title}
                setCardFullScreen={setCardFullScreen}
                chart={
                  !buttons ? (
                    <ReactECharts
                      ref={chartRef}
                      option={option}
                      className={`w-100 h-100`}
                    />
                  ) : (
                    <>
                      <ReactECharts
                        ref={chartRef}
                        option={option}
                        className={`w-100 flex-1`}
                      />

                      {/* <div style={{ textAlign: "center", marginTop: "20px" }}>
                      <img
                        src={createImage()}
                        alt="Grafik Resmi"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </div> */}

                      <div className="d-flex justify-content-center gap-2">
                        <button
                          onClick={() => setChartRound(1)}
                          className={`btn btn-theme fw-bold ${
                            chartRound !== 1 && "opacity-50"
                          }`}
                        >
                          Tur 1
                        </button>
                        <button
                          onClick={() => setChartRound(2)}
                          className={`btn btn-theme fw-bold ${
                            chartRound !== 2 && "opacity-50"
                          }`}
                        >
                          Tur 2
                        </button>
                      </div>
                    </>
                  )
                }
                height={height ? "auto" : false}
                table={table}
                note={note}
                fullScreen={fullScreen}
                readNote={readNote}
                readPublicNote={readPublicNote}
              />
            ) : (
              <ReactECharts
                ref={chartRef}
                option={option}
                className="w-100 h-100"
              />
            )
          ) : (
            <Card>
              <CardBody>
                <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
                  <span className="fw-bold h6 mb-0 d-flex align-items-center">
                    {title.toUpperCase()}
                  </span>
                </div>

                <span className="d-block text-center">
                  Veri Bulunmamaktadır
                </span>
              </CardBody>
            </Card>
          )
        ) : (
          <Card>
            <CardBody>
              <div className="spinner-border text-theme"></div>
            </CardBody>
          </Card>
        ))}

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
};

export default ChartPie;
