import React, { useEffect, useState, useContext } from "react";

import ChartBar from "../../components/echart/ChartBar.jsx";
import ChartPie from "../../components/echart/ChartPie.jsx";
import ChartBarTimeLine from "../../components/echart/ChartBarTimeLine.jsx";
import ChartLineMultiple from "../../components/echart/ChartLineMultiple.jsx";
import PageFilter from "../../components/PageFilter.jsx";
import PageLoader from "../../components/PageLoader.jsx";
import CardBinaHane from "../../components/info-cards/CardBinaHane.jsx";
import CardMedeniDurum from "../../components/info-cards/CardMedeniDurum.jsx";
import CardVatandasSayisi from "../../components/info-cards/CardVatandasSayisi.jsx";
import CardOlumSayisi from "../../components/info-cards/CardOlumSayisi.jsx";
import CardHemsehrilikGoc from "../../components/info-cards/CardHemsehrilikGoc.jsx";
import CardSosyoekonomik from "../../components/info-cards/CardSosyoekonomik.jsx";
import KentmetreMap from "./KentmetreMap.jsx";
import EchartRace from "../../components/echart/echartRace.jsx";
import apiClient from "../../ApiClient.js";
import PageChartCard from "../../components/page-chart-card/pageChartCard.jsx";
import ChartSankey from "../../components/echart/ChartSankey";
import ChartSankeyReverse from "../../components/echart/ChartSankeyReverse.jsx";
import { Card, CardBody } from "../../components/card/card.jsx";
import DinamikPage from "../dinamik-page/index.jsx";
import DynamicCard from "../../components/DynamicCard.jsx";
import { AppSettings } from "../../config/app-settings.js";
import { useLocation, useNavigate } from "react-router-dom";

export default function Anasayfa({
  show = true,
  setNeighborhoodAgeData,
  setNeighborhoodVoterPoliticalData,
  setNeighborhoodCitizenshipData,
  city,
  district,
  neighbourhood,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const menu = location.search.split("id=")
    ? location.search.split("id=")[1]
    : null;

  useEffect(() => {
    console.log(process.env.NODE_ENV);

    if (!menu) {
      navigate("/durum-analizi?id=51");
    }
  }, [menu]);

  const context = useContext(AppSettings);

  const [loading, setLoading] = useState(false);

  const [cityFilter, setCityFilter] = useState(null);
  const [districtFilter, setDistrictFilter] = useState(null);
  const [neighbourhoodFilter, setNeighbourhoodFilter] = useState(null);

  // Chart Data
  const [yasGrubunaGoreChartOptions, setYasGrubunaGoreChartOptions] =
    useState(null);
  const [hemSehrilikDurumuData, setHemSehrilikDurumuData] = useState(null);
  const [secmenSiyasiDurumData, setSecmenSiyasiDurumData] = useState(null);
  const [internetData, setInternetData] = useState(null);

  async function fetchGenelData(type, city, district, neighbourhood) {
    setYasGrubunaGoreChartOptions(null);
    setHemSehrilikDurumuData(null);
    setSecmenSiyasiDurumData(null);
    setInternetData(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type:
            type === "İNTERNET"
              ? "internet-kullanim-amaclari-2"
              : type === "YAS"
              ? "demograf"
              : `hemsehri-goc`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column:
              type === "İNTERNET"
                ? [
                    {
                      key: "Parametre",
                      value: "select",
                    },
                    {
                      key: "Deger",
                      value: "sum",
                    },
                  ]
                : type === "SIYASI DURUMU"
                ? [
                    {
                      key: "Tahmini 1. Derece Ailede Uyelik",
                      value: "select",
                    },
                    {
                      key: "Tahmini 2. Derece Ailede Uyelik",
                      value: "select",
                    },
                    {
                      key: "TC",
                      value: "select",
                    },
                  ]
                : type === "YAS"
                ? [
                    {
                      key: "Tarih",
                      value: "select",
                    },
                    {
                      key: "Parametre",
                      value: "select",
                    },
                    {
                      key: "Deger",
                      value: "sum",
                    },
                  ]
                : [
                    {
                      key: "Alt-Kategori-4",
                      value: "select",
                    },
                    {
                      key: "Parametre",
                      value: "select",
                    },
                    {
                      key: "Deger",
                      value: "sum",
                    },
                  ],
            condition: [
              type === "HEM SEHRILIK"
                ? {
                    key: "Alt-Kategori-1",
                    value: "ILCE BAZINDA HEMSEHRILIK ORANLARI",
                    operator: "=",
                    type: "and",
                  }
                : null,
              type === "YAS"
                ? {
                    key: "Alt-Kategori-1",
                    value: "YAS DAGILIMI",
                    operator: "=",
                    type: "and",
                  }
                : null,
              city
                ? {
                    key: "Sehir",
                    value: context
                      .removeTurkishCharacters(city.label)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Ilce",
                    value: context
                      .removeTurkishCharacters(district.label)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood.label)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // HEM SEHRILIK
      if (type === "HEM SEHRILIK") {
        if (response.length !== 0) {
          const mappedData = response.map((item) => {
            return {
              name: `${item["alt-kategori-4"]} - ${item["parametre"]}`,
              value: item.deger_sum,
            };
          });

          const sortedData = mappedData
            .sort((a, b) => b.value - a.value)
            .slice(0, 20);

          setHemSehrilikDurumuData(sortedData);

          if (setNeighborhoodCitizenshipData) {
            const transformedData = [["*", "*", "*"]];

            const headers = [
              context?.t?.city,
              context?.t?.district,
              context?.t?.integer,
            ];
            const rows = mappedData
              .sort((a, b) => b.value - a.value)
              .map((item) => {
                const [city, district] = item.name.split(" - ");
                return [city.trim(), district.trim(), item.value.toString()];
              });

            transformedData.push([headers, ...rows]);
            setNeighborhoodCitizenshipData(transformedData);
          }
        } else {
          setHemSehrilikDurumuData([]);

          if (setNeighborhoodCitizenshipData) {
            setNeighborhoodCitizenshipData([[], [[], []]]);
          }
        }
      }

      // YAS GRUBU
      if (type === "YAS") {
        if (response.length !== 0) {
          const sortedResponse = response.sort((a, b) => {
            const aStart = parseInt(a.parametre.split("-")[0].replace("+", ""));
            const bStart = parseInt(b.parametre.split("-")[0].replace("+", ""));
            return aStart - bStart;
          });

          let yasGrubunaGoreDataX = sortedResponse.map(function (obj) {
            return obj.parametre;
          });

          let yasGrubunaGoreDataY = sortedResponse.map(function (obj) {
            return obj.deger_sum;
          });

          setYasGrubunaGoreChartOptions([
            yasGrubunaGoreDataX,
            yasGrubunaGoreDataY,
          ]);

          if (setNeighborhoodAgeData) {
            const reportTableWidth = [];

            yasGrubunaGoreDataX.forEach((item) => {
              reportTableWidth.push("auto");
            });

            setNeighborhoodAgeData([
              reportTableWidth,
              yasGrubunaGoreDataX.map((item) => {
                return item + " " + context?.t?.yas_grubu;
              }),
              yasGrubunaGoreDataY,
            ]);
          }
        } else {
          setYasGrubunaGoreChartOptions([[], []]);
          if (setNeighborhoodAgeData) {
            setNeighborhoodAgeData([[], [], []]);
          }
        }
      }
    } catch (error) {
      console.error("Genel Data Error", error);
      setSecmenSiyasiDurumData(null);
      setInternetData(null);
      setYasGrubunaGoreChartOptions(null);
      setHemSehrilikDurumuData(null);
    }
  }

  useEffect(() => {
    fetchGenelData(
      "HEM SEHRILIK",
      cityFilter,
      districtFilter,
      neighbourhoodFilter
    );
    // fetchGenelData("YAS", cityFilter, districtFilter, neighbourhoodFilter);
  }, [cityFilter, districtFilter, neighbourhoodFilter, context?.chartColor]);

  // useEffect(() => {
  //   fetchGenelData("YAS", city, district, neighbourhood);
  // }, [setNeighborhoodAgeData, city, district, neighbourhood]);

  useEffect(() => {
    fetchGenelData("HEM SEHRILIK", city, district, neighbourhood);
  }, [setNeighborhoodCitizenshipData, city, district, neighbourhood]);

  const [yasGruplariData, setYasGruplariData] = useState(null);
  const [sesRaporuData, setSesRaporuData] = useState(null);
  const [egitimData, setEgitimData] = useState(null);
  const [medeniDurumData, setMedeniDurumData] = useState(null);
  const [olumDogumData, setOlumDogumData] = useState(null);
  const [sankeyReverseData, setSankeyReverseData] = useState(null);
  const [sankeyData, setSankeyData] = useState(null);

  return loading ? (
    <PageLoader loading={loading} setLoading={setLoading} />
  ) : (
    show && (
      <div className="row g-4 mb-4 zoom-item">
        <PageFilter
          cityFilter={cityFilter}
          districtFilter={districtFilter}
          neighbourhoodFilter={neighbourhoodFilter}
          setCityFilter={setCityFilter}
          setDistrictFilter={setDistrictFilter}
          setNeighbourhoodFilter={setNeighbourhoodFilter}
          street={false}
          allActive={true}
        />

        <div className="col-12" style={{ height: "calc(100vh - 200px)" }}>
          <div className="row g-4 h-100">
            <div className="col-12 h-100">
              <div className="row flex-column h-100 g-4 position-relative">
                <div className="col md-mb-n4">
                  <KentmetreMap
                    tab={true}
                    setCityFilter={setCityFilter}
                    setDistrictFilter={setDistrictFilter}
                    setNeighbourhoodFilter={setNeighbourhoodFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 map-fullscreen-hide">
          <div className="row g-4">
            <div className="col-12">
              <div className="row g-4">
                <div className="col-12 col-xl-4">
                  <div
                    className="row flex-column g-4"
                    style={{ height: "calc(600px + 25px)" }}
                  >
                    <div className="flex-1 overflow-hidden">
                      <DynamicCard
                        ai={true}
                        id={`homepage-1-${
                          cityFilter ? `${cityFilter.label}` : "all"
                        }${
                          districtFilter
                            ? `${cityFilter ? "-" : ""}${districtFilter.label}`
                            : ""
                        }${
                          neighbourhoodFilter
                            ? `${districtFilter ? "-" : ""}${
                                neighbourhoodFilter.label
                              }`
                            : ""
                        }`}
                        aiData={yasGruplariData}
                        fullScreen={true}
                        height="h-full"
                        events={false}
                        item={{
                          title: context?.t?.yas_grubuna_gore_birey_sayilari,
                        }}
                        chart={
                          <ChartBarTimeLine
                            type="yas"
                            setYasGruplariData={setYasGruplariData}
                            title={context?.t?.yas_grubuna_gore_birey_sayilari}
                            city={cityFilter?.label}
                            district={districtFilter?.label}
                            neighbourhood={neighbourhoodFilter?.label}
                            card={false}
                          />
                        }
                      />

                      {/* <DynamicCard
                        ai={true}
                        id={`homepage-1-${
                          cityFilter ? `${cityFilter.label}` : "all"
                        }${
                          districtFilter
                            ? `${cityFilter ? "-" : ""}${districtFilter.label}`
                            : ""
                        }${
                          neighbourhoodFilter
                            ? `${districtFilter ? "-" : ""}${
                                neighbourhoodFilter.label
                              }`
                            : ""
                        }`}
                        aiData={
                          yasGrubunaGoreChartOptions &&
                          yasGrubunaGoreChartOptions[0] &&
                          yasGrubunaGoreChartOptions[0].map((label, index) => ({
                            label: label,
                            value: yasGrubunaGoreChartOptions[1][index],
                          }))
                        }
                        height="h-full"
                        fullScreen={true}
                        cityFilter={cityFilter}
                        data={
                          yasGrubunaGoreChartOptions &&
                          yasGrubunaGoreChartOptions[0]
                        }
                        item={{
                          title: context?.t?.yas_grubuna_gore_birey_sayilari,
                          options: {
                            options: {
                              grid: {
                                bottom: "10%",
                              },
                              xAxis: [
                                {
                                  data:
                                    yasGrubunaGoreChartOptions &&
                                    yasGrubunaGoreChartOptions[0],
                                  type: "category",
                                  axisLabel: {
                                    rotate: 45,
                                    textStyle: {
                                      color: "#fff",
                                      fontSize: 10,
                                    },
                                  },
                                },
                              ],
                              yAxis: [
                                {
                                  type: "value",
                                  axisLabel: {
                                    textStyle: {
                                      color: "#fff",
                                    },
                                  },
                                },
                              ],
                              series: [
                                {
                                  data:
                                    yasGrubunaGoreChartOptions &&
                                    yasGrubunaGoreChartOptions[1],
                                  name: null,
                                  type: "bar",
                                  itemStyle: {
                                    color: context?.chartColor,
                                  },
                                  markPoint: {
                                    data: [
                                      {
                                        name: "Max",
                                        type: "max",
                                      },
                                      {
                                        name: "Min",
                                        type: "min",
                                      },
                                    ],
                                  },
                                },
                              ],
                              toolbox: {
                                show: true,
                                feature: {
                                  restore: {
                                    show: false,
                                  },
                                  magicType: {
                                    show: true,
                                    type: ["line", "bar"],
                                  },
                                  saveAsImage: {
                                    show: false,
                                  },
                                },
                                iconStyle: {
                                  borderColor: "#fff",
                                },
                              },
                              tooltip: {
                                trigger: "axis",
                              },
                              darkMode: true,
                              visualMap: context?.chartColor
                                ? {
                                    max:
                                      yasGrubunaGoreChartOptions &&
                                      yasGrubunaGoreChartOptions[1]
                                        ? Math.max(
                                            ...yasGrubunaGoreChartOptions[1].map(
                                              (item) => item
                                            )
                                          )
                                        : 0,
                                    min: 0,
                                    show: false,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  }
                                : false,
                              calculable: true,
                            },
                          },
                        }}
                      /> */}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-4" style={{ height: 600 }}>
                  <div className="row h-100 g-4">
                    <div className="col-12 h-100">
                      <DynamicCard
                        ai={true}
                        id={`homepage-2-${
                          cityFilter ? `${cityFilter.label}` : "all"
                        }${
                          districtFilter
                            ? `${cityFilter ? "-" : ""}${districtFilter.label}`
                            : ""
                        }${
                          neighbourhoodFilter
                            ? `${districtFilter ? "-" : ""}${
                                neighbourhoodFilter.label
                              }`
                            : ""
                        }`}
                        aiData={hemSehrilikDurumuData}
                        fullScreen={true}
                        height="h-full"
                        events={false}
                        cityFilter={cityFilter}
                        data={hemSehrilikDurumuData}
                        item={{
                          title: context?.t?.birey_hemsehrilik_durumu,
                          options: {
                            options: {
                              series: [
                                {
                                  data: hemSehrilikDurumuData,
                                  name: null,
                                  type: "pie",
                                  label: {
                                    textStyle: {
                                      fontSize: 15,
                                    },
                                  },
                                  radius: "50%",
                                  itemStyle: {
                                    color: context?.chartColor,
                                  },
                                },
                              ],
                              tooltip: {
                                trigger: "item",
                              },
                              darkMode: true,
                              visualMap: context?.chartColor
                                ? {
                                    min:
                                      hemSehrilikDurumuData &&
                                      hemSehrilikDurumuData.length !== 0
                                        ? Math.min(
                                            ...hemSehrilikDurumuData.map(
                                              (obj) => obj.value
                                            )
                                          )
                                        : 0,
                                    max:
                                      hemSehrilikDurumuData &&
                                      hemSehrilikDurumuData.length !== 0
                                        ? Math.max(
                                            ...hemSehrilikDurumuData.map(
                                              (obj) => obj.value
                                            )
                                          )
                                        : 0,
                                    show: false,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  }
                                : false,
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-4" style={{ height: 600 }}>
                  <div className="row h-100 g-4">
                    <div className="col-12 h-100">
                      <DynamicCard
                        ai={true}
                        id={`homepage-3-${
                          cityFilter ? `${cityFilter.label}` : "all"
                        }${
                          districtFilter
                            ? `${cityFilter ? "-" : ""}${districtFilter.label}`
                            : ""
                        }${
                          neighbourhoodFilter
                            ? `${districtFilter ? "-" : ""}${
                                neighbourhoodFilter.label
                              }`
                            : ""
                        }`}
                        aiData={medeniDurumData}
                        fullScreen={true}
                        height="h-full"
                        events={false}
                        item={{
                          title:
                            context?.t
                              ?.yillara_gore_secmen_medeni_durum_dagilimi,
                        }}
                        chart={
                          <ChartBarTimeLine
                            type="medeni-durum"
                            setMedeniDurumData={setMedeniDurumData}
                            id={13}
                            title={
                              context?.t?.yillara_gore_dogum_ve_olum_oranlari
                            }
                            city={cityFilter?.label}
                            district={districtFilter?.label}
                            neighbourhood={neighbourhoodFilter?.label}
                            graphic={12}
                            card={false}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="row g-4">
                <div className="col-12 col-xl-4" style={{ height: 600 }}>
                  <DynamicCard
                    ai={true}
                    id={`homepage-4-${
                      cityFilter ? `${cityFilter.label}` : "all"
                    }${
                      districtFilter
                        ? `${cityFilter ? "-" : ""}${districtFilter.label}`
                        : ""
                    }${
                      neighbourhoodFilter
                        ? `${districtFilter ? "-" : ""}${
                            neighbourhoodFilter.label
                          }`
                        : ""
                    }`}
                    aiData={sesRaporuData}
                    fullScreen={true}
                    events={false}
                    height="h-full"
                    item={{
                      title:
                        context?.t?.yillara_gore_sosyoekonomik_statu_dagilimi,
                    }}
                    chart={
                      <ChartBarTimeLine
                        type="ses-raporu"
                        setSesRaporuData={setSesRaporuData}
                        id={13}
                        title={context?.t?.yillara_gore_dogum_ve_olum_oranlari}
                        city={cityFilter?.label}
                        district={districtFilter?.label}
                        neighbourhood={neighbourhoodFilter?.label}
                        graphic={12}
                        card={false}
                      />
                    }
                  />
                </div>

                <div className="col-12 col-xl-4" style={{ height: 600 }}>
                  <DynamicCard
                    ai={true}
                    id={`homepage-5-${
                      cityFilter ? `${cityFilter.label}` : "all"
                    }${
                      districtFilter
                        ? `${cityFilter ? "-" : ""}${districtFilter.label}`
                        : ""
                    }${
                      neighbourhoodFilter
                        ? `${districtFilter ? "-" : ""}${
                            neighbourhoodFilter.label
                          }`
                        : ""
                    }`}
                    aiData={egitimData}
                    fullScreen={true}
                    events={false}
                    height="h-full"
                    item={{
                      title: context?.t?.secmen_genel_egitim_durumu,
                    }}
                    chart={
                      <ChartBarTimeLine
                        type="egitim"
                        setEgitimData={setEgitimData}
                        id={12}
                        title={context?.t?.secmen_genel_egitim_durumu}
                        city={cityFilter?.label}
                        district={districtFilter?.label}
                        neighbourhood={neighbourhoodFilter?.label}
                        path="voter-analysis"
                        graphic={3}
                        card={false}
                      />
                    }
                  />
                </div>

                <div className="col-12 col-xl-4" style={{ height: 600 }}>
                  <DynamicCard
                    ai={true}
                    id={`homepage-6-${
                      cityFilter ? `${cityFilter.label}` : "all"
                    }${
                      districtFilter
                        ? `${cityFilter ? "-" : ""}${districtFilter.label}`
                        : ""
                    }${
                      neighbourhoodFilter
                        ? `${districtFilter ? "-" : ""}${
                            neighbourhoodFilter.label
                          }`
                        : ""
                    }`}
                    aiData={olumDogumData}
                    fullScreen={true}
                    height="h-full"
                    events={false}
                    item={{
                      title: context?.t?.yillara_gore_dogum_ve_olum_oranlari,
                    }}
                    chart={
                      <ChartLineMultiple
                        id={13}
                        setOlumDogumData={setOlumDogumData}
                        title={context?.t?.yillara_gore_dogum_ve_olum_oranlari}
                        city={cityFilter?.label}
                        district={districtFilter?.label}
                        neighbourhood={neighbourhoodFilter?.label}
                        graphic={12}
                        card={false}
                      />
                    }
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              {cityFilter && districtFilter ? (
                <div className="row g-4">
                  <div className="col-12 col-xxl-6" style={{ height: "100vh" }}>
                    <DynamicCard
                      ai={true}
                      id={`homepage-7-${
                        cityFilter ? `${cityFilter.label}` : "all"
                      }${
                        districtFilter
                          ? `${cityFilter ? "-" : ""}${districtFilter.label}`
                          : ""
                      }${
                        neighbourhoodFilter
                          ? `${districtFilter ? "-" : ""}${
                              neighbourhoodFilter.label
                            }`
                          : ""
                      }`}
                      aiData={sankeyReverseData}
                      fullScreen={true}
                      height="h-full"
                      item={{
                        title: context?.t?.ilce_bazinda_alinan_goc,
                      }}
                      events={false}
                      chart={
                        <ChartSankeyReverse
                          city={cityFilter.label}
                          district={districtFilter}
                          graphic={1}
                          setSankeyReverseData={setSankeyReverseData}
                        />
                      }
                    />
                  </div>
                  <div className="col-12 col-xxl-6" style={{ height: "100vh" }}>
                    <DynamicCard
                      ai={true}
                      id={`homepage-8-${
                        cityFilter ? `${cityFilter.label}` : "all"
                      }${
                        districtFilter
                          ? `${cityFilter ? "-" : ""}${districtFilter.label}`
                          : ""
                      }${
                        neighbourhoodFilter
                          ? `${districtFilter ? "-" : ""}${
                              neighbourhoodFilter.label
                            }`
                          : ""
                      }`}
                      aiData={sankeyData}
                      fullScreen={true}
                      height="h-full"
                      item={{
                        title: context?.t?.ilce_bazinda_verilen_goc,
                      }}
                      events={false}
                      chart={
                        <ChartSankey
                          city={cityFilter.label}
                          district={districtFilter}
                          setSankeyData={setSankeyData}
                          graphic={2}
                        />
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="row" style={{ height: 500 }}>
                  <div className="col-6 h-100">
                    <Card className="h-100">
                      <CardBody>
                        <div className="d-flex flex-column h-100">
                          <h6 className="border-bottom pb-3 mb-3">
                            {context?.t?.ilce_bazinda_alinan_goc}
                          </h6>

                          <span className="flex-1 d-flex align-items-center justify-content-center text-center">
                            {context?.t?.il_ve_ilce_secmelisiniz}
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-6 h-100">
                    <Card className="h-100">
                      <CardBody>
                        <div className="d-flex flex-column h-100">
                          <h6 className="border-bottom pb-3 mb-3">
                            {context?.t?.ilce_bazinda_verilen_goc}
                          </h6>

                          <span className="flex-1 d-flex align-items-center justify-content-center text-center">
                            {context?.t?.il_ve_ilce_secmelisiniz}
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              )}
            </div>

            <div className="col-12">
              <h3 className="text-center mt-4 pt-4">
                {context?.t?.universite_ve_universite_ogrenci_sayilari}
              </h3>
            </div>

            <div className="col-12">
              <div className="row g-4 mb-5">
                <DinamikPage
                  todo={false}
                  removeGrid={true}
                  lastItem={6}
                  height={600}
                  filterPath={`${
                    cityFilter ? `?where[]=Sehir:${cityFilter.label}` : ""
                  }${
                    districtFilter
                      ? `${cityFilter ? "&" : "?"}where[]=Ilce:${
                          districtFilter.label
                        }`
                      : ""
                  }${
                    neighbourhoodFilter
                      ? `${districtFilter ? "&" : "?"}where[]=Mahalle:${
                          neighbourhoodFilter.label
                        }`
                      : ""
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
