import React, { useContext, useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import CountUp from "react-countup";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

import { Card, CardBody } from "../card/card";

export default function CardBinaHane({
  width,
  show = true,
  setNeighborhoodCountData = null,
  setStreetCountData = null,
  city,
  district,
  neighbourhood,
  street,
  list = true,
  heightFull = false,
}) {
  const context = useContext(AppSettings);

  const [data, setData] = useState();

  async function getData(dataColumn) {
    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "Bina Hane Sayısı",
          type: `bireysel-data-customer-${context?.me?.customer?.id}`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: dataColumn,
            condition: [
              city
                ? {
                    key: "Adres Il",
                    value: context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Adres Ilce",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Adres Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              street
                ? {
                    key: "Adres Sokak",
                    value: context
                      .removeTurkishCharacters(street)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response;
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    async function fetchData() {
      setData(null);

      const results = await Promise.all([
        getData([
          {
            key: "Adres_Mahalle",
            value: "select",
          },
        ]),
        getData([
          {
            key: "Adres Sokak",
            value: "select",
          },
        ]),
        getData([
          {
            key: "Bina NO",
            value: "select",
          },
        ]),
        getData([
          {
            key: "Bina NO",
            value: "select",
          },
          {
            key: "Daire",
            value: "select",
          },
        ]),
        getData([
          {
            key: "TC",
            value: "count",
          },
        ]),
      ]);

      const [neighbourhoods, streets, buildings, sections, people] = results;

      const aggregatedData = {
        neighbourhoodsCount: neighbourhoods ? neighbourhoods.length : 0,
        streetCount: streets ? streets.length : 0,
        buildingCount:
          buildings && buildings.length !== 0 ? buildings.length : 0,
        independentSectionCount:
          sections && sections.length !== 0 ? sections.length : 0,
        peopleCount: people && people.length !== 0 ? people[0].tc_count : 0,
      };

      if (neighbourhoods && neighbourhoods.length !== 0) {
        setData(aggregatedData);
      } else {
        setData([]);
      }

      if (setNeighborhoodCountData) {
        if (neighbourhoods && neighbourhoods.length !== 0) {
          setNeighborhoodCountData(aggregatedData);
        } else {
          setNeighborhoodCountData(null);
        }
      }

      if (setStreetCountData) {
        if (neighbourhoods && neighbourhoods.length !== 0) {
          setStreetCountData(aggregatedData);
        } else {
          setStreetCountData(null);
        }
      }
    }

    // if (city) {
    fetchData();
    // }
  }, [city, district, neighbourhood, street]);

  return (
    show && (
      <div
        className={`${width ? width : "col-12 col-md-6 col-lg-4 col-xl"} ${
          heightFull ? "h-100" : ""
        }`}
      >
        <Card className="h-100">
          <CardBody>
            {data !== "filter" ? (
              data ? (
                data.length !== 0 ? (
                  <>
                    <div className="d-flex fw-bold h6 mb-3 text-center">
                      <span className="flex-grow-1">{context?.t?.guncel}</span>
                    </div>

                    <div className="row align-items-center">
                      {list && (
                        <div className="col-12 px-4">
                          <div className="small text-inverse text-opacity-100 row text-center">
                            <div className="col-6 p-1">
                              {context?.t?.mahalle_sayisi}
                              <br />
                              <strong className="h4">
                                <CountUp
                                  separator="."
                                  end={data.neighbourhoodsCount || 0}
                                />
                              </strong>
                            </div>
                            <div className="col-6 p-1">
                              {context?.t?.sokak_sayisi}
                              <br />
                              <strong className="h4">
                                <CountUp
                                  separator="."
                                  end={data.streetCount || 0}
                                />
                              </strong>
                            </div>
                            <div className="col-6 p-1">
                              {context?.t?.bina_sayisi}
                              <br />
                              <strong className="h4">
                                <CountUp
                                  separator="."
                                  end={data.buildingCount || 0}
                                />
                              </strong>
                            </div>
                            <div className="col-6 p-1">
                              {context?.t?.hane_sayisi}
                              <br />
                              <strong className="h4">
                                <CountUp
                                  separator="."
                                  end={data.independentSectionCount || 0}
                                />
                              </strong>
                            </div>
                            <div className="col-12 p-1">
                              {context?.t?.kisi_sayisi}
                              <br />
                              <strong className="h3">
                                <CountUp
                                  separator="."
                                  end={data.peopleCount || 0}
                                />
                              </strong>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <span className="d-block text-center">
                    {context?.t?.no_data_available}
                  </span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )
            ) : (
              <span className="d-block text-center">
                {context?.t?.il_secmelisiniz}
              </span>
            )}
          </CardBody>
        </Card>

        <ToastContainer pauseOnFocusLoss={false} />
      </div>
    )
  );
}
