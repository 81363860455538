import React, { useEffect, useState } from "react";
import Answer from "./Answer";
import Select from "react-select";

const Question = ({
  t,
  question,
  questionIndex,
  updateQuestion,
  removeQuestion,
  categoryData,
  questions,
  setQuestions,
  sortButtonShow = true,
  moveQuestionUp,
  moveQuestionDown,
  addQuestionDown,
}) => {
  const [answers, setAnswers] = useState(question.answers || []);

  const [answerTypeArray, setAnswerTypeArray] = useState([
    { value: "radio", label: t?.tekli_cevap },
    { value: "checkbox", label: t?.coklu_cevap },
    { value: "string", label: t?.acik_uclu_cevap },
  ]);

  useEffect(() => {
    setAnswerTypeArray([
      { value: "radio", label: t?.tekli_cevap },
      { value: "checkbox", label: t?.coklu_cevap },
      { value: "string", label: t?.acik_uclu_cevap },
    ]);
  }, [t]);

  const handleInputChange = (e, variable) => {
    const updatedQuestion = {
      ...question,
      [variable]: e,
    };
    updateQuestion(question.id, updatedQuestion);
  };

  const toggleIsRequired = () => {
    const updatedQuestion = {
      ...question,
      isRequired: !question.isRequired,
    };
    updateQuestion(question.id, updatedQuestion);
  };

  const addAnswer = () => {
    const newAnswer = {
      id: Date.now(),
      title: "",
      answerImage: "",
      score: 0,
      isRequired: false,
      subQuestions: [],
    };
    setAnswers([...answers, newAnswer]);

    const updatedQuestion = {
      ...question,
      answers: [...answers, newAnswer],
    };
    updateQuestion(question.id, updatedQuestion);
  };

  const updateAnswer = (answerId, updatedAnswer) => {
    const updatedAnswers = answers.map((a) =>
      a.id === answerId ? updatedAnswer : a
    );
    setAnswers(updatedAnswers);

    const updatedQuestion = {
      ...question,
      answers: updatedAnswers,
    };
    updateQuestion(question.id, updatedQuestion);
  };

  const removeAnswer = (answerId) => {
    const updatedAnswers = answers.filter((a) => a.id !== answerId);
    setAnswers(updatedAnswers);

    const updatedQuestion = {
      ...question,
      answers: updatedAnswers,
    };
    updateQuestion(question.id, updatedQuestion);
  };

  return (
    <div
      className={`question-block mb-3 border ${
        question.answerType === "string" ? "question-string" : ""
      }`}
    >
      <div className="d-flex align-items-center justify-content-between mb-3">
        <span className="text-white fw-bold me-2">
          {/* {questionIndex + ". " + t?.question} */}
          {questionIndex + ". " + t?.question}
        </span>

        <div className="d-flex gap-2">
          {sortButtonShow && (
            <>
              <button
                onClick={moveQuestionUp}
                className="btn btn-outline-theme btn-sm"
              >
                <i className="fas fa-angle-up"></i>
              </button>

              <button
                onClick={moveQuestionDown}
                className="btn btn-outline-theme btn-sm"
              >
                <i className="fas fa-angle-down"></i>
              </button>

              <button
                onClick={addQuestionDown}
                className="btn btn-outline-theme btn-sm d-flex align-items-center gap-1"
              >
                <i className="fas fa-angle-down"></i>
                <i className="fas fa-plus"></i>
              </button>
            </>
          )}

          <button
            className="btn btn-danger btn-sm fw-bold"
            onClick={() => removeQuestion(question.id)}
          >
            {t?.soru_kaldir}
          </button>
        </div>
      </div>

      <div className="row g-2">
        <div className="col-12">
          <div className="form-check form-switch">
            <input
              type="checkbox"
              className="form-check-input"
              id={`survey_question_${question.id}_required`}
              checked={question.isRequired}
              onChange={toggleIsRequired}
            />
            <label
              className="form-check-label"
              htmlFor={`survey_question_${question.id}_required`}
            >
              {t?.required_question}
            </label>
          </div>
        </div>

        <div className="col-6">
          <label className="fw-bold">{t?.soru_kategorisi}</label>
          {categoryData ? (
            <Select
              name="category"
              options={categoryData.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })}
              classNamePrefix="react-select"
              placeholder=""
              noOptionsMessage={() => t?.no_option}
              value={
                question.category
                  ? {
                      value: question.category,
                      label: categoryData.find(
                        (item) => item.id === question.category
                      )?.name,
                    }
                  : null
              }
              onChange={(e) => handleInputChange(e.value, "category")}
            />
          ) : (
            <div className="spinner-border spinner-border-sm text-theme"></div>
          )}
        </div>

        <div className="col-6">
          <label className="fw-bold">{t?.soru_puani}</label>
          <input
            type="number"
            className="form-control"
            min={0}
            value={question.score}
            onChange={(e) => handleInputChange(e.target.value, "score")}
          />
        </div>

        <div className="col-6">
          <label className="fw-bold">{t?.soru_adi}</label>
          <input
            type="text"
            className="form-control"
            value={question.title}
            onChange={(e) => handleInputChange(e.target.value, "title")}
          />
        </div>

        <div className="col-6">
          <label className="fw-bold">{t?.cevap_tipi}</label>
          <Select
            name="answerType"
            options={answerTypeArray}
            classNamePrefix="react-select"
            placeholder=""
            noOptionsMessage={() => t?.no_option}
            value={
              question.answerType
                ? {
                    value: question.answerType,
                    label: answerTypeArray.find(
                      (item) => item.value === question.answerType
                    ).label,
                  }
                : null
            }
            onChange={(e) => handleInputChange(e.value, "answerType")}
          />
        </div>
      </div>

      <div className="mt-3">
        {question.answerType !== "string" &&
          answers.map((answer, index) => (
            <Answer
              t={t}
              key={answer.id}
              answerType={question.answerType}
              answer={answer}
              answerIndex={index + 1}
              categoryData={categoryData}
              updateAnswer={updateAnswer}
              removeAnswer={removeAnswer}
              questionIndex={questionIndex}
            />
          ))}

        {question.answerType === "string" && (
          <div className="row">
            <div className="col">
              <span className="d-block">{t?.metin_basligi}</span>
              <input
                type="text"
                className="form-control"
                value={question.stringTitle}
                onChange={(e) =>
                  handleInputChange(e.target.value, "stringTitle")
                }
              />
            </div>

            <div className="col">
              <span className="d-block">{t?.metin_puani}</span>
              <input
                type="number"
                className="form-control"
                value={question.stringScore}
                onChange={(e) =>
                  handleInputChange(e.target.value, "stringScore")
                }
                min={0}
              />
            </div>
          </div>
        )}

        {question.answerType !== "string" && (
          <button className="btn btn-dark w-100 mt-3" onClick={addAnswer}>
            + {t?.cevap_ekle}
          </button>
        )}
      </div>
    </div>
  );
};

export default Question;
