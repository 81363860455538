import React, { useContext, useEffect, useState } from "react";
import { AppSettings } from "../config/app-settings";

export default function PageLoader({ loading, setLoading }) {
  const context = useContext(AppSettings);

  const [loadingText, setLoadingText] = useState(context?.t?.loader_gathering);
  const [loadingAnimation, setLoadingAnimation] = useState(1);

  const performLoadingSteps = () => {
    setLoadingText(context?.t?.loader_gathering);
    setLoadingAnimation(1);

    setTimeout(() => {
      setLoadingText(context?.t?.loader_in_progress);
      setLoadingAnimation(2);

      setTimeout(() => {
        setLoadingText(context?.t?.loader_visualizing);
        setLoadingAnimation(3);
      }, 2500);
    }, 2500);
  };

  useEffect(() => {
    // document.addEventListener("keyup", (e) => {
    //   if (e.keyCode === 27) {
    //     setLoading(false);
    //   }
    // });

    // const loaderShown = localStorage.getItem("loader");

    // if (loaderShown) {
    //   setLoading(false);
    // }

    // setTimeout(() => {
    // setLoading(false);
    // localStorage.setItem("loader", "true");
    // }, 1500);

    if (context) {
      const interval = setInterval(() => {
        performLoadingSteps();
      }, 7500);

      // İlk çalışma için 2500 ms bekleme
      performLoadingSteps();

      return () => {
        clearInterval(interval);
      };
    }

    // eslint-disable-next-line
  }, [context]);

  return (
    loading && (
      <div className="app page-loader">
        {context && context.me && context?.me?.role?.id === 1 && (
          <div
            className="app-header pt-3"
            style={{
              position: "static",
              width: "auto",
              background: "none",
              height: "auto",
            }}
          >
            <div className="brand px-0">
              <div className="brand-logo">
                {localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user")).customer?.settings
                  ?.logo ? (
                  <img
                    className="mx-0"
                    src={
                      JSON.parse(localStorage.getItem("user")).customer
                        ?.settings?.logo
                    }
                    alt={
                      JSON.parse(localStorage.getItem("user")).customer
                        ?.customer?.name
                    }
                    height={100}
                  />
                ) : window.location.host.includes("datakokpit") ? (
                  <div className="brand-text">
                    DATA
                    <div className="card">
                      <span className="text-theme">KOKPIT</span>

                      <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="brand-text">
                    STRATEJİ
                    <div className="card">
                      <span style={{ color: "#3cd2a5" }}>MERKEZİ</span>

                      <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {context && context.me && context?.me?.role?.id === 1 ? (
          <div className="app-header">
            <div className="brand">
              <div className="brand-logo">
                <div className="brand-text">
                  {context?.t?.loader_data}
                  <div className={`card loader-text-${loadingAnimation}`}>
                    <span className="text-theme">{loadingText}</span>

                    <div className="card-arrow">
                      <div className="card-arrow-top-left"></div>
                      <div className="card-arrow-top-right"></div>
                      <div className="card-arrow-bottom-left"></div>
                      <div className="card-arrow-bottom-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "fixed",
              width: 200,
              height: 200,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div
              className="spinner-border spinner-border-sm w-100 h-100"
              style={{
                color: `${
                  localStorage.getItem("user") &&
                  JSON.parse(
                    localStorage.getItem("user")
                  ).customer?.settings?.theme.split("|")[1]
                }`,
              }}
            ></div>
            {localStorage.getItem("user") &&
              JSON.parse(localStorage.getItem("user")).customer?.settings
                ?.logo && (
                <img
                  className="mx-0"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 60,
                    height: "auto",
                  }}
                  src={
                    JSON.parse(localStorage.getItem("user")).customer?.settings
                      ?.logo
                  }
                  alt={
                    JSON.parse(localStorage.getItem("user")).customer?.customer
                      ?.name
                  }
                  height={100}
                />
              )}
          </div>
        )}
      </div>
    )
  );
}
