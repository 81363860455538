import React, { useContext, useEffect, useRef, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5wc from "@amcharts/amcharts5/wc";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import bireyselJson from "../../json/bireysel.json";
import { AppSettings } from "../../config/app-settings";

const EChartExample12 = ({ data, json = false, animation = true }) => {
  const context = useContext(AppSettings);

  const chartDivRef = useRef(null);
  const [chartData, setChartData] = useState(true);
  const [chartColor, setChartColor] = useState(context?.chartColor);

  useEffect(() => {
    setChartColor(context?.chartColor);
  }, [context]);

  useEffect(() => {
    if (data) {
      if (data[0]) {
        if (data[0].length !== 0) {
          setChartData(true);

          const newData = data[0]
            .map(({ Parametre: category, Yuzde: value, ...rest }, index) => {
              if (index < 20) {
                if (json === "bireysel") {
                  const newValue =
                    bireyselJson.Memleket_Il[
                      parseInt(category.split(" - ")[0])
                    ] +
                    " - " +
                    bireyselJson.Memleket_Ilce[
                      parseInt(category.split(" - ")[1])
                    ];

                  return {
                    category: newValue,
                    value,
                    ...rest,
                  };
                } else {
                  return {
                    category,
                    value,
                    ...rest,
                  };
                }
              } else {
                return null; // ya da istediğiniz başka bir değer
              }
            })
            .filter((item) => item !== null);

          if (chartDivRef) {
            // Create root element
            const root = am5.Root.new(chartDivRef.current);

            // Set themes
            if (animation) {
              root.setThemes([am5themes_Animated.new(root)]);
            }

            // Create series
            let series = root.container.children.push(
              am5wc.WordCloud.new(root, {
                colors:
                  chartColor === "random"
                    ? am5.ColorSet.new(root, {
                        colors: [
                          am5.color("#5470c6"),
                          am5.color("#91cc75"),
                          am5.color("#fac858"),
                          am5.color("#ee6666"),
                          am5.color("#3ba272"),
                          am5.color("#73c0de"),
                          am5.color("#9a60b4"),
                          am5.color("#5470c6"),
                          am5.color("#ea7ccc"),
                          am5.color("#91cc75"),
                          am5.color("#fac858"),
                          am5.color("#73c0de"),
                          am5.color("#ee6666"),
                          am5.color("#3ba272"),
                          am5.color("#9a60b4"),
                          am5.color("#ea7ccc"),
                        ],
                      })
                    : null,
                maxCount: 200,
                minWordLength: 2,
                minFontSize: am5.percent(30),
                maxFontSize: am5.percent(100),
              })
            );

            series.data.setAll(newData);

            series.labels.template.setAll({
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 5,
              paddingRight: 5,
              tooltipText: "{category}: [bold]{value}[/]",
              fill: chartColor === "random" ? null : am5.color(chartColor),
            });

            return () => {
              // Clean up the chart when the component is unmounted
              root.dispose();
            };
          }
        } else {
          setChartData(false);
        }
      }
    }
  }, [data]); // Empty dependency array ensures that this effect runs only once on mount

  return chartData ? (
    <div ref={chartDivRef} style={{ width: "100%", height: "90%" }} />
  ) : (
    <span className="d-block text-center">Veri Bulunmamaktadır</span>
  );
};

export default EChartExample12;
