import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/tr";

function formatliTarih(tarihMetni) {
  var gunler = ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"];
  var aylar = [
    "Oca",
    "Şub",
    "Mar",
    "Nis",
    "May",
    "Haz",
    "Tem",
    "Ağu",
    "Eyl",
    "Eki",
    "Kas",
    "Ara",
  ];

  var tarih = new Date(tarihMetni);

  var gun = gunler[tarih.getUTCDay()];
  var gunNumara = tarih.getUTCDate();
  var ay = aylar[tarih.getUTCMonth()];
  var yil = tarih.getUTCFullYear();
  var saat = ("0" + tarih.getUTCHours()).slice(-2);
  var dakika = ("0" + tarih.getUTCMinutes()).slice(-2);

  return (
    gun + ", " + gunNumara + " " + ay + " " + yil + " " + saat + ":" + dakika
  );
}

const TurkishDateComponent = ({ dateString, news = false }) => {
  const [turkishDate, setTurkishDate] = useState(null);

  useEffect(() => {
    if (news) {
      setTurkishDate(formatliTarih(dateString));
    } else {
      const dateMoment = moment(dateString, "YYYY-MM-DD HH:mm:ss");
      setTurkishDate(dateMoment.format("DD MMMM YYYY"));
    }
  }, [dateString]);

  if (!turkishDate) {
    return <span>Tarih yükleniyor...</span>;
  }

  return <span>{turkishDate}</span>;
};

export default TurkishDateComponent;
