import React, { useContext, useEffect, useRef, useState } from "react";

import Select from "react-select";
import { Swiper, SwiperSlide } from "swiper/react";
import { AppSettings } from "../../config/app-settings";

import ImportFile from "../../components/import-file";
import apiClient from "../../ApiClient";
import DynamicCard from "../../components/DynamicCard";
import toastMessage from "../../components/ToastMessage";
import { Card, CardBody, CardExpandToggler } from "../../components/card/card";

export default function AnketVeArastirmalar() {
  const context = useContext(AppSettings);
  const idContainer = useRef(null);

  const [customerSwiper, setCustomerSwiper] = useState(null);
  const [globalSwiper, setGlobalSwiper] = useState(null);

  const [fileColumns, setFileColumns] = useState(null);

  const [dataIds, setDataIDs] = useState({ data: [] });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [activeId, setActiveId] = useState(null);
  const [dataCharts, setDataCharts] = useState([]);
  const [dataTable, setDataTable] = useState(null);

  const [fileName, setFileName] = useState(null);
  const [customerValue, setCustomerValue] = useState();
  const [customerFileExists, setCustomerFileExists] = useState(false);

  const [createPageName, setCreatePageName] = useState("");

  const [pageName, setPageName] = useState("");
  const [activeSurvey, setActiveSurvey] = useState(0);
  const [createPageLoader, setCreatePageLoader] = useState(false);

  const [uploadPage, setUploadPage] = useState(false);

  const [pageSurveyCustomerValue, setPageSurveyCustomerValue] = useState();

  const myCustomer =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).customer;

  const [pages, setPages] = useState(null);

  // Get Pages
  async function handlePageSelect(e) {
    setPages(null);

    // const data = await context.getOncePages(e.value);
    // if (data) {
    //   setPages(
    //     data
    //       .filter((page) => {
    //         if (e && e.value) {
    //           return (
    //             page.name.startsWith("Anket Sayfası") &&
    //             page.page_type === "custom"
    //           );
    //         } else {
    //           return (
    //             page.name.startsWith("Anket Sayfası") &&
    //             page.page_type === "default"
    //           );
    //         }
    //       })
    //       .sort((a, b) => a.order - b.order)
    //       .map((item) => {
    //         return {
    //           value: item.id,
    //           label: item.name.split("Anket Sayfası")[1],
    //         };
    //       })
    //   );
    // } else {
    //   setPages(null);
    // }

    setCustomerValue(e);
  }

  useEffect(() => {
    if (context && context.me && context?.me?.role?.id === 1) {
      handlePageSelect({
        value: null,
        label: context?.t?.none,
      });
    } else {
      handlePageSelect({
        value: myCustomer.id,
        label: myCustomer.customer.name,
      });
    }
  }, [context]);

  // Get File Types
  async function handleFileTypes(surveyName, surveyId, customer) {
    setFileName(null);

    setFileColumns(null);
    setDataIDs(null);
    setDataCharts(null);

    if (customer.id) {
      const data = await context.getFileTypes(customer.id, true);

      if (data.length !== 0) {
        const fileNameCustomer = `anket-data-survey-${surveyId}-customer-${customer.id}`;
        const exists = data.some((item) => item.type === fileNameCustomer);

        if (exists) {
          setFileName(fileNameCustomer);
        } else {
          setFileName(null);

          setFileColumns([]);
          setDataIDs({ data: [] });
          setDataCharts([]);
        }
      } else {
        setFileName(null);

        setFileColumns([]);
        setDataIDs({ data: [] });
        setDataCharts([]);
      }
    } else {
      setFileName(`anket-data-survey-${surveyId}-customer-${customer.id}`);
    }

    // if (
    //   context?.me?.role?.id !== 1
    // ) {
    //   const data = await context.getFileTypes(
    //     JSON.parse(localStorage.getItem("user")).customer.id,
    //     true
    //   );
    //   if (data.length !== 0) {
    //     const fileNameCustomer = `anket-data${
    //       "-" + JSON.parse(localStorage.getItem("user")).customer.id
    //     }`;
    //     const exists = data.some((item) => item.type === fileNameCustomer);
    //     if (exists) {
    //       setCustomerFileExists(true);
    //       setFileName(fileNameCustomer);
    //     } else {
    //       setCustomerFileExists(false);
    //       setFileName("anket-data");
    //     }
    //   } else {
    //     setCustomerFileExists(false);
    //     setFileName("anket-data");
    //   }
    // } else {
    //   setCustomerFileExists(false);
    //   setFileName("anket-data");
    // }
  }

  // useEffect(() => {
  // handleFileTypes();
  // }, []);

  // Get File Columns
  async function getFileColumns(name) {
    setFileColumns(null);

    if (name) {
      try {
        const response = await apiClient.get(`/columns/${name}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response) {
          setFileColumns(response.data.columns);
        }
      } catch (error) {
        setFileColumns([]);
        console.error("Get File Types Error", error);
      }
    } else {
      setFileColumns(null);
    }
  }

  useEffect(() => {
    getFileColumns(fileName);
  }, [fileName]);

  // Get Data
  async function fetchDataIds(page, search) {
    setDataIDs(null);

    if (fileColumns && fileName) {
      try {
        const response = await apiClient.customPost(
          `/query/preview?page=${page}`,
          {
            chart: 1,
            page_id: 23,
            title: "-",
            type: fileName,
            description: "",
            customer: null,
            paginate: true,
            status: true,
            order: 1,
            sql: {
              column: [
                {
                  key: "ID",
                  value: "select",
                },
              ],
              condition:
                search && search !== ""
                  ? [
                      {
                        key: "ID",
                        value: search,
                        operator: "like",
                        type: "and",
                      },
                    ]
                  : [],
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setDataIDs(response);
      } catch (error) {
        setDataIDs(null);
        console.error(error);
      }
    } else {
      setDataIDs({ data: [] });
    }
  }

  async function fetchData(conditionID) {
    setDataTable(null);

    const dataColumn = fileColumns.map((item) => ({
      key: item.replaceAll("'", ""),
      value: "select",
    }));

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: fileName,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: dataColumn,
            condition: conditionID
              ? [
                  {
                    key: "ID",
                    value: conditionID,
                    operator: "=",
                    type: "and",
                  },
                ]
              : [],
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setDataTable(response);
    } catch (error) {
      setDataCharts([]);
      setDataTable([]);
      console.error(error);
    }
  }

  async function fetchAllData() {
    setDataCharts(null);

    if (fileColumns && fileName) {
      try {
        const response = await apiClient.get(`/survey?params=${fileName}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.data.length === 0) {
          setDataCharts([]);
        } else {
          const sortedEntries = Object.entries(response.data);

          const transformedData = [];

          sortedEntries.forEach(([key, value]) => {
            let chartType = 1;

            if (value && typeof value === "object") {
              const entries = Object.entries(value);

              if (entries.length > 20) {
                const hasLongName = entries.some(([name]) => name.length > 50);

                if (!hasLongName) {
                  chartType = 3;
                } else {
                  chartType = 0;
                }
              }

              if (entries.length >= 10 && entries.length <= 20) {
                chartType = 2;
              }

              let seriesData = entries.map(([name, count]) =>
                chartType === 3
                  ? {
                      category: name,
                      value: parseInt(count) || 0,
                    }
                  : {
                      name: name,
                      value: parseInt(count) || 0,
                    }
              );

              if (chartType === 2) {
                const names = seriesData.map((item) => item.name);
                const values = seriesData.map((item) =>
                  parseInt(item.value, 10)
                );
                seriesData = [names, values];
              }

              transformedData.push({
                title: key,
                chart: chartType,
                options: {
                  width: {
                    label: "Orta-Büyük (4/3)",
                    value: "col-4",
                  },
                  options:
                    chartType === 3 || chartType === 0
                      ? seriesData
                      : chartType === 2
                      ? {
                          grid: {
                            bottom: "20%",
                          },
                          xAxis: [
                            {
                              data: seriesData && seriesData[0],
                              type: "category",
                              axisLabel: {
                                rotate: 45,
                                textStyle: {
                                  color: "#fff",
                                  fontSize: 10,
                                },
                              },
                            },
                          ],
                          yAxis: [
                            {
                              type: "value",
                              axisLabel: {
                                textStyle: {
                                  color: "#fff",
                                },
                              },
                            },
                          ],
                          series: [
                            {
                              data: seriesData && seriesData[1],
                              name: null,
                              type: "bar",
                              markPoint: {
                                data: [
                                  {
                                    name: "Max",
                                    type: "max",
                                  },
                                  {
                                    name: "Min",
                                    type: "min",
                                  },
                                ],
                              },
                            },
                          ],
                          visualMap: context?.chartColor
                            ? {
                                max:
                                  seriesData && seriesData[1]
                                    ? Math.max(
                                        ...seriesData[1].map((item) => item)
                                      )
                                    : 0,
                                min: 0,
                                show: false,
                                inRange: {
                                  colorLightness: [0.2, 1],
                                },
                              }
                            : false,
                          toolbox: {
                            show: true,
                            feature: {
                              restore: {
                                show: false,
                              },
                              magicType: {
                                show: true,
                                type: ["line", "bar"],
                              },
                              saveAsImage: {
                                show: false,
                              },
                            },
                            iconStyle: {
                              borderColor: "#fff",
                            },
                          },
                          tooltip: {
                            trigger: "axis",
                          },
                          darkMode: true,
                          calculable: true,
                        }
                      : {
                          legend: {
                            top: "top",
                            left: "center",
                            show: false,
                            orient: "horizontal",
                            itemGap: 20,
                            textStyle: {
                              color: "#fff",
                            },
                          },
                          visualMap: context?.chartColor
                            ? {
                                min:
                                  seriesData && seriesData.length !== 0
                                    ? Math.min(
                                        ...seriesData.map((obj) => obj.value)
                                      )
                                    : 0,
                                max:
                                  seriesData && seriesData.length !== 0
                                    ? Math.max(
                                        ...seriesData.map((obj) => obj.value)
                                      )
                                    : 0,
                                show: false,
                                inRange: {
                                  colorLightness: [0.2, 1],
                                },
                              }
                            : false,
                          series: [
                            {
                              data: seriesData,
                              name: null,
                              type: "pie",
                              label: {
                                textStyle: {
                                  fontSize: 15,
                                },
                              },
                              radius: "50%",
                            },
                          ],
                          tooltip: {
                            trigger: "item",
                          },
                          darkMode: true,
                        },
                },
              });
            }
          });

          setDataCharts(transformedData);
        }
      } catch (error) {
        setDataCharts([]);
        setDataTable([]);
        console.error(error);
      }
    } else {
      setDataCharts([]);
      setDataTable([]);
    }
  }

  useEffect(() => {
    fetchDataIds(currentPage, searchTerm);
    fetchAllData();
  }, [fileColumns, fileName, context?.chartColor]);

  // Handle Click ID
  function handleData(id) {
    setActiveId(id);
    fetchData(id);
  }

  // ID Search
  function handleSearch(value) {
    setSearchTerm(value);
    setCurrentPage(1);
    fetchDataIds(1, value);
  }

  // ID Pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchDataIds(page, searchTerm);
  };

  // Active Survey
  function handleActiveSurvey(item, general) {
    setActiveSurvey(item.id);
    handleFileTypes(
      item.name,
      item.id,
      !general ? { id: item.customer_id } || myCustomer : { id: null }
    );
  }

  // Create Survey
  async function handleCreatePageImportFile() {
    if (!pageName) {
      toastMessage(context?.t?.analysisRequired, "error");
      return;
    }

    const data = {
      icon: null,
      order: 1,
      name: "Anket Sayfası " + pageName,
      type: "page",
      url: null,
      customer_id:
        context?.me?.role?.id === 1
          ? customerValue.value
          : myCustomer
          ? myCustomer.id
          : null,
      page_type: customerValue.value
        ? "custom"
        : context?.me?.role?.id === 1
        ? "default"
        : "custom",
      parent_id: null,
      iframe: null,
    };

    try {
      const response = await apiClient.customPost("/page", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setUploadPage(response);
      }
    } catch (error) {
      console.error("Page Add Error", error);
    }
  }

  const [showPages, setShowPages] = useState(true);

  async function handleCreatePage() {
    if (!pageName) {
      toastMessage(context?.t?.analysisRequired, "error");
      return;
    }

    setCreatePageLoader(true);

    const data = {
      icon: null,
      order: 1,
      name: "Anket Sayfası " + pageName,
      type: "page",
      url: null,
      customer_id:
        context?.me?.role?.id === 1
          ? pageSurveyCustomerValue.value
          : myCustomer
          ? myCustomer.id
          : null,
      page_type: pageSurveyCustomerValue.value
        ? "custom"
        : context?.me?.role?.id === 1
        ? "default"
        : "custom",
      parent_id: null,
      iframe: null,
    };

    try {
      const response = updatePage
        ? await apiClient.put(`/page/${updatePage?.id}`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
        : await apiClient.customPost("/page", data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

      if (response) {
        setCreatePageLoader(false);

        toastMessage(
          `${
            updatePage
              ? context?.t?.analiz_adi_guncellendi
              : context?.t?.analiz_adi_olusturuldu
          }`,
          "success"
        );
        setPageName("");
        context.getPages();

        document
          .getElementById("createGraphicSurveyPageModalCloseButton")
          .click();

        setShowPages(false);
        setTimeout(() => {
          setShowPages(true);
        }, 300);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 300);
      }
    } catch (error) {
      setCreatePageLoader(false);
      toastMessage(
        `${
          updatePage
            ? context?.t?.analiz_adi_guncellerken_hata
            : context?.t?.analiz_adi_olustururken_hata
        }`,
        "error"
      );
      console.error("Page Add Error", error);
    }
  }

  // Update Page
  const [updatePage, setUpdatePage] = useState(null);

  useEffect(() => {
    if (updatePage) {
      setPageName(updatePage?.name.split("Anket Sayfası")[1].trim());
      setPageSurveyCustomerValue({
        value: updatePage?.customer_id,
        label: context.allCustomerSelect.find(
          (customer) => customer.value === updatePage?.customer_id
        )?.label,
      });
    } else {
      setPageName("");
      setPageSurveyCustomerValue({
        value: null,
        label: context?.t?.none,
      });
    }
  }, [updatePage, context]);

  // Delete Page
  const [deletePage, setDeletePage] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);

  async function handlePageDelete(id) {
    setDeleteLoader(true);

    try {
      const response = await apiClient.delete(`/page/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setDeleteLoader(false);
        toastMessage(context?.t?.analiz_silindi, "success");

        context.getPages();

        document.getElementById("surveyDeletePageModalCloseButton").click();

        setShowPages(false);
        setTimeout(() => {
          setShowPages(true);
        }, 300);
      }
    } catch (error) {
      setDeleteLoader(false);
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  return (
    <div className="row gap-4 mb-5 pb-5">
      <div className="zoom-item">
        <div className="row">
          <div className="col-12">
            <h3 className="mb-3">
              {context?.t?.tamamlanmis_anket_yukleme_alani}
            </h3>

            <div className="d-flex align-items-start gap-3">
              <div className="">
                <button
                  type="button"
                  className="btn btn-outline-theme btn-lg"
                  data-bs-toggle="modal"
                  data-bs-target="#createGraphicSurveyModal"
                  onClick={() => setUpdatePage(null)}
                >
                  {context?.t?.analiz_yukle}
                </button>
                <a
                  href={`${process.env.PUBLIC_URL}/download/ornek-anket.csv`}
                  className="d-block mt-2"
                >
                  {context?.t?.example_file_download}
                </a>
              </div>

              {/* <button
                type="button"
                className="btn btn-outline-theme btn-lg"
                data-bs-toggle="modal"
                data-bs-target="#createGraphicSurveyPageModal"
                onClick={() => setUpdatePage(null)}
              >
                Anket Oluştur
              </button> */}

              {/* {customerFileExists && (
              <button
                type="button"
                className="btn btn-outline-theme ms-2 btn-lg"
                onClick={() =>
                  fileName === "anket-data"
                    ? window.location.reload()
                    : setFileName("anket-data")
                }
              >
                {fileName === "anket-data" ? "Benim Anketim" : "Genel Anket"}
              </button>
            )} */}
            </div>
          </div>
        </div>

        <div className="col-12 mt-4">
          <hr />
        </div>

        {showPages && (
          <div className="row">
            <div className="col-12 col-md-6">
              <h3 className="mb-3">{context?.t?.benim_anketlerim}</h3>
              <div className="row g-3 w-100">
                {context.pages ? (
                  context.pages.filter(
                    (page) =>
                      page.page_type === "custom" &&
                      page.name.startsWith("Anket Sayfası")
                  ).length !== 0 ? (
                    <div className="d-flex align-items-center gap-3">
                      <div className="flex-1 overflow-hidden">
                        <Swiper
                          onSwiper={(s) => {
                            setCustomerSwiper(s);
                          }}
                          loop={false}
                          slidesPerView="auto"
                          spaceBetween={12}
                          className="pt-3"
                        >
                          {context.pages
                            .filter(
                              (page) =>
                                page.page_type === "custom" &&
                                page.name.startsWith("Anket Sayfası")
                            )
                            .map((item, index) => (
                              <SwiperSlide
                                key={index}
                                className="w-auto position-relative"
                                style={{ flex: "none" }}
                              >
                                <div className="d-flex align-items-center gap-2 menu-item-events-hover position-relative">
                                  <button
                                    type="button"
                                    className={`btn btn-lg btn${
                                      activeSurvey === item.id ? "" : "-outline"
                                    }-theme`}
                                    onClick={() =>
                                      handleActiveSurvey(item, false)
                                    }
                                  >
                                    {item.name.split("Anket Sayfası")[1]}
                                  </button>

                                  <div
                                    className="menu-item-events"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      transform: "translate(0px, -60%)",
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      {((item.page_type &&
                                        item.page_type !== "default") ||
                                        (JSON.parse(
                                          localStorage.getItem("user")
                                        ).role.id === 1 &&
                                          item.page_type)) && (
                                        <button
                                          onClick={() => setUpdatePage(item)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#createGraphicSurveyPageModal"
                                          className="btn btn-sm p-0 btn-success d-flex align-items-center justify-content-center"
                                          style={{
                                            width: 15,
                                            height: 16,
                                          }}
                                        >
                                          <i
                                            className="fas fa-edit"
                                            style={{ fontSize: 10 }}
                                          ></i>
                                        </button>
                                      )}

                                      {((item.page_type &&
                                        item.page_type !== "default") ||
                                        (JSON.parse(
                                          localStorage.getItem("user")
                                        ).role.id === 1 &&
                                          item.page_type)) && (
                                        <button
                                          onClick={() => setDeletePage(item)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#surveyDeletePageModal"
                                          className="btn btn-sm p-0 btn-danger d-flex align-items-center justify-content-center"
                                          style={{
                                            width: 15,
                                            height: 16,
                                          }}
                                        >
                                          <i
                                            className="fas fa-trash-alt"
                                            style={{ fontSize: 10 }}
                                          ></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </div>

                      <div>
                        <button
                          className="d-block btn w-100 py-0 text-black btn-theme"
                          onClick={() => customerSwiper.slidePrev()}
                        >
                          {context?.t?.prev}
                        </button>

                        <button
                          className="d-block btn w-100 py-0 text-black btn-theme mt-2"
                          onClick={() => customerSwiper.slideNext()}
                        >
                          {context?.t?.next}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <span>{context?.t?.olusturulan_anket_bulunamadi}</span>
                  )
                ) : (
                  <div className="col-12">
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-12 col-md-6">
              <h3 className="mb-3">{context?.t?.genel_anketler}</h3>
              <div className="row g-3 w-100">
                {context.pages ? (
                  context.pages.filter(
                    (page) =>
                      page.page_type === "default" &&
                      page.name.startsWith("Anket Sayfası")
                  ).length !== 0 ? (
                    <div className="d-flex align-items-center gap-3">
                      <div className="flex-1 overflow-hidden">
                        <Swiper
                          onSwiper={(s) => {
                            setGlobalSwiper(s);
                          }}
                          loop={false}
                          slidesPerView="auto"
                          spaceBetween={12}
                          className="pt-3"
                        >
                          {context.pages
                            .filter(
                              (page) =>
                                page.page_type === "default" &&
                                page.name.startsWith("Anket Sayfası")
                            )
                            .map((item, index) => (
                              <SwiperSlide
                                key={index}
                                className="w-auto"
                                style={{ flex: "none" }}
                              >
                                <div className="d-flex align-items-center gap-2 menu-item-events-hover position-relative">
                                  <button
                                    type="button"
                                    className={`btn btn-lg btn${
                                      activeSurvey === item.id ? "" : "-outline"
                                    }-theme`}
                                    onClick={() =>
                                      handleActiveSurvey(item, true)
                                    }
                                  >
                                    {item.name.split("Anket Sayfası")[1]}
                                  </button>

                                  <div
                                    className="menu-item-events"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      transform: "translate(0px, -60%)",
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      {((item.page_type &&
                                        item.page_type !== "default") ||
                                        (JSON.parse(
                                          localStorage.getItem("user")
                                        ).role.id === 1 &&
                                          item.page_type)) && (
                                        <button
                                          onClick={() => setUpdatePage(item)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#createGraphicSurveyPageModal"
                                          className="btn btn-sm p-0 btn-success d-flex align-items-center justify-content-center"
                                          style={{
                                            width: 15,
                                            height: 16,
                                          }}
                                        >
                                          <i
                                            className="fas fa-edit"
                                            style={{ fontSize: 10 }}
                                          ></i>
                                        </button>
                                      )}

                                      {((item.page_type &&
                                        item.page_type !== "default") ||
                                        (JSON.parse(
                                          localStorage.getItem("user")
                                        ).role.id === 1 &&
                                          item.page_type)) && (
                                        <button
                                          onClick={() => setDeletePage(item)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#surveyDeletePageModal"
                                          className="btn btn-sm p-0 btn-danger d-flex align-items-center justify-content-center"
                                          style={{
                                            width: 15,
                                            height: 16,
                                          }}
                                        >
                                          <i
                                            className="fas fa-trash-alt"
                                            style={{ fontSize: 10 }}
                                          ></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </div>

                      <div>
                        <button
                          className="d-block btn w-100 py-0 text-black btn-theme"
                          onClick={() => globalSwiper.slidePrev()}
                        >
                          {context?.t?.prev}
                        </button>

                        <button
                          className="d-block btn w-100 py-0 text-black btn-theme mt-2"
                          onClick={() => globalSwiper.slideNext()}
                        >
                          {context?.t?.next}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <span>{context?.t?.genel_anket_bulunamadi}</span>
                  )
                ) : (
                  <div className="col-12">
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="col-12 mt-4">
          <hr />
        </div>

        {activeSurvey ? (
          <>
            <div className="col-12 mb-4">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-outline-theme"
                  data-bs-toggle="modal"
                  data-bs-target="#surveyIdsModal"
                >
                  {context?.t?.denek_id_gor}
                </button>

                {/* {dataCharts && dataCharts.length !== 0 && (
                  <div style={{ width: 150 }} className="ms-3">
                    <Select
                      options={[]}
                      placeholder={context?.t?.kategori_sec}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                    />
                  </div>
                )} */}
              </div>
            </div>

            <div className="col-12">
              <div className="row g-4">
                {dataCharts ? (
                  dataCharts.length !== 0 ? (
                    <>
                      {dataCharts.map((item, index) =>
                        item.chart === 0 ? (
                          <div
                            key={index}
                            className={`graphic-item position-relative ${
                              item?.options?.width?.value.split("col-")[1]
                                ? "col-12 col-xl-" +
                                  item?.options?.width?.value.split("col-")[1]
                                : item?.options?.width?.value
                            }`}
                          >
                            <Card>
                              <CardBody className="d-flex flex-column h-100">
                                {item.title && (
                                  <div
                                    className={`d-flex align-items-center justify-content-between border-bottom pb-2 mb-3`}
                                  >
                                    <span
                                      className={`fw-bold h6 mb-0 flex-1`}
                                      style={{
                                        maxWidth: 500,
                                      }}
                                    >
                                      {item?.title?.toUpperCase()}
                                    </span>

                                    <div className="d-flex align-items-center ms-2">
                                      <CardExpandToggler />
                                    </div>
                                  </div>
                                )}

                                <div className="position-relative overflow-hidden flex-1 d-flex flex-column">
                                  <div
                                    className="table-responsive"
                                    style={{ height: 510 }}
                                  >
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>{context?.t?.answer}</th>
                                          <th className="text-end">
                                            {context?.t?.integer}
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {item.options.options.map(
                                          (item, index) => (
                                            <tr key={index}>
                                              <td>{item.name}</td>
                                              <td className="text-end">
                                                {item.value}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                {item.order && (
                                  <div className="text-end">#{item.order}</div>
                                )}
                              </CardBody>
                            </Card>
                          </div>
                        ) : (
                          <DynamicCard
                            events={false}
                            key={index}
                            fullScreen={true}
                            item={item}
                            cityFilter={"asd"}
                          />
                        )
                      )}
                    </>
                  ) : (
                    <span className="text-white">
                      {context?.t?.analiz_bulunamadi}
                    </span>
                  )
                ) : (
                  <div className="spinner-border text-theme"></div>
                )}
              </div>
            </div>
          </>
        ) : (
          <h5 className="mt-4 fw-normal text-white">
            {context?.t?.analiz_ici_bir_anket_secin}
          </h5>
        )}
      </div>

      <div className="modal modal-cover fade" id="surveyIdsModal">
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">{context?.t?.denek_id}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <div className="col-12" ref={idContainer}>
                {dataIds ? (
                  dataIds.data.length !== 0 ? (
                    <>
                      <input
                        type="text"
                        placeholder={context?.t?.search}
                        value={searchTerm}
                        onChange={(e) => handleSearch(e.target.value)}
                        className="form-control mb-3"
                      />

                      <div className="d-flex flex-wrap gap-2">
                        {dataIds.data
                          .sort((a, b) => {
                            return Number(a.id) - Number(b.id);
                          })
                          .map((item, index) => (
                            <button
                              key={index}
                              type="button"
                              className={`btn btn-outline-theme btn-sm`}
                              // className={`btn btn${ activeId === item.id ? "" : "-outline" }-theme btn-sm`}
                              onClick={() => handleData(item.id)}
                              data-bs-toggle="modal"
                              data-bs-target="#surveyIdDetailModal"
                            >
                              {item.id}
                            </button>
                          ))}
                      </div>

                      <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center mt-4">
                        <ul className="pagination">
                          <li className={`paginate_button page-item`}>
                            <button
                              type="button"
                              onClick={() => handlePageChange(currentPage - 1)}
                              className={`page-link pointer ${
                                !dataIds.prev_page_url ? "disabled" : ""
                              }`}
                            >
                              {context?.t?.prev}
                            </button>
                          </li>

                          {dataIds.links.map(
                            (number, index) =>
                              index > 0 &&
                              index < dataIds.links.length - 1 && (
                                <li
                                  key={index}
                                  className={`paginate_button page-item ${
                                    currentPage === parseInt(number.label)
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <div
                                    onClick={() =>
                                      handlePageChange(parseInt(number.label))
                                    }
                                    className="page-link pointer"
                                  >
                                    {number.label}
                                  </div>
                                </li>
                              )
                          )}

                          <li className={`paginate_button page-item`}>
                            <button
                              type="button"
                              onClick={() => handlePageChange(currentPage + 1)}
                              className={`page-link pointer ${
                                !dataIds.next_page_url ? "disabled" : ""
                              }`}
                            >
                              {context?.t?.next}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <span>{context?.t?.id_bulunamadi}</span>
                  )
                ) : (
                  <div className="spinner-border text-theme"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="surveyIdDetailModal">
        <div className="modal-dialog" style={{ maxWidth: 1500 }}>
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">{context?.t?.anket_detay}</h3>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#surveyIdsModal"
                className="btn btn-dark ms-3"
              >
                {context?.t?.go_back}
              </button>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              {activeId &&
                (dataTable ? (
                  dataTable.length !== 0 ? (
                    <div className="">
                      <div className="table-responsive mt-4">
                        <table className="align-middle table border">
                          <thead>
                            <tr>
                              <th>{context?.t?.id}</th>
                              <th className="border-start">{context?.t?.tc}</th>
                              <th className="border-start">
                                {context?.t?.ip_adresi}
                              </th>
                              <th className="border-start">
                                {context?.t?.isim}
                              </th>
                              <th className="border-start">
                                {context?.t?.soyisim}
                              </th>
                              <th className="border-start">
                                {context?.t?.toplam_sure}
                              </th>
                              <th className="border-start">
                                {context?.t?.tamamlanma_tarih_saat}
                              </th>
                              <th className="border-start">
                                {context?.t?.phone}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {dataTable.map((item, index) => (
                                <>
                                  <td key={index + "_" + item.id}>{item.id}</td>
                                  <td
                                    key={index + "_" + item.id}
                                    className="border-start"
                                  >
                                    {item.tckn || "-"}
                                  </td>
                                  <td
                                    key={index + "_" + item.id}
                                    className="border-start"
                                  >
                                    {item.ip_adresi || "-"}
                                  </td>
                                  <td
                                    key={index + "_" + item.id}
                                    className="border-start"
                                  >
                                    {item.isim || "-"}
                                  </td>
                                  <td
                                    key={index + "_" + item.id}
                                    className="border-start"
                                  >
                                    {item.soyisim || "-"}
                                  </td>
                                  <td
                                    key={index + "_" + item.id}
                                    className="border-start"
                                  >
                                    {item.toplam_sure || "-"}
                                  </td>
                                  <td
                                    key={index + "_" + item.id}
                                    className="border-start"
                                  >
                                    {item.tamamlanma_tarih_saat || "-"}
                                  </td>
                                  <td
                                    key={index + "_" + item.id}
                                    className="border-start"
                                  >
                                    {item.telefon || "-"}
                                  </td>
                                </>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="table-responsive mt-2">
                        <table className="align-middle table border">
                          <thead>
                            <tr>
                              <th width={50}>#</th>
                              <th className="border-start">
                                {context?.t?.sorular}
                              </th>
                              <th className="border-start">
                                {context?.t?.cevaplar}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataTable.map((item, index) =>
                              Object.entries(item)
                                .filter(
                                  ([key]) =>
                                    key !== "id" &&
                                    key !== "tckn" &&
                                    key !== "telefon" &&
                                    key !== "isim" &&
                                    key !== "soyisim" &&
                                    key !== "ip_adresi" &&
                                    key !== "toplam_sure" &&
                                    key !== "tamamlanma_tarih_saat" &&
                                    key !== "||"
                                )
                                .map(([key, value], i) => (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td className="border-start">{key}</td>
                                    <td className="border-start">
                                      {value || "-"}
                                    </td>
                                  </tr>
                                ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <span className="text-white">
                      {context?.t?.analiz_bulunamadi}
                    </span>
                  )
                ) : (
                  <div className="spinner-border text-theme mt-4"></div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="createGraphicSurveyPageModal">
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                {updatePage
                  ? context?.t?.anket_duzenle
                  : context?.t?.anket_olustur}
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="createGraphicSurveyPageModalCloseButton"
              ></button>
            </div>

            <div className="modal-body">
              {!updatePage && context?.me?.role?.id === 1 && (
                <div className="mb-3">
                  <span className="mb-1 d-block">{context?.t?.customer}</span>
                  {context.allCustomerSelect ? (
                    <Select
                      options={[
                        {
                          value: null,
                          label: context?.t?.none,
                        },
                        ...context.allCustomerSelect,
                      ]}
                      placeholder=""
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      value={pageSurveyCustomerValue}
                      onChange={(e) => setPageSurveyCustomerValue(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
              )}

              <div className="mb-3">
                <span className="mb-1 d-block">{context?.t?.analiz_adi}</span>
                <input
                  type="text"
                  className="form-control"
                  value={pageName}
                  onChange={(e) => setPageName(e.target.value)}
                />
              </div>

              <div className="row">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-outline-theme w-100"
                    data-bs-dismiss="modal"
                  >
                    {context?.t?.cancel}
                  </button>
                </div>

                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-theme w-100 mb-3"
                    disabled={createPageLoader}
                    onClick={() => handleCreatePage(false)}
                  >
                    {updatePage ? context?.t?.edit : context?.t?.create}
                    {createPageLoader && (
                      <div className="spinner-border spinner-border-sm text-white ms-2"></div>
                    )}
                  </button>
                </div>

                {createPageLoader && (
                  <div
                    className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(29, 40, 53, 0.95)", zIndex: 99 }}
                  >
                    <div className="spinner-border text-theme"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="createGraphicSurveyModal">
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">{context?.t?.analiz_yukle}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="createGraphicSurveyModalCloseButton"
              ></button>
            </div>

            <div className="modal-body">
              {context?.me?.role?.id === 1 && (
                <div className="mb-3">
                  <span className="mb-1 d-block">{context?.t?.customer}</span>
                  {context.allCustomerSelect ? (
                    <Select
                      options={[
                        {
                          value: null,
                          label: context?.t?.none,
                          user_id: null,
                        },
                        ...context.allCustomerSelect,
                      ]}
                      placeholder=""
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      value={customerValue}
                      onChange={(e) => handlePageSelect(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
              )}

              <div className="mb-3">
                <span className="mb-1 d-block">{context?.t?.analiz_adi}</span>
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control flex-1"
                    disabled={uploadPage ? true : false}
                    readOnly={uploadPage ? true : false}
                    value={pageName}
                    onChange={(e) => setPageName(e.target.value)}
                  />
                  {uploadPage && (
                    <button
                      className="btn btn-danger ms-2"
                      onClick={() => setUploadPage(null)}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  )}
                </div>
              </div>

              {!uploadPage && (
                <button
                  type="button"
                  className="btn btn-theme w-100 mb-3"
                  disabled={createPageLoader}
                  onClick={() => handleCreatePageImportFile()}
                >
                  {context?.t?.create}
                  {createPageLoader && (
                    <div className="spinner-border spinner-border-sm text-white ms-2"></div>
                  )}
                </button>
              )}

              {/* <div className="mb-3">
                <span className="mb-1 d-block">Sayfa Seç</span>
                {pages ? (
                  <Select
                    options={pages}
                    placeholder=""
                    classNamePrefix="react-select"
                    noOptionsMessage={() => context?.t?.no_option}
                    value={uploadPage}
                    onChange={(e) => setUploadPage(e)}
                  />
                ) : (
                  <div className="spinner-border spinner-border-sm text-theme"></div>
                )}
              </div> */}

              {uploadPage && (
                <ImportFile
                  closeId="createGraphicSurveyModalCloseButton"
                  pageImport={true}
                  columnTypesString={true}
                  pageNameRequired={true}
                  pageName={pageName}
                  dataname={`anket-data-survey-${uploadPage.id}-customer-${
                    context?.me?.role?.id === 1
                      ? customerValue?.value
                      : myCustomer?.id
                  }-user-${customerValue?.user_id || null}`}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="surveyDeletePageModal">
        <div className="modal-dialog py-4">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                {context?.t?.are_you_sure}
                <br />
                <span className="h5">
                  {context?.t?.anket_adi}:{" "}
                  {deletePage?.name.split("Anket Sayfası")[1]}
                </span>
              </h3>

              <button
                type="button"
                className="btn-close"
                id="surveyDeletePageModalCloseButton"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <div className="d-flex gap-3">
                <button
                  type="button"
                  className="btn btn-outline-danger w-100"
                  onClick={() => handlePageDelete(deletePage?.id)}
                >
                  {context?.t?.delete}
                </button>

                <button
                  type="button"
                  className="btn btn-outline-theme w-100"
                  data-bs-dismiss="modal"
                >
                  {context?.t?.cancel}
                </button>

                {deleteLoader && (
                  <div
                    className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
                    style={{ background: "rgba(29, 40, 53, 0.95)", zIndex: 99 }}
                  >
                    <div className="spinner-border text-theme"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
