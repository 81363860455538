import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import DatePicker from "react-datepicker";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import { AppSettings } from "../../config/app-settings";
import apiClient from "../../ApiClient";
import toastMessage from "../../components/ToastMessage";
import PageFilter from "../../components/PageFilter";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const center = { lat: 39.1667, lng: 35.6667 };
const defaultZoom = 5.8;
let options;
setTimeout(() => {
  options = {
    center,
    zoom: defaultZoom,
    styles:
      document.querySelector("html").getAttribute("data-bs-theme") === "light"
        ? []
        : [
            {
              featureType: "all",
              elementType: "all",
              stylers: [
                { invert_lightness: true },
                { saturation: -50 },
                { lightness: 40 },
                { gamma: 0.5 },
                {
                  hue: window.location.host.includes("datakokpit")
                    ? "#17248a"
                    : "#3cd2a5",
                },
              ],
            },
          ],
  };
}, 3000);

export default function AddTodo({
  todoEditData,
  setTodoEditData,
  handleRemoveMarker,
  getTodoData,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [modalMapCity, setModalMapCity] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  // Error Messages
  function errorMessages(error) {
    if (error?.response?.status) {
      if (error.response.status === 422) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }

      if (error.response.status === 401) {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }

      if (error.response.status === 403) {
        toastMessage(context?.t?.try_again_error, "error");
        setTimeout(() => {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }, 1000);
      }

      if (error.response.status === 500) {
        toastMessage(context?.t?.server_error, "error");
      }
    }
  }

  // Cities Filter
  const [city, setCity] = useState(null);
  const [cityValue, setCityValue] = useState(null);

  const [district, setDistrict] = useState(null);
  const [districtLoader, setDistrictLoader] = useState(true);
  const [districtValue, setDistrictValue] = useState(null);
  const [allDistrict, setAllDistrict] = useState([]);

  const [neighborhoodValue, setNeighborhoodValue] = useState(null);
  const [neighborhoodLoader, setNeighborhoodLoader] = useState(true);
  const [allNeighborhood, setAllNeighborhood] = useState([]);

  const [streetValue, setStreetValue] = useState(null);
  const [streetLoader, setStreetLoader] = useState(true);
  const [allStreet, setAllStreet] = useState([]);

  // Users
  const [allUsersSelect, setAllUsersSelect] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(null);

  async function fetchAllUsersSelect() {
    setAllUsersSelect(null);

    try {
      const response = await apiClient.get(
        `/user?customer_id=${context?.me?.customer?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        setAllUsersSelect(response.data);
      }
    } catch (error) {
      console.error("Users Error", error);
      setAllUsersSelect([]);
    }
  }

  useEffect(() => {
    fetchAllUsersSelect();
  }, []);

  // Reset
  function resetNeighborhood() {
    setNeighborhoodValue(null);
    setNeighborhoodLoader(false);
    setTimeout(() => {
      setNeighborhoodLoader(true);
    }, 500);
  }

  function resetStreet() {
    setStreetValue(null);
    setStreetLoader(false);
    setTimeout(() => {
      setStreetLoader(true);
    }, 500);
  }

  // Select
  function handleCity(e) {
    setModalMapCity(e);
    setCityValue(e);

    setDistrictLoader(false);
    setTimeout(() => {
      setDistrictLoader(true);
    }, 500);
    setDistrictValue(null);
    fetchDistrict(e.value);

    resetNeighborhood();
    fetchNeighborhood(null);

    resetStreet();
    fetchStreet(null);
  }

  function handleDistrict(e) {
    setDistrictValue(e);
    setModalMapCity(e);

    resetNeighborhood();
    fetchNeighborhood(e.value && e.value !== "" ? e.value : null);

    resetStreet();
    fetchStreet(null);
  }

  function handleNeighborhood(e) {
    setNeighborhoodValue(e);
    setModalMapCity(e);

    resetStreet();
    fetchStreet(e.value && e.value !== "" ? e.label : null);
  }

  function handleStreet(e) {
    setStreetValue(e);
  }

  // Fetchs
  async function fetchDistrict(value, name) {
    if (value) {
      const data = await context.getAllDistrict(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: context?.t?.choose_district,
        });

        setAllDistrict(newData);
      }
    }
  }

  async function fetchNeighborhood(value) {
    if (value) {
      const data = await context.getAllNeighborhood(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: context?.t?.choose_neighborhood,
        });

        setAllNeighborhood(newData);
      }
    } else {
      setAllNeighborhood([]);
    }
  }

  async function fetchStreet(value) {
    if (value) {
      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: "Bina Hane Sayısı",
            type: `bireysel-data-customer-${context?.me?.customer?.id}`,
            description: "",
            customer: null,
            status: true,
            order: 1,
            connection_type: "local",
            sql: {
              column: [{ key: "Adres Sokak", value: "select" }],
              condition: [
                {
                  key: "Adres Il",
                  value: context
                    .removeTurkishCharacters(cityValue?.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                },
                {
                  key: "Adres Ilce",
                  value: context
                    .removeTurkishCharacters(districtValue?.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                },
                {
                  key: "Adres Mahalle",
                  value: context.removeTurkishCharacters(value).toUpperCase(),
                  operator: "like",
                  type: "and",
                },
              ],
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.length !== 0) {
          const data = response
            .map((item) => {
              return {
                value: context
                  .removeTurkishCharacters(item["adres sokak"])
                  .toUpperCase(),
                label: context
                  .removeTurkishCharacters(item["adres sokak"])
                  .toUpperCase(),
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label));

          const newData = data.slice();
          newData.unshift({
            value: "",
            label: context?.t?.choose_street,
          });

          setAllStreet(newData);
        } else {
          setAllStreet([]);
        }
      } catch (error) {
        setAllStreet(null);
      }
    } else {
      setAllStreet([]);
    }
  }

  // User
  const [me, setMe] = useState(null);

  async function getMe() {
    if (localStorage.getItem("token")) {
      try {
        const response = await apiClient.get("/me", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setMe(response.data);
      } catch (error) {
        if (error.response.status !== 404) {
          setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    } else {
      setMe(null);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/giris-yap");
    }
  }

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (me?.customer?.city) {
      setCityValue({
        value: me?.customer?.city?.id || null,
        label: me?.customer?.city?.name || null,
      });
      setCity(me?.customer?.city?.id || null);
      fetchDistrict(me?.customer?.city?.id || null);
    }

    if (me?.customer?.district) {
      setDistrictValue({
        value: me?.customer?.district?.id || null,
        label: me?.customer?.district?.name.toUpperCase() || null,
      });
      setDistrict(me?.customer?.district || null);
      fetchNeighborhood(me?.customer?.district?.id || null);
    }
  }, [me]);

  // Drag and Drop File
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedImagesUrl, setUploadedImagesUrl] = useState([]);
  const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

  const onDrop = useCallback(async (acceptedFiles) => {
    const maxTotalSize = 5 * 1024 * 1024; // 5MB in bytes

    // Filter the accepted files to only include allowed file types
    const imageFiles = acceptedFiles.filter((file) =>
      allowedFileTypes.includes(file.type)
    );

    // Calculate the total size of the new files plus any already uploaded files
    const totalSize = imageFiles.reduce((acc, file) => acc + file.size, 0);
    const currentSize = uploadedImages.reduce(
      (acc, file) => acc + file.size,
      0
    );

    if (totalSize + currentSize > maxTotalSize) {
      toastMessage(context?.t?.max_file_size_5mb, "error");
      return;
    }

    const uploadPromises = imageFiles.map(async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await apiClient.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response) {
          return response.data.url;
        }
      } catch (error) {
        console.error("Upload File Error", error);
        // errorMessages(error);
        return null;
      }
    });

    try {
      const urls = await Promise.all(uploadPromises);
      const validUrls = urls.filter((url) => url !== null);
      setUploadedImagesUrl((prevUrls) => [...prevUrls, ...validUrls]);
    } catch (error) {
      console.error("Error while processing upload promises", error);
      // Handle the error
    }

    setUploadedImages((prevImages) => [...prevImages, ...imageFiles]);
  }, []);

  const removeImage = (index) => {
    // Belirli bir index'teki resmi kaldırın
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setUploadedImagesUrl((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFileTypes.map((type) => `.${type.split("/")[1]}`).join(","), // Dosya uzantılarını belirle
  });

  // Upload File
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileLoader, setFileLoader] = useState(true);

  const handleUploadFile = async (e) => {
    const selectedFile = e.target.files[0];

    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    if (selectedFile.size > maxFileSize) {
      toastMessage(context?.t?.max_file_size_5mb, "error");
      return;
    }

    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if ([".xlsx", ".pdf"].includes("." + fileExtension)) {
        setFile(selectedFile);
        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
          const response = await apiClient.post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (response) {
            setFileUrl(response.data.url);
          }
        } catch (error) {
          toastMessage(context?.t?.dosya_boyutu_yuksek, "error");
          console.error("Upload File Error", error);
          setFileUrl(null);
          // errorMessages(error);
        }
      } else {
        setFile(null);
        toastMessage(context?.t?.desteklenmeyen_dosya_formati, "error");
      }
    }
  };

  function removeFile() {
    setFile(null);
    setFileUrl(null);
    setFileLoader(false);
    setTimeout(() => {
      setFileLoader(true);
    }, 300);
  }

  // Upload Image
  // const [image, setImage] = useState(null);
  // const [imageUrl, setImageUrl] = useState(null);

  // const handleUploadImage = async (e) => {
  //   const selectedFile = e.target.files[0];

  //   if (selectedFile) {
  //     const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

  //     if ([".jpg", ".jpeg", ".png", ".svg"].includes("." + fileExtension)) {
  //       setImage(selectedFile);

  //       const formData = new FormData();
  //       formData.append("file", selectedFile);

  //       try {
  //         const response = await apiClient.post("/upload", formData, {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           },
  //         });

  //         if (response) {
  //           setImageUrl(response.data.url);
  //         }
  //       } catch (error) {
  //         console.error("Upload File Error", error);
  //         setImageUrl(null);
  //         errorMessages(error);
  //       }
  //     } else {
  //       setFile(null);
  //       toastMessage("Desteklenmeyen dosya formatı", "error");
  //     }
  //   }
  // };

  // Todo ADD
  const [todoStartedDate, SetTodoStartedDate] = useState(new Date());
  const [todoFinishedDate, SetTodoFinishedDate] = useState(new Date());
  const [progress, setProgress] = useState(0);

  const [todoForm, setTodoForm] = useState({
    completed: false,
    category: null,
    sort: 1,
    title: "",
    description: "",
    summary: "",
    tags: [],
    lat: "",
    lng: "",
    zoom: "18",
    image: "",
    file: "",
    started_at: null,
    finished_at: null,
    city: null,
    district: null,
    neighbourhood: null,
    street: null,
    building: null,
    independent: null,
  });

  const handleSliderChange = (event) => {
    setProgress(event.target.value);
  };

  async function handleTodoSubmit() {
    // else if (!cityValue || cityValue.value === "") {
    //   toastMessage(context?.t?.il_bos_birakmayin, "error");
    // } else if (!districtValue || districtValue.value === "") {
    //   toastMessage(context?.t?.ilce_bos_birakmayin, "error");
    // } else if (!neighborhoodValue || neighborhoodValue.value === "") {
    //   toastMessage(context?.t?.mahalle_bos_birakmayin, "error");
    // } else if (!selectedMarker) {
    //   toastMessage(context?.t?.koordinat_belirleyin, "error");
    // }

    if (todoForm.title === "") {
      toastMessage(context?.t?.baslik_tarihi_bos_birakmayin, "error");
    } else if (!todoStartedDate) {
      toastMessage(context?.t?.baslangic_tarihi_bos_birakmayin, "error");
    } else if (!todoFinishedDate) {
      toastMessage(context?.t?.bitis_tarihi_bos_birakmayin, "error");
    } else if (!categorySelect) {
      toastMessage(context?.t?.kategori_bos_birakmayin, "error");
    } else if (todoForm.description === "") {
      toastMessage(context?.t?.aciklama_bos_birakmayin, "error");
    } else {
      todoForm.title = progress + "#" + todoForm.title;
      todoForm.category = categorySelect.value;
      todoForm.started_at =
        moment(todoStartedDate).format("YYYY-MM-DD") + " 00:00:00";
      todoForm.finished_at =
        moment(todoFinishedDate).format("YYYY-MM-DD") + " 00:00:00";
      if (selectedMarker) {
        todoForm.lat = selectedMarker ? selectedMarker.lat : null;
        todoForm.lng = selectedMarker ? selectedMarker.lng : null;
        todoForm.zoom = "18";
      } else {
        delete todoForm.lat;
        delete todoForm.lng;
        delete todoForm.zoom;
      }
      todoForm.file = fileUrl ? [fileUrl] : null;
      todoForm.image =
        uploadedImagesUrl.length === 0 ? null : uploadedImagesUrl;

      if (cityValue && cityValue.value !== "") {
        const { geo, ...cityWithoutGeo } = cityValue;
        todoForm.city = cityWithoutGeo;
      } else {
        todoForm.city = null;
      }

      if (districtValue && districtValue.value !== "") {
        const { geo, ...districtWithoutGeo } = districtValue;
        todoForm.district = districtWithoutGeo;
      } else {
        todoForm.district = null;
      }

      if (neighborhoodValue && neighborhoodValue.value !== "") {
        const { geo, ...neighborhoodWithoutGeo } = neighborhoodValue;
        todoForm.neighbourhood = neighborhoodWithoutGeo;
      } else {
        todoForm.neighbourhood = null;
      }

      todoForm.street =
        streetValue && streetValue.value !== "" ? streetValue : null;
      todoForm.building = [todoForm.building];

      todoForm.assigned =
        selectedUsers && selectedUsers.length !== 0 ? selectedUsers : null;

      // if (todoForm.building || todoForm.independent) {
      //   if (!todoForm.district) {
      //     toastMessage(context?.t?.ilce_girmelisiniz, "error");

      //     return false;
      //   }
      // }

      if (todoEditData) {
        try {
          const response = await apiClient.put(
            `/todos/${todoEditData.todo.id}`,
            todoForm,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (response) {
            toastMessage(context?.t?.gorev_guncellendi, "success");
            document.getElementById("addMarkerModalClose").click();
            getTodoData();
            setTodoEditData(true);
          }
        } catch (error) {
          console.error("Todo Add Error", error);
          setCategoryButtonDisabled(false);
          errorMessages(error);
        }
      } else {
        todoForm.sort = 1;
        try {
          const response = await apiClient.customPost("/todos", todoForm, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          if (response) {
            toastMessage(context?.t?.gorev_olusturuldu, "success");
            document.getElementById("addMarkerModalClose").click();
            getTodoData();
            setTodoEditData(true);
            removeFile();
            setProgress(0);
          }
        } catch (error) {
          console.error("Todo Add Error", error);
          setCategoryButtonDisabled(false);
          errorMessages(error);
        }
      }
    }
  }

  // Category List
  const [categorySelect, setCategorySelect] = useState(null);
  const [categoryData, setCategoryData] = useState(null);

  async function getCategoryData() {
    setCategoryData(null);

    try {
      const response = await apiClient.get(`/todo/categories`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        if (response.data.length !== 0) {
          const convertedArray = response.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
              color: item.color,
              sort: item.sort,
            };
          });

          convertedArray.sort((a, b) => a.sort - b.sort);

          setCategoryData(convertedArray);
        } else {
          setCategoryData([]);
        }
      }
    } catch (error) {
      console.error("Category List Error", error);
      setCategoryData(null);

      if (error.response.status === 500) {
        toastMessage(context?.t?.server_error, "error");
      } else if (error.response.status === 404) {
        navigate("/404");
      } else if (error.response.status === 403) {
        toastMessage(context?.t?.cok_fazla_istek_atildi, "error");
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      } else {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }
    }
  }

  useEffect(() => {
    getCategoryData();
    // eslint-disable-next-line
  }, []);

  // Category Add
  const [categoryButtonDisabled, setCategoryButtonDisabled] = useState(false);

  const [categoryForm, setCategoryForm] = useState({
    name: "",
    color: "",
    sort: 1,
  });

  const categoryHandleChange = (e) => {
    const { name, value } = e.target;
    setCategoryForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function categoryAddSubmit(event) {
    event.preventDefault();

    setCategoryButtonDisabled(true);

    if (categoryForm.color === "") {
      toastMessage(context?.t?.renk_secmelisiniz, "warning");
    } else {
      if (categoryEditShow) {
        try {
          const response = await apiClient.put(
            "/todo/categories/" + categoryForm.id,
            categoryForm,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response) {
            toastMessage(context?.t?.kategori_guncellendi, "success");
            setCategorySelect({
              value: categoryForm.id,
              label: categoryForm.name,
              color: categoryForm.color,
              sort: categoryForm.sort,
            });
            document.getElementById("categoryAddModalClose").click();

            getCategoryData();
          }
        } catch (error) {
          console.error("Category Edit Error", error);
          setCategoryButtonDisabled(false);

          errorMessages(error);
        }
      } else {
        categoryForm.sort = 1;

        try {
          const response = await apiClient.customPost(
            "/todo/categories",
            categoryForm,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response) {
            toastMessage(context?.t?.kategori_eklendi, "success");

            document.getElementById("categoryAddModalClose").click();

            getCategoryData();
          }
        } catch (error) {
          console.error("Category Add Error", error);
          setCategoryButtonDisabled(false);

          errorMessages(error);
        }
      }
    }
  }

  useEffect(() => {
    let categoryAddModal = document.getElementById("categoryAddModal");
    categoryAddModal.addEventListener("hidden.bs.modal", function (event) {
      setCategoryButtonDisabled(false);
      setCategoryForm({
        name: "",
        color: "",
      });
      setCategoryEditShow(false);

      categoryAddModal.querySelectorAll(".color-item").forEach((element) => {
        const inputElement = element.querySelector("input[type='radio']");
        if (inputElement) {
          inputElement.checked = false;
        }
      });
    });
  }, []);

  // Category Edit
  const [categoryEditShow, setCategoryEditShow] = useState(false);

  function categoryEdit() {
    setCategoryEditShow(true);
    setCategoryForm({
      id: categorySelect.value,
      name: categorySelect.label,
      color: categorySelect.color,
      sort: categorySelect.sort,
    });
  }

  // Category Delete
  const [categoryDeleteID, setCategoryDeleteID] = useState(false);

  async function handleCategoryDelete() {
    try {
      const response = await apiClient.delete(
        "/todo/categories/" + categoryDeleteID.value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        toastMessage(context?.t?.kategori_silindi, "success");
        setCategorySelect(null);
        document.getElementById("categoryDeleteConfirmModalClose").click();

        getCategoryData();
      }
    } catch (error) {
      console.error("Category Delete Error", error);
      setCategoryButtonDisabled(false);

      errorMessages(error);
    }
  }

  // Todo Edit
  useEffect(() => {
    if (todoEditData) {
      if (todoEditData?.category?.id) {
        setCategorySelect({
          value: todoEditData.category.id,
          label: todoEditData.category.name,
          sort: todoEditData.category.sort,
        });
        SetTodoStartedDate(new Date(todoEditData.todo.started_at));
        SetTodoFinishedDate(new Date(todoEditData.todo.finished_at));
        setFileUrl(todoEditData.todo.file || null);
        setUploadedImages(todoEditData.todo.image || []);
        setUploadedImagesUrl(todoEditData.todo.image || []);
        // setImageUrl(todoEditData.todo.image || null);

        setSelectedUsers(todoEditData.todo.assigned || null);

        setCityValue(todoEditData.todo.city || null);
        setDistrictValue(todoEditData.todo.district || null);
        setNeighborhoodValue(todoEditData.todo.neighbourhood || null);
        setStreetValue(todoEditData.todo.street || null);

        setSelectedMarker({
          lat: todoEditData?.todo?.location
            ? parseFloat(todoEditData?.todo?.location.lat)
            : null,
          lng: todoEditData?.todo?.location
            ? parseFloat(todoEditData?.todo?.location.lng)
            : null,
        });

        setModalMapCity(
          todoEditData.todo.neighbourhood
            ? todoEditData.todo.neighbourhood
            : todoEditData.todo.district
            ? todoEditData.todo.district
            : todoEditData.todo.city
            ? todoEditData.todo.city
            : null
        );

        setProgress(
          todoEditData?.todo?.title.includes("#")
            ? todoEditData?.todo?.title.split("#")[0]
            : 0
        );

        setTodoForm({
          completed: todoEditData?.completed,
          category: todoEditData?.category.id,
          sort: todoEditData?.todo?.sort,
          title: todoEditData?.todo?.title.includes("#")
            ? todoEditData?.todo?.title.split("#")[1]
            : todoEditData?.todo?.title,
          description: todoEditData?.todo?.description,
          summary: todoEditData?.todo?.summary,
          tags: todoEditData?.todo?.tags,
          lat: todoEditData?.todo?.location
            ? todoEditData?.todo?.location.lat
            : null,
          lng: todoEditData?.todo?.location
            ? todoEditData?.todo?.location.lng
            : null,
          zoom: "18",
          image: todoEditData?.todo?.image || "",
          file: todoEditData?.todo?.file || "",
          started_at: todoEditData?.todo?.started_at,
          finished_at: todoEditData?.todo?.finished_at,
          city: todoEditData?.todo?.city,
          district: todoEditData?.todo?.district,
          neighbourhood: todoEditData?.todo?.neighbourhood,
          street: todoEditData?.todo?.street,
          building: todoEditData?.todo?.building,
          independent: todoEditData?.todo?.independent,
        });
      }
    } else {
      setCategorySelect(null);
      SetTodoStartedDate(new Date());
      SetTodoFinishedDate(new Date());
      setFileUrl(null);
      setUploadedImages([]);
      setUploadedImagesUrl([]);
      setNeighborhoodValue(null);
      setDistrictValue(null);
      setStreetValue(null);
      setSelectedMarker(null);
      setModalMapCity(null);
      setTodoForm({
        category: null,
        title: "",
        sort: 1,
        description: "",
        summary: "",
        tags: [],
        lat: "",
        lng: "",
        zoom: "18",
        image: "",
        file: "",
        started_at: null,
        finished_at: null,
        city: null,
        district: null,
        neighbourhood: null,
        street: null,
        building: null,
        independent: null,
      });
    }
  }, [todoEditData]);

  //--

  const [map, setMap] = useState(null);
  const polygonRef = useRef(null);

  const updateMap = useCallback(() => {
    if (map && modalMapCity && modalMapCity.value && modalMapCity.geo) {
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
      }

      let itemGEOModalTodo;

      if (modalMapCity.value === 34) {
        itemGEOModalTodo = JSON.parse(
          modalMapCity.geo.replace(/\"/g, "").trim()
        );
      } else {
        itemGEOModalTodo = JSON.parse(
          modalMapCity.geo.replace(/\"/g, "").trim()
        )[0][0];
      }

      const polygonCoords = itemGEOModalTodo
        .map((coordGroup, coordGroupIndex) => {
          if (
            modalMapCity.value === 34 &&
            (coordGroupIndex === 0 || coordGroupIndex === 1)
          ) {
            return coordGroup[0].map((coord) => ({
              lat: coord[1],
              lng: coord[0],
            }));
          } else if (modalMapCity.value !== 34) {
            return { lat: coordGroup[1], lng: coordGroup[0] };
          }
        })
        .flat()
        .filter(Boolean);

      const bounds = new window.google.maps.LatLngBounds();
      polygonCoords.forEach((coord) => {
        bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
      });

      const modalMapCityPolygon = new window.google.maps.Polygon({
        paths: polygonCoords,
        map: map,
        fillColor: "#15202bb5",
        strokeColor: context?.theme === "light" ? "#000" : "#fff",
        strokeWeight: 1,
        clickable: false,
      });

      polygonRef.current = modalMapCityPolygon;

      map.setZoom(defaultZoom); // Varsayılan zoom seviyesini ayarla
      setTimeout(() => {
        // Fit bounds ve zoom seviyesini sabitleme
        map.fitBounds(bounds);
      }, 100);
    } else {
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
        map.setZoom(defaultZoom);
      }
    }
  }, [map, modalMapCity]);

  useEffect(() => {
    updateMap();
  }, [updateMap]);

  const handleMapClick = (event) => {
    const { latLng } = event;
    const newMarker = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };

    setSelectedMarker(newMarker);
  };

  return (
    <>
      <div className="modal modal-cover fade" id="addMarkerModal">
        <div className="modal-dialog modal-lg py-4">
          <div
            className="modal-content rounded"
            style={{ background: "var(--bs-dark4)" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                {todoEditData
                  ? context?.t?.gorev_guncelle
                  : context?.t?.gorev_olustur}
              </h3>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="addMarkerModalClose"
              ></button>
            </div>

            <div className="modal-body">
              {todoEditData && (
                <div className="mb-3">
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitch1"
                      defaultChecked={todoEditData?.todo?.completed}
                      onChange={(e) =>
                        setTodoForm((prevData) => ({
                          ...prevData,
                          completed: e.target.checked,
                        }))
                      }
                    />
                    <label className="form-check-label" htmlFor="customSwitch1">
                      {context?.t?.tamamlandi}
                    </label>
                  </div>
                </div>
              )}

              <div className="mb-3">
                <span className="mb-1 d-block">{context?.t?.title}</span>
                <input
                  type="text"
                  className="form-control"
                  value={todoForm.title}
                  onChange={(e) =>
                    setTodoForm((prevData) => ({
                      ...prevData,
                      title: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="mb-3">
                <span className="mb-1 d-block">
                  {context?.t?.tamamlanma_yuzdesi}
                </span>
                <div className="progress-container">
                  <input
                    type="range"
                    min="0"
                    max="100"
                    step="5"
                    value={progress}
                    onChange={handleSliderChange}
                    className="slider"
                    style={{ "--progress": `${progress}%` }} // CSS değişkeni olarak progress değeri
                  />
                  <div className="progress-bar">{progress}%</div>
                </div>
              </div>

              <div className="row position-relative" style={{ zIndex: 2 }}>
                <div className="col-6">
                  <div className="mb-3">
                    <span className="mb-1 d-block">
                      {context?.t?.started_date}
                    </span>
                    <DatePicker
                      locale="tr"
                      className="form-control"
                      selected={todoStartedDate}
                      onChange={(e) => SetTodoStartedDate(e)}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="mb-3">
                    <span className="mb-1 d-block">{context?.t?.end_date}</span>
                    <DatePicker
                      locale="tr"
                      className="form-control"
                      selected={todoFinishedDate}
                      onChange={(e) => SetTodoFinishedDate(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                {categoryData ? (
                  <>
                    <span className="mb-2 d-flex align-items-center justify-content-between">
                      {context?.t?.category}
                      <span
                        type="button"
                        className="btn btn-outline-theme"
                        data-bs-toggle="modal"
                        data-bs-target="#categoryAddModal"
                      >
                        {context?.t?.kategori_ekle}
                      </span>
                    </span>

                    <Select
                      options={categoryData}
                      value={categorySelect}
                      classNamePrefix="react-select"
                      placeholder=""
                      noOptionsMessage={() => "Eklenmiş bir kategori yok"}
                      onChange={(e) => setCategorySelect(e)}
                    />

                    {categorySelect && (
                      <div className="d-flex gap-2 mt-2">
                        <span
                          type="button"
                          className="btn btn-outline-theme"
                          data-bs-toggle="modal"
                          data-bs-target="#categoryAddModal"
                          onClick={() => categoryEdit()}
                        >
                          {context?.t?.edit}
                        </span>

                        <span
                          type="button"
                          className="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#categoryDeleteConfirmModal"
                          onClick={() => setCategoryDeleteID(categorySelect)}
                        >
                          {context?.t?.delete}
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="spinner-border text-theme"></div>
                )}
              </div>

              <div className="mb-3">
                <span className="mb-1 d-block">{context?.t?.etiketler}</span>
                <TagsInput
                  value={todoForm.tags}
                  placeHolder={context?.t?.etiket_girin}
                  onChange={(e) =>
                    setTodoForm((prevData) => ({
                      ...prevData,
                      tags: e,
                    }))
                  }
                />
              </div>

              <div className="mb-3">
                <span className="mb-1 d-block">{context?.t?.description}</span>
                <CKEditor
                  value={todoForm?.description || ""}
                  data={todoForm?.description || ""}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setTodoForm((prevData) => ({
                      ...prevData,
                      description: data,
                    }));
                  }}
                />
              </div>

              <div className="mb-3">
                <span className="mb-1 d-block">
                  {context?.t?.yonetici_ozeti}
                </span>
                <CKEditor
                  value={todoForm?.summary || ""}
                  data={todoForm?.summary || ""}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setTodoForm((prevData) => ({
                      ...prevData,
                      summary: data,
                    }));
                  }}
                />
              </div>

              <hr />

              <div className="mb-3">
                <div className="row g-3">
                  <div className={`col-6`}>
                    {context.allCity ? (
                      <Select
                        options={context.allCity}
                        value={
                          cityValue
                            ? cityValue
                            : city &&
                              context.allCity.find(function (item) {
                                return item.value === city;
                              })
                        }
                        classNamePrefix="react-select"
                        placeholder={context?.t?.choose_city}
                        noOptionsMessage={() => context?.t?.no_option}
                        onChange={(e) => handleCity(e)}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>

                  <div className={`col-6`}>
                    {me?.customer?.district ? (
                      district ? (
                        districtLoader ? (
                          <Select
                            options={[]}
                            value={
                              districtValue
                                ? districtValue
                                : district.city === cityValue.value
                                ? allDistrict.find(function (item) {
                                    return item.value === district.id;
                                  })
                                : ""
                            }
                            classNamePrefix="react-select"
                            placeholder={context?.t?.choose_district}
                            noOptionsMessage={() => context?.t?.no_option}
                            onChange={(e) => handleDistrict(e)}
                          />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )
                      ) : (
                        <div className="spinner-border spinner-border-sm text-theme"></div>
                      )
                    ) : districtLoader ? (
                      <Select
                        options={allDistrict}
                        value={districtValue}
                        classNamePrefix="react-select"
                        placeholder={context?.t?.choose_district}
                        noOptionsMessage={() => context?.t?.no_option}
                        onChange={(e) => handleDistrict(e)}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>

                  <div className={`col-6`}>
                    {neighborhoodLoader ? (
                      <Select
                        options={allNeighborhood}
                        value={neighborhoodValue ? neighborhoodValue : ""}
                        classNamePrefix="react-select"
                        placeholder={context?.t?.choose_neighborhood}
                        noOptionsMessage={() => context?.t?.no_option}
                        onChange={(e) => handleNeighborhood(e)}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>

                  <div className={`col-6`}>
                    {streetLoader ? (
                      <Select
                        options={allStreet}
                        value={streetValue ? streetValue : ""}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => context?.t?.no_option}
                        placeholder={context?.t?.choose_street}
                        onChange={handleStreet}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>

                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={context?.t?.building}
                      value={todoForm.building || ""}
                      onChange={(e) =>
                        setTodoForm((prevData) => ({
                          ...prevData,
                          building: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={context?.t?.apartment}
                      value={todoForm.independent || ""}
                      onChange={(e) =>
                        setTodoForm((prevData) => ({
                          ...prevData,
                          independent: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-1">
                <span className="d-block">
                  {context?.t?.pin_eklemek_haritaya_tiklayin}
                </span>

                {/* {selectedMarker && (
                  <span
                    className="d-block text-danger pointer"
                    onClick={() => handleRemoveMarker()}
                  >
                    Pin Kaldır
                  </span>
                )} */}
              </div>

              <div style={{ height: "300px", width: "100%" }} className="mb-3">
                <GoogleMap
                  mapContainerStyle={{
                    height: "100%",
                    width: "100%",
                  }}
                  options={options}
                  onLoad={setMap}
                  onClick={handleMapClick}
                >
                  {selectedMarker && (
                    <Marker
                      icon={{
                        path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
                        fillColor: "#de8e00",
                        fillOpacity: 1,
                        strokeWeight: 0,
                        strokeColor:
                          context?.theme === "light" ? "#000" : "#fff",
                        scale: 0.04,
                        size: new window.google.maps.Size(800, 800),
                        anchor: new window.google.maps.Point(200, 400),
                      }}
                      position={{
                        lat: selectedMarker.lat,
                        lng: selectedMarker.lng,
                      }}
                      map={map}
                    />
                  )}
                </GoogleMap>
              </div>

              <div className="mb-3">
                <div className="mb-3">
                  <span className="d-block">{context?.t?.users}</span>

                  <div>
                    {allUsersSelect ? (
                      <Select
                        options={allUsersSelect.map((item) => {
                          return {
                            value: item.id,
                            label: item.name,
                          };
                        })}
                        placeholder=""
                        classNamePrefix="react-select"
                        noOptionsMessage={() => context?.t?.no_option}
                        isMulti
                        value={selectedUsers}
                        onChange={(e) => setSelectedUsers(e)}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-1">
                  <span className="d-block">{context?.t?.import_file}</span>

                  {(file || fileUrl) && (
                    <span
                      className="d-block text-danger pointer"
                      onClick={() => removeFile()}
                    >
                      {context?.t?.dosya_kaldir}
                    </span>
                  )}
                </div>

                {fileLoader && (
                  <div className="col-12">
                    <input
                      id="todo_file"
                      type="file"
                      accept=".xlsx, .pdf"
                      onChange={handleUploadFile}
                      className="d-none"
                    />
                    <label
                      htmlFor="todo_file"
                      className="btn btn-outline-theme w-100 h-100 d-flex align-items-center justify-content-center pointer"
                    >
                      {context?.t?.import_file}
                    </label>
                    {file ? (
                      <span className="text-overflow d-block" title={file.name}>
                        {file.name}
                      </span>
                    ) : (
                      fileUrl && (
                        <a
                          href={fileUrl}
                          target="_blank"
                          className="text-overflow d-block"
                          title={fileUrl}
                          rel="noreferrer"
                        >
                          {fileUrl}
                        </a>
                      )
                    )}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <span className="mb-1 d-block">
                  {context?.t?.resimleri_yukle}
                </span>

                <div className="col-12">
                  <div>
                    <div
                      {...getRootProps()}
                      className="d-flex align-items-center justify-content-center text-center h3"
                      style={{
                        height: "130px",
                        border:
                          "2px dashed " + (isDragActive ? "#3cd2a5" : "#fff"),
                        cursor: "pointer",
                      }}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <span>{context?.t?.dosyalari_buraya_birakin}</span>
                      ) : (
                        <span>{context?.t?.surukle_birak_veya_tikla}</span>
                      )}
                    </div>

                    <div className="row g-2">
                      {uploadedImages.length !== 0 &&
                        uploadedImages.map((file, index) => (
                          <div className="col-3 position-relative" key={index}>
                            <img
                              key={index}
                              src={
                                todoEditData
                                  ? file
                                  : file instanceof Blob
                                  ? window.URL.createObjectURL(file)
                                  : ""
                              }
                              alt={file.name}
                              className="w-100 img-cover"
                              style={{
                                height: 150,
                              }}
                            />

                            <button
                              type="button"
                              className="btn btn-danger btn-sm position-absolute top-0 end-0 my-1 mx-2"
                              onClick={() => removeImage(index)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <hr className="my-3" />

              <div className="row g-3">
                <div className="col-12">
                  <div className="row g-2 justify-content-end h-100">
                    <div className="col-6 h-100">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="btn btn-outline-default w-100 h-100 d-flex align-items-center justify-content-center"
                      >
                        {context?.t?.cancel}
                      </button>
                    </div>

                    <div className="col-6 h-100">
                      <button
                        type="button"
                        onClick={handleTodoSubmit}
                        className="btn btn-theme w-100 h-100 d-flex align-items-center justify-content-center"
                      >
                        {todoEditData
                          ? context?.t?.gorev_guncelle
                          : context?.t?.gorev_olustur}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="categoryAddModal">
        <div className="modal-dialog modal-lg py-4">
          <div
            className="modal-content rounded"
            style={{ background: "var(--bs-dark4)" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                {categoryEditShow
                  ? context?.t?.kategori_duzenle
                  : context?.t?.kategori_ekle}
              </h3>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={categoryAddSubmit}>
                <div className="mb-3">
                  <span className="mb-1 d-block">
                    {context?.t?.kategori_adi}
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={categoryForm.name}
                    name="name"
                    onChange={(e) => categoryHandleChange(e)}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    {context?.t?.kategori_rengi}
                  </span>
                  <div className="d-flex flex-wrap gap-2">
                    {[
                      "#e91010",
                      "#FF4081",
                      "#7B1FA2",
                      "#9C27B0",
                      "#303F9F",
                      "#448AFF",
                      "#689F38",
                      "#4CAF50",
                      "#E64A19",
                      "#FFA000",
                      "#FFEB3B",
                      "#5D4037",
                      "#795548",
                      "#455A64",
                      "#607D8B",
                      "#CFD8DC",
                    ].map((item, index) => (
                      <div className="color-item" key={index}>
                        <input
                          type="radio"
                          value={item}
                          name="color"
                          id={"color_" + (index + 1)}
                          checked={item === categoryForm?.color ? true : false}
                          onChange={(e) => categoryHandleChange(e)}
                        />
                        <label
                          htmlFor={"color_" + (index + 1)}
                          style={{ backgroundColor: item }}
                        ></label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="row g-3 mt-3">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-outline-default w-100"
                      id="categoryAddModalClose"
                      data-bs-toggle="modal"
                      data-bs-target="#addMarkerModal"
                    >
                      {context?.t?.gorev_olustur}
                    </button>
                  </div>

                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-theme w-100"
                      disabled={categoryButtonDisabled}
                    >
                      {categoryEditShow ? context?.t?.update : context?.t?.save}
                      {categoryButtonDisabled && (
                        <div className="spinner-border spinner-border-sm text-theme ms-2"></div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="categoryDeleteConfirmModal">
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "var(--bs-dark4)" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">{context?.t?.are_you_sure}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target="#addMarkerModal"
              ></button>
            </div>

            <div className="modal-body">
              <h5 className="mb-4">
                {categoryDeleteID.label} -{" "}
                {
                  context?.t
                    ?.kategorisi_ve_kategoriye_bagli_basliklar_silinecektir
                }
              </h5>

              <div className="d-flex gap-3">
                <button
                  type="button"
                  className="btn btn-outline-danger w-100"
                  onClick={() => handleCategoryDelete()}
                >
                  {context?.t?.delete}
                </button>

                <button
                  type="button"
                  className="btn btn-outline-theme w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#addMarkerModal"
                  id="categoryDeleteConfirmModalClose"
                >
                  {context?.t?.cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
