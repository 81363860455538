import React, { useState } from "react";
import Question from "./Question";
import toastMessage from "../../components/ToastMessage";
import apiClient from "../../ApiClient";
import Fancybox from "../../components/Fancybox";

const Answer = ({
  t,
  answer,
  answerIndex,
  updateAnswer,
  removeAnswer,
  answerType,
  questionIndex,
  categoryData,
}) => {
  const [subQuestions, setSubQuestions] = useState(answer.subQuestions || []);

  const handleInputChange = (e, type) => {
    const updatedAnswer = {
      ...answer,
      [type]: e,
    };
    updateAnswer(answer.id, updatedAnswer);
  };

  const addSubQuestion = () => {
    if (answer.subQuestions.length > 0) {
      return;
    }

    const newSubQuestion = {
      id: Date.now(),
      title: "",
      category: null,
      answerType: "radio",
      score: 0,
      stringTitle: "",
      stringScore: 0,
      isRequired: false,
      answers: [
        {
          id: Date.now() + 1,
          title: "",
          answerImage: "",
          score: 0,
          isRequired: false,
          subQuestions: [],
        },
      ],
    };
    setSubQuestions([...subQuestions, newSubQuestion]);

    const updatedAnswer = {
      ...answer,
      subQuestions: [...subQuestions, newSubQuestion],
    };
    updateAnswer(answer.id, updatedAnswer);
  };

  const updateSubQuestion = (subQuestionId, updatedSubQuestion) => {
    const updatedSubQuestions = subQuestions.map((q) =>
      q.id === subQuestionId ? updatedSubQuestion : q
    );
    setSubQuestions(updatedSubQuestions);

    const updatedAnswer = {
      ...answer,
      subQuestions: updatedSubQuestions,
    };
    updateAnswer(answer.id, updatedAnswer);
  };

  const removeSubQuestion = (subQuestionId) => {
    const updatedSubQuestions = subQuestions.filter(
      (q) => q.id !== subQuestionId
    );
    setSubQuestions(updatedSubQuestions);

    const updatedAnswer = {
      ...answer,
      subQuestions: updatedSubQuestions,
    };
    updateAnswer(answer.id, updatedAnswer);
  };

  const [uploadImage, setUploadImage] = useState(null);
  const [uploadImageLoading, setUploadImageLoading] = useState(false);

  const handleUploadImage = async (e) => {
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    if (e.size > maxFileSize) {
      toastMessage(t?.max_file_size_5mb, "error");
      return;
    }

    setUploadImageLoading(true);

    const formData = new FormData();
    formData.append("file", e);

    try {
      const response = await apiClient.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        handleInputChange(response.data.url, "answerImage");

        toastMessage(t?.resim_eklendi, "success");
        setUploadImageLoading(false);
        setUploadImage(response.data.url);
      }
    } catch (error) {
      setUploadImageLoading(false);
      console.error("Upload File Error", error);
    }
  };

  return (
    <div className="answer-block mt-2">
      <div className="row align-items-center g-2">
        <div className="col flex-none w-auto">
          <span className="text-white fw-bold">
            {/* {questionIndex}- */}
            {answerIndex}. {t?.answer}
          </span>
        </div>

        <div className="col flex-none w-auto">
          {!uploadImageLoading && (
            <input
              id={answer.id + "_survey_answer_image_file"}
              type="file"
              accept=".png, .jpg, .jpeg"
              className="d-none"
              onChange={(e) => handleUploadImage(e.target.files[0])}
            />
          )}
          <label
            htmlFor={answer.id + "_survey_answer_image_file"}
            className="btn btn-outline-theme w-100 h-100 d-flex align-items-center justify-content-center pointer"
          >
            {t?.upload_image}
            {uploadImageLoading && (
              <div className="spinner-border spinner-border-sm ms-1"></div>
            )}
          </label>

          {answer.answerImage && (
            <div className="d-flex gap-1">
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <a
                  data-fancybox
                  href={answer.answerImage}
                  className="d-block"
                  style={{ textDecorationLine: "none" }}
                >
                  {t?.view}
                </a>
              </Fancybox>
              <div
                className="pointer d-block text-danger"
                onClick={() => {
                  handleInputChange("", "answerImage");
                }}
              >
                {t?.remove}
              </div>
            </div>
          )}
        </div>

        <div className="col">
          <input
            type="text"
            className="form-control"
            placeholder={t?.write_answer}
            value={answer.title}
            onChange={(e) => handleInputChange(e.target.value, "title")}
          />
        </div>

        <div className="col">
          <input
            type="number"
            name="points"
            className="form-control"
            placeholder={t?.answer_point}
            min={0}
            value={answer.score}
            onChange={(e) => handleInputChange(e.target.value, "score")}
          />
        </div>

        <div className="col flex-none w-auto">
          <div className="form-check form-switch align-items-center d-flex gap-2">
            <input
              type="checkbox"
              className="form-check-input"
              id={`answer_${answer.id}_image_required`}
              checked={answer.isRequired}
              onChange={(e) =>
                handleInputChange(e.target.checked, "isRequired")
              }
            />
            <label
              className="form-check-label"
              htmlFor={`answer_${answer.id}_image_required`}
              dangerouslySetInnerHTML={{
                __html: t?.required_image,
              }}
            />
          </div>
        </div>

        <div className="col flex-none w-auto">
          <button
            disabled={answerIndex > 1 ? false : true}
            className="btn btn-danger btn-sm fw-bold"
            onClick={() => answerIndex > 1 && removeAnswer(answer.id)}
          >
            {t?.remove_answer}
          </button>
        </div>

        {answerType === "radio" && (
          <div className="col flex-none w-auto">
            <button
              disabled={answer.subQuestions.length > 0 ? true : false}
              className="w-100 btn btn-theme text-black fw-bold  btn-sm"
              onClick={addSubQuestion}
            >
              + {t?.add_sub_question}
            </button>
          </div>
        )}
      </div>

      {subQuestions.length > 0 && (
        <div className="mt-3">
          <div className="row g-2">
            {subQuestions.map((subQuestion, index) => (
              <Question
                t={t}
                key={subQuestion.id}
                question={subQuestion}
                questionIndex={`${index + 1}`}
                // questionIndex={`${questionIndex}.${answerIndex}.${index + 1}`}
                updateQuestion={updateSubQuestion}
                removeQuestion={removeSubQuestion}
                questions={subQuestions}
                setQuestions={setSubQuestions}
                categoryData={categoryData}
                sortButtonShow={false}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Answer;
