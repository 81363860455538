import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useResolvedPath,
  useMatch,
  NavLink,
  useLocation,
  matchPath,
  Link,
} from "react-router-dom";
import { AppSettings } from "../../config/app-settings";

export function NavItem({ menu, events = true, handleGroupShowClick }) {
  const context = useContext(AppSettings);
  const [isActive, setIsActive] = useState(false); // Menü öğesinin aktifliğini kontrol etmek için state
  const location = useLocation();
  const menuRef = useRef(null);

  const handleMenuClick = () => {
    setIsActive(!isActive); // Eğer aktifse pasif, pasifse aktif yap
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsActive(false); // Menü dışında tıklanırsa menüyü kapat
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let path = menu.slug ? menu.slug : "";
  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname });

  if (menu.is_header) {
    return <div className="menu-header">{menu.name}</div>;
  }

  if (menu.is_divider) {
    return <div className="menu-divider"></div>;
  }

  let match2;

  if (path !== "/") {
    match2 = matchPath({ path: path, end: false }, location.pathname);
  }

  let icon = menu.icon && (
    <div className="menu-icon">
      <i className={menu.icon}></i>
    </div>
  );
  let img = menu.img && (
    <div className="menu-icon-img">
      <img src={menu.img} alt="" />
    </div>
  );
  let caret = menu.children && menu.children.length !== 0 && !menu.badge && (
    <div className="menu-caret">
      <b className="caret"></b>
    </div>
  );
  let label = menu.label && (
    <span className="menu-label ms-5px">{menu.label}</span>
  );
  let badge = menu.badge && <div className="menu-badge">{menu.badge}</div>;
  let highlight = menu.highlight && (
    <i className="fa fa-paper-plane text-theme"></i>
  );
  let name =
    menu.name && menu.name === "Rapor 1" ? (
      <div className="menu-text"></div>
    ) : menu.name === "Rapor 2" ? (
      <div className="menu-text"></div>
    ) : menu.name === "Rapor 3" ? (
      <div className="menu-text"></div>
    ) : (
      <div className="menu-text">
        {menu.name} {label} {highlight}
      </div>
    );
  return (
    <div
      ref={menuRef}
      className={`menu-item ${
        menu.slug
          ? "/" + menu.slug === location.pathname
            ? " active "
            : ""
          : ""
      } ${
        menu.children && menu.children.length !== 0
          ? `has-sub ${isActive ? "active" : ""}`
          : ""
      } ${!menu.icon ? " ps-0 " : ""}`}
    >
      <div>
        {events && (
          <div className="menu-item-events">
            {((menu.page_type && menu.page_type !== "default") ||
              (context?.me?.role?.id === 1 && menu.page_type)) && (
              <button
                onClick={() => context.setUpdatePage(menu)}
                data-bs-toggle="modal"
                data-bs-target="#createPageModal"
                className="btn btn-sm p-0 btn-success d-flex align-items-center justify-content-center"
                style={{
                  width: 15,
                  height: 16,
                }}
              >
                <i className="fas fa-edit" style={{ fontSize: 10 }}></i>
              </button>
            )}

            {context?.me?.role?.id === 1 &&
              menu.page_type &&
              (menu.page_type !== "default" || menu.iframe) && (
                <button
                  onClick={() => context.setDeletePage(menu)}
                  data-bs-toggle="modal"
                  data-bs-target="#deletePageModal"
                  className="btn btn-sm p-0 btn-danger d-flex align-items-center justify-content-center"
                  style={{
                    width: 15,
                    height: 16,
                  }}
                >
                  <i className="fas fa-trash-alt" style={{ fontSize: 10 }}></i>
                </button>
              )}
          </div>
        )}

        {menu.slug === "talep-formu" ? (
          <NavLink
            className="menu-link"
            to={"#"}
            data-bs-toggle="modal"
            data-bs-target="#modalTalepFormu"
          >
            {img} {icon} {name}
            {caret} {badge}
          </NavLink>
        ) : menu.url ? (
          <a
            className="menu-link"
            href={menu.url}
            target="_blank"
            rel="noreferrer"
          >
            {img} {icon} {name}
            {caret} {badge}
          </a>
        ) : menu.children && menu.children.length !== 0 ? (
          <span
            className={`menu-link pointer ${
              !icon ? "fw-medium text-white" : ""
            }`}
            state={{
              menu: menu.children && menu.children.length ? null : menu,
            }}
            style={{
              fontSize: !icon ? 13 : 13,
            }}
            onClick={handleMenuClick}
          >
            {img} {icon} {name}
            {caret} {badge}
          </span>
        ) : (
          <>
            {handleGroupShowClick ? (
              <Link
                className="menu-link"
                to={
                  menu.slug
                    ? menu.children && menu.children.length !== 0
                      ? "javascript:;"
                      : menu.slug + "?id=" + menu.id
                    : "javascript:;"
                }
                state={{
                  menu: null,
                }}
                onClick={() => handleGroupShowClick(menu)}
              >
                {img} {icon} {name}
                {caret} {badge}
              </Link>
            ) : (
              <Link
                className="menu-link"
                to={
                  menu.children && menu.children.length !== 0
                    ? "javascript:;"
                    : menu.slug + "?id=" + menu.id
                }
                state={{
                  menu: menu.children && menu.children.length ? null : menu,
                }}
              >
                {img} {icon} {name}
                {caret} {badge}
              </Link>
            )}
          </>
        )}
      </div>

      {/* Alt menüler yalnızca aktif menüler için görünür */}
      {isActive && menu.children && menu.children.length !== 0 && (
        <div className="menu-submenu">
          {menu.children.map((submenu, i) => (
            <NavItem
              key={i}
              menu={submenu}
              events={events}
              handleGroupShowClick={handleGroupShowClick}
            />
          ))}
        </div>
      )}
    </div>
  );
}
