import React, { useContext, useEffect, useState } from "react";
import toastMessage from "../../components/ToastMessage";
import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";
import DynamicCard from "../../components/DynamicCard";
import { Card, CardBody, CardExpandToggler } from "../../components/card/card";

export default function DevamEdenAnketler() {
  const context = useContext(AppSettings);

  // Atanmış Anketleri Getir
  const [assigmentData, setAssigmentData] = useState(null);

  async function handleGetReports() {
    setAssigmentData(null);

    try {
      const response = await apiClient.get(`/street-report`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.length !== 0) {
        setAssigmentData(response.data.sort((a, b) => a.id - b.id));
      } else {
        setAssigmentData([]);
      }
    } catch (error) {
      setAssigmentData(null);

      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error?.response?.data?.message, "error");
      }
    }
  }

  useEffect(() => {
    handleGetReports();
  }, []);

  // Status
  async function handleStatus(id, status) {
    try {
      const response = await apiClient.put(
        "/street-report/" + id,
        {
          status: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        toastMessage(context?.t?.anket_tamamlandi, "success");
        handleGetReports();
      }
    } catch (error) {
      console.error("Error", error);
    }
  }

  // Download CSV
  async function handleDownload(name, id) {
    try {
      const response = await apiClient.get(
        `/street-report-reply/${id}/export`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        const link = document.createElement("a");
        link.href = response.data.url;
        link.setAttribute("download", name + "_report.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();

        toastMessage(context?.t?.rapor_basariyla_olusturuldu, "success");
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error?.response?.data?.message, "error");
      }
    }
  }

  // Preview
  const [dataPreview, setDataPreview] = useState(null);

  async function handlePreview(item) {
    try {
      const response = await apiClient.get(
        `/street-report-reply/${item?.id}/result`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        if (response.data.length !== 0) {
          const transformedData = [];

          response.data.forEach((item) => {
            const { text: title, answers } = item;
            let chartType = 1;

            if (answers && Array.isArray(answers)) {
              const answerEntries = answers.map((answer) => ({
                name: answer.text,
                value: parseInt(answer.count) || 0,
              }));

              if (answerEntries.length > 20) {
                const hasLongName = answerEntries.some(
                  ({ name }) => name.length > 50
                );

                chartType = hasLongName ? 0 : 3; // Large datasets or names
              } else if (
                answerEntries.length >= 10 &&
                answerEntries.length <= 20
              ) {
                chartType = 2; // Moderate size datasets
              }

              let seriesData = answerEntries.map(({ name, value }) => ({
                name,
                value,
              }));

              // Handle chartType === 2 (Bar chart with categories)
              if (chartType === 2) {
                const names = seriesData.map((item) => item.name);
                const values = seriesData.map((item) => item.value);
                seriesData = [names, values];
              }

              transformedData.push({
                title,
                chart: chartType,
                options: {
                  width: {
                    label: "Orta-Büyük (4/3)",
                    value: "col-4",
                  },
                  options:
                    chartType === 3 || chartType === 0
                      ? seriesData
                      : chartType === 2
                      ? {
                          darkMode: context?.theme === "light" ? false : true,
                          grid: {
                            bottom: "20%",
                          },
                          xAxis: [
                            {
                              data: seriesData && seriesData[0],
                              type: "category",
                              axisLabel: {
                                rotate: 45,
                                textStyle: {
                                  color:
                                    context?.theme === "light"
                                      ? "#000"
                                      : "#fff",
                                  fontSize: 10,
                                },
                              },
                            },
                          ],
                          yAxis: [
                            {
                              type: "value",
                              axisLabel: {
                                textStyle: {
                                  color:
                                    context?.theme === "light"
                                      ? "#000"
                                      : "#fff",
                                },
                              },
                            },
                          ],
                          series: [
                            {
                              data: seriesData && seriesData[1],
                              name: null,
                              type: "bar",
                              markPoint: {
                                data: [
                                  {
                                    name: "Max",
                                    type: "max",
                                  },
                                  {
                                    name: "Min",
                                    type: "min",
                                  },
                                ],
                              },
                            },
                          ],
                          visualMap: context?.chartColor
                            ? {
                                max:
                                  seriesData && seriesData[1]
                                    ? Math.max(...seriesData[1])
                                    : 0,
                                min: 0,
                                show: false,
                                inRange: {
                                  colorLightness: [0.2, 1],
                                },
                              }
                            : false,
                          toolbox: {
                            show: true,
                            feature: {
                              restore: {
                                show: false,
                              },
                              magicType: {
                                show: true,
                                type: ["line", "bar"],
                              },
                              saveAsImage: {
                                show: false,
                              },
                            },
                            iconStyle: {
                              borderColor:
                                context?.theme === "light" ? "#000" : "#fff",
                            },
                          },
                          tooltip: {
                            trigger: "axis",
                          },
                          calculable: true,
                        }
                      : {
                          darkMode: context?.theme === "light" ? false : true,
                          legend: {
                            top: "top",
                            left: "center",
                            show: false,
                            orient: "horizontal",
                            itemGap: 20,
                            textStyle: {
                              color:
                                context?.theme === "light" ? "#000" : "#fff",
                            },
                          },
                          visualMap: context?.chartColor
                            ? {
                                min:
                                  seriesData && seriesData.length !== 0
                                    ? Math.min(
                                        ...seriesData.map((obj) => obj.value)
                                      )
                                    : 0,
                                max:
                                  seriesData && seriesData.length !== 0
                                    ? Math.max(
                                        ...seriesData.map((obj) => obj.value)
                                      )
                                    : 0,
                                show: false,
                                inRange: {
                                  colorLightness: [0.2, 1],
                                },
                              }
                            : false,
                          series: [
                            {
                              data: seriesData,
                              name: null,
                              type: "pie",
                              label: {
                                textStyle: {
                                  fontSize: 15,
                                },
                              },
                              radius: "50%",
                            },
                          ],
                          tooltip: {
                            trigger: "item",
                          },
                        },
                },
              });
            }
          });

          setDataPreview({ item: item, data: transformedData });
        } else {
          toastMessage(context?.t?.analiz_bulunamadi, "error");
        }
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error?.response?.data?.message, "error");
      }
    }
  }

  return (
    <div className="mb-5 pb-5">
      <h4 className="mb-4">{context?.t?.devam_eden_anketler}</h4>

      {assigmentData ? (
        assigmentData.length !== 0 ? (
          dataPreview ? (
            <div className="border-top pt-4">
              <div className="d-flex align-items-center flex-wrap gap-2 mb-3">
                <h5 className="mb-0">{dataPreview?.item?.name}</h5>
                <button
                  type="button"
                  className="btn btn-outline-theme btn-sm"
                  onClick={() => setDataPreview(null)}
                >
                  {context?.t?.go_back}
                </button>
              </div>

              <div className="row g-4">
                {dataPreview?.data ? (
                  dataPreview?.data.length !== 0 ? (
                    <>
                      {dataPreview?.data.map((item, index) =>
                        item.chart === 0 ? (
                          <div
                            key={index}
                            className={`graphic-item position-relative ${
                              item?.options?.width?.value.split("col-")[1]
                                ? "col-12 col-xl-" +
                                  item?.options?.width?.value.split("col-")[1]
                                : item?.options?.width?.value
                            }`}
                          >
                            <Card>
                              <CardBody className="d-flex flex-column h-100">
                                {item.title && (
                                  <div
                                    className={`d-flex align-items-center justify-content-between border-bottom pb-2 mb-3`}
                                  >
                                    <span
                                      className={`fw-bold h6 mb-0 flex-1`}
                                      style={{
                                        maxWidth: 500,
                                      }}
                                    >
                                      {item?.title?.toUpperCase()}
                                    </span>

                                    <div className="d-flex align-items-center ms-2">
                                      <CardExpandToggler />
                                    </div>
                                  </div>
                                )}

                                <div className="position-relative overflow-hidden flex-1 d-flex flex-column">
                                  <div
                                    className="table-responsive"
                                    style={{ height: 510 }}
                                  >
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>{context?.t?.answer}</th>
                                          <th className="text-end">
                                            {context?.t?.integer}
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {item.options.options.map(
                                          (item, index) => (
                                            <tr key={index}>
                                              <td>{item.name}</td>
                                              <td className="text-end">
                                                {item.value}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                {item.order && (
                                  <div className="text-end">#{item.order}</div>
                                )}
                              </CardBody>
                            </Card>
                          </div>
                        ) : (
                          <DynamicCard
                            events={false}
                            key={index}
                            fullScreen={true}
                            item={item}
                            cityFilter={"asd"}
                            surveyCard={true}
                          />
                        )
                      )}
                    </>
                  ) : (
                    <span className="text-white">
                      {context?.t?.analiz_bulunamadi}
                    </span>
                  )
                ) : (
                  <div className="spinner-border text-theme"></div>
                )}
              </div>
            </div>
          ) : (
            <div className="dataTables_wrapper dt-bootstrap5">
              <div className="dataTables_scroll">
                <div className="dataTables_scrollBody table-responsive">
                  <table className="table text-nowrap w-100 dataTable">
                    <thead>
                      <tr>
                        <th className="px-2" style={{ width: 50 }}>
                          #
                        </th>
                        <th className="px-2" style={{ width: 100 }}>
                          {context?.t?.anket_indir}
                        </th>
                        <th className="px-2" style={{ width: 100 }}>
                          {context?.t?.status}
                        </th>
                        <th className="px-2" style={{ width: 100 }}>
                          {context?.t?.preview}
                        </th>
                        <th className="px-2">{context?.t?.anket_adi}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {assigmentData
                        .sort((a, b) => a.status - b.status)
                        .filter((item) => item.customer_id !== null)
                        .map((item, index) => (
                          <tr
                            key={index}
                            style={{
                              background: item?.status
                                ? "rgb(60 210 165 / 16%)"
                                : "",
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-theme w-100"
                                onClick={() =>
                                  handleDownload(item?.name, item?.id)
                                }
                              >
                                {context?.t?.indir}
                              </button>
                            </td>
                            <td>
                              <button
                                type="button"
                                className={`btn w-100 btn-outline-theme`}
                                onClick={() =>
                                  handleStatus(item.id, !item?.status)
                                }
                              >
                                {item?.status
                                  ? context?.t?.go_back
                                  : context?.t?.tamamla}
                              </button>
                            </td>
                            <td>
                              <button
                                type="button"
                                className={`btn w-100 btn-outline-theme`}
                                onClick={() => handlePreview(item)}
                              >
                                {context?.t?.preview}
                              </button>
                            </td>
                            <td>{item.name}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        ) : (
          <span>{context?.t?.devam_eden_bir_anket_yoktur}</span>
        )
      ) : (
        <div className="spinner-border text-theme"></div>
      )}
    </div>
  );
}
