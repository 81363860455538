import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";

import Select from "react-select";
import { ToastContainer } from "react-toastify";
import ReactECharts from "echarts-for-react";

import { AppSettings } from "../../config/app-settings.js";
import { Card, CardBody } from "../card/card.jsx";
import toastMessage from "../ToastMessage.js";
import apiClient from "../../ApiClient.js";
import WordCloud from "../WordCloud.jsx";
import DynamicTable from "../DynamicTable.jsx";
import DynamicMap from "../DynamicMap.jsx";
import moment from "moment";
import DynamicNote from "../DynamicNote.jsx";
import DynamicCountCard from "../DynamicCountCard.jsx";

function formatErrorMessage(message, errorMessage) {
  const parts = message.split(":");

  const mainMessage = parts[0].trim();
  const note = parts[1] && parts[1].trim();

  return `${errorMessage} 
  
  ${mainMessage}
  ${note} `;
}

export default function CreateGraphicComponent({ modalOpen = false }) {
  const context = useContext(AppSettings);

  const [chartViewSelectOptions, setChartViewSelectOptions] = useState([
    { value: 1, label: "Pasta Grafiği" },
    { value: 2, label: "Çubuk Grafiği" },
    { value: 3, label: "Kelime Bulutu" },
    { value: 4, label: "Tablo" },
    { value: 5, label: "Harita" },
  ]);

  const [columnOperationSelectOptions, setColumnOperationSelectOptions] =
    useState([
      { value: "select", label: "TÜMÜ" },
      { value: "column", label: "SÜTUN" },
      { value: "count", label: "SAY" },
      { value: "avg", label: "ORTALAMA" },
      { value: "sum", label: "TOPLAM" },
      { value: "min", label: "MİNİMUM" },
      { value: "max", label: "MAKSİMUM" },
      { value: "distinct", label: "FARKLI" },
      { value: "toDate", label: "TARİH" },
      { value: "dayOfMonth", label: "GÜN" },
      { value: "toMonth", label: "AY" },
      { value: "toYear", label: "YIL" },
      { value: "toHour", label: "SAAT" },
      { value: "toMinute", label: "DAKİKA" },
      { value: "toSecond", label: "SANİYE" },
    ]);

  const [selectedOperatorOptions, setSelectedOperatorOptions] = useState([
    { value: "and", label: "Ve" },
    { value: "or", label: "Veya" },
  ]);

  const [selectedConditionOptions, setSelectedConditionOptions] = useState([
    { value: "=", label: "=" },
    { value: "!=", label: "!=" },
    { value: ">", label: ">" },
    { value: ">=", label: ">=" },
    { value: "<", label: "<" },
    { value: "<=", label: "<=" },
    { value: "in", label: "İÇİNDE" },
    { value: "notin", label: "DIŞINDA" },
    { value: "like", label: "BENZER" },
    { value: "notlike", label: "BENZEMEZ" },
    { value: "isnull", label: "BOŞ" },
    { value: "isnotnull", label: "DOLU" },
  ]);

  const [
    legendPositionOrientSelectOptions,
    setLegendPositionOrientSelectOptions,
  ] = useState([
    {
      value: "vertical",
      label: "Dikey",
    },
    {
      value: "horizontal",
      label: "Yatay",
    },
  ]);

  const [legendPositionLeftSelectOptions, setLegendPositionLeftSelectOptions] =
    useState([
      {
        value: "left",
        label: "Sol",
      },
      {
        value: "center",
        label: "Orta",
      },
      {
        value: "right",
        label: "Sağ",
      },
    ]);

  const [legendPositionTopSelectOptions, setLegendPositionTopSelectOptions] =
    useState([
      {
        value: "top",
        label: "Yukarı",
      },
      {
        value: "middle",
        label: "Orta",
      },
      {
        value: "bottom",
        label: "Aşağı",
      },
    ]);

  const [dateColumn, setDateColumn] = useState([
    {
      value: "todate",
      label: "TARİH",
    },
    {
      value: "dayofmonth",
      label: "GÜN",
    },
    {
      value: "tomonth",
      label: "AY",
    },
    {
      value: "toyear",
      label: "YIL",
    },
    {
      value: "tohour",
      label: "SAAT",
    },
    {
      value: "tominute",
      label: "DAKİKA",
    },
    {
      value: "tosecond",
      label: "SANİYE",
    },
  ]);

  const substringsToRemove = [
    "_todate",
    "_dayofmonth",
    "_tomonth",
    "_toyear",
    "_tohour",
    "_tominute",
    "_tosecond",
  ];

  const [showLegend, setShowLegend] = useState(true);
  const [legendOrient, setLegendOrient] = useState({
    value: "horizontal",
    label: "Yatay",
  });
  const [legendPosition, setLegendPosition] = useState({
    left: { value: "center", label: "Orta" },
    top: { value: "top", label: "Yukarı" },
  });
  const [graphicWidth, setGraphicWidth] = useState({
    value: "col-12",
    label: "12/12",
  });
  const [graphicHeight, setGraphicHeight] = useState(7);

  const toggleLegend = () => setShowLegend(!showLegend);

  const handleOrientChange = (value) => setLegendOrient(value);

  const handlePositionChange = (name, value) => {
    setLegendPosition((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [customerControl, setCustomerControl] = useState(null);

  const [customerList, setCustomerList] = useState([]);
  const [customerValue, setCustomerValue] = useState(null);

  const [loader, setLoader] = useState(false);
  const [previewShow, setPreviewShow] = useState(false);

  const [previewData, setPreviewData] = useState([]);

  const [status, setStatus] = useState(true);

  const [groupingColumns, setGroupingColumns] = useState([]);

  useEffect(() => {
    if (context.allCustomerSelect && context.allCustomerSelect.length > 0) {
      const hasAllOption = context.allCustomerSelect.some(
        (customer) => customer.value === "all"
      );

      if (!hasAllOption) {
        const customerListArray = [...context.allCustomerSelect];
        customerListArray.unshift({ value: "all", label: "Tümü" });
        setCustomerList(customerListArray);
      } else {
        setCustomerList(context.allCustomerSelect);
      }
    }
  }, [context.allCustomerSelect]);

  const [columns, setColumns] = useState([]);

  const handleAddColumn = () => {
    setColumns([
      ...columns,
      {
        id: Date.now(),
        selectedColumns: [],
        operationType: null,
        dateGroupValue: null,
      },
    ]);
  };

  const handleRemoveColumn = (id) => {
    setColumns(columns.filter((column) => column.id !== id));
  };

  const handleColumnChange = (selectedColumns, index) => {
    const newColumns = [...columns];
    newColumns[index].selectedColumns = selectedColumns;
    setColumns(newColumns);
  };

  const handleOperationTypeChange = (operationType, index) => {
    const newColumns = [...columns];
    newColumns[index].operationType = operationType;
    setColumns(newColumns);
  };

  const handleDateTypeChange = (dateGroupValue, index) => {
    const newColumns = [...columns];
    newColumns[index].dateGroupValue = dateGroupValue;
    setColumns(newColumns);
  };

  const [page, setPage] = useState(null);
  const [chartOrder, setChartOrder] = useState(1);
  const [chartName, setChartName] = useState("");
  const [chartDescription, setChartDescription] = useState("");
  const [chartView, setChartView] = useState(null);
  const [chartNoteValue, setChartNoteValue] = useState("");
  const [files, setFiles] = useState(null);
  const [fileColumns, setFileColumns] = useState([]);
  const [operation, setOperation] = useState({
    conditions: [],
    operationType: null,
    orderings: [],
  });

  const [fileTypes, setFileTypes] = useState(null);
  const [fileTypesCustomer, setFileTypesCustomer] = useState(null);

  const [pages, setPages] = useState(null);
  const [pageCustomer, setPageCustomer] = useState(null);

  useEffect(() => {
    if (context && context.me) {
      setFileTypesCustomer(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: context?.t?.mine,
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );
      setCustomerControl(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: context?.t?.mine,
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );
      setCustomerControl(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: context?.t?.mine,
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );

      //--

      setChartViewSelectOptions([
        { value: 1, label: context?.t?.pieChart },
        { value: 6, label: context?.t?.pieRoundedChart },
        { value: 8, label: context?.t?.pieRoseTypeChart },
        { value: 2, label: context?.t?.barChart },
        {
          value: 11,
          type: "multiple-bar",
          label: context?.t?.coklu_cubuk_grafigi,
        },
        {
          value: 13,
          type: "multiple-bar",
          label: context?.t?.coklu_cubuk_grafigi_yatay,
        },
        {
          value: 14,
          type: "multiple-bar",
          label: context?.t?.coklu_cubuk_grafigi_dikey,
        },
        {
          value: 15,
          type: "multiple-bar",
          label: context?.t?.coklu_alan_grafigi_dikey,
        },
        {
          value: 16,
          type: "multiple-bar",
          label: context?.t?.coklu_cizgi_grafigi_dikey,
        },
        { value: 7, label: context?.t?.barPolarTangential },
        { value: 9, label: context?.t?.zaman_grafigi },
        // { value: 9, label: context?.t?.treeChart },
        { value: 3, label: context?.t?.wordCloud },
        { value: 12, label: context?.t?.bilgi_karti },
        { value: 4, label: context?.t?.table },
        { value: 5, label: context?.t?.map },
        { value: 10, label: context?.t?.note },
      ]);

      setColumnOperationSelectOptions([
        { value: "select", label: context?.t?.all },
        { value: "column", label: context?.t?.column },
        { value: "count", label: context?.t?.count },
        { value: "avg", label: context?.t?.average },
        { value: "sum", label: context?.t?.total },
        { value: "min", label: context?.t?.minimum },
        { value: "max", label: context?.t?.maximum },
        { value: "distinct", label: context?.t?.distinct },
        { value: "toDate", label: context?.t?.date },
        { value: "dayOfMonth", label: context?.t?.day },
        { value: "toMonth", label: context?.t?.month },
        { value: "toYear", label: context?.t?.year },
        { value: "toHour", label: context?.t?.hour },
        { value: "toMinute", label: context?.t?.minute },
        { value: "toSecond", label: context?.t?.second },
      ]);

      setSelectedOperatorOptions([
        { value: "and", label: context?.t?.and },
        { value: "or", label: context?.t?.or },
      ]);

      setSelectedConditionOptions([
        { value: "=", label: "=" },
        { value: "!=", label: "!=" },
        { value: ">", label: ">" },
        { value: ">=", label: ">=" },
        { value: "<", label: "<" },
        { value: "<=", label: "<=" },
        { value: "in", label: context?.t?.in },
        { value: "notin", label: context?.t?.out },
        { value: "like", label: context?.t?.like },
        { value: "notlike", label: context?.t?.notLike },
        { value: "isnull", label: context?.t?.empty },
        { value: "isnotnull", label: context?.t?.filled },
      ]);

      setLegendPositionOrientSelectOptions([
        {
          value: "vertical",
          label: context?.t?.vertical,
        },
        {
          value: "horizontal",
          label: context?.t?.horizontal,
        },
      ]);

      setLegendPositionLeftSelectOptions([
        {
          value: "left",
          label: context?.t?.left,
        },
        {
          value: "center",
          label: context?.t?.middle,
        },
        {
          value: "right",
          label: context?.t?.right,
        },
      ]);
      setLegendPositionTopSelectOptions([
        {
          value: "top",
          label: context?.t?.up,
        },
        {
          value: "middle",
          label: context?.t?.middle,
        },
        {
          value: "bottom",
          label: context?.t?.down,
        },
      ]);

      setDateColumn([
        {
          value: "todate",
          label: context?.t?.date,
        },
        {
          value: "dayofmonth",
          label: context?.t?.day,
        },
        {
          value: "tomonth",
          label: context?.t?.month,
        },
        {
          value: "toyear",
          label: context?.t?.year,
        },
        {
          value: "tohour",
          label: context?.t?.hour,
        },
        {
          value: "tominute",
          label: context?.t?.minute,
        },
        {
          value: "tosecond",
          label: context?.t?.second,
        },
      ]);
      setLegendOrient({
        value: "horizontal",
        label: context?.t?.horizontal,
      });
      setLegendPosition({
        left: { value: "center", label: context?.t?.middle },
        top: { value: "top", label: context?.t?.up },
      });
      setGraphicWidth({
        value: "col-12",
        label: "12/12",
      });
      setGraphicHeight(7);
    }
  }, [context]);

  async function handlePageSelect(e) {
    const data = await context.getOncePages(e ? e.value : customerControl);

    if (data) {
      setPages(data);
    } else {
      setPages(null);
    }

    setPageCustomer(e);
  }

  async function handleFileTypesCustomer(e) {
    setFileTypes(null);
    setFiles(null);

    const data = await context.getFileTypes(
      e ? e.value : customerControl,
      true
    );

    if (data) {
      setFileTypes(data);
    } else {
      setFileTypes(null);
    }

    setFileTypesCustomer(e);
  }

  async function getFileColumns(name) {
    setFileColumns(null);

    try {
      const response = await apiClient.get(`/columns/${name}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response) {
        setFileColumns(response.data.columns);
      }
    } catch (error) {
      console.error("Get File Types Error", error);
    }
  }

  useEffect(() => {
    if (files) {
      getFileColumns(files?.value);
    }
  }, [files]);

  const addCondition = () => {
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: [
        ...prevOperation.conditions,
        {
          selectedColumn: null,
          selectedOperator: null,
          conditionDisabled: false,
          selectedCondition: null,
          inputDisabled: false,
          inputValue: "",
        },
      ],
    }));
  };

  const removeCondition = (conditionIndex) => {
    const newConditions = [...operation.conditions];
    newConditions.splice(conditionIndex, 1);
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleConditionColumnChange = (conditionIndex, selectedOption) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].selectedColumn = selectedOption;
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleOperatorChange = (conditionIndex, selectedOption) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].selectedOperator = selectedOption;
    newConditions[conditionIndex].conditionDisabled =
      selectedOption && selectedOption.value !== "";
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleConditionChange = (conditionIndex, selectedOption) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].selectedCondition = selectedOption;
    if (
      selectedOption &&
      (selectedOption.value === "isnull" ||
        selectedOption.value === "isnotnull")
    ) {
      newConditions[conditionIndex].inputDisabled = true;
      newConditions[conditionIndex].inputValue = "";
    } else {
      newConditions[conditionIndex].inputDisabled = false;
    }
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleInputChange = (conditionIndex, event) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].inputValue = event.target.value;
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleAddOrder = () => {
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: [
        ...prevOperation.orderings,
        {
          column: null,
          order: null,
        },
      ],
    }));
  };

  const handleOrderChange = (orderIndex, selectedOption) => {
    const newOrderings = [...operation.orderings];
    newOrderings[orderIndex].order = selectedOption;
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: newOrderings,
    }));
  };

  const handleOrderColumnChange = (orderIndex, selectedOption) => {
    const newOrderings = [...operation.orderings];
    newOrderings[orderIndex].column = selectedOption;
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: newOrderings,
    }));
  };

  const handleRemoveOrder = (orderIndex) => {
    const newOrderings = [...operation.orderings];
    newOrderings.splice(orderIndex, 1);
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: newOrderings,
    }));
  };

  // const checkDateColumnSelected = (selectedOptions) => {
  //   console.log(selectedOptions);
  //   const selectedValues = selectedOptions.map((option) => option.value);
  //   const dateColumns = ["day", "month", "year", "hour", "minute", "second"];
  //   for (const selectedValue of selectedValues) {
  //     if (dateColumns.includes(selectedValue)) {
  //       toastMessage("Tarih sütunu seçmeyi unutmayın!", "warning");
  //       return;
  //     }
  //   }
  // };

  const handleGroupingChange = (selectedOptions) => {
    setGroupingColumns(selectedOptions);
  };

  const validateConditions = () => {
    for (const condition of operation.conditions) {
      if (
        !condition.selectedColumn ||
        !condition.selectedCondition ||
        !condition.selectedOperator ||
        (condition.selectedCondition.value !== "isnull" &&
          condition.selectedCondition.value !== "isnotnull" &&
          !condition.inputValue)
      ) {
        toastMessage(context?.t?.conditions_sections_required, "error");
        return false;
      }
    }
    return true;
  };

  const validateColumns = () => {
    for (const column of columns) {
      if (!column.selectedColumns || !column.operationType) {
        toastMessage(context?.t?.columns_sections_required, "error");
        return false;
      }
    }
    return true;
  };

  const [data, setData] = useState(null);
  const [tablePage, setTablePage] = useState(1);
  const [tableCondition, setTableCondition] = useState({
    column: null,
    value: "",
  });

  const handleCreateButtonClick = async () => {
    if (chartView?.value === 10) {
      if (!page || !chartOrder || !chartName || !chartView) {
        toastMessage(context?.t?.chart_sections_required, "error");
        return;
      }

      if (chartName.length > 100) {
        toastMessage(context?.t?.chart_name_error, "error");
        return;
      }
    } else {
      if (
        !page ||
        !chartOrder ||
        !chartName ||
        !chartView ||
        (chartView?.value !== 5 && !files)
      ) {
        toastMessage(context?.t?.chart_sections_required, "error");
        return;
      }

      if (chartName.length > 100) {
        toastMessage(context?.t?.chart_name_error, "error");
        return;
      }

      if (chartView.value !== 5) {
        if (columns.length === 0) {
          toastMessage(context?.t?.select_column_error, "error");
          return;
        }

        if (!validateColumns()) {
          return;
        }
      }

      // if (operation.conditions.length === 0) {
      //   toastMessage("En Az Bir Koşul Seçin.", "error");
      //   return;
      // }

      if (operation.conditions.length !== 0 && !validateConditions()) {
        return;
      }

      const selectedColumnValues = columns.map(
        (col) => col.selectedColumns.value
      );
      for (const groupingColumn of groupingColumns) {
        if (!selectedColumnValues.includes(groupingColumn.value)) {
          toastMessage(
            `${context?.t?.group_warning} - "${groupingColumn.label}"`,
            "error"
          );
          return;
        }
      }
    }

    setTablePage(1);
    setTableCondition({ column: null, value: "" });

    setTimeout(() => {
      fetchPreview();
    }, 500);
  };

  async function fetchPreview(params) {
    if (chartView?.value === 10) {
      setLoader(false);
      setPreviewShow(true);
      setPreviewChartShow(true);

      const data = {
        chart: chartView.value,
        page_id: page.value,
        title: chartName,
        description: chartDescription,
        status: true,
        order: chartOrder,
        type: "-",
        customer_id: context.me
          ? context?.me?.role?.id === 1
            ? null
            : context.me.customer.id
          : null,
        customer: context.me
          ? context?.me?.role?.id === 1
            ? null
            : context.me.customer.id
          : null,
        sql: {
          column: [],
          condition: [],
          group: [],
        },
      };

      setData(data);
    } else if (chartView?.value === 5 && !files) {
      setLoader(false);
      setPreviewShow(true);

      const data = {
        chart: chartView.value,
        page_id: page.value,
        title: chartName,
        description: chartDescription,
        status: true,
        order: chartOrder,
        type: "-",
        customer_id: context.me
          ? context?.me?.role?.id === 1
            ? null
            : context.me.customer.id
          : null,
        customer: context.me
          ? context?.me?.role?.id === 1
            ? null
            : context.me.customer.id
          : null,
        sql: {
          column: [],
          condition: [],
          group: [],
        },
      };

      setData(data);
    } else {
      if (page && chartOrder && chartName && chartView && files) {
        const data = {
          chart: chartView.value,
          page_id: page.value,
          title: chartName,
          description: chartDescription,
          status: true,
          order: chartOrder,
          type: files?.value,
          customer_id: context.me
            ? context?.me?.role?.id === 1
              ? null
              : context.me.customer.id
            : null,
          customer: context.me
            ? context?.me?.role?.id === 1
              ? null
              : context.me.customer.id
            : null,
          sql: {
            column:
              columns.length !== 0
                ? columns.map((item) => {
                    return {
                      key: item.selectedColumns.value,
                      value: item.operationType.value,
                    };
                  })
                : [],
            condition:
              operation.conditions.length !== 0
                ? [
                    tableCondition.column
                      ? {
                          key: tableCondition.column,
                          value: tableCondition.value,
                          operator: "like",
                          type: "and",
                        }
                      : null,
                    ...operation.conditions.map((item) => {
                      return {
                        key: item.selectedColumn.value,
                        value: item.inputValue,
                        operator: item.selectedCondition.value,
                        type: item.selectedOperator.value,
                      };
                    }),
                  ].filter((item) => item !== null)
                : [
                    tableCondition.column
                      ? {
                          key: tableCondition.column,
                          value: tableCondition.value,
                          operator: "like",
                          type: "and",
                        }
                      : null,
                  ].filter((item) => item !== null),
            group:
              columns.length !== 0
                ? [
                    ...columns
                      .map((item) =>
                        item.dateGroupValue
                          ? item.dateGroupValue.map(
                              (dateGroup) => dateGroup.value
                            )
                          : []
                      )
                      .flat(),
                    ...groupingColumns.map((item) => item.value),
                  ]
                : [],
          },
        };

        setLoader(true);

        if (chartView.value === 4) {
          data.paginate = true;
        } else {
          if (data.paginate) {
            delete data.paginate;
          }
        }

        setData(data);

        try {
          const response = await apiClient.customPost(
            `/query/preview${data.paginate ? `?page=${tablePage}` : ""}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response) {
            // toastMessage("İşlem Doğrulandı", "success");
            setLoader(false);
            setPreviewShow(true);

            if (chartView.value === 4 || chartView.value === 5) {
              setPreviewData(response);
            } else {
              const filteredResponse = response.filter((item) => {
                return Object.values(item).every(
                  (value) => value !== "" && value !== null
                );
              });

              setPreviewData(filteredResponse);
            }

            if (context.graphicUpdate) {
              if (context.graphicUpdate.options.selectedColumn[0]) {
                setPreviewSelectValue(
                  response.some((item) =>
                    item.hasOwnProperty(
                      context.graphicUpdate.options.selectedColumn[0].value
                    )
                  )
                    ? context.graphicUpdate.options.selectedColumn[0]
                    : null
                );
              } else {
                setPreviewSelectValue(null);
              }

              if (context.graphicUpdate.options.selectedColumn[1]) {
                setPreviewSelectLabel(
                  response.some((item) =>
                    item.hasOwnProperty(
                      context.graphicUpdate.options.selectedColumn[1].value
                    )
                  )
                    ? context.graphicUpdate.options.selectedColumn[1]
                    : null
                );
              } else {
                setPreviewSelectLabel(null);
              }

              if (context.graphicUpdate.options.selectedColumn[2]) {
                setPreviewSelectWordCloud(
                  response.some((item) =>
                    item.hasOwnProperty(
                      context.graphicUpdate.options.selectedColumn[2].value
                    )
                  )
                    ? context.graphicUpdate.options.selectedColumn[2]
                    : null
                );
              } else {
                setPreviewSelectWordCloud(null);
              }

              if (context.graphicUpdate.options.selectedColumn[3]) {
                setPreviewSelectDate(
                  response.some((item) =>
                    item.hasOwnProperty(
                      context.graphicUpdate.options.selectedColumn[3].value
                    )
                  )
                    ? context.graphicUpdate.options.selectedColumn[3]
                    : null
                );
              } else {
                setPreviewSelectDate(null);
              }
            } else {
              setPreviewSelectWordCloud(null);
              setPreviewSelectValue(null);
              setPreviewSelectDate(null);
              setPreviewSelectLabel(null);
            }
          }
        } catch (error) {
          setLoader(false);

          if (error.response.data.errors) {
            Object.keys(error.response.data.errors).map((key, index) =>
              error.response.data.errors[key].map((errorMessage) =>
                toastMessage(
                  formatErrorMessage(errorMessage, context?.t?.data_type_error),
                  "error"
                )
              )
            );
          } else {
            toastMessage(
              formatErrorMessage(
                error.response.data.message,
                context?.t?.data_type_error
              ),
              "error"
            );
          }
        }
      }
    }
  }

  const [previewChartShow, setPreviewChartShow] = useState(false);
  const [previewSelectDate, setPreviewSelectDate] = useState(null);
  const [previewSelectValue, setPreviewSelectValue] = useState(null);
  const [previewSelectLabel, setPreviewSelectLabel] = useState(null);
  const activeChartOptions = [
    {
      id: 1,
      darkMode: true,
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: "50%",
          data: [],
          label: {
            textStyle: {
              fontSize: 15,
            },
          },
          itemStyle: {
            color: context?.chartColor,
          },
        },
      ],
    },
    {
      id: 2,
      tooltip: {
        trigger: "axis",
      },
      darkMode: true,
      toolbox: {
        show: true,
        feature: {
          magicType: { show: true, type: ["line", "bar"] },
          restore: { show: false },
          saveAsImage: { show: false },
        },
        iconStyle: { borderColor: "#fff" },
      },
      calculable: true,
      xAxis: [
        {
          type: "category",
          data: [],
          axisLabel: {
            rotate: 45,
            textStyle: {
              color: "#fff",
              fontSize: 10,
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
        },
      ],
      grid: { bottom: "20%" },
      series: [
        {
          name: "",
          type: "bar",
          data: [],
          markPoint: {
            data: [
              { type: "max", name: "Max" },
              { type: "min", name: "Min" },
            ],
          },
          itemStyle: {
            color: context?.chartColor,
          },
        },
      ],
    },
    {
      id: 11,
      darkMode: true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      yAxis: [
        {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
        },
      ],
    },
    {
      id: 13,
      darkMode: true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "value",
      },
    },
    {
      id: 14,
      darkMode: true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      yAxis: {
        type: "value",
      },
    },
    {
      id: 15,
      darkMode: true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      yAxis: [
        {
          type: "value",
        },
      ],
    },
    {
      id: 16,
      darkMode: true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      yAxis: [
        {
          type: "value",
        },
      ],
    },
    {
      id: 6,
      darkMode: true,
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          radius: ["40%", "70%"],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 15,
              fontWeight: "bold",
            },
          },
          name: "",
          type: "pie",
          data: [],
        },
      ],
    },
    {
      id: 7,
      darkMode: true,
      polar: {
        radius: [30, "80%"],
      },
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          type: "bar",
          coordinateSystem: "polar",
          label: {
            show: true,
            position: "middle",
            formatter: "{b}: {c}",
            itemStyle: {
              color: "#fff",
            },
          },
        },
      ],
    },
    {
      id: 8,
      darkMode: true,
      toolbox: {
        show: false,
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: [50, 250],
          center: ["50%", "50%"],
          roseType: "area",
          itemStyle: {
            borderRadius: 8,
          },
          data: [],
        },
      ],
    },
    {
      id: 9,
      darkMode: true,
      tooltip: {
        trigger: "axis",
        position: function (pt) {
          return [pt[0], "10%"];
        },
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: "none",
          },
          restore: {},
          saveAsImage: {},
        },
        iconStyle: {
          borderColor: "#ffffff",
        },
      },
      xAxis: {
        type: "time",
        boundaryGap: false,
        axisLabel: {
          color: "#ffffff",
        },
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, "100%"],
        axisLabel: {
          color: "#ffffff",
        },
      },
    },
  ];
  const [chartOptions, setChartOptions] = useState();
  const [wordCloudData, setWordCloudData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [previewSelectWordCloud, setPreviewSelectWordCloud] = useState(null);

  function handleSelectWordCloud(e) {
    setPreviewSelectWordCloud(e);
    setPreviewSelectValue(null);
    setPreviewSelectDate(null);
    setPreviewSelectLabel(null);
  }

  useEffect(() => {
    if (chartView) {
      setChartOptions(
        activeChartOptions.find((item) => item.id === chartView.value)
      );
    }
  }, [chartView]);

  useEffect(() => {
    if (chartView) {
      if (chartView.value !== 2 && chartView.value !== 9) {
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          legend: {
            show: showLegend,
            orient: legendOrient.value,
            left: legendPosition.left.value,
            top: legendPosition.top.value,
            itemGap: 20,
            textStyle: {
              color: "#fff",
            },
          },
        }));
      }
    }
  }, [chartView, showLegend, legendOrient, legendPosition, showLegend]);

  function handlePreview() {
    if (chartView.value !== 5) {
      if (chartView.value === 12) {
        if (!previewSelectValue) {
          toastMessage(context?.t?.choose_column_2, "error");
          return;
        }
      } else {
        if (chartView.value === 9 && !previewSelectDate) {
          toastMessage(context?.t?.choose_column_2, "error");
          return;
        }

        if (!previewSelectWordCloud && chartView.value !== 4) {
          if (!previewSelectValue || !previewSelectLabel) {
            toastMessage(context?.t?.choose_column_2, "error");
            return;
          }
        }
      }

      if (chartView.value === 1) {
        const chartData = previewData.map((item) => ({
          value: item[previewSelectValue.value],
          name: item[previewSelectLabel.value],
        }));

        setChartOptions((prevOptions) => ({
          ...prevOptions,
          tooltip: previewSelectValue.value.includes("_avg")
            ? {
                trigger: "item",
                formatter: function (params) {
                  return (
                    params.data.name + "<br /> %" + params.data.value.toFixed(2)
                  );
                },
              }
            : {
                trigger: "item",
              },
          visualMap: context?.chartColor
            ? {
                show: false,
                min: Math.min(...chartData.map((obj) => obj.value)),
                max: Math.max(...chartData.map((obj) => obj.value)),
                inRange: {
                  colorLightness: [0.2, 1],
                },
              }
            : false,
          series: [
            {
              ...prevOptions.series[0],
              data: chartData,
            },
          ],
        }));
      } else if (chartView.value === 8) {
        const chartData = previewData.map((item) => ({
          value: item[previewSelectValue.value],
          name: item[previewSelectLabel.value],
        }));

        setChartOptions((prevOptions) => ({
          ...prevOptions,
          tooltip: previewSelectValue.value.includes("_avg")
            ? {
                trigger: "item",
                formatter: function (params) {
                  return (
                    params.data.name + "<br /> %" + params.data.value.toFixed(2)
                  );
                },
              }
            : {
                trigger: "item",
              },
          visualMap: context?.chartColor
            ? {
                show: false,
                min: Math.min(...chartData.map((obj) => obj.value)),
                max: Math.max(...chartData.map((obj) => obj.value)),
                inRange: {
                  colorLightness: [0.2, 1],
                },
              }
            : false,
          series: [
            {
              ...prevOptions.series[0],
              data: chartData,
            },
          ],
        }));
      } else if (chartView.value === 6) {
        const chartData = previewData.map((item) => ({
          value: item[previewSelectValue.value],
          name: item[previewSelectLabel.value],
        }));

        setChartOptions((prevOptions) => ({
          ...prevOptions,
          tooltip: previewSelectValue.value.includes("_avg")
            ? {
                trigger: "item",
                formatter: function (params) {
                  return (
                    params.data.name + "<br /> %" + params.data.value.toFixed(2)
                  );
                },
              }
            : {
                trigger: "item",
              },
          visualMap: context?.chartColor
            ? {
                show: false,
                min: Math.min(...chartData.map((obj) => obj.value)),
                max: Math.max(...chartData.map((obj) => obj.value)),
                inRange: {
                  colorLightness: [0.2, 1],
                },
              }
            : false,
          series: [
            {
              ...prevOptions.series[0],
              data: chartData,
            },
          ],
        }));
      } else if (chartView.value === 7) {
        if (
          previewData &&
          previewData.length !== 0 &&
          previewSelectValue &&
          previewSelectLabel
        ) {
          setChartOptions((prevOptions) => ({
            ...prevOptions,
            tooltip: previewSelectValue.value.includes("_avg")
              ? {
                  trigger: "item",
                  formatter: function (params) {
                    return (
                      params.data.name +
                      "<br /> %" +
                      params.data.value.toFixed(2)
                    );
                  },
                }
              : {
                  trigger: "item",
                },
            angleAxis: {
              max: Math.max(
                ...previewData.map((item) => item[previewSelectValue.value])
              ),
              startAngle: 75,
              axisLabel: {
                color: "#fff",
              },
            },
            radiusAxis: {
              type: "category",
              data: previewData.map((item) => item[previewSelectLabel.value]),
              axisLabel: {
                color: "#fff",
              },
            },
            series: [
              {
                ...prevOptions.series[0],
                data: previewData.map((item) => item[previewSelectValue.value]),
              },
            ],
          }));
        }
      } else if (chartView.value === 9) {
        // Parametreler için veri yapısını oluştur
        const seriesData = {};
        const legendData = new Set();

        // Tarihe göre verileri birleştir ve değerleri topla
        const mergedData = {};

        previewData.forEach((item) => {
          const tarih = item[previewSelectDate.value];
          const parametre = item[previewSelectLabel.value];
          const deger = parseInt(item[previewSelectValue.value]);

          // Check if the necessary fields are present and valid
          if (tarih && parametre && !isNaN(deger)) {
            if (!mergedData[tarih]) {
              mergedData[tarih] = {};
            }

            if (!mergedData[tarih][parametre]) {
              mergedData[tarih][parametre] = 0;
            }

            mergedData[tarih][parametre] += deger; // Accumulate value for the same date and parameter
          }
        });

        // Convert mergedData back to an array
        const finalData = [];
        for (const tarih in mergedData) {
          for (const parametre in mergedData[tarih]) {
            finalData.push({
              tarih: tarih,
              parametre: parametre,
              deger: mergedData[tarih][parametre].toString(),
            });
          }
        }

        // Sorting by date and value
        finalData.sort((a, b) => {
          const dateDiff =
            new Date(a[previewSelectDate.value]) -
            new Date(b[previewSelectDate.value]);
          return dateDiff !== 0
            ? dateDiff
            : parseInt(a[previewSelectValue.value]) -
                parseInt(b[previewSelectValue.value]);
        });

        // Generate legend and series data
        finalData.forEach((item) => {
          const timestamp = new Date(item.tarih).getTime();
          const parametre = item.parametre;
          const deger = parseInt(item.deger);

          if (parametre) {
            // Check for valid parameter name
            // Prepare series data
            if (!seriesData[parametre]) {
              seriesData[parametre] = [];
            }
            seriesData[parametre].push([timestamp, deger]);

            // Prepare legend data
            legendData.add(parametre);
          }
        });

        const getRandomBrightColor = () => {
          const r = Math.floor(Math.random() * 256);
          const g = Math.floor(Math.random() * 256);
          const b = Math.floor(Math.random() * 256);

          // Canlı renk elde etmek için RGB değerlerinin toplamı yüksek olmalı
          const brightness = r + g + b;
          return brightness > 384
            ? `rgba(${r}, ${g}, ${b}, 1)`
            : getRandomBrightColor(); // 255 * 3 = 765
        };

        // Convert legend and series data to arrays
        const legendArray = Array.from(legendData).filter(Boolean); // Remove undefined entries
        const seriesArray = legendArray.map((parametre) => {
          const color = getRandomBrightColor();

          return {
            name: parametre,
            type: "line",
            smooth: true,
            symbol: "none",
            areaStyle: {
              color: `${color.replace(
                /rgba\((\d+), (\d+), (\d+), 1\)/,
                "rgba($1, $2, $3, 0.2)"
              )}`,
            },
            lineStyle: { color: color },
            data: seriesData[parametre],
          };
        });

        setChartOptions((prevOptions) => ({
          ...prevOptions,
          legend: {
            data: legendArray,
            textStyle: {
              color: "#ffffff",
            },
          },
          dataZoom: [
            legendArray.map((item) => {
              return {
                type: "inside",
                start: 0,
                end: "100%",
                textStyle: {
                  color: "#ffffff",
                },
              };
            }),
          ],
          series: seriesArray,
        }));
      } else if (chartView.value === 2) {
        setChartOptions((prevOptions) => {
          const sortedData = [...previewData].sort(
            (a, b) => a[previewSelectLabel.value] - b[previewSelectLabel.value]
          );

          return {
            ...prevOptions,
            visualMap: context?.chartColor
              ? {
                  show: false,
                  min: Math.min(
                    ...sortedData.map((item) => item[previewSelectValue.value])
                  ),
                  max: Math.max(
                    ...sortedData.map((item) => item[previewSelectValue.value])
                  ),
                  inRange: {
                    colorLightness: [0.2, 1],
                  },
                }
              : false,
            xAxis: [
              {
                ...prevOptions.xAxis[0],
                // Sıralanmış verilere göre xAxis etiketleri
                data: sortedData.map((item) => item[previewSelectLabel.value]),
              },
            ],
            series: [
              {
                ...prevOptions.series[0],
                // Sıralanmış verilere göre serideki veriler
                data: sortedData.map((item) => item[previewSelectValue.value]),
              },
            ],
          };
        });
      } else if (chartView.value === 11) {
        if (previewSelectDate && previewSelectValue && previewSelectLabel) {
          const xAxis = [
            ...new Set(
              previewData.map((item) => item[previewSelectDate.value])
            ),
          ];
          const legend = [
            ...new Set(
              previewData.map((item) => item[previewSelectValue.value])
            ),
          ];

          const transformedArray = previewData.reduce((acc, item) => {
            const existingModel = acc.find(
              (model) => model.name === item[previewSelectValue?.value]
            );

            if (existingModel) {
              existingModel.data.push(Number(item[previewSelectLabel.value]));
            } else {
              acc.push({
                name: item[previewSelectValue?.value],
                data: [Number(item[previewSelectLabel.value])],
                type: "bar",
                barGap: 0,
                label: {
                  show: true,
                  rotate: 90,
                  align: "left",
                  verticalAlign: "middle",
                  position: "insideBottom",
                  distance: 15,
                  formatter: "{c} " + item[previewSelectValue?.value],
                  fontSize: 13,
                },
                emphasis: {
                  focus: "series",
                },
              });
            }

            return acc;
          }, []);

          setChartOptions((prevOptions) => ({
            ...prevOptions,
            legend: {
              data: legend,
              itemGap: 20,
              textStyle: {
                color: "#fff",
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                data: xAxis,
                axisLabel: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            ],
            series: transformedArray,
          }));
        }
      } else if (chartView.value === 13) {
        if (previewSelectDate && previewSelectValue && previewSelectLabel) {
          const xAxis = [
            ...new Set(
              previewData.map((item) => item[previewSelectDate.value])
            ),
          ];
          const legend = [
            ...new Set(
              previewData.map((item) => item[previewSelectValue.value])
            ),
          ];

          const transformedArray = previewData.reduce((acc, item) => {
            const existingModel = acc.find(
              (model) => model.name === item[previewSelectValue?.value]
            );

            if (existingModel) {
              existingModel.data.push(Number(item[previewSelectLabel.value]));
            } else {
              acc.push({
                name: item[previewSelectValue?.value],
                data: [Number(item[previewSelectLabel.value])],
                type: "bar",
                stack: "total",
                label: {
                  show: true,
                },
                emphasis: {
                  focus: "series",
                },
              });
            }

            return acc;
          }, []);

          setChartOptions((prevOptions) => ({
            ...prevOptions,
            legend: {
              data: legend,
              itemGap: 20,
              textStyle: {
                color: "#fff",
              },
            },
            yAxis: {
              type: "category",
              data: xAxis,
              axisLabel: {
                textStyle: {
                  color: "#fff",
                },
              },
            },
            series: transformedArray,
          }));
        }
      } else if (chartView.value === 14) {
        if (previewSelectDate && previewSelectValue && previewSelectLabel) {
          const xAxis = [
            ...new Set(
              previewData.map((item) => item[previewSelectDate.value])
            ),
          ];
          const legend = [
            ...new Set(
              previewData.map((item) => item[previewSelectValue.value])
            ),
          ];

          const transformedArray = previewData.reduce((acc, item) => {
            const existingModel = acc.find(
              (model) => model.name === item[previewSelectValue?.value]
            );

            if (existingModel) {
              existingModel.data.push(Number(item[previewSelectLabel.value]));
            } else {
              acc.push({
                name: item[previewSelectValue?.value],
                data: [Number(item[previewSelectLabel.value])],
                type: "bar",
                stack: "total",
                barWidth: "60%",
                label: {
                  show: true,
                  formatter: "{c}",
                  fontSize: 13,
                },
                emphasis: {
                  focus: "series",
                },
              });
            }

            return acc;
          }, []);

          setChartOptions((prevOptions) => ({
            ...prevOptions,
            legend: {
              data: legend,
              itemGap: 20,
              textStyle: {
                color: "#fff",
              },
            },
            xAxis: {
              type: "category",
              data: xAxis,
              axisLabel: {
                textStyle: {
                  color: "#fff",
                },
              },
            },
            series: transformedArray,
          }));
        }
      } else if (chartView.value === 15) {
        if (previewSelectDate && previewSelectValue && previewSelectLabel) {
          const xAxis = [
            ...new Set(
              previewData.map((item) => item[previewSelectDate.value])
            ),
          ];
          const legend = [
            ...new Set(
              previewData.map((item) => item[previewSelectValue.value])
            ),
          ];

          const transformedArray = previewData.reduce((acc, item) => {
            const existingModel = acc.find(
              (model) => model.name === item[previewSelectValue?.value]
            );

            if (existingModel) {
              existingModel.data.push(Number(item[previewSelectLabel.value]));
            } else {
              acc.push({
                name: item[previewSelectValue?.value],
                data: [Number(item[previewSelectLabel.value])],
                type: "line",
                stack: "Total",
                label: {
                  show: true,
                  position: "top",
                },
                areaStyle: {},
                emphasis: {
                  focus: "series",
                },
              });
            }

            return acc;
          }, []);

          setChartOptions((prevOptions) => ({
            ...prevOptions,
            legend: {
              data: legend,
              itemGap: 20,
              textStyle: {
                color: "#fff",
              },
            },
            xAxis: [
              {
                type: "category",
                data: xAxis,
                boundaryGap: false,
                axisLabel: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            ],
            series: transformedArray,
          }));
        }
      } else if (chartView.value === 16) {
        if (previewSelectDate && previewSelectValue && previewSelectLabel) {
          const xAxis = [
            ...new Set(
              previewData.map((item) => item[previewSelectDate.value])
            ),
          ];
          const legend = [
            ...new Set(
              previewData.map((item) => item[previewSelectValue.value])
            ),
          ];

          const transformedArray = previewData.reduce((acc, item) => {
            const existingModel = acc.find(
              (model) => model.name === item[previewSelectValue?.value]
            );

            if (existingModel) {
              existingModel.data.push(Number(item[previewSelectLabel.value]));
            } else {
              acc.push({
                name: item[previewSelectValue?.value],
                data: [Number(item[previewSelectLabel.value])],
                type: "line",
                stack: "Total",
                label: {
                  show: true,
                  position: "top",
                },
                emphasis: {
                  focus: "series",
                },
              });
            }

            return acc;
          }, []);

          setChartOptions((prevOptions) => ({
            ...prevOptions,
            legend: {
              data: legend,
              itemGap: 20,
              textStyle: {
                color: "#fff",
              },
            },
            xAxis: [
              {
                type: "category",
                data: xAxis,
                boundaryGap: false,
                axisLabel: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            ],
            series: transformedArray,
          }));
        }
      } else if (chartView.value === 3) {
        let chartData;

        if (previewSelectWordCloud) {
          chartData = previewData
            .map((item) => item[previewSelectWordCloud.value])
            .join(", ");
        } else {
          chartData = previewData.map((item) => ({
            category: item[previewSelectLabel.value],
            value: item[previewSelectValue.value],
          }));
        }

        setWordCloudData(chartData);
      } else if (chartView.value === 4) {
        setTableData(previewData);
      }
    }

    setPreviewChartShow(true);
  }

  const [updateMapLocation, setUpdateMapLocation] = useState(null);
  const [mapLocation, setMapLocation] = useState({
    lat: 0,
    lng: 0,
    zoom: 0,
  });

  async function handleGraphicPost() {
    setLoader(true);

    const postData = data;

    postData.options = {
      noteValue: chartView.value === 10 ? chartNoteValue : null,
      file: {
        customerSelect: fileTypesCustomer,
        fileSelect: files,
      },
      page: {
        customerSelect: pageCustomer,
        pageSelect: page,
      },
      selectedColumn: [
        previewSelectValue,
        previewSelectLabel,
        previewSelectWordCloud,
        previewSelectDate,
      ],
      mapLocation: chartView.value === 5 ? mapLocation : null,
      width: graphicWidth,
      height: graphicHeight,
      options: wordCloudData.length !== 0 ? wordCloudData : chartOptions,
    };

    try {
      const response = context.graphicUpdate
        ? await apiClient.put(`/query/${context.graphicUpdate.id}`, postData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
        : await apiClient.customPost(`/query`, postData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
      if (response) {
        setLoader(false);
        toastMessage(
          context.graphicUpdate
            ? context?.t?.updated_chart
            : context?.t?.added_to_chart,
          "success"
        );

        setTimeout(() => {
          // context.getPages();
          // document.getElementById("createGraphicModalCloseButton").click();
          window.location.reload();
          // context.setGraphicFetch(!context.graphicFetch);
        }, 300);
      }
    } catch (error) {
      setLoader(false);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  function findNameById(array, id) {
    for (let item of array) {
      if (item.id === id) {
        return item.name;
      }
      if (item.children) {
        for (let child of item.children) {
          if (child.id === id) {
            return child.name;
          }
        }
      }
    }
    return null; // id bulunamazsa null döner
  }

  function handleResetPreview() {
    setPreviewShow(false);
    setPreviewChartShow(false);

    if (!context.graphicUpdate) {
      setPreviewData([]);
      setPreviewSelectValue(null);
      setPreviewSelectWordCloud(null);
      setPreviewSelectDate(null);
      setPreviewSelectLabel(null);
      setShowLegend(true);
      setLegendOrient({
        value: "horizontal",
        label: context?.t?.horizontal,
      });
      setLegendPosition({
        left: { value: "center", label: context?.t?.middle },
        top: { value: "top", label: context?.t?.up },
      });
      setGraphicWidth({
        value: "col-12",
        label: "12/12",
      });
      setGraphicHeight(7);
    }
  }

  function handleReset() {
    if (context && context.me) {
      setChartView(null);

      setPage(null);

      setChartName("");
      setFileTypesCustomer(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: context?.t?.mine,
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );
      setChartOrder(1);
      setChartDescription("");
      setFiles(null);
      setColumns([]);
      setGroupingColumns([]);
      setOperation({
        conditions: [],
        operationType: null,
        orderings: [],
      });

      handleFileTypesCustomer(customerControl);
      handlePageSelect(customerControl);
    }
  }

  // Update
  function getUpdateItems() {
    setPreviewShow(false);

    setChartView({
      value: context.graphicUpdate.chart,
      label: chartViewSelectOptions.find(
        (e) => e.value === context.graphicUpdate.chart
      ).label,
    });

    setChartName(context.graphicUpdate.title);
    setChartOrder(context.graphicUpdate.order);
    setChartDescription(context.graphicUpdate.description);

    if (context.graphicUpdate.options?.page?.customerSelect) {
      handlePageSelect(context.graphicUpdate.options?.page?.customerSelect);
      setPageCustomer(context.graphicUpdate.options?.page?.customerSelect);
    }

    if (context.graphicUpdate.options?.page?.pageSelect) {
      setPage(context.graphicUpdate.options?.page?.pageSelect);
    }

    if (context.graphicUpdate.options?.file?.customerSelect) {
      handleFileTypesCustomer(
        context.graphicUpdate.options?.file?.customerSelect
      );
      setFileTypesCustomer(context.graphicUpdate.options?.file?.customerSelect);
    }
    if (context.graphicUpdate.options?.file?.customerSelect) {
      setFiles(context.graphicUpdate.options.file.fileSelect);
    }

    let newArray = [];

    context.graphicUpdate.payload.group.forEach((item) => {
      substringsToRemove.forEach((checkItem) => {
        if (item.includes(checkItem)) {
          newArray.push(item);
        }
      });
    });

    setColumns(
      context.graphicUpdate.payload.column
        ? context.graphicUpdate.payload.column.map((item, index) => {
            let matchedItems = newArray.filter((newItem) =>
              newItem.startsWith(item.key + "_")
            );
            let dateGroupValue = matchedItems.map((match) => ({
              value: match,
              label: dateColumn.find(
                (e) => e.value === match.toLowerCase().split("_")[1]
              ).label,
            }));

            return {
              id: index,
              selectedColumns: { value: item.key, label: item.key },
              operationType: {
                value: item.value,
                label: columnOperationSelectOptions.find(
                  (e) => e.value === item.value
                ).label,
              },
              dateGroupValue: dateGroupValue,
            };
          })
        : []
    );

    const groupArray = context.graphicUpdate.payload.group.filter(
      (item) =>
        !substringsToRemove.some((substring) => item.includes(substring))
    );

    setGroupingColumns(
      groupArray.map((item) => ({
        value: item,
        label: item,
      }))
    );

    setOperation({
      conditions: context.graphicUpdate.payload.condition.map((item) => {
        return {
          selectedColumn: { value: item.key, label: item.key },
          selectedOperator: {
            value: item.type,
            label: selectedOperatorOptions.find((e) => e.value === item.type)
              .label,
          },
          conditionDisabled:
            item.operator === "isnull" || item.operator === "isnotnull"
              ? true
              : false,
          selectedCondition: {
            value: item.operator,
            label: selectedConditionOptions.find(
              (e) => e.value === item.operator
            ).label,
          },
          inputDisabled: false,
          inputValue: item.value,
        };
      }),
      operationType: null,
      orderings: [],
    });

    //--

    // if (context.graphicUpdate.chart === 3) {
    //   setWordCloudData(context.graphicUpdate.options.options);
    // } else {
    //   setChartOptions(context.graphicUpdate.options.options);
    // }

    setChartNoteValue(context.graphicUpdate.options.noteValue);
    setUpdateMapLocation(context.graphicUpdate.options.mapLocation);
    setGraphicWidth(context.graphicUpdate.options.width);
    setGraphicHeight(context.graphicUpdate.options.height);

    if (context.graphicUpdate.options.options.legend) {
      setShowLegend(context.graphicUpdate.options.options.legend.show);
      setLegendOrient({
        value: context.graphicUpdate.options.options.legend.orient,
        label: context.graphicUpdate.options.options.legend.orient
          ? legendPositionOrientSelectOptions.find(
              (e) =>
                e.value === context.graphicUpdate.options.options.legend.orient
            )?.label || null
          : null,
      });
      setLegendPosition({
        left: {
          value: context.graphicUpdate.options.options.legend.left,
          label: context.graphicUpdate.options.options.legend.left
            ? legendPositionLeftSelectOptions.find(
                (e) =>
                  e.value === context.graphicUpdate.options.options.legend.left
              ).label || null
            : null,
        },
        top: {
          value: context.graphicUpdate.options.options.legend.top,
          label: context.graphicUpdate.options.options.legend.top
            ? legendPositionTopSelectOptions.find(
                (e) =>
                  e.value === context.graphicUpdate.options.options.legend.top
              ).label || null
            : null,
        },
      });
    }
  }

  useEffect(() => {
    if (!context.graphicUpdate) {
      handleReset();
    }
  }, [context.graphicUpdate]);

  useEffect(() => {
    if (context.graphicUpdate) {
      getUpdateItems();
    } else {
      handleResetPreview();
    }
  }, [context]);

  return (
    context &&
    context.me && (
      <div className="row justify-content-center">
        {!previewShow ? (
          <div className="col-12 col-lg-8">
            <h4>{context?.t?.chart}</h4>

            <Card className="mb-4">
              <CardBody>
                <div className="row g-3">
                  {/* <div className="col-12">
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="create_graphic_status_radio"
                      defaultChecked={status}
                      onChange={(e) => setStatus(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="create_graphic_status_radio"
                    >
                      {status ? "Aktif" : "Pasif"}
                    </label>
                  </div>
                </div> */}

                  <div className="col-12">
                    <div className="row">
                      {context?.me?.role?.id === 1 && (
                        <div className="col-6">
                          <span className="mb-1 d-block">
                            {context?.t?.pageCustomer}
                          </span>
                          {context.allCustomerSelect ? (
                            <Select
                              options={[
                                {
                                  value: null,
                                  label: context?.t?.mine,
                                },
                                ...context.allCustomerSelect,
                              ]}
                              placeholder=""
                              classNamePrefix="react-select"
                              noOptionsMessage={() => context?.t?.no_option}
                              value={pageCustomer}
                              onChange={(e) => handlePageSelect(e)}
                            />
                          ) : (
                            <div className="spinner-border spinner-border-sm text-theme"></div>
                          )}
                        </div>
                      )}

                      <div
                        className={
                          context?.me?.role?.id === 1 ? "col-6" : "col-12"
                        }
                      >
                        <span className="mb-1 d-block">
                          {context?.t?.selectPage}
                        </span>
                        {pages ? (
                          <Select
                            options={pages
                              .filter((page) => {
                                // if (
                                //   !page.name.startsWith("Anket Sayfası") &&
                                //   page.parent_id !== 44 &&
                                //   page.page_type &&
                                //   !page.iframe &&
                                //   !page.url &&
                                //   context?.me?.role?.id === 1
                                // ) {
                                //   return true;
                                // }
                                return (
                                  !page.name.startsWith("Anket Sayfası") &&
                                  page.parent_id !== 44 &&
                                  page.page_type &&
                                  !page.url &&
                                  page.page_type !== "default"
                                );
                              })
                              .sort((a, b) => a.order - b.order)
                              .map((item) => {
                                if (item.children && item.children.length > 0) {
                                  return {
                                    label: item.name,
                                    options: item.children.map((child) => ({
                                      value: child.id,
                                      label: child.name,
                                    })),
                                  };
                                } else {
                                  return {
                                    value: item.id,
                                    label: item.name,
                                  };
                                }
                              })}
                            placeholder=""
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            value={page}
                            onChange={(e) => setPage(e)}
                          />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row">
                      {context?.me?.role?.id === 1 && (
                        <div className="col-6">
                          <span className="mb-1 d-block">
                            {context?.t?.fileCustomer}
                          </span>
                          {context.allCustomerSelect ? (
                            <Select
                              options={[
                                {
                                  value: null,
                                  label: context?.t?.mine,
                                },
                                ...context.allCustomerSelect,
                              ]}
                              placeholder=""
                              classNamePrefix="react-select"
                              noOptionsMessage={() => context?.t?.no_option}
                              value={fileTypesCustomer}
                              onChange={(e) => handleFileTypesCustomer(e)}
                            />
                          ) : (
                            <div className="spinner-border spinner-border-sm text-theme"></div>
                          )}
                        </div>
                      )}

                      <div
                        className={
                          context?.me?.role?.id === 1 ? "col-6" : "col-12"
                        }
                      >
                        <span className="mb-1 d-block">
                          {context?.t?.selectFile}
                        </span>
                        {fileTypes ? (
                          <Select
                            options={fileTypes.map((item) => {
                              return {
                                value: item.type,
                                label: item.type,
                              };
                            })}
                            placeholder=""
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            value={files}
                            onChange={(e) => setFiles(e)}
                          />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <span className="mb-1 d-block">
                      {context?.t?.chartOrder}
                    </span>
                    <input
                      type="number"
                      value={chartOrder}
                      min={1}
                      className="form-control"
                      onChange={(e) => setChartOrder(e.target.value)}
                    />
                  </div>

                  <div className="col-6">
                    <span className="mb-1 d-block">
                      {context?.t?.chartView}
                    </span>
                    <Select
                      options={chartViewSelectOptions}
                      placeholder=""
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      value={chartView}
                      onChange={setChartView}
                    />
                  </div>

                  <div className="col-12">
                    <span className="mb-1 d-block">
                      {context?.t?.chartName}
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      value={chartName}
                      onChange={(e) => setChartName(e.target.value)}
                    />
                  </div>

                  <div className="col-12">
                    <span className="mb-1 d-block">
                      {context?.t?.chartDescription}
                    </span>
                    <textarea
                      className="form-control"
                      value={chartDescription}
                      onChange={(e) => setChartDescription(e.target.value)}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>

            {chartView?.value !== 10 && (
              <>
                <h4>{context?.t?.query}</h4>

                <Card className="mb-4" style={{ zIndex: 1 }}>
                  <CardBody>
                    <div className="row gap-3">
                      <div className="col-12">
                        <button
                          type="button"
                          className="btn btn-outline-theme w-100 mb-3"
                          onClick={handleAddColumn}
                        >
                          {context?.t?.addColumn}
                        </button>

                        {columns && columns.length !== 0 ? (
                          columns.map((column, index) => (
                            <div key={index} className="row g-2 mb-3">
                              <div className="col">
                                {fileColumns ? (
                                  <Select
                                    options={fileColumns.map((item) => {
                                      return {
                                        value: item,
                                        label: item,
                                      };
                                    })}
                                    placeholder={context?.t?.choose_column}
                                    classNamePrefix="react-select"
                                    noOptionsMessage={() =>
                                      context?.t?.no_option
                                    }
                                    value={column.selectedColumns}
                                    onChange={(selected) =>
                                      handleColumnChange(selected, index)
                                    }
                                  />
                                ) : (
                                  <div className="spinner-border spinner-border-sm text-theme"></div>
                                )}
                              </div>

                              <div className="col">
                                <Select
                                  options={columnOperationSelectOptions}
                                  placeholder={context?.t?.selectAction}
                                  classNamePrefix="react-select"
                                  noOptionsMessage={() => context?.t?.no_option}
                                  value={column.operationType}
                                  onChange={(selected) =>
                                    handleOperationTypeChange(selected, index)
                                  }
                                />
                              </div>

                              {/* <div className="col">
                <Select
                  options={[
                    { value: "", label: context?.t?.none },
                    {
                      value:
                        column.selectedColumns.value + "_todate",
                      label: context?.t?.date,
                    },
                    {
                      value:
                        column.selectedColumns.value +
                        "_dayofmonth",
                      label: context?.t?.day,
                    },
                    {
                      value:
                        column.selectedColumns.value + "_tomonth",
                      label: context?.t?.month,
                    },
                    {
                      value:
                        column.selectedColumns.value + "_toyear",
                      label: context?.t?.year,
                    },
                    {
                      value:
                        column.selectedColumns.value + "_tohour",
                      label: context?.t?.hour,
                    },
                    {
                      value:
                        column.selectedColumns.value + "_tominute",
                      label: context?.t?.minute,
                    },
                    {
                      value:
                        column.selectedColumns.value + "_tosecond",
                      label: context?.t?.second,
                    },
                  ]}
                  isMulti
                  placeholder={context?.t?.dateGrouping}
                  classNamePrefix="react-select"
                  noOptionsMessage={() => context?.t?.no_option}
                  value={column.dateGroupValue}
                  onChange={(selected) =>
                    handleDateTypeChange(selected, index)
                  }
                />
              </div> */}

                              <div
                                className="col w-auto"
                                style={{ flex: "none" }}
                              >
                                <button
                                  className="btn border-0 btn-danger h-100"
                                  onClick={() => handleRemoveColumn(column.id)}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>{context?.t?.columnNotFound}</div>
                        )}
                      </div>

                      <div className="col-12">
                        <hr className="my-0" />
                      </div>

                      <div className="col-12">
                        <button
                          type="button"
                          className="btn btn-outline-theme w-100 mb-3"
                          onClick={addCondition}
                        >
                          {context?.t?.add_condition}
                        </button>

                        {operation.conditions.length !== 0 ? (
                          operation.conditions.map(
                            (condition, conditionIndex) => (
                              <div
                                key={conditionIndex}
                                className="row align-items-center g-2 mb-2"
                              >
                                <div
                                  className="col w-auto"
                                  style={{ flex: "none", minWidth: 80 }}
                                >
                                  {conditionIndex + 1}. {context?.t?.condition}:
                                </div>
                                <div className="col">
                                  {fileColumns ? (
                                    <Select
                                      options={fileColumns.map((item) => {
                                        return {
                                          value: item,
                                          label: item,
                                        };
                                      })}
                                      placeholder={context?.t?.choose_column}
                                      classNamePrefix="react-select"
                                      noOptionsMessage={() =>
                                        context?.t?.no_option
                                      }
                                      // isDisabled={condition.conditionDisabled}
                                      value={condition.selectedColumn}
                                      onChange={(selectedOption) =>
                                        handleConditionColumnChange(
                                          conditionIndex,
                                          selectedOption
                                        )
                                      }
                                    />
                                  ) : (
                                    <div className="spinner-border spinner-border-sm text-theme"></div>
                                  )}
                                </div>
                                <div className="col">
                                  <Select
                                    options={selectedConditionOptions}
                                    placeholder={context?.t?.selectOperator}
                                    classNamePrefix="react-select"
                                    noOptionsMessage={() =>
                                      context?.t?.no_option
                                    }
                                    // isDisabled={condition.conditionDisabled}
                                    value={condition.selectedCondition}
                                    onChange={(selectedOption) =>
                                      handleConditionChange(
                                        conditionIndex,
                                        selectedOption
                                      )
                                    }
                                  />
                                </div>
                                <div className="col">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={context?.t?.value}
                                    disabled={condition.inputDisabled}
                                    value={condition.inputValue}
                                    onChange={(event) =>
                                      handleInputChange(conditionIndex, event)
                                    }
                                  />
                                </div>
                                <div className="col">
                                  <Select
                                    options={selectedOperatorOptions}
                                    value={condition.selectedOperator}
                                    placeholder={context?.t?.choose_condition}
                                    classNamePrefix="react-select"
                                    noOptionsMessage={() =>
                                      context?.t?.no_option
                                    }
                                    onChange={(selectedOption) =>
                                      handleOperatorChange(
                                        conditionIndex,
                                        selectedOption
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className="col w-auto"
                                  style={{ flex: "none" }}
                                >
                                  <button
                                    className="btn border-0 btn-danger h-100"
                                    onClick={() =>
                                      removeCondition(conditionIndex)
                                    }
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <div>{context?.t?.conditionNotFound}</div>
                        )}
                      </div>

                      <div className="col-12">
                        <hr className="my-0" />
                      </div>

                      {/* <div className="col-12">
    <button
      type="button"
      className="btn btn-outline-theme w-100 mb-3"
      onClick={handleAddOrder}
    >
      Sıralama Ekle
    </button>

    {operation.orderings.length !== 0 ? (
      operation.orderings.map((order, orderIndex) => (
        <div
          key={orderIndex}
          className="row align-items-center g-2 mb-2"
        >
          <div className="col">
           {fileColumns ? (
              <Select
                options={fileColumns.map((item) => {
                  return {
                    value: item,
                    label: item,
                  };
                })}
                placeholder={context?.t?.choose_column}
                classNamePrefix="react-select"
                noOptionsMessage={() => context?.t?.no_option}
                value={order.column}
                onChange={(selectedOption) =>
                  handleOrderColumnChange(orderIndex, selectedOption)
                }
              />
            ) : (
              <div className="spinner-border spinner-border-sm text-theme"></div>
            )}
          </div>
          <div className="col">
            <Select
              options={[
                { value: "asc", label: "ARTAN" },
                { value: "desc", label: "AZALAN" },
              ]}
              placeholder="Sıra Seç"
              classNamePrefix="react-select"
              noOptionsMessage={() => context?.t?.no_option}
              value={order.order}
              onChange={(selectedOption) =>
                handleOrderChange(orderIndex, selectedOption)
              }
            />
          </div>
          <div className="col w-auto" style={{ flex: "none" }}>
            <button
              className="btn border-0 btn-danger h-100"
              onClick={() => handleRemoveOrder(orderIndex)}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      ))
    ) : (
      <div>Sıralama Bulunamadı</div>
    )}
  </div> */}
                      {/* 
  <div className="col-12">
    <hr className="my-0" />
  </div> */}

                      <div className="col-12">
                        <span className="mb-1 d-block">
                          {context?.t?.group}
                        </span>
                        {fileColumns ? (
                          <Select
                            options={fileColumns.map((item) => {
                              return {
                                value: item,
                                label: item,
                              };
                            })}
                            isMulti
                            placeholder={context?.t?.choose_column}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            value={groupingColumns}
                            onChange={(selectedOptions) => {
                              // checkDateColumnSelected(selectedOptions);
                              handleGroupingChange(selectedOptions);
                            }}
                          />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}

            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-outline-theme w-100"
                  data-bs-dismiss="modal"
                >
                  {context?.t?.cancel}
                </button>
              </div>

              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-theme w-100 mb-3"
                  onClick={!loader ? handleCreateButtonClick : undefined}
                >
                  {context?.t?.preview}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12">
            {chartView?.value !== 10 ? (
              chartView && chartView.value ? (
                <>
                  <hr className="mt-n3" />

                  <div className="row">
                    <div className="col-6">
                      <h4 className="mb-0">{context?.t?.graphic_preview}</h4>
                    </div>

                    <div className="col-6 text-end">
                      <small className="fw-medium">
                        {context?.t?.choose_page}: {page?.label}
                      </small>
                      <br />
                      <small className="fw-medium">
                        {context?.t?.choose_chart}:{" "}
                        {chartViewSelectOptions &&
                          chartViewSelectOptions.find(
                            (e) => e.value === chartView.value
                          ).label}
                      </small>
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    {chartView.value !== 4 && (
                      <>
                        {chartView.value !== 12 &&
                          (chartView?.type === "multiple-bar" ||
                            chartView.value === 9) && (
                            <div className="col">
                              <span className="mb-1 d-block">
                                {chartView?.type === "multiple-bar"
                                  ? "X"
                                  : context?.t?.date}
                              </span>
                              <Select
                                options={
                                  previewData.length !== 0
                                    ? Object.keys(previewData[0]).map(
                                        (key) => ({
                                          value: key,
                                          label: key,
                                        })
                                      )
                                    : []
                                }
                                placeholder={context?.t?.choose_column}
                                classNamePrefix="react-select"
                                noOptionsMessage={() => context?.t?.no_option}
                                value={previewSelectDate}
                                onChange={(e) => {
                                  setPreviewSelectWordCloud(null);
                                  setPreviewSelectDate(e);
                                }}
                              />
                            </div>
                          )}

                        <div className="col">
                          <span className="mb-1 d-block">
                            {chartView?.type === "multiple-bar"
                              ? "Y"
                              : chartView.value === 5
                              ? "Latitude"
                              : context?.t?.value}
                          </span>
                          <Select
                            options={
                              previewData.length !== 0
                                ? Object.keys(previewData[0]).map((key) => ({
                                    value: key,
                                    label: key,
                                  }))
                                : []
                            }
                            placeholder={context?.t?.choose_column}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            value={previewSelectValue}
                            onChange={(e) => {
                              setPreviewSelectWordCloud(null);
                              setPreviewSelectValue(e);
                            }}
                          />
                        </div>

                        <div className="col">
                          <span className="mb-1 d-block">
                            {chartView?.type === "multiple-bar"
                              ? "Y " + context?.t?.integer
                              : chartView.value === 5
                              ? "Longitude"
                              : context?.t?.title}
                          </span>
                          <Select
                            options={
                              previewData.length !== 0
                                ? Object.keys(previewData[0]).map((key) => ({
                                    value: key,
                                    label: key,
                                  }))
                                : []
                            }
                            placeholder={context?.t?.choose_column}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            value={previewSelectLabel}
                            onChange={(e) => {
                              setPreviewSelectWordCloud(null);
                              setPreviewSelectLabel(e);
                            }}
                          />
                        </div>
                      </>
                    )}

                    {chartView.value === 3 && (
                      <div className="col border-start">
                        <span className="mb-1 d-block">
                          {context?.t?.string}
                        </span>
                        <Select
                          options={
                            previewData.length !== 0
                              ? Object.keys(previewData[0]).map((key) => ({
                                  value: key,
                                  label: key,
                                }))
                              : []
                          }
                          placeholder={context?.t?.choose_column}
                          classNamePrefix="react-select"
                          noOptionsMessage={() => context?.t?.no_option}
                          value={previewSelectWordCloud}
                          onChange={(e) => handleSelectWordCloud(e)}
                        />
                      </div>
                    )}
                  </div>

                  <hr />

                  <div className="row">
                    <div className="col-6">
                      <button
                        type="button"
                        className="btn btn-outline-theme w-100"
                        onClick={() => handleResetPreview()}
                      >
                        {context?.t?.prev}
                      </button>
                    </div>

                    <div className="col-6">
                      <button
                        type="button"
                        className="btn btn-theme w-100 mb-3"
                        onClick={() => handlePreview()}
                      >
                        {context?.t?.view}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-theme w-100"
                  onClick={() => handleResetPreview()}
                >
                  {context?.t?.prev}
                </button>
              )
            ) : (
              <button
                type="button"
                className="btn btn-outline-theme w-100"
                onClick={() => handleResetPreview()}
              >
                {context?.t?.prev}
              </button>
            )}

            {previewChartShow && (
              <>
                {chartView && chartView.value && (
                  <>
                    <hr className="mt-0" />

                    <div className="col-12">
                      <div className="row g-3 mb-3">
                        {chartView.value !== 2 &&
                          chartView.value !== 12 &&
                          chartView.value !== 10 &&
                          chartView.value !== 9 &&
                          chartView.value !== 3 &&
                          chartView.value !== 4 &&
                          chartView.value !== 7 &&
                          chartView.value !== 5 && (
                            <>
                              <div className="col">
                                <span>{context?.t?.menu_section}</span>
                                <button
                                  onClick={toggleLegend}
                                  className="btn btn-theme w-100"
                                >
                                  {context?.t?.show_hide}
                                </button>
                              </div>

                              <div className="col">
                                <span>{context?.t?.menu_align}</span>
                                <Select
                                  options={legendPositionOrientSelectOptions}
                                  placeholder=""
                                  classNamePrefix="react-select"
                                  noOptionsMessage={() => context?.t?.no_option}
                                  value={legendOrient}
                                  onChange={(e) => handleOrientChange(e)}
                                />
                              </div>

                              <div className="col">
                                <span>{context?.t?.menu_horizontal}</span>
                                <Select
                                  options={legendPositionLeftSelectOptions}
                                  placeholder=""
                                  classNamePrefix="react-select"
                                  noOptionsMessage={() => context?.t?.no_option}
                                  value={legendPosition.left}
                                  onChange={(e) =>
                                    handlePositionChange("left", e)
                                  }
                                />
                              </div>

                              <div className="col border-end">
                                <span>{context?.t?.menu_vertical}</span>
                                <Select
                                  options={legendPositionTopSelectOptions}
                                  placeholder=""
                                  classNamePrefix="react-select"
                                  noOptionsMessage={() => context?.t?.no_option}
                                  value={legendPosition.top}
                                  onChange={(e) =>
                                    handlePositionChange("top", e)
                                  }
                                />
                              </div>
                            </>
                          )}

                        <div className="col">
                          <span>{context?.t?.width}</span>
                          <Select
                            options={Array.from({ length: 12 }, (_, i) => {
                              const colNumber = 12 - i;
                              return {
                                value: `col-${colNumber}`,
                                label: `${colNumber}/12`,
                              };
                            })}
                            placeholder=""
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            value={graphicWidth}
                            onChange={(e) => setGraphicWidth(e)}
                          />
                        </div>

                        <div className="col">
                          <span>{context?.t?.height}</span>
                          <input
                            type="number"
                            value={graphicHeight}
                            min={1}
                            className="form-control"
                            onChange={(e) => setGraphicHeight(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className={graphicWidth.value}>
                          <Card style={{ height: 100 * graphicHeight }}>
                            <CardBody className="d-flex flex-column overflow-hidden">
                              <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
                                <span
                                  className={`fw-bold h6 mb-0 d-flex align-items-center justify-content-center text-center flex-1`}
                                >
                                  {chartName.toUpperCase()}
                                </span>
                              </div>

                              <div className="position-relative overflow-hidden flex-1">
                                {chartView.value === 12 ? (
                                  <DynamicCountCard
                                    data={previewData}
                                    previewSelectValue={previewSelectValue}
                                    previewSelectLabel={previewSelectLabel}
                                  />
                                ) : chartView.value === 10 ? (
                                  <DynamicNote
                                    value={chartNoteValue}
                                    setValue={setChartNoteValue}
                                  />
                                ) : chartView.value === 5 ? (
                                  <DynamicMap
                                    data={previewData}
                                    selectedColumnLabel={previewSelectLabel}
                                    selectedColumnValue={previewSelectValue}
                                    tableColumn={columns}
                                    location={updateMapLocation}
                                    setMapLocation={setMapLocation}
                                  />
                                ) : chartView.value === 4 ? (
                                  <DynamicTable
                                    data={data}
                                    tableColumn={columns}
                                  />
                                ) : chartView.value === 3 ? (
                                  <WordCloud data={wordCloudData} />
                                ) : (
                                  <ReactECharts
                                    option={chartOptions}
                                    className="h-100"
                                  />
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <hr />

                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-theme w-100 mb-3"
                    onClick={() => handleGraphicPost()}
                  >
                    {context.graphicUpdate
                      ? context?.t?.update_chart
                      : context?.t?.added_chart}
                  </button>
                </div>
              </>
            )}
          </div>
        )}

        {loader && (
          <div
            className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
            style={{ background: "rgba(29, 40, 53, 0.95)", zIndex: 99 }}
          >
            <div className="spinner-border text-theme"></div>
          </div>
        )}
      </div>
    )
  );
}
