import React, { useContext, useEffect, useState } from "react";

import { CardExpandToggler } from "../../../components/card/card.jsx";
import apiClient from "../../../ApiClient.js";
import Select from "react-select";
import { AppSettings } from "../../../config/app-settings.js";
import { ToastContainer } from "react-toastify";

function SecimAnaliziTable2({
  show = true,
  cityFilter,
  districtFilter,
  neighbourhoodFilter,
  streetFilter,
  setSecimAnaliziSandikNoModalData,
  setStreetChestData = false,
}) {
  const context = useContext(AppSettings);

  const [currentPage] = useState(1);
  const [table2Data, setTable2Data] = useState(false);
  const [sandikNoData, setSandikNoData] = useState(null);

  async function fetchSandikNo() {
    setSandikNoData(null);

    if (cityFilter && districtFilter && neighbourhoodFilter) {
      const postOptions = {
        chart: 1,
        page_id: 23,
        title: "-",
        type: "secim",
        description: "",
        customer: null,
        status: true,
        order: 1,
        sql: {
          column: [
            {
              key: "Alt-Kategori-3",
              value: "select",
            },
          ],
          condition: [
            cityFilter
              ? {
                  key: "Sehir",
                  value: context
                    .removeTurkishCharacters(cityFilter.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                }
              : null,
            districtFilter
              ? {
                  key: "Ilce",
                  value: districtFilter.label.toUpperCase(),
                  operator: "=",
                  type: "and",
                }
              : null,
            neighbourhoodFilter
              ? {
                  key: "Mahalle",
                  value: neighbourhoodFilter.label.toUpperCase(),
                  operator: "=",
                  type: "and",
                }
              : null,
            {
              key: "Alt-Kategori-2",
              value: "2024 BELEDİYE BAŞKANLIĞI YEREL SEÇİMLERİ",
              operator: "=",
              type: "and",
            },
          ].filter((item) => item !== null),
          group: [],
        },
      };

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          postOptions,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.length !== 0) {
          setSandikNoData(response);
        } else {
          setSandikNoData([]);
        }
      } catch (error) {
        console.error("Genel Data Error", error);
        setSandikNoData(null);
      }
    } else {
      setSandikNoData(null);
    }
  }

  async function getTable2Data() {
    setTable2Data(null);

    const postOptions = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: "2024-Belediye-Yerel-Secim",
      description: "",
      customer: null,
      status: true,
      order: 1,
      sql: {
        column: [
          {
            key: "Alt-Kategori-3",
            value: "select",
          },
          {
            key: "Parametre",
            value: "select",
          },
          {
            key: "Deger",
            value: "select",
          },
        ],
        condition: [
          cityFilter
            ? {
                key: "Sehir",
                value: context
                  .removeTurkishCharacters(cityFilter.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          districtFilter
            ? {
                key: "Ilce",
                value: districtFilter.label.toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          neighbourhoodFilter
            ? {
                key: "Mahalle",
                value: neighbourhoodFilter.label.toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    // ?page=${currentPage}
    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        postOptions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // function compareByChest(a, b) {
      //   return a.Alt_Kategori_2 - b.Alt_Kategori_2;
      // }

      // response.data.sort(compareByChest);

      // if (setStreetChestData) {
      //   if (response.length !== 0) {
      //     const reportTableWidth = ["*", "*", "*", "*", "*"];
      //     const reportTableValue = [
      //       [
      //         context?.t?.district,
      //         context?.t?.neighborhood,
      //         context?.t?.street,
      //         context?.t?.sandik_no,
      //         context?.t?.secmen_sayisi,
      //       ],
      //     ];
      //     response.forEach((element) => {
      //       reportTableValue.push([
      //         element.ilce,
      //         element.mahalle,
      //         element.cadde_sokak,
      //         element.alt_kategori_2,
      //         element.deger,
      //       ]);
      //     });
      //     setStreetChestData([reportTableWidth, reportTableValue]);
      //   } else {
      //     setStreetChestData([[], [], []]);
      //   }
      // }

      console.log(response);

      if (response.length !== 0) {
        setTable2Data({ data: response });
      } else {
        setTable2Data([]);
      }
    } catch (error) {
      console.error("Genel Data Error", error);
      setTable2Data(null);
    }
  }

  useEffect(() => {
    if (show) {
      fetchSandikNo();
      getTable2Data();
    }
    // eslint-disable-next-line
  }, [currentPage, cityFilter, districtFilter, neighbourhoodFilter, show]);

  // Pagination
  // function handlePagination(value) {
  //   setCurrentPage(value);
  // }

  // --

  return (
    <>
      {show && (
        <div className="d-flex flex-column h-100">
          <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
            <span className="fw-bold h6 mb-0 d-flex align-items-center">
              {context?.t?.sokaga_gore_sandik_basina_secmen_sayisi}
            </span>

            <div className="d-flex align-items-center">
              <CardExpandToggler />
            </div>
          </div>

          <div className="dataTables_wrapper dt-bootstrap5 d-flex flex-column flex-1 overflow-hidden">
            {cityFilter && districtFilter && neighbourhoodFilter ? (
              table2Data ? (
                table2Data.data.length !== 0 ? (
                  <>
                    <div className="w-100 mb-2" style={{ maxWidth: 300 }}>
                      <span className="d-block">{context?.t?.sandik_no}</span>
                      {sandikNoData ? (
                        <Select
                          options={sandikNoData.map((item) => {
                            return {
                              value: item["alt-kategori-3"],
                              label: item["alt-kategori-3"],
                            };
                          })}
                          classNamePrefix="react-select"
                          placeholder={""}
                          noOptionsMessage={() => context?.t?.no_option}
                        />
                      ) : (
                        <div className="spinner-border text-theme"></div>
                      )}
                    </div>

                    <div className="dataTables_scroll flex-1 overflow-auto">
                      <div className="dataTables_scrollBody table-responsive">
                        <table className="table text-nowrap w-100 dataTable">
                          <thead>
                            <tr>
                              <th className="px-2" style={{ minWidth: 160 }}>
                                {context?.t?.siyaset_parti}
                              </th>
                              {/* <th style={{ minWidth: 160 }}>
                                {context?.t?.district}
                              </th>
                              <th style={{ minWidth: 160 }}>
                                {context?.t?.neighborhood}
                              </th>
                              <th style={{ minWidth: 160 }}>
                                {context?.t?.street}
                              </th>
                            */}
                              <th className="px-2" style={{ minWidth: 160 }}>
                                {context?.t?.secmen_sayisi}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {table2Data.data.map((item, index) => (
                              <tr key={index}>
                                {/* <td>{item?.ilce || context?.t?.unknown}</td>
                                <td>{item?.mahalle || context?.t?.unknown}</td>
                                <td>
                                  {item?.cadde_sokak || context?.t?.unknown}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-outline-theme"
                                    data-bs-toggle="modal"
                                    data-bs-target="#secimAnaliziSandikNoModal"
                                    onClick={() =>
                                      setSecimAnaliziSandikNoModalData &&
                                      setSecimAnaliziSandikNoModalData(item)
                                    }
                                  >
                                    {item?.alt_kategori_2 ||
                                      context?.t?.unknown}
                                  </button>
                                </td> */}
                                <td>
                                  {item?.parametre || context?.t?.unknown}
                                </td>
                                {/* <td>
                                  {item["alt-kategori-3"] ||
                                    context?.t?.unknown}
                                </td> */}
                                <td>{item?.deger || context?.t?.unknown}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {/* <div className="row align-items-center my-3">
                      <div className="mb-0 col-md-12">
                        <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                          <ul className="pagination d-flex flex-wrap">
                            <li
                              className={`paginate_button page-item ${
                                !table2Data.prev_page_url && "disabled"
                              }`}
                            >
                              <div
                                onClick={() =>
                                  table2Data.prev_page_url &&
                                  handlePagination(
                                    table2Data.prev_page_url.split("?page=")[1]
                                  )
                                }
                                className="page-link"
                              >
                                {context?.t?.prev}
                              </div>
                            </li>

                            {table2Data.links.map(
                              (item, index) =>
                                !isNaN(parseInt(item.label)) && (
                                  <li
                                    key={index}
                                    className={`paginate_button page-item ${
                                      item.active ? "active" : ""
                                    }`}
                                  >
                                    <div
                                      onClick={() =>
                                        handlePagination(item.label)
                                      }
                                      className="page-link"
                                    >
                                      {item.label}
                                    </div>
                                  </li>
                                )
                            )}

                            <li
                              className={`paginate_button page-item ${
                                !table2Data.next_page_url && "disabled"
                              }`}
                            >
                              <div
                                onClick={() =>
                                  table2Data.next_page_url &&
                                  handlePagination(
                                    table2Data.next_page_url.split("?page=")[1]
                                  )
                                }
                                className="page-link"
                              >
                                {context?.t?.next}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </>
                ) : (
                  <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    {context?.t?.no_data_available}
                  </div>
                )
              ) : (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                  <div className="spinner-border text-theme"></div>
                </div>
              )
            ) : (
              <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                {context?.t?.il_ve_ilce_ve_mahalle_secmelisiniz}
              </div>
            )}
          </div>
        </div>
      )}

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
}

export default SecimAnaliziTable2;
