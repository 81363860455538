import React from "react";

export default function PageIframe({ reportID, apiKey }) {
  return (
    <iframe
      src={`https://panel.kentmetre.com/reportsiframe?id=${reportID}&api_key=${apiKey}`}
      className="w-100 mb-4"
      style={{ minHeight: "100vh", background: "black" }}
    ></iframe>
  );
}
