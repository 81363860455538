import { useEffect, useState } from "react";

import { stats } from "./stats.jsx";
import { Card, CardBody, CardExpandToggler } from "../../components/card/card";
import PageChartCard from "../../components/page-chart-card/pageChartCard";
import ChartPie from "../../components/echart/ChartPie.jsx";
import EChartExample11 from "../../components/echart/echart11.jsx";
import EChartExample12 from "../../components/echart/echart12.jsx";
import CardBinaHane from "../../components/info-cards/CardBinaHane.jsx";
import CardMedeniDurum from "../../components/info-cards/CardMedeniDurum.jsx";
import CardOlumSayisi from "../../components/info-cards/CardOlumSayisi.jsx";
import CardHemsehrilikGoc from "../../components/info-cards/CardHemsehrilikGoc.jsx";
import CardSosyoekonomik from "../../components/info-cards/CardSosyoekonomik.jsx";
import CardVatandasSayisi from "../../components/info-cards/CardVatandasSayisi.jsx";
import PageFilter from "../../components/PageFilter.jsx";

export default function TalepAnalizi() {
  // Filter
  const [cityFilter, setCityFilter] = useState({
    value: 40,
    label: "ISTANBUL",
  });
  const [districtFilter, setDistrictFilter] = useState(null);
  const [neighbourhoodFilter, setNeighbourhoodFilter] = useState(null);
  const [streetFilter, setStreetFilter] = useState(null);

  function createGraphic() {
    localStorage.setItem("create-graphic-page", "talep-analizi");
  }

  // Stats Data
  const [statsData, setStatsData] = useState();
  const [chartOptions, setChartOptions] = useState(getChartOptions());

  function getChartOptions() {
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();

    return {
      bar: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      line: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        stroke: { curve: "straight", width: 2 },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      pie: {
        colors: [
          "rgba(" + themeColorRgb + ", 1)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .5)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      donut: {
        colors: [
          "rgba(" + themeColorRgb + ", .15)",
          "rgba(" + themeColorRgb + ", .35)",
          "rgba(" + themeColorRgb + ", .55)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .95)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
    };
  }

  useEffect(() => {
    setStatsData(stats);

    document.addEventListener("theme-reload", () => {
      setChartOptions(getChartOptions());
    });

    // eslint-disable-next-line
  }, []);

  // Chart Info
  const [data, setData] = useState(false);

  return (
    <div className="row gap-4">
      <div className="row">
        <div className="col">
          <PageFilter
            setCityFilter={setCityFilter}
            setDistrictFilter={setDistrictFilter}
            setNeighbourhoodFilter={setNeighbourhoodFilter}
            setStreetFilter={setStreetFilter}
          />
        </div>

        <div className="col w-auto border-start" style={{ flex: "none" }}>
          {/* <button
            type="button"
            className="btn btn-outline-theme w-100"
            data-bs-toggle="modal"
            data-bs-target="#createGraphicModal"
            onClick={() => createGraphic()}
          >
            Grafik Oluştur
          </button> */}
        </div>
      </div>

      <div className="col-12">
        <div className="row g-4">
          <CardBinaHane
            path="choice-analysis"
            city={cityFilter?.value}
            district={districtFilter?.value}
            neighbourhood={neighbourhoodFilter?.value}
            graphic={1}
          />

          <CardMedeniDurum
            title="SOKAK BINA VE HANE ANALIZLERI"
            path="choice-analysis"
            city={cityFilter?.value}
            district={districtFilter?.value}
            neighbourhood={neighbourhoodFilter?.value}
            graphic={2}
            type={2}
          />

          <CardVatandasSayisi
            city={cityFilter?.value}
            district={districtFilter?.value}
            neighbourhood={neighbourhoodFilter?.value}
            graphic={3}
          />

          <CardOlumSayisi
            path="choice-analysis"
            city={cityFilter?.value}
            district={districtFilter?.value}
            neighbourhood={neighbourhoodFilter?.value}
            graphic={4}
            type={2}
          />

          <CardHemsehrilikGoc
            path="choice-analysis"
            city={cityFilter?.value}
            district={districtFilter?.value}
            neighbourhood={neighbourhoodFilter?.value}
            graphic={5}
          />

          <CardSosyoekonomik
            title="Sosyoekonomik Statü Dağılımı"
            city={cityFilter?.value}
            district={districtFilter?.value}
            neighbourhood={neighbourhoodFilter?.value}
            graphic={6}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="row g-4">
          <div className="col-12 col-lg-6">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
                  <span className="fw-bold h6 mb-0 d-flex align-items-center flex-1 justify-content-center ps-2">
                    {"Talep Faktör Analizi".toUpperCase()}
                  </span>

                  <div className="d-flex align-items-center">
                    <CardExpandToggler />
                  </div>
                </div>

                <div className="">
                  <EChartExample11 setData={setData} />
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-12 col-lg-6">
            <Card>
              <CardBody>
                <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
                  <span className="fw-bold h6 mb-0 d-flex align-items-center flex-1 justify-content-center ps-2">
                    {"Hizmet Faktör Analizi".toUpperCase()}
                  </span>

                  <div className="d-flex align-items-center">
                    <CardExpandToggler />
                  </div>
                </div>

                <div className="">
                  <EChartExample11 setData={setData} />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      {data && (
        <div className="col-12">
          <div className="row g-4">
            <div className="col-12 col-lg-4">
              <ChartPie
                title="İletilen Şikayetlere Göre Hizmet Konuları"
                city={40}
                district={451}
                graphic={10}
                card={true}
              />
            </div>

            <div className="col-12 col-lg-4">
              <PageChartCard
                id="5"
                title="Şikayetlerde En Çok Kullanılan Kelimeler"
                chart={
                  <EChartExample12
                    data={[
                      [
                        {
                          Parametre: "Temizlik",
                          Yuzde: 8.33,
                        },
                        {
                          Parametre: "Çöp",
                          Yuzde: 8.33,
                        },
                        {
                          Parametre: "Evde Bakım",
                          Yuzde: 4.17,
                        },
                        {
                          Parametre: "Kaldırım",
                          Yuzde: 20.83,
                        },
                        {
                          Parametre: "Zabıta",
                          Yuzde: 16.67,
                        },
                        {
                          Parametre: "Seyyar",
                          Yuzde: 16.67,
                        },
                        {
                          Parametre: "Kötü Koku",
                          Yuzde: 20.83,
                        },
                        {
                          Parametre: "Çöp Konteyneri",
                          Yuzde: 4.17,
                        },
                      ],
                    ]}
                  />
                }
              />
            </div>

            <div className="col-12 col-lg-4">
              <ChartPie
                title="İletilen Şikayetlere Göre Şikayet Konuları"
                city={40}
                district={451}
                graphic={10}
                card={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
