import React, { useContext, useEffect, useState } from "react";

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  MultiGrid,
} from "react-virtualized";
import Select from "react-select";
import Immutable from "immutable";

import apiClient from "../../../ApiClient";
import toastMessage from "../../../components/ToastMessage";
import { Card, CardBody } from "../../../components/card/card";
import { AppSettings } from "../../../config/app-settings";
import PageFilter from "../../../components/PageFilter";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CardBinaHane from "../../../components/info-cards/CardBinaHane";
import CardMedeniDurum from "../../../components/info-cards/CardMedeniDurum";
import CardVatandasSayisi from "../../../components/info-cards/CardVatandasSayisi";
import CardOlumSayisi from "../../../components/info-cards/CardOlumSayisi";
import CardHemsehrilikGoc from "../../../components/info-cards/CardHemsehrilikGoc";
import CardSosyoekonomik from "../../../components/info-cards/CardSosyoekonomik";
import { Swiper, SwiperSlide } from "swiper/react";
import Fancybox from "../../../components/Fancybox";

function errorMessages(error) {
  if (error?.response) {
    if (error.response.status === 422) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }

    if (error.response.status === 500) {
      console.error("Sunucu Hatası!");
    }

    if (error.response.status === 413) {
      toastMessage("Bir süre sonra tekrar deneyin.");
    }
  } else {
    toastMessage("Tekrar deneyin.");
  }
}

const getClassName = ({ rowIndex }) => {
  return rowIndex % 2 === 0 ? "evenColumn" : "oddColumn";
};

const HaneAnalizi = () => {
  const context = useContext(AppSettings);

  const [haneColumns, setHaneColumns] = useState(null);
  const [columnConfig, setColumnConfig] = useState([
    { key: "detail", label: context?.t?.transaction, width: 100 },
    { key: "id", label: "#", width: 50 },
    // Diğer sabit kolonlar burada yer alabilir
  ]);

  const [detailColumns, setDetailColumns] = useState(null);
  const [dataLoader, setDataLoader] = useState(false);
  const [dataPagination, setDataPaginaiton] = useState(Immutable.List());
  const [data, setData] = useState(Immutable.List());
  const [detailData, setDetailData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [otherCurrentPage, setOtherCurrentPage] = useState(1);
  const cache = React.useRef(
    new CellMeasurerCache({
      defaultWidth: 150,
      fixedWidth: true,
    })
  ).current;

  const [familyID, setFamilyID] = useState(null);
  const [mahalleSonucuData, setMahalleSonucuData] = useState(null);
  const [memleketSonucuData, setMemleketSonucuData] = useState(null);
  const [sandıkSonucuData, setSandıkSonucuData] = useState(null);

  const [resetActive, setResetActive] = useState(false);
  const [searchColumn, setSearchColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCondition, setSearchCondition] = useState(null);

  const [resetOtherActive, setResetOtherActive] = useState(false);
  const [searchOtherTerm, setSearchOtherTerm] = useState("");
  const [detailSearchData, setDetailSearchData] = useState("");

  const [hizmetSikayet, setHizmetSikayet] = useState(null);

  const [hoveredDetailTableRow, setHoveredDetailTableRow] = useState(null);
  const [hoveredNameTableRow, setHoveredNameTableRow] = useState(null);
  const [hoveredHizmetlerTableRow, setHoveredHizmetlerTableRow] =
    useState(null);
  const [hoveredSikayetlerTableRow, setHoveredSikayetlerTableRow] =
    useState(null);

  // Filter
  const [cityFilter, setCityFilter] = useState(null);
  const [districtFilter, setDistrictFilter] = useState("filter");
  const [neighbourhoodFilter, setNeighbourhoodFilter] = useState(null);
  const [streetFilter, setStreetFilter] = useState(null);

  // Get BirthDay
  const [months, setMonths] = useState([
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ]);

  useEffect(() => {
    setColumnConfig([
      { key: "detail", label: context?.t?.transaction, width: 100 },
      { key: "id", label: "#", width: 50 },
      // Diğer sabit kolonlar burada yer alabilir
    ]);

    setMonths([
      context?.t?.ocak,
      context?.t?.subat,
      context?.t?.mart,
      context?.t?.nisan,
      context?.t?.mayis,
      context?.t?.haziran,
      context?.t?.temmuz,
      context?.t?.agustos,
      context?.t?.eylul,
      context?.t?.ekim,
      context?.t?.kasim,
      context?.t?.aralik,
    ]);
  }, [context, context?.t]);

  function formatDate(dateStr) {
    const [year, month, day] = dateStr.split("-").map(Number);
    return `${day} ${months[month - 1]} ${year}`;
  }

  // Get File Columns
  async function getFileColumns(name) {
    setHaneColumns(null);
    setDetailColumns(null);

    try {
      const response = await apiClient.get(`/columns/${name}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response) {
        if (name === `hane-data-customer-${context?.me?.customer?.id}`) {
          // setHaneColumns(
          //   response.data.columns.filter(
          //     (item) =>
          //       item === "Adres Il" ||
          //       item === "Adres Ilcesi" ||
          //       item === "Adres Mahallesi" ||
          //       item === "Adres Sokagi" ||
          //       item === "Bina NO" ||
          //       item === "Daire NO" ||
          //       item === "Ailedeki Seçmen Sayısı" ||
          //       item === "(0-4) Yas Arasi" ||
          //       item === "(5-6) Yas Arasi" ||
          //       item === "(5-9) Yas Arasi" ||
          //       item === "(10-14) Yas Arasi" ||
          //       item === "(15-19) Yas Arasi" ||
          //       item === "(18-24) Yas Arasi" ||
          //       item === "(20-24) Yas Arasi" ||
          //       item === "(25-29) Yas Arasi" ||
          //       item === "(30-34) Yas Arasi" ||
          //       item === "(35-39) Yas Arasi" ||
          //       item === "(40-44) Yas Arasi" ||
          //       item === "(45-49) Yas Arasi" ||
          //       item === "(50-54) Yas Arasi" ||
          //       item === "(55-59) Yas Arasi" ||
          //       item === "(60-64) Yas Arasi" ||
          //       item === "(+65) Yas" ||
          //       item === "Bebek Sayısı(0-3)" ||
          //       item === "Çocuk Sayısı(4-6)" ||
          //       item === "Çocuk Öğrenci Sayısı(6-14)" ||
          //       item === "Genç Çocuk Sayısı(15-17)" ||
          //       item === "Ilk Defa Oy Kullanacak Secmen Sayisi(17)" ||
          //       item === "Genç Seçmen Sayısı(18-29)" ||
          //       item === "Hanedeki Secmen Sayisi"
          //   )
          // );
          setHaneColumns(response.data.columns);
        }

        if (name === `bireysel-data-customer-${context?.me?.customer?.id}`) {
          setDetailColumns(
            response.data.columns.filter(
              (item) =>
                item === "Adres Il" ||
                item === "Adres Ilce" ||
                item === "Adres Mahalle" ||
                item === "Adres Sokak" ||
                item === "Bina NO" ||
                item === "Daire" ||
                item === "Secmen Profili" ||
                item === "Yaş Grubu" ||
                item === "Dogum Tarihi" ||
                item === "TC" ||
                item === "Ad Soyad" ||
                item === "Telefon NO" ||
                item === "Siyasal Kimlik 1" ||
                item === "Siyasal Kimlik 2" ||
                item === "Sandığa Gitme Ihtimali" ||
                item === "Sandığa Gitmeme Ihtimali" ||
                item === "Sandık Sonucu 1" ||
                item === "Sandık Sonucu 2" ||
                item === "Sandık Sonucu 3" ||
                item === "Mahalle Sonucu 1" ||
                item === "Mahalle Sonucu 2" ||
                item === "Mahalle Sonucu 3" ||
                item === "Memleket Sonucu 1" ||
                item === "Memleket Sonucu 2" ||
                item === "Memleket Sonucu 3"
            )
          );
        }
      }
    } catch (error) {
      setHaneColumns(null);
      setDetailColumns(null);
      errorMessages(error);
      console.error("Get File Types Error", error);
    }
  }

  useEffect(() => {
    if (context && context?.me) {
      getFileColumns(`hane-data-customer-${context?.me?.customer?.id}`);
      getFileColumns(`bireysel-data-customer-${context?.me?.customer?.id}`);
    }
  }, [context]);

  useEffect(() => {
    if (haneColumns) {
      const dynamicColumns = haneColumns.map((col) => ({
        key: col.toLowerCase().replace(/ /g, "_"),
        label: col,
        width:
          col === "Bina NO" || col === "Daire NO"
            ? 100
            : col === "Ilk Defa Oy Kullanacak Secmen Sayisi(17)"
            ? 250
            : 170,
      }));
      setColumnConfig([
        ...[
          { key: "detail", label: context?.t?.transaction, width: 100 },
          { key: "id", label: "#", width: 50 },
        ],
        ...dynamicColumns,
      ]);
    }
  }, [haneColumns]);

  // Get First Data
  async function fetchHaneData(page, saerch, condition) {
    if (cityFilter && districtFilter && districtFilter !== "filter") {
      setDataLoader(false);

      const dataColumn = haneColumns.map((item) => ({
        key: item,
        value: "select",
      }));

      const conditionData = [
        condition.city
          ? {
              key: "Adres Il",
              value: condition.city,
              operator: "=",
              type: "and",
            }
          : null,
        condition.district
          ? {
              key: "Adres Ilcesi",
              value: condition.district,
              operator: "=",
              type: "and",
            }
          : null,
        condition.neighbourhood
          ? {
              key: "Adres Mahallesi",
              value: condition.neighbourhood,
              operator: "=",
              type: "and",
            }
          : null,
        condition.street
          ? {
              key: "Adres Sokagi",
              value: condition.street,
              operator: "=",
              type: "and",
            }
          : null,
        condition.bina
          ? {
              key: "Bina NO",
              value: condition.bina,
              operator: "=",
              type: "and",
            }
          : null,
        condition.daire
          ? {
              key: "Daire NO",
              value: condition.daire,
              operator: "=",
              type: "and",
            }
          : null,
        saerch,
      ];

      const conditionDataFiltered = conditionData.filter(
        (item) => item !== null
      );

      try {
        const response = await apiClient.customPost(
          `/query/preview?page=${page}`,
          {
            chart: 1,
            page_id: 24,
            title: "Hane ve Seçmen Analizi",
            description: "",
            paginate: true,
            type: `hane-data-customer-${context?.me?.customer?.id}`,
            status: true,
            customer: null,
            order: 1,
            sql: {
              column: dataColumn,
              condition: conditionDataFiltered,
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          const immutableData = Immutable.List(response.data);

          setDataLoader(true);
          setDataPaginaiton(response);
          setData(immutableData);
        }
      } catch (error) {
        console.error(error);
        errorMessages(error);
      }
    }
  }

  useEffect(() => {
    if (haneColumns) {
      fetchHaneData(currentPage, searchCondition, {
        city: cityFilter?.label,
        district: districtFilter?.label,
        neighbourhood: neighbourhoodFilter?.label,
        street: streetFilter?.label,
        bina: null,
        daire: null,
      });
    }
  }, [
    haneColumns,
    cityFilter,
    districtFilter,
    neighbourhoodFilter,
    streetFilter,
  ]);

  // Get Detail Data
  const getDetailData = async (datum) => {
    const dataColumn = detailColumns.map((item) => ({
      key: item,
      value: "select",
    }));

    setFamilyID(null);
    setMahalleSonucuData(null);
    setMemleketSonucuData(null);
    setSandıkSonucuData(null);

    const conditionData = [
      {
        key: "Adres Il",
        value: datum["adres il"],
        operator: "=",
        type: "and",
      },
      {
        key: "Adres Ilce",
        value: datum.hasOwnProperty("tc")
          ? datum["adres ilce"]
          : datum["adres ilcesi"],
        operator: "=",
        type: "and",
      },
      {
        key: "Adres Mahalle",
        value: datum.hasOwnProperty("tc")
          ? datum["adres mahalle"]
          : datum["adres mahallesi"],
        operator: "=",
        type: "and",
      },
      {
        key: "Adres Sokak",
        value: datum.hasOwnProperty("tc")
          ? datum["adres sokak"]
          : datum["adres sokagi"],
        operator: "=",
        type: "and",
      },
      {
        key: "Bina NO",
        value: datum["bina no"],
        operator: "=",
        type: "and",
      },
      {
        key: "Daire",
        value: datum.hasOwnProperty("tc") ? datum["daire"] : datum["daire no"],
        operator: "=",
        type: "and",
      },
    ];
    const conditionFilteredData = conditionData.filter((item) => item.value);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 24,
          title: "Hane ve Seçmen Analizi Detay",
          type: `bireysel-data-customer-${context?.me?.customer?.id}`,
          description: "",
          status: true,
          customer: null,
          order: 1,
          sql: {
            column: dataColumn,
            condition: conditionFilteredData,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      let Sandik_Sonucu_Array = [];
      let Memleket_Sonucu_Array = [];
      let Mahalle_Sonucu_Array = [];
      let siyasalKimlikArray = [];

      if (response.length !== 0) {
        response.forEach((item) => {
          Mahalle_Sonucu_Array.push(
            item["mahalle sonucu 1"] || context?.t?.unknown
          );
          Mahalle_Sonucu_Array.push(
            item["mahalle sonucu 2"] || context?.t?.unknown
          );
          Mahalle_Sonucu_Array.push(
            item["mahalle sonucu 3"] || context?.t?.unknown
          );

          //--

          Memleket_Sonucu_Array.push(
            item["memleket sonucu 1"] || context?.t?.unknown
          );
          Memleket_Sonucu_Array.push(
            item["memleket sonucu 2"] || context?.t?.unknown
          );
          Memleket_Sonucu_Array.push(
            item["memleket sonucu 3"] || context?.t?.unknown
          );

          //--

          Sandik_Sonucu_Array.push(
            item["sandık sonucu 1"] || context?.t?.unknown
          );
          Sandik_Sonucu_Array.push(
            item["sandık sonucu 2"] || context?.t?.unknown
          );
          Sandik_Sonucu_Array.push(
            item["sandık sonucu 3"] || context?.t?.unknown
          );

          //--

          siyasalKimlikArray.push(
            item["siyasal kimlik 1"] || context?.t?.unknown
          );
          siyasalKimlikArray.push(
            item["siyasal kimlik 2"] || context?.t?.unknown
          );
        });

        setFamilyID([...new Set(siyasalKimlikArray)]);
        setMahalleSonucuData([...new Set(Mahalle_Sonucu_Array)]);
        setMemleketSonucuData([...new Set(Memleket_Sonucu_Array)]);
        setSandıkSonucuData([...new Set(Sandik_Sonucu_Array)]);

        setDetailData(response);
      } else {
        setFamilyID([]);
        setMahalleSonucuData([]);
        setMemleketSonucuData([]);
        setSandıkSonucuData([]);

        setDetailData([
          {
            "adres il": datum["adres il"],
            "adres ilce": datum["adres ilcesi"],
            "adres mahalle": datum["adres mahallesi"],
            "adres sokak": datum["adres sokagi"],
            "bina no": datum["bina no"],
            daire: datum["daire no"],
          },
        ]);
      }

      handleAkraba(response);

      handleHizmetSikayetler(response);
    } catch (error) {
      console.error(error);
      errorMessages(error);
    }
  };

  // Get Detail Search Name
  const getDetailSearch = async (page, search) => {
    setDetailSearchData(null);

    const conditionData = [
      {
        key: "Adres Il",
        value: cityFilter.label,
        operator: "=",
        type: "and",
      },
      {
        key: "Adres Ilce",
        value: districtFilter.label,
        operator: "=",
        type: "and",
      },
      neighbourhoodFilter
        ? {
            key: "Adres Mahalle",
            value: neighbourhoodFilter.label,
            operator: "=",
            type: "and",
          }
        : null,
      streetFilter
        ? {
            key: "Adres Sokak",
            value: streetFilter.label,
            operator: "=",
            type: "and",
          }
        : null,
      search && search.length !== 0
        ? {
            key: "Ad Soyad",
            value: search.toUpperCase(),
            operator: "like",
            type: "and",
          }
        : null,
    ].filter((item) => item !== null);

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${page}`,
        {
          chart: 1,
          page_id: 24,
          title: "Hane ve Seçmen Analizi Detay",
          type: `bireysel-data-customer-${context?.me?.customer?.id}`,
          description: "",
          paginate: true,
          status: true,
          customer: null,
          order: 1,
          sql: {
            column: [
              {
                key: "Adres Il",
                value: "select",
              },
              {
                key: "Adres Ilce",
                value: "select",
              },
              {
                key: "Adres Mahalle",
                value: "select",
              },
              {
                key: "Adres Sokak",
                value: "select",
              },
              {
                key: "Bina NO",
                value: "select",
              },
              {
                key: "Daire",
                value: "select",
              },
              {
                key: "TC",
                value: "select",
              },
              {
                key: "Ad Soyad",
                value: "select",
              },
            ],
            condition: conditionData,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.length !== 0) {
        setDetailSearchData(response);
      } else {
        setDetailSearchData([]);
      }
    } catch (error) {
      console.error(error);
      setDetailSearchData(null);
      errorMessages(error);
    }
  };

  // Table
  const cellRenderer = ({ columnIndex, key, parent, rowIndex, style }) => {
    let content;

    const item = dataPagination.data[rowIndex - 1];
    const classNames =
      rowIndex === 0 ? "headerCell" : getClassName({ rowIndex });

    if (rowIndex === 0) {
      content = columnConfig[columnIndex].label;
    } else {
      if (columnIndex === 0) {
        content = detailColumns ? (
          <button
            type="button"
            className="btn w-100 d-block btn-outline-theme btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#haneDetailsModal"
            onClick={() => getDetailData(item)}
          >
            {context?.t?.detail}
          </button>
        ) : (
          <div className="spinner-border text-theme"></div>
        );
      } else if (columnIndex === 1) {
        content = rowIndex;
      } else {
        content = item
          ? item[columnConfig[columnIndex].label.toLowerCase()] || "-"
          : "";
      }
    }

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div
          className={classNames}
          style={{ ...style, width: columnConfig[columnIndex].width }}
        >
          {content}
        </div>
      </CellMeasurer>
    );
  };

  const getColumnWidth = ({ index }) => columnConfig[index].width;

  // Filter Pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchHaneData(page, searchCondition, {
      city: cityFilter?.label,
      district: districtFilter?.label,
      neighbourhood: neighbourhoodFilter?.label,
      street: streetFilter?.label,
      bina: null,
      daire: null,
    });
  };

  const handleOtherPageChange = (page) => {
    setOtherCurrentPage(page);
    getDetailSearch(page, searchOtherTerm);
  };

  // Filter Search
  const handleSearch = () => {
    if (searchColumn && searchTerm !== "") {
      setResetActive(true);

      const searchData = {
        key: searchColumn.label,
        value: searchTerm.toUpperCase(),
        operator: "like",
        type: "and",
      };

      setSearchCondition(searchData);
      fetchHaneData(currentPage, searchData, {
        city: cityFilter?.label,
        district: districtFilter?.label,
        neighbourhood: neighbourhoodFilter?.label,
        street: streetFilter?.label,
        bina: null,
        daire: null,
      });
    }
  };

  const handleOtherSearch = () => {
    if (searchOtherTerm !== "") {
      setResetOtherActive(true);
      setOtherCurrentPage(1);
      getDetailSearch(1, searchOtherTerm);
    }
  };

  const handleFilterReset = () => {
    setSearchColumn(null);
    setSearchTerm("");
    setResetActive(false);
    setCurrentPage(1);
    setSearchCondition(null);

    fetchHaneData(1, null, {
      city: cityFilter?.label,
      district: districtFilter?.label,
      neighbourhood: neighbourhoodFilter?.label,
      street: streetFilter?.label,
      bina: null,
      daire: null,
    });
  };

  useEffect(() => {
    setSearchColumn(null);
    setSearchTerm("");
  }, [cityFilter, districtFilter, neighbourhoodFilter, streetFilter]);

  // Akraba
  const handleAkraba = async (data) => {
    if (data.length !== 0) {
      const conditionData = [
        {
          key: "Il",
          value: data[0]["adres il"],
          operator: "=",
          type: "and",
        },
        {
          key: "Ilce",
          value: data[0]["adres ilce"],
          operator: "=",
          type: "and",
        },
        {
          key: "Mahalle",
          value: data[0]["adres mahalle"],
          operator: "=",
          type: "and",
        },
        {
          key: "Cadde_Sokak",
          value: data[0]["adres sokak"],
          operator: "=",
          type: "and",
        },
        data[0]["bina no"]
          ? {
              key: "Bina_NO",
              value: data[0]["bina no"],
              operator: "=",
              type: "and",
            }
          : null,
        data[0]["daire"]
          ? {
              key: "Daire_NO",
              value: data[0]["daire"],
              operator: "=",
              type: "and",
            }
          : null,
      ].filter((item) => item !== null);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 24,
            title: "Hane ve Seçmen Analizi Detay",
            type: `akraba-data-customer-${context?.me?.customer?.id}`,
            description: "",
            status: true,
            customer: null,
            order: 1,
            sql: {
              column: [
                {
                  key: "TC",
                  value: "select",
                },
                {
                  key: "Aslen_Il",
                  value: "select",
                },
                {
                  key: "Aslen_Ilce",
                  value: "select",
                },
                {
                  key: "Ayni_Ilcedeki_Akraba_Sayisi",
                  value: "select",
                },
                {
                  key: "Ayni_Ildeki_Akraba_Sayisi",
                  value: "select",
                },
                {
                  key: "Aile_Buyugu",
                  value: "select",
                },
              ],
              condition: conditionData,
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const newDetailData = data.map((item1) => {
          const match = response.find((item2) => item2.tc === item1.tc);
          if (match) {
            Object.keys(match).forEach((key) => {
              if (key !== "tc") {
                item1[key] = match[key];
              }
            });
          }
          return item1;
        });

        setDetailData(newDetailData);
      } catch (error) {
        console.error(error);
        errorMessages(error);
      }
    }
  };

  // Hizmet Şikayetler
  const handleHizmetSikayetler = async (data) => {
    setHizmetSikayet(null);

    if (data.length !== 0) {
      const conditionData = [
        {
          key: "HIZMET-SIKAYET-ALAN-IL",
          value: data[0]["adres il"],
          operator: "=",
          type: "and",
        },
        {
          key: "HIZMET-SIKAYET-ALAN-ILCE",
          value: data[0]["adres ilce"],
          operator: "=",
          type: "and",
        },
        data[0]["adres mahalle"] && data[0]["adres mahalle"] !== ""
          ? {
              key: "HIZMET-SIKAYET-ALAN-MAHALLE",
              value: data[0]["adres mahalle"],
              operator: "=",
              type: "and",
            }
          : null,
        data[0]["adres sokak"] && data[0]["adres mahalle"] !== ""
          ? {
              key: "HIZMET-SIKAYET-ALAN-SOKAK",
              value: data[0]["adres sokak"],
              operator: "=",
              type: "and",
            }
          : null,
        data[0]["bina no"] && data[0]["bina no"] !== ""
          ? {
              key: "HIZMET-SIKAYET-ALAN-BINA-NO",
              value: data[0]["bina no"],
              operator: "=",
              type: "and",
            }
          : null,
        data[0]["daire"] && data[0]["daire"] !== ""
          ? {
              key: "HIZMET-SIKAYET-ALAN-DAIRE-NO",
              value: data[0]["daire"],
              operator: "=",
              type: "and",
            }
          : null,
        data[0]["tc"] && data[0]["tc"] !== ""
          ? {
              key: "TCKN",
              value: data[0]["tc"],
              operator: "=",
              type: "or",
            }
          : null,
        data[0]["telefon no"] && data[0]["telefon no"] !== ""
          ? {
              key: "TELEFON",
              value: data[0]["telefon no"],
              operator: "like",
              type: "or",
            }
          : null,
      ].filter((item) => item !== null);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 24,
            title: "Hane ve Seçmen Analizi Detay",
            type: "hizmet-sikayet-data",
            description: "",
            status: true,
            customer: null,
            order: 1,
            sql: {
              column: [
                {
                  key: "TUR",
                  value: "select",
                },
                {
                  key: "TCKN",
                  value: "select",
                },
                {
                  key: "TELEFON",
                  value: "select",
                },
                {
                  key: "ISIM",
                  value: "select",
                },
                {
                  key: "SOYISIM",
                  value: "select",
                },
                {
                  key: "HIZMET-SIKAYET-ALAN-IL",
                  value: "select",
                },
                {
                  key: "HIZMET-SIKAYET-ALAN-ILCE",
                  value: "select",
                },
                {
                  key: "HIZMET-SIKAYET-ALAN-MAHALLE",
                  value: "select",
                },
                {
                  key: "HIZMET-SIKAYET-ALAN-SOKAK",
                  value: "select",
                },
                {
                  key: "HIZMET-SIKAYET-ALAN-BINA-NO",
                  value: "select",
                },
                {
                  key: "HIZMET-SIKAYET-ALAN-DAIRE-NO",
                  value: "select",
                },
                {
                  key: "HIZMET_SIKAYET_ALAN_PLATFORM",
                  value: "select",
                },
                {
                  key: "BASLANGIC_TARIHI",
                  value: "select",
                },
                {
                  key: "BITIS_TARIHI",
                  value: "select",
                },
                {
                  key: "HIZMET_SIKAYET_ANA_KATEGORI",
                  value: "select",
                },
                {
                  key: "HIZMET_SIKAYET_ALT_KATEGORI_1",
                  value: "select",
                },
                {
                  key: "HIZMET_SIKAYET_ALT_KATEGORI_2",
                  value: "select",
                },
                {
                  key: "HIZMET_SIKAYET_METNI_1_ACILIS",
                  value: "select",
                },
                {
                  key: "HIZMET_SIKAYET_METNI_1_KAPANIS",
                  value: "select",
                },
              ],
              condition: conditionData,
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setHizmetSikayet(response);
      } catch (error) {
        console.error(error);
        setHizmetSikayet(null);
        // errorMessages(error);
      }
    } else {
      setHizmetSikayet([]);
    }
  };

  // Add Image
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size >= 5 * 1024 * 1024) {
        toastMessage(context?.t?.max_file_size_5mb, "error");
      } else {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await apiClient.post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (response) {
            const postData = {
              city: {
                name: detailData[0]["adres il"],
                image: [response.data.url],
              },
              district: {
                name: detailData[0]["adres ilce"],
              },
              neighbourhood: {
                name: detailData[0]["adres mahalle"],
              },
              street: {
                name: detailData[0]["adres sokak"],
              },
              building: {
                name: detailData[0]["bina no"],
              },
              independent: {
                name: detailData[0]["daire"],
              },
              note: noteValue,
            };

            try {
              const response = await apiClient.customPost(`/note`, postData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              });

              if (response) {
                toastMessage(context?.t?.resim_eklendi, "success");
              }
            } catch (error) {
              toastMessage(context?.t?.resim_eklenmedi, "error");
              console.error(error);
            }
          }
        } catch (error) {
          console.error("Upload File Error", error);
        }
      }
    }
  };

  // Add Note
  const [noteValue, setNoteValue] = useState("");
  const [getNote, setGetNote] = useState(null);
  const [noteLoader, setNoteLoader] = useState(false);

  async function handleNote() {
    setNoteLoader(true);

    if (getNote) {
      if (getNote.note !== noteValue) {
        try {
          const response = await apiClient.put(
            "/note/" + getNote.id,
            {
              note: noteValue,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response) {
            setNoteLoader(false);
            toastMessage(context?.t?.not_guncellendi, "success");
          }
        } catch (error) {
          setNoteLoader(false);
          toastMessage(context?.t?.not_guncellenmedi, "error");
          console.error(error);
        }
      }
    } else {
      if (noteValue && noteValue !== "") {
        const postData = {
          city: {
            name: detailData[0]["adres il"],
          },
          district: {
            name: detailData[0]["adres ilce"],
          },
          neighbourhood: {
            name: detailData[0]["adres mahalle"],
          },
          street: {
            name: detailData[0]["adres sokak"],
          },
          building: {
            name: detailData[0]["bina no"],
          },
          independent: {
            name: detailData[0]["daire"],
          },
          note: noteValue,
        };

        try {
          const response = await apiClient.customPost(`/note`, postData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (response) {
            setNoteLoader(false);
            toastMessage(context?.t?.not_eklendi, "success");
          }
        } catch (error) {
          setNoteLoader(false);
          toastMessage(context?.t?.not_eklenmedi, "error");
          console.error(error);
        }
      }
    }
  }

  // Get Note
  async function handleGetNote() {
    const filterProps = {
      city: detailData[0]["adres il"],
      district: detailData[0]["adres ilce"],
      neighbourhood: detailData[0]["adres mahalle"],
      street: detailData[0]["adres sokak"],
      building: detailData[0]["bina no"],
      independent: detailData[0]["daire"],
    };

    try {
      const response = await apiClient.get(`/note`, {
        params: filterProps,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (
        Object.keys(response.data).length === 0 &&
        response.data.constructor === Object
      ) {
        setGetNote(null);
        setNoteValue("");
      } else {
        setGetNote(response.data);
        setNoteValue(response.data.note);
      }
    } catch (error) {
      console.error("Get Note Error", error);
    }
  }

  useEffect(() => {
    if (detailData) {
      handleGetNote();
    }
  }, [detailData]);

  return (
    <>
      <div className="col-12 zoom-item mb-5">
        <div className="row g-3 mb-4">
          <PageFilter
            setCityFilter={setCityFilter}
            setDistrictFilter={setDistrictFilter}
            setNeighbourhoodFilter={setNeighbourhoodFilter}
            setStreetFilter={setStreetFilter}
          />

          <div className="col-12">
            <div className="row g-4">
              <CardBinaHane
                city={cityFilter?.label}
                district={districtFilter?.label}
                neighbourhood={neighbourhoodFilter?.label}
                street={streetFilter?.label}
              />

              <CardMedeniDurum
                title={context?.t?.sokak_bina_ve_hane_analizleri}
                path="choice-analysis"
                city={cityFilter?.label}
                district={districtFilter?.label}
                neighbourhood={neighbourhoodFilter?.label}
                street={streetFilter?.label}
                graphic={2}
                type={2}
              />

              <CardVatandasSayisi
                city={cityFilter?.label}
                district={districtFilter?.label}
                neighbourhood={neighbourhoodFilter?.label}
                street={streetFilter?.label}
                graphic={3}
              />

              <CardOlumSayisi
                path="choice-analysis"
                city={cityFilter?.label}
                district={districtFilter?.label}
                neighbourhood={neighbourhoodFilter?.label}
                street={streetFilter?.label}
                graphic={4}
                type={1}
              />

              <CardHemsehrilikGoc
                path="choice-analysis"
                city={cityFilter?.label}
                district={districtFilter?.label}
                neighbourhood={neighbourhoodFilter?.label}
                street={streetFilter?.label}
                graphic={5}
              />

              <CardSosyoekonomik
                title={context?.t?.sosyoekonomik_status_dagilimi}
                city={cityFilter?.label}
                district={districtFilter?.label}
                neighbourhood={neighbourhoodFilter?.label}
                street={streetFilter?.label}
                graphic={6}
              />
            </div>
          </div>

          <div className="col-12">
            <Card>
              <CardBody>
                {cityFilter && districtFilter && districtFilter !== "filter" ? (
                  <>
                    <div className="row">
                      <div className="col">
                        <div style={{ maxWidth: 500 }}>
                          <div className="row g-3">
                            <div className="col">
                              {haneColumns ? (
                                <Select
                                  options={haneColumns
                                    .filter(
                                      (item) =>
                                        item !== "Adres Il" &&
                                        item !== "Adres Ilcesi" &&
                                        item !== "Adres Mahallesi" &&
                                        item !== "Adres Sokagi"
                                    )
                                    .map((item) => ({
                                      label: item,
                                      value: item,
                                    }))}
                                  classNamePrefix="react-select"
                                  placeholder={context?.t?.choose_column_2}
                                  noOptionsMessage={() => context?.t?.no_option}
                                  value={searchColumn}
                                  onChange={(e) => setSearchColumn(e)}
                                />
                              ) : (
                                <div className="spinner-border text-theme"></div>
                              )}
                            </div>

                            <div className="col">
                              <input
                                type="text"
                                placeholder={context?.t?.search}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="form-control"
                              />
                            </div>

                            <div
                              className="col w-auto"
                              style={{ flex: "none" }}
                            >
                              <button
                                type="button"
                                className="btn btn-outline-theme"
                                onClick={() => handleSearch()}
                              >
                                {context?.t?.search}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col w-auto" style={{ flex: "none" }}>
                        {resetActive && (
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleFilterReset()}
                          >
                            {context?.t?.reset}
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="position-relative">
                      {dataLoader ? (
                        dataPagination.data.length === 0 ? (
                          <span className="d-block text-center border-top mt-3 pt-3">
                            {context?.t?.no_data_available}
                          </span>
                        ) : (
                          <div className="dataTables_wrapper dt-bootstrap5">
                            <div className="row mb-3">
                              <div className="dataTables_scroll">
                                <div className="dataTables_scrollBody border-top mt-3 pt-3 hide-data">
                                  <AutoSizer disableHeight>
                                    {({ width }) => (
                                      <MultiGrid
                                        cellRenderer={cellRenderer}
                                        columnCount={columnConfig.length}
                                        columnWidth={getColumnWidth}
                                        fixedColumnCount={1}
                                        fixedRowCount={1}
                                        height={window.innerHeight / 2}
                                        rowCount={
                                          dataPagination.data.length + 1
                                        }
                                        rowHeight={cache.rowHeight}
                                        width={width}
                                      />
                                    )}
                                  </AutoSizer>
                                </div>

                                <div className="row align-items-center mt-3">
                                  <div className="mb-0 col-md-12">
                                    <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                                      <ul className="pagination">
                                        <li
                                          className={`paginate_button page-item`}
                                        >
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handlePageChange(currentPage - 1)
                                            }
                                            className={`page-link pointer ${
                                              !dataPagination.prev_page_url
                                                ? "disabled"
                                                : ""
                                            }`}
                                          >
                                            {context?.t?.prev}
                                          </button>
                                        </li>

                                        {dataPagination.links.map(
                                          (number, index) =>
                                            index > 0 &&
                                            index <
                                              dataPagination.links.length -
                                                1 && (
                                              <li
                                                key={index}
                                                className={`paginate_button page-item ${
                                                  currentPage ===
                                                  parseInt(number.label)
                                                    ? "active"
                                                    : ""
                                                }`}
                                              >
                                                <div
                                                  onClick={() =>
                                                    handlePageChange(
                                                      parseInt(number.label)
                                                    )
                                                  }
                                                  className="page-link pointer"
                                                >
                                                  {number.label}
                                                </div>
                                              </li>
                                            )
                                        )}

                                        <li
                                          className={`paginate_button page-item`}
                                        >
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handlePageChange(currentPage + 1)
                                            }
                                            className={`page-link pointer ${
                                              !dataPagination.next_page_url
                                                ? "disabled"
                                                : ""
                                            }`}
                                          >
                                            {context?.t?.next}
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="spinner-border text-theme mt-3"></div>
                      )}
                    </div>

                    <hr />

                    <div className="row">
                      <div className="col">
                        <div style={{ maxWidth: 500 }}>
                          <div className="row g-3">
                            <div className="col">
                              <Select
                                options={[
                                  {
                                    label: "Ad Soyad",
                                    value: "Ad Soyad",
                                  },
                                ]}
                                value={{
                                  label: "Ad Soyad",
                                  value: "Ad Soyad",
                                }}
                                isDisabled
                                classNamePrefix="react-select"
                                placeholder={context?.t?.choose_column_2}
                                noOptionsMessage={() => context?.t?.no_option}
                              />
                            </div>

                            <div className="col">
                              <input
                                type="text"
                                placeholder={context?.t?.search}
                                value={searchOtherTerm}
                                onChange={(e) =>
                                  setSearchOtherTerm(e.target.value)
                                }
                                className="form-control"
                              />
                            </div>

                            <div
                              className="col w-auto"
                              style={{ flex: "none" }}
                            >
                              <button
                                type="button"
                                className="btn btn-outline-theme"
                                onClick={() => handleOtherSearch()}
                              >
                                {context?.t?.search}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      {resetOtherActive &&
                        (detailSearchData ? (
                          detailSearchData.length !== 0 ? (
                            <>
                              <div
                                className="table-responsive hide-data"
                                style={{ height: 500 }}
                              >
                                <table className="table mt-3">
                                  <thead>
                                    <tr>
                                      <th style={{ minWidth: 100, width: 100 }}>
                                        {context?.t?.transaction}
                                      </th>
                                      <th style={{ minWidth: 50, width: 50 }}>
                                        #
                                      </th>
                                      <th style={{ minWidth: 100, width: 100 }}>
                                        {context?.t?.city}
                                      </th>
                                      <th style={{ minWidth: 100, width: 100 }}>
                                        {context?.t?.district}
                                      </th>
                                      <th style={{ minWidth: 150, width: 150 }}>
                                        {context?.t?.neighborhood}
                                      </th>
                                      <th style={{ minWidth: 150, width: 150 }}>
                                        {context?.t?.street}
                                      </th>
                                      <th style={{ minWidth: 80, width: 80 }}>
                                        {context?.t?.building}
                                      </th>
                                      <th style={{ minWidth: 80, width: 80 }}>
                                        {context?.t?.apartment}
                                      </th>
                                      <th style={{ minWidth: 120, width: 120 }}>
                                        {context?.t?.tc}
                                      </th>
                                      <th style={{ minWidth: 200, width: 200 }}>
                                        {context?.t?.name_surname}
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {detailSearchData.data.map(
                                      (item, index) => (
                                        <tr
                                          key={index}
                                          onMouseEnter={() =>
                                            setHoveredNameTableRow(index)
                                          }
                                          onMouseLeave={() =>
                                            setHoveredNameTableRow(null)
                                          }
                                        >
                                          <td>
                                            <button
                                              type="button"
                                              className="btn btn-outline-theme btn-sm"
                                              data-bs-toggle="modal"
                                              data-bs-target="#haneDetailsModal"
                                              onClick={() =>
                                                getDetailData(item)
                                              }
                                            >
                                              {context?.t?.detail}
                                            </button>
                                          </td>
                                          <td>{index + 1}</td>
                                          <td>
                                            {hoveredNameTableRow === index
                                              ? item["adres il"]
                                              : "********"}
                                          </td>
                                          <td>
                                            {hoveredNameTableRow === index
                                              ? item["adres ilce"]
                                              : "********"}
                                          </td>
                                          <td>
                                            {hoveredNameTableRow === index
                                              ? item["adres mahalle"]
                                              : "********"}
                                          </td>
                                          <td>
                                            {hoveredNameTableRow === index
                                              ? item["adres sokak"]
                                              : "********"}
                                          </td>
                                          <td>
                                            {hoveredNameTableRow === index
                                              ? item["bina no"]
                                              : "********"}
                                          </td>
                                          <td>
                                            {hoveredNameTableRow === index
                                              ? item["daire"]
                                              : "********"}
                                          </td>
                                          <td>
                                            {hoveredNameTableRow === index
                                              ? context.me?.customer?.settings
                                                  ?.primary
                                                ? item["tc"]
                                                : "--"
                                              : "********"}
                                          </td>
                                          <td>
                                            {hoveredNameTableRow === index
                                              ? context.me?.customer?.settings
                                                  ?.primary
                                                ? item["ad soyad"]
                                                : "--"
                                              : "********"}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div className="row align-items-center mt-3">
                                <div className="mb-0 col-md-12">
                                  <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                                    <ul className="pagination">
                                      <li
                                        className={`paginate_button page-item`}
                                      >
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleOtherPageChange(
                                              otherCurrentPage - 1
                                            )
                                          }
                                          className={`page-link pointer ${
                                            !detailSearchData.prev_page_url
                                              ? "disabled"
                                              : ""
                                          }`}
                                        >
                                          {context?.t?.prev}
                                        </button>
                                      </li>

                                      {detailSearchData.links.map(
                                        (number, index) =>
                                          index > 0 &&
                                          index <
                                            detailSearchData.links.length -
                                              1 && (
                                            <li
                                              key={index}
                                              className={`paginate_button page-item ${
                                                otherCurrentPage ===
                                                parseInt(number.label)
                                                  ? "active"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                onClick={() =>
                                                  handleOtherPageChange(
                                                    parseInt(number.label)
                                                  )
                                                }
                                                className="page-link pointer"
                                              >
                                                {number.label}
                                              </div>
                                            </li>
                                          )
                                      )}

                                      <li
                                        className={`paginate_button page-item`}
                                      >
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleOtherPageChange(
                                              otherCurrentPage + 1
                                            )
                                          }
                                          className={`page-link pointer ${
                                            !detailSearchData.next_page_url
                                              ? "disabled"
                                              : ""
                                          }`}
                                        >
                                          {context?.t?.next}
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <span className="d-block mt-2">
                              {context?.t?.kisi_bulunamadi}
                            </span>
                          )
                        ) : (
                          <div className="spinner-border text-theme mt-3"></div>
                        ))}
                    </div>
                  </>
                ) : (
                  <span className="d-block text-center">
                    {context?.t?.il_ve_ilce_secmelisiniz}
                  </span>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      <div
        className="modal modal-cover fade details-modal"
        id="haneDetailsModal"
      >
        <div className="modal-dialog modal-fullscreen p-3">
          <div
            className="modal-content rounded py-2 px-3"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <div className="d-flex align-items-center">
                <h3 className="modal-title mb-0">{context?.t?.details}</h3>
                {detailData && detailData.length !== 0 && (
                  <ol className="breadcrumb mb-0 ms-3 mt-1">
                    <li className="breadcrumb-item">
                      {detailData[0]["adres il"] || "-"}
                    </li>
                    <li className="breadcrumb-item">
                      {detailData[0]["adres ilce"] || "-"}
                    </li>
                    <li className="breadcrumb-item">
                      {detailData[0]["adres mahalle"] || "-"}
                    </li>
                    <li className="breadcrumb-item">
                      {detailData[0]["adres sokak"] || "-"}
                    </li>
                    <li className="breadcrumb-item">
                      {detailData[0]["bina no"] || "-"}
                    </li>
                    <li className="breadcrumb-item">
                      {detailData[0]["daire"] || "-"}
                    </li>
                  </ol>
                )}
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <div
                className="row g-4 pb-4 flex-column"
                style={{ height: "calc(100vh - 170px)", overflow: "hidden" }}
              >
                <div className="col w-100" style={{ overflow: "hidden" }}>
                  <div className="row h-100 overflow-auto">
                    <div className="col-12 col-xl-8 h-100">
                      <div className="row d-flex flex-column h-100">
                        <div
                          className="col d-flex flex-column h-100"
                          style={{ overflow: "hidden" }}
                        >
                          <h6>{context?.t?.aile}</h6>

                          <div className="hide-data table-responsive">
                            {detailData ? (
                              <table className="table w-100">
                                <thead>
                                  <tr>
                                    <th style={{ minWidth: 120, width: 120 }}>
                                      {context?.t?.tc}
                                    </th>
                                    <th style={{ minWidth: 120, width: 120 }}>
                                      {context?.t?.dogum_tarihi}
                                    </th>
                                    <th style={{ minWidth: 150, width: 150 }}>
                                      {context?.t?.isim_soyisim}
                                    </th>
                                    <th style={{ minWidth: 120, width: 120 }}>
                                      {context?.t?.iletisim_bilgisi}
                                    </th>
                                    <th style={{ minWidth: 120, width: 120 }}>
                                      {context?.t?.yas_grubu}
                                    </th>
                                    <th style={{ minWidth: 120, width: 120 }}>
                                      {context?.t?.aslen_il}
                                    </th>
                                    <th style={{ minWidth: 120, width: 120 }}>
                                      {context?.t?.aslen_ilce}
                                    </th>
                                    <th style={{ minWidth: 120, width: 120 }}>
                                      {context?.t?.ayni_ildeki_akraba_sayisi}
                                    </th>
                                    <th style={{ minWidth: 120, width: 120 }}>
                                      {context?.t?.ayni_ilcedeki_akraba_sayisi}
                                    </th>
                                    <th style={{ minWidth: 300, width: 300 }}>
                                      {context?.t?.aile_buyugu}
                                    </th>
                                    {/* <th>Sandığa Gitme İhtimali</th>
                                    <th>Sandığa Gitmeme İhtimali</th>
                                    <th>Seçmen Türü</th> */}
                                  </tr>
                                </thead>

                                <tbody>
                                  {detailData.length !== 0 ? (
                                    detailData.map((item, index) => (
                                      <tr
                                        key={index}
                                        onMouseEnter={() =>
                                          setHoveredDetailTableRow(index)
                                        }
                                        onMouseLeave={() =>
                                          setHoveredDetailTableRow(null)
                                        }
                                      >
                                        <td>
                                          {hoveredDetailTableRow === index
                                            ? context.me?.customer?.settings
                                                ?.primary
                                              ? item?.tc || context?.t?.unknown
                                              : "--"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredDetailTableRow === index
                                            ? context.me?.customer?.settings
                                                ?.primary
                                              ? item["dogum tarihi"]
                                                ? formatDate(
                                                    item["dogum tarihi"]
                                                  )
                                                : context?.t?.unknown
                                              : "--"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredDetailTableRow === index
                                            ? context.me?.customer?.settings
                                                ?.primary
                                              ? item["ad soyad"] ||
                                                context?.t?.unknown
                                              : "--"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredDetailTableRow === index
                                            ? context.me?.customer?.settings
                                                ?.primary
                                              ? item["telefon no"]
                                                ? context.phoneFormat(
                                                    item["telefon no"]
                                                  )
                                                : context?.t?.unknown
                                              : "--"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredDetailTableRow === index
                                            ? item["yaş grubu"] ||
                                              context?.t?.unknown
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredDetailTableRow === index
                                            ? item["aslen_il"] ||
                                              context?.t?.unknown
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredDetailTableRow === index
                                            ? item["aslen_ilce"] ||
                                              context?.t?.unknown
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredDetailTableRow === index
                                            ? item[
                                                "ayni_ildeki_akraba_sayisi"
                                              ] || context?.t?.unknown
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredDetailTableRow === index
                                            ? item[
                                                "ayni_ilcedeki_akraba_sayisi"
                                              ] || context?.t?.unknown
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredDetailTableRow === index
                                            ? context.me?.customer?.settings
                                                ?.primary
                                              ? item["aile_buyugu"] ||
                                                context?.t?.unknown
                                              : "--"
                                            : "********"}
                                        </td>
                                        {/* <td>
                                          {"%" +
                                            item["sandığa gitme ihtimali"] ||
                                            context?.t?.unknown}
                                        </td>
                                        <td>
                                          {"%" +
                                            item["sandığa gitmeme ihtimali"] ||
                                            context?.t?.unknown}
                                        </td>
                                        <td>
                                          {item["secmen profili"] ||
                                            context?.t?.unknown}
                                        </td> */}
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td>{context?.t?.no_data_available}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              <div className="spinner-border text-theme"></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-xl-2 h-100 d-flex flex-column">
                      <div
                        className="flex-1 overflow-y-auto mb-3 border rounded w-100"
                        style={{ paddingRight: 10, marginRight: -10 }}
                      >
                        {getNote?.city?.image &&
                          getNote?.city?.image.length !== 0 && (
                            <Fancybox
                              options={{
                                Carousel: {
                                  infinite: false,
                                },
                              }}
                            >
                              <div className="row g-2">
                                {getNote.city.image.map((item, index) => (
                                  <a
                                    key={index}
                                    data-fancybox={"hane-detail-gallery"}
                                    href={item}
                                    className="col-6"
                                  >
                                    <img
                                      src={item}
                                      alt={"hane-detail-" + (index + 1)}
                                      className="w-100 img-cover"
                                      style={{ height: 100 }}
                                    />
                                  </a>
                                ))}
                              </div>
                            </Fancybox>
                          )}
                      </div>

                      <div className="w-100">
                        <input
                          id={`hane-detail-upload`}
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          className="d-none"
                          onChange={handleFileUpload}
                        />
                        <label
                          htmlFor={`hane-detail-upload`}
                          className="btn btn-outline-theme w-100 h-100 d-flex align-items-center justify-content-center pointer"
                        >
                          {context?.t?.upload_image}
                        </label>
                      </div>
                    </div>

                    <div className="col-12 col-xl-2 h-100 d-flex flex-column">
                      <textarea
                        className="form-control flex-1 mb-3"
                        placeholder={
                          getNote
                            ? context?.t?.not_guncelle
                            : context?.t?.not_ekle
                        }
                        value={noteValue}
                        onChange={(e) => setNoteValue(e.target.value)}
                        style={{ resize: "none" }}
                      ></textarea>
                      {getNote && (
                        <span className="h6" style={{ fontSize: 12 }}>
                          {context?.t?.duzenleyen}: {getNote.user.name}
                        </span>
                      )}
                      <button
                        type="button"
                        className="btn btn-outline-theme"
                        disabled={noteLoader}
                        onClick={() => handleNote()}
                      >
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          {getNote
                            ? context?.t?.not_guncelle
                            : context?.t?.not_ekle}
                          {noteLoader && (
                            <div className="spinner-border spinner-border-sm text-theme"></div>
                          )}
                        </div>
                      </button>
                    </div>

                    {/* <div className="col-12 col-xl-2">
                      <div className="row gap-4 d-flex flex-column h-100">
                        <div className="col-12 flex-1">
                          <Card className="h-100">
                            <CardBody className="d-flex flex-column">
                              <ul className="flex-nowrap justify-content-between text-center nav nav-tabs nav-tabs-v2">
                                <li className="nav-item mx-1">
                                  <a
                                    href="#boxResult2"
                                    className="nav-link active"
                                    data-bs-toggle="tab"
                                  >
                                    Memleket Sonucu
                                  </a>
                                </li>

                                <li className="nav-item mx-1">
                                  <a
                                    href="#boxResult1"
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                  >
                                    Mahalle Sonucu
                                  </a>
                                </li>

                                <li className="nav-item mx-1">
                                  <a
                                    href="#boxResult3"
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                  >
                                    Sandık Sonucu
                                  </a>
                                </li>
                              </ul>

                              <div className="tab-content flex-1 py-3">
                                <div
                                  className="tab-pane h-100 fade"
                                  id="boxResult1"
                                >
                                  {mahalleSonucuData ? (
                                    <div className="text-center h4 pt-4 d-flex flex-column justify-content-center h-100 gap-4">
                                      {mahalleSonucuData.length !== 0 ? (
                                        mahalleSonucuData.map((item, index) => (
                                          <div key={index}>
                                            {index + 1}. {item}
                                          </div>
                                        ))
                                      ) : (
                                        <span>{context?.t?.no_data_available}</span>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="spinner-border text-theme"></div>
                                  )}
                                </div>
                                <div
                                  className="tab-pane h-100 fade show active"
                                  id="boxResult2"
                                >
                                  {memleketSonucuData ? (
                                    <div className="text-center h4 pt-4 d-flex flex-column justify-content-center h-100 gap-4">
                                      {memleketSonucuData.length !== 0 ? (
                                        memleketSonucuData.map(
                                          (item, index) => (
                                            <div key={index}>
                                              {index + 1}. {item}
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <span>{context?.t?.no_data_available}</span>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="spinner-border text-theme"></div>
                                  )}
                                </div>
                                <div
                                  className="tab-pane h-100 fade"
                                  id="boxResult3"
                                >
                                  {sandıkSonucuData ? (
                                    <div className="text-center h4 pt-4 d-flex flex-column justify-content-center h-100 gap-4">
                                      {sandıkSonucuData.length !== 0 ? (
                                        sandıkSonucuData.map((item, index) => (
                                          <div key={index}>
                                            {index + 1}. {item}
                                          </div>
                                        ))
                                      ) : (
                                        <span>{context?.t?.no_data_available}</span>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="spinner-border text-theme"></div>
                                  )}
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>

                        <div className="col-12 flex-1">
                          <Card className="h-100">
                            <CardBody className="d-flex flex-column">
                              {familyID ? (
                                <>
                                  <ul className="flex-nowrap justify-content-between text-center nav nav-tabs nav-tabs-v2">
                                    <li className="nav-item mx-1 w-100">
                                      <a
                                        href="#detailProfile1"
                                        className="nav-link active block w-100"
                                        data-bs-toggle="tab"
                                      >
                                        Aile Siyasi Kimlik
                                      </a>
                                    </li>
                                  </ul>

                                  <div className="tab-content flex-1 py-3">
                                    <div
                                      className="tab-pane h-100 fade show active"
                                      id="detailProfile1"
                                    >
                                      <div className="text-center h4 pt-4 d-flex flex-column justify-content-center h-100 gap-4">
                                        {familyID.length !== 0 ? (
                                          familyID.map((item, index) => (
                                            <div key={index}>{item}</div>
                                          ))
                                        ) : (
                                          <span>
                                            {context?.t?.no_data_available}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="spinner-border text-theme"></div>
                              )}
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="col" style={{ overflow: "hidden" }}>
                  <div className="row">
                    <div className="col-6">
                      <div
                        className="h-100 d-flex flex-column"
                        style={{ overflow: "hidden" }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <h6>{context?.t?.hizmetler}</h6>
                        </div>

                        <div
                          className="flex-1 mt-3 hide-data"
                          style={{
                            overflow: "auto",
                          }}
                        >
                          {hizmetSikayet ? (
                            hizmetSikayet.length !== 0 &&
                            hizmetSikayet.filter(
                              (hizmet) => hizmet.tur === "HIZMET"
                            ).length !== 0 ? (
                              <table className="table w-100">
                                <thead style={{ whiteSpace: "nowrap" }}>
                                  <tr>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.started_date}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.end_date}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.tc}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.phone}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.isim_soyisim}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.main_category}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.sub_category_1}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.sub_category_2}
                                    </th>
                                    <th style={{ minWidth: 400, width: 400 }}>
                                      {context?.t?.acilis_metni}
                                    </th>
                                    <th style={{ minWidth: 400, width: 400 }}>
                                      {context?.t?.kapanis_metni}
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {hizmetSikayet
                                    .filter((hizmet) => hizmet.tur === "HIZMET")
                                    .sort(
                                      (a, b) =>
                                        new Date(b.baslangic_tarihi) -
                                        new Date(a.baslangic_tarihi)
                                    )
                                    .map((item, index) => (
                                      <tr
                                        key={index}
                                        onMouseEnter={() =>
                                          setHoveredHizmetlerTableRow(index)
                                        }
                                        onMouseLeave={() =>
                                          setHoveredHizmetlerTableRow(null)
                                        }
                                      >
                                        <td>
                                          {hoveredHizmetlerTableRow === index
                                            ? item.baslangic_tarihi || "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredHizmetlerTableRow === index
                                            ? item.bitis_tarihi || "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredHizmetlerTableRow === index
                                            ? context.me?.customer?.settings
                                                ?.primary
                                              ? item.tckn || "-"
                                              : "--"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredHizmetlerTableRow === index
                                            ? context.me?.customer?.settings
                                                ?.primary
                                              ? item.telefon || "-"
                                              : "--"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredHizmetlerTableRow === index
                                            ? context.me?.customer?.settings
                                                ?.primary
                                              ? item.isim + " " + item.soyisim
                                              : "--"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredHizmetlerTableRow === index
                                            ? item.hizmet_sikayet_ana_kategori ||
                                              "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredHizmetlerTableRow === index
                                            ? item.hizmet_sikayet_alt_kategori_1 ||
                                              "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredHizmetlerTableRow === index
                                            ? item.hizmet_sikayet_alt_kategori_2 ||
                                              "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredHizmetlerTableRow === index
                                            ? item.hizmet_sikayet_metni_1_acilis ||
                                              "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredHizmetlerTableRow === index
                                            ? item.hizmet_sikayet_metni_1_kapanis ||
                                              "-"
                                            : "********"}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            ) : (
                              <span>{context?.t?.hizmet_bulunamadi}</span>
                            )
                          ) : (
                            <div className="spinner-border text-theme"></div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div
                        className="h-100 d-flex flex-column"
                        style={{ overflow: "hidden" }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <h6>{context?.t?.sikayetler}</h6>
                        </div>

                        <div
                          className="flex-1 mt-3 hide-data"
                          style={{
                            overflow: "auto",
                          }}
                        >
                          {hizmetSikayet ? (
                            hizmetSikayet.length !== 0 &&
                            hizmetSikayet.filter(
                              (hizmet) => hizmet.tur === "SIKAYET"
                            ).length !== 0 ? (
                              <table className="table w-100">
                                <thead style={{ whiteSpace: "nowrap" }}>
                                  <tr>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.started_date}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.end_date}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.tc}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.phone}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.isim_soyisim}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.main_category}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.sub_category_1}
                                    </th>
                                    <th style={{ minWidth: 200, width: 200 }}>
                                      {context?.t?.sub_category_2}
                                    </th>
                                    <th style={{ minWidth: 400, width: 400 }}>
                                      {context?.t?.acilis_metni}
                                    </th>
                                    <th style={{ minWidth: 400, width: 400 }}>
                                      {context?.t?.kapanis_metni}
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {hizmetSikayet
                                    .filter(
                                      (hizmet) => hizmet.tur === "SIKAYET"
                                    )
                                    .sort(
                                      (a, b) =>
                                        new Date(b.baslangic_tarihi) -
                                        new Date(a.baslangic_tarihi)
                                    )
                                    .map((item, index) => (
                                      <tr
                                        key={index}
                                        onMouseEnter={() =>
                                          setHoveredSikayetlerTableRow(index)
                                        }
                                        onMouseLeave={() =>
                                          setHoveredSikayetlerTableRow(null)
                                        }
                                      >
                                        <td>
                                          {hoveredSikayetlerTableRow === index
                                            ? item.baslangic_tarihi || "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredSikayetlerTableRow === index
                                            ? item.bitis_tarihi || "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredSikayetlerTableRow === index
                                            ? context.me?.customer?.settings
                                                ?.primary
                                              ? item.tckn || "-"
                                              : "--"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredSikayetlerTableRow === index
                                            ? context.me?.customer?.settings
                                                ?.primary
                                              ? item.telefon || "-"
                                              : "--"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredSikayetlerTableRow === index
                                            ? context.me?.customer?.settings
                                                ?.primary
                                              ? item.isim + " " + item.soyisim
                                              : "--"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredSikayetlerTableRow === index
                                            ? item.hizmet_sikayet_ana_kategori ||
                                              "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredSikayetlerTableRow === index
                                            ? item.hizmet_sikayet_alt_kategori_1 ||
                                              "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredSikayetlerTableRow === index
                                            ? item.hizmet_sikayet_alt_kategori_2 ||
                                              "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredSikayetlerTableRow === index
                                            ? item.hizmet_sikayet_metni_1_acilis ||
                                              "-"
                                            : "********"}
                                        </td>
                                        <td>
                                          {hoveredSikayetlerTableRow === index
                                            ? item.hizmet_sikayet_metni_1_kapanis ||
                                              "-"
                                            : "********"}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            ) : (
                              <span>{context?.t?.sikayet_bulunamadi}</span>
                            )
                          ) : (
                            <div className="spinner-border text-theme"></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HaneAnalizi;
