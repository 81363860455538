import React, { useContext, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import SidebarNav from "./sidebar-nav.jsx";
import { AppSettings } from "../../config/app-settings.js";
import { Link, useNavigate } from "react-router-dom";

import tr from "../../images/tr-flag.svg";
import gb from "../../images/gb-flag.svg";
import fr from "../../images/fr-flag.svg";
import sa from "../../images/sa-flag.svg";
import ru from "../../images/ru-flag.svg";

function Sidebar({ importFileOpen, setImportFileOpen }) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  async function handleLogOut() {
    context.setMe(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    // localStorage.removeItem("theme");

    // document.body.classList.forEach((className) => {
    //   if (className.startsWith("theme-")) {
    //     document.body.classList.remove(className);
    //   }
    // });

    // document.getElementById("header").style.background =
    //   "rgba(29, 40, 53, 0.8);";
    // document.getElementById("sidebar").style.background = "#212e3b";

    // document.querySelector(".app").style.background =
    //   "linear-gradient(180deg, #3cd2a5 -190%, rgb(0, 7, 23) 90%)";

    window.location.href = "/giris-yap";
  }

  //--

  const toggleAppSidebarMobile = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-sidebar-toggled");
  };

  //--

  // useEffect(() => {
  //   let inputBuffer = "";

  //   const handleKeyDown = (event) => {
  //     if (event.key.length === 1) {
  //       inputBuffer += event.key;

  //       if (inputBuffer.endsWith("ohal123")) {
  //         document.body.classList.add("ohal-active");
  //       }
  //     } else if (event.key === "Backspace") {
  //       inputBuffer = inputBuffer.slice(0, -1);
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  // Dil değiştirildiğinde localStorage'a kaydedilir
  const handleChangeLanguage = (lang) => {
    context.setLanguage(lang);
    localStorage.setItem("language", lang);
  };

  useEffect(() => {
    // Sayfa yüklendiğinde localStorage'dan dili ayarla
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      context.setLanguage(savedLanguage);
    }
  }, [context]);

  return (
    <React.Fragment>
      <div id="sidebar" className="app-sidebar">
        {/* <div
          className="app-sidebar-button"
          style={{ top: 30 }}
          data-bs-toggle="modal"
          data-bs-target="#createPageModal"
          onClick={() => context.setUpdatePage(null)}
        >
          <i className="fas fa-md fa-fw fa-plus"></i>
        </div> */}
        <div className="app-sidebar-button" onClick={toggleAppSidebarMobile}>
          <i className="fas fa-md fa-fw fa-arrow-left"></i>
        </div>

        <PerfectScrollbar
          className="app-sidebar-content"
          options={{ suppressScrollX: true }}
        >
          <SidebarNav
            filteredMenu={
              context &&
              context.pages &&
              context.pages
                .map((item) => {
                  // Eğer item.id 44 ise, children'ı boş döndür
                  if (item.id === 44) {
                    return {
                      ...item,
                      children: [], // children'ı boş döndür
                    };
                  }
                  // Eğer item.name "Anket Sayfası" ile başlamıyorsa, item'ı döndür
                  if (item.name && !item.name.startsWith("Anket Sayfası")) {
                    return item;
                  }
                  // Diğer durumlarda null döndür
                  return null;
                })
                .filter((item) => item !== null) // null olmayanları filtrele
            }
          />

          <div className="mt-auto">
            {context && (
              <div className="row g-2 mx-1 mb-2">
                <div className="col-12">
                  <div className="row g-2">
                    <div
                      onClick={() => handleChangeLanguage("tr")}
                      className={`col pointer ${
                        context.language === "tr" ? "border border-theme" : ""
                      }`}
                    >
                      <img src={tr} alt="" className="w-100" height={30} />
                    </div>
                    <div
                      onClick={() => handleChangeLanguage("en")}
                      className={`col pointer ${
                        context.language === "en" ? "border border-theme" : ""
                      }`}
                    >
                      <img src={gb} alt="" className="w-100" height={30} />
                    </div>
                    <div
                      onClick={() => handleChangeLanguage("fr")}
                      className={`col pointer ${
                        context.language === "fr" ? "border border-theme" : ""
                      }`}
                    >
                      <img src={fr} alt="" className="w-100" height={30} />
                    </div>
                    <div
                      onClick={() => handleChangeLanguage("ar")}
                      className={`col pointer ${
                        context.language === "ar" ? "border border-theme" : ""
                      }`}
                    >
                      <img src={sa} alt="" className="w-100" height={30} />
                    </div>
                    <div
                      onClick={() => handleChangeLanguage("ru")}
                      className={`col pointer ${
                        context.language === "ru" ? "border border-theme" : ""
                      }`}
                    >
                      <img src={ru} alt="" className="w-100" height={30} />
                    </div>
                  </div>
                </div>

                {(context?.me?.role?.id === 1 ||
                  context?.me?.role?.id === 2 ||
                  context?.me?.role?.id === 4) && (
                  <>
                    {/* {context &&
                      context?.pages &&
                      context?.pages.length !== 0 &&
                      context?.pages.some((item) => item.id === 83) && (
                        <div className="col-12">
                          <Link
                            to="/afet-yonetimi"
                            className="btn btn-sm btn-outline-danger w-100"
                            // onClick={() => document.body.classList.add("ohal-active")}
                          >
                            {context?.t?.disaster_management}
                          </Link>
                        </div>
                      )} */}

                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-theme w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#createPageModal"
                        onClick={() => context.setUpdatePage(null)}
                      >
                        {context && context.t && context.t.create_page}
                      </button>
                    </div>

                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-theme w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#fileImportModal"
                        onClick={() => setImportFileOpen(!importFileOpen)}
                      >
                        {context && context.t && context.t.import_file}
                      </button>
                    </div>

                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-theme w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#createGraphicModal"
                        onClick={() => {
                          context.setCreateGraphicOpen(true);
                          context.setGraphicUpdate(null);
                        }}
                      >
                        {context && context.t && context.t.create_graphic}
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}

            {/* <div className="px-2 my-2">
              <ThemePanel />
            </div> */}

            <div
              className="px-2 mt-auto menu-footer"
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              <div className="d-flex gap-2">
                {context?.me?.role?.id !== 5 &&
                context?.me?.role?.id !== 6 &&
                context?.me?.role?.id !== 7 ? (
                  <>
                    <Link
                      to="/profil"
                      className="btn d-block px-1 btn-outline-theme flex-1 menu-footer-title overflow-hidden"
                      style={{ fontSize: 12 }}
                    >
                      {/* <i className="fa fa-sm fa-user me-1"></i> */}
                      {(context.me && context.me.name) || (
                        <div className="spinner-border text-theme spinner-border-sm"></div>
                      )}
                    </Link>
                    <Link
                      to="/profil?notification=birthday"
                      className="btn d-flex align-items-center btn-outline-warning py-0 px-2 position-relative"
                    >
                      {context?.birthDayData &&
                        context?.birthDayData?.data &&
                        context?.birthDayData?.data.length !== 0 && (
                          <span
                            className="d-flex px-1 align-items-center justify-content-center bg-warning text-black fw-bold position-absolute top-0 end-0 m-n1"
                            style={{
                              minWidth: 14,
                              height: 14,
                              fontSize: 10,
                              borderRadius: "10px",
                            }}
                          >
                            1
                          </span>
                        )}
                      <i className="fa fa-sm fa-bell"></i>
                    </Link>
                    <div
                      onClick={handleLogOut}
                      className="btn d-flex align-items-center btn-outline-danger py-0 px-2"
                    >
                      <i className="fa fa-sm fa-sign-out-alt"></i>
                    </div>
                  </>
                ) : (
                  <div
                    onClick={handleLogOut}
                    className="btn d-flex align-items-center justify-content-center btn-danger py-1 gap-2 px-2 w-100"
                  >
                    Çıkış Yap
                    <i className="fa fa-sm fa-sign-out-alt"></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;
