import React, { useEffect, useRef } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const Fancybox = ({ options, delegate, children, className }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const delegateSelector = delegate || "[data-fancybox]";
    const fancyboxOptions = options || {};

    NativeFancybox.bind(container, delegateSelector, fancyboxOptions);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  }, [options, delegate]);

  return (
    <div className={className} ref={containerRef}>
      {children}
    </div>
  );
};

export default Fancybox;
