import React, { useContext, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import apiClient from "../../ApiClient";
import toastMessage from "../../components/ToastMessage";
import { AppSettings } from "../../config/app-settings";
import AddReport from "../strateji-haritasi/addReport";

export default function AnketLink() {
  const context = useContext(AppSettings);

  useEffect(() => {
    context.setThemePanel(false);
    context.setAppHeaderNone(true);
    context.setAppScrollButton(false);
    context.setAppWeather(false);
    context.setAppSidebarNone(true);
    context.setAppFooterFull(true);
    context.setAppContentClass("login-page");

    return function cleanUp() {
      context.setThemePanel(true);
      context.setAppHeaderNone(false);
      context.setAppScrollButton(true);
      context.setAppWeather(true);
      context.setAppFooterFull(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("login-page");
    };
    // eslint-disable-next-line
  }, []);

  // Slug Survey ID
  const [slug, setSlug] = useState("");
  const [phone, setPhone] = useState("");
  const [cleanPhone, setCleanPhone] = useState("");
  const [surveyData, setSurveyData] = useState(null);
  const [failedAttempts, setFailedAttempts] = useState(0);

  useEffect(() => {
    const path = window.location.pathname;
    const pathParts = path.split("/").filter(Boolean);
    const extractedSlug = pathParts[pathParts.length - 1];
    setSlug(extractedSlug);
  }, []);

  async function handlePhoneCheck() {
    const cleanedPhone = phone.replace(/[\s_()]/g, "");

    if (cleanedPhone.length !== 10) {
      return; // Exit if the phone number is not the required length
    }

    const payloadPhone = "+90" + cleanedPhone;
    setCleanPhone(payloadPhone);

    try {
      const response = await apiClient.customPost(
        `/survey-check`,
        {
          report_id: parseInt(slug),
          phone: payloadPhone,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response?.status) {
        document.getElementById("reportQuestionsModalButton").click();
        setSurveyData(response?.survey);
      } else {
        setFailedAttempts((prev) => prev + 1);
        if (failedAttempts + 1 >= 3) {
          window.location.href = "/giris-yap";
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    slug && (
      <div className="container">
        <div className="d-flex align-items-end justify-content-center gap-2">
          <div className="w-100 mt-4" style={{ maxWidth: 200 }}>
            <span className="w-100 d-flex align-items-center justify-content-between mb-1">
              {context?.t?.phone_control}
              {failedAttempts !== 0 && (
                <span className="text-danger fw-bold">{failedAttempts}/3</span>
              )}
            </span>
            <InputMask
              mask="599 999 99 99"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            >
              {(inputProps) => (
                <input {...inputProps} type="tel" className="form-control" />
              )}
            </InputMask>
          </div>
          <button
            type="button"
            className="btn btn-outline-theme"
            onClick={() => handlePhoneCheck()}
          >
            {context?.t?.send}
          </button>

          <AddReport
            assigmentSelect={slug}
            surveyData={surveyData}
            surveyInnerPage={true}
            phone={cleanPhone}
          />

          <button
            type="button"
            className="d-none btn btn-outline-theme btn-lg"
            data-bs-toggle="modal"
            data-bs-target="#reportQuestionsModal"
            id="reportQuestionsModalButton"
          ></button>

          <button
            type="button"
            className="d-none"
            id="report-detail-modal-button"
            data-bs-toggle="modal"
            data-bs-target="#reportDetailModal"
          >
            Report Detail
          </button>
        </div>
      </div>
    )
  );
}
