import { useState, useEffect, useRef, useContext } from "react";

import Select from "react-select";
import ReactECharts from "echarts-for-react";

import apiClient from "../../../ApiClient";
import { AppSettings } from "../../../config/app-settings.js";
import { Card, CardBody } from "../../../components/card/card";

import DynamicCard from "../../../components/DynamicCard.jsx";
import PageFilter from "../../../components/PageFilter.jsx";
import HazirRaporlar from "../../hazir-raporlar/index.jsx";
import CardBinaHane from "../../../components/info-cards/CardBinaHane.jsx";

const SokakAnalizi = () => {
  const context = useContext(AppSettings);

  // Filter
  const [cityFilter, setCityFilter] = useState(null);
  const [districtFilter, setDistrictFilter] = useState(null);

  // Data
  const [data, setData] = useState(null);
  const [fileColumns, setFileColumns] = useState(null);

  // Street and Neighborhood
  const [activeStreetName, setActiveStreetName] = useState("");
  const [activeNeighborhoodName, setActiveNeighborhoodName] = useState("");
  const [streetArray, setStreetArray] = useState(null);
  const [selectStreetValue, setSelectStreetValue] = useState(null);

  // Charts
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);

  // Info Charts
  const [yasGrubunaGoreChartOptions, setYasGrubunaGoreChartOptions] =
    useState(null);
  const [secmenProfiliChartOptions, setSecmenProfiliChartOptions] =
    useState(null);
  const [hemsehrilikDurumuChartOptions, setHemsehrilikDurumuChartOptions] =
    useState(null);

  // Get File Columns
  async function getFileColumns(name) {
    setFileColumns(null);

    try {
      const response = await apiClient.get(`/columns/${name}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response) {
        setFileColumns(response.data.columns);
      }
    } catch (error) {
      setFileColumns([]);
      console.error("Get File Types Error", error);
    }
  }

  useEffect(() => {
    getFileColumns(`bireysel-data-customer-${context?.me?.customer?.id}`);
  }, []);

  // Get Data
  async function fetchData() {
    if (cityFilter && districtFilter) {
      setData(null);

      // const dataColumn = fileColumns.map((item) => ({
      //   key: item,
      //   value: "select",
      // }));

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: "Sokak Analizi",
            type: `bireysel-data-customer-${context?.me?.customer?.id}`,
            description: "",
            customer: null,
            status: true,
            order: 1,
            sql: {
              column: [
                {
                  key: "Adres Il",
                  value: "select",
                },
                {
                  key: "Adres Ilce",
                  value: "select",
                },
                {
                  key: "Adres Mahalle",
                  value: "select",
                },
                {
                  key: "Adres Sokak",
                  value: "select",
                },
                {
                  key: "Dogum Tarihi",
                  value: "select",
                },
                {
                  key: "Yaş Grubu",
                  value: "select",
                },
                {
                  key: "Secmen Profili",
                  value: "select",
                },
                {
                  key: "Memleket Il",
                  value: "select",
                },
                {
                  key: "Memleket Ilce",
                  value: "select",
                },
              ],
              condition: [
                cityFilter
                  ? {
                      key: "Adres Il",
                      value: context
                        .removeTurkishCharacters(cityFilter.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                districtFilter
                  ? {
                      key: "Adres Ilce",
                      value: context
                        .removeTurkishCharacters(districtFilter.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
              ].filter((item) => item !== null),
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response) {
          setData(response);

          if (response.length !== 0) {
            const result = {
              name: "SILE",
              children: [],
            };
            const sokakArray = [];
            const mahalleMap = new Map();
            const sokakMap = new Map();
            const filteredData = response.filter(
              (item) => item["adres ilce"] === "SILE"
            );
            filteredData.forEach((item) => {
              const mahalle = item["adres mahalle"];
              const sokak = item["adres sokak"];
              if (!mahalleMap.has(mahalle)) {
                sokakMap.set(mahalle, {
                  name: mahalle,
                  children: [],
                });
                mahalleMap.set(mahalle, {
                  name: mahalle,
                  neighborhood: true,
                  children: [],
                });
                result.children.push(mahalleMap.get(mahalle));
                sokakArray.push(sokakMap.get(mahalle));
              }
              const sokakSet = sokakMap.get(mahalle).children;
              if (!sokakSet.some((s) => s.name === sokak)) {
                sokakSet.push({ name: sokak, street: true });
              }
            });
            result.children.sort((a, b) => a.name.localeCompare(b.name));
            sokakArray.sort((a, b) => a.name.localeCompare(b.name));
            sokakArray.forEach((item) => {
              item.children.sort((a, b) => a.name.localeCompare(b.name));
            });
            setStreetArray(sokakArray);
            setChartData(result);
          }
        }
      } catch (error) {
        setData([]);
        console.error(error);
      }
    }
  }

  useEffect(() => {
    if (fileColumns && cityFilter && districtFilter) {
      fetchData();
    }
  }, [fileColumns, cityFilter, districtFilter]);

  // Get Info Chart
  function getInfoChart(streetName, streetSelect, neighborhoodName) {
    setActiveStreetName(streetName);
    setSelectStreetValue(streetSelect);

    const activeStreetData = data.filter(
      (item) =>
        (!streetName || item["adres sokak"] === streetName) &&
        item["adres mahalle"] === neighborhoodName
    );

    // Yaş Grubuna Göre Chart
    // Kuşak aralıklarını tanımla
    const generations = [
      { name: context?.t?.en_buyuk_nesil, start: 1910, end: 1924 },
      { name: context?.t?.sessiz_kusak, start: 1925, end: 1945 },
      { name: context?.t?.baby_boomer_kusagi, start: 1946, end: 1960 },
      { name: context?.t?.y_kusagi, start: 1961, end: 1980 },
      { name: context?.t?.x_kusagi, start: 1981, end: 1995 },
      { name: context?.t?.z_kusagi, start: 1996, end: 2012 },
    ];

    // Kuşak adlarının sıralamasını tanımla (bu sıralama sabit olarak tanımlanır)
    const generationOrder = [
      context?.t?.en_buyuk_nesil,
      context?.t?.sessiz_kusak,
      context?.t?.baby_boomer_kusagi,
      context?.t?.y_kusagi,
      context?.t?.x_kusagi,
      context?.t?.z_kusagi,
    ];

    // Yardımcı fonksiyon: yılı kuşak adını bul
    const findGeneration = (year) => {
      for (const generation of generations) {
        if (year >= generation.start && year <= generation.end) {
          return generation.name;
        }
      }
      return null;
    };

    // Kuşak sayımlarını tut
    const generationCounts = {};

    // Verilen tarihlerden yılları al ve kuşakları say
    activeStreetData.forEach((item) => {
      const year = item["dogum tarihi"].split("-")[0];
      const generation = findGeneration(year);

      if (generation) {
        if (!generationCounts[generation]) {
          generationCounts[generation] = 0;
        }
        generationCounts[generation]++;
      }
    });

    // Sonuçları sıralı olarak formatla
    const result = [[], []];

    // Kuşak adlarının sıralı dizisine göre sayıları ve isimleri ekle
    generationOrder.forEach((generation) => {
      if (generationCounts[generation] !== undefined) {
        result[0].push(generation);
        result[1].push(generationCounts[generation]);
      }
    });

    setYasGrubunaGoreChartOptions(result);

    // Secmen Profili Chart
    const secmenProfiliMap = new Map();
    const secmenProfiliOptions = [];

    activeStreetData.forEach((item) => {
      const value = item["secmen profili"];
      secmenProfiliMap.set(value, (secmenProfiliMap.get(value) || 0) + 1);
    });

    secmenProfiliMap.forEach((count, value) => {
      const percentage = Math.floor((count / activeStreetData.length) * 100);
      secmenProfiliOptions.push({
        value: percentage,
        name: value,
      });
    });

    setSecmenProfiliChartOptions(secmenProfiliOptions);

    // Hemşehrilik Durumu Chart
    const hemsehrilikDurumuMap = new Map();
    const hemsehrilikDurumuOptions = [];

    // Verileri işleyin ve hemşehrilik durumlarını sayın
    activeStreetData.forEach((item) => {
      const value = item["memleket il"] + " - " + item["memleket ilce"];
      hemsehrilikDurumuMap.set(
        value,
        (hemsehrilikDurumuMap.get(value) || 0) + 1
      );
    });

    // Map verilerini bir diziye dönüştürün
    hemsehrilikDurumuMap.forEach((count, value) => {
      hemsehrilikDurumuOptions.push({
        value: count,
        category: value,
      });
    });

    // İlk 30 öğeyi seçin
    const first30Options = hemsehrilikDurumuOptions.slice(0, 30);

    // Chart options'ı ayarlayın
    setHemsehrilikDurumuChartOptions(first30Options);
  }

  // Handle Street Select
  function handleSelectStreet(e) {
    getInfoChart(e.label, e, activeNeighborhoodName);

    handleHizmetSikayetler("HIZMET", e.label, activeNeighborhoodName);
    handleHizmetSikayetler("SIKAYET", e.label, activeNeighborhoodName);

    handleSendikalar(e.label, activeNeighborhoodName);
    handleDernekler(e.label, activeNeighborhoodName);
    handleMuhtarlar(e.label, activeNeighborhoodName);
  }

  // First Chart
  useEffect(() => {
    if (chartData) {
      const myChart = chartRef.current.getEchartsInstance();

      myChart.showLoading();

      myChart.hideLoading();

      myChart.setOption({
        darkMode: true,
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: [
          {
            type: "tree",
            data: [chartData],
            top: "1%",
            left: "7%",
            bottom: "1%",
            right: "20%",
            symbolSize: 0,
            lineStyle: {
              color: "#3cd2a540",
            },
            label: {
              position: "left",
              verticalAlign: "middle",
              align: "right",
              fontSize: 10,
              textStyle: {
                color: "#fff",
              },
            },
            leaves: {
              label: {
                position: "right",
                verticalAlign: "middle",
                align: "left",
              },
              lineStyle: {
                color: "#3cd2a540",
              },
            },
            expandAndCollapse: true,
            initialTreeDepth: 2,
          },
        ],
      });

      myChart.on("click", function (params) {
        if (params.data.neighborhood) {
          setActiveNeighborhoodName(params.data.name);
          setActiveStreetName("");

          const activeNeighborhoodStreet = streetArray.find(
            (item) => item.name === params.data.name
          );
          const activeNeighborhood = chartData.children.find(
            (node) => node.name === params.data.name
          );

          if (activeNeighborhood.children.length > 0) {
            activeNeighborhood.children = [];
          } else {
            chartData.children.forEach((element) => {
              element.children = [];
            });
            activeNeighborhood.children = activeNeighborhoodStreet.children;
          }

          myChart.setOption({
            series: [
              {
                data: [chartData],
              },
            ],
          });
        }

        if (params.data.neighborhood) {
          getInfoChart(null, null, params.data.name);
          handleHizmetSikayetler("HIZMET", null, params.data.name);
          handleHizmetSikayetler("SIKAYET", null, params.data.name);
          handleMuhtarlar(null, params.data.name);
          handleDernekler(null, params.data.name);
          handleSendikalar(null, params.data.name);
        }

        if (params.data.street) {
          getInfoChart(
            params.data.name,
            {
              label: params.data.name,
              value: params.data.name,
            },
            activeNeighborhoodName
          );
          handleHizmetSikayetler(
            "HIZMET",
            params.data.name,
            activeNeighborhoodName
          );
          handleHizmetSikayetler(
            "SIKAYET",
            params.data.name,
            activeNeighborhoodName
          );
          handleMuhtarlar(params.data.name, activeNeighborhoodName);
          handleDernekler(params.data.name, activeNeighborhoodName);
          handleSendikalar(params.data.name, activeNeighborhoodName);
        }
      });
    }
  }, [chartData, activeNeighborhoodName]);

  // Hizmet Şikayet
  const [hizmetData1, setHizmetData1] = useState([]);
  const [hizmetData2, setHizmetData2] = useState([]);
  const [hizmetData3, setHizmetData3] = useState([]);

  const [sikayetData1, setSikayetData1] = useState([]);
  const [sikayetData2, setSikayetData2] = useState([]);
  const [sikayetData3, setSikayetData3] = useState([]);

  const handleHizmetSikayetler = async (type, streetName, neighborhoodName) => {
    setHizmetData1(null);
    setHizmetData2(null);
    setHizmetData3(null);

    setSikayetData1(null);
    setSikayetData2(null);
    setSikayetData3(null);

    if (type && type !== "" && neighborhoodName && neighborhoodName !== "") {
      const conditionData = [
        {
          key: "TUR",
          value: type,
          operator: "=",
          type: "and",
        },
        {
          key: "HIZMET-SIKAYET-ALAN-MAHALLE",
          value: neighborhoodName,
          operator: "=",
          type: "and",
        },
        streetName
          ? {
              key: "HIZMET-SIKAYET-ALAN-SOKAK",
              value: streetName,
              operator: "=",
              type: "and",
            }
          : null,
      ].filter((item) => item !== null);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 24,
            title: "-",
            type: "hizmet-sikayet-data",
            description: "",
            status: true,
            customer: null,
            order: 1,
            sql: {
              column: [
                {
                  key: "HIZMET_SIKAYET_ANA_KATEGORI",
                  value: "select",
                },
                {
                  key: "HIZMET_SIKAYET_ANA_KATEGORI",
                  value: "count",
                },
                {
                  key: "HIZMET_SIKAYET_ALT_KATEGORI_1",
                  value: "select",
                },
                {
                  key: "HIZMET_SIKAYET_ALT_KATEGORI_1",
                  value: "count",
                },
                {
                  key: "HIZMET_SIKAYET_ALT_KATEGORI_2",
                  value: "select",
                },
                {
                  key: "HIZMET_SIKAYET_ALT_KATEGORI_2",
                  value: "count",
                },
              ],
              condition: conditionData,
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (type === "HIZMET") {
          if (response.length !== 0) {
            const anaKategoriArray = response.map((item) => ({
              name: item.hizmet_sikayet_ana_kategori || "-",
              value: parseInt(item.hizmet_sikayet_ana_kategori_count || "0"),
            }));

            const altKategori1Array = response.map((item) => ({
              name: item.hizmet_sikayet_alt_kategori_1 || "-",
              value: parseInt(item.hizmet_sikayet_alt_kategori_1_count || "0"),
            }));

            const altKategori2Array = response.map((item) => ({
              name: item.hizmet_sikayet_alt_kategori_2 || "-",
              value: parseInt(item.hizmet_sikayet_alt_kategori_2_count || "0"),
            }));

            setHizmetData1(anaKategoriArray);
            setHizmetData2(altKategori1Array);
            setHizmetData3(altKategori2Array);
          } else {
            setHizmetData1([]);
            setHizmetData2([]);
            setHizmetData3([]);
          }
        }

        if (type === "SIKAYET") {
          if (response.length !== 0) {
            const anaKategoriArray = response.map((item) => ({
              name: item.hizmet_sikayet_ana_kategori || "-",
              value: parseInt(item.hizmet_sikayet_ana_kategori_count || "0"),
            }));

            const altKategori1Array = response.map((item) => ({
              name: item.hizmet_sikayet_alt_kategori_1 || "-",
              value: parseInt(item.hizmet_sikayet_alt_kategori_1_count || "0"),
            }));

            const altKategori2Array = response.map((item) => ({
              name: item.hizmet_sikayet_alt_kategori_2 || "-",
              value: parseInt(item.hizmet_sikayet_alt_kategori_2_count || "0"),
            }));

            setSikayetData1(anaKategoriArray);
            setSikayetData2(altKategori1Array);
            setSikayetData3(altKategori2Array);
          } else {
            setSikayetData1([]);
            setSikayetData2([]);
            setSikayetData3([]);
          }
        }
      } catch (error) {
        console.error(error);

        if (type === "HIZMET") {
          setHizmetData1([]);
          setHizmetData2([]);
          setHizmetData3([]);
        }

        if (type === "SIKAYET") {
          setSikayetData1([]);
          setSikayetData2([]);
          setSikayetData3([]);
        }
      }
    }
  };

  // Rapor
  const [raporFilter, setRaporFilter] = useState(false);

  function handleRapor(city, district, neighborhood, street) {
    setRaporFilter({
      city: city,
      district: district,
      neighborhood: { value: neighborhood, label: neighborhood },
      street: street,
    });

    setTimeout(() => {
      const section = document.getElementById(`sokak-analizi-sokak-raporu`);
      if (section) {
        const sectionTop = section.offsetTop;
        window.scrollTo({
          top: sectionTop,
          behavior: "smooth",
        });
      }
    }, 300);
  }

  // Muhtarlar
  const [muhtarlikData, setMuhtarlikData] = useState(false);

  const handleMuhtarlar = async (streetName, neighborhoodName) => {
    setMuhtarlikData(null);

    if (neighborhoodName && neighborhoodName !== "") {
      const conditionData = [
        {
          key: "Il adı",
          value: cityFilter.label,
          operator: "=",
          type: "and",
        },
        {
          key: "IlCe Adı",
          value: districtFilter.label,
          operator: "=",
          type: "and",
        },
        {
          key: "Mahalle Adı",
          value: neighborhoodName,
          operator: "=",
          type: "and",
        },
      ].filter((item) => item !== null);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 24,
            title: "-",
            type: "muhtarlıklar",
            description: "",
            status: true,
            customer: null,
            order: 1,
            sql: {
              column: [
                {
                  key: "Muhtar Adı",
                  value: "select",
                },
                {
                  key: "MuhtarTelefonu",
                  value: "select",
                },
                {
                  key: "Muhtarlık Adı",
                  value: "select",
                },
                {
                  key: "Il adı",
                  value: "select",
                },
                {
                  key: "IlCe Adı",
                  value: "select",
                },
                {
                  key: "Mahalle Adı",
                  value: "select",
                },
                {
                  key: "Adres",
                  value: "select",
                },
                {
                  key: "Longitude",
                  value: "select",
                },
                {
                  key: "Latitude",
                  value: "select",
                },
              ],
              condition: conditionData,
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setMuhtarlikData(response);
      } catch (error) {
        setMuhtarlikData([]);
        console.error(error);
      }
    }
  };

  // Dernekler
  const [derneklerData, setDerneklerData] = useState(false);

  const handleDernekler = async (streetName, neighborhoodName) => {
    setDerneklerData(null);

    if (neighborhoodName && neighborhoodName !== "") {
      const conditionData = [
        {
          key: "Sehir",
          value: cityFilter.label,
          operator: "=",
          type: "and",
        },
        {
          key: "Ilce",
          value: districtFilter.label,
          operator: "=",
          type: "and",
        },
        {
          key: "Mahalle",
          value: neighborhoodName,
          operator: "=",
          type: "and",
        },
      ].filter((item) => item !== null);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 24,
            title: "-",
            type: "dernekler",
            description: "",
            status: true,
            customer: null,
            order: 1,
            sql: {
              column: [
                {
                  key: "Sehir",
                  value: "select",
                },
                {
                  key: "Ilce",
                  value: "select",
                },
                {
                  key: "Mahalle",
                  value: "select",
                },
                {
                  key: "Kurum-Adres",
                  value: "select",
                },
                {
                  key: "Faaliyet-Alanı",
                  value: "select",
                },
                {
                  key: "Kurum-Adi",
                  value: "select",
                },
                {
                  key: "Telefon",
                  value: "select",
                },
                {
                  key: "Detayli-Faaliyet-Alani",
                  value: "select",
                },
              ],
              condition: conditionData,
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setDerneklerData(response);
      } catch (error) {
        setDerneklerData([]);
        console.error(error);
      }
    }
  };

  // Sendikalar
  const [sendikalarData, setSendikalarData] = useState(false);

  const handleSendikalar = async (streetName, neighborhoodName) => {
    setSendikalarData(null);

    if (neighborhoodName && neighborhoodName !== "") {
      const conditionData = [
        {
          key: "Il",
          value: cityFilter.label,
          operator: "=",
          type: "and",
        },
        {
          key: "Ilce",
          value: districtFilter.label,
          operator: "=",
          type: "and",
        },
        {
          key: "Mahalle",
          value: neighborhoodName,
          operator: "=",
          type: "and",
        },
      ].filter((item) => item !== null);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 24,
            title: "-",
            type: "sendikalar",
            description: "",
            status: true,
            customer: null,
            order: 1,
            sql: {
              column: [
                {
                  key: "Sayı",
                  value: "select",
                },
                {
                  key: "Il",
                  value: "select",
                },
                {
                  key: "Ilce",
                  value: "select",
                },
                {
                  key: "Mahalle",
                  value: "select",
                },
                {
                  key: "Sendika-Adresi",
                  value: "select",
                },
                {
                  key: "Sendika-is-kolu",
                  value: "select",
                },
                {
                  key: "Sendika-adi",
                  value: "select",
                },
              ],
              condition: conditionData,
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setSendikalarData(response);
      } catch (error) {
        setSendikalarData([]);
        console.error(error);
      }
    }
  };

  return (
    <div className="col-12 zoom-item mb-5 pb-5">
      <div className="row g-3 mb-4">
        <PageFilter
          setCityFilter={setCityFilter}
          setDistrictFilter={setDistrictFilter}
          neighbourhood={false}
          street={false}
        />

        <div className="col-12">
          <Card>
            <CardBody>
              {cityFilter && districtFilter ? (
                data ? (
                  data.length !== 0 ? (
                    <>
                      <div className="row align-items-center tree-chart">
                        <div className="col overflow-hidden">
                          <ReactECharts
                            option={{}}
                            ref={chartRef}
                            className="w-100 h-100"
                          />
                        </div>

                        {activeNeighborhoodName && (
                          <span
                            className="h3 fw-bold text-white col d-block text-center h-100"
                            style={{
                              flex: "none",
                              width: "auto",
                              writingMode: "vertical-lr",
                              transform: "rotate(-180deg)",
                              borderLeft: "1px solid #60676e",
                              borderRight: "1px solid #60676e",
                            }}
                          >
                            {activeStreetName || activeNeighborhoodName}
                          </span>
                        )}

                        {streetArray && activeNeighborhoodName && (
                          <div className="col-12 col-xl-3 h-100">
                            <div className="row">
                              <div className="col">
                                <Select
                                  options={streetArray
                                    .find(
                                      (item) =>
                                        item.name === activeNeighborhoodName
                                    )
                                    .children.map(function (item) {
                                      return {
                                        label: item.name,
                                        value: item.name,
                                      };
                                    })}
                                  value={selectStreetValue}
                                  classNamePrefix="react-select"
                                  noOptionsMessage={() => context?.t?.no_option}
                                  placeholder={context?.t?.choose_street}
                                  className="mb-3"
                                  onChange={handleSelectStreet}
                                />
                              </div>

                              <div className="w-auto flex-none">
                                <button
                                  type="button"
                                  className="btn btn-outline-theme"
                                  onClick={() =>
                                    handleRapor(
                                      cityFilter,
                                      districtFilter,
                                      activeNeighborhoodName,
                                      selectStreetValue
                                    )
                                  }
                                >
                                  {context?.t?.sokak_raporu}
                                </button>
                              </div>
                            </div>

                            <div
                              className="row flex-xl-column g-3"
                              style={{ height: "calc(100% - 50px)" }}
                            >
                              <div className="col overflow-hidden">
                                <CardBinaHane
                                  width="w-100"
                                  heightFull={true}
                                  city={cityFilter?.label}
                                  district={districtFilter?.label}
                                  neighbourhood={activeNeighborhoodName}
                                  street={activeStreetName}
                                  graphic={1}
                                />
                              </div>
                              {/* <div className="col overflow-hidden">
                                <DynamicCard
                                  height="h-full"
                                  events={false}
                                  item={{
                                    title: "KUSAKLARA GORE SECMEN SAYISI",
                                    options: {
                                      options: {
                                        grid: {
                                          bottom: "20%",
                                        },
                                        xAxis: [
                                          {
                                            data: yasGrubunaGoreChartOptions[0],
                                            type: "category",
                                            axisLabel: {
                                              rotate: 45,
                                              textStyle: {
                                                color: "#fff",
                                                fontSize: 10,
                                              },
                                            },
                                          },
                                        ],
                                        yAxis: [
                                          {
                                            type: "value",
                                            axisLabel: {
                                              textStyle: {
                                                color: "#fff",
                                              },
                                            },
                                          },
                                        ],
                                        series: [
                                          {
                                            data: yasGrubunaGoreChartOptions[1],
                                            name: null,
                                            type: "bar",
                                            itemStyle: {
                                              color: context?.chartColor,
                                            },
                                            markPoint: {
                                              data: [
                                                {
                                                  name: "Max",
                                                  type: "max",
                                                },
                                                {
                                                  name: "Min",
                                                  type: "min",
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                        toolbox: {
                                          show: true,
                                          feature: {
                                            restore: {
                                              show: false,
                                            },
                                            magicType: {
                                              show: true,
                                              type: ["line", "bar"],
                                            },
                                            saveAsImage: {
                                              show: false,
                                            },
                                          },
                                          iconStyle: {
                                            borderColor: "#fff",
                                          },
                                        },
                                        tooltip: {
                                          trigger: "axis",
                                        },
                                        darkMode: true,
                                        visualMap: {
                                          max: 50,
                                          min: 0,
                                          show: false,
                                          inRange: {
                                            colorLightness: [0.2, 1],
                                          },
                                        },
                                        calculable: true,
                                      },
                                    },
                                  }}
                                />
                              </div> */}
                              {/* <div
                                className="col overflow-hidden"
                                style={{ height: 300 }}
                              >
                                <DynamicCard
                                  events={false}
                                  height={300}
                                  item={{
                                    title: "SEÇMEN PROFİLİ",
                                    options: {
                                      options: {
                                        series: [
                                          {
                                            data: secmenProfiliChartOptions,
                                            name: null,
                                            type: "pie",
                                            label: {
                                              textStyle: {
                                                fontSize: 15,
                                              },
                                            },
                                            radius: "50%",
                                            itemStyle: {
                                              color: context?.chartColor,
                                            },
                                          },
                                        ],
                                        tooltip: {
                                          trigger: "item",
                                        },
                                        darkMode: true,
                                        visualMap: {
                                          min: Math.min(
                                            ...secmenProfiliChartOptions.map(
                                              (obj) => obj.value
                                            )
                                          ),
                                          max: Math.max(
                                            ...secmenProfiliChartOptions.map(
                                              (obj) => obj.value
                                            )
                                          ),
                                          show: false,
                                          inRange: {
                                            colorLightness: [0.2, 1],
                                          },
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div> */}
                              <div
                                className="col overflow-hidden"
                                style={{ flex: 1.5 }}
                              >
                                <DynamicCard
                                  events={false}
                                  height="h-full"
                                  item={{
                                    chart: 3,
                                    title: context?.t?.hemsehrilik_durumu,
                                    options: {
                                      options: hemsehrilikDurumuChartOptions,
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <span className="d-block text-center">
                      {context?.t?.no_data_available}
                    </span>
                  )
                ) : (
                  <div className="spinner-border text-theme"></div>
                )
              ) : (
                <span className="d-block text-center">
                  {context?.t?.il_ve_ilce_secmelisiniz}
                </span>
              )}
            </CardBody>
          </Card>
        </div>
      </div>

      {cityFilter && districtFilter && data && data.length !== 0 && (
        <>
          <div className="row g-4 mt-4">
            <div className="col-12">
              <h4 className="text-center">{context?.t?.muhtarlik}</h4>
            </div>

            <div className="col-12">
              {muhtarlikData ? (
                <Card>
                  <CardBody>
                    {muhtarlikData.length !== 0 ? (
                      <div className="dataTables_wrapper dt-bootstrap5">
                        <div className="dataTables_scroll">
                          <div className="dataTables_scrollBody table-responsive">
                            <table className="table text-nowrap w-100 dataTable mb-0">
                              <thead>
                                <tr>
                                  <th className="px-1 text-center">
                                    {context?.t?.city}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.district}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.neighborhood}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.muhtarlik_adi}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.muhtar_adi}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.muhtar_telefon}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.address}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.location}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {muhtarlikData.map((item, index) => (
                                  <tr key={index}>
                                    <td className="py-3 text-center">
                                      {item["il adı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["ilce adı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["mahalle adı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["muhtarlık adı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["muhtar adı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["muhtartelefonu"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["adres"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["latitude"] && item["longitude"] ? (
                                        <a
                                          href={`https://www.google.com/maps?q=${item[
                                            "latitude"
                                          ].replace(",", ".")},${item[
                                            "longitude"
                                          ].replace(",", ".")}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {context?.t?.location}
                                        </a>
                                      ) : (
                                        context?.t?.unknown
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span>{context?.t?.no_data_available}</span>
                    )}
                  </CardBody>
                </Card>
              ) : (
                <div className="spinner-border text-theme"></div>
              )}
            </div>
          </div>

          <div className="row g-4 mt-4">
            <div className="col-12">
              <h4 className="text-center">{context?.t?.dernekler}</h4>
            </div>

            <div className="col-12">
              {derneklerData ? (
                <Card>
                  <CardBody>
                    {derneklerData.length !== 0 ? (
                      <div className="dataTables_wrapper dt-bootstrap5">
                        <div className="dataTables_scroll">
                          <div
                            className="dataTables_scrollBody table-responsive"
                            style={{ maxHeight: 500 }}
                          >
                            <table className="table text-nowrap w-100 dataTable mb-0">
                              <thead>
                                <tr>
                                  <th className="px-1 text-center">
                                    {context?.t?.city}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.district}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.neighborhood}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.kurum_adi}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.phone}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.faaliyet_alani}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.detayli_faaliyet_alani}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.kurum_adres}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {derneklerData.map((item, index) => (
                                  <tr key={index}>
                                    <td className="py-3 text-center">
                                      {item["sehir"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["ilce"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["mahalle"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["kurum-adi"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["telefon"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["faaliyet-alanı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["detayli-faaliyet-alani"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["kurum-adres"]}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span>{context?.t?.no_data_available}</span>
                    )}
                  </CardBody>
                </Card>
              ) : (
                <div className="spinner-border text-theme"></div>
              )}
            </div>
          </div>

          <div className="row g-4 mt-4">
            <div className="col-12">
              <h4 className="text-center">{context?.t?.sendikalar}</h4>
            </div>

            <div className="col-12">
              {sendikalarData ? (
                <Card>
                  <CardBody>
                    {sendikalarData.length !== 0 ? (
                      <div className="dataTables_wrapper dt-bootstrap5">
                        <div className="dataTables_scroll">
                          <div
                            className="dataTables_scrollBody table-responsive"
                            style={{ maxHeight: 500 }}
                          >
                            <table className="table text-nowrap w-100 dataTable mb-0">
                              <thead>
                                <tr>
                                  <th className="px-1 text-center">
                                    {context?.t?.city}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.district}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.neighborhood}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.sendika_adi}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.is_kolu}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.address}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.integer}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {sendikalarData.map((item, index) => (
                                  <tr key={index}>
                                    <td className="py-3 text-center">
                                      {item["il"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["ilce"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["mahalle"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["sendika-adresi"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["sendika-is-kolu"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["sendika-adi"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["sayı"]}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span>{context?.t?.no_data_available}</span>
                    )}
                  </CardBody>
                </Card>
              ) : (
                <div className="spinner-border text-theme"></div>
              )}
            </div>
          </div>

          {activeNeighborhoodName && (
            <>
              <div className="row g-4 mb-4 mt-4">
                <div className="col-12">
                  <div className="row g-4">
                    <div className="col-12">
                      <h4 className="text-center">
                        {context?.t?.istenilen_hizmetler}
                      </h4>
                    </div>

                    <div className="col-12 col-md-4">
                      <DynamicCard
                        events={false}
                        data={hizmetData1}
                        allEvents={false}
                        item={{
                          title: context?.t?.main_category,
                          options: {
                            options: {
                              series: [
                                {
                                  data: hizmetData1,
                                  name: null,
                                  type: "pie",
                                  label: {
                                    textStyle: {
                                      fontSize: 15,
                                    },
                                  },
                                  radius: "50%",
                                  itemStyle: {
                                    color: context?.chartColor,
                                  },
                                },
                              ],
                              tooltip: {
                                trigger: "item",
                              },
                              darkMode: true,
                              visualMap: context?.chartColor
                                ? {
                                    min:
                                      hizmetData1 &&
                                      Math.min(
                                        ...hizmetData1.map((obj) => obj.value)
                                      ),
                                    max:
                                      hizmetData1 &&
                                      Math.max(
                                        ...hizmetData1.map((obj) => obj.value)
                                      ),
                                    show: false,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  }
                                : false,
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <DynamicCard
                        events={false}
                        data={hizmetData2}
                        allEvents={false}
                        item={{
                          title: context?.t?.sub_category_1,
                          options: {
                            options: {
                              series: [
                                {
                                  data: hizmetData2,
                                  name: null,
                                  type: "pie",
                                  label: {
                                    textStyle: {
                                      fontSize: 15,
                                    },
                                  },
                                  radius: "50%",
                                  itemStyle: {
                                    color: context?.chartColor,
                                  },
                                },
                              ],
                              tooltip: {
                                trigger: "item",
                              },
                              darkMode: true,
                              visualMap: context?.chartColor
                                ? {
                                    min:
                                      hizmetData2 &&
                                      Math.min(
                                        ...hizmetData2.map((obj) => obj.value)
                                      ),
                                    max:
                                      hizmetData2 &&
                                      Math.max(
                                        ...hizmetData2.map((obj) => obj.value)
                                      ),
                                    show: false,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  }
                                : false,
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <DynamicCard
                        events={false}
                        data={hizmetData3}
                        allEvents={false}
                        item={{
                          title: context?.t?.sub_category_2,
                          options: {
                            options: {
                              series: [
                                {
                                  data: hizmetData3,
                                  name: null,
                                  type: "pie",
                                  label: {
                                    textStyle: {
                                      fontSize: 15,
                                    },
                                  },
                                  radius: "50%",
                                  itemStyle: {
                                    color: context?.chartColor,
                                  },
                                },
                              ],
                              tooltip: {
                                trigger: "item",
                              },
                              darkMode: true,
                              visualMap: context?.chartColor
                                ? {
                                    min:
                                      hizmetData3 &&
                                      Math.min(
                                        ...hizmetData3.map((obj) => obj.value)
                                      ),
                                    max:
                                      hizmetData3 &&
                                      Math.max(
                                        ...hizmetData3.map((obj) => obj.value)
                                      ),
                                    show: false,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  }
                                : false,
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row g-4 mt-1">
                    <div className="col-12">
                      <h4 className="text-center">
                        {context?.t?.iletilen_sikayetler}
                      </h4>
                    </div>

                    <div className="col-12 col-md-4">
                      <DynamicCard
                        events={false}
                        data={sikayetData1}
                        allEvents={false}
                        item={{
                          title: context?.t?.main_category,
                          options: {
                            options: {
                              series: [
                                {
                                  data: sikayetData1,
                                  name: null,
                                  type: "pie",
                                  label: {
                                    textStyle: {
                                      fontSize: 15,
                                    },
                                  },
                                  radius: "50%",
                                  itemStyle: {
                                    color: context?.chartColor,
                                  },
                                },
                              ],
                              tooltip: {
                                trigger: "item",
                              },
                              darkMode: true,
                              visualMap: context?.chartColor
                                ? {
                                    min:
                                      sikayetData1 &&
                                      Math.min(
                                        ...sikayetData1.map((obj) => obj.value)
                                      ),
                                    max:
                                      sikayetData1 &&
                                      Math.max(
                                        ...sikayetData1.map((obj) => obj.value)
                                      ),
                                    show: false,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  }
                                : false,
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <DynamicCard
                        events={false}
                        data={sikayetData2}
                        allEvents={false}
                        item={{
                          title: context?.t?.sub_category_1,
                          options: {
                            options: {
                              series: [
                                {
                                  data: sikayetData2,
                                  name: null,
                                  type: "pie",
                                  label: {
                                    textStyle: {
                                      fontSize: 15,
                                    },
                                  },
                                  radius: "50%",
                                  itemStyle: {
                                    color: context?.chartColor,
                                  },
                                },
                              ],
                              tooltip: {
                                trigger: "item",
                              },
                              darkMode: true,
                              visualMap: context?.chartColor
                                ? {
                                    min:
                                      sikayetData2 &&
                                      Math.min(
                                        ...sikayetData2.map((obj) => obj.value)
                                      ),
                                    max:
                                      sikayetData2 &&
                                      Math.max(
                                        ...sikayetData2.map((obj) => obj.value)
                                      ),
                                    show: false,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  }
                                : false,
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <DynamicCard
                        events={false}
                        data={sikayetData3}
                        allEvents={false}
                        item={{
                          title: context?.t?.sub_category_2,
                          options: {
                            options: {
                              series: [
                                {
                                  data: sikayetData3,
                                  name: null,
                                  type: "pie",
                                  label: {
                                    textStyle: {
                                      fontSize: 15,
                                    },
                                  },
                                  radius: "50%",
                                  itemStyle: {
                                    color: context?.chartColor,
                                  },
                                },
                              ],
                              tooltip: {
                                trigger: "item",
                              },
                              darkMode: true,
                              visualMap: context?.chartColor
                                ? {
                                    min:
                                      sikayetData3 &&
                                      Math.min(
                                        ...sikayetData3.map((obj) => obj.value)
                                      ),
                                    max:
                                      sikayetData3 &&
                                      Math.max(
                                        ...sikayetData3.map((obj) => obj.value)
                                      ),
                                    show: false,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  }
                                : false,
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {raporFilter && (
            <div id="sokak-analizi-sokak-raporu">
              <h5>{context?.t?.sokak_raporu}</h5>
              <ol className="breadcrumb mb-2 mt-1">
                <li className="breadcrumb-item">
                  {raporFilter?.city.label || "-"}
                </li>
                <li className="breadcrumb-item">
                  {raporFilter?.district.label || "-"}
                </li>
                <li className="breadcrumb-item">
                  {raporFilter?.neighborhood.label || "-"}
                </li>
                <li className="breadcrumb-item">
                  {raporFilter?.street.label || "-"}
                </li>
              </ol>
              <div className=""></div>
              <HazirRaporlar justPDF={true} raporFilter={raporFilter} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SokakAnalizi;
