import React, { useContext, useState } from "react";
import { Card, CardBody } from "../../components/card/card";
import Fancybox from "../../components/Fancybox";
import apiClient from "../../ApiClient";
import toastMessage from "../../components/ToastMessage";
import { AppSettings } from "../../config/app-settings";

export default function SahadanGelenlerItem({
  locationSahadanGelenler,
  handleMarkersSahadanGelenler,
  fetchSahadanGelenler,
  response,
  index,
}) {
  const context = useContext(AppSettings);
  const [showDetail, setShowDetail] = useState(false);

  async function handleFileUpload(e, replyId, itemId, status) {
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    if (e.size > maxFileSize) {
      toastMessage(context?.t?.max_file_size_5mb, "error");
      return;
    }

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      const response = await apiClient.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        const responseImage = response.data.url;

        try {
          const responseUpdate = await apiClient.put(
            "/groups/assignment/" + replyId,
            {
              status: status,
              answer_id: parseInt(itemId),
              image: responseImage,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (responseUpdate) {
            toastMessage(context?.t?.resim_eklendi, "success");
            fetchSahadanGelenler();
          }
        } catch (error) {
          console.error("Error", error);
        }
      }
    } catch (error) {
      console.error("Upload File Error", error);
    }
  }

  async function handleComplete(replyId, item, status) {
    try {
      const response = await apiClient.put(
        "/groups/assignment/" + replyId,
        {
          status: status,
          answer_id: parseInt(item.id),
          image: item?.answer?.status?.image || "https://-.com/",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        toastMessage(context?.t?.tamamlandi, "success");
        fetchSahadanGelenler();
      }
    } catch (error) {
      console.error("Error", error);
    }
  }

  const renderAnswer = (answerItem, index) => (
    <div
      key={index}
      className={`border mt-3 px-2 py-3 d-flex gap-2 ${
        answerItem?.answer?.status?.is_solved ? "border-theme" : ""
      }`}
    >
      <span className="text-white d-block">{index + 1}.</span>
      <div className="flex-1 overflow-hidden">
        <div className="text-white mb-2">
          <strong>{context?.t?.question}: </strong>
          <span>{answerItem?.question?.question}</span>
        </div>
        <div className="text-white mb-2">
          <strong>{context?.t?.answer}: </strong>
          <span>
            {typeof answerItem.answer.answer === "object"
              ? answerItem.answer.text || answerItem.answer.answer.answer
              : answerItem.text || answerItem.answer.answer}
          </span>
        </div>
        {/* Image List Mapping */}
        <div className="row g-2">
          <Fancybox
            options={{
              Carousel: {
                infinite: false,
              },
            }}
          >
            {answerItem.answer.images?.map((image, imageIndex) => (
              <div key={imageIndex} className="row g-2">
                {image.list?.map((imgUrl, urlIndex) => (
                  <div className="col-6">
                    <a
                      data-fancybox={`profile-sahadan-gelenler-${imageIndex}-gallery`}
                      href={imgUrl}
                      className="d-block border border-white"
                    >
                      <img
                        key={urlIndex}
                        src={imgUrl}
                        alt="answer"
                        className="w-100 img-cover"
                        style={{ height: "200px" }}
                      />
                    </a>
                  </div>
                ))}
                <p className="text-white mt-2 mb-0">
                  <strong>{context?.t?.description}: </strong>
                  {image.description || "Yok"}
                </p>
              </div>
            ))}
          </Fancybox>
        </div>

        {answerItem?.answer?.status?.image &&
          answerItem?.answer?.status?.image !== "https://-.com/" && (
            <div className="">
              <hr />

              <span className="text-white fw-bold d-block mb-3">
                {context?.t?.guncel_hali}
              </span>
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <a
                  data-fancybox={`guncel-profile-sahadan-gelenler-${answerItem?.id}-gallery`}
                  href={answerItem?.answer?.status?.image}
                  className="d-block w-100 border border-white"
                >
                  <img
                    src={answerItem?.answer?.status?.image}
                    alt="answer"
                    className="w-100 img-cover"
                    style={{ height: "200px" }}
                  />
                </a>
              </Fancybox>
            </div>
          )}

        {(!answerItem?.answer?.status?.image ||
          !answerItem?.answer?.status?.is_solved) && (
          <>
            <hr className="my-2" />

            <div className="d-flex flex-wrap justify-content-end gap-2">
              {!answerItem?.answer?.status?.image && (
                <>
                  <input
                    id={`import_image_sahadan_gelenler_${answerItem?.id}`}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) =>
                      handleFileUpload(
                        e,
                        response?.reply_id,
                        answerItem?.id,
                        answerItem?.answer?.status?.is_solved
                      )
                    }
                    className="d-none"
                  />
                  <label
                    htmlFor={`import_image_sahadan_gelenler_${answerItem?.id}`}
                    className={`btn btn-outline-theme`}
                  >
                    {context?.t?.upload_image}
                  </label>
                </>
              )}

              {!answerItem?.answer?.status?.is_solved && (
                <button
                  type="button"
                  className={`btn btn${
                    answerItem?.answer?.status?.is_solved ? "" : "-outline"
                  }-theme`}
                  onClick={() =>
                    !answerItem?.answer?.status?.is_solved &&
                    handleComplete(response?.reply_id, answerItem, true)
                  }
                >
                  {context?.t?.tamamla}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );

  // Calculate progress
  const completedAnswers = response.answer.filter((answerGroup) =>
    Array.isArray(answerGroup)
      ? answerGroup.every((answer) => answer?.answer?.status?.is_solved)
      : Object.values(answerGroup).every(
          (answer) => answer?.answer?.status?.is_solved
        )
  ).length;

  // totalAnswers'ı response.answer_id'ye göre filtrele
  const totalAnswers = response.answer.filter((answerGroup) =>
    Array.isArray(answerGroup)
      ? answerGroup.some((answer) => answer.id === response.answer_id)
      : Object.values(answerGroup).some(
          (answer) => answer.id === response.answer_id
        )
  ).length;

  const progressPercentage = (completedAnswers / totalAnswers) * 100;

  return (
    <div className="col-12 mb-3">
      <div className="progress-wrapper d-flex align-items-center mb-1">
        <span className="d-block text-center fw-bold me-2">
          {completedAnswers}/{totalAnswers}
        </span>
        <div className="progress flex-1" style={{ height: 10 }}>
          <div
            className="progress-bar bg-theme"
            role="progressbar"
            style={{ width: `${progressPercentage}%` }}
            aria-valuenow={progressPercentage}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>

      <Card>
        <CardBody>
          <div>
            <div className="d-flex justify-content-between gap-2">
              <h5
                className="d-flex align-items-center justify-content-between mb-0 pointer flex-1"
                onClick={() => setShowDetail(!showDetail)}
              >
                {index + 1}. {response.report_id.name}
                {showDetail ? (
                  <i className="fas fa-angle-up"></i>
                ) : (
                  <i className="fas fa-angle-down"></i>
                )}
              </h5>

              <button
                type="button"
                className="btn btn-outline-theme btn-sm"
                onClick={() => {
                  handleMarkersSahadanGelenler(
                    locationSahadanGelenler?.id === response?.reply_id
                      ? null
                      : response
                  );
                }}
              >
                {locationSahadanGelenler?.id === response?.reply_id
                  ? context?.t?.locations_hide
                  : context?.t?.locations_show}
              </button>
            </div>

            {showDetail &&
              response.answer.length !== 0 &&
              response.answer.map((answerGroup, groupIndex) =>
                Array.isArray(answerGroup)
                  ? answerGroup
                      .filter(
                        (answerItem) => answerItem.id === response?.answer_id
                      ) // Filter by answerItem.id
                      .map((answerItem, itemIndex) =>
                        renderAnswer(answerItem, itemIndex)
                      )
                  : Object.keys(answerGroup)
                      .filter(
                        (key) => answerGroup[key].id === response?.answer_id
                      ) // Filter by answerGroup[key].id
                      .map((key) =>
                        renderAnswer(answerGroup[key], parseInt(key))
                      )
              )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
