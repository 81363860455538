import React, { useState } from "react";

const LocationButton = () => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (err) => {
          setError("Konum alınırken bir hata oluştu: " + err.message);
        }
      );
    } else {
      setError("Tarayıcı konum desteği yok.");
    }
  };

  return (
    <div>
      <button onClick={handleGetLocation}>Konumumu Al</button>
      {location && (
        <div>
          <p>Latitude: {location.latitude}</p>
          <p>Longitude: {location.longitude}</p>
        </div>
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default LocationButton;
