import React, { useContext, useEffect, useState } from "react";
import Question from "./Question";
import Select from "react-select";
import { AppSettings } from "../../config/app-settings";
import toastMessage from "../../components/ToastMessage";
import apiClient from "../../ApiClient";

const QuestionPool = ({
  reportLoader,
  setReportLoader,
  reportCopy,
  reportEdit,
  handleGetReports,
  handleGetShowReport,
  categoryData,
  handleHazirAnketler,

  customer,
  anketAdi,
  setAnketAdi,
  anketAciklama,
  setAnketAciklama,
  questions,
  setCustomer,
  setQuestions,

  reportNameSelect,
  hazirAnketlerSelect,
}) => {
  const context = useContext(AppSettings);

  const addQuestion = () => {
    const newQuestion = {
      id: Date.now(),
      title: "",
      category: null,
      answerType: "radio",
      score: 0,
      stringTitle: "",
      stringScore: 0,
      isRequired: false,
      answers: [
        {
          id: Date.now() + 1,
          title: "",
          answerImage: "",
          score: 0,
          isRequired: false,
          subQuestions: [],
        },
      ],
    };
    setQuestions([...questions, newQuestion]);
  };

  const updateQuestion = (id, updatedQuestion) => {
    const updatedQuestions = questions.map((q) =>
      q.id === id ? updatedQuestion : q
    );
    setQuestions(updatedQuestions);
  };

  const removeQuestion = (id) => {
    setQuestions(questions.filter((q) => q.id !== id));
  };

  const moveQuestionUp = (index) => {
    if (index > 0) {
      const updatedQuestions = [...questions];
      [updatedQuestions[index - 1], updatedQuestions[index]] = [
        updatedQuestions[index],
        updatedQuestions[index - 1],
      ];
      setQuestions(updatedQuestions);
    }
  };

  const moveQuestionDown = (index) => {
    if (index < questions.length - 1) {
      const updatedQuestions = [...questions];
      [updatedQuestions[index], updatedQuestions[index + 1]] = [
        updatedQuestions[index + 1],
        updatedQuestions[index],
      ];
      setQuestions(updatedQuestions);
    }
  };

  const addQuestionDown = (index) => {
    const newQuestion = {
      id: Date.now(),
      title: "",
      category: null,
      answerType: "radio",
      score: 0,
      stringTitle: "",
      stringScore: 0,
      isRequired: false,
      answers: [
        {
          id: Date.now() + 1,
          title: "",
          answerImage: "",
          score: 0,
          isRequired: false,
          subQuestions: [],
        },
      ],
    };

    const updatedQuestions = [...questions];
    updatedQuestions.splice(index + 1, 0, newQuestion); 
    setQuestions(updatedQuestions);
  };

  const handleSubmit = async () => {
    if (context?.me?.role?.id === 1 && !customer) {
      toastMessage(context?.t?.musteri_secmelisiniz, "error");
      return;
    }

    if (!anketAdi || anketAdi === "") {
      toastMessage(context?.t?.anket_adi_girmelisiniz, "error");
      return;
    }

    if (questions.length === 0) {
      toastMessage(context?.t?.en_az_bir_soru_girmelisiniz, "error");
      return;
    }

    const validateQuestion = (question) => {
      if (
        !question.category ||
        question.score === "" ||
        question.score === null ||
        !question.title ||
        !question.answerType
      ) {
        toastMessage(context?.t?.tum_soru_alanlar_doldurmalisiniz, "error");
        return false;
      }

      if (question.answerType === "string") {
        // if (!question.stringTitle) {
        //   toastMessage(context?.t?.metin_basligi_girmelisiniz, "error");
        //   return false;
        // }

        if (question.score === "" || question.score === null) {
          toastMessage(context?.t?.metin_puani_girmelisiniz, "error");
          return false;
        }
      } else {
        if (question.answers.length === 0) {
          toastMessage(context?.t?.en_az_bir_cevap_girmelisiniz, "error");
          return false;
        }

        for (const answer of question.answers) {
          if (answer.score === "" || answer.score === null || !answer.title) {
            toastMessage(
              context?.t?.tum_cevap_alanlari_doldurmalisiniz,
              "error"
            );
            return false;
          }

          // Alt soruların kontrolü (subQuestions)
          if (answer.subQuestions && answer.subQuestions.length > 0) {
            for (const subQuestion of answer.subQuestions) {
              if (!validateQuestion(subQuestion)) {
                return false;
              }
            }
          }
        }
      }

      return true;
    };

    // Tüm soruların kontrolü
    for (const question of questions) {
      if (!validateQuestion(question)) {
        return; // Hata mesajı toastMessage ile gösterildi, işlem durduruldu
      }
    }

    const extractAnswers = (question, answers) => {
      return answers.map((answer) => {
        const condition =
          answer.subQuestions.length > 0
            ? {
                type: answer.subQuestions[0].answerType,
                question: answer.subQuestions[0].title || "",
                point: parseFloat(answer.subQuestions[0].score),
                category: answer.subQuestions[0].category,
                is_required: answer.subQuestions[0].isRequired,
                is_main: false,
                answers: extractAnswers(
                  answer.subQuestions[0],
                  answer.subQuestions[0].answers
                ), // Recursive call for answers
              }
            : null;

        return {
          answer:
            question.answerType === "string"
              ? question.stringTitle || question.title
              : answer.answerImage
              ? answer.answerImage + "|*|" + answer.title
              : answer.title,
          is_required: answer.isRequired,
          point: question.stringScore
            ? parseFloat(question.stringScore)
            : parseFloat(answer.score),
          condition: condition,
        };
      });
    };

    const questionsArray = questions.map((question) => {
      return {
        type: question.answerType,
        question: question.title || "",
        point: parseFloat(question.score),
        category: question.category,
        is_required: question.isRequired,
        is_main: true,
        answers: extractAnswers(question, question.answers), // Call the recursive function for answers
      };
    });

    const payload = {
      name: anketAdi,
      description: anketAciklama,
      customer_id:
        context?.me?.role?.id === 1
          ? customer.value
          : context?.me?.customer?.id,
      questions: questionsArray,
    };

    setReportLoader(true);

    try {
      const response = reportCopy
        ? await apiClient.customPost(`/street-report`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
        : reportEdit
        ? await apiClient.put(`/street-report/${reportEdit[0].id}`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
        : await apiClient.customPost(`/street-report`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

      setReportLoader(false);

      toastMessage(
        ` ${
          reportCopy
            ? context?.t?.anketi_kopyalandi
            : reportEdit
            ? context?.t?.anketi_guncellendi
            : context?.t?.anketi_olusturuldu
        }`,
        "success"
      );

      // if (!reportCopy && !reportEdit) {
      //   document.getElementById("createReportModalCloseButton").click();
      // } else {
      // }

      // document.querySelector('[data-bs-target="#viewSurveyModal"]').click();

      // setReportNameSelect(item);
      // setHazirAnketlerSelect(null);
      // handleGetShowReport({ value: item.id });

      setTimeout(() => {
        document.getElementById("createReportModalCloseButton").click();
        handleGetReports();
        handleHazirAnketler();
        if (reportCopy || reportEdit) {
          handleGetShowReport(
            reportEdit
              ? { value: reportEdit[0].id }
              : reportNameSelect || hazirAnketlerSelect
          );
        }
      }, 300);
    } catch (error) {
      setReportLoader(false);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  };

  // Edit
  const processSubQuestions = (condition) => {
    return {
      id: condition.id,
      title: condition.question,
      answerType: condition ? condition.type : null,
      score: condition.point,
      isRequired: condition.is_required,
      category: condition?.category?.id,
      stringTitle:
        condition.type === "string" ? condition.answers[0].answer : "",
      stringScore: condition.type === "string" ? condition.answers[0].point : 0,
      answers: condition?.answers
        ? condition.answers.map((subAnswer) => {
            return {
              id: subAnswer.id,
              answerImage: subAnswer.answer.includes("|*|")
                ? subAnswer.answer.split("|*|")[0]
                : "",
              title: subAnswer.answer.includes("|*|")
                ? subAnswer.answer.split("|*|")[1]
                : subAnswer.answer,
              score: subAnswer.point,
              isRequired: subAnswer.is_required,
              subQuestions: subAnswer.condition
                ? [processSubQuestions(subAnswer.condition)]
                : [],
            };
          })
        : [],
    };
  };

  useEffect(() => {
    if (reportEdit) {
      setCustomer(
        reportEdit[0]?.customer_id
          ? {
              value: reportEdit[0]?.customer_id,
              label: context.allCustomerSelect.find(
                (customer) => customer.value === reportEdit[0]?.customer_id
              )?.label,
            }
          : {
              value: null,
              label: context?.t?.tum_musteriler,
            }
      );
      setAnketAdi(reportEdit[0]?.name);
      setAnketAciklama(reportEdit[0]?.description);

      const editQuestionsData = reportEdit[0]?.questions.map((item) => {
        return {
          id: item?.id,
          title: item?.question,
          category: item?.category?.id,
          answerType: item?.type,
          score: item?.point,
          stringTitle: item?.type === "string" ? item.answers[0].answer : "",
          stringScore: item?.type === "string" ? item.answers[0].point : 0,
          isRequired: item?.is_required,
          answers:
            item?.answers && item?.answers.length !== 0
              ? item?.answers.map((answer) => {
                  return {
                    id: answer.id,
                    answerImage: answer.answer.includes("|*|")
                      ? answer.answer.split("|*|")[0]
                      : "",
                    title: answer.answer.includes("|*|")
                      ? answer.answer.split("|*|")[1]
                      : answer.answer,
                    score: answer.point,
                    isRequired: answer.is_required,
                    subQuestions: answer.condition
                      ? [processSubQuestions(answer.condition)]
                      : [],
                  };
                })
              : [],
        };
      });

      setQuestions(editQuestionsData);
    }
  }, [context.allCustomerSelect, reportEdit]);

  return (
    <div className="modal-body">
      {context?.me?.role.id === 1 && (
        <div className="mb-3">
          <span className="d-block mb-1">{context?.t?.select_customer}</span>
          {context.allCustomerSelect ? (
            <Select
              options={[
                { value: null, label: context?.t?.tum_musteriler },
                ...context.allCustomerSelect,
              ]}
              placeholder=""
              classNamePrefix="react-select"
              noOptionsMessage={() => context?.t?.no_option}
              value={customer}
              onChange={(e) => setCustomer(e)}
            />
          ) : (
            <div className="spinner-border spinner-border-sm text-theme"></div>
          )}
        </div>
      )}

      <div className="mb-3">
        <span className="d-block mb-1">{context?.t?.anket_adi}</span>
        <input
          type="text"
          className="form-control"
          value={anketAdi}
          onChange={(e) => setAnketAdi(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <span className="d-block mb-1">{context?.t?.anket_aciklama}</span>
        <input
          type="text"
          className="form-control"
          value={anketAciklama}
          onChange={(e) => setAnketAciklama(e.target.value)}
        />
      </div>

      {questions.map((question, index) => (
        <Question
          t={context?.t}
          key={question.id}
          question={question}
          questionIndex={index + 1}
          updateQuestion={updateQuestion}
          removeQuestion={removeQuestion}
          setQuestions={setQuestions}
          questions={questions}
          categoryData={categoryData}
          sortButtonShow={true}
          moveQuestionUp={() => moveQuestionUp(index)}
          moveQuestionDown={() => moveQuestionDown(index)}
          addQuestionDown={() => addQuestionDown(index)}
        />
      ))}

      <button className="btn btn-dark btn-lg w-100" onClick={addQuestion}>
        + {context?.t?.soru_ekle}
      </button>

      <div className="d-flex gap-3 border-top pt-4 mt-3">
        <button
          type="button"
          data-bs-dismiss="modal"
          className="btn btn-danger btn-lg col"
        >
          {context?.t?.cancel}
        </button>

        <button
          type="button"
          className="btn btn-theme text-black btn-lg col"
          onClick={handleSubmit}
          disabled={reportLoader}
        >
          {reportCopy
            ? context?.t?.copy
            : reportEdit
            ? context?.t?.edit
            : context?.t?.create}
          {reportLoader && (
            <div className="spinner-border spinner-border-sm"></div>
          )}
        </button>
      </div>
    </div>
  );
};

export default QuestionPool;
