import { Card, CardBody } from "../../components/card/card";
import apiClient from "../../ApiClient";
import { useState, useContext } from "react";
import DatePicker from "react-datepicker";
import { AppSettings } from "../../config/app-settings";
import moment from "moment";
import toastMessage from "../../components/ToastMessage";

export default function FileItem({
  id,
  item,
  setSelectedFile,
  setTypeData,
  setFileItemEvents,
  setVisibleId,
}) {
  const context = useContext(AppSettings);

  const [dataColumn, setDataColumn] = useState([]);
  const [data, setData] = useState(null);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [filterSwiper, setFilterSwiper] = useState(null);

  const [typeTitle, setTypeTitle] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [errorTable, setErrorTable] = useState(null);
  const [currentPageError, setCurrentPageError] = useState(1);

  const [selectedColumn, setSelectedColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerms, setSearchTerms] = useState({}); // Arama terimlerini saklamak için

  function handleFetchErrorReset() {
    setVisibleId(null);
    setErrorTable(null);
    setCurrentPageError(1);
  }

  async function fetchError(type, id, page = 1) {
    setData(null);
    setIsDataLoaded(false);
    setIsTableVisible(false);
    setIsLoading(false);
    setDataColumn([]);

    setCurrentPage(1);

    setVisibleId(id);

    setTypeTitle(type);
    setErrorTable(null);

    try {
      const response = await apiClient.get(
        `/failed-validations/${type}?page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response?.data?.data.length !== 0) {
        setErrorTable(response?.data);
      } else {
        toastMessage(context?.t?.hatali_satir_bulunamadi, "success");

        setVisibleId(null);
        setErrorTable(null);
        setCurrentPageError(1);
      }
    } catch (error) {
      setErrorTable(null);
      toastMessage(context?.t?.hatali_satir_bulunamadi, "success");

      setVisibleId(null);
      setErrorTable(null);
      setCurrentPageError(1);
      console.error("Get File Types Error", error);
    }
  }

  async function fetchColumn(type, id) {
    setTypeTitle(type);
    setErrorTable(null);
    setCurrentPageError(1);

    if (isTableVisible) {
      setIsTableVisible(false);
      setVisibleId(null);
      return;
    }

    if (isDataLoaded) {
      setVisibleId(id);
      setIsTableVisible(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await apiClient.get(`/columns/${type}?connection_type=local`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setDataColumn(response.data);
        await fetchData(1, type, response.data.columns, searchTerm);
      }
    } catch (error) {
      setDataColumn([]);
      setData([]);
      console.error("Get File Types Error", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchData(page, type, fileColumns, conditions) {
    setIsPageLoading(true);

    const columnsArray = fileColumns.map((item) => ({
      key: item.replace(/[\/\\']/g, ""),
      value: "select",
    }));

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${page}`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: type,
          description: "",
          customer: null,
          paginate: true,
          status: true,
          order: 1,
          connection_type: "local",
sql: {
            column: columnsArray,
            condition: conditions || [],
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setData(response);
      setIsTableVisible(true);
      setVisibleId(id);
      setIsDataLoaded(true);
    } catch (error) {
      setData([]);
      console.error(error);
    } finally {
      setIsPageLoading(false);
    }
  }

  const handlePageChangeError = (page) => {
    setCurrentPageError(page);
    fetchError(typeTitle, id, page);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page, typeTitle, dataColumn.columns, searchTerm);
  };

  const handleSearch = () => {
    const results = [];

    Object.entries(searchTerms).forEach(([key, value]) => {
      if (value) {
        const formattedValue =
          moment.isMoment(value) || value instanceof Date
            ? moment(value).format("YYYY-MM-DD")
            : value;

        results.push({
          key: key,
          value: formattedValue,
          operator: "like",
          type: "and",
        });
      }
    });

    setSearchTerm(results);
    fetchData(1, typeTitle, dataColumn.columns, results);
  };

  // const handleSearch = () => {
  //   if (selectedColumn && searchTerm !== "") {
  //     setCurrentPage(1);
  // fetchData(1, typeTitle, dataColumn.columns, searchTerm);
  //   }
  // };

  const handleClearSearch = () => {
    setSearchTerm("");
    setSearchTerms({});
    setSelectedColumn(null);
    setCurrentPage(1);
    fetchData(1, typeTitle, dataColumn.columns, "");
  };

  return (
    <div
      className={
        errorTable
          ? "col-12"
          : isTableVisible && data
          ? "col-12 "
          : `col-12 col-sm-6 col-xxxl-4`
      }
    >
      <Card>
        <CardBody>
          <div className="d-flex align-items-center justify-content-between">
            <span className="flex-1 me-2">
              {item.type.includes("bireysel-data") ||
              item.type.includes("hane-data") ||
              item.type.includes("akraba-data")
                ? item.type.split("-customer")[0]
                : item.type.split("-customer")[0].startsWith("manuel-")
                ? item.type.split("-customer")[0].split("manuel-")[1]
                : item.type.split("-customer")[0]}
            </span>

            <div className="d-flex align-items-center gap-2">
              {context?.me?.role?.id !== 7 && (
                <button
                  type="button"
                  className="btn btn-outline-theme btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#fileListImportModal"
                  onClick={() => setSelectedFile(item)}
                >
                  {context?.t?.uzerine_veri_yukle}
                </button>
              )}

              <button
                type="button"
                className="btn btn-outline-theme d-flex align-items-center gap-2 btn-sm"
                onClick={() => fetchColumn(item.type, id)}
              >
                <span>{context?.t?.table}</span>
                <i
                  className={`fas fa-angle-${isTableVisible ? "up" : "down"}`}
                ></i>
              </button>

              <button
                type="button"
                className="btn btn-danger d-flex align-items-center gap-2 btn-sm"
                onClick={() =>
                  errorTable
                    ? handleFetchErrorReset()
                    : fetchError(item.type, id, 1)
                }
              >
                <span>{context?.t?.hata_var_mi}</span>
                <i className={`fas fa-angle-${errorTable ? "up" : "down"}`}></i>
              </button>

              {context?.me?.role?.id !== 7 && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#yuklenenDosyalarDeleteModal"
                  onClick={() => setSelectedFile(item)}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              )}
            </div>
          </div>

          {isLoading ? (
            <div className="spinner-border text-theme"></div>
          ) : errorTable ? (
            <Card className="mt-3">
              <CardBody>
                {errorTable?.data.length !== 0 ? (
                  isPageLoading ? (
                    <div className="spinner-border text-theme"></div>
                  ) : (
                    <>
                      <div className="table-responsive" style={{ height: 500 }}>
                        <table className="table">
                          <thead>
                            <tr>
                              {Object.keys(errorTable?.data[0].data).map(
                                (key, index) => (
                                  <th
                                    style={{ whiteSpace: "nowrap" }}
                                    key={index}
                                  >
                                    {key}
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>

                          <tbody>
                            {errorTable?.data.map((item, rowIndex) => (
                              <tr key={rowIndex}>
                                {Object.keys(errorTable?.data[0].data).map(
                                  (key, colIndex) => (
                                    <td key={colIndex}>{item.data[key]}</td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center mt-4">
                        <ul className="pagination flex-wrap">
                          <li className={`paginate_button page-item`}>
                            <button
                              type="button"
                              onClick={() =>
                                handlePageChangeError(currentPageError - 1)
                              }
                              className={`page-link pointer ${
                                !errorTable.prev_page_url ? "disabled" : ""
                              }`}
                            >
                              {context?.t?.prev}
                            </button>
                          </li>

                          {errorTable.links.map(
                            (number, index) =>
                              index > 0 &&
                              index < errorTable.links.length - 1 && (
                                <li
                                  key={index}
                                  className={`paginate_button page-item ${
                                    currentPageError === parseInt(number.label)
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <div
                                    onClick={() =>
                                      handlePageChangeError(
                                        parseInt(number.label)
                                      )
                                    }
                                    className="page-link pointer"
                                  >
                                    {number.label}
                                  </div>
                                </li>
                              )
                          )}

                          <li className={`paginate_button page-item`}>
                            <button
                              type="button"
                              onClick={() =>
                                handlePageChangeError(currentPageError + 1)
                              }
                              className={`page-link pointer ${
                                !errorTable.next_page_url ? "disabled" : ""
                              }`}
                            >
                              {context?.t?.next}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </>
                  )
                ) : (
                  <p className="mb-0">{context?.t?.sonuc_bulunamadi}</p>
                )}
              </CardBody>
            </Card>
          ) : (
            isTableVisible &&
            dataColumn &&
            dataColumn?.columns?.length !== 0 &&
            data && (
              <Card className="mt-3">
                <CardBody>
                  <div className="row align-items-center">
                    <div className="col me-3 overflow-hidden">
                      <div className="d-flex overflow-hidden flex-wrap align-items-center gap-2 mb-3">
                        {/* <Select
                            options={dataColumn.columns.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            placeholder={context?.t?.choose_column_2}
                            value={selectedColumn}
                            onChange={(selectedOption) =>
                              setSelectedColumn(selectedOption)
                            }
                          /> */}

                        <div
                          className="d-flex gap-2 flex-1 overflow-auto pb-2 mb-2"
                          id={`${item.type}-${id}-datepicker-portal`}
                        >
                          {dataColumn.types.map((columnItem, columnIndex) => (
                            <div
                              key={columnIndex}
                              style={{ flex: "0 0 200px" }}
                            >
                              <span>{columnItem.key}</span>
                              {columnItem.value === "date" ? (
                                <DatePicker
                                  locale="tr"
                                  className="form-control"
                                  isClearable
                                  portalId={`${item.type}-${id}-datepicker-portal`}
                                  selected={searchTerms[columnItem.key] || null}
                                  onChange={(date) =>
                                    setSearchTerms((prev) => ({
                                      ...prev,
                                      [columnItem.key]: date,
                                    }))
                                  }
                                  required
                                />
                              ) : (
                                <input
                                  type={
                                    columnItem.value === "id" ||
                                    columnItem.value === "integer"
                                      ? "number"
                                      : "text"
                                  }
                                  className="form-control"
                                  value={searchTerms[columnItem.key] || ""}
                                  onChange={(e) =>
                                    setSearchTerms((prev) => ({
                                      ...prev,
                                      [columnItem.key]: e.target.value,
                                    }))
                                  }
                                  style={{ maxWidth: 300 }}
                                />
                              )}
                            </div>
                          ))}
                        </div>

                        <button
                          type="button"
                          className="btn btn-theme"
                          onClick={handleSearch}
                        >
                          {context?.t?.search}
                        </button>

                        {searchTerm && (
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleClearSearch}
                          >
                            {context?.t?.remove}
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="col w-auto flex-none">
                      <button
                        type="button"
                        className="btn btn-outline-theme me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#yuklenenDosyalarIslemler"
                        onClick={() =>
                          setFileItemEvents({
                            column: dataColumn,
                            row: null,
                            type: item,
                          })
                        }
                      >
                        {context?.t?.satir_ekle}
                      </button>

                      <button
                        type="button"
                        className="btn btn-outline-theme"
                        data-bs-toggle="modal"
                        data-bs-target="#yuklenenDosyalarModal"
                        onClick={() =>
                          setTypeData({
                            column: dataColumn.columns,
                            type: item,
                          })
                        }
                      >
                        {context?.t?.veri_silmek_icin_tikla}
                      </button>
                    </div>
                  </div>

                  {data?.data.length !== 0 ? (
                    isPageLoading ? (
                      <div className="spinner-border text-theme"></div>
                    ) : (
                      <>
                        <div
                          className="table-responsive"
                          style={{ height: 500 }}
                        >
                          <table className="table">
                            <thead>
                              <tr>
                                <th style={{ width: 100 }}>
                                  {context?.t?.transaction}
                                </th>
                                {dataColumn.columns.map((header, index) => (
                                  <th
                                    style={{ whiteSpace: "nowrap" }}
                                    key={index}
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {data.data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td>
                                    <button
                                      className="btn btn-outline-theme btn-sm"
                                      data-bs-toggle="modal"
                                      data-bs-target="#yuklenenDosyalarIslemler"
                                      onClick={() =>
                                        setFileItemEvents({
                                          column: dataColumn,
                                          row: Object.values(row).map(
                                            (cell, cellIndex) => {
                                              return cell;
                                            }
                                          ),
                                          type: item,
                                        })
                                      }
                                    >
                                      {context?.t?.update}
                                    </button>
                                  </td>
                                  {Object.values(row).map((cell, cellIndex) => (
                                    <td key={cellIndex}>{cell}</td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center mt-4">
                          <ul className="pagination flex-wrap">
                            <li className={`paginate_button page-item`}>
                              <button
                                type="button"
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                className={`page-link pointer ${
                                  !data.prev_page_url ? "disabled" : ""
                                }`}
                              >
                                {context?.t?.prev}
                              </button>
                            </li>

                            {data.links.map(
                              (number, index) =>
                                index > 0 &&
                                index < data.links.length - 1 && (
                                  <li
                                    key={index}
                                    className={`paginate_button page-item ${
                                      currentPage === parseInt(number.label)
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      onClick={() =>
                                        handlePageChange(parseInt(number.label))
                                      }
                                      className="page-link pointer"
                                    >
                                      {number.label}
                                    </div>
                                  </li>
                                )
                            )}

                            <li className={`paginate_button page-item`}>
                              <button
                                type="button"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                                className={`page-link pointer ${
                                  !data.next_page_url ? "disabled" : ""
                                }`}
                              >
                                {context?.t?.next}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </>
                    )
                  ) : (
                    <p className="mb-0">{context?.t?.sonuc_bulunamadi}</p>
                  )}
                </CardBody>
              </Card>
            )
          )}
        </CardBody>
      </Card>
    </div>
  );
}
