import React, { useState } from "react";
import apiClient from "../../ApiClient";
import moment from "moment";
import toastMessage from "../../components/ToastMessage";

export default function DatabaseForm({ context }) {
  const [formData, setFormData] = useState({
    database: context?.me?.customer?.settings?.database_info?.database || "",
    host: context?.me?.customer?.settings?.database_info?.host || "",
    port: context?.me?.customer?.settings?.database_info?.port || "",
    username: context?.me?.customer?.settings?.database_info?.username || "",
    password: context?.me?.customer?.settings?.database_info?.password || "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (context?.me?.role?.id === 1 || context?.me?.role?.id === 2) {
      setLoading(true);

      try {
        const response = await apiClient.customPost(
          `/test-connection`,
          {
            host: formData.host,
            port: formData.port,
            database: formData.database,
            username: formData.username,
            password: formData.password,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        toastMessage(response?.message, "success");

        const payload = {
          customer: context?.me?.customer?.customer,
          contact: context?.me?.customer?.contact,
          started_at: moment(context?.me?.customer?.started_at).format(
            "YYYY-MM-DD"
          ),
          ended_at: moment(context?.me?.customer?.ended_at).format(
            "YYYY-MM-DD"
          ),
          reports: context?.me?.customer?.reports,
          api_key: context?.me?.customer?.api_key,
          rate:
            context?.me?.customer?.rate && context?.me?.customer?.rate?.value
              ? parseInt(context?.me?.customer?.rate?.value)
              : 100,
          settings: {
            ...context?.me?.customer?.settings,
            database_info: {
              host: formData.host,
              port: formData.port,
              database: formData.database,
              username: formData.username,
              password: formData.password,
            },
          },
        };

        try {
          const response = await apiClient.put(
            `/customer/${context?.me?.customer?.id}`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response) {
            toastMessage(context?.t?.updateed, "success");
            setLoading(false);
          }
        } catch (error) {
          toastMessage(context?.t?.try_again_error, "success");
          setLoading(false);
          console.error("Customer Update Error", error);
        }
      } catch (error) {
        toastMessage(error?.response?.data?.message, "error");
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {(context?.me?.role?.id === 1 || context?.me?.role?.id === 2) && (
        <>
          <h3>{context?.t?.veritabani_baglantisi}</h3>

          <hr />

          <div className="my-4">
            <form onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="host">
                      {context?.t?.veritabani_adi}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="database"
                      value={formData.database}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="host">
                      {context?.t?.host_adresi}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="host"
                      value={formData.host}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="port">
                      {context?.t?.port_numarasi}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      id="port"
                      value={formData.port}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="username">
                      {context?.t?.user_name}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="username"
                      value={formData.username}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="password">
                      {context?.t?.password}
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      id="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="text-end mt-3">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-outline-theme d-inline-flex align-items-center gap-2"
                >
                  {context?.t?.save}
                  {loading && (
                    <div className="spinner-border-sm spinner-border text-theme"></div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
}
