import React from "react";

export default function Logo({ animation = true }) {
  return (
    <>
      <div className="brand">
        <div className="brand-logo pe-none justify-content-between w-100">
          <div className="brand-text">
            STRATEJİ
            <div className="card">
              <span className="text-theme">MERKEZİ</span>

              <div className="card-arrow">
                <div className="card-arrow-top-left"></div>
                <div className="card-arrow-top-right"></div>
                <div className="card-arrow-bottom-left"></div>
                <div className="card-arrow-bottom-right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {animation && (
        <svg
          className="live-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 100"
        >
          <polyline
            fill="none"
            strokeWidth="3"
            stroke="#3cd2a5"
            points="2.4,58.7 70.8,58.7 76.1,46.2 81.1,58.7 89.9,58.7 93.8,66.5 102.8,22.7 110.6,78.7 115.3,58.7 126.4,58.7 134.4,54.7 142.4,58.7 197.8,58.7 "
          />
        </svg>
      )}
    </>
  );
}
