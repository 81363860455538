import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const UpdateMapAnalizModal = ({
  show,
  onClose,
  pageName,
  setPageName,
  context,
  updatePageLoader,
  handleUpdatePage,
}) => {
  const modalRoot =
    document.getElementById("updateMapAnalizModalRoot") ||
    document.createElement("div");

  useEffect(() => {
    if (!document.getElementById("updateMapAnalizModalRoot")) {
      modalRoot.setAttribute("id", "updateMapAnalizModalRoot");
      document.body.appendChild(modalRoot);
    }

    return () => {
      if (modalRoot && modalRoot.parentNode) {
        document.body.removeChild(modalRoot);
      }
    };
  }, [modalRoot]);

  if (!show) return null;

  return ReactDOM.createPortal(
    <>
      <div className="modal-backdrop fade show"></div>
      <div
        className="modal modal-cover fade show"
        id="updateMapAnalizModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "var(--bs-dark4)" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">{context?.t?.edit}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={onClose}
              ></button>
            </div>

            <div className="modal-body">
              <div className="mb-3">
                <span className="mb-1 d-block">{context?.t?.analiz_adi}</span>
                <input
                  type="text"
                  className="form-control"
                  value={pageName}
                  onChange={(e) => setPageName(e.target.value)}
                />
              </div>

              <div className="row">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-outline-theme w-100"
                    data-bs-dismiss="modal"
                    onClick={onClose}
                  >
                    {context?.t?.cancel}
                  </button>
                </div>

                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-theme w-100 mb-3"
                    disabled={updatePageLoader}
                    onClick={() => handleUpdatePage()}
                  >
                    {context?.t?.edit}
                    {updatePageLoader && (
                      <div className="spinner-border spinner-border-sm text-white ms-2"></div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    modalRoot
  );
};

export default UpdateMapAnalizModal;
