import React, { useContext } from "react";
import { AppSettings } from "../../config/app-settings";

function Footer({ appFooterFull, appScrollButton }) {
  const context = useContext(AppSettings);

  return (
    <footer
      id="footer"
      className={`app-footer${appFooterFull ? " app-footer-full" : ""}`}
    >
      <p>&copy; 2024 KENTMETRE {context?.t?.GROUP_OF_COMPANIES}</p>

      <p
        className={`${appScrollButton ? "pe-5" : ""} border-top pt-3`}
        dangerouslySetInnerHTML={{
          __html: context?.t?.footer_text,
        }}
      />
    </footer>
  );
}

export default Footer;
