import React, { useEffect, useContext, useState, useCallback } from "react";

import ReactECharts from "echarts-for-react";
import regression from "regression";

import { AppSettings } from "../../config/app-settings.js";
import moment from "moment";
import apiClient from "../../ApiClient.js";

const formatDate = (dateStr) => {
  const parsedDate = moment(
    dateStr,
    ["YYYY-MM-DD", "YYYY-MM", "YYYY", "MM", "DD"],
    true
  );

  if (parsedDate.isValid()) {
    return parsedDate.format("YYYY-MM-DD");
  }

  return moment("2025-01-01").format("YYYY-MM-DD");
};

export default function DynamicChartItem({
  refItem,
  previewData,
  previewSelectValue,
  previewSelectLabel,
  previewSelectDate,
  chartView,
  regressionCount,
  regressionFrequency,
  regressionType,
}) {
  const context = useContext(AppSettings);

  const activeChartOptions = [
    {
      id: 1,
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: "50%",
          data: [],
          label: {
            textStyle: {
              fontSize: 10,
            },
          },
          itemStyle: {
            color: context?.chartColor,
          },
        },
      ],
    },
    {
      id: 2,
      tooltip: {
        trigger: "axis",
      },
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      // toolbox: {
      //   show: true,
      //   feature: {
      //     magicType: { show: true, type: ["line", "bar"] },
      //     restore: { show: false },
      //     saveAsImage: { show: false },
      //   },
      //   iconStyle: { borderColor: context?.theme === "dark"?"#fff":"#000" },
      // },
      calculable: true,
      xAxis: [
        {
          type: "category",
          data: [],
          axisLabel: {
            rotate: 45,
            textStyle: {
              color: context?.theme === "dark" ? "#fff" : "#000",
              fontSize: 10,
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            textStyle: {
              color: context?.theme === "dark" ? "#fff" : "#000",
            },
          },
        },
      ],
      grid: { right: "2%", bottom: "10%" },
      series: [
        {
          name: "",
          type: "bar",
          data: [],
          markPoint: {
            data: [
              { type: "max", name: "Max" },
              { type: "min", name: "Min" },
            ],
          },
          itemStyle: {
            color: context?.chartColor,
          },
        },
      ],
    },
    {
      id: 17,
      tooltip: {
        trigger: "axis",
      },
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      // toolbox: {
      //   show: true,
      //   feature: {
      //     magicType: { show: true, type: ["line", "bar"] },
      //     restore: { show: false },
      //     saveAsImage: { show: false },
      //   },
      //   iconStyle: { borderColor: context?.theme === "dark"?"#fff":"#000" },
      // },
      calculable: true,
      xAxis: [
        {
          type: "category",
          data: [],
          axisLabel: {
            rotate: 45,
            textStyle: {
              color: context?.theme === "dark" ? "#fff" : "#000",
              fontSize: 10,
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            textStyle: {
              color: context?.theme === "dark" ? "#fff" : "#000",
            },
          },
        },
      ],
      grid: { right: "2%", bottom: "10%" },
      series: [
        {
          name: "",
          type: "line",
          data: [],
          markPoint: {
            data: [
              { type: "max", name: "Max" },
              { type: "min", name: "Min" },
            ],
          },
          itemStyle: {
            color: context?.chartColor,
          },
        },
      ],
    },
    {
      id: 11,
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      yAxis: [
        {
          type: "value",
          axisLabel: {
            textStyle: {
              color: context?.theme === "dark" ? "#fff" : "#000",
            },
          },
        },
      ],
    },
    {
      id: 13,
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "value",
      },
    },
    {
      id: 14,
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      yAxis: {
        type: "value",
      },
    },
    {
      id: 15,
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      yAxis: [
        {
          type: "value",
        },
      ],
    },
    {
      id: 16,
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      yAxis: [
        {
          type: "value",
        },
      ],
    },
    {
      id: 6,
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          radius: ["40%", "70%"],
          itemStyle: {
            borderColor: context?.theme === "dark" ? "#fff" : "#000",
            borderWidth: 1,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 10,
              fontWeight: "bold",
            },
          },
          name: "",
          type: "pie",
          data: [],
        },
      ],
    },
    {
      id: 7,
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      polar: {
        radius: [30, "80%"],
      },
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          type: "bar",
          coordinateSystem: "polar",
          label: {
            show: true,
            position: "middle",
            formatter: "{b}: {c}",
            itemStyle: {
              color: context?.theme === "dark" ? "#fff" : "#000",
            },
          },
        },
      ],
    },
    {
      id: 8,
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      toolbox: {
        show: false,
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: [50, 250],
          center: ["50%", "50%"],
          roseType: "area",
          itemStyle: {
            borderRadius: 8,
          },
          data: [],
        },
      ],
    },
    {
      id: 9,
      darkMode: window.location.host.includes("datakokpit") ? false : true,
      tooltip: {
        trigger: "axis",
        position: function (pt) {
          return [pt[0], "10%"];
        },
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: "none",
          },
          restore: {},
          saveAsImage: {},
        },
        iconStyle: {
          borderColor: context?.theme === "dark" ? "#ffffff" : "#000",
        },
      },
      xAxis: {
        type: "time",
        boundaryGap: false,
        axisLabel: {
          color: context?.theme === "dark" ? "#ffffff" : "#000",
        },
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, "100%"],
        axisLabel: {
          color: context?.theme === "dark" ? "#ffffff" : "#000",
        },
      },
    },
  ];

  const [processedOptions, setProcessedOptions] = useState(null);
  const [chartShow, setChartShow] = useState(false);

  const handlePreview = useCallback(
    async (chartOptions) => {
      if (
        chartView &&
        chartOptions &&
        previewData.length > 0 &&
        previewSelectValue &&
        previewSelectLabel
      ) {
        const filteredPreview = previewData.filter((item) =>
          Object.values(item).every((val) => val)
        );

        // Regression
        let futurePredictions = [];

        if (
          (chartView?.value === 2 || chartView?.value === 17) &&
          regressionCount > 0 &&
          regressionFrequency &&
          regressionType
        ) {
          const formattedString = filteredPreview
            .map(
              (item) =>
                `${formatDate(item[previewSelectLabel.value])},${
                  item[previewSelectValue?.value]
                }`
            )
            .join(";");

          try {
            const response = await apiClient.get(
              `https://arima.kentmetre.com/forecast?data=` +
                formattedString +
                `&frequency=${regressionFrequency?.value}&forecast_horizon=${regressionCount}`
            );

            if (response && response?.data.length !== 0) {
              const dataItem = response?.data[regressionType?.value];

              if (
                dataItem &&
                Object.keys(dataItem).length > 0 &&
                dataItem.dates?.length &&
                dataItem.values?.length
              ) {
                const resultData = dataItem.dates.map((tarih, index) => ({
                  [previewSelectLabel.value]: tarih,
                  [previewSelectValue?.value]: dataItem.values[index],
                }));

                futurePredictions = resultData;
              } else {
                futurePredictions = [];
              }
            }
          } catch (error) {
            console.error(error);
          }

          // const regressionData = filteredPreview.map((d) => [
          //   d[previewSelectLabel.value],
          //   parseInt(d[previewSelectValue?.value]),
          // ]);

          // const result =
          //   regressionType === "linear"
          //     ? regression.linear(regressionData)
          //     : regression.polynomial(regressionData);

          // const lastYear =
          //   filteredPreview[filteredPreview.length - 1][
          //     previewSelectLabel.value
          //   ];
          // for (let i = 1; i <= regressionCount; i++) {
          //   const year = lastYear + i;
          //   const value = result.predict(year)[1];
          //   futurePredictions.push({
          //     [previewSelectLabel.value]: year,
          //     [previewSelectValue.value]: value || 0,
          //   });
          // }
        } else {
          futurePredictions = [];
        }

        // Orijinal formatı belirlemek için bir fonksiyon
        const detectOriginalFormat = (value) => {
          if (/^\d{4}$/.test(value)) return "year"; // Yıl formatı (2021)
          if (/^\d{4}-\d{2}$/.test(value)) return "year-month"; // Yıl-ay formatı (2022-05)
          if (/^\d{2}$/.test(value)) return "month"; // Ay formatı (03)
          if (/^\d{2}-\d{2}$/.test(value)) return "day"; // Gün formatı (15)
          return "unknown"; // Bilinmeyen format
        };

        // Ekstra diziyi orijinal formatına uyarlama
        const normalizeExtra = (extra, originalFormat) => {
          return extra.map((item) => {
            let formattedDate;
            const dateParts = item[previewSelectLabel.value].split("-");

            switch (originalFormat) {
              case "year":
                formattedDate = dateParts[0]; // Yıl
                break;
              case "year-month":
                formattedDate = `${dateParts[0]}-${dateParts[1]}`; // Yıl-ay
                break;
              case "month":
                formattedDate = dateParts[1]; // Ay
                break;
              case "day":
                formattedDate = dateParts[2]; // Gün
                break;
              default:
                formattedDate = item[previewSelectLabel.value]; // Varsayılan: Yıl-ay-gün
            }

            return { ...item, [previewSelectLabel.value]: formattedDate };
          });
        };

        // Orijinal dizinin formatını algıla ve ekstra diziyi normalize et
        const originalFormat = detectOriginalFormat(
          filteredPreview[0][previewSelectLabel.value]
        );
        const normalizedExtra = normalizeExtra(
          futurePredictions,
          originalFormat
        );

        let mergedPreview =
          (chartView?.value === 2 || chartView?.value === 17) &&
          regressionCount > 0 &&
          regressionFrequency &&
          regressionType
            ? filteredPreview.concat(normalizedExtra)
            : filteredPreview;

        // Charts
        if (chartView.value === 1) {
          if (
            filteredPreview.length !== 0 &&
            previewSelectValue &&
            previewSelectLabel
          ) {
            const chartData = filteredPreview.map((item) => ({
              value: item[previewSelectValue.value],
              name: item[previewSelectLabel.value],
            }));
            setProcessedOptions((prevOptions) => ({
              ...prevOptions,
              darkMode: context?.theme === "light" ? false : true,
              tooltip: previewSelectValue.value.includes("_avg")
                ? {
                    trigger: "item",
                    formatter: function (params) {
                      return (
                        params.data.name +
                        "<br /> %" +
                        params.data.value.toFixed(2)
                      );
                    },
                  }
                : {
                    trigger: "item",
                  },
              visualMap: context?.chartColor
                ? {
                    show: false,
                    min: Math.min(...chartData.map((obj) => obj.value)),
                    max: Math.max(...chartData.map((obj) => obj.value)),
                    inRange: {
                      colorLightness: [0.2, 1],
                    },
                  }
                : false,
              series: [
                {
                  ...prevOptions.series[0],
                  data: chartData,
                },
              ],
            }));

            setChartShow(true);
          }
        } else if (chartView.value === 8) {
          if (
            filteredPreview.length !== 0 &&
            previewSelectValue &&
            previewSelectLabel
          ) {
            const chartData = filteredPreview.map((item) => ({
              value: item[previewSelectValue.value],
              name: item[previewSelectLabel.value],
            }));
            setProcessedOptions((prevOptions) => ({
              ...prevOptions,
              darkMode: context?.theme === "light" ? false : true,
              tooltip: previewSelectValue.value.includes("_avg")
                ? {
                    trigger: "item",
                    formatter: function (params) {
                      return (
                        params.data.name +
                        "<br /> %" +
                        params.data.value.toFixed(2)
                      );
                    },
                  }
                : {
                    trigger: "item",
                  },
              visualMap: context?.chartColor
                ? {
                    show: false,
                    min: Math.min(...chartData.map((obj) => obj.value)),
                    max: Math.max(...chartData.map((obj) => obj.value)),
                    inRange: {
                      colorLightness: [0.2, 1],
                    },
                  }
                : false,
              series: [
                {
                  ...prevOptions.series[0],
                  data: chartData,
                },
              ],
            }));

            setChartShow(true);
          }
        } else if (chartView.value === 6) {
          if (
            filteredPreview.length !== 0 &&
            previewSelectValue &&
            previewSelectLabel
          ) {
            const chartData = filteredPreview.map((item) => ({
              value: item[previewSelectValue.value],
              name: item[previewSelectLabel.value],
            }));
            setProcessedOptions((prevOptions) => ({
              ...prevOptions,
              darkMode: context?.theme === "light" ? false : true,
              tooltip: previewSelectValue.value.includes("_avg")
                ? {
                    trigger: "item",
                    formatter: function (params) {
                      return (
                        params.data.name +
                        "<br /> %" +
                        params.data.value.toFixed(2)
                      );
                    },
                  }
                : {
                    trigger: "item",
                  },
              visualMap: context?.chartColor
                ? {
                    show: false,
                    min: Math.min(...chartData.map((obj) => obj.value)),
                    max: Math.max(...chartData.map((obj) => obj.value)),
                    inRange: {
                      colorLightness: [0.2, 1],
                    },
                  }
                : false,
              series: [
                {
                  ...prevOptions.series[0],
                  data: chartData,
                },
              ],
            }));

            setChartShow(true);
          }
        } else if (chartView.value === 7) {
          if (
            filteredPreview &&
            filteredPreview.length !== 0 &&
            previewSelectValue &&
            previewSelectLabel
          ) {
            setProcessedOptions((prevOptions) => ({
              ...prevOptions,
              darkMode: context?.theme === "light" ? false : true,
              tooltip: previewSelectValue.value.includes("_avg")
                ? {
                    trigger: "item",
                    formatter: function (params) {
                      return (
                        params.data.name +
                        "<br /> %" +
                        params.data.value.toFixed(2)
                      );
                    },
                  }
                : {
                    trigger: "item",
                  },
              angleAxis: {
                max: Math.max(
                  ...filteredPreview.map(
                    (item) => item[previewSelectValue.value]
                  )
                ),
                startAngle: 75,
                axisLabel: {
                  color: context?.theme === "dark" ? "#fff" : "#000",
                },
              },
              radiusAxis: {
                type: "category",
                data: filteredPreview.map(
                  (item) => item[previewSelectLabel.value]
                ),
                axisLabel: {
                  color: context?.theme === "dark" ? "#fff" : "#000",
                },
              },
              series: [
                {
                  ...prevOptions.series[0],
                  data: filteredPreview.map(
                    (item) => item[previewSelectValue.value]
                  ),
                },
              ],
            }));

            setChartShow(true);
          }
        } else if (chartView.value === 9) {
          if (
            filteredPreview.length !== 0 &&
            previewSelectDate &&
            previewSelectLabel &&
            previewSelectValue
          ) {
            // Parametreler için veri yapısını oluştur
            const seriesData = {};
            const legendData = new Set();
            // Tarihe göre verileri birleştir ve değerleri topla
            const mergedData = {};
            filteredPreview.forEach((item) => {
              const tarih = item[previewSelectDate.value];
              const parametre = item[previewSelectLabel.value];
              const deger = parseInt(item[previewSelectValue.value]);
              // Check if the necessary fields are present and valid
              if (tarih && parametre && !isNaN(deger)) {
                if (!mergedData[tarih]) {
                  mergedData[tarih] = {};
                }
                if (!mergedData[tarih][parametre]) {
                  mergedData[tarih][parametre] = 0;
                }
                mergedData[tarih][parametre] += deger; // Accumulate value for the same date and parameter
              }
            });
            // Convert mergedData back to an array
            const finalData = [];
            for (const tarih in mergedData) {
              for (const parametre in mergedData[tarih]) {
                finalData.push({
                  tarih: tarih,
                  parametre: parametre,
                  deger: mergedData[tarih][parametre].toString(),
                });
              }
            }
            // Sorting by date and value
            finalData.sort((a, b) => {
              const dateDiff =
                new Date(a[previewSelectDate.value]) -
                new Date(b[previewSelectDate.value]);
              return dateDiff !== 0
                ? dateDiff
                : parseInt(a[previewSelectValue.value]) -
                    parseInt(b[previewSelectValue.value]);
            });
            // Generate legend and series data
            finalData.forEach((item) => {
              const timestamp = new Date(item.tarih).getTime();
              const parametre = item.parametre;
              const deger = parseInt(item.deger);
              if (parametre) {
                // Check for valid parameter name
                // Prepare series data
                if (!seriesData[parametre]) {
                  seriesData[parametre] = [];
                }
                seriesData[parametre].push([timestamp, deger]);
                // Prepare legend data
                legendData.add(parametre);
              }
            });
            const getRandomBrightColor = () => {
              const r = Math.floor(Math.random() * 256);
              const g = Math.floor(Math.random() * 256);
              const b = Math.floor(Math.random() * 256);
              // Canlı renk elde etmek için RGB değerlerinin toplamı yüksek olmalı
              const brightness = r + g + b;
              return brightness > 384
                ? `rgba(${r}, ${g}, ${b}, 1)`
                : getRandomBrightColor(); // 255 * 3 = 765
            };
            // Convert legend and series data to arrays
            const legendArray = Array.from(legendData).filter(Boolean); // Remove undefined entries
            const seriesArray = legendArray.map((parametre) => {
              const color = getRandomBrightColor();
              return {
                name: parametre,
                type: "line",
                smooth: true,
                symbol: "none",
                areaStyle: {
                  color: `${color.replace(
                    /rgba\((\d+), (\d+), (\d+), 1\)/,
                    "rgba($1, $2, $3, 0.2)"
                  )}`,
                },
                lineStyle: { color: color },
                data: seriesData[parametre],
              };
            });
            setProcessedOptions((prevOptions) => ({
              ...prevOptions,
              darkMode: context?.theme === "light" ? false : true,
              legend: {
                data: legendArray,
                textStyle: {
                  color: context?.theme === "dark" ? "#ffffff" : "#000",
                },
              },
              dataZoom: [
                legendArray.map((item) => {
                  return {
                    type: "inside",
                    start: 0,
                    end: "100%",
                    textStyle: {
                      color: context?.theme === "dark" ? "#ffffff" : "#000",
                    },
                  };
                }),
              ],
              series: seriesArray,
            }));
          }

          setChartShow(true);
        } else if (chartView.value === 2) {
          if (
            filteredPreview.length !== 0 &&
            previewSelectLabel &&
            previewSelectValue
          ) {
            setProcessedOptions((prevOptions) => {
              const sortedData = [...mergedPreview].sort(
                (a, b) =>
                  a[previewSelectLabel.value] - b[previewSelectLabel.value]
              );
              return {
                ...prevOptions,
                darkMode: context?.theme === "light" ? false : true,
                visualMap: context?.chartColor
                  ? {
                      show: false,
                      min: Math.min(
                        ...sortedData.map(
                          (item) => item[previewSelectValue.value]
                        )
                      ),
                      max: Math.max(
                        ...sortedData.map(
                          (item) => item[previewSelectValue.value]
                        )
                      ),
                      inRange: {
                        colorLightness: [0.2, 1],
                      },
                    }
                  : false,
                xAxis: [
                  {
                    ...prevOptions.xAxis[0],
                    // Sıralanmış verilere göre xAxis etiketleri
                    data: sortedData.map(
                      (item) => item[previewSelectLabel.value]
                    ),
                  },
                ],
                series: [
                  {
                    ...prevOptions.series[0],
                    data:
                      (chartView?.value === 2 || chartView?.value === 17) &&
                      regressionCount > 0 &&
                      regressionFrequency &&
                      regressionType &&
                      futurePredictions.length !== 0
                        ? sortedData.map((item, index) => {
                            const dataValue = item[previewSelectValue.value];

                            if (index >= sortedData.length - regressionCount) {
                              return {
                                value: dataValue,
                                itemStyle: {
                                  color: "#003fff",
                                },
                              };
                            } else {
                              return {
                                value: dataValue,
                              };
                            }
                          })
                        : sortedData.map((item, index) => {
                            return {
                              value: item[previewSelectValue.value],
                            };
                          }),
                  },
                ],
              };
            });

            setChartShow(true);
          }
        } else if (chartView.value === 17) {
          if (
            filteredPreview.length !== 0 &&
            previewSelectLabel &&
            previewSelectValue
          ) {
            setProcessedOptions((prevOptions) => {
              const sortedData = [...mergedPreview].sort(
                (a, b) =>
                  a[previewSelectLabel.value] - b[previewSelectLabel.value]
              );
              return {
                ...prevOptions,
                darkMode: context?.theme === "light" ? false : true,
                visualMap: context?.chartColor
                  ? {
                      show: false,
                      min: Math.min(
                        ...sortedData.map(
                          (item) => item[previewSelectValue.value]
                        )
                      ),
                      max: Math.max(
                        ...sortedData.map(
                          (item) => item[previewSelectValue.value]
                        )
                      ),
                      inRange: {
                        colorLightness: [0.2, 1],
                      },
                    }
                  : false,
                xAxis: [
                  {
                    ...prevOptions.xAxis[0],
                    // Sıralanmış verilere göre xAxis etiketleri
                    data: sortedData.map(
                      (item) => item[previewSelectLabel.value]
                    ),
                  },
                ],
                series: [
                  (chartView?.value === 2 || chartView?.value === 17) &&
                  regressionCount > 0 &&
                  regressionFrequency &&
                  regressionType &&
                  futurePredictions.length !== 0
                    ? {
                        ...prevOptions.series[0],
                        lineStyle: {
                          color: "#003fff",
                        },
                        itemStyle: {
                          color: "#003fff",
                        },
                        data: sortedData.map((item, index) => {
                          return {
                            value: item[previewSelectValue.value],
                          };
                        }),
                      }
                    : null,
                  {
                    ...prevOptions.series[0],
                    data: sortedData
                      .map((item, index) => {
                        if (index >= sortedData.length - regressionCount) {
                          return null;
                        }

                        return {
                          value: item[previewSelectValue.value],
                        };
                      })
                      .filter(Boolean),
                  },
                ].filter((item) => item !== null),
              };
            });

            setChartShow(true);
          }
        } else if (chartView.value === 11) {
          if (
            filteredPreview.length !== 0 &&
            previewSelectDate &&
            previewSelectValue &&
            previewSelectLabel
          ) {
            const sortedData = [...filteredPreview].sort(
              (a, b) => a[previewSelectDate.value] - b[previewSelectDate.value]
            );

            const xAxis = [
              ...new Set(
                sortedData.map((item) => item[previewSelectDate.value])
              ),
            ];
            const legend = [
              ...new Set(
                sortedData.map((item) => item[previewSelectValue.value])
              ),
            ];
            const transformedArray = sortedData.reduce((acc, item) => {
              const existingModel = acc.find(
                (model) => model.name === item[previewSelectValue?.value]
              );
              if (existingModel) {
                existingModel.data.push(Number(item[previewSelectLabel.value]));
              } else {
                acc.push({
                  name: item[previewSelectValue?.value],
                  data: [Number(item[previewSelectLabel.value])],
                  type: "bar",
                  barGap: 0,
                  label: {
                    show: true,
                    rotate: 90,
                    align: "left",
                    verticalAlign: "middle",
                    position: "insideBottom",
                    distance: 15,
                    formatter: "{c} " + item[previewSelectValue?.value],
                    fontSize: 10,
                  },
                  emphasis: {
                    focus: "series",
                  },
                });
              }
              return acc;
            }, []);
            setProcessedOptions((prevOptions) => ({
              ...prevOptions,
              darkMode: context?.theme === "light" ? false : true,
              legend: {
                data: legend,
                itemGap: 20,
                textStyle: {
                  color: context?.theme === "dark" ? "#fff" : "#000",
                },
              },
              xAxis: [
                {
                  type: "category",
                  axisTick: { show: false },
                  data: xAxis,
                  axisLabel: {
                    textStyle: {
                      color: context?.theme === "dark" ? "#fff" : "#000",
                    },
                  },
                },
              ],
              series: transformedArray,
            }));

            setChartShow(true);
          }
        } else if (chartView.value === 13) {
          if (
            filteredPreview.length !== 0 &&
            previewSelectDate &&
            previewSelectValue &&
            previewSelectLabel
          ) {
            const sortedData = [...filteredPreview].sort(
              (a, b) => a[previewSelectDate.value] - b[previewSelectDate.value]
            );

            const xAxis = [
              ...new Set(
                sortedData.map((item) => item[previewSelectDate.value])
              ),
            ];
            const legend = [
              ...new Set(
                sortedData.map((item) => item[previewSelectValue.value])
              ),
            ];
            const transformedArray = sortedData.reduce((acc, item) => {
              const existingModel = acc.find(
                (model) => model.name === item[previewSelectValue?.value]
              );
              if (existingModel) {
                existingModel.data.push(Number(item[previewSelectLabel.value]));
              } else {
                acc.push({
                  name: item[previewSelectValue?.value],
                  data: [Number(item[previewSelectLabel.value])],
                  type: "bar",
                  stack: "total",
                  label: {
                    show: true,
                  },
                  emphasis: {
                    focus: "series",
                  },
                });
              }
              return acc;
            }, []);
            setProcessedOptions((prevOptions) => ({
              ...prevOptions,
              darkMode: context?.theme === "light" ? false : true,
              legend: {
                data: legend,
                itemGap: 20,
                textStyle: {
                  color: context?.theme === "dark" ? "#fff" : "#000",
                },
              },
              yAxis: {
                type: "category",
                data: xAxis,
                axisLabel: {
                  textStyle: {
                    color: context?.theme === "dark" ? "#fff" : "#000",
                  },
                },
              },
              series: transformedArray,
            }));

            setChartShow(true);
          }
        } else if (chartView.value === 14) {
          if (
            filteredPreview.length !== 0 &&
            previewSelectDate &&
            previewSelectValue &&
            previewSelectLabel
          ) {
            const sortedData = [...filteredPreview].sort(
              (a, b) => a[previewSelectDate.value] - b[previewSelectDate.value]
            );

            const xAxis = [
              ...new Set(
                sortedData.map((item) => item[previewSelectDate.value])
              ),
            ];
            const legend = [
              ...new Set(
                sortedData.map((item) => item[previewSelectValue.value])
              ),
            ];
            const transformedArray = sortedData.reduce((acc, item) => {
              const existingModel = acc.find(
                (model) => model.name === item[previewSelectValue?.value]
              );
              if (existingModel) {
                existingModel.data.push(Number(item[previewSelectLabel.value]));
              } else {
                acc.push({
                  name: item[previewSelectValue?.value],
                  data: [Number(item[previewSelectLabel.value])],
                  type: "bar",
                  stack: "total",
                  barWidth: "60%",
                  label: {
                    show: true,
                    formatter: "{c}",
                    fontSize: 10,
                  },
                  emphasis: {
                    focus: "series",
                  },
                });
              }
              return acc;
            }, []);
            setProcessedOptions((prevOptions) => ({
              ...prevOptions,
              darkMode: context?.theme === "light" ? false : true,
              legend: {
                data: legend,
                itemGap: 20,
                textStyle: {
                  color: context?.theme === "dark" ? "#fff" : "#000",
                },
              },
              xAxis: {
                type: "category",
                data: xAxis,
                axisLabel: {
                  textStyle: {
                    color: context?.theme === "dark" ? "#fff" : "#000",
                  },
                },
              },
              series: transformedArray,
            }));

            setChartShow(true);
          }
        } else if (chartView.value === 15) {
          if (
            filteredPreview.length !== 0 &&
            previewSelectDate &&
            previewSelectValue &&
            previewSelectLabel
          ) {
            const sortedData = [...filteredPreview].sort(
              (a, b) => a[previewSelectDate.value] - b[previewSelectDate.value]
            );

            const xAxis = [
              ...new Set(
                sortedData.map((item) => item[previewSelectDate.value])
              ),
            ];
            const legend = [
              ...new Set(
                sortedData.map((item) => item[previewSelectValue.value])
              ),
            ];
            const transformedArray = sortedData.reduce((acc, item) => {
              const existingModel = acc.find(
                (model) => model.name === item[previewSelectValue?.value]
              );
              if (existingModel) {
                existingModel.data.push(Number(item[previewSelectLabel.value]));
              } else {
                acc.push({
                  name: item[previewSelectValue?.value],
                  data: [Number(item[previewSelectLabel.value])],
                  type: "line",
                  stack: "Total",
                  label: {
                    show: true,
                    position: "top",
                  },
                  areaStyle: {},
                  emphasis: {
                    focus: "series",
                  },
                });
              }
              return acc;
            }, []);
            setProcessedOptions((prevOptions) => ({
              ...prevOptions,
              darkMode: context?.theme === "light" ? false : true,
              legend: {
                data: legend,
                itemGap: 20,
                textStyle: {
                  color: context?.theme === "dark" ? "#fff" : "#000",
                },
              },
              xAxis: [
                {
                  type: "category",
                  data: xAxis,
                  boundaryGap: false,
                  axisLabel: {
                    textStyle: {
                      color: context?.theme === "dark" ? "#fff" : "#000",
                    },
                  },
                },
              ],
              series: transformedArray,
            }));

            setChartShow(true);
          }
        } else if (chartView.value === 16) {
          if (
            filteredPreview.length !== 0 &&
            previewSelectDate &&
            previewSelectValue &&
            previewSelectLabel
          ) {
            const sortedData = [...filteredPreview].sort(
              (a, b) => a[previewSelectDate.value] - b[previewSelectDate.value]
            );

            const xAxis = [
              ...new Set(
                sortedData.map((item) => item[previewSelectDate.value])
              ),
            ];
            const legend = [
              ...new Set(
                sortedData.map((item) => item[previewSelectValue.value])
              ),
            ];
            const transformedArray = sortedData.reduce((acc, item) => {
              const existingModel = acc.find(
                (model) => model.name === item[previewSelectValue?.value]
              );
              if (existingModel) {
                existingModel.data.push(Number(item[previewSelectLabel.value]));
              } else {
                acc.push({
                  name: item[previewSelectValue?.value],
                  data: [Number(item[previewSelectLabel.value])],
                  type: "line",
                  stack: "Total",
                  label: {
                    show: true,
                    position: "top",
                  },
                  emphasis: {
                    focus: "series",
                  },
                });
              }
              return acc;
            }, []);
            setProcessedOptions((prevOptions) => ({
              ...prevOptions,
              darkMode: context?.theme === "light" ? false : true,
              legend: {
                data: legend,
                itemGap: 20,
                textStyle: {
                  color: context?.theme === "dark" ? "#fff" : "#000",
                },
              },
              xAxis: [
                {
                  type: "category",
                  data: xAxis,
                  boundaryGap: false,
                  axisLabel: {
                    textStyle: {
                      color: context?.theme === "dark" ? "#fff" : "#000",
                    },
                  },
                },
              ],
              series: transformedArray,
            }));

            setChartShow(true);
          }
        }
      }
    },
    [
      chartView,
      previewData,
      previewSelectValue,
      previewSelectLabel,
      regressionCount,
      regressionFrequency,
      regressionType,
    ]
  );

  useEffect(() => {
    if (chartView) {
      const selectedOption = activeChartOptions.find(
        (item) => item.id === chartView.value
      );

      setProcessedOptions({
        ...selectedOption,
      });
      handlePreview(selectedOption); // Seçili chartOptions'u handlePreview'e gönder
    }
  }, [regressionType, context?.theme]);

  return (
    chartShow &&
    processedOptions && (
      <ReactECharts
        ref={refItem}
        option={processedOptions}
        className="w-100 h-100"
      />
    )
  );
}
