import { useContext, useEffect, useState } from "react";

import Select from "react-select";

import { NavItem } from "./nav-item.jsx";
import { AppSettings } from "../../config/app-settings.js";
import { Link } from "react-router-dom";
import apiClient from "../../ApiClient.js";

function SidebarNav({ filteredMenu, setAssignmentGroupData }) {
  const context = useContext(AppSettings);
  const [activeIndex, setActiveIndex] = useState(null);
  const [valueCustomer, setValueCustomer] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  async function handleCustomerButton(e, id) {
    context.getPages(id);
    setValueCustomer(e);

    context.setActiveCustomer(id);
  }

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (menuId) => {
    setActiveMenu(activeMenu === menuId ? null : menuId);
  };

  //-- Fetch Groups
  const [birimlerList, setBirimlerList] = useState(null);

  async function handleGroupShowClick(item) {
    if (item) {
      try {
        const response = await apiClient.get(
          `/pages/assignment?group_id=${item.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response?.data) {
          // Rekürsif olarak tüm birimlerin children'larını kontrol eden fonksiyon
          const addChildrenToMatchingBirim = (birimler) => {
            return birimler.map((birim) => {
              // Eğer şu anki birim id'si eşleşiyorsa children'larına veriyi ekle
              if (birim.id === item.id) {
                return {
                  ...birim,
                  children: [...birim.children, ...response.data],
                };
              }

              // Eğer bu birimin children'ları varsa, onları da kontrol et
              if (birim.children && birim.children.length > 0) {
                return {
                  ...birim,
                  children: addChildrenToMatchingBirim(birim.children), // Rekürsif çağrı
                };
              }

              return birim;
            });
          };

          // Birimler listesini güncelle
          const updatedBirimlerList = addChildrenToMatchingBirim(birimlerList);

          // Yeni birimler listesini set et
          setBirimlerList(updatedBirimlerList);
        }
      } catch (error) {
        console.error("Birimler", error);
      }
    }
  }

  async function fetchBirimler() {
    setBirimlerList(null);

    try {
      const response = await apiClient.get(`/groups`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (context?.activeCustomer) {
        setBirimlerList(
          response.data.filter(
            (item) => item.customer_id === context?.activeCustomer
          )
        );
      } else {
        setBirimlerList(response.data);
      }
    } catch (error) {
      setBirimlerList([]);
      console.error("Birimler", error);
    }
  }

  useEffect(() => {
    if (context?.me) {
      fetchBirimler();
    }
  }, [context?.me, context?.activeCustomer]);

  return (
    <div className="menu">
      {context &&
        context?.me?.role?.id === 1 &&
        context.allCustomerSelect &&
        context.allCustomerSelect.length !== 0 && (
          <div>
            <div className="mx-3">
              <Select
                options={[
                  // {
                  //   value: null,
                  //   label: "Admin",
                  // },
                  ...context.allCustomerSelect
                    .sort((a, b) => a.value - b.value)
                    .map((customer) => {
                      return {
                        value: customer.value,
                        label: customer.label,
                      };
                    }),
                ]}
                placeholder={context?.t?.choose_customer}
                classNamePrefix="react-select"
                noOptionsMessage={() => context?.t?.no_option}
                value={valueCustomer}
                onChange={(e) => handleCustomerButton(e, e.value)}
              />
            </div>
          </div>
        )}

      {!filteredMenu && (
        <div className="mx-3 mt-3">
          <div className="spinner-border spinner-border-sm text-theme"></div>
        </div>
      )}

      {context?.me && filteredMenu && filteredMenu.length !== 0 && (
        <>
          {context?.me?.role?.id === 8 && (
            <>
              <div className="d-flex align-items-center justify-content-between menu-header pointer">
                {context?.t?.ozel_sayfalar}
                <div className="active menu-caret">
                  <b className="caret"></b>
                </div>
              </div>

              {context?.assignmentGroupData &&
              context?.assignmentGroupData.length !== 0 ? (
                <div className="menu-first-dropdown">
                  {context?.assignmentGroupData
                    .sort((a, b) => a.order - b.order)
                    .map((menu, i) => (
                      <NavItem
                        key={i}
                        menu={menu}
                        isActive={i + "_group_page" === activeIndex}
                        onClick={() => handleItemClick(i + "_group_page")}
                      />
                    ))}
                </div>
              ) : (
                <span className="px-3">{context?.t?.create_page}</span>
              )}
            </>
          )}

          {/* -- */}

          {context?.me?.role?.id !== 5 &&
            filteredMenu.filter(
              (menu) =>
                menu.page_type === "default" &&
                !menu.iframe &&
                menu.name !== "Anket Oluştur" &&
                menu.name !== "Devam Edenler" &&
                menu.name !== "Tamamlananlar" &&
                menu.name !== "Veri Girişi" &&
                menu.name !== "Hazır Raporlar" &&
                menu.name !== "Afet Yönetimi" &&
                menu.name !== "Araştırma Raporu"
            ).length !== 0 && (
              <div
                className="d-flex align-items-center justify-content-between menu-header pointer"
                onClick={() => toggleMenu(1)}
              >
                {context?.t?.genel_gorunum}
                <div
                  className={`menu-caret ${activeMenu === 1 ? "active" : ""}`}
                >
                  <b className="caret"></b>
                </div>
              </div>
            )}
          {activeMenu === 1 && (
            <div className="menu-first-dropdown">
              {filteredMenu
                .filter(
                  (menu) =>
                    menu.page_type === "default" &&
                    !menu.iframe &&
                    menu.name !== "Anket Oluştur" &&
                    menu.name !== "Devam Edenler" &&
                    menu.name !== "Tamamlananlar" &&
                    menu.name !== "Veri Girişi" &&
                    menu.name !== "Hazır Raporlar" &&
                    menu.name !== "Afet Yönetimi" &&
                    menu.name !== "Araştırma Raporu"
                )
                .sort((a, b) => a.order - b.order)
                .map((menu, i) => {
                  if (
                    (menu.name === "Seçim Analizi" &&
                      context?.me?.customer?.settings?.secimAnalizi ===
                        false) ||
                    (menu.name === "Sandık Analizi" &&
                      context?.me?.customer?.settings?.sandikAnalizi === false)
                  ) {
                    return null;
                  }
                  return (
                    <NavItem
                      key={i}
                      menu={menu}
                      isActive={i + "_default" === activeIndex}
                      onClick={() => handleItemClick(i + "_default")}
                    />
                  );
                })}
            </div>
          )}

          {context?.me &&
            (context?.me?.role?.id === 1 || context?.me?.role?.id === 2) && (
              <>
                <div
                  className="d-flex align-items-center justify-content-between menu-header pointer"
                  onClick={() => toggleMenu(2)}
                >
                  {context?.t?.ileri_analiz}
                  <div
                    className={`menu-caret ${activeMenu === 2 ? "active" : ""}`}
                  >
                    <b className="caret"></b>
                  </div>
                </div>
                {activeMenu === 2 && (
                  <div className="menu-first-dropdown">
                    {filteredMenu.filter(
                      (menu) => menu.page_type === "default" && menu.iframe
                    ).length !== 0 ? (
                      filteredMenu
                        .filter(
                          (menu) => menu.page_type === "default" && menu.iframe
                        )
                        .sort((a, b) => a.order - b.order)
                        .map((menu, i) => (
                          <NavItem
                            key={i}
                            menu={menu}
                            isActive={i + "_default_iframe" === activeIndex}
                            onClick={() =>
                              handleItemClick(i + "_default_iframe")
                            }
                          />
                        ))
                    ) : (
                      <span className="px-3">
                        {context?.t?.analiz_icin_iletisime_gecin}
                      </span>
                    )}
                  </div>
                )}
              </>
            )}

          {filteredMenu.filter((menu) => menu.page_type === "custom").length !==
            0 && (
            <div
              className="d-flex align-items-center justify-content-between menu-header pointer"
              onClick={() => toggleMenu(3)}
            >
              {context?.t?.ozel_sayfalar}
              <div className={`menu-caret ${activeMenu === 3 ? "active" : ""}`}>
                <b className="caret"></b>
              </div>
            </div>
          )}

          {activeMenu === 3 && (
            <div className="menu-first-dropdown">
              {filteredMenu
                .filter(
                  (menu) => menu.parent_id !== 44 && menu.page_type === "custom"
                )
                .sort((a, b) => a.order - b.order)
                .map((menu, i) => (
                  <NavItem
                    key={i}
                    menu={menu}
                    isActive={i + "_custom" === activeIndex}
                    onClick={() => handleItemClick(i + "_custom")}
                  />
                ))}
            </div>
          )}

          {context?.me?.role?.id !== 3 &&
            filteredMenu.filter(
              (menu) =>
                menu.name === "Anket Oluştur" ||
                menu.name === "Devam Edenler" ||
                menu.name === "Tamamlananlar"
            ).length !== 0 && (
              <div
                className="d-flex align-items-center justify-content-between menu-header pointer"
                onClick={() => toggleMenu(5)}
              >
                {context?.t?.anket_ve_arastirmalar}
                <div
                  className={`menu-caret ${activeMenu === 5 ? "active" : ""}`}
                >
                  <b className="caret"></b>
                </div>
              </div>
            )}

          {activeMenu === 5 && (
            <div className="menu-first-dropdown">
              {filteredMenu
                .filter(
                  (menu) =>
                    menu.name === "Anket Oluştur" ||
                    menu.name === "Devam Edenler" ||
                    menu.name === "Tamamlananlar"
                )
                .sort((a, b) => a.order - b.order)
                .map((menu, i) => (
                  <NavItem
                    key={i}
                    menu={menu}
                    isActive={i + "_islemler_default" === activeIndex}
                    onClick={() => handleItemClick(i + "_islemler_default")}
                  />
                ))}
            </div>
          )}

          {context &&
            context?.pages &&
            context?.pages.length !== 0 &&
            context?.pages.some((item) => item.id === 83) && (
              <Link
                to="/afet-yonetimi"
                className="d-flex align-items-center justify-content-between menu-header pointer text-decoration-none"
              >
                {context?.t?.disaster_management}

                {/* <div className="menu-caret">
                  <b className="caret"></b>
                </div> */}
              </Link>
            )}

          {birimlerList &&
            (context?.me?.role?.id === 1 ||
              context?.me?.role?.id === 2 ||
              context?.me?.role?.id === 4) && (
              <>
                <div
                  className="d-flex align-items-center justify-content-between menu-header pointer"
                  onClick={() => toggleMenu(6)}
                >
                  {context?.t?.departman_sayfalari}
                  <div
                    className={`menu-caret ${activeMenu === 6 ? "active" : ""}`}
                  >
                    <b className="caret"></b>
                  </div>
                </div>

                {activeMenu === 6 && (
                  <div className="menu-first-dropdown">
                    {birimlerList.map((menu, i) => (
                      <NavItem
                        key={i}
                        menu={menu}
                        isActive={menu.id + "_groups_pages" === activeIndex}
                        onClick={(id) => handleItemClick(id + "_groups_pages")}
                        handleGroupShowClick={handleGroupShowClick}
                        events={false}
                      />
                    ))}
                  </div>
                )}
              </>
            )}

          {(context?.me?.role?.id === 1 ||
            context?.me?.role?.id === 2 ||
            context?.me?.role?.id === 4) && (
            <Link
              to="/hiyerarsik-yapi"
              className="d-flex align-items-center justify-content-between menu-header pointer text-decoration-none"
            >
              {context?.t?.hiyerarsik_yapi}
            </Link>
          )}

          {(context?.me?.role?.id === 1 ||
            context?.me?.role?.id === 2 ||
            context?.me?.role?.id === 4) && (
            <Link
              to="/kullanicilar"
              className="d-flex align-items-center justify-content-between menu-header pointer text-decoration-none"
            >
              {context?.t?.users}
            </Link>
          )}

          {context?.me?.role?.id !== 3 &&
            filteredMenu.filter((menu) => menu.name === "Veri Girişi")
              .length !== 0 &&
            filteredMenu
              .filter((menu) => menu.name === "Veri Girişi")
              .sort((a, b) => a.order - b.order)
              .map((menu, i) => (
                <Link
                  to={"/yuklenen-dosyalar"}
                  className="d-flex align-items-center justify-content-between menu-header pointer text-decoration-none"
                >
                  {menu.name === "Veri Girişi"
                    ? context?.t?.veri_girisi
                    : menu.name}
                </Link>
              ))}

          {context?.me?.role?.id !== 3 &&
            filteredMenu.filter(
              (menu) =>
                menu.name === "Hazır Raporlar" ||
                menu.name === "Araştırma Raporu"
            ).length !== 0 && (
              <div
                className="d-flex align-items-center justify-content-between menu-header pointer"
                onClick={() => toggleMenu(4)}
              >
                {context?.t?.transactions}
                <div
                  className={`menu-caret ${activeMenu === 4 ? "active" : ""}`}
                >
                  <b className="caret"></b>
                </div>
              </div>
            )}

          {activeMenu === 4 && (
            <div className="menu-first-dropdown">
              {filteredMenu
                .filter(
                  (menu) =>
                    menu.name === "Hazır Raporlar" ||
                    ((context?.me?.role?.id === 1 ||
                      context?.me?.role?.id === 5) &&
                      menu.name === "Araştırma Raporu")
                )
                .sort((a, b) => a.order - b.order)
                .map((menu, i) => (
                  <NavItem
                    key={i}
                    menu={menu}
                    isActive={i + "_islemler_default" === activeIndex}
                    onClick={() => handleItemClick(i + "_islemler_default")}
                  />
                ))}
            </div>
          )}

          <div className="menu-other-dropdown">
            {filteredMenu
              .filter((menu) => {
                if (context?.me?.role?.id !== 1 && menu.is_header) {
                  menu.name = "";
                }
                return !menu.page_type;
              })
              .sort((a, b) => a.order - b.order)
              .map((menu, i) => (
                <NavItem
                  key={i}
                  menu={menu}
                  isActive={i + "_global" === activeIndex}
                  onClick={() => handleItemClick(i + "_global")}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default SidebarNav;
