import React, { useContext } from "react";

import { AppSettings } from "../../config/app-settings";

import PageIframe from "./pageIframe";

export default function KentmetreRaporu3() {
  const context = useContext(AppSettings);

  return (
    <>
      {context?.me?.customer?.reports.length !== 0 &&
      context?.me?.customer?.reports[2] ? (
        <PageIframe
          reportID={context?.me?.customer?.reports[2]?.value}
          api_key={context?.me?.customer?.api_key}
        />
      ) : (
        <span>3. Rapor eklenmemiştir.</span>
      )}
    </>
  );
}
