import React, { useContext, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardExpandToggler,
} from "../../components/card/card.jsx";
import apiClient from "../../ApiClient.js";
import toastMessage from "../../components/ToastMessage.js";
import { AppSettings } from "../../config/app-settings.js";
import { useNavigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import Fancybox from "../../components/Fancybox.jsx";
import StratejiHaritasi from "../strateji-haritasi/index.jsx";
import moment from "moment";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";

const center = { lat: 39.1667, lng: 35.6667 };
const defaultZoom = 5.8;
let options;
setTimeout(() => {
  options = {
    center,
    zoom: defaultZoom,
    fullscreenControl: false,
    styles:
      document.querySelector("html").getAttribute("data-bs-theme") === "light"
        ? []
        : [
            {
              featureType: "all",
              elementType: "all",
              stylers: [
                { invert_lightness: true },
                { saturation: -50 },
                { lightness: 40 },
                { gamma: 0.5 },
                {
                  hue: window.location.host.includes("datakokpit")
                    ? "#17248a"
                    : "#3cd2a5",
                },
              ],
            },
          ],
  };
}, 3000);

export default function KentmetreMap({
  setCityFilter,
  setDistrictFilter,
  setNeighbourhoodFilter,
  tab = true,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  // Get Locations
  const [locationData, setLocationData] = useState(null);
  const [reportID, setReportID] = useState(null);

  const [selectedMarker, setSelectedMarker] = useState(null);

  async function getLocations(reportID) {
    if (reportID) {
      setLocationData(null);

      try {
        const response = await apiClient.get(
          `https://api.kentmetre.com/v1.0/map/${reportID}`
        );

        if (response.status === 200) {
          setLocationData(response.data);
        }
      } catch (error) {
        console.error("Kentmetre Locations Error", error);
        setLocationData([]);
        setReportID(null);

        if (error.response.status === 500) {
          toastMessage("500 | Sunucu Hatası!", "error");
        } else if (error.response.status === 404) {
          // toastMessage("404 | Bulunamadı!", "error");
        } else if (error.response.status === 403) {
          toastMessage("Çok fazla istek atıldı.", "error");
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        } else {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    } else {
      setLocationData([]);
    }
  }

  useEffect(() => {
    getLocations(reportID);
  }, [reportID]);

  // Marker Detail
  const [mapDetailData, setMapDetailData] = useState(null);
  const [mapDetailAddressData, setMapDetailAddressData] = useState(null);

  async function getMapDetailData(id) {
    if (id) {
      setMapDetailData(null);
      setMapDetailAddressData(null);

      document.getElementById("opinionModalClose").click();
      document.getElementById("kentmetreMapDetailModalButton").click();

      try {
        const response = await apiClient.get(
          `https://api.kentmetre.com/v1.0/uuid-report/${id}`
        );

        if (response.status === 200) {
          setMapDetailData(response?.data);
        }
      } catch (error) {
        console.error("Kentmetre Modal Detay Error", error);
        setMapDetailData([]);

        if (error.response.status === 500) {
          // toastMessage("500 | Sunucu Hatası!", "error");
        } else if (error.response.status === 404) {
          toastMessage("404 | Bulunamadı!", "error");
        } else if (error.response.status === 403) {
          toastMessage("Çok fazla istek atıldı.", "error");
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        } else {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }

      try {
        const response = await apiClient.get(
          `https://api.kentmetre.com/v1.0/uuid-address/${id}`
        );

        if (response.status === 200) {
          setMapDetailAddressData(response?.data);
        }
      } catch (error) {
        console.error("Kentmetre Modal Detay Error", error);
        setMapDetailAddressData([]);

        if (error.response.status === 500) {
          toastMessage("500 | Sunucu Hatası!", "error");
        } else if (error.response.status === 404) {
          // toastMessage("404 | Bulunamadı!", "error");
        } else if (error.response.status === 403) {
          toastMessage("Çok fazla istek atıldı.", "error");
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        } else {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  }

  // Opinion Data
  const [opinionData, setOpinionData] = useState(null);

  async function getOpinionData(id) {
    if (id) {
      setOpinionData(null);

      try {
        const response = await apiClient.get(
          `https://api.kentmetre.com/v1.0/opinion/detail/${id}`
        );

        if (response.status === 200) {
          setOpinionData(response.data);
        }
      } catch (error) {
        console.error("Kentmetre Şikayet Error", error);
        setOpinionData([]);

        if (error.response.status === 500) {
          toastMessage("500 | Bulunamadı", "error");
        } else if (error.response.status === 404) {
          // toastMessage("404 | Bulunamadı!", "error");
        } else if (error.response.status === 403) {
          toastMessage("Çok fazla istek atıldı.", "error");
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        } else {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  }

  return (
    <>
      <div className={`position-relative h-100 ${!tab ? "pb-5" : ""}`}>
        <Card className={`${"h-100"}`}>
          <CardBody className="d-flex flex-column">
            {!tab && (
              <div className="map-zoom-item">
                <CardExpandToggler />
              </div>
            )}

            <div className="flex-1 tab-content mt-2">
              {tab && (
                <div
                  className="tab-pane fade show active h-100"
                  id="strategy-map"
                  role="tabpanel"
                  aria-labelledby="strategy-map-tab"
                >
                  <StratejiHaritasi
                    setCityFilter={setCityFilter}
                    setDistrictFilter={setDistrictFilter}
                    setNeighbourhoodFilter={setNeighbourhoodFilter}
                    card={true}
                    scrum={false}
                  />
                </div>
              )}

              {context.me?.customer?.reports[0] ? (
                <div
                  className={`tab-pane fade h-100 ${!tab ? "show active" : ""}`}
                  id="kentmetre-map"
                  role="tabpanel"
                  aria-labelledby="kentmetre-map-tab fade"
                >
                  <svg
                    className="live-svg-map live-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 100"
                  >
                    <polyline
                      fill="none"
                      strokeWidth="3"
                      stroke={
                        window.location.host.includes("datakokpit")
                          ? "#17248a"
                          : "#3cd2a5"
                      }
                      points="2.4,58.7 70.8,58.7 76.1,46.2 81.1,58.7 89.9,58.7 93.8,66.5 102.8,22.7 110.6,78.7 115.3,58.7 126.4,58.7 134.4,54.7 142.4,58.7 197.8,58.7 "
                    />
                  </svg>

                  <div className="d-flex flex-column h-100">
                    <div className="row mb-3 me-4">
                      <div className="col">
                        <div className="row g-2">
                          {context.me ? (
                            context.me?.customer?.reports.map(
                              (item, index) =>
                                item &&
                                index <= 2 && (
                                  <div className="col" key={index}>
                                    <button
                                      className={`btn btn${
                                        reportID === item.value
                                          ? "-outline"
                                          : ""
                                      }-theme w-100 btn-lg`}
                                      onClick={() =>
                                        setReportID(parseInt(item.value))
                                      }
                                    >
                                      {item.label}
                                    </button>
                                  </div>
                                )
                            )
                          ) : (
                            <div className="spinner-border text-theme"></div>
                          )}
                        </div>
                      </div>

                      {locationData ? (
                        locationData.length !== 0 && (
                          <div
                            className="col w-auto border-start"
                            style={{ flex: "none" }}
                          >
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#opinionModal"
                              className="btn btn-theme btn-lg"
                              onClick={() => getOpinionData(reportID)}
                            >
                              İstekler Şikayetler ve Notlar
                            </button>
                          </div>
                        )
                      ) : (
                        <div className="spinner-border text-theme"></div>
                      )}
                    </div>

                    <div className="flex-grow-1">
                      {locationData ? (
                        locationData.length !== 0 ? (
                          <GoogleMap
                            mapContainerStyle={{
                              height: "100%",
                              width: "100%",
                            }}
                            options={options}
                          >
                            <MarkerClusterer
                              options={{
                                maxZoom: 20, // Set this to control when clusters should stop grouping markers
                                styles: [
                                  {
                                    textColor: "white", // Set the cluster text color to white
                                    url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
                                    height: 50,
                                    width: 50,
                                  },
                                  {
                                    textColor: "white",
                                    url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png",
                                    height: 56,
                                    width: 56,
                                  },
                                  {
                                    textColor: "white",
                                    url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
                                    height: 66,
                                    width: 66,
                                  },
                                ],
                              }}
                            >
                              {(clusterer) =>
                                Object.keys(locationData).map((key) => {
                                  const item = locationData[key];

                                  if (item.latitude && item.longitude) {
                                    return (
                                      <Marker
                                        key={key}
                                        position={{
                                          lat: parseFloat(item.latitude),
                                          lng: parseFloat(item.longitude),
                                        }}
                                        clusterer={clusterer}
                                        onClick={() =>
                                          setSelectedMarker({
                                            key,
                                            position: {
                                              lat: parseFloat(item.latitude),
                                              lng: parseFloat(item.longitude),
                                            },
                                          })
                                        }
                                      />
                                    );
                                  }
                                  return null;
                                })
                              }
                            </MarkerClusterer>

                            {selectedMarker && (
                              <InfoWindow
                                position={selectedMarker.position}
                                onCloseClick={() => setSelectedMarker(null)}
                              >
                                <div className="me-2 text-center">
                                  <span className="d-block">
                                    {selectedMarker.key}
                                  </span>
                                  <button
                                    id="detailButton"
                                    className="btn btn-theme mt-1"
                                    onClick={() =>
                                      getMapDetailData(selectedMarker.key)
                                    }
                                  >
                                    Ölçek Detay
                                  </button>
                                </div>
                              </InfoWindow>
                            )}
                          </GoogleMap>
                        ) : (
                          <span className="h1 d-flex align-items-center justify-content-center h-100 text-center px-4">
                            Haritayı görüntülemek için
                            <br /> bir rapor seçin
                          </span>
                        )
                      ) : (
                        <div className="spinner-border text-theme"></div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <span>Rapor Eklenmemiştir</span>
              )}
            </div>
          </CardBody>
        </Card>
      </div>

      <div className="modal modal-cover fade" id="opinionModal">
        <div className="modal-dialog modal-xl py-4">
          <div
            className="modal-content rounded"
            style={{ background: "var(--bs-dark4)" }}
          >
            <div className="modal-header px-4 border-bottom">
              <h3 className="modal-title">İstekler Şikayetler ve Notlar</h3>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="opinionModalClose"
              ></button>
            </div>

            <div className="modal-body py-4">
              {opinionData ? (
                opinionData.length !== 0 ? (
                  opinionData.map((item, index) => (
                    <div className="mb-4" key={index}>
                      <div className="d-flex">
                        <div
                          className="text-white fw-bold"
                          style={{
                            minWidth: "60px",
                            fontSize: "24px",
                          }}
                        >
                          {index + 1}.
                        </div>

                        <div className="flex-1">
                          {item.complaint && (
                            <div className="border p-4">
                              <h4 className="mb-3">Şikayet</h4>
                              <h5 className="mb-3">{item.complaint || "-"}</h5>

                              {item.complaintImage && (
                                <Fancybox
                                  options={{
                                    Carousel: {
                                      infinite: false,
                                    },
                                  }}
                                >
                                  <a data-fancybox href={item.complaintImage}>
                                    <img
                                      width={300}
                                      src={item.complaintImage}
                                    />
                                  </a>
                                </Fancybox>
                              )}

                              <h3 className="mb-2 border-top pt-4 mt-4">
                                {item?.uuid || "-"}
                              </h3>
                              <button
                                type="button"
                                className="btn btn-theme btn-lg"
                                onClick={() => getMapDetailData(item.uuid)}
                              >
                                Ölçeğe Git
                              </button>
                            </div>
                          )}

                          {item.note && (
                            <div className="border p-4">
                              <h4 className="mb-3">Not</h4>
                              <h4 className="mb-3">{item?.note || "-"}</h4>

                              {item.noteImage && (
                                <Fancybox
                                  options={{
                                    Carousel: {
                                      infinite: false,
                                    },
                                  }}
                                >
                                  <a data-fancybox href={item.noteImage}>
                                    <img width={300} src={item.noteImage} />
                                  </a>
                                </Fancybox>
                              )}
                            </div>
                          )}

                          {item?.suggest && (
                            <div className="border p-4">
                              <h4 className="mb-3">Öneri</h4>
                              <h4 className="mb-3">{item?.suggest || "-"}</h4>

                              {item.suggestImage && (
                                <Fancybox
                                  options={{
                                    Carousel: {
                                      infinite: false,
                                    },
                                  }}
                                >
                                  <a data-fancybox href={item.suggestImage}>
                                    <img width={300} src={item.suggestImage} />
                                  </a>
                                </Fancybox>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="d-block text-center">
                    Veri Bulunmamaktadır
                  </span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )}
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#kentmetreMapDetailModal"
        className="d-none"
        id="kentmetreMapDetailModalButton"
      >
        Ölçeği Görüntüle
      </button>

      <div className="modal modal-cover fade" id="kentmetreMapDetailModal">
        <div className="modal-dialog modal-xl py-4">
          <div
            className="modal-content rounded"
            style={{ background: "var(--bs-dark4)" }}
          >
            <div className="modal-header px-4 border-bottom">
              <div>
                {mapDetailData ? (
                  <h3 className="modal-title">
                    Personel:{" "}
                    <span className="text-theme">
                      {mapDetailData?.person?.name}{" "}
                      {mapDetailData?.person?.lastname}
                    </span>
                    <br />
                    Personel E-mail:{" "}
                    <a href={`mailto:${mapDetailData?.person?.email}`}>
                      {mapDetailData?.person?.email}
                    </a>
                  </h3>
                ) : (
                  <div className="spinner-border text-theme"></div>
                )}

                {mapDetailAddressData ? (
                  <>
                    <h4 className="modal-title">
                      Adres:{" "}
                      <span className="text-theme">
                        {mapDetailAddressData?.building}{" "}
                        {mapDetailAddressData?.gate}
                        <br />
                        {mapDetailAddressData?.neighbourhood}{" "}
                        {mapDetailAddressData?.street}{" "}
                        {mapDetailAddressData?.district
                          ? mapDetailAddressData?.district.toUpperCase()
                          : "-"}
                        /{mapDetailAddressData?.city || "-"}
                      </span>
                    </h4>
                    <h4 className="modal-title">
                      Oluşturulma Tarihi:{" "}
                      <span className="text-theme">
                        {moment(mapDetailAddressData?.created_at).format(
                          "DD MMMM YYYY - HH:mm"
                        )}
                      </span>
                    </h4>
                  </>
                ) : (
                  <div className="spinner-border text-theme"></div>
                )}
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <h5 className="mt-2 mb-4">Ölçek Cevapları</h5>

              {mapDetailData ? (
                mapDetailData.length !== 0 ? (
                  mapDetailData?.replies.map((item, index) => (
                    <div key={index} className="mb-4">
                      <table className="border w-100">
                        <tbody>
                          <tr>
                            <td
                              className="border p-3 fw-bold"
                              style={{ width: 100 }}
                            >
                              Soru {index + 1}
                            </td>
                            <td className="border p-3 fw-bold">
                              {item?.question?.question?.text || ""}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="border p-3 fw-bold"
                              style={{ width: 100 }}
                            >
                              Cevap
                            </td>
                            <td className="border p-3 fw-bold">
                              {item?.text
                                ? item?.text
                                : item?.answer?.answer?.text || ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))
                ) : (
                  <span className="d-block text-center">
                    Veri Bulunmamaktadır
                  </span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
}
