import React, { useContext, useEffect, useState } from "react";

import ReactECharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AppSettings } from "../../config/app-settings";
import PageChartCard from "../page-chart-card/pageChartCard";
import apiClient from "../../ApiClient";
import toastMessage from "../ToastMessage";
import { Card, CardBody } from "../card/card";

import demografiJson from "../../json/demografi.json";
import bireyselJson from "../../json/bireysel.json";
import egitimJson from "../../json/egitim.json";
import siyasetmetreAnalizJson from "../../json/siyasetmetre_analiz.json";

const ChartBar = ({
  show = true,
  setNeighborhoodAgeData = false,
  id = null,
  title,
  path = "home",
  city,
  district,
  neighbourhood,
  street,
  graphic,
  clean = false,
  card,
  type = 1,
  titleLength = false,
  optionData = false,
}) => {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [xAxisData, setXAxisData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [chartColor, setChartColor] = useState(null);

  useEffect(() => {
    if (context) {
      setChartColor(context?.chartColor);
    }
  }, [context]);

  var colors = [
    "#5470c6",
    "#91cc75",
    "#fac858",
    "#ee6666",
    "#3ba272",
    "#73c0de",
    "#9a60b4",
    "#5470c6",
    "#ea7ccc",
    "#91cc75",
    "#fac858",
    "#73c0de",
    "#ee6666",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
  ];

  function popRandomColor() {
    var rand = Math.random();
    var color = colors[Math.floor(rand * colors.length)];
    colors.splice(Math.floor(rand * colors.length), 1);
    return color;
  }

  const option = {
    tooltip: {
      trigger: "axis",
    },
    title: {
      show: clean,
      text: title,
      left: "center",
      textStyle: {
        color: "#fff",
        fontSize: 13,
      },
    },
    darkMode: true,
    // legend: {
    //   show: false,
    //   data: ["Rainfall", "Evaporation"],
    //   textStyle: {
    //     color: "#fff",
    //   },
    // },
    toolbox: {
      show: !clean,
      feature: {
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
      iconStyle: { borderColor: "#fff" },
    },
    visualMap: !chartColor
      ? false
      : {
          show: false,
          min: 5,
          max: 50,
          inRange: {
            colorLightness: [0.5, 3],
          },
        },
    calculable: true,
    xAxis: [
      {
        type: "category",
        // prettier-ignore
        data: xAxisData,
        axisLabel: {
          rotate: 45,
          textStyle: {
            color: "#fff",
            fontSize: 10,
          },
          formatter: function (value, index) {
            return value.substring(0, titleLength ? titleLength : 7) + "...";
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          textStyle: {
            color: "#fff",
          },
        },
      },
    ],
    grid: { bottom: "20%" },
    series: [
      {
        name: "Birey Sayısı",
        type: "bar",
        data: seriesData,
        markPoint: {
          data: [
            { type: "max", name: "Max" },
            { type: "min", name: "Min" },
            // { name: "Max", value: 10, },
            // { name: "Min", value: 0, },
          ],
        },
        itemStyle: {
          color: chartColor,
        },
      },
      // {
      //   name: "Evaporation",
      //   type: "bar",
      //   data: [
      //     2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
      //   ],
      //   markPoint: {
      //     data: [
      //       { name: "Max", value: 182.2, xAxis: 7, yAxis: 183 },
      //       { name: "Min", value: 2.3, xAxis: 11, yAxis: 3 },
      //     ],
      //   },
      //   markLine: {
      //     data: [{ type: "average", name: "Avg" }],
      //   },
      //   itemStyle: {
      //     color: "#3cd2a5",
      //   },
      // },
    ],
  };

  async function getData() {
    setData(null);

    try {
      const response = await apiClient.get(
        `/${path}?graphic=${graphic}${city ? `&city=${city}` : ""}${
          district ? `&district=${district}` : ""
        }${neighbourhood ? `&neighbourhood=${neighbourhood}` : ""}${
          street ? `&street=${street}` : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      let keysArray = [];
      let valuesArray = [];

      if (response.status === 200) {
        if (response.data[0].length) {
          response.data[0].forEach(function (obj) {
            var keys = Object.keys(obj);
            var values = Object.values(obj);

            keysArray = keysArray.concat(
              siyasetmetreAnalizJson.parametre[keys]
            );

            valuesArray = valuesArray.concat({
              value: parseFloat(values),
              itemStyle: {
                color: !chartColor ? popRandomColor() : chartColor,
              },
            });
          });
        } else {
          [response.data[0]].forEach(function (obj) {
            var keys = Object.keys(obj);
            var values = Object.values(obj);

            keysArray = keysArray.concat(keys);
            valuesArray = valuesArray.concat(values);
          });

          for (var i = 0; i < keysArray.length; i++) {
            keysArray[i] =
              siyasetmetreAnalizJson.parametre[parseInt(keysArray[i])];
          }
        }

        setData(response.data);

        setXAxisData(keysArray);
        setSeriesData(valuesArray);

        if (setNeighborhoodAgeData) {
          const reportTableWidth = [];
          const reportTableValue = [];

          response.data[0].forEach((item) => {
            reportTableWidth.push("auto");
          });

          valuesArray.forEach((item) => {
            reportTableValue.push(item.value.toString());
          });

          setNeighborhoodAgeData([
            reportTableWidth,
            keysArray,
            reportTableValue,
          ]);
        }
      }
    } catch (error) {
      console.error(title + " Error", error);
      setData(null);

      if (error.response.status === 500) {
        toastMessage("500 | Sunucu Hatası!", "error");
      } else if (error.response.status === 404) {
        navigate("/404");
      } else if (error.response.status === 403) {
        toastMessage("Çok fazla istek atıldı.", "error");
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      } else {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }
    }
  }

  useEffect(() => {
    if (optionData) {
      let keysArray2 = [];
      let valuesArray2 = [];

      if (optionData[0].length) {
        optionData[0].forEach(function (obj) {
          var keys = obj.Parametre;
          var values = obj.Yuzde;

          keysArray2 = keysArray2.concat(
            bireyselJson.Secmen_Yasi[parseInt(keys)]
          );
          valuesArray2 = valuesArray2.concat(parseFloat(values));
        });
        setData(optionData);
        setXAxisData(keysArray2);
        setSeriesData(valuesArray2);
      } else {
        setData(null);
        setXAxisData([]);
        setSeriesData([]);
      }
    } else {
      getData();
    }
    // eslint-disable-next-line
  }, [chartColor, city, district, neighbourhood, street, optionData]);

  // For Voice
  const utterance = new SpeechSynthesisUtterance();

  const [readNote, setReadNote] = useState(true);

  function readPublicNote(text) {
    setReadNote(!readNote);

    // if (readNote) {
    //   utterance.text = text;
    //   utterance.lang = "tr-TR";
    //   utterance.rate = 0.8;
    //   utterance.pitch = 0.8;
    //   window.speechSynthesis.speak(utterance);
    // } else {
    //   window.speechSynthesis.cancel();
    // }
  }

  return (
    <>
      {show &&
        (data ? (
          data[0].length !== 0 ? (
            card ? (
              <PageChartCard
                id={id}
                title={title}
                path={path}
                city={city}
                district={district}
                neighbourhood={neighbourhood}
                street={street}
                graphic={graphic}
                chart={
                  <ReactECharts
                    option={option}
                    className={`w-100 ${clean ? "" : "h-100 pb-4"}`}
                  />
                }
                height="auto"
                tableData={data[0]}
                readNote={readNote}
                readPublicNote={readPublicNote}
              />
            ) : (
              <ReactECharts
                option={option}
                className={`w-100 ${clean ? "" : "h-100 pb-4"}`}
              />
            )
          ) : (
            <Card>
              <CardBody>
                <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
                  <span className="fw-bold h6 mb-0 d-flex align-items-center">
                    {title.toUpperCase()}
                  </span>
                </div>

                <span className="d-block text-center">
                  Veri Bulunmamaktadır
                </span>
              </CardBody>
            </Card>
          )
        ) : (
          <Card>
            <CardBody>
              <div className="spinner-border text-theme"></div>
            </CardBody>
          </Card>
        ))}

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
};

export default ChartBar;
