import React, { useContext } from "react";
import { AppSettings } from "../config/app-settings";
import CountUp from "react-countup";

export default function DynamicCountCard({
  data,
  previewSelectValue,
  previewSelectLabel,
}) {
  const context = useContext(AppSettings);

  return (
    <div className="overflow-auto h-100">
      {data && data.length !== 0 ? (
        <div className={`lg text-inverse text-opacity-100 text-center`}>
          {previewSelectLabel ? (
            data.map((item, index) => (
              <div key={index} className={index > 0 ? "mt-4" : ""}>
                <span className="d-block mb-1">
                  {item[previewSelectLabel.value]}
                </span>
                <strong className="h4">
                  <CountUp separator="." end={item[previewSelectValue.value]} />
                </strong>
              </div>
            ))
          ) : (
            <div>
              <strong className="h4">
                <CountUp
                  separator="."
                  end={data[0][previewSelectValue.value]}
                />
              </strong>
            </div>
          )}
        </div>
      ) : (
        context?.t?.no_data_available
      )}
    </div>
  );
}
