import React, { useContext, useEffect, useRef, useState } from "react";

import ReactECharts from "echarts-for-react";

import { AppSettings } from "../config/app-settings";
import { Card, CardBody, CardExpandToggler } from "./card/card";
import WordCloud from "./WordCloud";
import apiClient from "../ApiClient";
import DynamicTable from "./DynamicTable";
import DynamicMap from "./DynamicMap";
import DynamicNote from "./DynamicNote";
import toastMessage from "./ToastMessage";
import DynamicCountCard from "./DynamicCountCard";

export default function DynamicCard({
  id,
  todoData,
  todoCategoryData,
  pureTodoData,
  setTodoData,
  ai = false,
  aiData,
  dataLength,
  triggerFetch,
  setTriggerFetch,
  item,
  result = [1],
  chart = false,
  cityFilter,
  data = [1],
  setData,
  fullScreen = false,
  menu,
  isActive,
  setActiveIndex,
  readText,
  setCardNote,
  setCardName,
  animate = true,
  events = true,
  width = null,
  height = 600,
  buttons = false,
  getChartImages = false,
  allEvents = true,
}) {
  const chartRef = useRef(null);
  const chartRefItem = useRef(null);
  const context = useContext(AppSettings);

  const [activeCard, setActiveCard] = useState(false);

  const toggleActiveCard = () => {
    setActiveCard(!activeCard);
    readText(item.id);
  };

  useEffect(() => {
    if (isActive && item.description) {
      window.speechSynthesis.cancel();

      const msg = new SpeechSynthesisUtterance();
      msg.text = item.description;
      msg.lang = "tr-TR";
      msg.volume = 1;
      msg.rate = 0.8;
      msg.pitch = 0.8;

      msg.onend = () => {
        setActiveCard(false);
        setActiveIndex(null);
      };

      window.speechSynthesis.speak(msg);
    } else {
      window.speechSynthesis.cancel();
    }
  }, [isActive, item.description]);

  // Create Image
  const createImage = () => {
    setTimeout(() => {
      if (
        getChartImages &&
        chartRef &&
        chartRef.current &&
        chartRefItem.current
      ) {
        const echartElement =
          chartRefItem.current.querySelector(".echarts-for-react");

        if (echartElement) {
          const chart = chartRef.current.getEchartsInstance();
          const base64Image = chart.getDataURL({
            pixelRatio: 2,
            backgroundColor: "#212d38",
          });

          getChartImages((oldArray) => [...oldArray, base64Image]);
        }
      }
    }, 1000);
  };

  useEffect(() => {
    if ((getChartImages, data)) {
      createImage();
    }
  }, [getChartImages, data]);

  // Tablo Preview
  // const [tableData, setTableData] = useState(null);
  // const [tablePage, setTablePage] = useState(1);
  // const [tableCondition, setTableCondition] = useState({
  //   column: null,
  //   value: "",
  // });

  // async function tablePreview() {
  //   setTableData(null);

  //   const data = {
  //     chart: item.chart,
  //     page_id: item.page_id,
  //     title: item.title,
  //     type: item.type,
  //     description: item.description,
  //     status: item.status,
  //     customer: item.customer_id,
  //     order: item.order,
  //     paginate: true,
  //     sql: {
  //       column: item.payload.column,
  //       condition:
  //         item.payload.condition.length !== 0
  //           ? [
  //               tableCondition.column
  //                 ? {
  //                     key: tableCondition.column,
  //                     value: tableCondition.value,
  //                     operator: "like",
  //                     type: "and",
  //                   }
  //                 : null,
  //               ...item.payload.condition.map((item) => {
  //                 return {
  //                   key: item.key,
  //                   value: item.value,
  //                   operator: item.operator,
  //                   type: item.type,
  //                 };
  //               }),
  //             ].filter((item) => item !== null)
  //           : [
  //               tableCondition.column
  //                 ? {
  //                     key: tableCondition.column,
  //                     value: tableCondition.value,
  //                     operator: "like",
  //                     type: "and",
  //                   }
  //                 : null,
  //             ].filter((item) => item !== null),
  //       group: item.payload.group,
  //     },
  //   };

  //   try {
  //     const response = await apiClient.customPost(
  //       `/query/preview?page=${tablePage}`,
  //       data,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );

  //     if (response) {
  //       setTableData(response);
  //     }
  //   } catch (error) {
  //     setTableData([]);
  //     console.error(error);
  //   }
  // }

  // useEffect(() => {
  //   if (item.chart === 4) {
  //     tablePreview();
  //   }
  // }, [item, tablePage, tableCondition]);

  // Map Preview
  const [mapData, setMapData] = useState(null);

  async function mapPreview() {
    setMapData(null);

    const data = {
      chart: item.chart,
      page_id: item.page_id,
      title: item.title,
      type: item.type,
      description: item.description,
      status: item.status,
      customer: item.customer_id,
      order: item.order,
      sql: {
        column: item.payload.column,
        condition: item.payload.condition,
        group: item.payload.group,
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setMapData(response);
      }
    } catch (error) {
      setMapData([]);
      console.error(error);
    }
  }

  useEffect(() => {
    if (item.chart === 5) {
      mapPreview();
    }
  }, [item]);

  // Handle Sort
  const [graphicSort, setGraphicSort] = useState(item?.order);
  const [graphicWidth, setGraphicWidth] = useState(item?.options?.width?.value);

  const handleCardSort = async (type) => {
    let newOrder = graphicSort;

    if (type === "up") {
      newOrder++;
    } else if (type === "down" && newOrder > 1) {
      newOrder--;
    }

    setGraphicSort(newOrder);

    // const updatedData = data.map((dataItem) => {
    //   if (dataItem.query.id === item?.id) {
    //     console.log(dataItem.query.options?.width?.value);
    //     setTimeout(() => {
    //       handleCardWidth(
    //         dataItem.query.options?.width?.value.split("col-")[1]
    //       );
    //     }, 300);

    //     return {
    //       query: { ...dataItem.query, order: newOrder },
    //       result: [...dataItem.result],
    //     };
    //   }
    //   return dataItem;
    // });

    // if (setData) {
    //   setData(updatedData);
    // }

    try {
      const response = await apiClient.put(
        "/query/" + item?.id,
        {
          order: newOrder,
          description: item.description,
          sql: {
            column: item.payload.column,
            condition: item.payload.condition,
            group: item.payload.group,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        // toastMessage(context?.t?.updated_chart, "success");
        setTriggerFetch(!triggerFetch);
      }
    } catch (error) {
      console.error("Note Add Error", error);
    }
  };

  // Handle Width
  const [chartWidthShow, setChartWidthShow] = useState(false);

  const handleCardWidth = async (colNumber) => {
    setGraphicWidth("col-" + colNumber);

    const { id, ...payload } = item;
    payload.options = {
      ...payload.options,
      width: { value: "col-" + colNumber, label: colNumber + "/12" },
    };
    payload.sql = {
      condition: item.payload.condition,
      group: item.payload.group,
    };

    try {
      const response = await apiClient.put("/query/" + item?.id, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        toastMessage(context?.t?.updated_chart, "success");
        setChartWidthShow(false);
      }
    } catch (error) {
      console.error("Note Add Error", error);
    }
  };

  return (
    <div
      className={`graphic-item position-relative ${
        graphicWidth
          ? "col-12 col-xl-" + graphicWidth.split("col-")[1]
          : "col-12"
      }`}
      style={{
        height: height === "h-full" ? "100%" : "auto",
      }}
    >
      {events &&
        ((context?.me?.role?.id !== 1 &&
          menu?.page_type !== "default" &&
          item?.customer_id) ||
          context?.me?.role?.id === 1) && (
          <div
            className="graphic-events d-flex justify-content-end position-absolute"
            style={{ top: -20, right: 10, zIndex: 1 }}
          >
            <div className="me-2">{item?.type}</div>

            <button
              type="button"
              className="btn me-1 position-relative btn-sm p-0 btn-dark d-flex align-items-center justify-content-center"
              style={{
                height: 20,
              }}
            >
              <div
                onClick={() => setChartWidthShow(!chartWidthShow)}
                className="d-flex align-items-center gap-2"
              >
                {context?.t?.width}
                {chartWidthShow ? (
                  <i className="fas fa-angle-up"></i>
                ) : (
                  <i className="fas fa-angle-down"></i>
                )}
              </div>

              {chartWidthShow && (
                <ul
                  className="position-absolute p-0"
                  style={{
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%, 0px)",
                  }}
                >
                  {Array.from({ length: 12 }, (_, i) => {
                    const colNumber = 12 - i;
                    return (
                      <li
                        className="btn btn-sm p-0 btn-dark d-flex align-items-center justify-content-center"
                        onClick={() => handleCardWidth(colNumber)}
                      >
                        {colNumber}/12
                      </li>
                    );
                  })}
                </ul>
              )}
            </button>

            <button
              type="button"
              className="btn btn-sm p-0 btn-dark d-flex align-items-center justify-content-center"
              style={{
                width: 20,
                height: 20,
              }}
              onClick={() => handleCardSort("down")}
            >
              <i className="fas fa-angle-up"></i>
            </button>

            <button
              type="button"
              className="btn btn-sm p-0 btn-dark d-flex align-items-center justify-content-center"
              style={{
                width: 20,
                height: 20,
              }}
              onClick={() => handleCardSort("up")}
            >
              <i className="fas fa-angle-down"></i>
            </button>

            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#createGraphicModal"
              className="btn btn-sm p-0 btn-success d-flex align-items-center justify-content-center"
              style={{
                width: 20,
                height: 20,
              }}
              onClick={() => {
                context.setCreateGraphicOpen(true);
                context.setGraphicUpdate(item);
              }}
            >
              <i className="fas fa-edit" style={{ fontSize: 14 }}></i>
            </button>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#graphicDeleteModal"
              className="btn btn-sm p-0 btn-danger d-flex align-items-center justify-content-center"
              style={{
                width: 20,
                height: 20,
              }}
              onClick={() => context.setGraphicDelete(item)}
            >
              <i className="fas fa-trash-alt" style={{ fontSize: 14 }}></i>
            </button>
          </div>
        )}

      <Card
        style={{
          zIndex: 0,
          width: width || "auto",
          height: item?.options?.height
            ? 100 * item?.options?.height + 30
            : height === "h-full"
            ? "100%"
            : height,
        }}
      >
        <CardBody className="d-flex flex-column overflow-hidden h-100">
          {item.title && (
            <div
              className={`d-flex align-items-center justify-content-${
                allEvents ? "between" : "center"
              } border-bottom pb-2 mb-3`}
            >
              <span
                className={`fw-bold h6 mb-0 flex-1 ${
                  !fullScreen ? "text-center" : "text-start"
                }`}
                style={{
                  maxWidth: 500,
                }}
              >
                {item?.title?.toUpperCase()}
              </span>

              {allEvents && (
                <div className="d-flex align-items-center ms-2">
                  {setCardNote &&
                    item.description &&
                    item.description !== "" && (
                      <>
                        <div className="me-2 pe-2 border-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target={`#pageCardNoteModal_${menu}`}
                            onClick={() => {
                              setCardName(item.title);
                              setCardNote(item.description);
                            }}
                          >
                            {context?.t?.not_oku}
                          </button>
                        </div>

                        <div className="me-2 pe-2 border-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme btn-sm"
                            onClick={toggleActiveCard}
                          >
                            {isActive
                              ? context?.t?.durdur
                              : context?.t?.not_dinle}
                          </button>
                        </div>
                      </>
                    )}

                  {ai &&
                    data &&
                    data.length !== 0 &&
                    item?.chart !== 12 &&
                    item?.chart !== 10 &&
                    item?.chart !== 9 &&
                    item?.chart !== 5 &&
                    item?.chart !== 4 && (
                      <div className="me-2 pe-2 border-end">
                        <button
                          onClick={() =>
                            context?.setAiData({
                              id: id,
                              title: item.title,
                              data: aiData,
                            })
                          }
                          data-bs-toggle="modal"
                          data-bs-target="#aiModal"
                          type="button"
                          className="btn text-theme px-0"
                        >
                          <svg
                            style={{ width: 28, height: 28 }}
                            viewBox="0 0 512 512"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <g
                                fill="currentColor"
                                transform="translate(64.000000, 64.000000)"
                              >
                                <path d="M320,64 L320,320 L64,320 L64,64 L320,64 Z M171.749388,128 L146.817842,128 L99.4840387,256 L121.976629,256 L130.913039,230.977 L187.575039,230.977 L196.319607,256 L220.167172,256 L171.749388,128 Z M260.093778,128 L237.691519,128 L237.691519,256 L260.093778,256 L260.093778,128 Z M159.094727,149.47526 L181.409039,213.333 L137.135039,213.333 L159.094727,149.47526 Z M341.333333,256 L384,256 L384,298.666667 L341.333333,298.666667 L341.333333,256 Z M85.3333333,341.333333 L128,341.333333 L128,384 L85.3333333,384 L85.3333333,341.333333 Z M170.666667,341.333333 L213.333333,341.333333 L213.333333,384 L170.666667,384 L170.666667,341.333333 Z M85.3333333,0 L128,0 L128,42.6666667 L85.3333333,42.6666667 L85.3333333,0 Z M256,341.333333 L298.666667,341.333333 L298.666667,384 L256,384 L256,341.333333 Z M170.666667,0 L213.333333,0 L213.333333,42.6666667 L170.666667,42.6666667 L170.666667,0 Z M256,0 L298.666667,0 L298.666667,42.6666667 L256,42.6666667 L256,0 Z M341.333333,170.666667 L384,170.666667 L384,213.333333 L341.333333,213.333333 L341.333333,170.666667 Z M0,256 L42.6666667,256 L42.6666667,298.666667 L0,298.666667 L0,256 Z M341.333333,85.3333333 L384,85.3333333 L384,128 L341.333333,128 L341.333333,85.3333333 Z M0,170.666667 L42.6666667,170.666667 L42.6666667,213.333333 L0,213.333333 L0,170.666667 Z M0,85.3333333 L42.6666667,85.3333333 L42.6666667,128 L0,128 L0,85.3333333 Z"></path>
                              </g>
                            </g>
                          </svg>
                        </button>
                      </div>
                    )}

                  {fullScreen && <CardExpandToggler />}
                </div>
              )}
            </div>
          )}

          <div
            className="position-relative overflow-hidden flex-1 d-flex flex-column"
            ref={chartRefItem}
          >
            {!chart ? (
              data ? (
                item?.chart === 12 ? (
                  <DynamicCountCard
                    data={result}
                    previewSelectValue={item?.options?.selectedColumn[0]}
                    previewSelectLabel={item?.options?.selectedColumn[1]}
                  />
                ) : item?.chart === 10 && result.length === 0 ? (
                  <DynamicNote
                    value={item?.options?.noteValue}
                    setValue={null}
                  />
                ) : item?.chart === 5 && result.length === 0 ? (
                  <DynamicMap
                    data={mapData}
                    location={item?.options?.mapLocation}
                    selectedColumnValue={item?.options?.selectedColumn[0]}
                    selectedColumnLabel={item?.options?.selectedColumn[1]}
                    tableColumn={item.payload.column}
                    todoData={todoData}
                    todoCategoryData={todoCategoryData}
                    pureTodoData={pureTodoData}
                    setTodoData={setTodoData}
                    context={context}
                  />
                ) : data.length !== 0 && result.length !== 0 ? (
                  item?.options &&
                  item?.options?.options &&
                  item?.options?.options.length !== 0 ? (
                    item?.chart === 5 ? (
                      <DynamicMap
                        data={mapData}
                        location={item?.options?.mapLocation}
                        selectedColumnValue={item?.options?.selectedColumn[0]}
                        selectedColumnLabel={item?.options?.selectedColumn[1]}
                        tableColumn={item.payload.column}
                        todoData={todoData}
                        todoCategoryData={todoCategoryData}
                        pureTodoData={pureTodoData}
                        setTodoData={setTodoData}
                        context={context}
                      />
                    ) : item?.chart === 4 ? (
                      <DynamicTable
                        data={{
                          chart: item.chart,
                          page_id: item.page_id,
                          title: item.title,
                          type: item.type,
                          description: item.description,
                          status: item.status,
                          customer: item.customer_id,
                          order: item.order,
                          paginate: true,
                          sql: {
                            column: item.payload.column,
                            condition: item.payload.condition,
                            group: item.payload.group,
                          },
                        }}
                        tableColumn={item.payload.column}
                      />
                    ) : item?.chart === 3 ? (
                      <WordCloud
                        height={height}
                        animate={animate}
                        data={item?.options?.options}
                      />
                    ) : (
                      <>
                        <ReactECharts
                          ref={chartRef}
                          option={
                            item?.chart === 11 ||
                            item?.chart === 13 ||
                            item?.chart === 14 ||
                            item?.chart === 15 ||
                            item?.chart === 16
                              ? item?.options?.options
                              : item?.chart === 9
                              ? item?.options?.options
                              : item?.options?.options?.baseOption
                              ? item?.options?.options
                              : {
                                  ...item?.options?.options,
                                  visualMap: !context?.chartColor
                                    ? false
                                    : item?.options?.options.visualMap,
                                  series: [
                                    {
                                      ...item?.options?.options.series[0],
                                      animation: getChartImages ? false : true,
                                      itemStyle: { color: context?.chartColor },
                                    },
                                  ],
                                }
                          }
                          className="flex-1"
                        />

                        {buttons}
                      </>
                    )
                  ) : (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <small className="mb-2 d-block">
                        {context?.t?.grafik_eklenmedi}
                      </small>
                    </div>
                  )
                ) : (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <span className="d-block text-center">
                      {context?.t?.no_data_available}
                    </span>
                  </div>
                )
              ) : (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <div className="spinner-border text-theme"></div>
                </div>
              )
            ) : (
              chart
            )}
          </div>

          {events && <div className="text-end">#{item.order}</div>}
        </CardBody>
      </Card>
    </div>
  );
}
