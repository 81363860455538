import { useEffect } from "react";

import apiClient from "../../ApiClient";

export default function GetNote({
  setNoteData,
  path,
  graphic,
  city,
  district,
  neighbourhood,
  street,
}) {
  async function getNote() {
    if (path) {
      try {
        const response = await apiClient.customGet("/notes", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            type: "private",
            page: path,
            graphic: graphic,
            city: city,
            district: district,
            neighbourhood: neighbourhood,
            street: street,
          },
        });

        if (response) {
          setNoteData(response);
        } else {
          setNoteData([]);
        }
      } catch (error) {
        console.error("Get note", error);

        setNoteData([]);

        if (error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
      }
    }
  }

  useEffect(() => {
    getNote();
  }, [setNoteData]);
}
