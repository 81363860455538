import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";

import { AppSettings } from "../config/app-settings";
import apiClient from "../ApiClient";
import { useNavigate } from "react-router-dom";

export default function PageFilter({
  cityFilter,
  setCityFilter,
  districtFilter,
  setDistrictFilter,
  neighbourhoodFilter,
  setNeighbourhoodFilter,
  setStreetFilter,
  setCityData = false,
  setDistrictData = false,
  setNeighborhoodData = false,
  setStreetData = false,
  neighbourhood = true,
  street = true,
  report = false,
  allActive = false,
  getReport,
}) {
  const context = useContext(AppSettings);
  const [colClass, setColClass] = useState("col-lg-3");
  const navigate = useNavigate();

  useEffect(() => {
    if (!report) {
      if (!neighbourhood) {
        setColClass("col-lg-6");
      } else {
        if (!street) {
          setColClass("col-lg-4");
        }
      }
    }
  }, [neighbourhood, street, report]);

  useEffect(() => {
    if (report) {
      setColClass("col-lg-3");
    }
  }, [report]);

  //--

  const [city, setCity] = useState(null);
  const [cityValue, setCityValue] = useState(null);

  const [district, setDistrict] = useState(null);
  const [districtLoader, setDistrictLoader] = useState(true);
  const [districtValue, setDistrictValue] = useState(null);
  const [allDistrict, setAllDistrict] = useState([]);

  const [neighborhoodValue, setNeighborhoodValue] = useState(null);
  const [neighborhoodLoader, setNeighborhoodLoader] = useState(true);
  const [allNeighborhood, setAllNeighborhood] = useState([]);

  const [streetValue, setStreetValue] = useState(null);
  const [streetLoader, setStreetLoader] = useState(true);
  const [allStreet, setAllStreet] = useState([]);

  // Reset
  function resetNeighborhood() {
    setNeighborhoodValue(null);
    setNeighborhoodLoader(false);
    setTimeout(() => {
      setNeighborhoodLoader(true);
    }, 500);
  }

  function resetStreet() {
    setStreetValue(null);
    setStreetLoader(false);
    setTimeout(() => {
      setStreetLoader(true);
    }, 500);
  }

  // Select
  function handleCity(e) {
    setCityValue(e);

    setDistrictLoader(false);
    setTimeout(() => {
      setDistrictLoader(true);
    }, 500);
    setDistrictValue(null);

    fetchDistrict(e.value && e.value !== "" ? e.value : null);

    resetNeighborhood();
    fetchNeighborhood(null);

    resetStreet();
    fetchStreet(null);
  }

  function handleDistrict(e) {
    setDistrictValue(e);

    if (neighbourhood) {
      resetNeighborhood();
      fetchNeighborhood(e.value && e.value !== "" ? e.value : null);
    }

    resetStreet();
    fetchStreet(null);
  }

  function handleNeighborhood(e) {
    setNeighborhoodValue(e);

    resetStreet();
    fetchStreet(e.value && e.value !== "" ? e.label : null);
  }

  function handleStreet(e) {
    setStreetValue(e);
  }

  // Fetchs
  async function fetchDistrict(value, name) {
    if (value) {
      const data = await context.getAllDistrict(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: context?.t?.choose_district,
        });

        if (setDistrictData) {
          setDistrictData({
            active: {
              value: context?.me?.customer?.settings?.district?.id || null,
              label:
                context?.me?.customer?.settings?.district?.name.toUpperCase() ||
                null,
            },
            all: newData,
          });
        }
        setAllDistrict(newData);
      }
    } else {
      setAllDistrict([]);
    }
  }

  async function fetchNeighborhood(value) {
    if (value) {
      const data = await context.getAllNeighborhood(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: context?.t?.choose_neighborhood,
        });

        setAllNeighborhood(newData);
        if (setNeighborhoodData) {
          setNeighborhoodData(newData);
        }
      }
    } else {
      setAllNeighborhood([]);
    }
  }

  async function fetchStreet(value) {
    if (value) {
      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: "Bina Hane Sayısı",
            type: `bireysel-data-customer-${context?.me?.customer?.id}`,
            description: "",
            customer: null,
            status: true,
            order: 1,
            connection_type: "local",
sql: {
              column: [{ key: "Adres Sokak", value: "select" }],
              condition: [
                {
                  key: "Adres Il",
                  value: context
                    .removeTurkishCharacters(cityValue?.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                },
                {
                  key: "Adres Ilce",
                  value: context
                    .removeTurkishCharacters(districtValue?.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                },
                {
                  key: "Adres Mahalle",
                  value: context.removeTurkishCharacters(value).toUpperCase(),
                  operator: "like",
                  type: "and",
                },
              ],
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.length !== 0) {
          const data = response
            .map((item) => {
              return {
                value: context
                  .removeTurkishCharacters(item["adres sokak"])
                  .toUpperCase(),
                label: context
                  .removeTurkishCharacters(item["adres sokak"])
                  .toUpperCase(),
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label));

          const newData = data.slice();
          newData.unshift({
            value: "",
            label: context?.t?.choose_street,
          });

          setAllStreet(newData);
          if (setStreetData) {
            setStreetData(newData);
          }
        } else {
          setAllStreet([]);
        }
      } catch (error) {
        setAllStreet(null);
      }
    } else {
      setAllStreet([]);
    }
  }

  // User
  useEffect(() => {
    if (context?.me && context?.me?.customer?.settings?.city?.value) {
      if (setCityData) {
        setCityData({
          active: {
            value: context?.me?.customer?.settings?.city?.value || null,
            label: context?.me?.customer?.settings?.city?.label || null,
          },
          all: context.allCity,
        });
      }

      setCityValue({
        value: context?.me?.customer?.settings?.city?.value || null,
        label: context?.me?.customer?.settings?.city?.label || null,
      });
      setCity(context?.me?.customer?.settings?.city?.value || null);
      fetchDistrict(context?.me?.customer?.settings?.city?.value || null);

      if (setCityFilter) {
        setCityFilter({
          value: context?.me?.customer?.settings?.city?.value || null,
          label: context?.me?.customer?.settings?.city?.label || null,
        });
      }
    }

    if (context?.me?.customer?.settings?.district?.value) {
      setDistrictValue({
        value: context?.me?.customer?.settings?.district?.value || null,
        label:
          context?.me?.customer?.settings?.district?.label.toUpperCase() ||
          null,
      });
      setDistrict(context?.me?.customer?.settings?.district || null);
      fetchNeighborhood(
        context?.me?.customer?.settings?.district?.value || null
      );

      if (setDistrictFilter) {
        setDistrictFilter({
          value: context?.me?.customer?.settings?.district?.value || null,
          label:
            context?.me?.customer?.settings?.district?.label.toUpperCase() ||
            null,
        });
      }
    }
  }, [context?.me]);

  // Filter
  function handleFilter() {
    // console.log(cityValue, districtValue, neighborhoodValue, streetValue);
    setCityFilter(
      cityValue ? (cityValue.value === "" ? null : cityValue) : null
    );
    setDistrictFilter(
      districtValue ? (districtValue.value === "" ? null : districtValue) : null
    );
    if (setNeighbourhoodFilter) {
      setNeighbourhoodFilter(
        neighborhoodValue
          ? neighborhoodValue.value === ""
            ? null
            : neighborhoodValue
          : null
      );
    }
    if (setStreetFilter) {
      setStreetFilter(
        streetValue ? (streetValue.value === "" ? null : streetValue) : null
      );
    }
  }

  // Report
  function handleReport(name) {
    getReport(
      name,
      cityValue,
      districtValue
        ? districtValue.value === ""
          ? null
          : districtValue
        : null,
      neighborhoodValue
        ? neighborhoodValue.value === ""
          ? null
          : neighborhoodValue
        : null,
      streetValue ? (streetValue.value === "" ? null : streetValue) : null
    );
    // console.log(cityValue, districtValue, neighborhoodValue, streetValue);
  }

  // --

  useEffect(() => {
    if (cityFilter) {
      setCityValue({
        value: cityFilter.value,
        label: cityFilter.label,
      });
      fetchDistrict(cityFilter.value);
    }
  }, [cityFilter]);

  useEffect(() => {
    if (districtFilter) {
      setDistrictValue({
        value: districtFilter.value,
        label: districtFilter.label,
      });
      fetchNeighborhood(districtFilter.value);
    } else {
      setDistrictLoader(false);
      setTimeout(() => {
        setDistrictLoader(true);
      }, 500);
      setDistrictValue(null);
    }
  }, [districtFilter]);

  useEffect(() => {
    if (neighbourhoodFilter) {
      setNeighborhoodValue({
        value: neighbourhoodFilter.value,
        label: neighbourhoodFilter.label,
      });
    } else {
      setNeighborhoodLoader(false);
      setTimeout(() => {
        setNeighborhoodLoader(true);
      }, 500);
      setNeighborhoodValue(null);
    }
  }, [neighbourhoodFilter]);

  return context?.me && context?.me?.customer?.settings ? (
    <div className="col-12">
      <div className="row g-3">
        <div className="col-12 col-md-10">
          <div className="row g-3">
            <div className={`col-12 col-md-6 ${colClass}`}>
              {context.allCity && context.allCity.length !== 0 ? (
                <Select
                  options={
                    context?.me?.customer?.settings?.city?.value
                      ? []
                      : [
                          allActive
                            ? { value: "", label: context?.t?.all }
                            : null,
                          ...context.allCity,
                        ].filter((item) => item !== null)
                  }
                  value={
                    cityValue
                      ? cityValue
                      : city
                      ? context.allCity.find(function (item) {
                          return item.value === city;
                        })
                      : allActive
                      ? { value: "", label: context?.t?.all }
                      : null
                  }
                  classNamePrefix="react-select"
                  placeholder={context?.t?.choose_city}
                  noOptionsMessage={() => context?.t?.no_option}
                  onChange={(e) => handleCity(e)}
                />
              ) : (
                <div className="spinner-border spinner-border-sm text-theme"></div>
              )}
            </div>

            <div className={`col-12 col-md-6 ${colClass}`}>
              {context?.me?.customer?.settings?.district ? (
                // district ? (
                districtLoader ? (
                  <Select
                    options={
                      context?.me?.customer?.settings?.district?.value
                        ? []
                        : allDistrict
                    }
                    value={
                      districtValue
                      // districtValue
                      //   ? districtValue
                      //   : district.city === cityValue.value
                      //   ? allDistrict.find(function (item) {
                      //       return item.value === district.id;
                      //     })
                      //   : ""
                    }
                    classNamePrefix="react-select"
                    placeholder={context?.t?.choose_district}
                    noOptionsMessage={() => context?.t?.no_option}
                    onChange={handleDistrict}
                  />
                ) : (
                  <div className="spinner-border spinner-border-sm text-theme"></div>
                )
              ) : // ) : (
              //   <div className="spinner-border spinner-border-sm text-theme"></div>
              // )
              districtLoader ? (
                <Select
                  options={allDistrict}
                  value={districtValue}
                  classNamePrefix="react-select"
                  placeholder={context?.t?.choose_district}
                  noOptionsMessage={() => context?.t?.no_option}
                  onChange={handleDistrict}
                />
              ) : (
                <div className="spinner-border spinner-border-sm text-theme"></div>
              )}
            </div>

            {neighbourhood && (
              <div className={`col-12 col-md-6 ${colClass}`}>
                {neighborhoodLoader ? (
                  <Select
                    options={allNeighborhood}
                    value={neighborhoodValue ? neighborhoodValue : ""}
                    classNamePrefix="react-select"
                    placeholder={context?.t?.choose_neighborhood}
                    noOptionsMessage={() => context?.t?.no_option}
                    onChange={handleNeighborhood}
                  />
                ) : (
                  <div className="spinner-border spinner-border-sm text-theme"></div>
                )}
              </div>
            )}

            {street && (
              <div className={`col-12 col-md-6 ${colClass}`}>
                {streetLoader ? (
                  <Select
                    options={allStreet}
                    value={streetValue ? streetValue : ""}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => context?.t?.no_option}
                    placeholder={context?.t?.choose_street}
                    onChange={handleStreet}
                  />
                ) : (
                  <div className="spinner-border spinner-border-sm text-theme"></div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="col-12 col-md-2">
          {report ? (
            <div className="row g-2">
              <div className="col">
                <button
                  type="button"
                  className="btn btn-outline-theme w-100"
                  style={{ whiteSpace: "nowrap" }}
                  disabled={neighborhoodValue ? false : true}
                  onClick={() => handleReport("neighbourhood")}
                >
                  {context?.t?.mahalle_raporu}
                </button>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-outline-theme w-100"
                  style={{ whiteSpace: "nowrap" }}
                  disabled={streetValue ? false : true}
                  onClick={() => handleReport("street")}
                >
                  {context?.t?.sokak_raporu}
                </button>
              </div>
            </div>
          ) : (
            <button
              type="button"
              className="btn btn-outline-theme w-100"
              onClick={handleFilter}
            >
              {context?.t?.filtrele}
            </button>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="col-12">
      <div className="spinner-border spinner-border-sm text-theme"></div>
    </div>
  );
}
