import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card, CardBody } from "../../components/card/card";
import { AppSettings } from "../../config/app-settings";
import toastMessage from "../../components/ToastMessage";
import apiClient from "../../ApiClient";
import Fancybox from "../../components/Fancybox";
import { useLocation } from "react-router-dom";
import DatabaseForm from "./DatabaseForm";

export default function Profil() {
  const context = useContext(AppSettings);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hasNotification = searchParams.has("notification");

  // Photo
  const [profileImage, setProfileImage] = useState(null);
  const [, setHover] = useState(false);

  useEffect(() => {
    if (context?.me) {
      setProfileImage(context?.me?.settings?.image || null);
    }
  }, [context]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    // Sadece resim dosyalarına izin ver
    if (file && !file.type.startsWith("image/")) {
      toastMessage(context?.t?.please_select_image_file, "error");
      return;
    }

    // Maksimum dosya boyutu 5MB
    if (file && file.size > 5 * 1024 * 1024) {
      toastMessage(context?.t?.max_file_size_10mb, "error");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await apiClient.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setProfileImage(response.data.url);
        UserUpdate(response.data.url);
      }
    } catch (error) {
      console.error("Upload File Error", error);
      setProfileImage(null);
    }
  };

  async function UserUpdate(img) {
    try {
      const response = await apiClient.put(
        `/user/${context?.me?.id}`,
        {
          settings: {
            gender: context?.me?.settings?.gender || "",
            title: context?.me?.settings?.title || "",
            image: img,
            kvkk_confirm_date: context?.me?.settings?.kvkk_confirm_date || null,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        toastMessage(context?.t?.updated_image, "success");
      }
    } catch (error) {
      console.error("User Update Error", error);
    }
  }

  const getInitials = () => {
    if (context?.me?.name) {
      const nameParts = context.me.name.split(" ");
      const initials = nameParts[0][0] + nameParts[nameParts.length - 1][0];
      return initials.toUpperCase();
    }
    return "";
  };

  // Dogum Gunleri
  const [bireyselData, setBireyselData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredTableRow, setHoveredTableRow] = useState(null);

  const fetchBireysel = useCallback(async () => {
    setBireyselData(null);

    const option = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `bireysel-data-customer-${context?.me?.customer?.id}`,
      description: "",
      customer: null,
      paginate: true,
      status: true,
      order: 1,
      connection_type: "local",
      sql: {
        column: [
          {
            key: "data",
            value: "select",
          },
        ],
        condition: [
          {
            key: "Dogum_Tarihi_toMonth",
            value: (new Date().getMonth() + 1).toString(),
            operator: "=",
            type: "and",
          },
          {
            key: "Dogum_Tarihi_dayOfMonth",
            value: new Date().getDate().toString(),
            operator: "=",
            type: "and",
          },
        ],
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${currentPage}`,
        option,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setBireyselData(response);
    } catch (error) {
      setBireyselData([]);
      console.error(error);
    }
  }, [context?.me?.customer?.id, currentPage]);

  function handlePagination(value) {
    setCurrentPage(value);
  }

  // useEffect sadece role id'si 1, 2 veya 4 olduğunda çalışacak
  useEffect(() => {
    if (
      hasNotification &&
      (context?.me?.role?.id === 1 ||
        context?.me?.role?.id === 2 ||
        context?.me?.role?.id === 4)
    ) {
      fetchBireysel();
    }
  }, [context?.me?.role?.id, fetchBireysel, hasNotification]);

  // Sahadan Gelenler
  const [sahadanGelenlerData, setSahadanGelenlerData] = useState(null);

  const fetchSahadanGelenler = useCallback(async () => {
    setSahadanGelenlerData(null);

    try {
      const response = await apiClient.get(`/groups/assignment`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setSahadanGelenlerData(response.data);
    } catch (error) {
      setSahadanGelenlerData([]);
      console.error(error);
    }
  }, []); // Fonksiyon sadece bir kez oluşturulacak

  useEffect(() => {
    fetchSahadanGelenler();
  }, [fetchSahadanGelenler]); // Bağımlılıklar: context ve fetch fonksiyonu

  return (
    <div className="mb-5 pb-5">
      <Card>
        <CardBody>
          <div className="row">
            {!hasNotification && (
              <div className="col-12 col-md-2 border-end">
                <div
                  className="d-flex align-items-center justify-content-center mb-3 profil-photo-item"
                  style={{
                    position: "relative",
                    maxWidth: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    border: "2px solid #ddd",
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  {profileImage ? (
                    <img
                      src={profileImage}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <h1 className="text-white mb-0">
                      <span>{getInitials()}</span>
                    </h1>
                  )}
                  <div
                    className="profil-photo-item-upload"
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      top: 0,
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, .9)",
                      color: context?.theme === "light" ? "#000" : "#fff",
                      textAlign: "center",
                      padding: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    <label
                      htmlFor="profile-image-upload"
                      style={{ cursor: "pointer" }}
                      className="w-100 h-100 d-flex align-items-center justify-content-center"
                    >
                      {context?.t?.upload_image}
                    </label>
                    <input
                      id="profile-image-upload"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => handleImageChange(e)}
                    />
                  </div>
                </div>

                <h4>{context?.me?.name}</h4>
                <strong>@{context?.me?.username}</strong>

                <hr />

                <ul className="ps-3 mt-3">
                  <li className="mb-2">
                    <span className="fs-12px">{context?.t?.customer}:</span>
                    <span className="d-block fw-bold">
                      {context?.me?.customer?.customer.name}
                    </span>
                  </li>
                  <li className="mb-2">
                    <span className="fs-12px">
                      {context?.t?.authorized_province}:
                    </span>
                    <span className="d-block fw-bold">
                      {context?.me?.customer?.settings?.city.label ||
                        context?.t?.all}
                    </span>
                  </li>
                  <li className="mb-2">
                    <span className="fs-12px">
                      {context?.t?.authorized_district}:
                    </span>
                    <span className="d-block fw-bold">
                      {context?.me?.customer?.settings?.city.district ||
                        context?.t?.all}
                    </span>
                  </li>
                </ul>

                <hr />

                <ul className="ps-3 mt-3">
                  <li className="mb-2">
                    <span className="fs-12px">{context?.t?.degree}:</span>
                    <span className="d-block fw-bold">
                      {context?.me?.settings?.title}
                    </span>
                  </li>
                  <li className="mb-2">
                    <span className="fs-12px">{context?.t?.role}:</span>
                    <span className="d-block fw-bold">
                      {context?.me?.role?.name}
                    </span>
                  </li>
                  <li className="mb-2">
                    <span className="fs-12px">{context?.t?.phone}:</span>
                    <a
                      className="d-block fw-bold"
                      href={`tel:${context?.me?.phone}`}
                    >
                      {context?.me?.phone}
                    </a>
                  </li>
                  <li className="mb-2">
                    <span className="fs-12px">{context?.t?.email}:</span>
                    <a
                      className="d-block fw-bold"
                      href={`mailto:${context?.me?.email}`}
                    >
                      {context?.me?.email}
                    </a>
                  </li>
                  <li>
                    <span className="fs-12px">{context?.t?.address}:</span>
                    <p className="d-block fw-bold">{context?.me?.address}</p>
                  </li>
                </ul>
              </div>
            )}

            <div className="col">
              <DatabaseForm context={context} />

              {hasNotification &&
                (context?.me?.role?.id === 1 ||
                  context?.me?.role?.id === 2 ||
                  context?.me?.role?.id === 4) && (
                  <>
                    <h3>{context?.t?.birth_day_persons}</h3>

                    <hr />

                    <div className="mt-4">
                      {bireyselData ? (
                        <div className="col-12">
                          {bireyselData?.data &&
                          bireyselData?.data.length !== 0 ? (
                            <>
                              <div className="dataTables_wrapper dt-bootstrap5">
                                <div className="dataTables_scroll">
                                  <div
                                    className="dataTables_scrollBody table-responsive"
                                    style={{ height: "calc(100vh - 500px)" }}
                                  >
                                    <table className="table text-nowrap w-100 dataTable hide-data">
                                      <thead>
                                        <tr>
                                          <th
                                            className="text-start p-3"
                                            style={{ width: 80, minWidth: 80 }}
                                          >
                                            #
                                          </th>
                                          <th
                                            className="text-start p-3"
                                            style={{
                                              width: 200,
                                              minWidth: 200,
                                            }}
                                          >
                                            {context?.t?.name_surname}
                                          </th>
                                          <th
                                            className="text-start p-3"
                                            style={{ width: 80, minWidth: 80 }}
                                          >
                                            {context?.t?.gender}
                                          </th>
                                          <th
                                            className="text-start p-3"
                                            style={{ width: 80, minWidth: 80 }}
                                          >
                                            {context?.t?.age}
                                          </th>
                                          <th
                                            className="text-start p-3"
                                            style={{ width: 80, minWidth: 80 }}
                                          >
                                            {context?.t?.phone}
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {bireyselData.data.map(
                                          (item, index) => {
                                            const parsedData = JSON.parse(
                                              item.data
                                            );

                                            return (
                                              <tr
                                                key={index}
                                                onMouseEnter={() =>
                                                  setHoveredTableRow(index)
                                                }
                                                onMouseLeave={() =>
                                                  setHoveredTableRow(null)
                                                }
                                              >
                                                <td
                                                  style={{ minWidth: 80 }}
                                                  className="p-3"
                                                >
                                                  {index + 1}
                                                </td>
                                                <td
                                                  style={{ minWidth: 150 }}
                                                  className="p-3"
                                                >
                                                  {hoveredTableRow === index
                                                    ? context.me?.customer
                                                        ?.settings?.primary
                                                      ? parsedData[
                                                          "Ad Soyad"
                                                        ] || context?.t?.unknown
                                                      : "--"
                                                    : "********"}
                                                </td>
                                                <td
                                                  style={{ minWidth: 150 }}
                                                  className="p-3"
                                                >
                                                  {hoveredTableRow === index
                                                    ? context.me?.customer
                                                        ?.settings?.primary
                                                      ? parsedData[
                                                          "Cinsiyet"
                                                        ] || context?.t?.unknown
                                                      : "--"
                                                    : "********"}
                                                </td>
                                                <td
                                                  style={{ minWidth: 150 }}
                                                  className="p-3"
                                                >
                                                  {hoveredTableRow === index
                                                    ? context.me?.customer
                                                        ?.settings?.primary
                                                      ? parsedData[
                                                          "Dogum Tarihi"
                                                        ]
                                                        ? new Date().getFullYear() -
                                                          parsedData[
                                                            "Dogum Tarihi"
                                                          ].split("-")[0]
                                                        : context?.t?.unknown
                                                      : "--"
                                                    : "********"}
                                                </td>
                                                <td
                                                  style={{ minWidth: 150 }}
                                                  className="p-3"
                                                >
                                                  {hoveredTableRow === index
                                                    ? context.me?.customer
                                                        ?.settings?.primary
                                                      ? parsedData["Telefon NO"]
                                                        ? context.phoneFormat(
                                                            parsedData[
                                                              "Telefon NO"
                                                            ]
                                                          )
                                                        : context?.t?.unknown
                                                      : "--"
                                                    : "********"}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div className="row align-items-center mt-3">
                                  <div className="mb-0 col-md-12">
                                    <div className="dataTables_paginate paging_simple_numbers d-flex flex-wrap justify-content-center text-center">
                                      <ul className="pagination">
                                        <li
                                          className={`paginate_button page-item ${
                                            !bireyselData.prev_page_url &&
                                            "disabled"
                                          }`}
                                        >
                                          <span
                                            onClick={() =>
                                              bireyselData.prev_page_url &&
                                              handlePagination(
                                                bireyselData.prev_page_url.split(
                                                  "?page="
                                                )[1]
                                              )
                                            }
                                            className="page-link"
                                          >
                                            {context?.t?.prev}
                                          </span>
                                        </li>

                                        {bireyselData.links.map(
                                          (item, index) =>
                                            !isNaN(parseInt(item.label)) && (
                                              <li
                                                key={index}
                                                className={`paginate_button page-item ${
                                                  item.active ? "active" : ""
                                                }`}
                                              >
                                                <span
                                                  onClick={() =>
                                                    handlePagination(item.label)
                                                  }
                                                  className="page-link"
                                                >
                                                  {item.label}
                                                </span>
                                              </li>
                                            )
                                        )}

                                        <li
                                          className={`paginate_button page-item ${
                                            !bireyselData.next_page_url &&
                                            "disabled"
                                          }`}
                                        >
                                          <span
                                            onClick={() =>
                                              bireyselData.next_page_url &&
                                              handlePagination(
                                                bireyselData.next_page_url.split(
                                                  "?page="
                                                )[1]
                                              )
                                            }
                                            className="page-link"
                                          >
                                            {context?.t?.next}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <span className="d-block">
                              {context?.t?.no_data_available}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="spinner-border text-theme"></div>
                      )}
                    </div>
                  </>
                )}

              {!hasNotification &&
                (context?.me?.role?.id === 1 ||
                  context?.me?.role?.id === 2 ||
                  context?.me?.role?.id === 5) && (
                  <>
                    <h3>{context?.t?.coming_from_field}</h3>

                    <hr />

                    <div className="row">
                      {sahadanGelenlerData ? (
                        sahadanGelenlerData.length !== 0 ? (
                          sahadanGelenlerData.map((response, index) => (
                            <div className="col-12 col-md-6" key={index}>
                              <Card>
                                <CardBody>
                                  <div>
                                    <h4>
                                      {index + 1}. {response.report_id.name}
                                    </h4>
                                    {/* <h4>
                          Location: {response.location.lat},{" "}
                          {response.location.lng} (Zoom:{" "}
                          {response.location.zoom})
                        </h4> */}

                                    <hr />

                                    {response.answer.length !== 0 &&
                                      response.answer.map(
                                        (answerGroup, groupIndex) =>
                                          Array.isArray(answerGroup)
                                            ? answerGroup.map(
                                                (answerItem, itemIndex) => (
                                                  <div
                                                    key={itemIndex}
                                                    className="border mt-3 p-2 d-flex gap-2"
                                                  >
                                                    <span className="text-white d-block">
                                                      {itemIndex + 1}.
                                                    </span>
                                                    <div className="flex-1 overflow-hidden">
                                                      {/* <p>ID: {answerItem.id}</p> */}
                                                      <div className="text-white mb-2">
                                                        <strong>
                                                          {context?.t?.question}
                                                          :{" "}
                                                        </strong>
                                                        <span>
                                                          {
                                                            answerItem?.question
                                                              ?.question
                                                          }
                                                        </span>
                                                        {/* <span className="btn btn-dark px-2 py-0 ms-2">
                                                      {
                                                        answerItem?.question
                                                          ?.point
                                                      }
                                                    </span> */}
                                                      </div>

                                                      <div className="text-white mb-2">
                                                        <strong>
                                                          {context?.t?.answer}:{" "}
                                                        </strong>
                                                        <span>
                                                          {
                                                            answerItem.answer
                                                              .answer.answer
                                                          }
                                                        </span>
                                                        {/* <span className="btn btn-dark px-2 py-0 ms-2">
                                                      {
                                                        answerItem.answer.answer
                                                          .point
                                                      }
                                                    </span> */}
                                                      </div>

                                                      {/* Image List Mapping */}
                                                      <div className="row g-2">
                                                        <Fancybox
                                                          options={{
                                                            Carousel: {
                                                              infinite: false,
                                                            },
                                                          }}
                                                        >
                                                          {answerItem.answer.images?.map(
                                                            (
                                                              image,
                                                              imageIndex
                                                            ) => (
                                                              <div
                                                                key={imageIndex}
                                                                className="row g-2"
                                                              >
                                                                {image.list?.map(
                                                                  (
                                                                    imgUrl,
                                                                    urlIndex
                                                                  ) => (
                                                                    <a
                                                                      key={
                                                                        urlIndex
                                                                      }
                                                                      data-fancybox={
                                                                        "profile-sahadan-gelenler-" +
                                                                        imageIndex +
                                                                        "-gallery"
                                                                      }
                                                                      href={
                                                                        imgUrl
                                                                      }
                                                                      className="d-block col-6"
                                                                    >
                                                                      <img
                                                                        src={
                                                                          imgUrl
                                                                        }
                                                                        alt="answer"
                                                                        className="w-100 img-cover"
                                                                        style={{
                                                                          height:
                                                                            "200px",
                                                                        }}
                                                                      />
                                                                    </a>
                                                                  )
                                                                )}
                                                                <p className="text-white mt-2 mb-0">
                                                                  <strong>
                                                                    {
                                                                      context?.t
                                                                        ?.description
                                                                    }
                                                                    :{" "}
                                                                  </strong>
                                                                  {image.description ||
                                                                    context?.t
                                                                      ?.none}
                                                                </p>
                                                              </div>
                                                            )
                                                          )}
                                                        </Fancybox>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )
                                            : // For non-array answers
                                              Object.keys(answerGroup).map(
                                                (key) => {
                                                  const answerObj =
                                                    answerGroup[key];
                                                  return (
                                                    <div
                                                      key={key}
                                                      className="border mt-3 p-2 d-flex gap-2"
                                                    >
                                                      <span className="text-white d-block">
                                                        {parseInt(key) + 1}.
                                                      </span>
                                                      <div className="flex-1 overflow-hidden">
                                                        {/* <p>ID: {answerObj.id}</p> */}
                                                        <div className="text-white mb-2">
                                                          <strong>
                                                            {
                                                              context?.t
                                                                ?.question
                                                            }
                                                            :{" "}
                                                          </strong>
                                                          <span>
                                                            {
                                                              answerObj
                                                                ?.question
                                                                ?.question
                                                            }
                                                          </span>
                                                          {/* <span className="btn btn-dark px-2 py-0 ms-2">
                                                        {
                                                          answerObj?.question
                                                            ?.point
                                                        }
                                                      </span> */}
                                                        </div>

                                                        <div className="text-white mb-2">
                                                          <strong>
                                                            {context?.t?.answer}
                                                            :{" "}
                                                          </strong>
                                                          <span>
                                                            {typeof answerObj
                                                              .answer.answer ===
                                                            "object"
                                                              ? answerObj.answer
                                                                  .answer.answer
                                                              : answerObj.answer
                                                                  .answer}
                                                          </span>
                                                          {/* {typeof answerObj.answer
                                                        .answer ===
                                                        "object" && (
                                                        <span className="btn btn-dark px-2 py-0 ms-2">
                                                          {
                                                            answerObj.answer
                                                              .answer.point
                                                          }
                                                        </span>
                                                      )} */}
                                                        </div>

                                                        {/* Image List Mapping */}
                                                        <Fancybox
                                                          options={{
                                                            Carousel: {
                                                              infinite: false,
                                                            },
                                                          }}
                                                        >
                                                          <div>
                                                            {answerObj.answer.images?.map(
                                                              (
                                                                image,
                                                                imageIndex
                                                              ) => (
                                                                <div
                                                                  key={
                                                                    imageIndex
                                                                  }
                                                                  className="row g-2"
                                                                >
                                                                  {image.list?.map(
                                                                    (
                                                                      imgUrl,
                                                                      urlIndex
                                                                    ) => (
                                                                      <a
                                                                        data-fancybox={
                                                                          "profile-sahadan-gelenler-" +
                                                                          (imageIndex +
                                                                            1) +
                                                                          "-gallery"
                                                                        }
                                                                        href={
                                                                          imgUrl
                                                                        }
                                                                        className="d-block col-6"
                                                                      >
                                                                        <img
                                                                          key={
                                                                            urlIndex
                                                                          }
                                                                          src={
                                                                            imgUrl
                                                                          }
                                                                          alt="answer"
                                                                          className="w-100 img-cover"
                                                                          style={{
                                                                            height:
                                                                              "200px",
                                                                          }}
                                                                        />
                                                                      </a>
                                                                    )
                                                                  )}
                                                                  <p className="text-white mt-2 mb-0">
                                                                    <strong>
                                                                      {
                                                                        context
                                                                          ?.t
                                                                          ?.description
                                                                      }
                                                                      :{" "}
                                                                    </strong>
                                                                    {image.description ||
                                                                      context?.t
                                                                        ?.none}
                                                                  </p>
                                                                </div>
                                                              )
                                                            )}
                                                          </div>
                                                        </Fancybox>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )
                                      )}
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          ))
                        ) : (
                          <p>{context?.t?.noanswer_sent_you}</p>
                        )
                      ) : (
                        <div className="spinner-border text-theme"></div>
                      )}
                    </div>
                  </>
                )}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
