import React, { useState } from "react";

export default function GroupList({
  data,
  setBirimlerUpdateItem,
  setBirimlerDeleteItem,
}) {
  // Toggle için useState
  const [visibleUserIds, setVisibleUserIds] = useState([]);

  const toggleUserDetails = (userId) => {
    if (visibleUserIds.includes(userId)) {
      setVisibleUserIds(visibleUserIds.filter((id) => id !== userId)); // gizle
    } else {
      setVisibleUserIds([...visibleUserIds, userId]); // göster
    }
  };

  const renderUsers = (users) => {
    return (
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            <a
              href="#/"
              onClick={() => toggleUserDetails(user.id)} // Tıklama ile toggle
              className="d-flex align-items-center justify-content-center"
            >
              {user.name}
              <i className="fas fa-info-circle ms-2" />
            </a>

            {/* Detayları sadece tıklandığında göster */}
            {visibleUserIds.includes(user.id) && (
              <div
                className="text-start border border-white text-white px-2 py-1 rounded mt-1"
                style={{ fontSize: 12 }}
              >
                <div>{user.username}</div>
                <div className="border-top">{user.role.name}</div>
                <div className="border-top">{user.email}</div>
                <div className="border-top">{user.phone}</div>
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  };

  const renderTree = (nodes) => {
    return (
      <ul>
        {nodes.map((node) => (
          <li key={node.id}>
            <div className="d-flex justify-content-center gap-1">
              <a href="#/" className="d-flex align-items-center">
                {node.name}
              </a>
              <div className="d-flex flex-column gap-1">
                <button
                  type="button"
                  className="btn btn-outline-theme btn-sm px-1 py-0 d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#birimEkleModal"
                  style={{ height: 20 }}
                  onClick={() => setBirimlerUpdateItem(node)}
                >
                  <i className="fas fa-edit" style={{ fontSize: 10 }}></i>
                </button>
                <button
                  type="button"
                  className="btn btn-danger btn-sm px-1 py-0 d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#birimlerDeleteConfirm"
                  style={{ height: 20 }}
                  onClick={() => setBirimlerDeleteItem(node)}
                >
                  <i className="fas fa-trash-alt" style={{ fontSize: 10 }}></i>
                </button>
              </div>
            </div>

            {/* Kullanıcıları render et */}
            {node.users && node.users.length > 0 && renderUsers(node.users)}

            {/* Çocuk düğümleri render et */}
            {node.children &&
              node.children.length > 0 &&
              renderTree(node.children)}
          </li>
        ))}
      </ul>
    );
  };

  return <div className="tree">{renderTree(data)}</div>;
}
