const tr = {
    bolge_bulunamadi: "Bölge bulunamadı.",
    coklu_alan_grafigi_dikey: "Çoklu Alan Grafiği",
    coklu_cizgi_grafigi_dikey: "Çoklu Çizgi Grafiği",
    coklu_cubuk_grafigi_dikey: "Dikey Çoklu Çubuk Grafiği",
    coklu_cubuk_grafigi_yatay: "Yatay Çoklu Çubuk Grafiği",

    hiyerarsik_yapi: "Hiyerarşik Yapı",
    tum_okullarin_konumlari: "Tüm Okulların Konumlari",
    okul_adi: "Okul Adı",
    devlet_ozel: "Devlet/Özel",
    ogrenci_sayisi: "Öğrenci Sayısı",
    ogretmen_sayisi: "Öğretmen Sayısı",
    ogretmen_basina_ogrenci_sayisi: "Öğretmen Başına Öğrenci Sayısı",
    derslik: "Derslik",
    ogrenci_basina_derslik_sayisi: "Öğrenci Başına Derslik Sayısı",
    kutuphane_kitap_sayisi: "Kütüphane Kitap Sayısı",
    konferans_salon_sayisi: "Konferans Salon Sayısı",

    okullar: "Okullar",
    width: "Genişlik",
    height: "Yükseklik",
    bilgi_karti: "Bilgi Kartı",
    map_import_data_info_message: 'Konumları görüntülemek için sütun adlarını <strong>"Latitude"</strong> (Enlem) ve <strong>"Longitude"</strong> (Boylam) olarak ayarlayın.',
    coklu_cubuk_grafigi: "Çoklu Çubuk Grafiği",

    afet_title: "Yapı Kullanım İzin Belgesi İstatistikleri",
    afet_chart_1_title: "⁠Kat Sayısı",
    afet_chart_2_title: "⁠Yapı Sahibi",
    afet_chart_3_title: "⁠Taşıyıcı Sistem Açıklaması 1",
    afet_chart_4_title: "⁠Taşıyıcı Sistem Açıklaması 2",
    afet_chart_5_title: "⁠Taşıyıcı Sistem Açıklaması 3",
    afet_chart_6_title: "⁠Isıtma Sistemi",
    afet_chart_7_title: "⁠Bina Sayısı",
    afet_chart_8_title: "⁠Daire Sayısı",
    afet_chart_9_title: "⁠Yüzölçümü (m2)",

    analiz: "Analiz",
    veri_girisi: "Veri Girişi",
    bos_birakmayin: "Boş Bırakmayın",
    sms_default_message_1: "Telefon numaranız tek kullanımlık şifreniz olarak adınıza tanımlanmıştır. Aşağıda bulunan linkten anketimize ulaşıp görüşlerinizi belirtebilirsiniz. Görüşleriniz bizim için çok kıymetlidir.",
    example_data: "Örnek Veri",
    dosya_olustur: "Dosya Oluştur",
    sms_confirm: "Tabloda bulunan tüm vatandaşların telefonlarına sms atılacaktır. Onaylıyor musunuz?",

    bu_anket_kullanicilara_gonderildi: "Bu Anket Kullanıcılara Gönderildi",
    bu_anket_sms_olarak_gonderildi: "Bu Anket SMS Olarak Gönderildi",
    bu_anket_data_once_gonderildi: "Bu Anket Daha Önce Gönderildi",

    kullanicilara_gonder: "Kullanıcılara Gönder",
    sms_gonder: "SMS Gönder",
    yetkiniz_yoktur: "Yetkiniz Yoktur",
    zaman_grafigi: "Zaman Grafiği",
    tum_saglik_tesisleri_konumlari: "Tüm Sağlık Tesislerin Konumlari",
    il_ve_ilce_ve_mahalle_secmelisiniz: "İl, İlçe ve Mahalle Seçmelisiniz",
    siyaset_parti: "Parti",
    anket_aciklama: "Anket Açıklama",
    yukle_dosya_sutun_uyari: "Yüklediğiniz dosyanın sütun başlıklarında '_' ve Türkçe karakterler ile başında rakam olmamalıdır.Lütfen bu kurallara dikkat ederek dosyanızı yükleyiniz.",
    eklenmis_bir_soru_bulunamadi: "Eklenmiş bir soru bulunamadı.",
    hazir_anketler: "Hazır Anketler",
    analiz_sec: "Analiz Seç",
    gorev_kategorileri: "Görev Kategorileri",
    aciklama_goster: "Açıklama Göster",
    aciklama_gizle: "Açıklama Gizle",
    gorevleri_sadece_olusturan_kisi_silebilir: "Görevleri sadece oluşturan kişi silebilir.",

    olusturulma: "Oluşturulma",
    guncelleme: "Güncelleme",

    menu_rengi: "Menü Rengi",
    site_rengi: "Site Rengi",
    grafik_rengi: "Grafik Rengi",

    deprem: "DEPREM",
    risk_arastirmasi: "RISK ARASTIRMASI",
    diri_fay_hatti_yakinligi: "DIRI FAY HATTI YAKINLIGI",
    deprem_risk_derece_seviyesi: "DEPREM RISK DERECE SEVIYESI",
    yerlesim_yeri_envanteri: "YERLESIM YERI ENVANTERI",
    yapim_yilina_gore_bina_sayisi: "YAPIM YILINA GORE BINA SAYISI",
    kat_sayisina_gore_bina_sayisi: "KAT SAYISINA GORE BINA SAYISI",
    yapim_turune_gore_bina_sayisi: "YAPIM TURUNE GORE BINA SAYISI",

    rapor_basariyla_olusturuldu: "Rapor başarıyla oluşturuldu.",
    ondalik_sayi: "Ondalık Sayı",

    ileri_analiz: "İleri Analiz",
    genel_gorunum: "Genel Görünüm",
    anket_ve_arastirmalar: "Anket ve Araştırmalar",
    ozel_sayfalar: "Özel Sayfalar",
    analiz_icin_iletisime_gecin: "Analiz İçin İletişime Geçin",

    ocak: "Ocak",
    subat: "Şubat",
    mart: "Mart",
    nisan: "Nisan",
    mayis: "Mayıs",
    haziran: "Haziran",
    temmuz: "Temmuz",
    agustos: "Ağustos",
    eylul: "Eylül",
    ekim: "Ekim",
    kasim: "Kasım",
    aralik: "Aralık",

    BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2024: "2024 BELEDİYE BAŞKANLIĞI YEREL SEÇİMLERİ",
    BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2024: "2024 BÜYÜKŞEHİR BELEDİYE BAŞKANLIĞI YEREL SEÇİMLERİ",
    BASKANLIK_SECIMI_2_TUR_1: "2023 CUMHURBAŞKANLIĞI 1.TUR SEÇİMLERİ",
    YEREL_SECIMLERI_2019: "2019 YEREL SEÇİMLERİ",
    CUMHURBASKANLIGI_SECIMLERI_2018: "2018 CUMHURBAŞKANLIĞI SEÇİMLERİ",
    MILLETVEKILLIGI_SECIMLERI_2018: "2018 MİLLETVEKİLİ GENEL SEÇİMLERİ",
    HALK_OYLAMASI_2017: "2017 HALK OYLAMASI",
    MILLETVEKILLIGI_SECIMLERI_2015: "2015 MİLLETVEKİLİ GENEL SEÇİMLERİ",
    BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2014: "2014 BELEDIYE BASKANLIGI BUYUKSEHİR SECIMI",

    BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2009: "2009 BELEDIYE BASKANLIGI YEREL SECIMLERI",
    DONEM_MILLETVEKILLIGI_GENEL_SECIMI_24: "24. DONEM MILLETVEKILLIGI GENEL SECIMI",
    BELEDIYE_BASKANLIGI_SECIMI_2014: "2014 BELEDIYE BASKANLIGI SECIMI",
    CUMHURBASKANLIGI_SECIMI_12: "12. CUMHURBASKANLIGI SECIMI",
    DONEM_MILLETVEKILLIGI_SECIMLERI_25: "25. DONEM MILLETVEKILLIGI SECIMLERI",
    MILLETVEKILLIGI_GENEL_SECIMLERI_26: "26. MILLETVEKILLIGI GENEL SECIMLERI",
    BASKANLIK_SECIMI_1: "1. BASKANLIK SECIMI",
    DONEM_MILLETVEKILLIGI_GENEL_SECIMLERI_27: "27. DONEM MILLETVEKILLIGI GENEL SECIMLERI",
    BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2019: "2019 BELEDIYE BASKANLIGI YEREL SECIMLERI",
    YENILENEN_ISTANBUL_SECIMI_2019: "2019 YENILENEN ISTANBUL SECIMI",
    DONEM_MILLETVEKILI_GENEL_SECIMLERI_28: "28. DONEM MILLETVEKILI GENEL SECIMLERI",
    BASKANLIK_SECIMI_2_TUR_2: "2023 CUMHURBAŞKANLIĞI 2.TUR SEÇİMLERİ",

    siyasal_kimlik_analiz_sonuclari: "SİYASAL KİMLİK ANALİZ SONUÇLARI",
    baskanlik_secimi_ve_sokaga_gore_sandik_basina_birey_sayisi_tablosu: "2. BAŞKANLIK SEÇİMİ VE SOKAĞA GÖRE SANDIK BAŞINA BİREY SAYISI TABLOSU",
    yeni_secmen: "Yeni Seçmen",
    birey: "BİREY",
    mahallede_son_yil_itibariyle_toplam_sayisi: "Mahallede son yıl itibariyle toplam sayısı",
    birey_bulunmaktadir: "Birey bulunmaktadır.",
    hane_bulunmaktadir: "Hane bulunmaktadır.",
    sokak_bulunmaktadir: "Sokak bulunmaktadır.",
    bina_bulunmaktadir: "Bina bulunmaktadır.",
    gorev_mevcut_degil: "Görev Mevcut Değil",
    mahallede_bulunan_sendikalar: "MAHALLEDE BULUNAN SENDİKALAR",
    mahallede_bulunan_dernekler: "MAHALLEDE BULUNAN DERNEKLER",
    secim_analiz_raporu: "SEÇİM ANALİZ RAPORU",
    medeni_durum_analizi: "MEDENİ DURUM ANALİZİ",
    downloading_text: "Yükleniyor...",
    yas_grubuna_gore_birey_durumu: "YAŞ GRUBUNA GÖRE BİREY DURUMU",
    egitim_durumu: "EĞİTİM DURUMU",
    mahallede_ortalama_secmen_yasi: "Mahallede Ortalama Seçmen Yaşı",
    bulunmaktadir: "bulunmaktadır.",
    strateji_merkezi_mahalle_raporu: "Strateji Merkezi Mahalle Raporu",
    strateji_merkezi_sokak_raporu: "Strateji Merkezi Sokak Raporu",
    anket_cevaplanmamis: "Anket Cevaplanmamış",
    cevap_silindi: "Cevap Silindi",
    pin_filtre: "Pin Filtre",
    arastirma_cevaplari: "Araştırma Cevapları",
    bu_cevabi_sil: "Bu Cevabı Sil",
    telefon_kontrol_edildi: "Telefon Kontrol Edildi",
    haritayi_gizle: "Haritayı Gizle",
    kullanici_bilgileri: "Kullanıcı Bilgileri",
    toplam_soru: "Toplam Soru",
    resim_yok: "Resim Yok",
    guncel_hali: "Güncel Hali",

    desteklenmeyen_dosya_formati: "Desteklenmeyen dosya formatı",
    dosya_boyutu_yuksek: "Dosya boyutu yüksek",
    baslik_tarihi_bos_birakmayin: "Başlık boş bırakmayın",
    baslangic_tarihi_bos_birakmayin: "Başlangıç Tarihi boş bırakmayın",
    bitis_tarihi_bos_birakmayin: "Bitiş Tarihi boş bırakmayın",
    kategori_bos_birakmayin: "Kategori boş bırakmayın",
    aciklama_bos_birakmayin: "Açıklama boş bırakmayın",
    il_bos_birakmayin: "İl boş bırakmayın",
    ilce_bos_birakmayin: "İlçe boş bırakmayın",
    mahalle_bos_birakmayin: "Mahalle boş bırakmayın",
    koordinat_belirleyin: "Koordinat Belirleyin",
    ilce_girmelisiniz: "İlçe girmelisiniz",
    tamamlanma_yuzdesi: "Tamamlanma Yüzdesi",
    etiketler: "Etiketler",
    etiket_girin: "Etiket girin",
    dosya_kaldir: "Dosya Kaldır",
    resimleri_yukle: "Resimler Yükle",
    dosyalari_buraya_birakin: "Dosyaları Buraya Bırakın",
    surukle_birak_veya_tikla: "Sürükle Bırak Veya Tıkla",
    gorev_guncelle: "Görev Güncelle",
    gorev_guncellendi: "Görev Güncellendi",
    gorev_olusturuldu: "Görev Oluşturuldu",
    gorev_olustur: "Görev Oluştur",
    kategori_duzenle: "Kategori Düzenle",
    kategori_ekle: "Kategori Ekle",
    kategori_adi: "Kategori Adı",
    kategori_rengi: "Kategori Rengi",
    kategorisi_ve_kategoriye_bagli_basliklar_silinecektir: "Kategorisi ve kategoriye bağlı başlıklar silinecektir.",
    eklenmis_bir_gorev_bulunmamaktadir: "Eklenmiş bir görev bulunmamaktadır",
    gorev_silindi: "Görev Silindi",
    kategori_sirasi_guncellendi: "Kategori Sırası Güncellendi",
    liste_sirasi_guncellendi: "Liste Sırası Güncellendi",
    yonetici_ozetini_kapat_mesaji: "YÖNETİCİ ÖZETİ, KAPATMAK İÇİN BAŞKA BİR ALANA TIKLAYIN.",
    yonetici_ozetini_oku: "Yönetici Özetini Oku",
    olusturan_kisi: "Oluşturan Kişi",
    olusturulma_tarihi: "Oluşturulma Tarihi",
    guncelleyen_kisi: "Güncelleyen Kişi",
    olusturma_tarihi: "Oluşturma Tarihi",
    guncelleme_tarihi: "Güncelleme Tarihi",
    yonetici_ozeti: "Yönetici Özeti",

    yapildi: "Yapıldı",
    yapilmadi: "Yapılmadı",
    kisiye_anket_ata: "Kişiye Anket Ata",
    atanmis_bir_anket_yok: "Atanmış bir anket yok.",
    kac_kisi: "KAÇ KİŞİ",
    female: "KADIN",
    male: "ERKEK",
    muhtarliklar: "MUHTARLIKLAR",
    yapilacak_anketler: "YAPILACAK ANKETLER",
    kisilere_atanmış_anketler: "KİŞİLERE ATANMIŞ ANKETLER",
    olusturulmus_tablo_secin: "OLUŞTURULMUŞ TABLO SEÇİN",
    tabloyu_goster: "Tabloyu Göster",
    tabloyu_gizle: "Tabloyu Gizle",
    tabloyu_sil: "Tabloyu Sil",
    tabloyu_indir: "Tabloyu İndir",
    tablo_secin: "Tablo Seçin",
    filtre_olustur: "Filtre Oluştur",
    filtre_adi_giriniz: "Filtre adı giriniz",
    filtre_tablosu: "FİLTRE TABLOSU",
    anket_ata: "Anket Ata",
    anket_atandi: "Anket Atandı",
    anket_secmelisiniz: "Anket Seçmelisiniz",
    tc_bulunamadi: "TC Bulunamadı",
    tablo_silindi: "Tablo Silindi",
    filtre_adi_girmelisiniz: "Filtre adı girmelisiniz",
    filtre_eklendi: "Filtre Eklendi",
    filtre_yapmalisiniz: "Filtre yapmalısınız",
    hizmet_bulunamadi: "Hizmet Bulunamadı",
    sikayet_bulunamadi: "Şikayet Bulunamadı",
    ayni_ildeki_akraba_sayisi: "Aynı Ildeki Akraba Sayısı",
    ayni_ilcedeki_akraba_sayisi: "Aynı Ilçedeki Akraba Sayısı",
    duzenleyen: "Düzenleyen",
    memleket_il: "Memleket İl",
    memleket_ilce: "Memleket İlçe",
    aslen_il: "Aslen Il",
    aslen_ilce: "Aslen Ilçe",
    aile_buyugu: "Aile Büyüğü",
    aile: "Aile",
    kisi_bulunamadi: "Kişi Bulunamadı",
    not_eklendi: "Not Eklendi",

    not_eklenmedi: "Not Eklenemedi",
    not_ekle: "Not Ekle",
    not_guncelle: "Not Güncelle",
    not_guncellenmedi: "Not Güncellemedi",
    not_guncellendi: "Not Güncellendi",
    resim_eklenmedi: "Resim Eklenemedi",
    resim_eklendi: "Resim Eklendi",
    details: "Detaylar",
    detail: "Detay",
    hizmetler: "Hizmetler",
    sikayetler: "Şikayetler",
    iletilen_sikayetler: "İLETİLEN ŞİKAYETLER",
    istenilen_hizmetler: "İSTENİLEN HİZMETLER",
    is_kolu: "İş Kolu",
    sendika_adi: "Sendika Adı",
    sendikalar: "SENDİKALAR",
    kurum_adres: "Kurum Adres",
    detayli_faaliyet_alani: "Detaylı Faaliyet Alanı",
    faaliyet_alani: "Faaliyet Alanı",
    dernekler: "DERNEKLER",
    muhtarlik: "MUHTARLIK",
    muhtar_telefon: "Muhtar Telefon",
    muhtar_adi: "Muhtar Adı",
    muhtarlik_adi: "Muhtarlık Adı",
    kisi_ve_uzeri_goc_eden_birey_sayisi: "Kişi ve üzeri göç eden birey sayısı",
    olum: "Ölüm",
    dogum: "Doğum",
    olum_oranlari: "Ölüm Oranları",
    dogum_oranlari: "Doğum Oranları",
    dagilim: "Dağılım",
    universite_ve_universite_ogrenci_sayilari: "Üniversite ve Üniversite Öğrenci Sayıları",
    verilen_goc: "Verilen Göç",
    alinan_goc: "Alınan Göç",
    ilce_bazinda_verilen_goc: "İlçe Bazında Verilen Göç",
    ilce_bazinda_alinan_goc: "İlçe Bazında Alınan Göç",
    secmen_genel_egitim_durumu: "SECMEN GENEL EĞITIM DURUMU",
    yillara_gore_sosyoekonomik_statu_dagilimi: "YILLARA GÖRE SOSYOEKONOMIK STATÜ DAĞILIMI",
    yillara_gore_dogum_ve_olum_oranlari: "Yıllara Göre Doğum ve Ölüm Oranları",
    yillara_gore_secmen_medeni_durum_dagilimi: "Yıllara Göre Seçmen Medeni Durum Dağılımı",
    birey_hemsehrilik_durumu: "BİREY HEMŞEHRİLİK DURUMU",
    yas_grubuna_gore_birey_sayilari: "YAŞ GRUBUNA GÖRE BİREY SAYILARI",
    yas_grubu: "YAŞ GRUBU",
    tahmini_1_derece_aile: "Tahmini 1. Derece Ailede Üyelik",
    tahmini_2_derece_aile: "Tahmini 2. Derece Ailede Üyelik",
    tahmini_uye_yakini_olmayan: "Tahmini Üye Yakını Olmayan",
    ortalama_secmen_yasi: "Ortalama Seçmen Yaşı",
    olum_sayisi: "Ölüm Sayısı",
    dogum_sayisi: "Doğum Sayısı",
    ilk_defa_oy_sayisi: "ILK DEFA OY KULLANACAK SECMEN SAYISI",
    toplam_secmen: "Toplam Seçmen",
    guncel: "Güncel",
    baskanlik_secimi: "2. BASKANLIK SECIMI",
    tur: "Tur",
    tur_1: "Tur 1",
    tur_2: "Tur 2",
    sokaga_gore_sandik_basina_secmen_sayisi: "SOKAGA GORE SANDIK BASINA SECMEN SAYISI",
    kusaklara_gore_secmen_sayisi: "KUSAKLARA GORE SECMEN SAYISI",
    sosyoekonomik_status_dagilimi: "Sosyoekonomik Statü Dağılımı",
    sokak_bina_ve_hane_analizleri: "SOKAK BINA VE HANE ANALIZLERI",
    en_buyuk_nesil: "EN BUYUK NESIL",
    sessiz_kusak: "SESSIZ KUSAK",
    baby_boomer_kusagi: "BABY BOOMER KUSAGI",
    y_kusagi: "Y KUSAGI",
    x_kusagi: "X KUSAGI",
    z_kusagi: "Z KUSAGI",
    hemsehrilik_ve_goc: "Hemsehrilik ve Göç",

    oran: "Oran",
    secmen_sayisi: "Seçmen Sayısı",
    sandik_geneli: "Sandık Geneli",
    sandik_no: "Sandık No",
    siyasal_kimlik: "Siyasal Kimlik",
    hicbir_bulunamadi_uzgunum: "Hiçbir şey bulunamadı - üzgünüm",
    hicbir_kayit_mevcut_degil: "Hiçbir kayıt mevcut değil",
    icerisinde_bulunan: "içerisinde bulunan",
    numarali_sandikta: "numaralı sandıkta",
    secmen_oy_kullanmistir: "seçmen oy kullanmıştır.",
    hemsehrilik_durumu: "HEMŞEHRİLİK DURUMU",
    sandik_sonucu: "SANDIK SONUCU",
    genelinde_ikamet_eden: "genelinde ikamet eden",
    baskanlik_sistemine_gore_secim_sandik_analizi: "2. BAŞKANLIK SİSTEMİNE GÖRE SEÇİM SANDIK ANALİZİ",
    aradiginiz_sayfayi_bulamiyoruz: "Aradığınız sayfayı bulamıyoruz",
    doldur_ve_en_az_bir_kosul_ekleyin: "Lütfen tüm alanları doldurduğunuzdan emin olun ve en az bir koşul ekleyin.",
    olusturulan_anket_bulunamadi: "Oluşturulan Bir Anket Bulunamadı",
    tamamlanmis_anket_yukleme_alani: "Tamamlanmış Anket Yükleme Alanı",
    genel_anket_bulunamadi: "Genel Bir Anket Bulunamadı",
    anket_secin: "Anket Seçin",
    bir_anket_secin: "Bir Anket Seçin",
    analiz_ici_bir_anket_secin: "Analiz İçin Bir Anket Seçin",
    devam_eden_bir_anket_yoktur: "Devam eden bir anket yoktur.",
    kategori_adi_girmelisiniz: "Kategori Adı Girmelisiniz",
    kategori_puan_girmelisiniz: "Kategori Puan Girmelisiniz",
    atanmis_kullanici_bulunamadi: "Atanmış Kullanıcı Bulunamadı",
    kisi_kaldirildi: "Kişi Kaldırıldı",
    reklam_seciniz: "Reklam Seçiniz",
    kullanici_secin: "Kullanıcı Seçin",
    kullanici_atandi: "Kullanıcı Atandı",
    anket: "Anket",
    reklam_basliklari: "Reklam Başlıkları",
    anket_silindi: "Anket Silindi",
    tamamla: "Tamamla",
    tamamlandi: "Tamamlandı",
    anketler: "Anketler",
    tamamlanan_anketler: "Tamamlanan Anketler",
    anket_tamamlandi: "Anket Tamamlandı",
    devam_eden_anketler: "Devam Eden Anketler",
    analiz_bulunamadi: "Analiz Bulunamadı",
    status: "Durum",
    kategori_kaldir: "Kategori Kaldır",
    ust_kategori: "Üst Kategori",
    puan: "Puan",
    indir: "İndir",
    anket_indir: "Anket İndir",
    anket_olustur: "Anket Oluştur",
    departman_kaldirildi: "Departman Kaldırıldı",
    departman_atandi: "Departman Atandı",
    anket_duzenle: "Anket Düzenle",
    anket_detay: "Anket Detay",
    degisiklik_bulunamadi: "Değişiklik Bulunamadı",
    departman_secmelisiniz: "Departman Seçmelisiniz",
    kategori_eklendi: "Kategori Eklendi",
    kategori_silindi: "Kategori Silindi",
    kategori_olustur: "Kategori Oluştur",
    kategori_bulunamadi: "Kategori Bulunamadı",
    kategori_olusturuldu: "Kategori Oluşturuldu",
    cok_fazla_istek_atildi: "Çok fazla istek atıldı.",
    renk_secmelisiniz: "Renk Seçmelisiniz",
    kategori_guncelle: "Kategori Güncelle",
    kategori_guncellendi: "Kategori Güncellendi",
    atanmis_kullanicilar: "Atanmış Kullanıcılar",
    kategori_sec: "Kategori Seç",
    zorunlu: "Zorunlu",
    ata: "Ata",
    isim: "İsim",
    tamamlanma_tarih_saat: "Tamamlanma Tarih Saat",
    toplam_sure: "Toplam Süre",
    soyisim: "Soyisim",
    id: "ID",
    tc: "TC",
    ip_adresi: "IP Adresi",
    id_bulunamadi: "ID Bulunamadı",
    denek_id: "Denek ID",
    denek_id_gor: "Denek ID Gör",
    genel_anketler: "Genel Anketler",
    benim_anketlerim: "Benim Anketlerim",
    turkiye_gundemi: "Türkiye Gündemi",
    analiz_yukle: "Analiz Yükle",
    analiz_yukleyin: "Analiz Yükleyin",
    sonuc_bulunamadi: "Sonuç Bulunamadı",
    veri_silmek_icin_tikla: "Veri Silmek İçin Tıkla",
    satir_guncelle: "Satır Güncelle",
    satir_ekle: "Satır Ekle",
    satir_yuklenemedi: "Satır Yüklenemedi",
    satir_yuklendi: "Satır Başarıyla Yüklendi",
    satir_guncellenemedi: "Satır Güncellenemedi",
    satir_guncellendi: "Satır Başarıyla Güncellendi",
    veriler_silindi: "Veriler Silindi",
    satirlar_bos_birakilamaz: "Satırlar boş bırakılamaz",
    dosya_bulunamadi: "Dosya Bulunamadı",
    filtrele: "Filtrele",
    sokak_raporu: "Sokak Raporu",
    mahalle_analiz_raporu: "Mahalle Analiz Raporu",
    sokak_analiz_raporu: "Sokak Analiz Raporu",
    mahallede_toplam: "Mahallede toplam",
    iletisim_numarasi: "İletişim Numarası",
    mahalle_muhtari: "Mahalle Muhtarı",
    mahalle_raporu: "Mahalle Raporu",
    add_image: "Resim Ekle",
    cevabinizi_yaziniz: "Cevabınızı Yazınız",
    cevap_gonderildi: "Cevaplar Gönderildi",
    sorusu_icin_resim_konum_gerekiyor: "sorusu için resim ve konum gerekiyor.",
    ekle: "Ekle",
    alan: "Alan",
    atama_yap: "Atama Yap",
    departman_sec: "Departman Seç",
    resim_zorunlu: "Resim Zorunlu",
    sorular: "Sorular",
    coklu_cevap: "Çoklu Cevap",
    tekli_cevap: "Tekli Cevap",
    acik_uclu_cevap: "Açık Uçlu Cevap",
    cevaplar: "Cevaplar",
    sorulara_don: "Sorulara Dön",
    resim_alani_ekle: "Resim Alanı Ekle",
    soru_ekle: "Soru Ekle",
    soru_kaldir: "Soru Kaldır",
    anket_adi: "Anket Adı",
    arastirma_sorulari: "Araştırma Soruları",
    rapor_olustur: "Raporu Oluştur",
    tespit_raporu_olustur: "Tespit Raporu Oluştur",
    pin_belirleyin: "Pin Belirleyin",
    pin_eklemek_haritaya_tiklayin: "Pin eklemek için haritaya tıklayabilirsiniz",
    gozlem_olustur: "Gözlem Oluştur",
    atanmis_anketler: "Atanmış Anketler",
    dosya_ara: "Dosya Ara",
    sizin_yukledikleriniz: "Sizin Yükledikleriniz",
    kosul_olustur: "Koşul Oluştur",
    secilenleri_kaldir: "Seçilenleri Kaldır",
    uzerine_veri_yukle: "Üzerine Veri Yükle",
    soru_kategori_ekle: "Soru Kategorisi Ekle",
    soru_kategori_guncelle: "Soru Kategorisi Güncelle",
    soru_kategorileri: "Soru Kategorileri",
    anketi_kaldir: "Anketi Kaldır",
    kisi: "Kişi",
    kisiye_atama: "Kişiye Atama",
    anketi_olustur: "Anketi Oluştur",
    anketi_duzenle: "Anketi Düzenle",
    anketi_kopyala: "Anketi Kopyala",
    anketi_kopyalandi: "Anket Kopyalandı",
    anketi_guncellendi: "Anket Güncellendi",
    anketi_olusturuldu: "Anket Oluşturuldu",
    gorev_ekle: "Görev Ekle",
    anket_sec: "Anket Seç",
    tum_soru_alanlar_doldurmalisiniz: "Tüm Soru Alanlarını Doldurulmalısınız",
    en_az_bir_soru_girmelisiniz: "En Az Bir Soru Girmelisiniz",

    anket_adi_girmelisiniz: "Anket Adı Girmelisiniz",
    musteri_secmelisiniz: "Müşteri Seçmelisiniz",
    tum_cevap_alanlari_doldurmalisiniz: "Tüm Cevap Alanları Doldurulmalısınız",
    en_az_bir_cevap_girmelisiniz: "En Az Bir Cevap Girmelisiniz",
    cevap_ekle: "Cevap Ekle",
    metin_puani: "Metin Puanı",
    metin_puani_girmelisiniz: "Metin Puanı Girmelisiniz",
    metin_basligi: "Metin Başlığı",
    metin_basligi_girmelisiniz: "Metin Başlığı Girmelisiniz",
    tumunu_kaldir: "Tümünü Kaldır",
    error_delete_data_graphics: "Silme işleminden bu dosyaya bağlı tüm veri ve grafikler etkilenecektir. Silmek istediğinize emin misiniz?",
    tum_alanlari_doldurun: "Lütfen tüm alanları doldurun.",
    taleb_gonderildi: "Talebiniz Gönderildi",
    taleb_gonderilemedi: "Talebiniz Gönderilemedi",
    talep_formu: "Talep Formu",
    contact: "İletişim",
    rapor: "Rapor",
    kentmetre_raporu: "Kentmetre Raporu",
    customers: "Müşteriler",
    yonetim: "Yönetim",
    yonetici: "Yonetici",
    halkla_iliskiler_gozlemci: "Halkla İlişkiler Gözlemci",
    info_hane_ve_birey_analizi: "Hane ve Birey Analizi (Sadece Anket Yapma Kısmını Görebilir)",
    kentmetre_haritasi: "Kentmetre Haritası",
    strateji_haritasi: "Strateji Haritası",
    hane_ve_birey_analizi: "Hane ve Birey Analizi",
    halkla_iletisim_yonetimi: "Halkla İletişim Yönetimi",
    hazir_raporlar: "Hazır Raporlar",
    public_relations: "Halkla İlişkiler",
    institution_official: "Firma / Kurum Yetkilisi",
    gozlemci: "Gözlemci",
    arastirma_raporu: "Araştırma Raporu",
    cannot_appoint_manager: "Yönetici Atanamaz",
    password: "Şifre",
    password_confirm: "Şifre Doğrula",
    pass_8_min: "Şifre En Az 8 Karakter",
    pass_same: "Şifre Aynı Girilmeli",

    durdur: "Durdur",
    not_dinle: "Not Dinle",
    not_oku: "Not Oku",
    etiket_yazin: "ETİKET YAZIN",
    profile: "Profil",
    user_address: "Kullanıcı Adresi",
    user_title: "Kullanıcı Ünvan",
    user_role: "Kullanıcı Yetkisi",
    user_activation_phone: "Kullanıcı Aktivasyon Telefonu",
    user_representative: "Kullanıcı Yetkilisi Adı Soyadı",
    customer_representative: "Müşteri Yetkilisi Adı Soyadı",
    example_file_download: "Örnek Dosya İndir",
    fixed_files: "Sabit Dosyalar",
    clear: "Temizle",
    clear_cache: "Cache Temizle",
    info_clear_cache: "Bütün ön belleğe alınan tarama verileri silinecektir.",
    error_delete_condition: "Bulunan koşullar kalıcı olarak silinecektir ve geri dönüşü olmayacaktır. <strong>Emin misiniz?</strong>",

    raporu_goruntulemek_icin_filtre_yapmalisiniz: "Raporu Görüntülemek İçin Filtre Yapmalısınız",
    footer_text: `<strong class="text-theme">stratejimerkezi.com</strong>'un hiçbir kurum ve kuruluşla ilgisi yoktur. Bu sitede sadece açık kaynak araştırma ve tahminsel veriler gösterilmektedir. <br /> <strong class="text-theme">stratejimerkezi.com</strong>'da gösterilen yer, adres, bilgi ve harita bilgilerinin doğruluğunu garanti etmez, sadece bilgilendirme amaçlıdır.`,
    GROUP_OF_COMPANIES: "ŞİRKETLER GRUBU",
    birth_day_persons: "Doğum Günü Olan Vatandaşlar",
    coming_from_field: "Sahadan Gelenler",
    il_secmelisiniz: "İl Seçmelisiniz",
    il_ve_ilce_filtrelemeniz_gerekiyor: "İl ve İlçe Filtrelemeniz Gerekiyor",
    sokak_secmelisiniz: "Sokak Seçmelisiniz",
    mahalle_secmelisiniz: "Mahalle Seçmelisiniz",
    ilce_secmelisiniz: "İlçe Seçmelisiniz",
    il_ve_ilce_secmelisiniz: "İl ve İlçe Seçmelisiniz",
    no_data_available: "Veri Bulunmamaktadır",
    noanswer_sent_you: "Size atana bir cevap yoktur.",
    bina_basina_ortalama_hane_sayisi: "Bina Başına Ortalama Hane Sayısı",
    sokak_basina_ortalama_bina_sayisi: "Sokak Başına Ortalama Bina Sayısı",
    dosyayi_goruntule: "Dosyayı Görüntüle",
    description: "Açıklama",
    add_sub_question: "Alt Soru Ekle",
    bosanmis: "Boşanmış",
    bekar: "Bekar",
    evli_sayisi: "Evli Sayısı",
    esi_olmus: "Eşi Ölmüş",
    answer: "Cevap",
    remove_answer: "Cevabı Kaldır",
    required_image: "Resim  <br /> Gerekiyor",
    answer_point: "Cevap Paunı",
    write_answer: "Cevap Yazınız",
    cevap_tipi: "Cevap Tipi",
    soru_adi: "Soru Adı",
    soru_puani: "Soru Puanı",
    soru_kategorisi: "Soru Kategorisi",
    required_question: "Zorunlu Soru",
    esi_oldu: "EŞİ ÖLDÜ",
    evli: "EVLİ",
    hic_evlenmedi: "HİÇ EVLENMEDİ",
    bosandi: "BOŞANDI",
    question: "Soru",
    age: "Yaş",
    unknown: "Bilinmiyor",
    gender: "Cinsiyet",
    address: "Adres",
    degree: "Ünvan",
    authorized_province: "Yetkili İlçe",
    authorized_district: "Yetkili İl",
    upload_image: "Resim Yükle",
    updated_image: "Resim Güncellendi",
    max_file_size_5mb: "Dosya Boyutu 5MB Fazla Olamaz",
    max_file_size_10mb: "Dosya boyutu en fazla 10MB olabilir.",
    please_select_image_file: "Lütfen bir resim dosyası seçin.",
    add_group: "Departman Ekle",
    membership: "Üyelik",
    user: "Kullanıcı",
    log_kayitlari: "Log Kayıtları",
    users: "Kullanıcılar",
    edit_user: "Kullanıcı Düzenle",
    add_user: "Kullanıcı Ekle",
    user_name: "Kullanıcı Adı",
    person_name_surname: "K. Yetkilisi Adı Soyadı",
    user_phone: "Kullanıcı Telefon",
    user_email: "Kullanıcı Email",
    search: "Ara",
    reset: "Sıfırla",
    grafik_eklenmedi: "Grafik Eklenmedi",
    user_not_found: "Kullanıcı Bulunamadı",
    transaction: "İşlem",
    transactions: "İşlemler",
    yes: "Evet",
    no: "Hayır",
    next: "İleri",
    department: "Departman",
    department_name: "Departman Adı",
    department_name_required: "Departman Adı Girmelisiniz",
    updated_department: "Departman Düzenlendi",
    added_department: "Departman Eklendi",
    deleted_department: "Departman Silindi",
    departments: "Departmanlar",
    choose_customer: "Müşteri Seçin",
    show_users: "Kişileri Göster",
    kisiler: "Kişiler",
    hide_users: "Kişileri Gizle",
    remove: "Kaldır",
    isim_soyisim: "İsim Soyisim",
    iletisim_bilgisi: "İletişim Bilgisi",
    dogum_tarihi: "Doğum Tarihi",
    name_surname: "Ad Soyad",
    username: "Kullanıcı Adı",
    note: "Not",
    role: "Rol",
    department_not_added: "Departman Eklenmemiştir",
    department_edit: "Departman Düzenle",
    department_add: "Departman Ekle",
    save: "Kaydet",
    copy: "Kopyala",
    edit: "Düzenle",
    treeChart: "Ağaç Grafiği",
    sanketChart: "Akış Grafiği",
    pieRoseTypeChart: "Pasta Gül Grafiği",
    pieRoundedChart: "Yuvarlak Pasta Grafiği",
    barPolarTangential: "Kutupsal Çubuk Grafiği",
    analiz_silindi: "Analiz Silindi",
    analiz_adi_olusturuldu: "Analiz Adı Oluşturuldu",
    analiz_adi_guncellendi: "Analiz Adı Güncellendi",
    analiz_adi_olustururken_hata: "Analiz Adı Oluştururken Hata",
    analiz_adi_guncellerken_hata: "Analiz Adı Güncellerken Hata",
    analysisRequired: "Analiz Adı Girin",
    etiketlenen: "Etiketlenen",
    getir: "Getir",
    analiz_adi: "Analiz Adı",
    breaking_news: "Son Dakika",
    choose_city: "İl Seçiniz",
    choose_district: "İlçe Seçiniz",
    choose_neighborhood: "Mahalle Seçiniz",
    choose_street: "Sokak Seçiniz",
    choose_building: "Bina Seçiniz",
    choose_apartment: "Daire Seçiniz",
    find: "Bul",
    disaster_management: "Afet Yönetimi",
    go_back: "Geri Dön",
    city: "İl",
    population: "Nüfus",
    distance: "Mesafe",
    district: "İlçe",
    neighborhood: "Mahalle",
    street: "Sokak",
    building: "Bina",
    apartment: "Daire",
    started_date: "Başlangıç Tarihi",
    end_date: "Bitiş Tarihi",
    category: "Kategori",
    kapanis_metni: "Kapanış Metni",
    acilis_metni: "Açılış Metni",
    main_category: "Ana Kategori",
    sub_category: "Alt Kategori",
    sub_category_1: "Alt Kategori 1",
    sub_category_2: "Alt Kategori 2",
    bolumler: "Bölümler",
    web_site: "Web Sitesi",
    kurum_adi: "Kurum Adı",
    size: "Büyüklük",
    depth: "Derinlik",
    closestCities: "En Yakın Şehirler",
    time_of_creation: "Oluş Zamanı",
    lat: "Enlem",
    lng: "Boylam",
    place: "Yer",
    requirements_table: "İhtiyaç Tablosu",
    total_population: "Toplam Nüfus",
    how_many_days_required: "Kaç Günlük Lazım?",
    total_requirement: "Toplam İhtiyaç",
    unit: "Birim",
    contents: "İçerik",
    analysis_data_not_added: "Analiz Verisi Eklenmemiştir",
    healthcare_facilities: "Sağlık Tesisleri",
    location: "Konum",
    all_locations: "Tüm Konumlar",
    locations: "Konumlar",
    all_locations_show: "Tüm Konumları Göster",
    locations_show: "Konumları Göster",
    locations_hide: "Konumları Gizle",

    page: "Sayfa",
    create_page: "Sayfa Oluştur",
    import_file: "Dosya Yükle",
    create_graphic: "Grafik Oluştur",
    are_you_sure: "Emin misiniz?",
    iliskili_tum_kategoriler_silinecektir: "İlişkili Tüm Kategoriler Silinecektir",
    mine: "BENİM",
    show: "Göster",
    hide: "Gizle",
    show_hide: "Göster/Gizle",
    updateed: "Güncellendi",
    update: "Güncelle",
    updating: "Güncellerken",
    created: "Eklendi",
    create: "Oluştur",
    creating: "Eklerken",
    send: "Gönder",
    cancel: "İptal",
    preview: "Ön İzleme",
    prev: "Geri",
    view: "Görüntüle",
    delete: "Sil",
    no_option: "Seçenek Yok",
    max_200_mb: "200mb Fazla Olamaz",
    file_size_error: "Dosya Boyutu Fazla",
    server_error: "Sunucu Hatası!",
    try_again_error: "Bir süre sonra tekrar deneyin.",
    added_to_chart: "Grafik Sayfaya Eklendi",
    updated_chart: "Grafik Güncellendi",
    deleted_chart: "Grafik Silindi",
    conditions_sections_required: "Koşul Alanlarını Boş Bırakmayın.",
    columns_sections_required: "Sütun Alanlarını Boş Bırakmayın.",
    chart_sections_required: "Grafik Alanlarını Boş Bırakmayın.",
    chart_name_error: "Grafik Adı 100 Karakterden Az Olmalıdır.",
    select_column_error: "En Az Bir Sütun Seçin.",
    choose_column: "Sütun Seç",
    choose_column_2: "Sütun Seçin",
    group_warning: "Gruplandırma sütunu seçilen sütunlarda bulunmalıdır.",
    value: "Değer",
    title: "Başlık",
    tum_musteriler: "Tüm Müşteriler",
    icon: "İkon",
    iframe: "Iframe",
    customer: "Müşteri",
    select_customer: "Müşteri Seç",
    page_order: "Sayfa Sırası",
    page_deleted: "Sayfa Silindi",
    parent_menu: "Üst Menü",
    page_name: "Sayfa Adı",
    external_link: "Sayfa Dış Link",
    pageOrder: "Sayfa Sırası Girin",
    pageName: "Sayfa Adı Girin",
    pageNameCharLimit: "Sayfa Adı Karakteri 3'den Fazla Olmalıdır",
    externalLinkError: "Dış Link Hatalı",
    iframeLinkError: "Iframe Link Hatalı",
    file_import_max: "Dosya Boyutu 50MB Fazla Olamaz",
    file_important: "Dosya Gerekli",
    file_import: "Dosya İçeri Aktar",
    data_name_important: "Veri Adı Gerekli",
    data_name: "Veri Adı",
    file: "Dosya",
    file_name: "Dosya Adı",
    data_type: "Veri Türü",
    data_type_error: "Veri Türü Uyuşmazlığı",
    integer: "Sayı",
    string: "Metin",
    date: "Tarih",
    json: "JSON",
    email: "Email",
    phone: "Telefon",
    phone_control: "Telefon Kontrol",
    boolean: "Mantıksal (Doğru/Yanlış)",
    separators: "Ayırıcılar",
    separators_warning: "Ayırıcılar Tespit Edilemedi",
    separators_error: "CSV dosyasında tanınmayan bir ayırıcı kullanılmış.",
    choose_csv_file: "CSV Dosya Seç",
    warning_for_date: "Uyarı: Tarih seçtiğiniz sütunların formatı işlem yapabilmeniz için bu şekilde <strong>2024-01-01</strong> olmalıdır.",
    upload: "Yükle",
    column_warning: "Tüm Sütunların Veri Türlerini Belirleyin",
    file_import_info: "Veriler kuyruklama ve analiz işlemlerinin sonunda sisteme eklenecektir. Bu işlem birkaç dakika sürebilir.",
    file_import_success: "Dosya Başarıyla Yüklendi",
    file_import_error: "Dosya Yüklenemedi",
    mahalle_sayisi: "Mahalle Sayısı",
    sokak_sayisi: "Sokak Sayısı",
    bina_sayisi: "Bina Sayısı",
    hane_sayisi: "Hane Sayısı",
    kisi_sayisi: "Kişi Sayısı",

    graphic_preview: "Grafik Ön İzleme",
    secilen: "Seçilen",
    choose_page: "Seçilen Sayfa",
    choose_chart: "Seçilen Grafik",
    added_chart: "Grafiği Sayfaya Ekle",
    update_chart: "Grafiği Güncelle",
    chart_width: "Grafik Genişliği",
    chart: "Grafik",
    query: "Sorgu",
    group: "Gruplandırma",
    pageCustomer: "Sayfa Müşteri",
    selectPage: "Sayfa Seçiniz",
    fileCustomer: "Dosya Müşteri",
    selectFile: "Dosya Seç",
    chartOrder: "Grafik Sırası",
    chartView: "Grafik Görünüm",
    pieChart: "Pasta Grafiği",
    barChart: "Çubuk Grafiği",
    wordCloud: "Kelime Bulutu",
    table: "Tablo",
    map: "Harita",
    chartName: "Grafik Adı",
    chartDescription: "Grafik Açıklama",
    addColumn: "Sütun Ekle",
    columnNotFound: "Sütun Bulunamadı",
    selectColumn: "Sütun Seç",
    selectOperator: "Operatör Seç",
    selectCondition: "Koşıl Seç",
    selectAction: "İşlem Seç",
    dateGrouping: "Tarih Gruplandırma",
    menu_section: "Menü Alanı",
    menu_align: "Menü Hizalama",
    menu_horizontal: "Menü Hizalama (Yatay)",
    menu_vertical: "Menü Hizalama (Dikey)",
    xl: "Büyük (4/4)",
    lg: "Orta-Büyük (4/3)",
    m: "Orta (4/2)",
    sm: "Küçük (4/1)",
    all: "TÜMÜ",
    column: "SÜTUN",
    count: "SAY",
    average: "ORTALAMA",
    total: "TOPLAM",
    minimum: "MİNİMUM",
    maximum: "MAKSİMUM",
    distinct: "FARKLI",
    day: "GÜN",
    month: "AY",
    year: "YIL",
    hour: "SAAT",
    minute: "DAKİKA",
    second: "SANİYE",
    none: "YOK",
    condition: "Koşul",
    add_condition: "Koşul Ekle",
    choose_condition: "Koşul Seç",
    conditionNotFound: "Koşul Bulunamadı",
    you_can_add_condition: "Koşul Ekleyebilirsiniz",
    in: "İÇİNDE",
    out: "DIŞINDA",
    like: "BENZER",
    notLike: "BENZEMEZ",
    empty: "BOŞ",
    filled: "DOLU",
    and: "Ve",
    or: "Veya",
    vertical: "Dikey",
    horizontal: "Yatay",
    left: "Sol",
    center: "Orta",
    right: "Sağ",
    up: "Yukarı",
    middle: "Orta",
    down: "Aşağı",
    loader_data: "VERİLER",
    loader_gathering: "TOPLANIYOR...",
    loader_in_progress: "ANALİZ EDİLİYOR...",
    loader_visualizing: "GÖRSELLEŞTİRİLİYOR..."
};

export default tr;