import React, { useContext, useRef, useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate } from "react-router-dom";
import toastMessage from "../ToastMessage";
import { ToastContainer } from "react-toastify";
import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

function PageChartCardNote({
  noteData,
  getNote,
  id,
  path,
  city,
  district,
  neighbourhood,
  street,
  graphic,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();
  const [editorValue, setEditorValue] = useState("");

  async function saveNote() {
    if (noteData && noteData[0] && noteData[0].length !== 0) {
      try {
        const response = await apiClient.put(
          "/notes/" + noteData[0]?.id,
          {
            note: editorValue,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          toastMessage(response.data.message, "success");
          document.getElementById("modalCoverExample_close_" + id).click();
          getNote();
        }
      } catch (error) {
        console.error("Note Add Error", error);
      }
    } else {
      try {
        const response = await apiClient.customPost(
          "/notes",
          {
            type: context?.me?.role?.id === 1 ? "public" : "private",
            page: path,
            graphic: graphic,
            city: city,
            district: district,
            neighbourhood: neighbourhood,
            street: street,
            note: editorValue,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          toastMessage(response.message, "success");
          document.getElementById("modalCoverExample_close_" + id).click();
          getNote();
        }
      } catch (error) {
        console.error("Note Add Error", error);

        if (error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  }

  return (
    <>
      <div className="modal modal-cover fade" id={"modalCoverExample_" + id}>
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                Not{" "}
                {noteData && noteData[0] && noteData[0].length !== 0
                  ? "Düzenle"
                  : "Ekle"}
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id={"modalCoverExample_close_" + id}
              ></button>
            </div>
            <div className="modal-body">
              <CKEditor
                editor={ClassicEditor}
                data={(noteData && noteData[0]?.note) || ""}
                value={(noteData && noteData[0]?.note) || ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorValue(data);
                }}
              />

              <button
                type="button"
                className="btn btn-outline-theme w-100 mt-3"
                onClick={() => saveNote()}
              >
                Kaydet
              </button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
}

export default PageChartCardNote;
