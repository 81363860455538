import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CountUp from "react-countup";

import siyasetmetreAnalizJson from "../../json/siyasetmetre_analiz.json";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";
import toastMessage from "../ToastMessage";

import { Card, CardBody, CardExpandToggler } from "../card/card";

export default function CardHemsehrilikGoc({
  city,
  district,
  neighbourhood,
  street,
  graphic,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [data, setData] = useState();

  async function getData(dataName) {
    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "Hemsehrilik Göç",
          type: dataName,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Memleket Il",
                value: "select",
              },
              {
                key: "Memleket Ilce",
                value: "select",
              },
              {
                key: "TC",
                value: "count",
              },
            ],
            condition: [
              city
                ? {
                    key: "Adres Il",
                    value: context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Adres Ilce",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Adres Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              street
                ? {
                    key: "Adres Sokak",
                    value: context
                      .removeTurkishCharacters(street)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response;
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    async function fetchData() {
      setData(null);

      const results = await Promise.all([
        getData(`bireysel-data-customer-${context?.me?.customer?.id}`),
      ]);

      if (results[0].length !== 0) {
        const result = results[0]
          .sort((a, b) => b.tc_count - a.tc_count)
          .slice(0, 4);
        setData(result);
      } else {
        setData([]);
      }
    }

    // if (city) {
    fetchData();
    // }
  }, [city, district, neighbourhood, street]);

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl">
      <Card className="h-100">
        <CardBody>
          {data !== "filter" ? (
            data ? (
              data.length !== 0 ? (
                <>
                  <div className="d-flex fw-bold h6 mb-3 text-center">
                    <span className="flex-grow-1">
                      {context?.t?.hemsehrilik_ve_goc}
                    </span>
                  </div>

                  <div className="row align-items-center text-center">
                    <div className={"col-12"}>
                      <div className={`lg text-inverse text-opacity-100`}>
                        {data.map((item, index) => (
                          <div key={index} className="mb-2">
                            {item["memleket il"] + "/" + item["memleket ilce"]}
                            <br />
                            <strong className="h4">
                              <CountUp
                                separator="."
                                end={parseInt(item.tc_count) || 0}
                              />
                            </strong>{" "}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* <div className="col-5">
                    <div className="mt-n2">
                      <Chart
                        type="bar"
                        height={100}
                        options={{
                          colors: [themeColor],
                          chart: { sparkline: { enabled: true } },
                          tooltip: {
                            x: { show: false },
                            y: {
                              title: {
                                formatter: function (seriesName) {
                                  return "";
                                },
                              },
                            },
                          },
                        }}
                        series={[
                          {
                            name: "Medeni Durumlar",
                            data: [
                              data?.[1] || 0,
                              data?.[2] || 0,
                              data?.[3] || 0,
                              data?.[4] || 0,
                            ],
                          },
                        ]}
                      />
                    </div>
                  </div> */}
                  </div>
                </>
              ) : (
                <span className="d-block text-center">
                  {context?.t?.no_data_available}
                </span>
              )
            ) : (
              <div className="spinner-border text-theme"></div>
            )
          ) : (
            <span className="d-block text-center">
              {context?.t?.il_secmelisiniz}
            </span>
          )}
        </CardBody>
      </Card>

      <ToastContainer pauseOnFocusLoss={false} />
    </div>
  );
}
