import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";
import { Swiper, SwiperSlide } from "swiper/react";
import toastMessage from "../../components/ToastMessage";

function hexToRgba(hex, opacity) {
  // Hex kodundan başındaki # işaretini kaldır
  hex = hex.replace("#", "");

  // R, G, B değerlerini al
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // Opacity (saydamlık) değerini 0 ile 1 arasında normalize et
  var alpha = opacity || 1;

  // rgba formatında geri döndür
  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}

export default function MenuItem({
  city,
  district,
  neighborhood,
  menu,
  setTableLocationData,
  setTableShow,
  tableShow,
  customerColor,
  handleTableMarker,
  setTableLocationButton,
  tableLocationButton,
  activeMapAnaliz,
  setActiveMapAnaliz,
  setUpdateMapPage,
  setShowUpdateMapAnalizModal,
}) {
  const context = useContext(AppSettings);

  const [globalSwiper, setGlobalSwiper] = useState(null);

  const [data, setData] = useState(null);
  const [dataColumn, setDataColumn] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [mapSelectValue, setMapSelectValue] = useState(null);
  const [mapInputValue, setMapInputValue] = useState("");

  async function fetchColumn(type) {
    setDataColumn(null);

    try {
      const response = await apiClient.get(
        `/columns/${`harita-data-${activeMapAnaliz.id}-customer-${activeMapAnaliz.customer_id}?connection_type=local`}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        setDataColumn(response.data.columns);
      }
    } catch (error) {
      setDataColumn(null);
      setActiveMapAnaliz(null);
      toastMessage(context?.t?.downloading_text, "info");
      console.error("Get File Types Error", error);
    }
  }

  useEffect(() => {
    if (activeMapAnaliz) {
      fetchColumn();
    }
  }, [activeMapAnaliz]);

  async function fetchMapData(
    city,
    district,
    neighborhood,
    type,
    page,
    paginate,
    filterCondition = null
  ) {
    const columns = dataColumn.map((item) => ({
      key: item,
      value: "select",
    }));

    const options = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `harita-data-${activeMapAnaliz.id}-customer-${activeMapAnaliz.customer_id}`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      connection_type: "local",
sql: {
        column: paginate
          ? columns
          : [
              {
                key: "Latitude",
                value: "select",
              },
              {
                key: "Longitude",
                value: "select",
              },
            ],
        condition: [
          city
            ? {
                key: "Adres Il",
                value: context.removeTurkishCharacters(city).toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          district
            ? {
                key: "Adres Ilce",
                value: context.removeTurkishCharacters(district).toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          neighborhood
            ? {
                key: "Adres Mahalle",
                value: context
                  .removeTurkishCharacters(neighborhood)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          filterCondition || null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    if (paginate) {
      options.paginate = true;
    }

    setData(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${page}`,
        options,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (paginate) {
        setData(response);
      } else {
        setTableLocationData(response);
      }
    } catch (error) {
      setData(null);
      console.error(error);
    }
  }

  useEffect(() => {
    if (dataColumn) {
      fetchMapData(city, district, neighborhood, menu, currentPage, true, null);
      fetchMapData(
        city,
        district,
        neighborhood,
        menu,
        currentPage,
        false,
        null
      );
    }
  }, [
    city,
    district,
    neighborhood,
    menu,
    currentPage,
    dataColumn,
    activeMapAnaliz,
  ]);

  function handlePagination(value) {
    setCurrentPage(value);
  }

  //--

  async function handlePageDelete(id) {
    try {
      const response = await apiClient.delete(`/page/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        toastMessage(context?.t?.analiz_silindi, "success");

        context.getPages();
      }
    } catch (error) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  return (
    <div
      className="map-info-container border border-white position-absolute row py-3"
      style={{
        background:
          customerColor !== "null"
            ? hexToRgba(customerColor, 0.8)
            : window.location.host.includes("datakokpit")
            ? "rgba(255, 255, 255, 0.9)"
            : "rgba(19, 29, 40, 0.9)",
        bottom: 16,
        left: 24,
        right: 80,
      }}
    >
      {customerColor !== "null" && (
        <div
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{
            background: "rgb(0, 7, 23)",
            opacity: 0.75,
          }}
        ></div>
      )}

      <div style={{ zIndex: 1 }} className="col-12">
        <div className="d-flex align-items-center justify-content-between">
          <div className="flex-1 me-2 overflow-hidden">
            {city && (
              <div className="h5 mb-0" id="info-title">
                {city}
                {district ? "/" + district : ""}
                {neighborhood ? "/" + neighborhood : ""}
              </div>
            )}
            <div>
              {context.pages ? (
                context.pages.some(
                  (page) =>
                    page.id === 44 &&
                    Array.isArray(page.children) &&
                    page.children.length > 0
                ) ? (
                  <div className="d-flex align-items-center gap-3">
                    <div className="flex-1 overflow-hidden">
                      <Swiper
                        onSwiper={(s) => {
                          setGlobalSwiper(s);
                        }}
                        loop={false}
                        slidesPerView="auto"
                        spaceBetween={12}
                        className="pt-2"
                      >
                        {context.pages
                          .filter(
                            (page) =>
                              page.id === 44 &&
                              Array.isArray(page.children) &&
                              page.children.length > 0
                          )
                          .map((page) =>
                            page.children.map((child, index) => (
                              <SwiperSlide
                                key={index}
                                className="w-auto"
                                style={{ flex: "none" }}
                              >
                                <div className="d-flex align-items-center gap-2 menu-item-events-hover position-relative">
                                  <button
                                    type="button"
                                    className={`btn btn-sm btn${
                                      activeMapAnaliz &&
                                      activeMapAnaliz.id === child.id
                                        ? ""
                                        : "-outline"
                                    }-theme`}
                                    onClick={() => setActiveMapAnaliz(child)}
                                  >
                                    {child.name}
                                  </button>

                                  <div
                                    className="menu-item-events"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      transform: "translate(0px, -60%)",
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <button
                                        onClick={() => {
                                          setShowUpdateMapAnalizModal(true);
                                          setUpdateMapPage(child);
                                        }}
                                        className="btn btn-sm p-0 btn-success d-flex align-items-center justify-content-center"
                                        style={{
                                          width: 15,
                                          height: 16,
                                        }}
                                      >
                                        <i
                                          className="fas fa-edit"
                                          style={{ fontSize: 10 }}
                                        ></i>
                                      </button>

                                      <button
                                        onClick={() =>
                                          handlePageDelete(child.id)
                                        }
                                        className="btn btn-sm p-0 btn-danger d-flex align-items-center justify-content-center"
                                        style={{
                                          width: 15,
                                          height: 16,
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash-alt"
                                          style={{ fontSize: 10 }}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))
                          )}
                      </Swiper>
                    </div>

                    <div className="d-flex gap-2">
                      <button
                        className="d-block btn w-100 py-0 text-black btn-theme"
                        onClick={() => globalSwiper.slidePrev()}
                      >
                        {context?.t?.prev}
                      </button>

                      <button
                        className="d-block btn w-100 py-0 text-black btn-theme"
                        onClick={() => globalSwiper.slideNext()}
                      >
                        {context?.t?.next}
                      </button>
                    </div>
                  </div>
                ) : (
                  <span>{context?.t?.analiz_yukleyin}</span>
                )
              ) : (
                <div className="spinner-border spinner-border-sm text-theme"></div>
              )}
            </div>
          </div>

          {activeMapAnaliz && (
            <div className="d-flex gap-2 border-start ps-2">
              <button
                type="button"
                className="btn btn-outline-theme btn-sm"
                onClick={() => {
                  setTableShow(!tableShow);
                }}
              >
                {!tableShow
                  ? context?.t?.tabloyu_goster
                  : context?.t?.tabloyu_gizle}
              </button>

              <button
                type="button"
                className="btn btn-outline-theme btn-sm"
                onClick={() => {
                  handleTableMarker();
                  setTableLocationButton(!tableLocationButton);
                }}
              >
                {tableLocationButton
                  ? context?.t?.locations_show
                  : context?.t?.locations_hide}
              </button>
            </div>
          )}
        </div>
      </div>

      {tableShow && (
        <div
          style={{ zIndex: 1, height: 400 }}
          className="col-12 d-flex flex-column border-top pt-3 mt-3"
        >
          <div className="h-100">
            <div className="d-flex flex-column h-100">
              {dataColumn && dataColumn.length !== 0 && (
                <div className="mb-2 d-flex flex-wrap gap-2">
                  <div className="w-100" style={{ maxWidth: 200 }}>
                    <Select
                      options={dataColumn.map((header) => {
                        return {
                          value: header,
                          label: header,
                        };
                      })}
                      menuPlacement="top"
                      classNamePrefix="react-select"
                      placeholder={context?.t?.filtrele}
                      noOptionsMessage={() => context?.t?.no_option}
                      value={mapSelectValue}
                      onChange={(e) => setMapSelectValue(e)}
                    />
                  </div>

                  <div className="w-100" style={{ maxWidth: 200 }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={context?.t?.search.toUpperCase()}
                      value={mapInputValue}
                      onChange={(e) => setMapInputValue(e.target.value)}
                    />
                  </div>

                  {mapSelectValue && mapInputValue && (
                    <>
                      <div
                        className="btn btn-outline-theme"
                        onClick={() =>
                          fetchMapData(
                            city,
                            district,
                            neighborhood,
                            menu,
                            currentPage,
                            true,
                            mapSelectValue && mapInputValue
                              ? {
                                  key: mapSelectValue.label,
                                  value: mapInputValue,
                                  operator: "like",
                                  type: "and",
                                }
                              : null
                          )
                        }
                      >
                        {context?.t?.find.toUpperCase()}
                      </div>

                      <div
                        className="btn btn-outline-theme"
                        onClick={() =>
                          fetchMapData(
                            city,
                            district,
                            neighborhood,
                            menu,
                            currentPage,
                            true,
                            null
                          )
                        }
                      >
                        {context?.t?.reset.toUpperCase()}
                      </div>
                    </>
                  )}
                </div>
              )}

              <div className="table-responsive h-100">
                {data ? (
                  data.data.length !== 0 ? (
                    <table className="table text-nowrap w-100">
                      <thead>
                        <tr>
                          {Object.keys(data.data[0]).map((header) => (
                            <th key={header}>{header.toUpperCase()}</th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {data.data.map((item, index) => (
                          <tr key={index}>
                            {Object.keys(data.data[0]).map((header) => (
                              <td key={header}>{item[header]}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <span className="d-block text-center">
                      {context?.t?.no_data_available}
                    </span>
                  )
                ) : (
                  <div className="spinner-border text-theme"></div>
                )}
              </div>

              {data && (
                <div className="row align-items-center mt-3 overflow-x-auto">
                  <div className="mb-0 col-md-12">
                    <div className="dataTables_paginate paging_simple_numbers d-flex">
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item ${
                            !data.prev_page_url && "disabled"
                          }`}
                        >
                          <div
                            onClick={() =>
                              data.prev_page_url &&
                              handlePagination(
                                data.prev_page_url.split("?page=")[1]
                              )
                            }
                            className="page-link pointer"
                          >
                            {context?.t?.prev}
                          </div>
                        </li>

                        {data.links.map(
                          (item, index) =>
                            !isNaN(parseInt(item.label)) && (
                              <li
                                key={index}
                                className={`paginate_button page-item ${
                                  item.active ? "active" : ""
                                }`}
                              >
                                <div
                                  onClick={() => handlePagination(item.label)}
                                  className="page-link pointer"
                                >
                                  {item.label}
                                </div>
                              </li>
                            )
                        )}

                        <li
                          className={`paginate_button page-item ${
                            !data.next_page_url && "disabled"
                          }`}
                        >
                          <div
                            onClick={() =>
                              data.next_page_url &&
                              handlePagination(
                                data.next_page_url.split("?page=")[1]
                              )
                            }
                            className="page-link pointer"
                          >
                            {context?.t?.next}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
