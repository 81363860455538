import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppSettings } from "../../config/app-settings";
import apiClient from "../../ApiClient";
import toastMessage from "../../components/ToastMessage.js";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import Select from "react-select";

function LogKayitlari() {
  const navigate = useNavigate();
  const context = useContext(AppSettings);

  useEffect(() => {
    if (context && context.me) {
      if (context?.me?.role?.id !== 1) {
        navigate("/404");
      }
    }
  }, [context]);

  const [data, setData] = useState(false);
  const [userFilter, setUserFilter] = useState(false);
  const [statusFilter, setStatusFilter] = useState({
    value: null,
    label: "Hata Kodu Seçiniz...",
  });
  const [currentPage, setCurrentPage] = useState(1);

  async function getData() {
    setData(null);

    try {
      const response = await apiClient.get(
        `/logs?limit=50&page=${currentPage}${
          userFilter ? `&user=${userFilter}` : ""
        }${statusFilter.value ? `&status=${statusFilter.value}` : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("SOKAGA GORE SANDIK BASINA SECMEN SAYISI Error", error);
      setData(null);

      if (error.response.status === 500) {
        toastMessage("500 | Sunucu Hatası!", "error");
      } else if (error.response.status === 404) {
        navigate("/404");
      } else if (error.response.status === 403) {
        toastMessage("Çok fazla istek atıldı.", "error");
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      } else {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [currentPage, userFilter, statusFilter]);

  // Pagination
  function handlePagination(value) {
    setCurrentPage(value);
  }

  // User
  const [userData, setUserData] = useState({ data: [] });
  const [errorMessage, setErrorMessage] = useState(null);
  const [userCurrentPage, setUserCurrentPage] = useState(1);

  const [customerSelect, setCustomerSelect] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  async function getUserData() {
    setUserData(null);

    try {
      const response = await apiClient.get(
        `/user?customer_id=${customerId.value}&limit=50&paginate=true&sort=id&direction=asc&page=${userCurrentPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setUserData(response.data);
      }
    } catch (error) {
      console.error("Users Error", error);
      setUserData(null);

      if (error.response.status === 500) {
        setErrorMessage("500 | Sunucu Hatası!");
      } else if (error.response.status === 404) {
        navigate("/404");
      } else if (error.response.status === 403) {
        setErrorMessage("Çok fazla istek atıldı.");
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      } else {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }
    }
  }

  useEffect(() => {
    if (customerId) {
      getUserData();
    }
    // eslint-disable-next-line
  }, [customerId, userCurrentPage]);

  // Pagination
  function userHandlePagination(value) {
    setUserCurrentPage(value);
  }

  async function handleCustomerSelect(e) {
    setCustomerSelect(e);
    setCustomerId(e);
  }

  return (
    context &&
    context.me &&
    context?.me?.role?.id === 1 && (
      <div className="mb-5">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              {context?.me?.role.id === 1 && (
                <div className="d-flex align-items-end justify-content-between mb-2">
                  <div className="w-100" style={{ maxWidth: 200 }}>
                    <span>Müşteri Seç</span>
                    {context.allCustomerSelect ? (
                      <Select
                        options={context.allCustomerSelect}
                        placeholder=""
                        classNamePrefix="react-select"
                        noOptionsMessage={() => "Seçenek Yok"}
                        value={customerSelect}
                        onChange={(e) => handleCustomerSelect(e)}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>

                  <button
                    type="button"
                    className="btn btn-outline-theme"
                    onClick={() => setUserFilter(null)}
                  >
                    Tümü
                  </button>
                </div>
              )}

              {userData ? (
                <div className="dataTables_wrapper dt-bootstrap5">
                  <div className="dataTables_scroll">
                    <div
                      className="dataTables_scrollBody table-responsive"
                      style={{ height: 200 }}
                    >
                      <table className="table text-nowrap w-100 dataTable">
                        <thead>
                          <tr>
                            <th className="px-2">Kullanıcı Adı</th>
                          </tr>
                        </thead>

                        <tbody>
                          {userData?.data?.length !== 0 ? (
                            userData.data.map((item, index) => (
                              <tr
                                key={index}
                                onClick={() => setUserFilter(item?.id)}
                                className="pointer"
                                style={{
                                  opacity: userFilter === item?.id ? 1 : 0.5,
                                }}
                              >
                                <td>{item?.username || "-"}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td>Kişi Bulunamadı</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {userData.data.length !== 0 && (
                    <div className="row align-items-center mt-1">
                      <div className="mb-0 col-md-12">
                        <div className="dataTables_paginate paging_simple_numbers d-flex text-center">
                          <ul className="pagination">
                            <li
                              className={`paginate_button page-item ${
                                !userData.prev_page_url && "disabled"
                              }`}
                            >
                              <Link
                                to="#"
                                onClick={() =>
                                  userData.prev_page_url &&
                                  userHandlePagination(
                                    userData.prev_page_url.split("?page=")[1]
                                  )
                                }
                                className="page-link"
                              >
                                Geri
                              </Link>
                            </li>

                            {userData.links.map(
                              (item, index) =>
                                !isNaN(parseInt(item.label)) && (
                                  <li
                                    key={index}
                                    className={`paginate_button page-item ${
                                      item.active ? "active" : ""
                                    }`}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        userHandlePagination(item.label)
                                      }
                                      className="page-link"
                                    >
                                      {item.label}
                                    </Link>
                                  </li>
                                )
                            )}

                            <li
                              className={`paginate_button page-item ${
                                !userData.next_page_url && "disabled"
                              }`}
                            >
                              <Link
                                to={"#"}
                                onClick={() =>
                                  userData.next_page_url &&
                                  userHandlePagination(
                                    userData.next_page_url.split("?page=")[1]
                                  )
                                }
                                className="page-link"
                              >
                                İleri
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="spinner-border text-theme"></div>
              )}
            </div>

            <div className="col-6 border-start">
              <span className="fw-bold pt-2 pb-2 mb-2 d-block border-bottom">
                Hata Kodu
              </span>
              <Select
                options={[
                  { value: null, label: "Hata Kodu Seçiniz..." },
                  { value: 200, label: "Başarılı - 200" },
                  { value: 401, label: "Yetkisiz Giriş - 401" },
                  { value: 403, label: "Yetkisiz Giriş - 403" },
                  { value: 422, label: "Validasyon Hatası - 422" },
                  { value: 429, label: "Çok Fazla İstek - 429" },
                  { value: 500, label: "Sunucu Hatası - 500" },
                ]}
                value={statusFilter}
                classNamePrefix="react-select"
                placeholder="Hata Kodu Seçiniz..."
                noOptionsMessage={() => "Seçenek Yok"}
                onChange={(e) => setStatusFilter(e)}
              />
            </div>
          </div>
        </div>

        {data && data.data ? (
          data?.data.length !== 0 ? (
            <>
              <div className="dataTables_wrapper dt-bootstrap5 d-flex flex-column flex-1 overflow-hidden border-top pt-4 mt-4">
                <div className="dataTables_scroll flex-1 overflow-auto">
                  <div className="dataTables_scrollBody table-responsive">
                    <table className="table text-nowrap w-100 dataTable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Kullanıcı</th>
                          <th>Müşteri</th>
                          <th>Tarih - Saat</th>
                          <th style={{ minWidth: 300 }}>Tarayıcı</th>
                          <th>IP Adresi</th>
                          <th style={{ minWidth: 100 }}>Sayfa</th>
                          <th>İstek</th>
                          <th style={{ minWidth: 100 }}>Hata Kodu</th>
                          <th>Cevap</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.data.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.id || "-"}</td>
                            <td>
                              <span>{item?.user?.name || "-"}</span>
                              <br />
                              <span>{item?.user?.username || "-"}</span>
                            </td>
                            <td>
                              <span>
                                {item?.user?.customer?.customer?.competent ||
                                  "-"}
                              </span>
                              <br />
                              <span>
                                {item?.user?.customer?.customer?.name || "-"}
                              </span>
                            </td>
                            <td>
                              {item?.data?.timestamp
                                ? moment(item?.data?.timestamp).format(
                                    "DD MMMM YYYY - HH:mm:ss"
                                  )
                                : "-"}
                            </td>
                            <td style={{ whiteSpace: "initial" }}>
                              {item?.data?.headers["user-agent"][0] || "-"}
                            </td>
                            <td>{item?.data?.ip || "-"}</td>
                            <td>{item?.data?.path || "-"}</td>
                            <td>
                              {item?.data?.request
                                ? JSON.stringify(item?.data?.request)
                                : "-"}
                            </td>
                            <td>
                              {item?.status
                                ? item?.status === 200
                                  ? "Başarılı"
                                  : item?.status === 401
                                  ? "Yetkisiz Giriş"
                                  : item?.status === 403
                                  ? "Yetkisiz Giriş"
                                  : item?.status === 422
                                  ? "Validasyon Hatası"
                                  : item?.status === 429
                                  ? "Çok Fazla İstek"
                                  : item?.status === 500
                                  ? "Sunucu Hatası"
                                  : "-"
                                : "-"}
                            </td>
                            <td>
                              {item?.response
                                ? JSON.stringify(item?.response)
                                : "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row align-items-center my-3">
                  <div className="mb-0 col-md-12">
                    <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item ${
                            !data.prev_page_url && "disabled"
                          }`}
                        >
                          <div
                            onClick={() =>
                              data.prev_page_url &&
                              handlePagination(
                                data.prev_page_url.split("?page=")[1]
                              )
                            }
                            className="page-link"
                          >
                            Geri
                          </div>
                        </li>

                        {data.links.map(
                          (item, index) =>
                            !isNaN(parseInt(item.label)) && (
                              <li
                                key={index}
                                className={`paginate_button page-item ${
                                  item.active ? "active" : ""
                                }`}
                              >
                                <div
                                  onClick={() => handlePagination(item.label)}
                                  className="page-link"
                                >
                                  {item.label}
                                </div>
                              </li>
                            )
                        )}

                        <li
                          className={`paginate_button page-item ${
                            !data.next_page_url && "disabled"
                          }`}
                        >
                          <div
                            onClick={() =>
                              data.next_page_url &&
                              handlePagination(
                                data.next_page_url.split("?page=")[1]
                              )
                            }
                            className="page-link"
                          >
                            İleri
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <span className="d-block text-center mt-3 pt-3 border-top">
              Kayıt Bulunamadı
            </span>
          )
        ) : (
          <div className="spinner-border text-theme mt-3"></div>
        )}

        <ToastContainer pauseOnFocusLoss={false} />
      </div>
    )
  );
}

export default LogKayitlari;
