import React, { useEffect, useContext, useState } from "react";
import { ToastContainer } from "react-toastify";

import apiClient from "../../ApiClient.js";
import toastMessage from "../../components/ToastMessage.js";
import { AppSettings } from "./../../config/app-settings.js";
import { Card, CardBody } from "../../components/card/card.jsx";

// import video from "./bg.mp4";
import Logo from "../../components/logo/logo.jsx";
import PasswordInput from "../../components/PasswordInput.jsx";
import { useNavigate } from "react-router-dom";

const PinInput = ({ length, onComplete }) => {
  const [values, setValues] = useState(new Array(length).fill(""));

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === "") {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);

      if (value !== "" && index < length - 1) {
        document.getElementById(`pin-input-${index + 1}`).focus();
      }

      if (newValues.every((num) => num !== "")) {
        onComplete(newValues.join(""));
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !values[index] && index > 0) {
      document.getElementById(`pin-input-${index - 1}`).focus();
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
      {values.map((value, index) => (
        <input
          key={index}
          id={`pin-input-${index}`}
          type="text"
          value={value}
          maxLength={1}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className="form-control"
          style={{
            width: "40px",
            height: "40px",
            fontSize: "24px",
            textAlign: "center",
          }}
        />
      ))}
    </div>
  );
};

function GirisYap() {
  // App Settings
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [meData, setMeData] = useState(null);
  const [accessKey, setAccessKey] = useState(null);

  useEffect(() => {
    context.setThemePanel(false);
    context.setAppHeaderNone(true);
    context.setAppScrollButton(false);
    context.setAppWeather(false);
    context.setAppSidebarNone(true);
    context.setAppFooterFull(true);
    context.setAppContentClass("login-page");

    return function cleanUp() {
      context.setThemePanel(true);
      context.setAppHeaderNone(false);
      context.setAppScrollButton(true);
      context.setAppWeather(true);
      context.setAppFooterFull(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("login-page");
    };
    // eslint-disable-next-line
  }, []);

  function getFirstValidSlug(pages) {
    for (let page of pages) {
      if (page.type !== "link") {
        if (page.children && page.children.length > 0) {
          const childSlug = getFirstValidSlug(page.children);
          if (childSlug) {
            return childSlug;
          }
        } else {
          return page;
        }
      }
    }
    return null;
  }

  async function getPages(userInfo) {
    if (userInfo?.settings?.kvkk_confirm_date) {
      try {
        const response = await apiClient.get("/page", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response) {
          const firstMenu = getFirstValidSlug(
            response.data
              .filter((page) => !page.name.startsWith("Anket Sayfası"))
              .sort((a, b) => a.order - b.order)
          );

          setTimeout(() => {
            if (userInfo.role.id !== 1) {
              if (
                (window.location.host.split(".")[0] === "www"
                  ? window.location.host.split(".")[1]
                  : window.location.host.split(".")[0]) ===
                userInfo.customer.settings.domain
              ) {
                toastMessage("Yönlendiriliyor...", "success");

                if (userInfo.role.id === 3) {
                  window.location.href = "/strateji-haritasi";
                } else if (userInfo.role.id === 4) {
                  window.location.href = "/hane-ve-birey-analizi";
                } else if (userInfo.role.id === 5) {
                  window.location.href = "/arastirma-raporu";
                } else if (userInfo.role.id === 6) {
                  window.location.href = "/halkla-iletisim-yonetimi";
                } else if (userInfo.role.id === 7) {
                  window.location.href = "/yuklenen-dosyalar";
                } else {
                  navigate("/durum-analizi", {
                    state: {
                      menu: { id: 51 },
                    },
                  });
                  window.location.reload();
                }
              } else {
                toastMessage("Kullanıcı Bulunamadı", "error");
              }
            } else {
              toastMessage("Yönlendiriliyor...", "success");
              navigate("/durum-analizi", {
                state: {
                  menu: { id: 51 },
                },
              });
              window.location.reload();
            }
          }, 300);
        }
      } catch (error) {
        console.error("Get Pages Error", error);
      }
    } else {
      try {
        const response = await apiClient.put(
          `/user/${userInfo?.id}`,
          {
            settings: {
              gender: userInfo?.settings?.gender || "",
              title: userInfo?.settings?.title || "",
              image: userInfo?.settings?.image || null,
              kvkk_confirm_date: new Date().toLocaleString(),
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          try {
            const response = await apiClient.get("/page", {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
            if (response) {
              const firstMenu = getFirstValidSlug(
                response.data
                  .filter((page) => !page.name.startsWith("Anket Sayfası"))
                  .sort((a, b) => a.order - b.order)
              );

              // setTimeout(() => {
              //   if (userInfo.role.id !== 1) {
              //     if (
              //       window.location.host.split(".")[0] ===
              //       userInfo.customer.settings.domain
              //     ) {
              //       toastMessage("Yönlendiriliyor...", "success");

              //       if (userInfo.role.id === 3) {
              //         window.location.href = "/strateji-haritasi";
              //       } else if (userInfo.role.id === 4) {
              //         window.location.href = "/hane-ve-birey-analizi";
              //       } else if (userInfo.role.id === 5) {
              //         window.location.href = "/arastirma-raporu";
              //       } else {
              //         navigate("/durum-analizi", {
              //           state: {
              //             menu: { id: 51 },
              //           },
              //         });
              //         window.location.reload();
              //       }
              //     } else {
              //       toastMessage("Kullanıcı Bulunamadı", "error");
              //     }
              //   } else {
              //     toastMessage("Yönlendiriliyor...", "success");
              //     navigate("/durum-analizi", {
              //       state: {
              //         menu: { id: 51 },
              //       },
              //     });
              //     window.location.reload();
              //   }
              // }, 300);
            }
          } catch (error) {
            console.error("Get Pages Error", error);
          }
        }
      } catch (error) {
        console.error("User Update Error", error);
      }
    }
  }

  async function getMe(token) {
    try {
      const response = await apiClient.get("/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        if (response.data.role.id !== 1) {
          if (
            (window.location.host.split(".")[0] === "www"
              ? window.location.host.split(".")[1]
              : window.location.host.split(".")[0]) ===
            response.data.customer.settings.domain
          ) {
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(response.data));
            getPages(response.data);
          } else {
            toastMessage("Kullanıcı Bulunamadı", "error");
            setBtnDisabled(true);
          }
        } else {
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(response.data));
          getPages(response.data);
        }
      }
    } catch (error) {
      setBtnDisabled(true);
      console.error(error);
    }
  }

  // Handle Submit
  async function handleSubmit(event) {
    event.preventDefault();
    setBtnDisabled(true);

    const data = {
      email: event.target[0].value,
      password: event.target[1].value,
    };

    try {
      const response = await apiClient.customPost("/login", data);

      toastMessage("Hesap Bulundu", "success");

      if (response.access_key) {
        setMeData(response.phone);
        setAccessKey(response.access_key);
        handlePinShow(response.access_key);
      } else {
        setTimeout(() => {
          getMe(response.access_token);
        }, 300);
      }
    } catch (error) {
      console.error("Login Error", error);
      setBtnDisabled(false);

      if (error?.response?.status === 401) {
        toastMessage("Hesap bulunamadı!", "error");
      }

      if (error?.response?.status === 422) {
        toastMessage("E-posta adresi geçersiz.", "error");
      }

      if (error?.response?.status === 403) {
        toastMessage("Bir süre sonra tekrar deneyin.", "error");
      }

      if (error?.response?.status === 500) {
        toastMessage("Lütfen tekrar deneyin.", "error");
      }
    }
  }

  // VIDEO
  // useEffect(() => {
  //   // YouTube IFrame API'sini ekleyin
  //   const tag = document.createElement("script");
  //   tag.src = "https://www.youtube.com/iframe_api";
  //   const firstScriptTag = document.getElementsByTagName("script")[0];
  //   firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //   // YouTube Player API'si hazır olduğunda çağrılan fonksiyon
  //   window.onYouTubeIframeAPIReady = () => {
  //     new window.YT.Player("player", {
  //       height: "390",
  //       width: "640",
  //       videoId: "7WwvrNEi7V4",
  //       playerVars: {
  //         autoplay: 1,
  //         loop: 1,
  //         controls: 0,
  //         playlist: "7WwvrNEi7V4", // Döngü için gereklidir
  //         mute: 1,
  //       },
  //       events: {
  //         onReady: onPlayerReady,
  //         onStateChange: onPlayerStateChange,
  //       },
  //     });
  //   };

  //   const onPlayerReady = (event) => {
  //     event.target.playVideo();
  //   };

  //   const onPlayerStateChange = (event) => {
  //     if (event.data === window.YT.PlayerState.ENDED) {
  //       event.target.playVideo(); // Video bittiğinde tekrar oynat
  //     }
  //   };
  // }, []);

  //--

  const handleAccept = () => {
    localStorage.setItem("kvkk_confirm", "true");
  };

  //--

  const [pinShow, setPinShow] = useState(false);
  const [otpLoader, setOTPLoader] = useState(false);
  const [pinValue, setPinValue] = useState(null);

  const handleComplete = async (e) => {
    e.preventDefault();

    setOTPLoader(true);

    try {
      const response = await apiClient.customPost("/login/otp", {
        access_key: accessKey,
        otp: parseInt(pinValue),
      });

      if (response) {
        setOTPLoader(false);
        toastMessage(`Şifre Doğrulandı`, "success");
        setTimeout(() => {
          getMe(response.access_token);
        }, 300);
      }
    } catch (error) {
      setOTPLoader(false);
      console.error("OTP Error", error);

      if (error?.response?.status === 401) {
        toastMessage("Yanlış Pin", "error");
      }

      if (error?.response?.status === 422) {
        toastMessage("E-posta adresi geçersiz.", "error");
      }

      if (error?.response?.status === 403) {
        toastMessage("Bir süre sonra tekrar deneyin.", "error");
      }

      if (error?.response?.status === 500) {
        toastMessage("Lütfen tekrar deneyin.", "error");
      }
    }
  };

  function handlePinShow() {
    setPinShow(true);
    setTimeout(() => {
      document.getElementById(`pin-input-0`).focus();
    }, 300);
  }

  return (
    <div className="login">
      {/* <div className="login-bg">
        <div id="player"></div>
      </div> */}

      <div className="app-header">
        <Logo animation={false} />
      </div>

      <div className="login-content">
        <h1 className="text-center mb-4">Giriş Yap</h1>

        {pinShow ? (
          <div className="text-center">
            <form onSubmit={(e) => handleComplete(e)}>
              <PinInput length={6} onComplete={(e) => setPinValue(e)} />
              <button type="submit" className="btn btn-outline-theme mt-3">
                Giriş Yap
              </button>
            </form>

            <div className="text-center mt-4">
              <p className="mb-0">{meData}</p>
              <p>Telefonunu Gelen Kodu Girin</p>
            </div>

            {otpLoader && (
              <div className="mt-4 text-center">
                <div className="spinner-border spinner-border-sm text-theme"></div>
              </div>
            )}
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control form-control-lg bg-white bg-opacity-5"
                placeholder=""
                required
              />
            </div>
            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                  Şifre <span className="text-danger">*</span>
                </label>
              </div>
              <PasswordInput
                extraClass={"form-control-lg bg-white bg-opacity-5"}
              />
            </div>
            {!localStorage.getItem("kvkk_confirm") && (
              <div className="mb-3">
                <div className="form-check form-checkbox">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="loginCheckbox"
                    required
                    onChange={handleAccept}
                  />
                  <label className="form-check-label" htmlFor="loginCheckbox">
                    <span
                      className="text-theme text-underline pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#kvkkModal"
                    >
                      KVKK ve Aydınlatma Metnini
                    </span>
                    , okudum onaylıyorum.
                  </label>
                </div>
              </div>
            )}
            <button
              type="submit"
              disabled={btnDisabled}
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
            >
              Giriş Yap
              {btnDisabled && (
                <div className="spinner-border spinner-border-sm text-theme ms-2"></div>
              )}
            </button>
          </form>
        )}

        <div className="modal modal-cover fade" id="kvkkModal">
          <div className="modal-dialog py-4 px-sm-4" style={{ maxWidth: 1000 }}>
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">KVKK ve Aydınlatme Metni</h3>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div className="modal-body">
                <div className="login-confirm-text border-top pt-4">
                  <p>
                    <strong>
                      {" "}
                      Müşteri Sms Ve E-posta İletisi Gönderimi Kişisel Verilerin
                      Korunması Hakkında Bilgilendirme Ve Aydınlatma Metni
                    </strong>
                    <br />
                    <br />
                    6698 sayılı Kişisel Verilerin Korunması Kanunu; 24 Mart 2016
                    tarihinde kabul edilmiş, 7 Nisan 2016 tarihli ve 29677
                    sayılı Resmi Gazete’de yayımlanarak yürürlüğe girmiştir.
                    <br />
                    <br />
                    Kanunun amacı, kişisel verilerin işlenmesinde başta özel
                    hayatın gizliliği olmak üzere kişilerin temel hak ve
                    özgürlüklerini korumak ve gerçek kişilere ilişkin kişisel
                    verileri işleyen gerçek ve tüzel kişilerin yükümlülükleri
                    ile uyacakları usul ve esasları düzenlemektir.
                    <br />
                    <br />
                    Bu Bilgilendirme ve Aydınlatma Metni, 6698 sayılı Kişisel
                    Verilen Korunması Kanunu (KVKK) ile düzenlenen gerçek
                    kişilerin “Kişisel Verilerinin” işlenmesi ve aktarılması
                    kapsamında hazırlanmıştır. İşbu metinde yer alan “Kişisel
                    Veri” kavramı, kimliği belirli yahut belirlenebilir olan
                    gerçek kişiye ait her türlü bilgiyi ifade etmektedir.
                    <br />
                    <br />
                    Kentmetre İletişim Danışmanlığı San. Tic. A. Ş.(Şirket),
                    tarafınıza ait kişisel verilerinizin korunmasına önem
                    vermektedir. Bu nedenle, haklarınızı korumak amacıyla
                    yürürlükte olan 6698 sayılı Kişisel Verilerin Korunması
                    Kanunu kapsamında ve SMS ve e-posta iletilerinin gönderimi
                    sürecine dair kişisel verilerinizin kullanımı, korunması ve
                    haklarınız konusunda sizi bilgilendirmekteyiz.
                    <br />
                    <br />
                    <strong>
                      Aydınlatma Metni’nin Amacı ile Şirketimizin Konumu
                    </strong>
                    <br />
                    <br />
                    Kentmetre İletişim Danışmanlığı San. Tic. A. Ş. , SMS ve
                    e-posta iletilerinin gönderimi sürecinde müşterilere ilişkin
                    kişisel veriler bakımından KVKK kapsamında kişisel verileri
                    işlemesi ve yönetmesi sebebiyle “veri sorumlusu” sıfatına
                    sahip olup işbu Bilgilendirme ve Aydınlatma Metni ile söz
                    konusu Kanun uyarınca ilgili kişilerin
                    <br />
                    <br />
                    Kentmetre İletişim Danışmanlığı San. Tic. A. Ş.tarafından
                    gerçekleştirilen kişisel veri işleme faaliyetleri hakkında
                    aydınlatılması ve bilgilendirilmesi hedeflenmektedir.
                    <br />
                    <br />
                    <strong>
                      SMS ve E-Posta İletilerinin Gönderimi Sürecinde
                      Müşterilere Ait Kişisel Verilerin İşlenme Amacı
                    </strong>
                    <br />
                    <br />
                    SMS ve e-posta iletilerinin gönderimi sürecinde müşterilere
                    ait kişisel veriler; açık rıza verilmesi halinde Şirket’in
                    tanıtım, bilgilendirme, reklam faaliyetlerinin yapılabilmesi
                    ve müşteriler ile iletişim kurulabilmesi amacıyla
                    işlenmektedir.
                    <br />
                    <br />
                    Şirketimizce kişisel veriler, Kanun’un 5. maddesinde de
                    ifade edildiği üzere;
                    <br />
                    - Kanunlarda açıkça öngörülmesi,
                    <br />
                    - Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                    ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
                    verilerin işlenmesinin gerekli olması,
                    <br />
                    - Veri sorumlusu olarak hukuki yükümlülüklerimizin yerine
                    getirilebilmesi için zorunlu olması,
                    <br />
                    - İlgili kişilerin (kişisel verileri işlenen gerçek
                    kişilerin) kendileri tarafından kişisel verilerinin
                    alenileştirilmiş olması,
                    <br />
                    - Bir hakkın tesisi, kullanılması veya korunması için veri
                    işlemenin zorunlu olması,
                    <br />
                    - İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                    kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                    işlenmesinin zorunlu olması
                    <br />
                    <br />
                    ile Kanun’un 5. Maddesinde belirtilen diğer kişisel veri
                    işleme şartları ve amaçları çerçevesinde işlenmektedir.
                    Bunun yanında 5. Madde kapsamında, ilgili kişilerin (kişisel
                    verileri işlenen gerçek kişilerin) açık rızası alınmak
                    kaydıyla işlenmesi mümkün olan kişisel veriler, ancak bahse
                    konu açık rızaların edinilmesi yoluyla işlenmektedir.
                    <br />
                    <br />
                    Öğrenilmesi halinde ilgili kişi hakkında ayrımcılık
                    yapılmasına veya mağduriyete neden olabilecek nitelikteki
                    verilerini ifade eden özel nitelikli kişisel veriler ise,
                    ancak ilgili kişinin açık rızası, Kişisel Verileri Koruma
                    Kurulu tarafından belirlenen gerekli önlemlerin alınması ve
                    Kanun’un 6. Maddesinde yer alan şartları ve amaçları
                    çerçevesinde işlenmektedir.
                    <br />
                    <br />
                    <strong>
                      SMS ve E-posta İletilerinin Gönderimi Sürecinde
                      Müşterilerin Açık Rızası Doğrultusunda İşlenecek Kişisel
                      Veriler ve Bu Kişisel Verilerin İşlenme Amacı
                    </strong>
                    <br />
                    <br />
                    Aşağıda Kentmetre İletişim Danışmanlığı San. Tic. A. Ş.
                    tarafından işlenen ve Kanun uyarınca kişisel veri sayılan
                    verilerin hangileri olduğu sıralanmıştır. Aksi açıkça
                    belirtilmedikçe, işbu Politika ile arz edilen hüküm ve
                    koşullar kapsamında “kişisel veri” ifadesi aşağıda yer alan
                    bilgileri kapsayacaktır.
                    <br />
                    <br />
                    • Ad ve Soyad
                    <br />
                    • Telefon Numarası
                    <br />
                    • E-posta Adresi
                    <br />
                    <br />
                    Kentmetre İletişim Danışmanlığı San. Tic. A. Ş.; SMS ve
                    E-posta iletilerinin gönderimi sürecine katılan müşterilere
                    ait verileri Şirket’in tanıtım, bilgilendirme, reklam
                    faaliyetlerinin yapılabilmesi ve müşteriler ile iletişim
                    kurulabilmesi amacıyla, meşru menfaatleri çerçevesinde,
                    yukarıdaki açıklamalara bağlı ve mevzuattaki sınırlamalara
                    uygun olarak işlemekte ve toplamaktadır.
                    <br />
                    <br />
                    Yukarıda belirtilen kişisel verilerin Kentmetre İletişim
                    Danışmanlığı San. Tic. A. Ş. tarafından yurt içinde
                    işlenmesi nedeniyle ilgili kişinin açık rıza vermesi
                    gerekmektedir.
                    <br />
                    <br />
                    Bunun yanında yukarı anılan amaçlarla online alışveriş
                    sürecinde müşterilere e-posta ve SMS iletilerinin
                    ulaştırılmasına ilişkin Ticari Elektronik İletiler Hakkında
                    Yönetmelik uyarınca ilgili kişinin onay vermesi
                    gerekmektedir.
                    <br />
                    <br />
                    <strong>İşlenen Kişisel Verilerin Aktarılması</strong>
                    <br />
                    <br />
                    Tarafımızca toplanan kişisel verileriniz; hukuka ve
                    dürüstlük kurallarına uygun, doğru ve gerektiğinde güncel,
                    belirli, açık ve meşru amaçlar için işlenen, işlendikleri
                    amaçla bağlantılı, sınırlı ve ölçülü, ilgili mevzuatta
                    öngörülen veya işlendikleri amaç için gerekli olan süre
                    kadar muhafaza edilme ilkelerine uygun olarak işlenir.
                    <br />
                    <br />
                    <strong>
                      Kişisel Verilerinizin Ne Şekilde İşlenebileceği
                    </strong>
                    <br />
                    <br />
                    6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca,
                    tarafımız ile paylaştığınız ve yukarıda ifadesini bulan
                    kişisel verileriniz ve özel nitelikli kişisel verileriniz
                    tamamen veya kısmen, otomatik olarak, veyahut herhangi bir
                    veri kayıt sisteminin parçası olmak kaydıyla otomatik
                    olmayan yollarla elde edilerek, kaydedilerek, depolanarak,
                    değiştirilerek, yeniden düzenlenerek, veriler üzerinde
                    gerçekleştirilen her türlü işleme konu olarak
                    işlenebilecektir. KVKK kapsamında veriler üzerinde
                    gerçekleştirilen her türlü işlem “kişisel verilerin
                    işlenmesi” olarak kabul edilmektedir.
                    <br />
                    <br />
                    <strong>Kişisel Verilerinizin Toplanma Şekli</strong>
                    <br />
                    <br />
                    Kişisel verileriniz, SMS ve e-posta iletilerinin gönderimi
                    sürecinde dijital yollar ve elden toplanmakta ve
                    işlenmektedir.
                    <br />
                    <br />
                    <strong>Kişisel Verilerin Güvenliği</strong>
                    <br />
                    <br />
                    Şirketimiz, 6698 sayılı Kişisel Verileri Koruma Kanunu
                    kapsamında ve ‘veri sorumlusu’ olması nedeniyle veri
                    güvenliğini sağlama yükümlülüğü taşımaktadır. Bu nedenle
                    Şirketimiz,
                    <br />
                    • Kişisel verilerin hukuka aykırı olarak işlenmesini
                    önlemek,
                    <br />
                    • Kişisel verilere hukuka aykırı olarak erişilmesini
                    önlemek,
                    <br />
                    • Kişisel verilerin muhafazasını sağlamak amacıyla uygun
                    güvenlik düzeyini temin etmeye yönelik her türlü teknik ve
                    idari tedbirleri almaktadır. Bunun yanında Şirketimiz kendi
                    kurum veya kuruluşunda, bu Kanun hükümlerinin uygulanmasını
                    sağlamak amacıyla gerekli denetimleri yapmakta ve
                    yaptırmaktadır. Şirketimiz, işlediği kişisel verileri KVKK
                    ve diğer mevzuata aykırı olarak başkasına açıklamamakta,
                    paylaşmamakta, aktarmamakta, ifşa etmemekte ve işleme amacı
                    dışında kullanmamaktadır.
                    <br />
                    <br />
                    <strong>Kişisel Veri Sahibi Olarak Haklarınız</strong>
                    <br />
                    <br />
                    Kanun’un 11. maddesi uyarınca veri sahipleri;
                    <br />
                    • Kendileri ile ilgili kişisel veri işlenip işlenmediğini
                    öğrenme,
                    <br />
                    • Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
                    <br />
                    • Kişisel verilerin işlenme amacını ve bunların amacına
                    uygun kullanılıp kullanılmadığını öğrenme,
                    <br />
                    • Yurt içinde veya yurt dışında kişisel verilerin
                    aktarıldığı üçüncü kişileri bilme,
                    <br />
                    • Kişisel verilerin eksik veya yanlış işlenmiş olması
                    hâlinde bunların düzeltilmesini isteme ve bu kapsamda
                    yapılan işlemin kişisel verilerin aktarıldığı üçüncü
                    kişilere bildirilmesini isteme,
                    <br />
                    • Kanun ve ilgili diğer kanun hükümlerine uygun olarak
                    işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
                    ortadan kalkması hâlinde kişisel verilerin silinmesini veya
                    yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel
                    verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                    <br />
                    • İşlenen verilerin münhasıran otomatik sistemler
                    vasıtasıyla analiz edilmesi suretiyle kişinin kendisi
                    aleyhine bir sonucun ortaya çıkmasına itiraz etme,
                    <br />
                    • Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                    zarara uğraması hâlinde zararın giderilmesini talep etme
                    haklarına sahiptir.
                    <br />
                    <br />
                    Kişisel verileriniz ile ilgili başvuru ve taleplerinizi
                    dilerseniz İlgili Kişi Başvuru Formu aracılığıyla;{" "}
                    <a href="mailto:info@kentmetre.com">
                      info@kentmetre.com
                    </a>{" "}
                    adresine elektronik posta göndererek,
                    <br />
                    <br />
                    Geçerli bir kimlik belgesi ile bizzat Şirketimize
                    başvurarak,
                    <br />
                    <br />
                    Islak imzalı ve kimlik fotokopisi ile Fevzi Çakmak Mah.
                    Tevfik İleri Cad. No:129 İç Kapı No: 5 Pendik / İstanbul
                    adresine göndererek,
                    <br />
                    <br />
                    Mobil imza veya güvenli elektronik imza ile imzalayıp
                    <a href="mailto:info@kentmetre.com">
                      info@kentmetre.com
                    </a>{" "}
                    adresine e-posta göndererek, şirketimize iletebilirsiniz.
                    <br />
                    <br />
                    Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ
                    uyarınca, İlgili Kişinin, başvurusunda isim, soy isim,
                    başvuru yazılı ise imza, T.C. kimlik numarası (başvuruda
                    bulunan kişinin yabancı olması halinde uyruğu, pasaport
                    numarası veya varsa kimlik numarası), tebligata esas
                    yerleşim yeri veya iş yeri adresi, varsa bildirime esas
                    elektronik posta adresi, telefon numarası ve faks numarası
                    ile talep konusuna dair bilgilerin bulunması zorunludur.
                    <br />
                    <br />
                    İlgili Kişi, yukarıda belirtilen hakları kullanmak için
                    yapacağı ve kullanmayı talep ettiği hakka ilişkin
                    açıklamaları içeren başvuruda talep edilen hususu açık ve
                    anlaşılır şekilde belirtmelidir. Başvuruya ilişkin bilgi ve
                    belgelerin başvuruya eklenmesi gerekmektedir.
                    <br />
                    <br />
                    Talep konusunun başvuranın şahsı ile ilgili olması
                    gerekmekle birlikte, başkası adına hareket ediliyor ise
                    başvuruyu yapanın bu konuda özel olarak yetkili olması ve bu
                    yetkinin belgelendirilmesi (vekâletname) gerekmektedir.
                    Ayrıca başvurunun kimlik ve adres bilgilerini içermesi ve
                    başvuruya kimliği doğrulayıcı belgelerin eklenmesi
                    gerekmektedir.
                    <br />
                    <br />
                    Yetkisiz üçüncü kişilerin başkası adına yaptığı talepler
                    değerlendirmeye alınmayacaktır. Kişisel Veri Sahipleri
                    olarak KVKK’nın 11. maddesinde sayılan haklarınıza ilişkin
                    taleplerinizi işbu Bilgilendirme ve Aydınlatma Metninde
                    düzenlenen yöntemlerle Şirketimize iletmeniz durumunda
                    Şirketimiz talebin niteliğine göre talebi en kısa sürede ve
                    en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır.
                    <br />
                    <br />
                    Aydınlatma metnini okuduğunuz için teşekkür ederiz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer pauseOnFocusLoss={false} />
    </div>
  );
}

export default GirisYap;
