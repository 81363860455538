import React, { useContext, useEffect, useRef, useState } from "react";

import ReactECharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import siyasetmetreSecimJson from "../../json/siyasetmetre_secim.json";
import { AppSettings } from "../../config/app-settings";
import apiClient from "../../ApiClient";
import toastMessage from "../ToastMessage";
import PageChartCard from "../page-chart-card/pageChartCard";

const EchartRace = ({
  id,
  title,
  path,
  city,
  district,
  neighbourhood,
  street,
  graphic,
  table,
  extraChart,
  note,
}) => {
  const chartRef = useRef();

  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [chartOption, myChartOption] = useState({});
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState([]);

  async function getData() {
    setChartData([]);
    setData(null);

    try {
      const response = await apiClient.get(
        `/${path}?graphic=${graphic}${city ? `&city=${city}` : ""}${
          district ? `&district=${district}` : ""
        }${neighbourhood ? `&neighbourhood=${neighbourhood}` : ""}${
          street ? `&street=${street}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      let chartDataArray = [];

      if (response.status === 200) {
        if (!district && !neighbourhood && !street) {
          setChartData([
            ["Income", "Country", "Year"],
            [43.14, "AK PARTI", "2009 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [35.36, "CHP", "2009 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [8.99, "SAADET", "2009 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [8.06, "MHP", "2009 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [1.76, "BBP", "2009 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [4.48, "DSP", "2009 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [0.69, "ODP", "2009 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [
              53.33,
              "AK PARTI",
              "2011 - 24. DONEM MILLETVEKILLIGI GENEL SECIMI",
            ],
            [33.85, "CHP", "2011 - 24. DONEM MILLETVEKILLIGI GENEL SECIMI"],
            [2.01, "SAADET", "2011 - 24. DONEM MILLETVEKILLIGI GENEL SECIMI"],
            [10.16, "MHP", "2011 - 24. DONEM MILLETVEKILLIGI GENEL SECIMI"],
            [0.9, "BBP", "2011 - 24. DONEM MILLETVEKILLIGI GENEL SECIMI"],
            [0.44, "DSP", "2011 - 24. DONEM MILLETVEKILLIGI GENEL SECIMI"],
            [45.74, "AK PARTI", "2014 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [38.49, "CHP", "2014 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [3.29, "SAADET", "2014 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [6.75, "MHP", "2014 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [0.68, "BBP", "2014 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [0.08, "DSP", "2014 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [4.81, "HEDEP", "2014 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [0.07, "ODP", "2014 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [42.66, "AK PARTI", "2015 - 25. DONEM MILLETVEKILLIGI SECIMLERI"],
            [30.54, "CHP", "2015 - 25. DONEM MILLETVEKILLIGI SECIMLERI"],
            [2.31, "SAADET", "2015 - 25. DONEM MILLETVEKILLIGI SECIMLERI"],
            [11.58, "MHP", "2015 - 25. DONEM MILLETVEKILLIGI SECIMLERI"],
            [0.24, "DSP", "2015 - 25. DONEM MILLETVEKILLIGI SECIMLERI"],
            [12.87, "HEDEP", "2015 - 25. DONEM MILLETVEKILLIGI SECIMLERI"],
            [49.1, "AK PARTI", "2015 - 26. MILLETVEKILLIGI GENEL SECIMLERI"],
            [30.83, "CHP", "2015 - 26. MILLETVEKILLIGI GENEL SECIMLERI"],
            [0.96, "SAADET", "2015 - 26. MILLETVEKILLIGI GENEL SECIMLERI"],
            [8.68, "MHP", "2015 - 26. MILLETVEKILLIGI GENEL SECIMLERI"],
            [0.56, "BBP", "2015 - 26. MILLETVEKILLIGI GENEL SECIMLERI"],
            [0.15, "DSP", "2015 - 26. MILLETVEKILLIGI GENEL SECIMLERI"],
            [10.21, "HEDEP", "2015 - 26. MILLETVEKILLIGI GENEL SECIMLERI"],
            [
              42.37,
              "AK PARTI",
              "2018 - 27. DONEM MILLETVEKILLIGI GENEL SECIMLER",
            ],
            [26.94, "CHP", "2018 - 27. DONEM MILLETVEKILLIGI GENEL SECIMLER"],
            [1.61, "SAADET", "2018 - 27. DONEM MILLETVEKILLIGI GENEL SECIMLER"],
            [8.27, "MHP", "2018 - 27. DONEM MILLETVEKILLIGI GENEL SECIMLER"],
            [12.76, "HEDEP", "2018 - 27. DONEM MILLETVEKILLIGI GENEL SECIMLER"],
            [
              8.2,
              "IYI PARTI",
              "2018 - 27. DONEM MILLETVEKILLIGI GENEL SECIMLER",
            ],
            [48.55, "AK PARTI", "2019 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [48.8, "CHP", "2019 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [1.21, "SAADET", "2019 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [0.32, "BTP", "2019 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [0.36, "DSP", "2019 - BELEDIYE BASKANLIGI YEREL SECIMLERI"],
            [45.23, "AK PARTI", "2019 - YENILENEN ISTANBUL SECIMI"],
            [54.23, "CHP", "2019 - YENILENEN ISTANBUL SECIMI"],
            [0.69, "SAADET", "2019 - YENILENEN ISTANBUL SECIMI"],
            [
              36.57,
              "AK PARTI",
              "2023 - 28. DONEM MILLETVEKILI GENEL SECIMLERI",
            ],
            [29.12, "CHP", "2023 - 28. DONEM MILLETVEKILI GENEL SECIMLERI"],
            [6.21, "MHP", "2023 - 28. DONEM MILLETVEKILI GENEL SECIMLERI"],
            [0.84, "BBP", "2023 - 28. DONEM MILLETVEKILI GENEL SECIMLERI"],
            [8.42, "HEDEP", "2023 - 28. DONEM MILLETVEKILI GENEL SECIMLERI"],
            [
              8.41,
              "IYI PARTI",
              "2023 - 28. DONEM MILLETVEKILI GENEL SECIMLERI",
            ],
            [2.91, "ZP", "2023 - 28. DONEM MILLETVEKILI GENEL SECIMLERI"],
            [4.18, "TIP", "2023 - 28. DONEM MILLETVEKILI GENEL SECIMLERI"],
            [3.39, "YRP", "2023 - 28. DONEM MILLETVEKILI GENEL SECIMLERI"],
          ]);
          setData([["Income", "Country", "Year"]]);
        } else {
          if (response.data[0].length !== 0) {
            if (district === 457 || district === 478 || district === 474) {
              response.data[0].push({
                Alt_Kategori_1: 13,
                year: 2019,
                total: 0,
                parameter: 8,
              });
            }

            response.data[0].forEach((item) => {
              let yearTitle;

              if (parseInt(item.year) === 2009) {
                yearTitle =
                  item.year + " - BELEDIYE BASKANLIGI YEREL SECIMLERI";
              }
              if (parseInt(item.year) === 2011) {
                yearTitle =
                  item.year + " - 24. DONEM MILLETVEKILLIGI GENEL SECIMI";
              }
              if (parseInt(item.year) === 2014) {
                yearTitle =
                  item.year + " - BELEDIYE BASKANLIGI YEREL SECIMLERI";
              }
              if (parseInt(item.year) === 2015 && item.Alt_Kategori_1 === 7) {
                yearTitle =
                  item.year + " - 25. DONEM MILLETVEKILLIGI SECIMLERI";
              }
              if (parseInt(item.year) === 2015 && item.Alt_Kategori_1 === 8) {
                yearTitle =
                  item.year + " - 26. MILLETVEKILLIGI GENEL SECIMLERI";
              }
              if (parseInt(item.year) === 2018) {
                yearTitle =
                  item.year + " - 27. DONEM MILLETVEKILLIGI GENEL SECIMLER";
              }
              if (parseInt(item.year) === 2019 && item.Alt_Kategori_1 === 13) {
                yearTitle =
                  item.year + " - BELEDIYE BASKANLIGI YEREL SECIMLERI";
              }
              if (parseInt(item.year) === 2019 && item.Alt_Kategori_1 === 4) {
                yearTitle = item.year + " - YENILENEN ISTANBUL SECIMI";
              }
              if (parseInt(item.year) === 2023) {
                yearTitle =
                  item.year + " - 28. DONEM MILLETVEKILI GENEL SECIMLERI";
              }

              chartDataArray.push([
                parseFloat(item.total.toFixed(2)),
                siyasetmetreSecimJson.parametre[item.parameter],
                yearTitle,
              ]);
            });

            const sortedArray = chartDataArray.sort((a, b) => {
              const yearA = parseInt(a[2]);
              const yearB = parseInt(b[2]);

              if (yearA !== yearB) {
                return yearA - yearB;
              } else {
                const textA = a[2].includes(
                  "BELEDIYE BASKANLIGI YEREL SECIMLERI"
                )
                  ? 0
                  : 1;
                const textB = b[2].includes(
                  "BELEDIYE BASKANLIGI YEREL SECIMLERI"
                )
                  ? 0
                  : 1;
                return textA - textB;
              }
            });

            sortedArray.unshift(["Income", "Country", "Year"]);

            setChartData(sortedArray);
            setData(response.data);
          } else {
            setChartData([]);
            setData([[]]);
          }
        }
      }
    } catch (error) {
      console.error(title + " Error", error);
      setData(null);

      if (error.response.status === 500) {
        toastMessage("500 | Sunucu Hatası!", "error");
      } else if (error.response.status === 404) {
        navigate("/404");
      } else if (error.response.status === 403) {
        toastMessage("Çok fazla istek atıldı.", "error");
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      } else {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }
    }
  }

  useEffect(() => {
    // getData();
    // eslint-disable-next-line
  }, [city, district, neighbourhood, street]);

  // Chart
  useEffect(() => {
    if (data && chartData) {
      if (data[0].length !== 0) {
        const myChart = chartRef.current.getEchartsInstance();

        run(chartData);

        function run(_rawData) {
          const countries = [
            "AK PARTI",
            "CHP",
            "SAADET",
            "MHP",
            "BBP",
            "BTP",
            "DSP",
            "HEDEP",
            "IYI PARTI",
            "ZP",
            "TIP",
            "YRP",
            "ODP",
          ];
          const datasetWithFilters = [];
          const seriesList = [];

          countries.forEach((country) => {
            var datasetId = "dataset_" + country;

            datasetWithFilters.push({
              id: datasetId,
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    // { dimension: "Year", gte: 1950 },
                    { dimension: "Country", "=": country },
                  ],
                },
              },
            });

            seriesList.push({
              type: "line",
              datasetId: datasetId,
              showSymbol: false,
              name: country,
              endLabel: {
                show: true,
                formatter: function (params) {
                  return (
                    params.value[1] +
                    ": " +
                    params.value[0].toLocaleString("tr-TR")
                  );
                },
              },
              labelLayout: {
                moveOverlap: "shiftY",
              },
              emphasis: {
                focus: "series",
              },
              encode: {
                x: "Year",
                y: "Income",
                label: ["Country", "Income"],
                itemName: "Year",
                tooltip: ["Income"],
              },
              itemStyle: {
                color:
                  country === "AK PARTI"
                    ? "#f7981a"
                    : country === "CHP" && "#ed1b25",
              },
            });
          });

          const option = {
            darkMode: window.location.host.includes("datakokpit")?false:true,
            animationDuration: 10000,
            dataset: [
              {
                id: "dataset_raw",
                source: _rawData,
              },
              ...datasetWithFilters,
            ],
            tooltip: {
              order: "valueDesc",
              trigger: "axis",
            },
            xAxis: {
              type: "category",
              nameLocation: "middle",
              axisLabel: {
                rotate: 45,
                textStyle: {
                  color: "#fff",
                  fontSize: 10,
                },
                formatter: function (value, index) {
                  return value.substring(0, 7) + "...";
                },
              },
            },
            yAxis: {
              axisLabel: {
                color: "#fff",
              },
            },
            grid: {
              right: 100,
              top: 20,
              bottom: 100,
            },
            series: seriesList,
          };

          // myChart.setOption(option);
          myChartOption(option);
        }
      }
    }
  }, [data, chartData]);

  return (
    <>
      {data ? (
        data[0].length !== 0 ? (
          <PageChartCard
            id={id}
            path={path}
            graphic={graphic}
            city={city}
            district={district}
            neighbourhood={neighbourhood}
            street={street}
            tableData={chartData}
            title={title}
            table={table}
            extraChart={false}
            note={note}
            chart={
              <ReactECharts
                option={chartOption}
                ref={chartRef}
                className="w-100 h-100"
              />
            }
            height="auto"
          />
        ) : (
          <span className="d-block text-center">
            Veri Bulunmamaktadır
          </span>
        )
      ) : (
        <div className="spinner-border text-theme"></div>
      )}

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
};

export default EchartRace;
