import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import apiClient from "../../ApiClient";
import toastMessage from "../../components/ToastMessage";
import { AppSettings } from "../../config/app-settings";

import PasswordInput from "../../components/PasswordInput";
import { ToastContainer } from "react-toastify";
import RoleInfo from "../../components/RoleInfo";

export default function UpdateModal({ getData, updateItem }) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  // Update User
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [updateID, setUpdateID] = useState(null);
  const [submitData, setSubmitData] = useState(null);
  const [formData, setFormData] = useState({
    customer: "",
    role: "",
    username: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    password: "",
    password_confirmation: "",
    settings: {
      gender: "",
      title: "",
      image: null,
      kvkk_confirm_date: null,
    },
  });

  useEffect(() => {
    if (updateItem) {
      setUpdateID(updateItem.id);
      setFormData(updateItem);
      setSubmitData(updateItem);
    } else {
      setUpdateID(null);
      setSubmitData(null);
      setFormData({
        customer: "",
        role: "",
        username: "",
        name: "",
        phone: "",
        email: "",
        address: "",
        password: "",
        password_confirmation: "",
        settings: {
          gender: "",
          title: "",
          image: null,
          kvkk_confirm_date: null,
        },
      });
    }
  }, [updateItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      if (name === "unvan") {
        return {
          ...prevData,
          settings: {
            ...prevData.settings,
            title: value,
          },
        };
      }

      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const handleChangeSelect = (selectedOption, selectName) => {
    setFormData((prevData) => {
      if (selectName === "gender") {
        return {
          ...prevData,
          settings: {
            ...prevData.settings,
            gender: selectedOption,
          },
        };
      }

      return { ...prevData, [selectName]: selectedOption };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setButtonDisabled(true);

    const changedFields = {};
    for (const key in formData) {
      if (formData[key] !== submitData[key]) {
        changedFields[key] = formData[key];
      }
    }

    const updatedData = { ...changedFields };

    if (updatedData["customer"]) {
      updatedData["customer"] = updatedData["customer"].value;
    }

    if (updatedData["role"]) {
      updatedData["role"] = updatedData["role"].value;
    }

    if (context?.me?.role.id !== 1 && updatedData["role"] === 1) {
      toastMessage(context?.t?.cannot_appoint_manager, "error");
      return;
    }

    try {
      const response = await apiClient.put(`/user/${updateID}`, updatedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setButtonDisabled(false);

        toastMessage(response.data.message, "success");

        document.getElementById("updateModalClose").click();
        getData();

        // window.location.reload();
      }
    } catch (error) {
      console.error("User Update Error", error);
      setButtonDisabled(false);

      if (error.response.status === 422) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }

      if (error.response.status === 401) {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }

      if (error.response.status === 403) {
        toastMessage(context?.t?.try_again_error, "error");
        setTimeout(() => {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }, 1000);
      }

      if (error.response.status === 500) {
        toastMessage(context?.t?.server_error, "error");
      }
    }
  }

  return (
    <div className="modal modal-cover fade" id="updateModal">
      <div className="modal-dialog">
        <div
          className="modal-content rounded"
          style={{ background: "#141b24" }}
        >
          <div className="modal-header">
            <h3 className="modal-title">{context?.t?.edit_user}</h3>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              id="updateModalClose"
            ></button>
          </div>

          <ul className="nav nav-tabs nav-tabs-v2 ps-4 pe-4">
            <li className="nav-item me-3">
              <a
                href="#edit_kullanici_bilgisi"
                className="nav-link active"
                data-bs-toggle="tab"
              >
                {context?.t?.user}
              </a>
            </li>
            <li className="nav-item me-3">
              <a
                href="#edit_uyelik_bilgisi"
                className="nav-link"
                data-bs-toggle="tab"
              >
                {context?.t?.membership}
              </a>
            </li>
          </ul>

          <form onSubmit={handleSubmit}>
            <div className="tab-content p-4">
              <div
                className="tab-pane fade show active"
                id="edit_kullanici_bilgisi"
              >
                {context?.me?.role.id === 1 && (
                  <div className="mb-3">
                    <span className="mb-1 d-block">
                      {context?.t?.choose_customer}
                    </span>
                    <Select
                      options={
                        context.allCustomerSelect
                          ? context.allCustomerSelect
                          : []
                      }
                      placeholder=""
                      classNamePrefix="react-select"
                      value={formData.customer}
                      onChange={(selectedOption) =>
                        handleChangeSelect(selectedOption, "customer")
                      }
                      noOptionsMessage={() => context?.t?.no_option}
                    />
                  </div>
                )}

                <div className="mb-3">
                  <RoleInfo />

                  <Select
                    options={
                      context.allRolesSelect ? context.allRolesSelect : []
                    }
                    placeholder=""
                    classNamePrefix="react-select"
                    value={formData.role}
                    onChange={(selectedOption) =>
                      handleChangeSelect(selectedOption, "role")
                    }
                    required
                    noOptionsMessage={() => context?.t?.no_option}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">{context?.t?.gender}</span>
                  <Select
                    options={[
                      { value: "E", label: context?.t?.male },
                      { value: "K", label: context?.t?.female },
                    ]}
                    placeholder=""
                    classNamePrefix="react-select"
                    value={formData.settings.gender}
                    onChange={(selectedOption) =>
                      handleChangeSelect(selectedOption, "gender")
                    }
                    required
                    noOptionsMessage={() => context?.t?.no_option}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">{context?.t?.username}</span>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                    disabled
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">{context?.t?.user_title}</span>
                  <input
                    type="text"
                    className="form-control"
                    name="unvan"
                    value={formData.settings.title}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    {context?.t?.user_representative}
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    {context?.t?.user_activation_phone}
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    placeholder="+905555555555"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">{context?.t?.user_email}</span>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    {context?.t?.user_address}
                  </span>
                  <textarea
                    className="form-control"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="tab-pane fade" id="edit_uyelik_bilgisi">
                <div className="mb-3">
                  <span className="mb-1 d-block">{context?.t?.password}</span>
                  <PasswordInput
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required={false}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    {context?.t?.password_confirm}
                  </span>
                  <PasswordInput
                    name="password_confirmation"
                    value={formData.password_confirmation}
                    onChange={handleChange}
                    required={false}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-outline-theme w-100"
                disabled={buttonDisabled}
              >
                {context?.t?.save}
                {buttonDisabled && (
                  <div className="spinner-border spinner-border-sm text-theme ms-2"></div>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
