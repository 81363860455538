import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import { AppSettings } from "../../config/app-settings";
import apiClient from "../../ApiClient";
import { ToastContainer } from "react-toastify";
import AnketSorulari from "./AnketSorulari";
import toastMessage from "../../components/ToastMessage";
import { Card, CardBody } from "../../components/card/card";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const center = { lat: 39.1667, lng: 35.6667 };
const defaultZoom = 5.8;
let options;
setTimeout(() => {
  options = {
    center,
    disableDefaultUI: true,
    zoomControl: true,
    styles:
      document.querySelector("html").getAttribute("data-bs-theme") === "light"
        ? []
        : [
            {
              featureType: "all",
              elementType: "all",
              stylers: [
                { invert_lightness: true },
                { saturation: -50 },
                { lightness: 40 },
                { gamma: 0.5 },
                {
                  hue: window.location.host.includes("datakokpit")
                    ? "#17248a"
                    : "#3cd2a5",
                },
              ],
            },
          ],
  };
}, 3000);

export default function AddReport({
  getLocation,
  currentLocation,
  imageLocation,
  mapModalData,
  surveyData,
  tcSurvey,
  handleRemoveMarker,
  assigmentSelect,
  scriptLoaded,
  surveyInnerPage = false,
  phone,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  // Cities Filter
  const [city, setCity] = useState(null);
  const [cityValue, setCityValue] = useState(null);

  const [district, setDistrict] = useState(null);
  const [districtLoader, setDistrictLoader] = useState(true);
  const [districtValue, setDistrictValue] = useState(null);
  const [allDistrict, setAllDistrict] = useState([]);

  const [neighborhoodValue, setNeighborhoodValue] = useState(null);
  const [neighborhoodLoader, setNeighborhoodLoader] = useState(true);
  const [allNeighborhood, setAllNeighborhood] = useState([]);

  const [streetValue, setStreetValue] = useState(null);
  const [streetLoader, setStreetLoader] = useState(true);
  const [allStreet, setAllStreet] = useState([]);

  const [buildValue, setBuildValue] = useState("");
  const [apartmentValue, setApartmentValue] = useState("");

  const [submitSurveyLoading, setSubmitSurveyLoading] = useState(false);

  const [zoomLevel, setZoomLevel] = useState(defaultZoom);
  const [mapInstance, setMapInstance] = useState(null);
  const [modalMapCity, setModalMapCity] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  // Reset
  function resetNeighborhood() {
    setNeighborhoodValue(null);
    setNeighborhoodLoader(false);
    setTimeout(() => {
      setNeighborhoodLoader(true);
    }, 500);
  }

  function resetStreet() {
    setStreetValue(null);
    setStreetLoader(false);
    setTimeout(() => {
      setStreetLoader(true);
    }, 500);
  }

  // Select
  function handleCity(e) {
    setModalMapCity(e);
    setCityValue(e);

    setDistrictLoader(false);
    setTimeout(() => {
      setDistrictLoader(true);
    }, 500);
    setDistrictValue(null);
    fetchDistrict(e.value);

    resetNeighborhood();
    fetchNeighborhood(null);

    resetStreet();
    fetchStreet(null);
  }

  function handleDistrict(e) {
    setDistrictValue(e);
    setModalMapCity(e);

    resetNeighborhood();
    fetchNeighborhood(e.value && e.value !== "" ? e.value : null);

    resetStreet();
    fetchStreet(null);
  }

  function handleNeighborhood(e) {
    setNeighborhoodValue(e);
    setModalMapCity(e);

    resetStreet();
    fetchStreet(e.value && e.value !== "" ? e.label : null);
  }

  async function handleStreet(e) {
    setStreetValue(e);

    const regionInfo = {
      city: cityValue?.label,
      district: districtValue?.label,
      neighborhood: neighborhoodValue?.label,
      street: e?.label,
    };

    const address = `${regionInfo.street}, ${regionInfo.neighborhood}, ${regionInfo.district}, ${regionInfo.city}`;

    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${process.env.REACT_APP_GOOGLE_API}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setSelectedMarker({ lat: location.lat, lng: location.lng, zoom: 25 });
        map.panTo({ lat: location.lat, lng: location.lng });
        map.setZoom(25);
      } else {
        toastMessage(context?.t?.bolge_bulunamadi, "error");
      }
    } catch (error) {
      console.error("Geocode hatası:", error);
      toastMessage(context?.t?.try_again_error, "error");
    }
  }

  // Fetchs
  async function fetchDistrict(value, name) {
    if (value) {
      const data = await context.getAllDistrict(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: context?.t?.choose_district,
        });

        setAllDistrict(newData);
      }
    }
  }

  async function fetchNeighborhood(value) {
    if (value) {
      const data = await context.getAllNeighborhood(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: context?.t?.choose_neighborhood,
        });

        setAllNeighborhood(newData);
      }
    } else {
      setAllNeighborhood([]);
    }
  }

  async function fetchStreet(value) {
    if (value) {
      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: "Bina Hane Sayısı",
            type: `bireysel-data-customer-${context?.me?.customer?.id}`,
            description: "",
            customer: null,
            status: true,
            order: 1,
            connection_type: "local",
            sql: {
              column: [{ key: "Adres Sokak", value: "select" }],
              condition: [
                {
                  key: "Adres Il",
                  value: context
                    .removeTurkishCharacters(cityValue?.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                },
                {
                  key: "Adres Ilce",
                  value: context
                    .removeTurkishCharacters(districtValue?.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                },
                {
                  key: "Adres Mahalle",
                  value: context.removeTurkishCharacters(value).toUpperCase(),
                  operator: "like",
                  type: "and",
                },
              ],
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.length !== 0) {
          const data = response
            .map((item) => {
              return {
                value: context
                  .removeTurkishCharacters(item["adres sokak"])
                  .toUpperCase(),
                label: context
                  .removeTurkishCharacters(item["adres sokak"])
                  .toUpperCase(),
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label));

          const newData = data.slice();
          newData.unshift({
            value: "",
            label: context?.t?.choose_street,
          });

          setAllStreet(newData);
        } else {
          setAllStreet([]);
        }
      } catch (error) {
        setAllStreet(null);
      }
    } else {
      setAllStreet([]);
    }
  }

  // User
  // const [me, setMe] = useState(null);

  // async function getMe() {
  //   if (localStorage.getItem("token")) {
  //     try {
  //       const response = await apiClient.get("/me", {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       });
  //       setMe(response.data);
  //     } catch (error) {
  //       if (error.response.status !== 404) {
  //         setMe(null);
  //         localStorage.removeItem("token");
  //         localStorage.removeItem("user");
  //         navigate("/giris-yap");
  //       }
  //     }
  //   } else {
  //     setMe(null);
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("user");
  //     navigate("/giris-yap");
  //   }
  // }

  // useEffect(() => {
  //   getMe();
  // }, []);

  useEffect(() => {
    if (context && context?.me) {
      if (context?.me?.customer?.city) {
        setCityValue({
          value: context?.me?.customer?.city?.id || null,
          label: context?.me?.customer?.city?.name || null,
        });
        setCity(context?.me?.customer?.city?.id || null);
        fetchDistrict(context?.me?.customer?.city?.id || null);
      }

      if (context?.me?.customer?.district) {
        setDistrictValue({
          value: context?.me?.customer?.district?.id || null,
          label: context?.me?.customer?.district?.name.toUpperCase() || null,
        });
        setDistrict(context?.me?.customer?.district || null);
        fetchNeighborhood(context?.me?.customer?.district?.id || null);
      }
    }
  }, [context]);

  // Create Report
  const [answers, setAnswers] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [imageFields, setImageFields] = useState({}); // Yeni eklenen state

  function resizeImage(file, maxWidth, maxHeight, callback) {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        callback(new File([blob], file.name, { type: file.type }));
      }, file.type);
    };

    reader.readAsDataURL(file);
  }

  async function handleImageUpload(questionId, fieldId, file) {
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file.size > maxFileSize) {
      toastMessage(context?.t?.max_file_size_5mb, "error");
      return;
    }

    resizeImage(file, 500, 500, async (resizedFile) => {
      const formData = new FormData();
      formData.append("file", resizedFile);

      try {
        const response = await apiClient.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const imageUrl = response.data.url;

        setImageFields((prevFields) => ({
          ...prevFields,
          [questionId]: (prevFields[questionId] || []).map((field) =>
            field.id === fieldId
              ? { ...field, files: [...field.files, imageUrl] }
              : field
          ),
        }));
      } catch (error) {
        console.error("Error:", error);
      }
    });
  }

  function handleAddImageField(questionId) {
    setImageFields((prevFields) => ({
      ...prevFields,
      [questionId]: [
        ...(prevFields[questionId] || []),
        {
          id: Date.now(),
          files: [],
          description: "",
          location: imageLocation,
        },
      ],
    }));
  }

  function handleRemoveImageField(questionId, fieldId) {
    setImageFields((prevFields) => ({
      ...prevFields,
      [questionId]: (prevFields[questionId] || []).filter(
        (field) => field.id !== fieldId
      ),
    }));
  }

  function handleImageChange(questionId, fieldId, event) {
    const file = event.target.files[0]; // Tek dosya seçildiğini varsayıyoruz
    if (file) {
      if (getLocation) {
        getLocation();
      }
      handleImageUpload(questionId, fieldId, file);
    }
  }

  async function handleRemoveImage(questionId, fieldId, imageUrl) {
    setImageFields((prevFields) => ({
      ...prevFields,
      [questionId]: (prevFields[questionId] || []).map((field) =>
        field.id === fieldId
          ? {
              ...field,
              files: field.files.filter((file) => file !== imageUrl),
            }
          : field
      ),
    }));
  }

  function handleDescriptionChange(questionId, fieldId, description) {
    setImageFields((prevFields) => ({
      ...prevFields,
      [questionId]: (prevFields[questionId] || []).map((field) =>
        field.id === fieldId ? { ...field, description } : field
      ),
    }));
  }

  //--

  function handleCreateReport() {
    if (assigmentSelect) {
      if (!cityValue || cityValue?.value === "") {
        toastMessage("Şehir Seçmelisiniz", "error");
        return;
      }

      if (!districtValue || districtValue?.value === "") {
        toastMessage("İlçe Seçmelisiniz", "error");
        return;
      }

      if (!neighborhoodValue || neighborhoodValue?.value === "") {
        toastMessage("Mahalle Seçmelisiniz", "error");
        return;
      }

      if (!streetValue || streetValue?.value === "") {
        toastMessage("Sokak Seçmelisiniz", "error");
        return;
      }

      if (!selectedMarker) {
        toastMessage(context?.t?.pin_belirleyin, "error");
        return;
      }

      document.getElementById("reportQuestionsModalButton").click();
    }
  }

  const [errors, setErrors] = useState({});

  async function handleSubmitSurvey() {}

  const [predictions, setPredictions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [mapCenter, setMapCenter] = useState(null);
  const inputRef = useRef(null);
  const autocompleteServiceRef = useRef(null);
  const placesServiceRef = useRef(null);

  useEffect(() => {
    if (scriptLoaded && inputRef.current) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
      placesServiceRef.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      const handleInputChange = () => {
        const query = inputRef.current.value;
        setInputValue(query);

        if (query.length > 2) {
          autocompleteServiceRef.current.getPlacePredictions(
            { input: query, componentRestrictions: { country: "TR" } },
            (predictions, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setPredictions(predictions);
              }
            }
          );
        } else {
          setPredictions([]);
        }
      };

      inputRef.current.addEventListener("input", handleInputChange);

      return () => {
        inputRef.current.removeEventListener("input", handleInputChange);
      };
    }
  }, [scriptLoaded]);

  //--

  const [map, setMap] = useState(null);
  const polygonRef = useRef(null);

  const updateMap = useCallback(() => {
    if (map && modalMapCity && modalMapCity.value) {
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
      }

      let itemGEOModalTodo;

      if (modalMapCity.value === 34) {
        itemGEOModalTodo = JSON.parse(
          modalMapCity.geo.replace(/\"/g, "").trim()
        );
      } else {
        itemGEOModalTodo = JSON.parse(
          modalMapCity.geo.replace(/\"/g, "").trim()
        )[0][0];
      }

      const polygonCoords = itemGEOModalTodo
        .map((coordGroup, coordGroupIndex) => {
          if (
            modalMapCity.value === 34 &&
            (coordGroupIndex === 0 || coordGroupIndex === 1)
          ) {
            return coordGroup[0].map((coord) => ({
              lat: coord[1],
              lng: coord[0],
            }));
          } else if (modalMapCity.value !== 34) {
            return { lat: coordGroup[1], lng: coordGroup[0] };
          }
        })
        .flat()
        .filter(Boolean);

      const bounds = new window.google.maps.LatLngBounds();
      polygonCoords.forEach((coord) => {
        bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
      });

      const modalMapCityPolygon = new window.google.maps.Polygon({
        paths: polygonCoords,
        map: map,
        fillColor: "#15202bb5",
        strokeColor: context?.theme === "light" ? "#000" : "#fff",
        strokeWeight: 1,
        clickable: false,
      });

      polygonRef.current = modalMapCityPolygon;

      map.setZoom(defaultZoom); // Varsayılan zoom seviyesini ayarla
      setTimeout(() => {
        // Fit bounds ve zoom seviyesini sabitleme
        map.fitBounds(bounds);
      }, 100);
    } else {
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
        map.setZoom(defaultZoom);
      }
    }
  }, [map, modalMapCity]);

  useEffect(() => {
    updateMap();
  }, [updateMap]);

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const newMarker = {
      lat: lat,
      lng: lng,
      zoom: zoomLevel,
    };

    setSelectedMarker(newMarker);
  };

  const handleZoomChanged = () => {
    if (map) {
      setZoomLevel(map.getZoom());
    }
  };

  // Image Map
  const [imageSelectedMarker, setImageSelectedMarker] = useState(false);
  const [imageZoomLevel, setImageZoomLevel] = useState(false);

  const handleImageMapClick = (e, questionId, fieldId) => {
    setImageFields((prevFields) => ({
      ...prevFields,
      [questionId]: prevFields[questionId].map((field) =>
        field.id === fieldId
          ? {
              ...field,
              selectedMarker: {
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
                zoom: 1,
              },
            }
          : field
      ),
    }));
  };

  const handleImageZoomChanged = () => {
    if (map) {
      setImageZoomLevel(map.getZoom());
    }
  };

  // Reset
  function handleReset() {
    // Cities Filter
    setCity(null);
    setCityValue(null);

    setDistrict(null);
    setDistrictLoader(true);
    setDistrictValue(null);
    setAllDistrict([]);

    setNeighborhoodValue(null);
    setNeighborhoodLoader(true);
    setAllNeighborhood([]);

    setStreetValue(null);
    setStreetLoader(true);
    setAllStreet([]);

    setBuildValue("");
    setApartmentValue("");

    setSubmitSurveyLoading(false);

    setZoomLevel(defaultZoom);
    setModalMapCity(null);
    setSelectedMarker(null);

    // Create Report
    setAnswers({});
    setCurrentQuestion(null);
    setImageFields({}); // Yeni eklenen state

    setErrors({});

    setPredictions([]);
    setInputValue("");
    setMapCenter(null);

    // Image Map
    setImageSelectedMarker(false);
    setImageZoomLevel(false);
  }

  return (
    <>
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#reportQuestionsModal"
        className="d-none"
        id="reportQuestionsModalButton"
      />

      {!surveyInnerPage && (
        <div className="modal modal-cover fade" id="addReportModal">
          <div
            className="modal-dialog py-4 px-sm-4"
            style={{
              maxWidth: "100%",
            }}
          >
            <div
              className="modal-content rounded"
              style={{ background: "var(--bs-dark4)" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">
                  {context?.t?.tespit_raporu_olustur}
                </h3>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="addReportModalClose"
                ></button>
              </div>

              <div className="modal-body">
                <div className="mb-3">
                  <div className="row g-3">
                    <div className={`col-6`}>
                      {context.allCity ? (
                        <div className="select-height">
                          <Select
                            options={context.allCity}
                            value={
                              cityValue
                                ? cityValue
                                : city &&
                                  context.allCity.find(function (item) {
                                    return item.value === city;
                                  })
                            }
                            classNamePrefix="react-select"
                            placeholder={context?.t?.choose_city}
                            noOptionsMessage={() => context?.t?.no_option}
                            onChange={(e) => handleCity(e)}
                          />
                        </div>
                      ) : (
                        <div className="spinner-border spinner-border-sm text-theme"></div>
                      )}
                    </div>

                    <div className={`col-6`}>
                      {context?.me?.customer?.district ? (
                        district ? (
                          districtLoader ? (
                            <div className="select-height">
                              <Select
                                options={[]}
                                value={
                                  districtValue
                                    ? districtValue
                                    : district.city === cityValue.value
                                    ? allDistrict.find(function (item) {
                                        return item.value === district.id;
                                      })
                                    : ""
                                }
                                classNamePrefix="react-select"
                                placeholder={context?.t?.choose_district}
                                noOptionsMessage={() => context?.t?.no_option}
                                onChange={(e) => handleDistrict(e)}
                              />
                            </div>
                          ) : (
                            <div className="spinner-border spinner-border-sm text-theme"></div>
                          )
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )
                      ) : districtLoader ? (
                        <div className="select-height">
                          <Select
                            options={allDistrict}
                            value={districtValue}
                            classNamePrefix="react-select"
                            placeholder={context?.t?.choose_district}
                            noOptionsMessage={() => context?.t?.no_option}
                            onChange={(e) => handleDistrict(e)}
                          />
                        </div>
                      ) : (
                        <div className="spinner-border spinner-border-sm text-theme"></div>
                      )}
                    </div>

                    <div className={`col-6`}>
                      {neighborhoodLoader ? (
                        <div className="select-height">
                          <Select
                            options={allNeighborhood}
                            value={neighborhoodValue ? neighborhoodValue : ""}
                            classNamePrefix="react-select"
                            placeholder={context?.t?.choose_neighborhood}
                            noOptionsMessage={() => context?.t?.no_option}
                            onChange={(e) => handleNeighborhood(e)}
                          />
                        </div>
                      ) : (
                        <div className="spinner-border spinner-border-sm text-theme"></div>
                      )}
                    </div>

                    <div className={`col-6`}>
                      {streetLoader ? (
                        <div className="select-height">
                          <Select
                            options={allStreet}
                            value={streetValue ? streetValue : ""}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            placeholder={context?.t?.choose_street}
                            onChange={handleStreet}
                          />
                        </div>
                      ) : (
                        <div className="spinner-border spinner-border-sm text-theme"></div>
                      )}
                    </div>

                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        style={{ height: 60 }}
                        placeholder={context?.t?.building}
                        value={buildValue}
                        onChange={(e) => setBuildValue(e.target.value)}
                      />
                    </div>

                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        style={{ height: 60 }}
                        placeholder={context?.t?.apartment}
                        value={apartmentValue}
                        onChange={(e) => setApartmentValue(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-1">
                  <span className="d-block h5 fw-normal">
                    {context?.t?.pin_eklemek_haritaya_tiklayin}
                  </span>
                  {/* 
                  <span
                    className="d-block text-danger pointer h5 fw-normal"
                    onClick={() => setSelectedMarker(null)}
                  >
                    Pin Kaldır
                  </span> */}
                </div>

                <div className="mb-3">
                  <GoogleMap
                    mapContainerStyle={{
                      height: 400,
                      width: "100%",
                    }}
                    options={options}
                    zoom={zoomLevel}
                    onLoad={setMap}
                    onClick={handleMapClick}
                    onZoomChanged={handleZoomChanged}
                  >
                    {selectedMarker && (
                      <Marker
                        icon={{
                          url:
                            "data:image/svg+xml;charset=utf-8," +
                            encodeURIComponent(`
                              <svg width="800px" height="800px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5.5 5.5C5.5 4.11929 6.61929 3 8 3C9.38071 3 10.5 4.11929 10.5 5.5C10.5 6.88071 9.38071 8 8 8H7V11H8C11.0376 11 13.5 8.53757 13.5 5.5C13.5 2.46243 11.0376 0 8 0C4.96243 0 2.5 2.46243 2.5 5.5H5.5Z" stroke="#000000" fill="#de8e00"/>
                                  <path d="M10 13H7V16H10V13Z" stroke="#000000" fill="#de8e00"/>
                                </svg>
                          `),
                          scale: 0.04,
                          scaledSize: new window.google.maps.Size(30, 30),
                          anchor: new window.google.maps.Point(15, 15),
                        }}
                        position={{
                          lat: selectedMarker.lat,
                          lng: selectedMarker.lng,
                        }}
                        map={map}
                      />
                    )}
                  </GoogleMap>
                </div>

                <hr className="my-3" />

                <div className="row g-3">
                  <div className="col-12">
                    <div className="row g-2 justify-content-end h-100">
                      <div className="col-6 h-100">
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          className="btn btn-lg btn-outline-default w-100 h-100 d-flex align-items-center justify-content-center"
                        >
                          {context?.t?.cancel}
                        </button>
                      </div>

                      <div className="col-6 h-100">
                        <button
                          type="button"
                          className="btn btn-lg btn-theme w-100 h-100 d-flex align-items-center justify-content-center"
                          onClick={() => handleCreateReport()}
                        >
                          {context?.t?.rapor_olustur}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="modal modal-cover fade" id="reportQuestionsModal">
        <div className="modal-dialog py-4 px-sm-4" style={{ maxWidth: 1600 }}>
          <div
            className="modal-content rounded"
            style={{ background: "var(--bs-dark4)" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                <span>{context?.t?.arastirma_sorulari}</span>
                {!tcSurvey && !phone && (
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target={
                      surveyInnerPage ? "#kisiAnketiModal" : "#addReportModal"
                    }
                    className="btn btn-dark btn-lg ms-3"
                  >
                    {context?.t?.go_back}
                  </button>
                )}
              </h3>

              <button
                type="button"
                id="reportQuestionsModalCloseButton"
                className="btn-close d-none"
                data-bs-dismiss="modal"
              ></button>

              {tcSurvey || phone ? (
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              ) : (
                <button
                  type="button"
                  className="btn-close"
                  data-bs-toggle="modal"
                  data-bs-target={
                    surveyInnerPage ? "#kisiAnketiModal" : "#addReportModal"
                  }
                ></button>
              )}
            </div>

            <div className="modal-body">
              <h4>
                {context?.t?.anket_adi}: {surveyData?.name}
              </h4>

              <hr />

              <AnketSorulari
                city={cityValue?.value || null}
                district={districtValue?.value || null}
                neighbourhood={neighborhoodValue?.value || null}
                street={streetValue?.value || null}
                building={buildValue || null}
                independent={apartmentValue || null}
                location={selectedMarker}
                tcSurvey={tcSurvey}
                surveyInnerPage={surveyInnerPage}
                setSubmitSurveyLoading={setSubmitSurveyLoading}
                //--
                handleReset={handleReset}
                //--
                setCurrentQuestion={setCurrentQuestion}
                setAnswers={setAnswers}
                data={surveyData}
                errors={errors}
                setErrors={setErrors}
                imageFields={imageFields}
                phone={phone}
              />
            </div>
          </div>
        </div>

        {submitSurveyLoading && (
          <div
            className="position-fixed top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center"
            style={{ zIndex: 99, background: "rgb(13 20 18 / 85%)" }}
          >
            <div className="spinner-border text-theme"></div>
          </div>
        )}
      </div>

      <div className="modal modal-cover fade" id="imageUploadModal">
        <div
          className="modal-dialog py-4 px-sm-4"
          style={{
            maxWidth: "100%",
          }}
        >
          <div
            className="modal-content rounded"
            style={{ background: "var(--bs-dark4)" }}
          >
            <div className="modal-header border-bottom">
              <h3 className="modal-title">
                {context?.t?.upload_image}
                <br />
                {currentQuestion?.question}
              </h3>

              <button
                type="button"
                className="btn btn-dark btn-lg"
                data-bs-toggle="modal"
                data-bs-target="#reportQuestionsModal"
              >
                {context?.t?.sorulara_don}
              </button>
            </div>

            <div className="modal-body">
              <button
                type="button"
                className="btn btn-lg btn-outline-theme mb-3"
                onClick={() => handleAddImageField(currentQuestion?.id)}
              >
                {context?.t?.resim_alani_ekle}
              </button>

              {(imageFields[currentQuestion?.id] || []).map((field, index) => (
                <Card key={field.id} className="mb-3">
                  <CardBody>
                    <div className="border-bottom pb-3 mb-3">
                      <div className="row align-items-center">
                        <div className="col-6">
                          <span className="fw-normal h5">
                            {index + 1}. {context?.t?.alan}
                          </span>
                        </div>

                        <div className="col-6 text-end">
                          <button
                            type="button"
                            className="btn btn-lg btn-danger"
                            onClick={() =>
                              handleRemoveImageField(
                                currentQuestion?.id,
                                field.id
                              )
                            }
                          >
                            {context?.t?.remove}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 mb-3">
                        <label className="form-label">
                          {context?.t?.upload_image}
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-lg"
                          accept="image/*"
                          onChange={(e) =>
                            handleImageChange(currentQuestion?.id, field.id, e)
                          }
                        />
                      </div>

                      <div className="col-6 mb-3">
                        <label className="form-label">
                          {context?.t?.description}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          value={field.description}
                          onChange={(e) =>
                            handleDescriptionChange(
                              currentQuestion?.id,
                              field.id,
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>

                    {field.files.length > 0 && (
                      <div className="row g-3">
                        {field.files.map((file, fileIndex) => (
                          <div key={fileIndex} className="col-md-4">
                            <Card>
                              <CardBody className="p-1">
                                <div
                                  className="position-relative"
                                  style={{ height: 250 }}
                                >
                                  <img
                                    src={file}
                                    alt={`Uploaded ${fileIndex}`}
                                    className="card-img-top w-100 h-100 img-cover"
                                  />

                                  <button
                                    type="button"
                                    className="btn btn-danger position-absolute top-0 end-0 m-1 btn-sm"
                                    onClick={() =>
                                      handleRemoveImage(
                                        currentQuestion?.id,
                                        field.id,
                                        file
                                      )
                                    }
                                  >
                                    <i
                                      className="fas fa-trash-alt"
                                      style={{ fontSize: 14 }}
                                    ></i>
                                  </button>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        ))}

                        <GoogleMap
                          mapContainerStyle={{ height: 400, width: "100%" }}
                          options={options}
                          zoom={imageZoomLevel}
                          onLoad={setMap}
                          onClick={(e) =>
                            handleImageMapClick(
                              e,
                              currentQuestion?.id,
                              field.id
                            )
                          } // Her field için ayrı marker ekleme
                          onZoomChanged={handleImageZoomChanged}
                        >
                          {field.selectedMarker && (
                            <Marker
                              icon={{
                                path: "M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5ZM7.5 10C8.88071 10 10 8.88071 10 7.5C10 6.11929 8.88071 5 7.5 5C6.11929 5 5 6.11929 5 7.5C5 8.88071 6.11929 10 7.5 10ZM10.3536 13.3536L12.5 15.5L18.1464 9.85355C18.4614 9.53857 19 9.76165 19 10.2071V19H5V18L9.64645 13.3536C9.84171 13.1583 10.1583 13.1583 10.3536 13.3536Z",
                                fillColor: "#de8e00",
                                fillOpacity: 1,
                                strokeColor: "#000000",
                                scale: 1,
                                size: new window.google.maps.Size(24, 24),
                                anchor: new window.google.maps.Point(12, 12),
                              }}
                              position={{
                                lat: field.selectedMarker.lat,
                                lng: field.selectedMarker.lng,
                              }}
                            />
                          )}
                        </GoogleMap>
                      </div>
                    )}
                  </CardBody>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
