import React, { useEffect, useRef, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5wc from "@amcharts/amcharts5/wc";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default function WordCloud({
  data,
  previewSelectWordCloud,
  previewSelectLabel,
  previewSelectValue,
  surveyCard = false,
  height = 600,
  animate = true,
}) {
  const chartDivRef = useRef(null);
  const [chart, setChart] = useState(null);
  const colorsArray = [
    am5.color("#5470c6"),
    am5.color("#91cc75"),
    am5.color("#fac858"),
    am5.color("#ee6666"),
    am5.color("#3ba272"),
    am5.color("#73c0de"),
    am5.color("#9a60b4"),
    am5.color("#5470c6"),
    am5.color("#ea7ccc"),
    am5.color("#91cc75"),
    am5.color("#fac858"),
    am5.color("#73c0de"),
    am5.color("#ee6666"),
    am5.color("#3ba272"),
    am5.color("#9a60b4"),
    am5.color("#ea7ccc"),
  ];

  useEffect(() => {
    let chartData;

    if (surveyCard) {
      chartData = data;
    } else {
      if (previewSelectWordCloud) {
        chartData = data
          .map((item) => item[previewSelectWordCloud.value])
          .join(", ");
      } else {
        chartData = data.map((item) => ({
          category: item[previewSelectLabel.value],
          value: item[previewSelectValue.value],
        }));
      }
    }

    if (chart) {
      chart.dispose(); // Dispose of previous chart instance
    }

    if (chartData) {
      if (chartDivRef) {
        setTimeout(() => {
          // Create root element
          const root = am5.Root.new(chartDivRef.current);

          // Set themes
          // if (animate) {
          //   root.setThemes([am5themes_Animated.new(root)]);
          // }

          // Create series
          let series = root.container.children.push(
            Array.isArray(chartData)
              ? am5wc.WordCloud.new(root, {
                  colors: am5.ColorSet.new(root, {
                    colors: colorsArray,
                  }),
                  maxCount: 200,
                  minWordLength: 2,
                  minFontSize: am5.percent(30),
                  maxFontSize: am5.percent(100),
                })
              : am5wc.WordCloud.new(root, {
                  colors: am5.ColorSet.new(root, {
                    colors: colorsArray,
                  }),
                  maxCount: 200,
                  minWordLength: 2,
                  minFontSize: am5.percent(30),
                  maxFontSize: am5.percent(100),
                  text: chartData,
                })
          );

          if (Array.isArray(chartData)) {
            series.data.setAll(chartData);
          }

          series.labels.template.setAll({
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 5,
            paddingRight: 5,
            tooltipText: "{category}: [bold]{value}[/]",
            fill: am5.color(
              window.location.host.includes("datakokpit")
                ? "#17248a"
                : "#3cd2a5"
            ),
          });

          setChart(root);

          return () => {
            root.dispose();
          };
        }, 300);
      }
    }
  }, [data, previewSelectWordCloud, previewSelectLabel, previewSelectValue]);

  return (
    <div
      style={{ width: "100%", height: height === "h-full" ? "100%" : height }}
    >
      <div ref={chartDivRef} style={{ width: "100%", height: "100%" }} />
    </div>
  );
}
