import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from "@react-google-maps/api";
import moment from "moment";
import Fancybox from "./Fancybox";
import { CardExpandToggler } from "./card/card";

const calculateCenter = (data, latField, lngField) => {
  if (data.length === 0) return { lat: 39.1667, lng: 35.6667 };

  const total = data.reduce(
    (acc, item) => {
      const lat = parseFloat(item[latField].replace(",", "."));
      const lng = parseFloat(item[lngField].replace(",", "."));
      acc.lat += lat;
      acc.lng += lng;
      return acc;
    },
    { lat: 0, lng: 0 }
  );

  const count = data.length;
  return {
    lat: total.lat / count,
    lng: total.lng / count,
  };
};

export default function DynamicMap({
  data,
  todoCategoryData,
  pureTodoData,
  tableColumn,
  selectedColumnValue,
  selectedColumnLabel,
  setMapLocation,
  location,
  todoData: initialTodoData,
  context,
}) {
  const [tableSelectedData, setTableSelectedData] = useState(null);
  const [hoveredMarkerId, setHoveredMarkerId] = useState(null);

  const [selectedMarkerTodo, setSelectedMarkerTodo] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [options, setOptions] = useState({
    center: { lat: 39.1667, lng: 35.6667 },
    fullscreenControl: false,
    zoom: 5.8,
    styles:
      document.querySelector("html").getAttribute("data-bs-theme") === "light"
        ? []
        : [
            {
              featureType: "all",
              elementType: "all",
              stylers: [
                { invert_lightness: true },
                { saturation: -50 },
                { lightness: 40 },
                { gamma: 0.5 },
                {
                  hue: window.location.host.includes("datakokpit")
                    ? "#17248a"
                    : "#3cd2a5",
                },
              ],
            },
          ],
  });

  useEffect(() => {
    if (location) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        center: { lat: location.lat, lng: location.lng },
        zoom: location.zoom,
      }));
    } else {
      if (
        data &&
        data.length !== 0 &&
        selectedColumnValue &&
        selectedColumnLabel
      ) {
        const newCenter = calculateCenter(
          data,
          selectedColumnValue.value,
          selectedColumnLabel.value
        );
        // `options`'un sadece `center` kısmını `newCenter` ile güncelle
        setOptions((prevOptions) => ({
          ...prevOptions,
          center: newCenter,
        }));
      }
    }
  }, [data, selectedColumnValue, selectedColumnLabel, location]);

  const handleMarkerClick = (item) => {
    setSelectedMarker(item);
  };

  const mapRef = useRef(null);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onCenterChanged = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();

      if (setMapLocation) {
        setMapLocation((prevState) => ({
          ...prevState,
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        }));
      }
    }
  };

  const onZoomChanged = () => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();

      if (setMapLocation) {
        setMapLocation((prevState) => ({
          ...prevState,
          zoom: newZoom,
        }));
      }
    }
  };

  const [pinFiltre, setPinFiltre] = useState();
  const [todoData, setTodoData] = useState(initialTodoData);

  function handlePinFiltre(e) {
    setPinFiltre(e);

    const filtered = pureTodoData.filter((item) => {
      if (e.value === null) return true;
      return item.category && item.category.id === e.value;
    });

    setTodoData(e.value === "removed" ? [] : filtered);
  }

  return (
    <div className="h-100">
      <div className="map-zoom-item">
        <CardExpandToggler />
      </div>

      <div
        style={{
          zIndex: 1,
          padding: 5,
          right: 50,
          top: 10,
          borderRadius: 4,
        }}
        className="position-absolute bg-white d-flex gap-2"
      >
        <div className="">
          <span
            className="d-block fw-bold mb-1"
            style={{ lineHeight: 1, color: "black" }}
          >
            {context?.t?.gorev_kategorileri}
          </span>
          <div
            style={{
              minWidth: 180,
            }}
          >
            <Select
              options={todoCategoryData}
              className="select-map"
              classNamePrefix="react-select"
              noOptionsMessage={() => context?.t?.no_option}
              placeholder={context?.t?.pin_filtre}
              value={pinFiltre}
              onChange={(e) => handlePinFiltre(e)}
            />
          </div>
        </div>
      </div>

      <GoogleMap
        onLoad={onMapLoad}
        onCenterChanged={onCenterChanged}
        onZoomChanged={onZoomChanged}
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        options={options} // Use options with state values here
      >
        {todoData && todoData.length !== 0 && (
          <MarkerClusterer
            options={{
              maxZoom: 20, // Set this to control when clusters should stop grouping markers
              styles: [
                {
                  textColor: "white", // Set the cluster text color to white
                  url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
                  height: 50,
                  width: 50,
                },
                {
                  textColor: "white",
                  url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png",
                  height: 56,
                  width: 56,
                },
                {
                  textColor: "white",
                  url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
                  height: 66,
                  width: 66,
                },
              ],
            }}
          >
            {(clusterer) =>
              todoData.map((todo) =>
                todo.list.map((marker, index) => {
                  if (marker?.location) {
                    return (
                      <Marker
                        key={index}
                        clusterer={clusterer}
                        position={{
                          lat: parseFloat(marker?.location?.lat),
                          lng: parseFloat(marker?.location?.lng),
                        }}
                        icon={{
                          path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
                          fillColor: todo?.category?.color,
                          fillOpacity: 1,
                          strokeWeight: hoveredMarkerId === marker.id ? 2 : 0,
                          strokeColor:
                            context?.theme === "light" ? "#000" : "#fff",
                          scale: 0.07,
                          size: new window.google.maps.Size(800, 800),
                          anchor: new window.google.maps.Point(200, 400),
                        }}
                        title={marker.title}
                        onClick={() => {
                          setSelectedMarkerTodo(marker);
                          setTableSelectedData(null);
                        }}
                        onMouseOver={() => {
                          setHoveredMarkerId(marker.id);
                        }}
                        onMouseOut={() => {
                          setHoveredMarkerId(null);
                        }}
                      >
                        {selectedMarkerTodo &&
                          selectedMarkerTodo.id === marker.id && (
                            <InfoWindow
                              position={selectedMarkerTodo.position}
                              onCloseClick={() => setSelectedMarkerTodo(null)}
                            >
                              <div
                                className="px-2"
                                style={{
                                  maxWidth: 450,
                                  maxHeight: 322,
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                }}
                              >
                                <span className="h4 text-black mb-2 d-block">
                                  {selectedMarkerTodo.title.includes("#")
                                    ? selectedMarkerTodo.title.split("#")[1]
                                    : selectedMarkerTodo.title}
                                </span>

                                <div>
                                  {selectedMarkerTodo.image && (
                                    <div
                                      className="carousel slide"
                                      id={`mapMarkerCarousel_${index}`}
                                    >
                                      <div className="carousel-inner">
                                        {selectedMarkerTodo.image.map(
                                          (image, imageIndex) => (
                                            <Fancybox
                                              options={{
                                                Carousel: {
                                                  infinite: false,
                                                },
                                              }}
                                            >
                                              <div
                                                key={imageIndex}
                                                className={`carousel-item${
                                                  imageIndex === 0
                                                    ? " active"
                                                    : ""
                                                }`}
                                              >
                                                <a
                                                  data-fancybox={`todo-${selectedMarkerTodo.id}-gallery`}
                                                  href={image}
                                                >
                                                  <img
                                                    src={image}
                                                    className="d-block mx-auto"
                                                    alt={
                                                      selectedMarkerTodo.title
                                                    }
                                                    height="200"
                                                  />
                                                </a>
                                              </div>
                                            </Fancybox>
                                          )
                                        )}
                                      </div>

                                      <button
                                        className="carousel-control-prev"
                                        type="button"
                                        data-bs-target={`#mapMarkerCarousel_${index}`}
                                        data-bs-slide="prev"
                                      >
                                        <span
                                          className="carousel-control-prev-icon"
                                          aria-hidden="true"
                                        ></span>
                                        <span className="visually-hidden">
                                          {context?.t?.prev}
                                        </span>
                                      </button>

                                      <button
                                        className="carousel-control-next"
                                        type="button"
                                        data-bs-target={`#mapMarkerCarousel_${index}`}
                                        data-bs-slide="next"
                                      >
                                        <span
                                          className="carousel-control-next-icon"
                                          aria-hidden="true"
                                        ></span>
                                        <span className="visually-hidden">
                                          {context?.t?.next}
                                        </span>
                                      </button>
                                    </div>
                                  )}
                                </div>

                                <div className="text-start p-2">
                                  {selectedMarkerTodo.completed && (
                                    <span className="d-block text-theme fw-bold mb-2">
                                      {context?.t?.tamamlandi}
                                    </span>
                                  )}
                                  {selectedMarkerTodo.description && (
                                    <>
                                      <span className="d-block fw-bold mb-2">
                                        {context?.t?.description}
                                      </span>
                                      <div
                                        className="fw-normal"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            selectedMarkerTodo.description,
                                        }}
                                      ></div>
                                      <hr />
                                    </>
                                  )}
                                  {selectedMarkerTodo.summary && (
                                    <>
                                      <span className="d-block fw-bold mb-2">
                                        {context?.t?.yonetici_ozeti}
                                      </span>
                                      <div
                                        className="fw-normal"
                                        dangerouslySetInnerHTML={{
                                          __html: selectedMarkerTodo.summary,
                                        }}
                                      ></div>
                                      <hr />
                                    </>
                                  )}
                                  {selectedMarkerTodo.district && (
                                    <span className="d-block fw-normal mb-1">
                                      {context?.t?.address}:
                                      {selectedMarkerTodo.neighbourhood &&
                                        selectedMarkerTodo.neighbourhood
                                          .label}{" "}
                                      {selectedMarkerTodo.street &&
                                        selectedMarkerTodo.street.label}
                                      <br />
                                      {context?.t?.building}:
                                      {selectedMarkerTodo.building || "-"}{" "}
                                      {context?.t?.apartment}:
                                      {selectedMarkerTodo.independent || "-"}
                                      <br />
                                      {selectedMarkerTodo.city &&
                                        selectedMarkerTodo.city.label}
                                      /
                                      {selectedMarkerTodo.district &&
                                        selectedMarkerTodo.district.label &&
                                        selectedMarkerTodo.district.label.toUpperCase()}
                                    </span>
                                  )}
                                  <span className="d-block fw-normal">
                                    {context?.t?.started_date}:
                                    {moment(
                                      selectedMarkerTodo.started_at,
                                      "YYYY-MM-DD HH:mm:ss"
                                    ).format("DD MMMM YYYY")}
                                  </span>
                                  <span className="d-block fw-normal mt-1">
                                    {context?.t?.end_date}:
                                    {moment(
                                      selectedMarkerTodo.finished_at,
                                      "YYYY-MM-DD HH:mm:ss"
                                    ).format("DD MMMM YYYY")}
                                  </span>

                                  {selectedMarkerTodo.file && (
                                    <a
                                      href={selectedMarkerTodo.file[0]}
                                      target="_blank"
                                      className="mt-2 d-block fw-medium"
                                      rel="noreferrer"
                                    >
                                      {context?.t?.dosyayi_goruntule}
                                    </a>
                                  )}

                                  {selectedMarkerTodo.tags.length !== 0 && (
                                    <>
                                      <hr className="my-2" />
                                      <div className="d-flex flex-wrap gap-1">
                                        {selectedMarkerTodo.tags.map(
                                          (tag, tagIndex) => (
                                            <span
                                              key={tagIndex}
                                              className="badge bg-black"
                                            >
                                              {tag}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </InfoWindow>
                          )}
                      </Marker>
                    );
                  } else {
                    return null;
                  }
                })
              )
            }
          </MarkerClusterer>
        )}

        {data &&
          data.length !== 0 &&
          selectedColumnValue &&
          selectedColumnLabel && (
            <>
              <MarkerClusterer
                options={{
                  maxZoom: 20,
                  styles: [
                    {
                      textColor: "white",
                      url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
                      height: 50,
                      width: 50,
                    },
                    {
                      textColor: "white",
                      url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png",
                      height: 56,
                      width: 56,
                    },
                    {
                      textColor: "white",
                      url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
                      height: 66,
                      width: 66,
                    },
                  ],
                }}
              >
                {(clusterer) =>
                  data.map((item, index) => {
                    const position = {
                      lat: parseFloat(
                        item[selectedColumnValue.value].replace(",", ".")
                      ),
                      lng: parseFloat(
                        item[selectedColumnLabel.value].replace(",", ".")
                      ),
                    };

                    return (
                      <Marker
                        key={index}
                        position={position}
                        clusterer={clusterer}
                        onClick={() => handleMarkerClick(item)}
                      />
                    );
                  })
                }
              </MarkerClusterer>

              {selectedMarker && (
                <InfoWindow
                  position={{
                    lat: parseFloat(
                      selectedMarker[selectedColumnValue.value].replace(
                        ",",
                        "."
                      )
                    ),
                    lng: parseFloat(
                      selectedMarker[selectedColumnLabel.value].replace(
                        ",",
                        "."
                      )
                    ),
                  }}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  <div>
                    {Object.entries(selectedMarker).map(([key, value]) => (
                      <p key={key}>
                        <strong>{key}:</strong> {value}
                      </p>
                    ))}
                  </div>
                </InfoWindow>
              )}
            </>
          )}
      </GoogleMap>
    </div>
  );
}
