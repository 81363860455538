import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import apiClient from "../ApiClient";

export default function DynamicTable({ data, tableColumn }) {
  // Tablo Preview
  const [tableData, setTableData] = useState(null);
  const [tablePage, setTablePage] = useState(1);
  const [tableCondition, setTableCondition] = useState({
    column: null,
    value: "",
  });

  const [selectValue, setSelectValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const tablePreview = useCallback(async () => {
    if (data) {
      setTableData(null);

      const payload = {
        chart: data.chart,
        page_id: data.page_id,
        title: data.title,
        type: data.type,
        description: data.description,
        status: data.status,
        customer: data.customer_id,
        order: data.order,
        paginate: true,
        connection_type: "local",
sql: {
          column: data.payload ? data.payload.column : data.sql.column,
          condition: data.payload ? data.payload.condition : data.sql.condition,
          group: data.payload ? data.payload.group : data.sql.group,
        },
      };

      payload.sql.condition =
        payload.sql.condition.length !== 0
          ? [
              tableCondition.column
                ? {
                    key: tableCondition.column,
                    value: tableCondition.value,
                    operator: "like",
                    type: "and",
                  }
                : null,
              ...payload.sql.condition.map((item) => {
                return {
                  key: item.key,
                  value: item.value,
                  operator: item.operator,
                  type: item.type,
                };
              }),
            ].filter((item) => item !== null)
          : [
              tableCondition.column
                ? {
                    key: tableCondition.column,
                    value: tableCondition.value,
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null);

      try {
        const response = await apiClient.customPost(
          `/query/preview?page=${tablePage}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          setTableData(response);
        }
      } catch (error) {
        setTableData({ data: [] });
        console.error(error);
      }
    }
  }, [data, tablePage, tableCondition]);

  useEffect(() => {
    tablePreview();
  }, [tablePreview]);

  const handleFilter = (e) => {
    if (selectValue && inputValue) {
      setTablePage(1);
      setTableCondition({ column: selectValue.value, value: inputValue });
    }
  };

  const handleReset = (e) => {
    setTablePage(1);
    setSelectValue(null);
    setInputValue("");
    setTableCondition({ column: null, value: "" });
  };

  const handlePageChange = (page) => {
    setTablePage(page);
  };

  return (
    <div className="flex-1 overflow-hidden d-flex flex-column h-100">
      {tableData ? (
        <>
          {tableColumn && (
            <div className="row g-3 mb-3">
              <div className="col">
                <Select
                  options={tableColumn.map((column, index) => {
                    return {
                      label: column?.selectedColumns
                        ? column?.selectedColumns.value
                        : column?.key,
                      value: column?.selectedColumns
                        ? column?.selectedColumns.value
                        : column?.key,
                    };
                  })}
                  value={selectValue}
                  classNamePrefix="react-select"
                  placeholder="FİLTRE SECINIZ..."
                  noOptionsMessage={() => "Seçenek Yok"}
                  onChange={(e) => setSelectValue(e)}
                />
              </div>

              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="FİLTRE YAZINIZ..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </div>

              <div className="col w-auto flex-none">
                <button
                  className="btn btn-outline-theme"
                  onClick={() => handleFilter()}
                >
                  BUL
                </button>
                {selectValue && inputValue && (
                  <button
                    className="btn btn-danger ms-2"
                    onClick={() => handleReset()}
                  >
                    Kaldır
                  </button>
                )}
              </div>
            </div>
          )}

          {tableData.data.length !== 0 ? (
            <div className="dataTables_wrapper dt-bootstrap5 flex-1 overflow-hidden d-flex flex-column">
              <div className="dataTables_scroll flex-1 overflow-auto">
                <div className="dataTables_scrollBody table-responsive">
                  <table className="table text-nowrap w-100 dataTable">
                    <thead>
                      <tr>
                        {Object.keys(tableData.data[0]).map((header, index) => (
                          <th className="text-start p-3" key={index}>
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {tableData.data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.values(row).map((value, cellIndex) => (
                            <td key={cellIndex}>{value}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row align-items-center mt-2">
                <div className="mb-0 col-md-12">
                  <div className="dataTables_paginate paging_simple_numbers d-flex flex-wrap justify-content-center text-center mt-3">
                    <ul className="pagination flex-wrap mb-0">
                      <li className={`paginate_button page-item`}>
                        <button
                          type="button"
                          onClick={() => handlePageChange(tablePage - 1)}
                          className={`page-link pointer ${
                            !tableData.prev_page_url ? "disabled" : ""
                          }`}
                        >
                          Geri
                        </button>
                      </li>

                      {tableData.links.map(
                        (number, index) =>
                          index > 0 &&
                          index < tableData.links.length - 1 && (
                            <li
                              key={index}
                              className={`paginate_button page-item ${
                                tablePage === parseInt(number.label)
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div
                                onClick={() =>
                                  handlePageChange(parseInt(number.label))
                                }
                                className="page-link pointer"
                              >
                                {number.label}
                              </div>
                            </li>
                          )
                      )}

                      <li className={`paginate_button page-item`}>
                        <button
                          type="button"
                          onClick={() => handlePageChange(tablePage + 1)}
                          className={`page-link pointer ${
                            !tableData.next_page_url ? "disabled" : ""
                          }`}
                        >
                          İleri
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <span className="d-block">Veri Bulunmamaktadır</span>
          )}
        </>
      ) : (
        <div className="spinner-border text-theme"></div>
      )}
    </div>
  );
}
